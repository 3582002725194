import * as React from 'react';
import {
	Grid,
	Typography,
	Button,
	Tooltip,
	Card,
	CardActions,
	CardContent,
	TextField,
	FormHelperText,
	makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { observer } from 'mobx-react-lite';
import HelpIcon from '@material-ui/icons/Help';
import { useForm } from 'react-hook-form';
import Captcha from './Captcha';
import { stores } from '../store';
import UserLogin from '../components/UserLogin';
import fieldSchema from '../Validation/schemaValidation';
const useStyles = makeStyles(theme => ({
	root: {
		minWidth: '275px',
		'& > *': {
			margin: theme.spacing(0.5),
			width: '380px',
			paddingRight: theme.spacing(4),
		},
		'@media (min-width: 0px) and (max-width: 760px)': {
			marginLeft: '0px !important',
			width: '100% !important',
		},
		marginLeft: '174px',
		width: '384px',
		borderRadius: '4px',
		top: '224px',
	},
	helper: {
		color: theme.palette.error.main,
		fontSize: '.8em',
		paddingLeft: '1.2rem',
		marginTop: 0,
	},
	title: {
		color: theme.palette.text.primary,
		paddingLeft: 12,
	},
	subtitle: {
		color: theme.palette.text.secondary,
		paddingLeft: 12,
	},
	buttonStyle: {
		fontSize: 14,
	},
	tooltip: {
		width: 82,
		padding: 2,
		textAlign: 'center',
	},
	errorText: {
		padding: 0,
		margin: 0,
		textAlign: 'left',
		color: theme.palette.error.main,
	},
	dialogBoxStyle: {
		width: '328px',
		height: '232px',
		overflow: 'hidden',
		padding: 25,
	},

	buttonDialog: {
		'&.MuiButton-text': {
			position: 'relative',
			left: '20px',
		},
	},
	helpIcon: {
		color: theme.palette.grey[50],
		fontSize: '1.3em',
		marginTop: 12,
	},

	label: {
		'&$focusedLabel': {
			color: 'cyan',
		},
		'&$erroredLabel': {
			color: 'orange',
		},
	},
	errorInputText: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#BD0810',
		},
		'&.MuiInputBase-input:focus': {
			color: '#BD0810',
		},
	},
	'&.MuiFormHelperText-root': {
		display: 'none',
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	submitBtn: {
		fontWeight: 500,
	},
	text: {
		color: theme.palette.text.primary,
		fontSize: '20',
	},
	subtext: {
		paddingTop: 11,
	},
	linkColor: {
		color: theme.palette.primary.main,
	},
	footer: {
		bottom: 0,
		marginTop: 'calc(5% + 60px)',
		width: '100%',
		textAlign: 'left',
		color: theme.palette.text.primary,
		float: 'left',
	},
}));
interface FormData {
	email: string;
	telephone: string;
	name: string;
}

const RegisterForm: React.FunctionComponent = observer(() => {
	const classes = useStyles();

	const registerValue = React.useContext(stores.context.register);
	const routes = React.useContext(stores.context.routes);
	const { routeName } = React.useContext(stores.context.routes);
	const appstores = React.useContext(stores.context.appStores);

	const emailRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const nameRef = React.useRef<HTMLInputElement>();
	const telephoneRef = React.useRef<HTMLInputElement>();
	const fieldValues: FormData = {
		email: '',
		telephone: '',
		name: '',
	};
	const [values, setValues] = React.useState({ email: '', telephone: '', name: '' });

	const methods = useForm<FormData>({
		validationSchema: fieldSchema,
		defaultValues: fieldValues,
		mode: 'onChange',
	});

	const { register, errors } = methods;
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [errDialogOpen, setErrDialogOpen] = React.useState(false);
	const [responseErr, setResponseErr] = React.useState(false);
	const [fieldErr, setFieldErr] = React.useState(false);
	const [isFormValid, setIsFormValid] = React.useState(false);

	const onSubmit = (event: React.SyntheticEvent) => {
		const { email, name, telephone } = values;
		fieldValues.email = email;
		fieldValues.telephone = telephone;
		fieldValues.name = name;
		if (fieldValues) {
			registerValue.saveUser(fieldValues).then(response => {
				if (response && response.Registration !== null && response.Registration.Status === 402) {
					const responseData = response.Registration.Errors;
					const [{ ErrorMessage }] = responseData;
					setErrDialogOpen(true);
					setResponseErr(ErrorMessage);
				} else if (response && response.Registration !== null && response.Registration.Status === 400) {
					const responseData = response.Registration.Errors;
					const [{ ErrorMessage }] = responseData;
					setFieldErr(ErrorMessage);
					if (emailRef.current) {
						emailRef.current.focus();
					}
				} else if (response && response.Registration == null) {
					setDialogOpen(true);
				} else if (response && response.Registration.Status == 200) {
					setDialogOpen(true);
				}
			});
		}
	};

	const handleClose = (isRegistered?: boolean) => {
		setDialogOpen(false);
		setErrDialogOpen(false);
		isRegistered && routes.navigate({ name: 'root' });
	};

	const handleInputChange = (event: React.ChangeEvent<any>) => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
		setIsFormValid(false);
		if (
			!errors.email &&
			!errors.name &&
			!errors.telephone &&
			values.email.length > 0 &&
			values.name.length > 0 &&
			values.telephone.length > 0
			// && appstores.captchaval==false
		) {
			setIsFormValid(true);
		}
	};

	return (
		<>
			<Grid item xs={12}>
				{routeName === 'root' ? <UserLogin /> : ''}
				<Card className={classes.root}>
					<CardContent>
						<Typography gutterBottom variant="h5" align="left" component="h2" className={classes.title}>
							Register an account
						</Typography>
						<Typography variant="body2" align="left" component="p" className={classes.subtitle}>
							Enter your email address, full name and business
						</Typography>
						<Typography variant="body2" align="left" component="p" className={classes.subtitle}>
							phone number.
						</Typography>
						<TextField
							id="filled-email-input-required"
							label="Email"
							type="text"
							name="email"
							autoComplete="email"
							variant="filled"
							margin="normal"
							onChange={handleInputChange}
							error={errors.email ? true : false}
							helperText={errors.email ? errors.email.message : ''}
							FormHelperTextProps={{
								classes: {
									root: classes.errorInputText,
								},
							}}
							inputRef={e => {
								register(e);
								emailRef.current = e;
							}}
							style={{ width: '280px' }}
						/>
						<Tooltip
							title={<div className={classes.tooltip}>Enter your company domain email address</div>}
							placement="bottom"
						>
							<HelpIcon className={classes.helpIcon} />
						</Tooltip>
						{fieldErr ? (
							<FormHelperText error={errors.email && fieldErr} className={classes.helper}>
								{fieldErr}
							</FormHelperText>
						) : (
							''
						)}
						<TextField
							id="filled-name-input-required"
							label="Full name"
							type="text"
							name="name"
							autoComplete="name"
							variant="filled"
							onChange={handleInputChange}
							margin="normal"
							error={errors.name ? true : false}
							helperText={errors.name ? errors.name.message : ''}
							FormHelperTextProps={{ className: classes.errorInputText }}
							inputRef={e => {
								register(e);
								nameRef.current = e;
							}}
							style={{ width: '280px' }}
						/>
						<Tooltip
							title={<div className={classes.tooltip}>Enter your full name e.g,John Smith</div>}
							placement="bottom"
						>
							<HelpIcon className={classes.helpIcon} />
						</Tooltip>
						<TextField
							id="filled-telephone-input-required"
							label="Telephone"
							type="text"
							name="telephone"
							autoComplete="Telephone"
							variant="filled"
							onChange={handleInputChange}
							margin="normal"
							error={errors.telephone ? true : false}
							helperText={errors.telephone ? errors.telephone.message : ''}
							FormHelperTextProps={{ className: classes.errorInputText }}
							inputRef={e => {
								register(e);
								telephoneRef.current = e;
							}}
							style={{ width: '280px' }}
						/>
						<Tooltip
							title={<div className={classes.tooltip}>Enter your business number including +44 etc, dialing code</div>}
							placement="bottom"
						>
							<HelpIcon className={classes.helpIcon} />
						</Tooltip>
						<Captcha />
						<CardActions style={{ width: '50%', float: 'right' }}>
							<Button
								type="button"
								size="small"
								color="primary"
								className={classes.buttonStyle}
								onClick={() => {
									routes.navigate({ name: 'root' });
									appstores.captchaerr = false;
								}}
							>
								Back
							</Button>
							<Button
								type="submit"
								size="small"
								color="primary"
								disabled={isFormValid && appstores.captchaval == true ? false : true}
								className={!isFormValid || appstores.captchaval == false ? classes.disableLink : classes.submitBtn}
								onClick={onSubmit}
							>
								Register
							</Button>
						</CardActions>
					</CardContent>
				</Card>
				<Dialog
					open={dialogOpen}
					onClose={() => handleClose()}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<Typography variant="h5" component="h3" className={classes.text}>
						Thank you for registering your account
					</Typography>
					<Typography component="p" className={classes.subtext}>
						Please check your email for a link to verify your account. You will then be able to login to Aria.
					</Typography>
					<DialogActions>
						<Button onClick={() => handleClose(true)} color="primary" className={classes.buttonDialog}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={errDialogOpen}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<Typography component="p" className={classes.subtext}>
						{responseErr}
					</Typography>
					<DialogActions>
						<Button onClick={() => handleClose(false)} color="primary" className={classes.buttonDialog}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</>
	);
});

export default RegisterForm;
