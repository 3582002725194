import * as React from 'react';
import {
	Box,
	Hidden,
	Dialog,
	DialogTitle,
	Button,
	DialogContentText,
	DialogActions,
	DialogContent,
	Typography,
	Divider,
	Link,
} from '@material-ui/core';
import UserAccountIcon from './UserAccountIcon';
import { stores } from '../../store';
import { observer } from 'mobx-react-lite';
import useStyles from '../../assets/css/HeaderNavCss';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const HeaderNav = observer(() => {
	const routes = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);
	const floordata = user.floorsingledata;
	const classes = useStyles();
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const [esmeDialogOpenSpaces, setEsmeDialogOpenSpaces] = React.useState(false);
	const [moduleName, setModuleName] = React.useState('');
	const handleClickClose = () => {
		setEsmeDialogOpen(false);
		setEsmeDialogOpenSpaces(false);
	};
	React.useEffect(() => {
		const refuelRoutesList: any = ['editMenus', 'refuelList', 'copyMenus', 'refuel', 'addMenus'];
		const askRoutesList: any = [
			'ask',
			'chatbot',
			'jobRequestForm',
			'dynamicform',
			'viewMyJobs',
			'sentItems',
			'feedback',
		];
		const feedRouteList: any = [
			'feed',
			'announcements',
			'announcementsEditor',
			'delivery',
			'evacuation',
			'evacuationEditor',
			'incident',
			'promotion',
			'promotionEditor',
			'survey',
			'surveyEditor',
			'surveyReport',
			'visitor',
			'archive',
		];
		const spacesRouteList: any = [
			'floorplanUploader',
			'floorplanEditor',
			'editSiteInfo',
			'spaces',
			'spacesHome',
			'siteInfo',
			'myBookings',
			'addAttendees',
			'roomInfo',
			'deskInfo',
			'bookingsView',
		];
		if (refuelRoutesList.includes(routes.routeName)) {
			setModuleName('Refuel');
		} else if (askRoutesList.includes(routes.routeName)) {
			setModuleName('Ask');
		} else if (feedRouteList.includes(routes.routeName)) {
			setModuleName('Feed');
		} else if (spacesRouteList.includes(routes.routeName)) {
			setModuleName('Spaces');
		} else if (routes.routeName) setModuleName(routes.routeName);
	}, [routes.routeName]);
	const handleCloseChat = () => {
		routes.testval = false;
		setEsmeDialogOpen(false);
		routes.navigate(
			{ name: 'rotName' },
			{
				clearParams: true,
			},
		);
	};
	const handleCloseChatSpaces = () => {
		routes.testval = false;
		setEsmeDialogOpenSpaces(false);
		if (floordata) {
			routes.navigate(
				{
					name: 'spacesHome',
					params: {
						clientId: floordata.clientId,
						floorId: floordata.floorId,
					},
				},
				{
					clearParams: true,
				},
			);
		} else {
			routes.navigate(
				{
					name: 'spacesHome',
					params: {
						clientId: 'NA',
						floorId: 'NA',
					},
				},
				{
					clearParams: true,
				},
			);
		}
	};
	const helpURL = process.env.REACT_APP_HELP_URL;
	return (
		<>
			<Box className={classes.headerNavBox}>
				<Typography variant="body1" className={classes.headerBreadcrm}>
					{moduleName}
				</Typography>
				<Divider className={classes.divider} orientation="vertical" variant="middle" />
				<Link href={helpURL} target="_blank" underline="none" className={classes.helpLink}>
					<HelpOutlineOutlinedIcon className={classes.helpIcon} />
					<Typography className={classes.helpText} variant="body1">
						{'Help'}
					</Typography>
				</Link>
				<Hidden xsDown>
					<div className={classes.userAccountIcon}>
						<UserAccountIcon />
					</div>
				</Hidden>
			</Box>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChat} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClose} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpenSpaces} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChatSpaces} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClose} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default HeaderNav;
