import React, { useState, useEffect } from 'react';
import { displayDayTime } from '../../util';
import { observer, useLocalStore } from 'mobx-react-lite';
import { stores } from '../../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import classnames from 'classnames';
import { faPlus, faTrash, faEdit, faCopy } from '@fortawesome/pro-light-svg-icons';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import useStyles from '../../assets/css/ListTheme';
import Tooltip from '@material-ui/core/Tooltip';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Grid,
	Dialog,
	DialogActions,
	TablePagination,
	TableFooter,
	Paper,
	Fab,
	IconButton,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { IEvacuation, Category, ISiteObject } from '../../types';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IFeedListOptions {
	Category?: number;
	PageCount?: number;
	PageSize?: number;
	Sort?: number;
	Order?: string;
	SearchText?: string;
	TotalCount?: number;
}

const EvacuationList = observer(() => {
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const notifications = React.useContext(stores.context.notifications);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const sites = React.useContext(stores.context.site);
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const rowsPerPageOptions = [2, 5, 10, 15];
	const [menus, setMenus] = useState<IEvacuation[]>([]);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [deleteId, setDeleteID] = React.useState(Number);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	//console.log('cnt',df.length,sites.siteList.length);

	// if(df.length!== sites.siteList.length)
	// {
	// 	console.log('change detected');

	// }
	//appStores.showAppLoader = user.permissions === null ? true : false;
	const [showEvacuation, setShowEvacuation] = React.useState(0);
	appStores.showAppLoader = user.permissions === null ? true : false;
	let feedListOptions: IFeedListOptions = useLocalStore(() => ({
		Category: Category.EvacuationTest,
		PageCount: 10,
		PageSize: 0,
		Sort: 0,
		Order: 'ModifiedTime',
		SearchText: '',
		TotalCount: 0,
	}));

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		feedListOptions.PageSize = newPage;
		fetchContentEvacuation();
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		feedListOptions.PageCount = parseInt(event.target.value, 10);
		feedListOptions.PageSize = 0;
		fetchContentEvacuation();
	};
	const handleClick = (header: string) => {
		if (feedListOptions.Sort === 1) {
			feedListOptions.Sort = 0;
		} else {
			feedListOptions.Sort = 1;
		}
		feedListOptions.Order = header;
		fetchContentEvacuation();
	};
	const handleClickOpen = (Id: number) => {
		setDialogOpen(true);
		setDeleteID(Id);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	const fetchContentEvacuation = async () => {
		appStores.showAppLoader = true;
		const results = await contentManagement.fetchContentEvacuation(feedListOptions);

		setMenus(toJS(results));
		appStores.showAppLoader = false;
	};

	const deleteItem = async (menuId: any) => {
		if (menuId !== '') {
			const response = await contentManagement.deleteEvacuation([menuId]);
			if (response) {
				notifications.addNotification('Evacuation deleted', 'delete');
				await fetchContentEvacuation();
				setDialogOpen(false);
			} else {
				notifications.addNotification(response + 'Something went wrong, Please try again', 'error');
			}
		}
	};

	localStorage.removeItem('URL');
	localStorage.removeItem('CMValue');

	React.useEffect(() => {
		if (user.permissions) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowEvacuation(1);
				}
			} else {
				setShowEvacuation(0);
			}
		} else {
			setShowEvacuation(0);
		}
	}, [user.permissions]);
	useEffect(() => {
		const fetchMenus = async () => {
			await fetchContentEvacuation();
		};
		fetchMenus();
	}, []);

	const getFeedCount = () => {
		feedListOptions.TotalCount = contentManagement.contentRecords;
		return feedListOptions.TotalCount || 0;
	};

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div>
					{showEvacuation === 1 && appStores.showAppLoader === false ? (
						<Paper className={classes.paper}>
							<Grid container>
								<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
									<Typography variant="h5">Evacuation Test</Typography>
								</Grid>
								<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
									<Fab
										color="primary"
										aria-label="add"
										className={classes.addIcon}
										onClick={() => {
											routes.navigate(
												{ name: 'evacuationEditor' },
												{
													clearParams: true,
												},
											);
										}}
									>
										<Icon icon={faPlus} />
									</Fab>
								</Grid>
							</Grid>
							{menus.length === 0 ? (
								<Grid container>
									<Grid item xs={12} className={classes.noResults}>
										<Typography variant="h6">We couldn't find Evacuation Test List </Typography>
										<Typography className={classes.textMargin}>
											Please make evacuation test from Add button on top.
										</Typography>
									</Grid>
								</Grid>
							) : (
								<Grid container>
									<Grid item xs={12}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<div className={classes.tableHeaders}>
															Sites
															{/* <Icon icon={faSort} 
														onClick={() => {
															if (user.evacuationlists.reverse()) {
																
															}
														}}/> */}
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Title{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('Title');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Occurs{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('StartTime');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Repeat{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('repeat');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Status{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('Status');
																}}
															/>
														</div>
													</TableCell>
													<TableCell align="center">Actions</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{menus.map((list: IEvacuation) => {
													const siteName = df.findIndex((nam: any) => nam.Id == list.Parameters.SiteId);
													return (
														<TableRow key={list.Id} hover={true} className="dataRow">
															<TableCell>{siteName != -1 ? df[siteName].Name : ''}</TableCell>
															<TableCell>{list.Title}</TableCell>
															<TableCell>{displayDayTime(list.StartTime)}</TableCell>
															<TableCell>
																{list.Parameters.RepeatFrequency === 5
																	? 'Every Year'
																	: list.Parameters.RepeatFrequency === 4
																	? 'Every Month'
																	: list.Parameters.RepeatFrequency === 3
																	? 'Every 2 weeks'
																	: list.Parameters.RepeatFrequency === 2
																	? 'Every week'
																	: list.Parameters.RepeatFrequency === 1
																	? 'EveryDay'
																	: 'Never'}
															</TableCell>
															<TableCell>{list.Status}</TableCell>
															<TableCell padding="none">
																<div className={classes.actionIcons}>
																	<Tooltip title="Make Copy">
																		<IconButton
																			aria-label="Make Copy"
																			color="inherit"
																			size="small"
																			className={classnames(classes.actionIconButton)}
																			onClick={() => {
																				if (list.Id) {
																					routes.navigate(
																						{
																							name: 'evacuationEditor',
																							params: {
																								SiteId: user.currentSiteId,
																								Title: list.Title,
																								EvacuationType: list.Parameters.EvacuationType,
																								RepeatFrequency: list.Parameters.RepeatFrequency,
																								Category: Category.EvacuationTest,
																								StartTime: list.Parameters.StartTime,
																								RepeatUntil: list.Parameters.RepeatUntil,
																								Heading: 'Edit Copy',
																							},
																						},
																						{
																							clearParams: true,
																						},
																					);
																				}
																			}}
																		>
																			<Icon icon={faCopy} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Edit">
																		<IconButton
																			aria-label="Edit"
																			color="inherit"
																			size="small"
																			className={classes.actionIconButton}
																			onClick={() => {
																				if (list.Id) {
																					routes.navigate(
																						{
																							name: 'evacuationEditor',
																							params: {
																								Id: list.Id,
																								SiteId: user.currentSiteId,
																								Title: list.Title,
																								EvacuationType: list.Parameters.EvacuationType,
																								RepeatFrequency: list.Parameters.RepeatFrequency,
																								Category: Category.EvacuationTest,
																								StartTime: list.Parameters.StartTime,
																								RepeatUntil: list.Parameters.RepeatUntil,
																								Heading: 'Edit',
																								status: list.Status,
																							},
																						},
																						{
																							clearParams: true,
																						},
																					);
																				}
																			}}
																		>
																			<Icon icon={faEdit} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Delete">
																		<IconButton
																			aria-label="Delete"
																			color="inherit"
																			size="small"
																			onClick={() => {
																				handleClickOpen(list.Id);
																			}}
																			className={classes.actionIconButton}
																		>
																			<Icon icon={faTrash} />
																		</IconButton>
																	</Tooltip>
																</div>
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
											<TableFooter>
												<TableRow>
													<TablePagination
														rowsPerPageOptions={rowsPerPageOptions}
														colSpan={7}
														count={getFeedCount() > 0 ? getFeedCount() : 0}
														rowsPerPage={feedListOptions.PageCount ? feedListOptions.PageCount : 5}
														page={feedListOptions.PageSize ? feedListOptions.PageSize : 0}
														SelectProps={{
															inputProps: { 'aria-label': 'Rows per page' },
															native: true,
														}}
														onChangePage={handleChangePage}
														onChangeRowsPerPage={handleChangeRowsPerPage}
													/>
												</TableRow>
											</TableFooter>
										</Table>
									</Grid>
								</Grid>
							)}
						</Paper>
					) : showEvacuation === 0 && appStores.showAppLoader === false ? (
						<>
							<img src={accessDeniedIcon} className={classes.accessDimensionsForm} alt="" />
							<div className={classes.textMargin}>
								<Typography variant="subtitle2">You do not have permission to access this area</Typography>
								<Typography variant="caption">Please contact your manager to request this access</Typography>
							</div>
						</>
					) : (
						''
					)}
				</div>
				<Dialog
					open={dialogOpen}
					onClose={() => handleClose()}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete <br /> this item?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={() => {
								deleteItem(deleteId);
							}}
						>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</main>
		</>
	);
});

export default EvacuationList;
