import { ICanvasFloorplanDetail } from '../../types';
const zoomControl = {
	mouseDown(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		const delta = floorplanDetail.controlType === 'zoomIn' ? 100 : -100;
		this.zoomControl(canvas, options, delta);
	},
	mouseUp(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
	mouseMove(canvas: fabric.Canvas, options: fabric.IEvent) {},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		const evt = options.e as any;
		const delta = evt.deltaY * -1;

		if (
			(floorplanDetail.controlType === 'zoomIn' && delta >= 0) ||
			(floorplanDetail.controlType === 'zoomOut' && delta < 0)
		) {
			this.zoomControl(canvas, options, delta);
		} else {
			evt.preventDefault();
			evt.stopPropagation();
		}
	},
	zoomControl(canvas: fabric.Canvas, options: fabric.IEvent, delta: number) {
		const evt = options.e as any;
		let zoom = canvas.getZoom();
		zoom = zoom + delta / 200;
		if (zoom > 20) zoom = 20;
		if (zoom < 1) zoom = 1;
		const offsetX = evt.touches ? evt.touches.item(0).offsetX : evt.offsetX;
		const offsetY = evt.touches ? evt.touches.item(0).offsetY : evt.offsetY;
		canvas.zoomToPoint({ x: offsetX, y: offsetY } as fabric.Point, zoom);
		evt.preventDefault();
		evt.stopPropagation();
		const vpt = canvas.viewportTransform!;
		if (zoom < 400 / 1000) {
			if (canvas.viewportTransform) {
				canvas.viewportTransform[4] = 200 - (1000 * zoom) / 2;
				canvas.viewportTransform[5] = 200 - (1000 * zoom) / 2;
			}
		} else {
			if (vpt[4] >= 0) {
				if (canvas.viewportTransform) {
					canvas.viewportTransform[4] = 0;
				}
			} else if (vpt[4] < canvas.getWidth() - 1000 * zoom) {
				if (canvas.viewportTransform) {
					canvas.viewportTransform[4] = canvas.getWidth() - 1000 * zoom;
				}
			}
			if (vpt[5] >= 0) {
				if (canvas.viewportTransform) {
					canvas.viewportTransform[5] = 0;
				}
			} else if (vpt[5] < canvas.getHeight() - 1000 * zoom) {
				if (canvas.viewportTransform) {
					canvas.viewportTransform[5] = canvas.getHeight() - 1000 * zoom;
				}
			}
		}
	},
};

export default {
	...zoomControl,
};
