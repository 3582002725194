import { action, observable } from 'mobx';
import { getAPI, postAPI } from 'services/apiService';
import { IFeedItem, IFeedCategory } from '../types';

interface IFeedUpdate {
	feedId: string;
	ButtonName: string;
	State: any;
}

class Feed {
	@observable
	public loading = false;
	@observable
	public feedItems: IFeedItem[] = [];
	@observable
	public feedCategories: IFeedCategory[] = [];
	@observable
	public preferences: any = [];
	@observable
	public ff: any = [];
	@observable
	public newNotifications: any = [];
	@observable
	public notificationCategory: any = [
		{ id: 6, title: 'Announcement' },
		{ id: 1, title: 'Evacuation Test' },
		{ id: 0, title: 'Incident' },
		{ id: 3, title: 'ParcelDelivery' },
		{ id: 4, title: 'Promotions' },
		{ id: 7, title: 'Survey' },
		{ id: 2, title: 'Visitor Booking' },
		{ id: 8, title: 'Job Status' },
		{ id: 9, title: 'Quote Approval' },
		{ id: 10, title: 'Uplift Approval' },
	];
	@observable
	public surveyList: any = [];
	@observable
	public mainFeedItems: any = [];
	@observable
	public mainFeedItemsCount: number = 0;
	@observable
	public statusFeedItemsCount: number = 0;
	@observable
	public archiveFeedItemsCount: number = 0;
	@observable
	public archiveFeedItems: any = [];
	@observable
	public statusFeedItems: any = [];
	@observable
	public surveyTypes = {
		'3D1F18FD-1E50-4EFA-8B22-B6545BE94172': 'Star Survey',
		'A1D0706F-8E7B-4686-A874-5A238F7F3C3E': 'NPS Survey',
	};

	constructor() {
		this.fetchFeedCategories();
		this.getMainFeeds(1);
		this.getStatusFeeds(1);
		setInterval(() => {
			var feedAutoRefresh = this.feedItems.some(e => e.showOpenCard === true);
			if (!feedAutoRefresh) {
			}
		}, Number(process.env.REACT_APP_FEED_INTERVAL || 20000));
	}

	@action
	public async getMainFeeds(pageNo: Number) {
		let mainFeedItems = await getAPI(`/feed/mainfeed/${pageNo}`);
		mainFeedItems.Result.sort((a: IFeedItem, b: IFeedItem) => {
			if (a.StartTime > b.StartTime) return -1;
			if (a.StartTime < b.StartTime) return 1;
			return 0;
		});
		this.mainFeedItems = mainFeedItems;
		this.mainFeedItemsCount = mainFeedItems.Pagination && mainFeedItems.Pagination.TotalFeedCount;
		this.loading = false;
		return this.mainFeedItems;
	}
	@action
	public async getStatusFeeds(pageNo: Number) {
		let statusFeedItems = await getAPI(`/feed/statusfeed/${pageNo}`);
		statusFeedItems.Result.sort((a: IFeedItem, b: IFeedItem) => {
			if (a.StartTime > b.StartTime) return -1;
			if (a.StartTime < b.StartTime) return 1;
			return 0;
		});
		this.statusFeedItems = statusFeedItems;
		this.statusFeedItemsCount = statusFeedItems.Pagination && statusFeedItems.Pagination.TotalFeedCount;
		this.loading = false;
		return this.statusFeedItems;
	}
	@action
	public async getArchiveFeeds(pageNo: Number) {
		let archiveFeedItems = await getAPI(`/feed/archivefeed/${pageNo}`);
		archiveFeedItems.Result.sort((a: IFeedItem, b: IFeedItem) => {
			if (a.StartTime > b.StartTime) return -1;
			if (a.StartTime < b.StartTime) return 1;
			return 0;
		});
		this.archiveFeedItems = archiveFeedItems;
		this.archiveFeedItemsCount = archiveFeedItems.Pagination && archiveFeedItems.Pagination.TotalFeedCount;
		this.loading = false;
		return this.archiveFeedItems;
	}

	@action
	public async fetchFeedCategories() {
		this.feedCategories = await getAPI('/feed/categories');
		this.feedCategories.push({ id: '5', title: 'RoomBooking' });
		this.feedCategories.push({ id: '8', title: 'JobStatusChange' });
		this.feedCategories.push({ id: '10', title: 'UpliftApproval' });
		this.feedCategories.push({ id: '9', title: 'QuoteApproval' });
		this.feedCategories.push({ id: '11', title: 'ClosedSites' });
	}

	@action
	public async fetchPreferences() {
		this.preferences = await getAPI('/user/preferences');
		this.ff = this.preferences.Feed.filter((fh: any) => fh.PushEnabled === false);
		this.newNotifications = this.notificationCategory.filter((n: any) => this.ff.some((m: any) => n.title === m.Name));
	}

	@action
	public async fetchSurveyList() {
		let surveyList = await getAPI('/feed/surveylist');
		surveyList.sort((a: IFeedItem, b: IFeedItem) => {
			if (a.Id > b.Id) return -1;
			if (a.Id < b.Id) return 1;
			return 0;
		});
		this.surveyList = surveyList;
	}
	@action
	public async updateFeedStatus(data: IFeedUpdate) {
		const feedUpdated = await postAPI(`/feed/updateStatus`, data);
		const fIndex = this.feedItems.findIndex(f => Number(f.Id) === Number(data.feedId));
		this.feedItems[fIndex] = { ...feedUpdated, showOpenCard: false };
		this.mainFeedItems = this.mainFeedItems;
		if (feedUpdated.Errors && feedUpdated.Errors.toString().includes('Maximo is down, please try later.')) {
			return 'Maximo is down, please try later.';
		}
		return;
	}
	@action
	public async getSharedURL(Id: number) {
		return await postAPI(`/feed/share/${Id}`, {});
	}
	@action
	public async getsharedFeed(id: string) {
		return await getAPI(`/feed/${id}`);
	}
	@action
	public async postFeedLike(data: any) {
		return await postAPI(`/feed/likeunlikepost`, data);
	}
}

export default Feed;
