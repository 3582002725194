import * as React from 'react';
import { useObserver, useLocalStore, observer } from 'mobx-react-lite';
import { stores } from '../store';
import FloorplanEditorCanvas from '../components/FloorplanEditor/Canvas';
import { Icon, Alert } from '@mitie/material-ui-extensions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ZoomInImg from '../assets/images/Zoom_In@2x.png';
import ZoomOutImg from '../assets/images/Zoom_Out@2x.png';
import CondecoLogo from '../assets/images/Condeco_Logo_Large.png';
import noResults from '../assets/images/Office_Web@2x.png';
import Metting_Room from '../assets/images/Metting_Room.png';
import Desk_room from '../assets/images/Desk_room.png';
import { WatchLater } from '@material-ui/icons';
import CalendarIcon from '../assets/images/CalendarIcon';
import parkingTile from '../assets/images/parkingbookingtile.png';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import underConstruction from '../assets/images/underConstructionNew.svg';
import Nofloors from '../assets/Empty_State@2x.png';
import Successfullsearch from '../assets/Successful_Search.png';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	MenuItem,
	makeStyles,
	Backdrop,
	CircularProgress,
	Typography,
	Grid,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	TextField,
	InputLabel,
	FormControl,
	Select,
	Box,
	IconButton,
	Divider,
	Link,
	Breadcrumbs,
} from '@material-ui/core';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { IFloorplanDetail, IObject, IEntitie, IFloorCoordinates, ICanvas } from '../types';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
//import BookingMenu from './BookingMenu';
import Moment from 'react-moment';

const equipmenttMap =
	process.env.REACT_APP_ENV == 'PROD'
		? {
				Display: '1',
				AudioConference: '3',
				VideoConference: '2',
				FlipChart: '18',
				Whiteboard: '16',
				Projector: '19',
		  }
		: {
				Display: '1',
				AudioConference: '3',
				VideoConference: '2',
				FlipChart: '11',
				Whiteboard: '13',
				Projector: '12',
		  };

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 		anchorOrigin: {
// 			vertical: 'bottom',
// 			horizontal: 'left',
// 		},

// 		getContentAnchorEl: null,
// 	},
// };

const useStyles = makeStyles(theme => ({
	disabledButton: {
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},

	//second form styles

	backbutton: {
		border: '1px solid #66CCFA',
		color: '#66CCFA',
		'&:hover': {
			border: '1px solid #66CCFA',
			color: '#ffffff',
			background: '#66CCFA',
		},
	},
	timebtn: {
		margin: '1px !important',
		width: '148px !important',
		boxShadow: 'none !important',
		border: '1px solid rgba(9, 139, 189, 0.5)',
		marging: '1px !important',
		borderRadius: '8px',
		minHeight: '48px',
		background: '#FFFFFF',
		color: '#14A0D6',
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: 'none !important',
			backgroundColor: '#CFDBE5 !important',
			minHeight: '48px',
			width: '148px !important',
		},
		'&:hover': {
			border: '3px solid #14a0d6',
			height: '48px',
			width: '148px !important',
			background: '#FFFFFF',
		},
		'&.selectedTime:hover': {
			border: '1px solid #66ccfa',
		},
		'&.selectedTime': {
			border: '1px solid rgb(102 204 250)',
		},
	},

	timebtnnew: {
		margin: '1px !important',
		width: '148px !important',
		boxShadow: 'none !important',
		minHeight: '48px',
		marging: '1px !important',
		background: '#66CCFA',
		borderRadius: '8px',
		color: '#FFFFFF',
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
			minHeight: '48px',
			width: '148px !important',
		},
		'&:hover': {
			height: '48px',
			width: '148px !important',
			background: '#66ccfa',
		},
	},
	gridStyle: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0px !important',
		gap: '5px',
		height: '41vh',
		overflowY: 'auto',
		padding: '5px 10px',
		background: '#F4F8FC !important',
		'&::-webkit-scrollbar-thumb': {
			background: '#66CCFA !important',
			height: '220px',
		},
	},

	iconOpen: {
		transform: 'rotate(0deg)',
		color: 'red',
	},
	// selectItemHover: {
	// 	'& .MuiListItem-button:hover': {
	// 		backgroundColor: '#098BBD0A !important',
	// 	},
	// 	'& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
	// 		backgroundColor: '#098bbd3d !important',
	// 	},
	// },

	//second  form styles ends

	root: {
		backgroundColor: theme.palette.background.default,
		textAlign: 'left',
		overFlow: 'hidden',
		'& .MuiContainer-root': {
			margin: theme.spacing(0),
			padding: theme.spacing(0),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},

	headerNavBox: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	selectItemHover: {
		'& .MuiListItem-button:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
			backgroundColor: '#098bbd3d !important',
		},
	},
	placeholderItem: {
		'& .MuiInputBase-input': {
			color: '#8B8DAE !important',
		},
	},
	gridPadding: {
		'& .MuiFormControl-root': {
			minWidth: '350px',
			minHeight: '48px',
			marginTop: '-22px',
		},

		'& .MuiFormControl-root:hover': {
			minWidth: '350px',
			minHeight: '48px',
			marginTop: '-22px',
			borderRadius: '10px !important',
		},
		'& .MuiInput-underline:before,& .MuiInput-underline:after': {
			display: 'none',
		},
		'& .MuiGrid-item': {
			float: 'none !important',
			width: '171px',
		},
	},
	gridPaddingForSelectDate: {
		'& .MuiFormControl-root': {
			minWidth: '171px',
			minHeight: '48px',
			marginTop: '-25px',
		},

		'& .MuiFormControl-root:hover': {
			width: '100%',
			borderRadius: '10px !important',
		},
		'& .MuiInput-underline:before,& .MuiInput-underline:after': {
			display: 'none',
		},
		'& .MuiGrid-item': {
			float: 'none !important',
		},
	},
	tabPanel: {
		'& .MuiBox-root': {
			paddingLeft: '27px !important',
			paddingTop: '31px',
		},
	},
	selectFloorBox: {
		'& .MuiInput-underline:before,& .MuiInput-underline:after': {
			display: 'none',
		},
	},
	imageIcon: {
		height: '100%',
	},
	iconRoot: {
		textAlign: 'center',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 223,
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		marginBottom: '8px',
		borderRadius: '4px',
		'&:hover': { background: `#e9f5f8 !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '7px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '15px',
			paddingRight: '10px !important',
		},
		background: '#f9fbfd',
	},
	floorFormControl: {
		margin: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		marginLeft: '27px',
		[theme.breakpoints.between('lg', 'xl')]: {
			marginLeft: '12px',
		},
		marginBottom: '8px',

		border: '1px solid #8b8dae',
		borderRadius: '10px',

		'&:hover': { background: `none !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '6px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '6px',
		},
	},

	filtelayout: {
		paddingLeft: '10px',
		display: 'none',
		'& .PrivateSwitchBase-root-487': {
			padding: '4px',
		},

		'@media (min-width: 1200px) and (max-width: 1250px) and (min-height:500px) and (max-height:550px)': {
			height: '131px !important',
			overflow: 'auto',
		},
		'@media (min-width: 1200px) and (max-width: 1250px)': {
			height: '58px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1280px) and (max-width: 1340px) and (min-height:600px) and (max-height:660px)': {
			height: '154px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1280px) and (max-width: 1340px) and (min-height:790px) and (max-height:1000px)': {
			height: '264px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1350px) and (max-width: 1380px) and (min-height:500px) and (max-height:649px)': {
			height: '105px !important',
			overflow: 'auto',
			marginLeft: '-9px !important',
		},

		'@media (min-width: 1350px) and (max-width: 1380px) and (min-height:650px)': {
			height: '190px !important',
			overflow: 'auto',
			marginLeft: '-9px !important',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:610px) and (max-height:660px)': {
			height: '184px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:670px) and (max-height:690px)': {
			height: '212px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:691px) and (max-height:789px)': {
			height: '232px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1401px) and (max-width: 1499px) and (min-height:790px) and (max-height:1200px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1350px) and (min-height:600px) and (max-height:650px) and (max-width: 1399px)': {
			height: '140px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 600px) and (max-height: 699px) and (max-width:1599px)': {
			height: '162px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 450px) and (max-height: 600px) and (max-width:1599px)': {
			height: '200px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 700px) and (max-width:1599px)': {
			height: '254px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1600px) and (min-height: 700px) and (max-width:1699px)': {
			height: '255px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1700px) and (min-height: 700px) and (max-height:799px)': {
			height: '235px !important',
			overflow: 'auto',
			marginLeft: '-5px !important',
		},

		'@media (min-width: 1700px) and (min-height: 400px) and (max-height: 699px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1700px) and (min-height: 800px) and (max-height:850px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1601px) and (max-width: 1699px)': {
			height: '200px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1601px) and (max-width: 1699px) and (min-height: 1000px) and (max-height:1200px)': {
			height: '288px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1801px) and (max-width: 1850px)': {
			height: '266px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1851px) and (max-width: 2000px)': {
			height: '290px !important',
			overflow: 'auto',
		},
		'@media (min-width: 2001px) and (max-width: 2200px)': {
			height: '300px !important',
			overflow: 'auto',
		},
		'@media (min-width: 2700px) and (max-width: 3000px)': {
			height: '400px !important',
			overflow: 'auto',
		},
	},

	stickyresponsive: {
		'@media (min-width: 1800px) and (max-width: 2050px)': {
			height: '61px !important',
		},
		'@media (min-width: 2070px) and (max-width: 4000px)': {
			height: '64px !important',
		},
		'@media (min-width: 1420px) and (max-width: 1799px)  ': {
			height: '57px !important',
		},
		'@media (min-width: 1350px) and (max-width: 1419px)  ': {
			height: '54px !important',
		},
		'@media (min-width: 1200px) and (max-width: 1349px)  ': {
			height: '52px !important',
		},
		'@media(min-width:1000px) and (max-width: 1750px)': {
			overflow: 'inherit !important',
		},
	},

	stickyresponsiveright: {
		'@media (min-width: 1350px) and (max-width: 1419px) and (min-height:750px) and (max-height:1100px)': {
			height: '64px !important',
		},

		'@media (min-width: 1200px) and (max-width: 1349px) and (min-height:750px) and (max-height:1100px) ': {
			height: '62px !important',
		},
	},
	overflowX: {
		'@media(max-width: 1750px)': {
			overflow: 'auto',
		},
	},
	floorMargin: {
		'@media(max-width: 1500px)': {
			marginTop: '45px !important',
		},
	},
	formControlnew: {
		margin: theme.spacing(1),
		minWidth: 223,
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		marginBottom: '8px',
		borderRadius: '4px',
		'&:hover': { background: `#e9f5f8 !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '6px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '15px',
		},
		'& .MuiInputLabel-formControl': {
			transform: 'translate(-5px, -19px) scale(0.8) !important',
		},
	},
	floorFormControlnew: {
		margin: theme.spacing(1),
		minWidth: 292,
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		[theme.breakpoints.up('xl')]: {
			minWidth: 353,
		},
		marginBottom: '8px',
		borderRadius: '10px',
		marginLeft: '27px',
		border: '1px solid #8b8dae',
		'&:hover': { background: `#e9f5f8 !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '6px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '15px',
		},

		'& .MuiInputLabel-formControl': {
			transform: 'translate(-5px, -19px) scale(0.8) !important',
		},
	},

	formControlLenEmpty: {
		'& .MuiInputLabel-formControl': {
			transform: 'translate(-5px, -19px) scale(0.8) !important',
		},
	},
	floorRoomNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {
			float: 'right',
		},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '142px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	headerNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '129px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	alignStyle: {
		padding: '0px 0px !important',
	},
	headerMenuLinkActive: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		fontWeight: 500,
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
	},

	headerSpacesAndDesksLinkActive: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '70%',
		fontSize: '1rem',
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: '#002855',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
			fontWeight: 'bold',
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	spaceAndDeskheaders: {
		color: '#002855 !important',
		fontSize: '1rem',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
			fontWeight: 'bold',
		},
	},

	btngrpattende: {
		'&:hover': {
			backgroundColor: '#e9f5f8 !important',
		},
	},

	btngrpattendeplus: {
		'&:hover': {
			backgroundColor: '#e9f5f8 !important',
		},
	},

	selectFloor: {
		'& .MuiInput-underline:before': {
			display: 'none',
		},
	},

	pickerStyle: {
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiIconButton-root': {
			padding: '0 !important',
		},

		'& .MuiInputLabel-root': {
			display: 'none',
		},
		'& .MuiInputBase-input': {
			marginLeft: '9px !important',
		},
		'& .MuiInputBase-root': {
			border: '1px solid #8b8dae',
			borderRadius: '8px',
			marginRight: '1px',
			paddingRight: '10px',
			height: '48px',
			width: '171px',
		},
		'& .MuiInputAdornment-positionEnd': {
			marginLeft: '0 !important',
		},
		'& .MuiInput-underline:before': {
			display: 'none',
		},
	},

	meetingsubject: {
		'& .MuiInputBase-input': {
			paddingLeft: '6px !important',
		},
	},

	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,

			background: '#f9fbfd',
			marginLeft: '23px',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '86%',
			marginLeft: '23px',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-inputRoot:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiInputBase-input:hover': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},

		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
	},
	option: {
		'&[data-focus="true"]': {
			backgroundColor: '#f4f8fc',
		},

		'&[aria-selected="true"]': {
			backgroundColor: '#f4f8fc',
		},
	},

	dropBoxContainer: {
		background: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		width: '240px',
		padding: theme.spacing(2),
		display: 'none',
	},
	dropBoxContainerVisible: {
		display: 'block',
	},
	dropBoxContainerDisplay: {
		position: 'absolute',
		right: '16px',
		textTransform: 'capitalize',
	},
	dropBox: {
		height: '56px',
		width: '208px',
		margin: theme.spacing(1.5, 0),
		'& > label': {
			cursor: 'pointer',
		},
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	itemLengthLabel: {
		margin: theme.spacing(2, 0),
	},
	footerIcons: {
		borderRadius: theme.shape.borderRadius,
		height: '72px',
		width: '434px',
		flexWrap: 'nowrap',
		textAlign: 'center',
		overflow: 'auto',
		background: `${theme.palette.grey[50]} 0% 0% no-repeat padding-box`,
		boxShadow: `0px 3px 6px ${theme.palette.text.primary}52`,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0),
		},
	},
	iconBox: {
		cursor: 'pointer',
		color: theme.palette.text.secondary,
		'&:active, &:hover': {
			'& > * , & > * svg': {
				color: theme.palette.primary.main,
				fontWeight: 'bold',
			},
		},
	},
	TextField: {},
	iconBoxSelected: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		'& > * , & > * svg': {
			color: theme.palette.primary.main,
			fontWeight: 'bold',
		},
	},
	iconBoxDisabled: {
		color: theme.palette.grey[100],
		'& > * , & > * svg': {
			height: '30px',
			width: '30px',
			color: theme.palette.grey[100],
		},
	},
	iconsDivider: {
		margin: theme.spacing(1, 0.5),
		'& > *': {
			backgroundColor: theme.palette.text.secondary,
		},
	},
	footerIcon: {
		width: '48px',
		height: '48px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	footerIconLabel: {
		fontSize: '9px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '24px',
		width: 'auto',
	},
	footerBtn: {
		height: '36px',
		width: '140px',
		margin: theme.spacing(0, 1, 0, 0),
	},

	framecontainer: {
		width: '100%',
	},

	outlinedBtn: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '4px',
		padding: theme.spacing(0, 0.125),
	},
	footerTabs: {
		width: '195px',
		height: '36px',
		minHeight: '36px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '185px',
		},
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		'& .Mui-selected, & .Mui-selected:hover': {
			backgroundColor: theme.palette.primary.main,
			color: `${theme.palette.background.paper}`,
		},
		'& button:last-child': {
			borderRight: 'none',
		},
	},
	rootItem: {
		minWidth: '62px',
		minHeight: '36px',
		borderRight: `1px solid ${theme.palette.primary.main}`,
		[theme.breakpoints.down('xs')]: {
			width: '33.3%',
		},
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	indicator: {
		display: 'none',
	},
	zoomSize: {
		padding: '0px',
		margin: '0px',
	},

	canvasWidth: {
		'@media (min-width: 1700px) and (max-width:1800px)': {
			width: '117% !important',
		},
	},
	canvasscreen: {
		width: '100%',
		height: 'calc(100vh - 138px)',
		display: 'flex',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	mainDivi: {
		height: '0px',
	},
	canvasWrapperGridItem: {
		padding: '0.3rem 0.3rem 0 0.3rem',
		height: '100%',
	},

	canvasWrapperDrawerOpen: {
		width: 'calc(100% - 185px)',
		height: '432px',
	},
	statusMsg: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			backgroundColor: theme.palette.success.main,
			padding: theme.spacing(1),
		},
	},
	closeIcon: {
		'& > *': {
			color: theme.palette.background.paper,
		},
	},
	footerIconJustify: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'space-between',
		},
	},
	displayFixed: {
		position: 'fixed',
		bottom: '8px',
		width: 'auto',
		display: 'flex',
		zIndex: 'inherit',
	},
	paper: {
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		flexDirection: 'column',
		'& .MuiInputBase-input': {
			background: 'none',
		},
		'& .makeStyles-paper-51': {
			padding: '0px',
		},
		'& .MuiPaper-elevation4': {
			boxShadow: 'none',
		},
	},

	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 150px)',
		margin: '0 !important',
	},
	Dimensions: {
		display: 'inline-block',
		objectFit: 'contain',
		textAlign: 'center',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
		'@media (min-width:1280px)': {
			marginTop: '10px',
		},
		'@media (min-width:1454.55px)': {
			marginTop: '18px',
		},
		'@media (min-width:1600px)': {
			marginTop: '28px',
		},
		'@media (min-width:1777.73px)': {
			marginTop: '43px',
		},

		'@media (min-width:2000px)': {
			marginTop: '65px',
		},
		'@media  (min-width:2133.33px)': {
			marginTop: '80px',
		},
		'@media (min-width:2400px)': {
			marginTop: '110px',
		},
		'@media (min-width:3200px)': {
			marginTop: '190px',
		},
		'@media (min-width:4800px)': {
			marginTop: '345px',
		},
		'@media (min-width:6400px)': {
			marginTop: '500px',
		},
	},
	filterDimensions: {
		display: 'inline-block',
		objectFit: 'contain',
		textAlign: 'center',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
		'@media (min-width:1280px)': {
			position: 'relative',
			top: '20px',
		},
		'@media (min-width:1454.55px)': {
			position: 'relative',
			top: '30px',
		},
		'@media (min-width:1600px)': {
			position: 'relative',
			top: '80px',
		},

		'@media (min-width:1777.73px)': {
			position: 'relative',
			top: '80px',
		},

		'@media (min-width:2000px)': {
			position: 'relative',
			top: '130px',
		},
		'@media  (min-width:2133.33px)': {
			position: 'relative',
			top: '150px',
		},
		'@media (min-width:2400px)': {
			position: 'relative',
			top: '200px',
		},
		'@media (min-width:3200px)': {
			position: 'relative',
			top: '350px',
		},
		'@media (min-width:4800px)': {
			position: 'relative',
			top: '650px',
		},
		'@media (min-width:6400px)': {
			position: 'relative',
			top: '950px',
		},
	},

	textDimension3: {
		'@media (min-width:1280px)': {
			marginLeft: '26px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '20px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '3px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '2px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '0px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '0px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-30px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-100px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-180px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-300px',
		},
	},
	// tabpanel: {
	// 	'& .MuiBox-root': {
	// 		paddingLeft: '27px !important',
	// 	},
	// },
	textDimension4: {
		'@media (min-width:1280px)': {
			marginLeft: 'px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: 'px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '2px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '2px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '0px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '0px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-30px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-90px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-150px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-290px',
		},
	},
	textDimension1: {
		'@media (min-width:1280px)': {
			marginLeft: '35px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '20px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '16px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '12px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '1px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '-8px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-22px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-80px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-150px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-260px',
		},
	},
	textDimension2: {
		'@media (min-width:1280px)': {
			marginLeft: '50px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '40px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '35px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '35px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '23px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '17px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '4px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-70px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-120px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-270px',
		},
	},

	spcfixed: {
		right: 0,
		position: 'fixed',
		left: '260px',
		'@media (min-width: 0px) and (max-width: 699px)': {
			position: 'relative !important',
			left: '0px !important',
		},
	},
	accessDimensions: {
		width: '45%',
		height: '3% !important',
		margin: 'auto',
		display: 'block',
		'@media (min-width: 1260px) and (min-height: 590px) and (max-width:1359px) and (max-height:690px)': {
			width: '49% !important',
		},
		'@media (min-width: 1400px) and (min-height: 650px) and (max-width:1499px) and (max-height:750px)': {
			width: '48% !important',
		},
		'@media (min-width: 1600px) and (min-height: 740px) and (max-width:1699px) and (max-height:840px)': {
			width: '44% !important',
		},
		'@media (min-width: 1700px) and (min-height: 800px) and (max-width:1799px) and (max-height:900px)': {
			width: '48% !important',
		},
		'@media (min-width: 1900px) and (min-height: 900px) and (max-width:1999px) and (max-height:1000px)': {
			width: '53% !important',
		},
		'@media (min-width: 2540px) and (min-height: 1200px) and (max-width:2640px) and (max-height:1300px)': {
			width: '52% !important',
		},
	},
	accessDimensions4: {
		width: '35%',
		height: '3% !important',
		margin: 'auto',
		display: 'block',
		'@media (min-width: 1260px) and (min-height: 590px) and (max-width:1359px) and (max-height:690px)': {
			width: '49% !important',
		},
		'@media (min-width: 1400px) and (min-height: 650px) and (max-width:1499px) and (max-height:750px)': {
			width: '48% !important',
		},
		'@media (min-width: 1600px) and (min-height: 740px) and (max-width:1699px) and (max-height:840px)': {
			width: '44% !important',
		},
		'@media (min-width: 1700px) and (min-height: 800px) and (max-width:1799px) and (max-height:900px)': {
			width: '48% !important',
		},
		'@media (min-width: 1900px) and (min-height: 900px) and (max-width:1999px) and (max-height:1000px)': {
			width: '53% !important',
		},
		'@media (min-width: 2540px) and (min-height: 1200px) and (max-width:2640px) and (max-height:1300px)': {
			width: '52% !important',
		},
	},

	accessDimensions2: {
		width: '95%',
		height: '3%',
		margin: 'auto',
		display: 'block',
	},
	zoomButtons: {
		width: '95%',
		height: '3%',
		margin: 'auto',
		display: 'block',
	},

	tabs: {
		'& .MuiTab-root': {
			minWidth: '0px !important',
			minHeight: '52.6px!important',
			fontWeight: 'normal',
		},
		' & .MuiTab-textColorInherit': {
			opacity: '1 !important',
			color: '#002855',
		},
		'& .MuiTabs-fixed .MuiTabs-indicator': {
			display: 'none',
		},
		'& .MuiTabs-flexContainer': {
			flexWrap: 'wrap',
		},
	},
	PaymentCaptionnew: {
		textOverFlow: 'ellipsis',
		position: 'relative',
		top: '90px',
	},
	condecoImage: {
		width: '45%',
		margin: 'auto',
		marginTop: '70px',
	},
	spacesImg: {
		width: '174px',
		height: '154px',
		backgroundImage: `url(${Metting_Room})`,
		backgroundSize: 'cover',
		border: '1px solid #f0f0f0',
		backgroundPosition: 'center',
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '16px !important',
		paddingTop: '10px',
		display: 'flex',
		color: '#002855',
		justifyContent: 'center',
		alignItems: 'baseline',
		margin: '5px 13px',
		cursor: 'pointer',
		opacity: '1',
		'& .MuiButtonBase-root': {
			height: 'auto !important',
			width: 'auto',
			backgroundColor: '#FFFFFFCF',
			padding: '5px 10px',
			border: 'none',
			borderRadius: '10px !important',
			minHeight: '31px !important',
			minWidth: '128px !important',
		},
		'&:hover': {
			border: '1px solid #66ccfa',
			'& .MuiButtonBase-root': {
				fontWeight: 'bold',
			},
		},
		'@media (max-width:1500px)': {
			margin: '5px',
			height: '135px',
			'& .MuiTab-wrapper': {
				overflow: 'hidden',
				whiteSpace: 'inherit',
				textOverflow: 'ellipsis',
				display: 'block',
				width: '100px',
			},
		},
	},
	deskImg: {
		width: '174px',
		height: '154px',
		backgroundImage: `url(${Desk_room})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		boxShadow: ' 0px 3px 6px #00000029',
		borderRadius: '16px !important',
		paddingTop: '10px',
		display: 'flex',
		border: '1px solid #f0f0f0',
		justifyContent: 'center',
		margin: '5px 10px',
		alignItems: 'baseline',
		cursor: 'pointer',
		opacity: '1',
		'& .MuiButtonBase-root': {
			backgroundColor: '#FFFFFFCF',
			minHeight: '31px !important',
			maxHeight: '31px !important',
			padding: '5px 10px',
			border: 'none',
			borderRadius: '10px !important',
			minWidth: '42px',
			width: '60px !important',
		},
		'&:hover': {
			border: '1px solid #66ccfa',
			'& .MuiButtonBase-root': {
				fontWeight: 'bold',
			},
		},
		'@media (max-width:1500px)': {
			margin: '5px',
			height: '135px',
		},
		PlaceHolderClr: {
			'& .MuiSelect-root': {
				color: '#8B8DAE',
			},
		},
	},
	selectedTab: {
		'&:hover': {
			'& .MuiButtonBase-root': { fontWeight: 'bold !important' },
		},
		'& .MuiButtonBase-root': { fontWeight: 'bold !important' },
	},
	parkingImg: {
		width: '174px',
		height: '154px',
		backgroundImage: `url(${parkingTile})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		border: '1px solid #f0f0f0',
		borderRadius: '20px',
		paddingTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		margin: '5px 10px',
		alignItems: 'baseline',
		cursor: 'pointer',
		boxShadow: ' 0px 3px 6px #00000029',
		'& .MuiButtonBase-root': {
			height: '31px !important',
			auto: 'auto !important',
			backgroundColor: '#FFFFFFCF',
			minHeight: 'inherit !important',
			padding: '5px 10px',
			border: 'none',
			width: '74px !important',
			borderRadius: '10px !important',
		},
		'&:hover': {
			border: '1px solid #66ccfa',
			'& .MuiButtonBase-root': {
				fontWeight: 'bold',
			},
		},
		'@media (max-width:1500px)': {
			margin: '5px',
			height: '135px',
		},
		PlaceHolderClr: {
			'& .MuiSelect-root': {
				color: '#8B8DAE',
			},
		},
	},
	booknowButton: {
		marginLeft: '10px',
		background: '#66CCFA',
		color: '#ffffff',
		height: '36px',
		width: '94px',
		boxShadow: 'none',
		'&:hover': { background: '#098BBD', boxShadow: 'none' },
	},
	applyFilterButton: {
		marginLeft: '10px',
		background: '#66CCFA',
		color: '#ffffff',
		height: '36px',
		width: '104px',
		boxShadow: 'none',
		'&:hover': { background: '#098BBD', boxShadow: 'none' },
	},
	selctedTab: {
		border: '1px solid #002855',
	},
	'@global': {
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: '#66CCFA',
			height: '6px',
			widht: '6px',
		},
		'*::-webkit-scrollbar': {
			height: '6px',
			width: '6px',
			display: 'block',
		},
	},
}));
const Calendar = (props: any) => <CalendarIcon data={props} />;
const SpacesHome = observer(() => {
	const appStores = React.useContext(stores.context.appStores);
	const { route } = React.useContext(stores.context.routes);
	const notifications = React.useContext(stores.context.notifications);
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const [entityType, setEntityType] = React.useState<any>();
	const spaces = React.useContext(stores.context.spaces);
	const [zones, setZones] = React.useState([]);
	const [floorImgLoaded, setfloorImgLoaded] = React.useState(false);
	const [floorList, setfloorList] = React.useState([]);
	const [selectedFloor, setselectedFloor] = React.useState('');
	const [open, setStatusMessageOpen] = React.useState('');
	const [quckbkval, setquick] = React.useState(false);
	const [dispmenu, setdispmenu] = React.useState<any>();
	const [bookingLoader, setBookingLoader] = React.useState(false);
	const [Bkngs, setBookings] = React.useState(false);
	const [dispSpaceAndDesk, setDispSpaceAndDesk] = React.useState<any>();
	const [evntdata, setEventData] = React.useState<any>([]);
	const [evntdess, setEvenDescription] = React.useState<any>();
	const [setUpTypeId, setSetUpId] = React.useState<any>();
	const [clientidfeatures, setClientId] = React.useState<any>();
	const [selectedFilter, setSelectedFilter] = React.useState<any>([{ description: 'Meeting Equipment', id: 'none' }]);
	//const [filterOptions, setFilterOptions] = React.useState<any[]>([]);
	const [selectedType, setSelectedType] = React.useState(-1);
	const [showMap, setShowMap] = React.useState<any>(true);
	//const [zoneSingleRecord, setZoneSingleRecord] = React.useState<any>();
	// const [currentAvailableTime, setCurrentAvailableTime]: any = React.useState(null);
	const [initialTimerange, setInitialTimeRange]: any = React.useState(null);

	const [allRoomsdeskData, setAllRoomsDeskData] = React.useState([]);
	const [allRoomsdeskData2, setAllRoomsDeskData2] = React.useState([]);
	const [allRoomsdeskData3, setAllRoomsDeskData3] = React.useState([]);
	const [finalRoomId, setfinalRoomId] = React.useState();
	const [finalsetUpTypeId, setfinalsetUpTypeId] = React.useState();
	const [finalrecordType, setfinalrecordType] = React.useState();
	const [floorNameVal, setFloorName] = React.useState<any>();

	const getAvailableStatus = () => {
		let available;
		let id = 0;
		for (let time of timeSlots) {
			let booked = checkSlotStatus('time_' + id);
			if (booked) {
				available = time;
				break;
			}
			id++;
		}
		console.log(available);
		// if (timeSlots && timeSlots[0] == available) {
		// 	setCurrentAvailableTime(null);
		// } else if (available) {
		// 	setCurrentAvailableTime(available);
		// } else {
		// 	setCurrentAvailableTime(timeSlots[timeSlots.length - 1]);
		// }
	};
	const getBookingData = (roomId: any, starDateTime: string, endDateTime: string) => {
		setBookingLoader(true);
		spaces.getBookedDateTime(roomId, starDateTime, endDateTime).then((res: any) => {
			let bookedData = res.map((item: any) => ({
				start: moment(item.eventStartTime).format('HH:mm'),
				end: moment(item.eventEndTime).format('HH:mm'),
			}));
			setBookedRange(bookedData);
			if (!initialTimerange) {
				setBookingLoader(false);
			}
		});
	};

	React.useEffect(() => {
		setEntityType(
			user.featurePermissions && user.featurePermissions.ROOMS
				? 'room'
				: user.featurePermissions && user.featurePermissions.DESKS
				? 'desk'
				: user.featurePermissions && user.featurePermissions.PARKING
				? 'parking'
				: '',
		);
		setDispSpaceAndDesk(
			user.featurePermissions && user.featurePermissions.ROOMS
				? 1
				: user.featurePermissions && user.featurePermissions.DESKS
				? 0
				: user.featurePermissions && user.featurePermissions.PARKING
				? 2
				: '',
		);
		const fetchDetails = async () => {
			await fetchCondecoDetails();
		};

		const test = user.currentSiteId ? user.currentSiteId : '';

		spaces.fetchZoneDetails(test, route.params.floorId).then((res: any) => {
			const Zonelist = res.Zones;
			const ZoneData = Zonelist.findIndex((Zonerecord: any) => {
				return Zonerecord.ZoneId === route.params.zoneId;
			});
			if (Zonelist[ZoneData]) {
				const startDateTime = moment()
					.utc()
					.format();
				const endDateTime = moment()
					.add('15', 'minutes')
					.utc()
					.format();
				spaces.RoomAvailable(Zonelist[ZoneData].RoomId, startDateTime, endDateTime).then((res: any) => {
					setTearDownHours(res[0].tearDownHours);
				});
			}
			// setZoneSingleRecord(Zonelist[ZoneData]);
			spaces.roomdatesavailability(route.params.zoneId);

			spaces.meetingeventlist().then(res => {
				setEventData(res);

				setEvenDescription(res[0].id);
			});
		});

		fetchDetails();
		spaces.meetingeventlist();
		spaces.fetchFeatures();
		console.log(evntdess);
		setHeightTweetsDiv();
	}, [user.currentSiteId, spaces, appStores, user.featurePermissions]);

	React.useEffect(() => {
		if (entityType == 'room') {
			applyFilters(true);
		} else if (entityType == 'parking') {
			applyFiltersNew(true);
		} else {
			// applyFiltersDesksNew();
			applyFiltersDesks(true);
		}
		setFloorName(selectedFloor);
		spaces.EditorFlrData(route.params.floorId);
	}, [selectedFloor]);

	const fetchCondecoDetails = async () => {
		appStores.showAppLoader = true;
		const result = await spaces.fetchCondecoDetails(user.currentSiteId);
		setBookings(result && result.ISCondecoAvailable);
		setClientId(result && result.Clientid);
		mapFilterOptions(result.clientid);
		appStores.showAppLoader = false;
	};

	const showStatusForDeleteCoordinates = () => {
		setStatusMessageOpen('deleted');
		setEntityType('room');
	};
	const handleChangeFilter = (event: React.ChangeEvent<{ value: string[] }>) => {
		const {
			target: { value },
		} = event;
		if (value.length) {
			const index = value.findIndex((x: any) => x.description === 'Meeting Equipment');
			if (index > -1) value.splice(index, 1);
			setSelectedFilter(value);
		} else {
			setSelectedFilter([{ description: 'Meeting Equipment', id: 'none' }]);
		}
	};
	const setMappedEntityIds = (mappedEntityIds: string[]) => {
		floorplanDetail.mappedEntityIds = mappedEntityIds;
	};
	const quckbk = () => {
		clearFilters(entityType === 'room' ? 'space' : 'desk');
		setquick(true);
		setdispmenu(1);
		if (entityType == 'room') {
			applyFilters();
		} else {
			applyFiltersDesks();
		}
	};

	const floordata = user.floorsingledata ? user.floorsingledata : '';
	const routes = React.useContext(stores.context.routes);

	const getRoundedDate = (minutes: any = 15, d = moment().format()) => {
		var time = 1000 * 60 * minutes;
		var date = new Date(d);
		var rounded = new Date(date.getTime() + time - (date.getTime() % time));
		return moment(rounded).format('YYYY-MM-DDTHH:mm');
	};

	const filterCoordinates = (data: IFloorCoordinates[], filterData: [{ ZoneId: String }] | any) => {
		let filterDataId = filterData.map((data: { ZoneId: any }) => data.ZoneId);
		let filtered = data.filter((item: any) => filterDataId.includes(item.id));
		return filtered;
	};

	const fetchCordinates = (filterZoneData: [{ ZoneId: String }] | any, filterRes: any) => {
		setBookingLoader(true);
		spaces
			.fetchFloorplanCoordinates(route.params.floorId, entityType)
			.then(list => {
				const filteredCoordinates = filterCoordinates(list.floorplanCoordinates || [], filterZoneData);
				FloorplanEditorCanvas.resetCanvas('deleteObjects');
				FloorplanEditorCanvas.floorplanDetail.savedFloorplanCoordinates = [...filteredCoordinates];
			})
			.finally(() => {
				setTimeout(() => {
					FloorplanEditorCanvas.showFloorplanCoordinates(filterRes, filterZoneData, false, true);
					setBookingLoader(false);
				}, 0);
			});
	};

	const flrplan = () => {
		clearFilters(entityType === 'room' ? 'space' : 'desk');
		setquick(false);
		setdispmenu(0);
	};

	const [chkval, setCheckval] = React.useState<any>(false);
	const [chkval1, setCheckval1] = React.useState<any>(false);
	const [chkval2, setCheckval2] = React.useState<any>(false);
	const [chkval3, setCheckval3] = React.useState<any>(false);
	const [chkval4, setCheckval4] = React.useState<any>(false);
	const [chkval5, setCheckval5] = React.useState<any>(false);

	const [roomslength, setroomslength] = React.useState<any>('');
	const [recordType, setRecordType] = React.useState<any>('');
	const [RoomId, setRoomId] = React.useState<any>('');
	const [meetingRoomName, setMeetingRoom] = React.useState<any>('');

	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const [opensubject, setOpen] = React.useState(false);
	const [zoneIdAttendee, setZoneId] = React.useState<any>('');
	const [BookingId, setBookingId] = React.useState<any>('');
	const initialSpaceState = {
		NoOfAttendees: 1,
		StartTime: moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format(),

		EndTime: moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
			.add(15, 'minutes')
			.format(),

		//Roomfeatures: [],

		SiteId: user.currentSiteId,
		levelId: route.params.floorId,
		Deskfeatures: [],
	};
	const initialDeskState = {
		StartTime: moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format(),
		EndTime: moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
			.add(15, 'minutes')
			.format(),
		Deskfeatures: [],
		SiteId: user.currentSiteId,
		levelId: route.params.floorId,
	};
	const [spaceForm, setSpaceForm] = React.useState(initialSpaceState);
	const [deskForm, setDeskForm] = React.useState(initialDeskState);
	const [formSpacesTouched, setFormSpacesTouched] = React.useState(true);
	const [formDesksTouched, setFormDesksTouched] = React.useState(true);

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
		console.log(zoneIdAttendee);
		setDialogClose(false);
	};

	const handleClickOpen = () => {
		setEsmeDialogOpen(false);
		if (entityType == 'desk') {
			handleClickFinalOpen2();
		} else {
			setOpen(true);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [openevent, setEventOpen] = React.useState(false);

	// const handleClickEventOpen = () => {
	// 	setEsmeDialogOpen(false);
	// 	setEventOpen(true);
	// };

	const handleCloseevent = () => {
		setEventOpen(false);
	};

	const [subjecttit, setSubjectTitle] = React.useState<any>('Room Booking');
	const subject = (event: any) => {
		setSubjectTitle(event.target.value);
	};

	const triggerselection = () => {
		let startdate: any = '';
		let enddate: any = '';
		startdate = getDisplayStartDate();
		enddate = getDisplayEndDate();
		if (startdate && enddate) {
			let searchyearmon: any = moment(startdate).format('MMMM');
			let searchyearon: any = moment(startdate).format('YYYY');
			setnewDataVariablemonth(searchyearmon);
			const numMonth = moment(startdate).format('MM');
			setselectedmonthval(numMonth);
			setselectedYear(searchyearon);
			makeDateOption();
			let currentmnthnew: any = moment().format('MM');
			let searchyear: any = moment(startdate).format('YYYY');
			let searchmnth: any = moment(startdate).format('MM');
			let searchday: any = moment(startdate).format('DD');
			// setnewDataVariablemonth(
			// 	moment(moment(searchyear + '-' + searchmnth + '-' + searchday).format('YYYY/MM/DD')).format('MMMM'),
			// );
			setkeyval(keyval + 1);
			let diff: number =
				currentmnthnew == searchmnth
					? moment(startdate, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days')
					: moment(startdate, 'YYYY-MM-DD').diff(moment().format(searchyear + '-' + searchmnth + '-' + '01'), 'days');

			if (diff < 0) {
				diff = 0;
			}
			const startTime: any = moment(startdate).format('HH:mm');
			const endTime: any = moment(enddate)
				.subtract(15, 'minutes')
				.format('HH:mm');

			preparemonthData(searchyearmon);

			const newDate =
				currentmnthnew == searchmnth
					? {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format('YYYY-MM-DD'),
					  }
					: {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format(searchyear + '-' + searchmnth + '-' + searchday),
					  };

			updatedDate = newDate.date;
			setSelectedDate(newDate);
			if (getSlots(0 + diff)) {
				let startIndex = getSlots(0 + diff).findIndex((item: any) => item == startTime);
				let endIndex = getSlots(0 + diff).findIndex((item: any) => item == endTime);
				if (startIndex >= 0 && endIndex >= 0) {
					const obj = { start: 'time_' + startIndex, end: 'time_' + endIndex };
					setSelectedTimeRange(obj);

					setTimeout(() => {
						highlightButtons(startIndex, endIndex, '#66ccfa');
					}, 3000);
				}
			}
			setTimeout(() => {
				setHeightTweetsDiv();
			}, 1000);
		} else {
			getSlots(0);
			setSelectedDate({
				id: 0,
				date: moment().format('YYYY-MM-DD'),
			});
		}
	};

	const handleClickFinalOpen = () => {
		setOpen(false);
		if (showMap == false) {
			Roombookingfinalupdated();
		} else {
			quickBookRoomUpdated();
		}
	};

	const handleClickFinalOpen2 = () => {
		setOpen(false);

		if (showMap == false) {
			Deskbookingfinalupdated();
		} else {
			quickBookDeskUpdated();
		}
	};

	const handleClickFinalOpen3 = () => {
		setOpen(false);
		if (showMap == false) {
			Parkbookingfinalupdated();
		} else {
			quickBookParkUpdated();
		}
	};
	const setHeightTweetsDiv = () => {
		const height: any = document.getElementById('tabsDynamicHeight')?.clientHeight;
		const divElement = document.getElementById('dynamicHeight');
		const divElementTimeSlot = document.getElementById('dynamicHeightTimeslot');
		if (divElement && divElementTimeSlot) {
			const invh = (height / document.documentElement.clientHeight) * 100;
			divElement.style.setProperty('height', `calc(73vh - ${invh}vh)`);
			divElementTimeSlot.style.setProperty('height', `calc(73vh - ${invh}vh)`);
		}
	};

	const _handleFormChange = (name: string, value: any, formName = 'space') => {
		value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		let mmdate = moment(value).format('MM');
		setselectedmonthval(mmdate);
		if (name == 'EndTime') {
			if (formName == 'desk') {
				if (moment(value).diff(moment(deskForm.StartTime)) < 0) {
					setTimeout(() => {
						notifications.addNotification('End date time should be greater than start date time', 'error');
					}, 500);
					return;
				}
			} else {
				if (moment(value).diff(moment(spaceForm.StartTime)) < 0) {
					setTimeout(() => {
						notifications.addNotification('End date time should be greater than start date time', 'error');
					}, 500);
					return;
				}
			}
		}
		if (formName == 'space' && value !== 'Invalid date') {
			if (new Date(value).getMinutes() >= 1 && new Date(value).getMinutes() <= 15) {
				setSpaceForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 15)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 16 && new Date(value).getMinutes() <= 30) {
				setSpaceForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 30)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 31 && new Date(value).getMinutes() <= 45) {
				setSpaceForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 45)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 46 && new Date(value).getMinutes() <= 60) {
				setSpaceForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 60)

						.format(),
				}));
			} else {
				setSpaceForm((state: any) => ({ ...state, [name]: value }));
			}

			setFormSpacesTouched(false);
			setFormDesksTouched(false);
		} else if (formName == 'desk' && value !== 'Invalid date') {
			if (new Date(value).getMinutes() >= 1 && new Date(value).getMinutes() <= 15) {
				setDeskForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 15)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 16 && new Date(value).getMinutes() <= 30) {
				setDeskForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 30)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 31 && new Date(value).getMinutes() <= 45) {
				setDeskForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 45)

						.format(),
				}));
			} else if (new Date(value).getMinutes() >= 46 && new Date(value).getMinutes() <= 60) {
				setDeskForm((state: any) => ({
					...state,
					[name]: moment(value)
						.set('minute', 60)

						.format(),
				}));
			} else {
				setDeskForm((state: any) => ({ ...state, [name]: value }));
			}

			setFormDesksTouched(false);
		}
	};

	const _handleCheckBox = (formName: string, itemName: string, checked: boolean) => {
		checked ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		if (checked) {
			setSpaceForm((state: any) => ({ ...state, [formName]: [...state.Deskfeatures, itemName] }));
		} else {
			let filtered = spaceForm.Deskfeatures.filter((item: any) => item !== itemName);
			setSpaceForm((state: any) => ({ ...state, [formName]: [...filtered] }));
		}
		setFormSpacesTouched(false);
	};

	let [uQuickRoomButton, setUQuickRoomButton] = React.useState('');
	// let [uRoomStartTime, setURoomStartTime] = React.useState('');
	// let [uRoomEndTime, setURoomEndTime] = React.useState('');

	const applyFiltersNew = async (coordinatesView: boolean = false) => {
		spaces.fetchZoneDetails(user.currentSiteId, route.params.floorId).then(async (res: any) => {
			await setZones(res.Zones);
		});
		if (counter > 1) {
			handleZoom(Math.max(2 - 1, 1));
		}

		setCounter(1);
		let canvasObjfilter: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
		if (canvasObjfilter != undefined) {
			canvasObjfilter['viewportTransform'] = [1, 0, 0, 1, 0, 0];
		}

		FloorplanEditorCanvas.resetCanvas('deleteObjects');
		// setURoomStartTime(spaceForm.StartTime);
		// setURoomEndTime(spaceForm.EndTime);
		const selectedInitialDTRange = `${moment(spaceForm.StartTime).format('DD/MM/YYYY')} ${moment(
			spaceForm.StartTime,
		).format('HH:mm')} - ${moment(spaceForm.EndTime).format('HH:mm')} `;
		setUQuickRoomButton(selectedInitialDTRange);
		localStorage.removeItem('CMValue');
		let formattedStartTime = moment(spaceForm.StartTime).format('HH:mm');
		let formattedEndTime = moment(spaceForm.EndTime).format('HH:mm');
		let formattedStartDateTime = moment(spaceForm.StartTime).utc();
		let currentDateTime = moment().utc();

		setAppliedFilter({ StartTime: spaceForm.StartTime, EndTime: spaceForm.EndTime });
		setBookingLoader(true);
		spaceForm.SiteId = user.currentSiteId;
		spaceForm.levelId = floordata.floorId;
		const { Deskfeatures, StartTime, EndTime, levelId, SiteId } = spaceForm;
		const obj = {
			Deskfeatures,
			StartTime,
			EndTime,
			levelId,
			SiteId,
		};
		return spaces
			.getCordinate(obj, 2)
			.then((res: any) => {
				setAllRoomsDeskData3(res);
				const availableRooms: any = res.filter((res: any) => res.unavailableReason == 0);

				if (
					spaceForm.StartTime >= spaceForm.EndTime ||
					formattedStartTime < '00:00' ||
					formattedEndTime > '23:45' ||
					formattedStartDateTime.isBefore(currentDateTime)
				) {
					setroomslength(0);
				} else {
					setroomslength(availableRooms.length);
				}
				if (availableRooms.length > 0) {
					setRecordType(availableRooms[0].recordType);
					setSetUpId(availableRooms[0].defaultSetupTypeId);
					setRoomId(availableRooms[0].id);
					setSubjectTitle('Parking slot booking');
					setMeetingRoom(availableRooms[0].name);
					spaces.meetingeventlist().then(res => {
						setEventData(res);
						setEvenDescription(res[0].id);
					});

					const Zonermm: any = zones.filter((Zones: any) => Zones.RoomId == availableRooms[0].id);
					if (Array.isArray(Zonermm) && Zonermm.length > 0) {
						if (Zonermm[0].ZoneId !== '') {
							setZoneId(Zonermm[0].ZoneId);
						} else {
							setZoneId('NoZoneId');
						}
					}
				} else {
					setMeetingRoom('');
				}
				if (!coordinatesView) {
					filterZonesAndCordinates(res);
				}
				return availableRooms.length;
			})
			.catch(err => {
				return 0;
			})
			.finally(() => {
				setBookingLoader(false);
			});
	};

	const applyFilters = async (coordinatesView: boolean = false) => {
		spaces.fetchZoneDetails(user.currentSiteId, route.params.floorId).then(async (res: any) => {
			await setZones(res.Zones);
		});
		if (counter > 1) {
			handleZoom(Math.max(2 - 1, 1));
		}

		setCounter(1);
		let canvasObjfilter: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
		if (canvasObjfilter != undefined) {
			canvasObjfilter['viewportTransform'] = [1, 0, 0, 1, 0, 0];
		}

		FloorplanEditorCanvas.resetCanvas('deleteObjects');
		//setURoomStartTime(spaceForm.StartTime);
		//setURoomEndTime(spaceForm.EndTime);
		const selectedInitialDTRange = `${moment(spaceForm.StartTime).format('DD/MM/YYYY')} ${moment(
			spaceForm.StartTime,
		).format('HH:mm')}	 - ${moment(spaceForm.EndTime).format('HH:mm')} `;
		setUQuickRoomButton(selectedInitialDTRange);
		localStorage.removeItem('CMValue');
		let formattedStartTime = moment(spaceForm.StartTime).format('HH:mm');
		let formattedEndTime = moment(spaceForm.EndTime).format('HH:mm');
		let formattedStartDateTime = moment(spaceForm.StartTime).utc();
		let currentDateTime = moment().utc();

		setAppliedFilter({ StartTime: spaceForm.StartTime, EndTime: spaceForm.EndTime });
		setBookingLoader(true);
		spaceForm.SiteId = user.currentSiteId;
		spaceForm.levelId = selectedFloor;
		const list: any = selectedFilter.map((s: any) => s.description);
		spaceForm.Deskfeatures = list.length ? list : [];

		const { Deskfeatures, StartTime, EndTime, levelId, SiteId } = spaceForm;
		const obj = {
			Deskfeatures,
			StartTime,
			EndTime,
			levelId,
			SiteId,
		};
		return spaces
			.getCordinate(obj, 1)
			.then((res: any) => {
				setAllRoomsDeskData(res);
				const availableRooms: any = res.filter((res: any) => res.unavailableReason == 0);
				if (
					spaceForm.StartTime >= spaceForm.EndTime ||
					formattedStartTime < '00:00' ||
					formattedEndTime > '23:45' ||
					formattedStartDateTime.isBefore(currentDateTime)
				) {
					setroomslength(0);
				} else {
					setroomslength(availableRooms.length);
				}
				if (availableRooms.length > 0) {
					setRecordType(availableRooms[0].recordType);
					setSetUpId(availableRooms[0].defaultSetupTypeId);
					setRoomId(availableRooms[0].id);
					setSubjectTitle('Room Booking');
					setMeetingRoom(availableRooms[0].name);
					spaces.meetingeventlist().then(res => {
						setEventData(res);

						setEvenDescription(res[0].id);
					});

					const Zonermm: any = zones.filter((Zones: any) => Zones.RoomId == availableRooms[0].id);
					if (Array.isArray(Zonermm) && Zonermm.length > 0) {
						if (Zonermm[0].ZoneId !== '') {
							setZoneId(Zonermm[0].ZoneId);
						} else {
							setZoneId('NoZoneId');
						}
					}
				}
				if (!coordinatesView) {
					filterZonesAndCordinates(res);
				}
				return availableRooms.length;
			})
			.catch(err => {
				return 0;
			})
			.finally(() => {
				setBookingLoader(false);
			});
	};

	const [openFinal, setDialogFinal] = React.useState(false);

	const [opennotavailable, setDialogClose] = React.useState(false);

	const [invalidDialog, setInvalidDialog] = React.useState(false);

	const handleClickfinal = () => {
		setDialogFinal(false);
		clearFilters(entityType === 'room' ? 'space' : entityType === 'desk' ? 'desk' : 'parking');
		//applyFilters();
		setSelectedTimeRange(null);
		clearHighlight();
	};

	const handleInvalidClose = () => {
		setInvalidDialog(false);
	};

	const ConfirmMsg = async () => {
		if (showMap === true) {
			let avaialableLength = 0;
			if (entityType === 'room') {
				avaialableLength = await applyFilters(true);
			} else if (entityType === 'parking') {
				avaialableLength = await applyFiltersNew(true);
			} else {
				avaialableLength = await applyFiltersDesks(true);
			}

			if (avaialableLength <= 0) {
				setDialogClose(true);
				return;
			}
		}

		let formtime: any = `${moment(getDisplayStartDate()).format('HH:mm')}`;
		let frmdate = `${moment(getDisplayStartDate()).format('DD/MM/YYYY')}`;
		let today: any = new Date();
		let time: any = today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();

		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		let todaynew = dd + '/' + mm + '/' + yyyy;

		if (formtime > time) {
			let entval: any = '';
			if (entityType == 'parking') {
				entval = 'Parking';
			}
			if (entityType == 'desk') {
				entval = 'Desk';
			}
			if (entityType == 'room') {
				entval = 'Room';
			}

			const flrdialog = spaces.flrInfoEditor && spaces.flrInfoEditor.find((zone: any) => zone.ZoneType == entval);
			if (flrdialog == undefined) {
				setDialogClose(true);
			} else {
				setTimeout(() => {
					setEsmeDialogOpen(true);
				}, 2000);
			}
		} else if (Date.parse(`01/01/9999 ${formtime}`) < Date.parse(`01/01/9999 ${time}`) && todaynew == frmdate) {
			setInvalidDialog(true);
		} else {
			let entval: any = '';
			if (entityType == 'parking') {
				entval = 'Parking';
			}
			if (entityType == 'desk') {
				entval = 'Desk';
			}
			if (entityType == 'room') {
				entval = 'Room';
			}

			const flrdialog = spaces.flrInfoEditor && spaces.flrInfoEditor.find((zone: any) => zone.ZoneType == entval);
			if (flrdialog == undefined) {
				setDialogClose(true);
			} else {
				setEsmeDialogOpen(true);
			}
		}
	};
	const [meetingevent, setEventVal] = React.useState<any>();
	console.log(meetingevent);
	/* new code for room booking */

	const quickBookRoomUpdated = () => {
		const startdatetime = spaceForm.StartTime;
		const enddatetimenew = spaceForm.EndTime;
		setBookingLoader(true);
		const EventTypeId = selectedType == -1 ? 5 : selectedType;
		spaces
			.RoomBookingUpdated(RoomId, recordType, startdatetime, enddatetimenew, subjecttit, EventTypeId, setUpTypeId, 1)
			.then((res: any) => {
				setBookingId(res.BookingId);
				setBookingLoader(false);
				setDialogFinal(true);
			});
	};

	const quickBookParkUpdated = () => {
		const startdatetime = spaceForm.StartTime;
		const enddatetimenew = spaceForm.EndTime;
		setBookingLoader(true);
		const EventTypeId = selectedType == -1 ? 5 : selectedType;
		//const spcval:any = spaces.flrInfoEditor.find((zone:any) => zone.ZoneType=='Parking');
		spaces
			.RoomBookingUpdated(RoomId, recordType, startdatetime, enddatetimenew, subjecttit, EventTypeId, setUpTypeId, 2)
			.then((res: any) => {
				setBookingId(res.BookingId);
				setBookingLoader(false);
				setDialogFinal(true);
			});
	};

	/* new code for room booking */

	/* new code for desk booking */

	const quickBookDeskUpdated = () => {
		const startdatetime = deskForm.StartTime;
		const enddatetimenew = deskForm.EndTime;
		setBookingLoader(true);
		const EventTypeId = 5;
		spaces
			.RoomBookingUpdated(
				RoomId,
				recordType,
				startdatetime,
				enddatetimenew,
				'Desk Booking',
				EventTypeId,
				setUpTypeId,
				0,
			)
			.then((res: any) => {
				setBookingId(res.BookingId);
				setBookingLoader(false);
				setDialogFinal(true);
			});
	};

	/* final room booking */
	const getSelectedTimeRange = () => {
		return {
			start:
				selectedTimeRange && selectedTimeRange.start && timeSlots[selectedTimeRange.start.split('_')[1]]
					? timeSlots[selectedTimeRange.start.split('_')[1]]
					: null,
			end:
				selectedTimeRange && selectedTimeRange.end && timeSlots[selectedTimeRange.end.split('_')[1]]
					? timeSlots[selectedTimeRange.end.split('_')[1]]
					: null,
		};
	};

	const getDisplayStartDate = () => {
		if (showMap == false) {
			const startDateTime = moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss').format();
			return startDateTime;
		} else {
			if (entityType == 'desk') {
				return deskForm.StartTime;
			} else {
				return spaceForm.StartTime;
			}
		}
	};

	const getDisplayEndDate = () => {
		if (showMap == false) {
			const endDateTime =
				getSelectedTimeRange().end == null
					? moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss')
							.add('15', 'minutes')
							.format()
					: moment(selectedDate.date + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm:ss')
							.add('15', 'minutes')
							.format();
			return endDateTime;
		} else {
			if (entityType == 'desk') {
				return deskForm.EndTime;
			} else {
				return spaceForm.EndTime;
			}
		}
	};

	const Roombookingfinalupdated = () => {
		const startDateTime = moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss').format();

		const endDateTime =
			getSelectedTimeRange().end == null
				? moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format()
				: moment(selectedDate.date + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format();
		setBookingLoader(true);
		//const subject = 'Room Booking';
		const EventTypeId = selectedType == -1 ? 5 : selectedType;
		spaces
			.RoomBookingUpdated(
				finalRoomId,
				finalrecordType,
				startDateTime,
				endDateTime,
				subjecttit,
				EventTypeId,
				finalsetUpTypeId,
				1,
			)
			.then((res: any) => {
				if (res.statusCode === 400) {
					setDialogClose(true);
					return;
				}

				if (res) {
					setBookingId(res.BookingId);
					setDialogFinal(true);
				} else {
					setDialogClose(true);
				}
			})
			.finally(() => setBookingLoader(false));
	};

	const Deskbookingfinalupdated = () => {
		const startDateTime = moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss').format();

		const endDateTime =
			getSelectedTimeRange().end == null
				? moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format()
				: moment(selectedDate.date + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format();
		setBookingLoader(true);
		//const subject = 'Desk Booking';
		const EventTypeId = 5;
		spaces
			.RoomBookingUpdated(
				finalRoomId,
				finalrecordType,
				startDateTime,
				endDateTime,
				subjecttit,
				EventTypeId,
				finalsetUpTypeId,
				0,
			)
			.then((res: any) => {
				if (res.statusCode === 400) {
					setDialogClose(true);
					return;
				}
				if (res) {
					setBookingId(res.BookingId);
					setDialogFinal(true);
				} else {
					setDialogClose(true);
				}
			})
			.finally(() => setBookingLoader(false));
	};

	const Parkbookingfinalupdated = () => {
		const startDateTime = moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss').format();

		const endDateTime =
			getSelectedTimeRange().end == null
				? moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format()
				: moment(selectedDate.date + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format();
		setBookingLoader(true);
		//const subject = 'Desk Booking';
		const EventTypeId = selectedType == -1 ? 5 : selectedType;
		spaces
			.RoomBookingUpdated(
				finalRoomId,
				finalrecordType,
				startDateTime,
				endDateTime,
				subjecttit,
				EventTypeId,
				finalsetUpTypeId,
				2,
			)
			.then((res: any) => {
				if (res.statusCode === 400) {
					setDialogClose(true);
					return;
				}
				if (res) {
					setBookingId(res.BookingId);
					setDialogFinal(true);
				} else {
					setDialogClose(true);
				}
			})
			.finally(() => setBookingLoader(false));
	};

	/* new code for desk booking */

	/* new code for parking booking */

	// const quickBookParkingUpdated = () =>
	// {
	// 	const startdatetime = spaceForm.StartTime;
	// 	const enddatetimenew = spaceForm.EndTime;
	// 	setBookingLoader(true);
	// 	const EventTypeId = 5;
	// 	spaces
	// 		.RoomBookingUpdated(19,"RequestableRoom",startdatetime,enddatetimenew,"test",EventTypeId,3,2)
	// 		.then((res: any) => {
	// 			setBookingId(res.BookingId);
	// 			setBookingLoader(false);
	// 			setDialogFinal(true);
	// 		});
	// }

	/* new code for parking booking */

	// const Roombookingfinal = () => {
	// 	const startdatetime = uRoomStartTime;
	// 	const enddatetimenew = uRoomEndTime;
	// 	setBookingLoader(true);
	// 	const EventTypeId = meetingevent ? meetingevent : evntdess;
	// 	spaces
	// 		.RoomBooking(RoomId, recordType, startdatetime, enddatetimenew, subjecttit, EventTypeId, setUpTypeId)
	// 		.then((res: any) => {
	// 			setBookingId(res.BookingId);
	// 			setBookingLoader(false);
	// 			setDialogFinal(true);
	// 		});
	// };
	const filterZonesAndCordinates = (data: [{ id: Number }] | any) => {
		let filteredIds = data.map((item: { [x: string]: any }) => item['id']);
		let filteredZones = zones.filter(zone => filteredIds.includes(zone['RoomId']));
		fetchCordinates(filteredZones, data);
	};
	let [uQuickDeskButton, setUQuickDeskButton] = React.useState('');
	let [udeskStartTime, setUDeskStartTime] = React.useState('');
	let [udeskEndTime, setUDeskEndTime] = React.useState('');

	const applyFiltersDesks = async (coordinatesView: boolean = false) => {
		deskForm.SiteId = user.currentSiteId;
		deskForm.levelId = route.params.floorId;
		spaces.fetchZoneDetails(user.currentSiteId, route.params.floorId).then((res: any) => {
			setZones(res.Zones);
		});
		setUDeskStartTime(deskForm.StartTime);
		setUDeskEndTime(deskForm.EndTime);
		if (counter > 1) {
			handleZoom(Math.max(2 - 1, 1));
		}

		setCounter(1);
		let canvasObjnew: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
		if (canvasObjnew != undefined) {
			canvasObjnew['viewportTransform'] = [1, 0, 0, 1, 0, 0];
		}

		const selectedInitialDTRange = `${moment(deskForm.StartTime).format('DD/MM/YYYY')} ${moment(
			deskForm.StartTime,
		).format('HH:mm')} - ${moment(deskForm.EndTime).format('HH:mm')} `;

		setUQuickDeskButton(selectedInitialDTRange);
		let formattedStartTime = moment(deskForm.StartTime).format('HH:mm');
		let formattedEndTime = moment(deskForm.EndTime).format('HH:mm');
		let formattedStartDateTime = moment(deskForm.StartTime).utc();
		let currentDateTime = moment().utc();
		setAppliedFilter({ StartTime: deskForm.StartTime, EndTime: deskForm.EndTime });
		setBookingLoader(true);
		deskForm.SiteId = user.currentSiteId;
		deskForm.levelId = selectedFloor;

		const list: any = selectedFilter.map((s: any) => s.description);
		const { StartTime, EndTime, levelId, SiteId } = deskForm;
		const obj = {
			Deskfeatures: list.length ? list : [],
			StartTime,
			EndTime,
			levelId,
			SiteId,
		};
		return spaces
			.getCordinate(obj, 0)
			.then((res: any) => {
				setAllRoomsDeskData2(res);
				const availableDesks: any = res.filter((res: any) => res.unavailableReason == 0);
				if (
					deskForm.StartTime >= deskForm.EndTime ||
					formattedStartTime < '00:00' ||
					formattedEndTime > '23:45' ||
					formattedStartDateTime.isBefore(currentDateTime)
				) {
					setroomslength(0);
					deskForm.SiteId = user.currentSiteId;
				} else {
					setroomslength(availableDesks.length);
					deskForm.SiteId = user.currentSiteId;
				}
				if (availableDesks.length > 0) {
					setRecordType(availableDesks[0].recordType);
					setSetUpId(availableDesks[0].defaultSetupTypeId);
					setRoomId(availableDesks[0].id);
					setSubjectTitle('Desk Booking');
					setMeetingRoom(res[0].name);
					deskForm.SiteId = user.currentSiteId;
				}
				if (!coordinatesView) {
					filterZonesAndCordinates(res);
				}
				return availableDesks.length;
			})
			.catch(err => {
				return 0;
			})
			.finally(() => {
				setBookingLoader(false);
			});
	};

	// const applyFiltersDesksNew = () => {
	// 	deskForm.SiteId = user.currentSiteId;
	// 	deskForm.levelId = route.params.floorId;
	// 	spaces.fetchZoneDetails(user.currentSiteId, route.params.floorId).then((res: any) => {
	// 		setZones(res.Zones);
	// 	});
	// 	setUDeskStartTime(deskForm.StartTime);
	// 	setUDeskEndTime(deskForm.EndTime);
	// 	if (counter > 1) {
	// 		handleZoom(Math.max(2 - 1, 1));
	// 	}

	// 	setCounter(1);
	// 	let canvasObjnew: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
	// 	if (canvasObjnew != undefined) {
	// 		canvasObjnew['viewportTransform'] = [1, 0, 0, 1, 0, 0];
	// 	}

	// 	const selectedInitialDTRange = `${moment(deskForm.StartTime).format('DD/MM/YYYY')} ${moment(
	// 		deskForm.StartTime,
	// 	).format('HH:mm')} - ${moment(deskForm.EndTime).format('HH:mm')} `;

	// 	setUQuickDeskButton(selectedInitialDTRange);
	// 	let formattedStartTime = moment(deskForm.StartTime).format('HH:mm');
	// 	let formattedEndTime = moment(deskForm.EndTime).format('HH:mm');
	// 	let formattedStartDateTime = moment(deskForm.StartTime).utc();
	// 	let currentDateTime = moment().utc();
	// 	setAppliedFilter({ StartTime: deskForm.StartTime, EndTime: deskForm.EndTime });
	// 	setBookingLoader(true);
	// 	deskForm.SiteId = user.currentSiteId;
	// 	deskForm.levelId = floordata.floorId;
	// 	const { levelId, EndTime, StartTime } = deskForm;
	// 	const obj = {
	// 		levelId,
	// 		EndTime,
	// 		StartTime,
	// 	};
	// 	spaces
	// 		.getCordinate(obj, 0)
	// 		.then((res: any) => {
	// 			setAllRoomsDeskData2(res);
	// 			console.log('deskres', res);
	// 			const availableDesks: any = res.filter((res: any) => res.unavailableReason == 0);
	// 			if (
	// 				deskForm.StartTime >= deskForm.EndTime ||
	// 				formattedStartTime < '00:00' ||
	// 				formattedEndTime > '23:45' ||
	// 				formattedStartDateTime.isBefore(currentDateTime)
	// 			) {
	// 				setroomslength(0);
	// 				deskForm.SiteId = user.currentSiteId;
	// 			} else {
	// 				setroomslength(availableDesks.length);
	// 				deskForm.SiteId = user.currentSiteId;
	// 			}
	// 			if (availableDesks.length > 0) {
	// 				setRecordType(availableDesks[0].recordType);
	// 				setSetUpId(availableDesks[0].defaultSetupTypeId);
	// 				setRoomId(availableDesks[0].id);
	// 				setSubjectTitle('Desk Booking');
	// 				setMeetingRoom(res[0].name);
	// 				deskForm.SiteId = user.currentSiteId;
	// 			}
	// 		})
	// 		.finally(() => {
	// 			setBookingLoader(false);
	// 		});
	// };

	const [resetFilter, setResetFilter] = React.useState(false);
	const clearFilters = (type: String) => {
		localStorage.removeItem('CMValue');
		setShowMap(true);
		setResetFilter(state => !state);
		setSelectedFilter([{ description: 'Meeting Equipment', id: 'none' }]);
		setSelectedType(-1);
		setUQueryParam(false);
		if (type == 'space') {
			setSpaceForm(initialSpaceState);
			setDeskForm(initialDeskState);
			setFormSpacesTouched(true);
			setCheckval(false);
			setCheckval1(false);
			setCheckval2(false);
			setCheckval3(false);
			setCheckval4(false);
			setCheckval5(false);
		} else if (type == 'desk') {
			setSpaceForm(initialSpaceState);
			setDeskForm(initialDeskState);
			setFormDesksTouched(true);
			setCheckval(false);
			setCheckval1(false);
			setCheckval2(false);
			setCheckval3(false);
			setCheckval4(false);
			setCheckval5(false);
		} else if (type == 'parking') {
			setSpaceForm(initialSpaceState);
			setDeskForm(initialDeskState);
			setFormSpacesTouched(true);
			setCheckval(false);
			setCheckval1(false);
			setCheckval2(false);
			setCheckval3(false);
			setCheckval4(false);
			setCheckval5(false);
		}
	};
	const [openFinal2, setDialogFinal2] = React.useState(false);

	const handleClickfinal2 = () => {
		setDialogFinal2(false);
		applyFiltersDesks();
		setSelectedTimeRange(null);
		clearFilters(entityType === 'room' ? 'space' : entityType === 'parking' ? 'parking' : 'desk');
		clearHighlight();
	};

	const redirection2 = () => {
		window.location.href = `/myBookings`;
	};
	const DeskBookingfinal = () => {
		const startdatetime = udeskStartTime;
		const enddatetimenew = udeskEndTime;
		setBookingLoader(true);
		spaces.DeskBooking(RoomId, recordType, startdatetime, enddatetimenew, subjecttit, setUpTypeId).then((res: any) => {
			setBookingLoader(false);
			setDialogFinal2(true);
		});
	};

	const TabPanel = (props: any) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	};

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.any.isRequired,
		value: PropTypes.any.isRequired,
	};

	const a11yProps = (index: any) => {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	};

	const [value, setValue] = React.useState(0);
	const [uqueryParam, setUQueryParam] = React.useState(true);

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};
	const [appliedFilter, setAppliedFilter]: any = React.useState(null);
	const formRef: any = React.useRef();
	formRef.current = appliedFilter;
	const [counter, setCounter] = React.useState(1);
	const handleChangenew = (event: any, counter: any) => {
		handleZoom(counter + 1);
	};
	const updateStartDate = (date: any, formType = 'spaces') => {
		let start = moment();
		if (formType == 'spaces') {
			start = moment(spaceForm.StartTime, 'YYYY-MM-DDTHH:mm');
		} else if (formType == 'desk') {
			start = moment(deskForm.StartTime, 'YYYY-MM-DDTHH:mm');
		}
		if (start.year() != date.year() || start.date() != date.date() || start.month() != date.month()) {
			start = date.subtract(15, 'minutes');
			if (formType == 'spaces') {
				_handleFormChange('StartTime', start.utc().format());
			} else if (formType == 'desk') {
				_handleFormChange('StartTime', start.utc().format(), 'desk');
			}
		}
	};
	let floorplanDetail: IFloorplanDetail = useLocalStore(() => ({
		URL: '',
		propertyTypeId: '',
		propertyId: '',
		propertyName: '',
		controlType: '',
		savedFloorplanCoordinates: [],
		ZoneData: [],
		showPropContainer: false,
		mappedEntityIds: [],
		entitiesList: [],
		entitiesListByType: [],
		isEntityTypeDisabled: false,
	}));

	React.useEffect(() => {
		setAppliedFilter(null);
	}, [entityType]);
	React.useEffect(() => {
		const fetchFloorEntities = () => {
			spaces
				.fetchEntities(
					{
						clientId: route.params.clientId,
						EntityId: route.params.floorId,
						isDescendants: 'descendants',
					},
					false,
				)
				.then((list: IEntitie[]) => {
					floorplanDetail.entitiesList = list;
					floorplanDetail.entitiesListByType = floorplanDetail.entitiesList.filter(node => {
						return node.Type === entityType;
					});
				});
		};

		fetchFloorEntities();
		if (user.currentSiteId && floordata && floordata?.clientId && floordata.floorId) {
			routes.navigate(
				{
					name: 'spacesHome',
					params: {
						clientId: floordata.clientId,
						floorId: floordata.floorId,
					},
				},
				{
					clearParams: true,
				},
			);
		} else {
			routes.navigate(
				{
					name: 'spacesHome',
					params: {
						clientId: 'NA',
						floorId: 'NA',
					},
				},
				{
					clearParams: true,
				},
			);
		}
		if ((route.params.clientId != 'NA' && route.params.floorId != 'NA') || quckbkval == false) {
			FloorplanEditorCanvas.fetchFloorplanImage(
				spaces,
				route,
				entityType,
				showStatusForDeleteCoordinates,
				setMappedEntityIds,
				true,
			).then(() => {
				setfloorImgLoaded(true);
				FloorplanEditorCanvas.resetCanvas('deleteObjects');
				let canvasObj: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
				canvasObj &&
					canvasObj.on('mouse:down', (options: any) => {
						floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
						let canvasActiveObject: IObject = canvasObj.getActiveObject()!;

						if (!floorplanDetail.controlType && canvasActiveObject && canvasActiveObject.type !== 'activeSelection') {
							floorplanDetail.propertyId = canvasActiveObject.id || '';
							floorplanDetail.showPropContainer = true;
							// let queryParams = '?';
							if (formRef.current && uqueryParam) {
								// queryParams = '?startTime=' + formRef.current.StartTime + '&endTime=' + formRef.current.EndTime;
							}
							if (entityType == 'room') {
								triggerselection();
								setShowMap(!showMap);
								//console.log('roomidd',floorplanDetail.propertyId);
								//console.log('allroomdata',allRoomsdeskData);
								const singleroomdesk: any = allRoomsdeskData.find((single: any) => {
									return single.entityId === floorplanDetail.propertyId;
								});

								setfinalRoomId(singleroomdesk.id);
								setfinalsetUpTypeId(singleroomdesk.defaultSetupTypeId);
								setfinalrecordType(singleroomdesk.recordType);
								setMeetingRoom(singleroomdesk.name);
								getAvailableStatus();
							} else if (entityType == 'parking') {
								triggerselection();
								setShowMap(!showMap);
								const singleroomdesk: any = allRoomsdeskData3.find((single: any) => {
									return single.entityId === floorplanDetail.propertyId;
								});
								setfinalRoomId(singleroomdesk.id);
								setfinalsetUpTypeId(singleroomdesk.defaultSetupTypeId);
								setfinalrecordType(singleroomdesk.recordType);
								setMeetingRoom(singleroomdesk.name);
								getAvailableStatus();
							} else {
								triggerselection();
								setShowMap(!showMap);
								const singleroomdesk: any = allRoomsdeskData2.find((single: any) => {
									return single.entityId === floorplanDetail.propertyId;
								});
								setfinalRoomId(singleroomdesk.id);
								setfinalsetUpTypeId(singleroomdesk.defaultSetupTypeId);
								setfinalrecordType(singleroomdesk.recordType);
								setMeetingRoom(singleroomdesk.name);
								getAvailableStatus();
							}
						} else {
							const evt = options.e as any;
							canvasObj['isDragging'] = true;
							canvasObj['selection'] = false;
							canvasObj['lastPosX'] = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
							canvasObj['lastPosY'] = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
						}
					});
				canvasObj &&
					canvasObj.on('mouse:up', () => {
						if (floorplanDetail.controlType) {
							floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
							floorplanDetail.controlType = FloorplanEditorCanvas.floorplanDetail.controlType;
						} else {
							canvasObj['isDragging'] = false;
							canvasObj['selection'] = true;
						}
					});
				canvasObj &&
					canvasObj.on('object:modified', () => {
						floorplanDetail.isEntityTypeDisabled = true;
					});
				canvasObj &&
					canvasObj.on('mouse:move', (options: any) => {
						if (canvasObj['isDragging']) {
							const evt = options.e as any;
							const clientX = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
							const clientY = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
							if (canvasObj['viewportTransform']) {
								canvasObj['viewportTransform'][4] += clientX - canvasObj['lastPosX'];
								canvasObj['viewportTransform'][5] += clientY - canvasObj['lastPosY'];
							}
							canvasObj.requestRenderAll();
							canvasObj.getObjects().forEach((object: fabric.Object) => {
								object.setCoords();
							});
							canvasObj['lastPosX'] = clientX;
							canvasObj['lastPosY'] = clientY;
						}
					});
			});
		}
	}, [
		route,
		spaces,
		floorplanDetail,
		entityType,
		floordata,
		resetFilter,
		allRoomsdeskData,
		allRoomsdeskData2,
		allRoomsdeskData3,
	]);

	/*code for dateslots functions */
	const [bookedRange, setBookedRange]: any = React.useState([]);
	const [tearDownHours, setTearDownHours]: any = React.useState(null);

	const _highlightTimeRange = (start: string, end: string) => {
		let temp: any = { start, end };
		clearHighlight();
		let cursor1 = temp.start;
		let cursor2 = temp.end;
		if (Number(cursor1.split('_')[1]) < Number(cursor2.split('_')[1])) {
			let obj = { start: cursor1, end: cursor2 };
			setSelectedTimeRange(obj);
			highlightButtons(cursor1.split('_')[1], cursor2.split('_')[1], '#098BBD');
		} else {
			let obj = { start: cursor2, end: cursor1 };
			setSelectedTimeRange(obj);
			highlightButtons(cursor2.split('_')[1], cursor1.split('_')[1], '#098BBD');
		}
		setInitialTimeRange(null);
		setTimeout(() => setBookingLoader(false), 1000);
	};
	React.useEffect(() => {
		getAvailableStatus();
		if (initialTimerange) {
			setBookingLoader(true);
			setTimeout(() => {
				_highlightTimeRange(initialTimerange.start, initialTimerange.end);
			}, 2000);
		}
	}, [bookedRange]);
	const checkSlotStatus = (id: string, offbreak = false) => {
		let booked = false;
		const index = Number(id.split('_')[1]);
		let slotTime = moment(timeSlots[index], 'HH:mm');
		bookedRange.forEach((booking: any) => {
			let currBookingStart = moment(booking.start, 'HH:mm');
			let currBookingEnd = moment(booking.end, 'HH:mm');
			if (!tearDownHours) {
				if (slotTime.isBetween(currBookingStart, currBookingEnd, undefined, '[]')) {
					if (offbreak) {
						booked = true;
					} else if (slotTime.isBetween(currBookingStart, currBookingEnd.subtract(15, 'minutes'), undefined, '[]')) {
						booked = true;
					}
				}
			} else if (
				slotTime.isBetween(
					currBookingStart.subtract(60 * Number(tearDownHours), 'minutes'),
					currBookingEnd,
					undefined,
					'[]',
				)
			) {
				booked = true;
			}
		});
		return booked;
	};

	const [consecutiveTimeDialog, setConsecutiveTimeDialog] = React.useState(false);
	const handleConsecutiveSlots = () => {
		setConsecutiveTimeDialog(false);
	};
	const [selectedTimeRange, setSelectedTimeRange]: any = React.useState(null);
	const highlightButtons = (start: any, end: any, color: any) => {
		const butns: any = document.querySelectorAll('.timeBtnDefault');
		let startFound = false;
		let endFound = false;
		for (let butn of butns) {
			if (butn.id.split('_')[1] == start) {
				startFound = true;
			}
			if (startFound && !endFound) {
				if ((butn as HTMLButtonElement).disabled) {
					setConsecutiveTimeDialog(true);

					setSelectedTimeRange(null);
					clearHighlight();
					break;
				}
				(butn as HTMLElement).style.backgroundColor = color;
				(butn as HTMLElement).style.color = 'white';
				(butn as HTMLElement).className = (butn as HTMLElement).className + ' selectedTime';
			}
			if (butn.id.split('_')[1] == end) {
				endFound = true;
				break;
			}
		}
	};

	const _handleTimeRange = (id: string) => {
		if (!selectedTimeRange) {
			if (document.getElementById(id)) {
				document.getElementById(id)!.style.backgroundColor = '#66ccfa';
				document.getElementById(id)!.style.color = '#fff';
				document.getElementById(id)!.className = document.getElementById(id)!.className + ' selectedTime';
				setSelectedTimeRange(() => ({ start: id }));
			}
		} else if (selectedTimeRange['start'] == id) {
			clearHighlight();
			setSelectedTimeRange(null);
			return;
		} else {
			let temp: any = { ...selectedTimeRange };
			clearHighlight();
			if (temp.start && temp.end) {
				let current = Number(id.split('_')[1]);
				if (Number(temp.start.split('_')[1]) > current) {
					temp.start = id;
				} else if (Number(temp.start.split('_')[1]) < current) {
					temp.end = id;
				}
			}
			let cursor1 = temp.start ? temp.start : selectedTimeRange.start;
			let cursor2 = temp.end ? temp.end : id;
			if (selectedTimeRange['end'] == id) {
				cursor2 = 'time_' + (Number(selectedTimeRange.end.split('_')[1]) - 1);
			}
			if (Number(cursor1.split('_')[1]) < Number(cursor2.split('_')[1])) {
				let obj = { start: cursor1, end: cursor2 };
				setSelectedTimeRange(obj);
				highlightButtons(cursor1.split('_')[1], cursor2.split('_')[1], '#66ccfa');
			} else {
				let obj = { start: cursor2, end: cursor1 };
				setSelectedTimeRange(obj);
				highlightButtons(cursor2.split('_')[1], cursor1.split('_')[1], '#66ccfa');
			}
		}
	};

	const clearHighlight = () => {
		const butns: any = document.querySelectorAll('.timeBtnDefault');
		for (let butn of butns) {
			(butn as HTMLElement).style.backgroundColor = 'white';
			(butn as HTMLElement).style.color = '#098BBD';
			//(butn as HTMLElement).style.border = '1px solid rgba(9, 139, 189, 0.5)';
			(butn as HTMLElement).className = (butn as HTMLElement).className.replace('selectedTime', '');
		}
	};
	const [selectedYear, setselectedYear] = React.useState<any>(moment().format('YYYY'));
	const _handleDays = (id: number) => {
		const selectDateValue =
			crnttttmnth == 1
				? {
						id,
						date: moment()
							.add(id, 'days')
							.format('YYYY-MM-DD'),
				  }
				: {
						id,
						date: moment(selectedYear + '-' + selectedMonth + '-' + '01')
							.add(id, 'days')
							.format('YYYY-MM-DD'),
				  };
		updatedDate = selectDateValue.date;
		setSelectedDate(selectDateValue);

		setSelectedTimeRange(null);

		clearHighlight();
		getSlots(id);
	};

	const preparemonthData = (arg1: any) => {
		const daysCountDep: number = spaces.Availabledays.AllowBookingsBeforeXDays
			? spaces.Availabledays.AllowBookingsBeforeXDays
			: 62;
		let Noofdayscount: any = 0;

		let monthnumber = moment()
			.month(arg1)
			.format('MM');
		let dateArray: any = moment()
			.add(daysCountDep, 'days')
			.format('YYYY-MMMM-DD')
			.split('-');

		let LastDayOfMonth = moment()
			.add(daysCountDep, 'days')
			.format('YYYY-MM-DD');

		if (dateArray[0] === selectedYear.toString() && dateArray[1] === selectedMonth) {
			let eventdate = moment(LastDayOfMonth);
			let todaysdate = moment(LastDayOfMonth).format('YYYY-MM-01');
			Noofdayscount = eventdate.diff(todaysdate, 'days') + 1;
		} else {
			Noofdayscount = moment(selectedYear + '-' + monthnumber, 'YYYY-MM').daysInMonth();
		}
		let currentmnth: any = moment().format('MM');

		if (currentmnth == selectedMonth) {
			setcurntmnth(1);
			let a = moment().endOf('month');
			let b = moment();
			setnoofdays(a.diff(b, 'days') + 1);
		} else {
			setcurntmnth(0);

			setnoofdays(Noofdayscount);
		}
	};

	const monthChangeEventHandler = (event: any, value: any) => {
		let values = event.target.value.split(' ');
		let selectedMonth: string = values[0];

		let selectedYear: number = Number(values[1]);
		setselectedYear(selectedYear);
		let currentmnth: any = moment().format('MM');
		_handleDays(0);
		getSlots(0);
		let monthnumber = moment()
			.month(selectedMonth)
			.format('MM');
		let dateArray: any = moment()
			.add(daysCount, 'days')
			.format('YYYY-MMMM-DD')
			.split('-');
		let Noofdayscount: any = 0;
		let LastDayOfMonth = moment()
			.add(daysCount, 'days')
			.format('YYYY-MM-DD');

		if (dateArray[0] === selectedYear.toString() && dateArray[1] === selectedMonth) {
			let eventdate = moment(LastDayOfMonth);
			let todaysdate = moment(LastDayOfMonth).format('YYYY-MM-01');
			Noofdayscount = eventdate.diff(todaysdate, 'days') + 1;
		} else {
			Noofdayscount = moment(selectedYear + '-' + monthnumber, 'YYYY-MM').daysInMonth();
		}

		setselectedmonthval(monthnumber);

		if (currentmnth == monthnumber) {
			setcurntmnth(1);
			let a = moment().endOf('month');
			let b = moment();
			setnoofdays(a.diff(b, 'days') + 1);
		} else {
			setcurntmnth(0);

			setnoofdays(Noofdayscount);
		}

		let dateoldformat =
			currentmnth == monthnumber
				? { id: 0, date: moment().format('YYYY-MM-DD') }
				: { id: 0, date: selectedYear + '-' + monthnumber + '-' + '01' };
		updatedDate = dateoldformat.date;
		getSlots(0);
		setSelectedDate(dateoldformat);
	};

	const [noofdayscount, setnoofdays] = React.useState<any>(31);
	const [timeSlots, setTimeSlots] = React.useState([]);
	const [selectMonthListOption, setSelectMonthListOption] = React.useState<any>([]);
	const [selectedMonth, setselectedmonthval] = React.useState<any>(moment().format('MM'));
	const [keyval, setkeyval] = React.useState<any>(0);
	const [crnttttmnth, setcurntmnth] = React.useState<any>(0);
	//let crnttttmnth = 1;
	const startTimeSlot = '00:00';
	const endTimeSlot = '23:45';
	const interval = 15;
	// console.log('timeslots', timeSlots);
	const getSlots = (id: number) => {
		let slots: Array<any> | any = [];
		let startTime = moment(startTimeSlot, 'HH:mm');

		if (id == 0 && crnttttmnth == 1) {
			if (
				moment()
					.tz('Europe/London')
					.format('HH:mm') < moment(startTimeSlot, 'HH:mm').format('HH:mm')
			) {
				startTime = moment(startTimeSlot, 'HH:mm');
			} else {
				startTime = moment(getRoundedDate());
			}
		}
		var endTime = moment(endTimeSlot, 'HH:mm');
		if (startTime.isAfter(endTime)) {
			setTimeSlots(slots);
			return;
		}

		const buildingData = buildingentities.filter((buildingInfo: any) =>
			buildingInfo.Entity_Id === route.params.zoneId ? buildingInfo : null,
		);
		let buildingNumber: any = buildingData.length > 0 ? buildingData[0].BuildingId : null;

		const choosenDate =
			officeHoursData &&
			officeHoursData
				.slice(0)
				.filter((item: any) =>
					moment(item.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') === updatedDate ? item : null,
				);
		if (choosenDate.length > 0 && choosenDate[0].dayVisible) {
			setTimeSlots([]);
			//setBookRoombuttonText('');
			//setSelectedTimeRange(null);
		} else {
			while (startTime <= endTime) {
				if (buildingNumber !== null && choosenDate.length > 0) {
					if (
						moment(choosenDate[0].startTime, 'HH:mm') <= startTime &&
						moment(choosenDate[0].endTime, 'HH:mm').subtract('15', 'minutes') >= startTime
					) {
						slots.push(moment(startTime).format('HH:mm'));
						startTime.add(interval, 'minutes');
					} else {
						startTime.add(interval, 'minutes');
					}
				} else {
					slots.push(moment(startTime).format('HH:mm'));
					startTime.add(interval, 'minutes');
				}
			}
			if (tearDownHours && id == 0 && crnttttmnth == 1) {
				slots.shift();
			}
			setTimeSlots(slots);
		}
		return slots;
	};

	/* code for date slots ends*/

	React.useEffect(() => {
		setcurntmnth(crnttttmnth);
		getSlots(0);
	}, [crnttttmnth]);
	React.useEffect(() => {
		const fetchZoneData = async () => {
			const test = spaces.currentSiteId ? spaces.currentSiteId : '';
			spaces.fetchZoneDetails(test, route.params.floorId).then(async (res: any) => {
				await setZones(res.Zones);
			});
			const hrs = await spaces.dynamichours(user.currentSiteId);
			if (hrs !== '') {
				getSlots(0);
			}
			console.log('hours', hrs);
			setbuidlingentities(hrs.buildings_entities);

			setofficeHoursdnmc(hrs.officeHours);
		};
		fetchZoneData();
	}, [spaces.currentSiteId, route.params.floorId]);

	React.useEffect(() => {
		/*  api calling for office hours data */

		let currentmnthonly: any = moment().format('MM');

		const searchnew = new URLSearchParams(window.location.search);
		let diffnew: number = moment(searchnew.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days');
		let yeamtnday = moment()
			.add(diffnew, 'days')
			.format('MM');
		if (searchnew.get('startTime') && currentmnthonly !== yeamtnday) {
			let diff: number = moment(searchnew.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days');
			let yearandmonth = moment()
				.add(diff, 'days')
				.format('YYYY-MM');
			let Noofdayscount2: any = moment(yearandmonth, 'YYYY-MM').daysInMonth();
			setselectedmonthval(yeamtnday);
			setnoofdays(Noofdayscount2);
		} else if (currentmnthonly == yeamtnday) {
			setcurntmnth(1);
			let a = moment().endOf('month');
			let b = moment();
			setnoofdays(a.diff(b, 'days') + 1);
			setselectedmonthval(moment().format('MM'));
		} else {
			let a = moment().endOf('month');
			let b = moment();
			setnoofdays(a.diff(b, 'days') + 1);
			setselectedmonthval(moment().format('MM'));
		}

		/*  api calling for office hours data ends*/
	}, [user.currentSiteId]);

	FloorplanEditorCanvas.initDrawerOpen(appStores.drawerOpen);

	React.useEffect(() => {
		spaces.fetchAllClientsSpaces().then((list: any) => {
			let filteredFloorPlans = list.floorplans.filter((e: any) => e.buildingId === floordata.buildingId);
			if (filteredFloorPlans.length > 0) {
				setfloorList(filteredFloorPlans);
				setselectedFloor(floordata.floorId);
				setFloorName(floordata.floorName);
			}
		});
	}, [floordata.clientId]);

	const handleFloorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setselectedFloor(event.target.value as string);
		floordata.floorId = event.target.value;
		setquick(false);
		setdispmenu(0);
		routes.navigate(
			{
				name: 'spacesHome',
				params: {
					clientId: floordata.clientId,
					floorId: floordata.floorId,
				},
			},
			{
				clearParams: true,
			},
		);
	};
	const handleZoom = (counter: number) => {
		let canvasObj: fabric.Canvas = FloorplanEditorCanvas.getCanvas();
		canvasObj.zoomToPoint({ x: 20, y: 20 } as fabric.Point, counter);
		setCounter(counter);
	};

	const mapFilterOptions = (clientidfeatures: string) => {
		// const filterOptions = [
		// 	{
		// 		name: 'Display Screen',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '2' : equipmenttMap.Display,
		// 	},
		// 	{
		// 		name: 'Video Conference',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '25' : equipmenttMap.VideoConference,
		// 	},
		// 	{
		// 		name: 'Audio Conference',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '5' : equipmenttMap.AudioConference,
		// 	},
		// 	{
		// 		name: 'Flip Chart',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '6' : equipmenttMap.FlipChart,
		// 	},
		// 	{
		// 		name: 'Whiteboard',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '1' : equipmenttMap.Whiteboard,
		// 	},
		// 	{
		// 		name: 'Projector',
		// 		value: clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' ? '9' : equipmenttMap.Projector,
		// 	},
		// ];
	};
	const handelSelectedType = (event: React.ChangeEvent<{ value: number }>) => {
		setSelectedType(event.target.value);
		setFormSpacesTouched(false);
	};
	// const data = [
	// 	{ id: 34 },
	// 	{ id: 39 },
	// 	{ id: 36 },
	// 	{ id: 340 },
	// 	{ id: 34 },
	// 	{ id: 39 },
	// 	{ id: 36 },
	// 	{ id: 340 },
	// 	{ id: 34 },
	// 	{ id: 39 },
	// 	{ id: 36 },
	// 	{ id: 340 },
	// ];

	/* code for calendar dropdown */
	const [buildingentities, setbuidlingentities] = React.useState<any>([]);

	const daysCount: number = spaces.Availabledays.AllowBookingsBeforeXDays
		? spaces.Availabledays.AllowBookingsBeforeXDays
		: 62;
	// let monthsList: any = [];

	// let dateStart = moment(moment().format('L'));
	// let dateEnd = moment(
	// 	moment()
	// 		.add(daysCount, 'days')
	// 		.format('L'),
	// );
	// while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
	// 	monthsList.push(dateStart.format('MMMM YYYY'));
	// 	dateStart.add(1, 'month');
	// }
	// console.log('month', monthsList);

	React.useEffect(() => {
		makeDateOption();
	}, []);

	const makeDateOption = () => {
		let monthsList: any[] = [];
		let dateStart = moment(moment().format('L'));
		let dateEnd = moment(
			moment()
				.add(daysCount, 'days')
				.format('L'),
		);
		while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
			monthsList.push(dateStart.format('MMMM YYYY'));
			dateStart.add(1, 'month');
		}
		// selectMonthListOption, setSelectMonthListOption
		setSelectMonthListOption([...monthsList]);
	};

	// React.useEffect(() => {
	// 	const search = new URLSearchParams(window.location.search);
	// 	if (search.get('startTime') && search.get('endTime') && buildingentities != '') {
	// 		let currentmnthnew: any = moment().format('MM');
	// 		let searchyear: any = moment(search.get('startTime')).format('YYYY');
	// 		let searchmnth: any = moment(search.get('startTime')).format('MM');
	// 		let searchday: any = moment(search.get('startTime')).format('DD');
	// 		setnewDataVariablemonth(
	// 			moment(moment(searchyear + '-' + searchmnth + '-' + searchday).format('YYYY/MM/DD')).format('MMMM'),
	// 		);
	// 		setkeyval(keyval + 1);

	// 		let diff: number =
	// 			currentmnthnew == searchmnth
	// 				? moment(search.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days')
	// 				: moment(search.get('startTime'), 'YYYY-MM-DD').diff(
	// 						moment().format(searchyear + '-' + searchmnth + '-' + '01'),
	// 						'days',
	// 				  );

	// 		if (diff < 0) {
	// 			diff = 0;
	// 		}
	// 		const startTime: any = moment(search.get('startTime')).format('HH:mm');
	// 		const endTime: any = moment(search.get('endTime'))
	// 			.subtract(15, 'minutes')
	// 			.format('HH:mm');

	// 		const newDate =
	// 			currentmnthnew == searchmnth
	// 				? {
	// 						id: 0 + diff,
	// 						date: moment()
	// 							.add(diff, 'days')
	// 							.format('YYYY-MM-DD'),
	// 				  }
	// 				: {
	// 						id: 0 + diff,
	// 						date: moment()
	// 							.add(diff, 'days')
	// 							.format(searchyear + '-' + searchmnth + '-' + searchday),
	// 				  };

	// 		updatedDate = newDate.date;
	// 		setSelectedDate(newDate);

	// 		if (getSlots(0 + diff)) {
	// 			let startIndex = getSlots(0 + diff).findIndex((item: any) => item == startTime);
	// 			let endIndex = getSlots(0 + diff).findIndex((item: any) => item == endTime);
	// 			if (startIndex >= 0 && endIndex >= 0) {
	// 				setInitialTimeRange({ start: 'time_' + startIndex, end: 'time_' + endIndex });
	// 			}
	// 		}
	// 	} else if (search.get('startTime') && search.get('endTime')) {
	// 		let currentmnthnew: any = moment().format('MM');
	// 		let searchyear: any = moment(search.get('startTime')).format('YYYY');
	// 		let searchmnth: any = moment(search.get('startTime')).format('MM');
	// 		let searchday: any = moment(search.get('startTime')).format('DD');
	// 		setnewDataVariablemonth(
	// 			moment(moment(searchyear + '-' + searchmnth + '-' + searchday).format('YYYY/MM/DD')).format('MMMM'),
	// 		);
	// 		setkeyval(keyval + 1);

	// 		let diff: number =
	// 			currentmnthnew == searchmnth
	// 				? moment(search.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days')
	// 				: moment(search.get('startTime'), 'YYYY-MM-DD').diff(
	// 						moment().format(searchyear + '-' + searchmnth + '-' + '01'),
	// 						'days',
	// 				  );

	// 		if (diff < 0) {
	// 			diff = 0;
	// 		}
	// 		const startTime: any = moment(search.get('startTime')).format('HH:mm');
	// 		const endTime: any = moment(search.get('endTime'))
	// 			.subtract(15, 'minutes')
	// 			.format('HH:mm');

	// 		const newDate =
	// 			currentmnthnew == searchmnth
	// 				? {
	// 						id: 0 + diff,
	// 						date: moment()
	// 							.add(diff, 'days')
	// 							.format('YYYY-MM-DD'),
	// 				  }
	// 				: {
	// 						id: 0 + diff,
	// 						date: moment()
	// 							.add(diff, 'days')
	// 							.format(searchyear + '-' + searchmnth + '-' + searchday),
	// 				  };

	// 		updatedDate = newDate.date;
	// 		setSelectedDate(newDate);

	// 		if (getSlots(0 + diff)) {
	// 			let startIndex = getSlots(0 + diff).findIndex((item: any) => item == startTime);
	// 			let endIndex = getSlots(0 + diff).findIndex((item: any) => item == endTime);
	// 			if (startIndex >= 0 && endIndex >= 0) {
	// 				setInitialTimeRange({ start: 'time_' + startIndex, end: 'time_' + endIndex });
	// 			}
	// 		}
	// 	} else {
	// 		getSlots(0);
	// 		setSelectedDate({
	// 			id: 0,
	// 			date: moment().format('YYYY-MM-DD'),
	// 		});
	// 	}
	// }, [zoneSingleRecord, buildingentities]);

	/* code for calendar dropdown */

	/* code for date slider starts */

	const [officeHoursData, setOfficeHoursData] = React.useState<any>([]);
	const [selectedDate, setSelectedDate] = React.useState({ id: 0, date: moment().format('YYYY-MM-DD') });
	const [buildingId, setBuildingId] = React.useState<any>(null);
	const [dateSlots, setDateSlots] = React.useState<any>([]);

	let updatedDate = selectedDate.date;
	const [newDataVariablemonth1, setnewDataVariablemonth] = React.useState<any>(
		moment(moment(selectedDate.date).format('YYYY/MM/DD')).format('MMMM'),
	);

	React.useEffect(() => {
		const starnewDateTime = `${selectedDate.date}T00:00:00Z`;
		const endnewDateTime = `${selectedDate.date}T23:45:00Z`;
		// if (zoneSingleRecord) {
		// 	getBookingData(zoneSingleRecord && zoneSingleRecord.RoomId, starnewDateTime, endnewDateTime);
		// }
		if (finalRoomId !== undefined) {
			getBookingData(finalRoomId, starnewDateTime, endnewDateTime);
		}
	}, [selectedDate, finalRoomId, BookingId]);

	const [officeHoursdnmc, setofficeHoursdnmc] = React.useState<any>([]);
	const buildingData = buildingentities.filter((buildingInfo: any) =>
		buildingInfo.Entity_Id === route.params.zoneId ? buildingInfo : null,
	);

	React.useEffect(() => {
		dateTimeTrigger();
	}, []);

	const dateTimeTrigger = () => {
		let buildingNumber: any = '';

		if (buildingData.length > 0) {
			buildingNumber = buildingData[0].BuildingId;
			setBuildingId(buildingNumber);

			let hoursData = officeHoursdnmc.filter((officeHoursData: any) =>
				officeHoursData.buildingId === buildingNumber ? officeHoursData : null,
			);

			const apiCount: number = spaces.Availabledays.AllowBookingsBeforeXDays
				? spaces.Availabledays.AllowBookingsBeforeXDays
				: 62;

			let daysDiff = moment(hoursData[hoursData.length - 1].startDate, 'YYYY-MM-DD').diff(moment(), 'days');

			let endDay = hoursData[hoursData.length - 1].startDate;

			let remainingDays = apiCount - daysDiff;

			for (let i = 1; i <= remainingDays; i++) {
				let obj = {
					startDate: `${moment(endDay)
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					endDate: `${moment(endDay)
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					dayVisible: false,
					webOnly: false,
					startTime: '00:00:00',
					endTime: '24:00:00',
					buildingId: `${buildingNumber}`,
				};
				hoursData.push(obj);
			}

			for (let i = 0; i < daysDiff; i++) {
				let obj = {
					startDate: `${moment()
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					endDate: `${moment()
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					dayVisible: false,
					webOnly: false,
					startTime: '00:00:00',
					endTime: '24:00:00',
					buildingId: `${buildingNumber}`,
				};

				const foundItem = hoursData.find((item: any) => {
					return moment(item.startDate, 'YYYY-MM-DD').isSame(moment(obj.startDate, 'YYYY-MM-DD'));
				});
				if (foundItem === undefined) {
					hoursData.splice(i, 0, obj);
				}
			}

			setOfficeHoursData(hoursData);

			const monthlyDays =
				hoursData &&
				hoursData.slice(0).filter((dateValues: any) => {
					let month = new Date(dateValues.startDate).toLocaleString('default', { month: 'long' });
					let year = new Date(dateValues.startDate).getFullYear();
					let newupdatedmonth = moment(selectedDate.date).format('MMMM');
					let newupdatedyear = moment(selectedDate.date).format('YYYY');
					return month === newupdatedmonth && year === Number(newupdatedyear) ? dateValues : null;
				});

			let jsonObject = monthlyDays.map((e: any) => JSON.stringify(e));
			let uniqueSet = new Set(jsonObject);
			let uniqueArray = Array.from(uniqueSet).map((e: any) => JSON.parse(e));
			setDateSlots(uniqueArray);
			setSelectedDate({ id: 0, date: moment().format('YYYY-MM-DD') }); //need to remove added for error fix for compile
		}
	};

	/* code for date slider ends */

	return useObserver(() =>
		!Bkngs ? (
			<div className={classes.root}>
				<Backdrop className={classes.backdrop} open={bookingLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>

				{open && (
					<Box className={classes.statusMsg}>
						<Alert>
							<Typography variant="caption">
								{open === 'deleted' ? 'Deleted successfully!' : 'Floorplan coordinates saved successfully!'}
							</Typography>
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setStatusMessageOpen('');
								}}
							>
								<Icon icon={faTimesCircle} className={classes.closeIcon} />
							</IconButton>
						</Alert>
					</Box>
				)}

				<div
					style={{
						height: 'calc(100vh - 62px)',
						margin: 0,
						display: 'flex',
						padding: 0,
						overflowY: 'scroll',
					}}
					className={appStores.desktopDrawer ? classes.spcfixed : ''}
				>
					<div className={classes.framecontainer}>
						<Grid container spacing={1} style={{ width: '100%', height: '100%' }}>
							<Grid
								item
								xs={12}
								md={
									route.params.clientId != 'NA' &&
									route.params.floorId != 'NA' &&
									user.featurePermissions &&
									(user.featurePermissions.ROOMS || user.featurePermissions.PARKING || user.featurePermissions.DESKS)
										? 9
										: 12
								}
							>
								<div className={classes.paper}>
									<Grid container>
										<Grid item container xs={7} sm={7} justify="flex-start">
											<FormControl
												style={{ display: 'none' }}
												className={floorList.length > 0 ? classes.formControl : classes.formControlnew}
											>
												<InputLabel style={{ paddingLeft: '21px' }}>Floor</InputLabel>
												<Select
													MenuProps={{
														className: classes.selectItemHover,
														anchorOrigin: {
															vertical: 'bottom',
															horizontal: 'left',
														},
														transformOrigin: {
															vertical: 'top',
															horizontal: 'left',
														},
														getContentAnchorEl: null,
													}}
													value={selectedFloor}
													onChange={handleFloorChange}
												>
													{floorList.map((e: any) => {
														return e.active === 'True' ? (
															<MenuItem key={e.floorId} value={e.floorId}>
																{e.floorName}
															</MenuItem>
														) : (
															<MenuItem></MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</Grid>
										<Grid item container xs={5} sm={5} justify="flex-end">
											<Breadcrumbs
												aria-label="breadcrumb"
												separator=""
												color="textSecondary"
												style={{ height: '46px', display: 'none' }}
												className={classes.floorRoomNav}
											>
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														paddingTop: '15px',
														height: '54px',
													}}
												>
													<Link
														color="inherit"
														underline="none"
														onClick={flrplan}
														style={{ cursor: 'pointer' }}
														className={dispmenu == 0 ? classes.headerMenuLinkActive : ''}
													>
														Floor Plan
													</Link>
												</div>

												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														paddingTop: '15px',
														height: '54px',
													}}
												>
													<Link
														color="inherit"
														underline="none"
														onClick={quckbk}
														style={{ cursor: 'pointer' }}
														className={dispmenu == 1 ? classes.headerMenuLinkActive : ''}
													>
														Quick Book
													</Link>
												</div>
											</Breadcrumbs>
										</Grid>
									</Grid>
									<Divider className={classes.mainDivi} />
									<Grid container style={{ height: '100%' }}>
										<Grid item xs={12} style={{ marginTop: '5px', marginRight: '11px' }}>
											{quckbkval == false ? (
												route.params.clientId != 'NA' &&
												route.params.floorId != 'NA' &&
												user.featurePermissions &&
												(user.featurePermissions.ROOMS ||
													user.featurePermissions.PARKING ||
													user.featurePermissions.DESKS) ? (
													<div
														id="canvasWrapper"
														className={appStores.drawerOpen ? classes.canvasWrapperDrawerOpen : classes.canvasscreen}
													>
														<div style={{ position: 'relative', width: '100%' }}>
															<canvas id="floorplanCanvas"></canvas>
														</div>
													</div>
												) : (
													<div className={classes.filterDimensions} style={{ marginBottom: '0px', width: '100%' }}>
														<img
															src={underConstruction}
															className={classes.accessDimensions4}
															alt="underconstruction"
														/>
														<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
															We don't have any floor plans yet for this site
														</Typography>
														<Typography variant="caption">Please check again soon</Typography>
													</div>
												)
											) : roomslength > 0 ? (
												entityType == 'room' ? (
													<div className={classes.filterDimensions} style={{ marginBottom: '0px', width: '100%' }}>
														<img src={Successfullsearch} className={classes.accessDimensions} alt="underconstruction" />
														<Typography
															variant="subtitle2"
															style={{ marginTop: '47.75px' }}
															className={classes.textDimension3}
														>
															{roomslength} rooms are available using these filters
														</Typography>
														<Typography variant="caption" className={classes.textDimension4}>
															Please click below to book
														</Typography>
														<br />
														<br />
														<br />
													</div>
												) : (
													<div className={classes.filterDimensions} style={{ marginBottom: '-6px', width: '100%' }}>
														<img src={Successfullsearch} className={classes.accessDimensions} alt="underconstruction" />
														<Typography
															variant="subtitle2"
															style={{ marginTop: '47px' }}
															className={classes.textDimension3}
														>
															{roomslength} desks are available using these filters
														</Typography>
														<Typography variant="caption" className={classes.textDimension4}>
															Please click below to book
														</Typography>
														<br />
														<br />
														<br />
													</div>
												)
											) : entityType == 'room' ? (
												<>
													<div className={classes.filterDimensions} style={{ marginBottom: '0px', width: '100%' }}>
														<img src={Nofloors} className={classes.accessDimensions} alt="underconstruction" />
														<Typography
															variant="subtitle2"
															style={{ marginTop: '20px' }}
															className={classes.textDimension1}
														>
															No rooms are available using these filters
														</Typography>
														<Typography variant="caption" className={classes.textDimension2}>
															Please try a different search
														</Typography>
														<br />
														<br />
														<br />
													</div>
												</>
											) : (
												<div className={classes.filterDimensions} style={{ marginBottom: '0px', width: '100%' }}>
													<img src={Nofloors} className={classes.accessDimensions} alt="underconstruction" />
													<Typography
														variant="subtitle2"
														style={{ marginTop: '20px' }}
														className={classes.textDimension1}
													>
														No desks are available using these filters
													</Typography>
													<Typography variant="caption" className={classes.textDimension2}>
														Please try a different search
													</Typography>
													<br />
													<br />
													<br />
												</div>
											)}
										</Grid>
									</Grid>
									{route.params.clientId != 'NA' &&
									route.params.floorId != 'NA' &&
									user.featurePermissions &&
									(user.featurePermissions.ROOMS ||
										user.featurePermissions.PARKING ||
										user.featurePermissions.DESKS) ? (
										<Grid>
											<div
												style={{
													position: 'sticky',
													overflow: 'hidden',
													height: '56px',
													top: 'calc(100vh-50px)',
												}}
												className={classes.stickyresponsive}
											>
												<Divider
													style={{
														height: '0.5px',
														backgroundColor: '#66CCFA',
														width: '98%',
														marginLeft: '1%',
													}}
												/>

												{quckbkval == false ? (
													<div
														style={{
															position: 'absolute',
															width: '300px',
															height: '200px',
															zIndex: 15,
															left: '50%',
															margin: '0px 0 0 -100px',
														}}
													>
														<FormGroup aria-label="position" row>
															{floorImgLoaded ||
															(route.params.clientId != 'NA' && route.params.floorId != 'NA') ||
															(user.featurePermissions &&
																(user.featurePermissions.ROOMS ||
																	user.featurePermissions.PARKING ||
																	user.featurePermissions.DESKS)) ? (
																<FormControlLabel
																	value="end"
																	style={{ marginTop: '17px' }}
																	control={
																		<ButtonGroup size="small" aria-label="small outlined button group">
																			<span
																				onClick={() => handleZoom(Math.max(counter - 1, 1))}
																				style={{ marginRight: '2px' }}
																			>
																				<img
																					style={{ width: '24px', marginTop: '-2px' }}
																					src={ZoomOutImg}
																					alt="Zoom out"
																				/>
																			</span>
																			<div>
																				<Slider
																					value={counter}
																					min={1}
																					max={5}
																					onChange={handleChangenew}
																					style={{ bottom: '5px', width: '110px' }}
																					aria-labelledby="continuous-slider"
																				/>
																			</div>
																			<span
																				onClick={() => handleZoom(Math.min(counter + 1, 5))}
																				style={{ marginLeft: '12px' }}
																			>
																				<img
																					style={{ width: '24px', marginTop: '-3px' }}
																					src={ZoomInImg}
																					alt="Zoom In"
																				/>
																			</span>
																		</ButtonGroup>
																	}
																	label=""
																	labelPlacement="start"
																/>
															) : (
																''
															)}
														</FormGroup>
													</div>
												) : roomslength > 0 ? (
													entityType == 'room' ? (
														<div
															style={{
																position: 'absolute',
																width: '307px',
																height: '200px',
																zIndex: 15,
																left: '46%',
																margin: '0px 0 0 -100px',
															}}
														>
															<Button
																size="small"
																variant="contained"
																color="primary"
																onClick={ConfirmMsg}
																className={classes.disabledButton}
															>
																Quick Book a room for {uQuickRoomButton}
															</Button>
														</div>
													) : (
														<div
															style={{
																position: 'absolute',
																width: '307px',
																height: '200px',
																zIndex: 15,
																left: '46%',
																margin: '0px 0 0 -100px',
															}}
														>
															<Button
																variant="contained"
																size="small"
																color="primary"
																onClick={DeskBookingfinal}
																className={classes.disabledButton}
															>
																Quick Book a desk for {uQuickDeskButton}
															</Button>
														</div>
													)
												) : entityType == 'room' ? (
													<div
														style={{
															position: 'absolute',
															width: '307px',
															height: '200px',
															zIndex: 15,
															left: '46%',
															margin: '0px 0 0 -100px',
														}}
													>
														{' '}
														<Button
															variant="contained"
															color="primary"
															size="small"
															//onClick={Roombookingfinal}
															disabled
															className={classes.disabledButton}
														>
															Quick Book a room for {uQuickRoomButton}
														</Button>
													</div>
												) : (
													<div
														style={{
															position: 'absolute',
															width: '307px',
															height: '200px',
															zIndex: 15,
															left: '46%',
															margin: '0px 0 0 -100px',
														}}
													>
														<Button
															variant="contained"
															color="primary"
															size="small"
															onClick={DeskBookingfinal}
															disabled
															className={classes.disabledButton}
														>
															Quick Book a desk for {uQuickDeskButton}
														</Button>
													</div>
												)}
											</div>
										</Grid>
									) : (
										''
									)}
								</div>
							</Grid>
							{route.params.clientId != 'NA' &&
							route.params.floorId != 'NA' &&
							user.featurePermissions &&
							(user.featurePermissions.ROOMS || user.featurePermissions.PARKING || user.featurePermissions.DESKS) ? (
								<Grid
									item
									xs={12}
									md={3}
									style={{ boxShadow: '-8px 8px 8px #327ca129', borderRadius: '0px 0px 0px 24px' }}
								>
									<div className={classes.paper}>
										<Grid container>
											<AppBar position="static" style={{ backgroundColor: 'white' }} id={'tabsDynamicHeight'}>
												<Tabs
													value={value}
													onChange={handleChange}
													indicatorColor="primary"
													variant="fullWidth"
													aria-label="full width tabs example"
													className={classes.tabs}
												>
													{user.featurePermissions && user.featurePermissions.ROOMS ? (
														<div
															className={classes.spacesImg}
															style={entityType == 'room' ? { border: '1px solid #002855' } : {}}
															onClick={() => {
																clearFilters('desk');
																clearFilters('parking');
																clearFilters('space');
																setDispSpaceAndDesk(1);
																setEntityType('room');
																applyFilters();
															}}
														>
															<Tab
																label="Meeting Rooms"
																style={
																	entityType == 'room'
																		? { fontWeight: 'bold', height: '31px !important', width: '135px' }
																		: {}
																}
																className={
																	dispSpaceAndDesk == 1
																		? classes.headerSpacesAndDesksLinkActive
																		: classes.spaceAndDeskheaders
																}
																{...a11yProps(0)}
															/>
														</div>
													) : (
														''
													)}
													{user.featurePermissions && user.featurePermissions.DESKS ? (
														<div
															className={classes.deskImg}
															onClick={() => {
																// applyFiltersDesksNew();

																clearFilters('space');
																clearFilters('parking');
																clearFilters('desk');
																setEntityType('desk');
																applyFiltersDesks();
																setDispSpaceAndDesk(0);
															}}
															style={entityType == 'desk' ? { border: '1px solid #002855' } : {}}
														>
															<Tab
																style={entityType == 'desk' ? { fontWeight: 'bold' } : {}}
																label="Desks"
																className={
																	dispSpaceAndDesk == 0
																		? classes.headerSpacesAndDesksLinkActive
																		: classes.spaceAndDeskheaders
																}
																{...a11yProps(1)}
															/>
														</div>
													) : (
														''
													)}
													{user.featurePermissions && user.featurePermissions.PARKING ? (
														<div
															className={classes.parkingImg}
															style={entityType == 'parking' ? { border: '1px solid #002855' } : {}}
															onClick={() => {
																clearFilters('desk');
																clearFilters('space');
																clearFilters('parking');
																setEntityType('parking');
																applyFiltersNew();
																setDispSpaceAndDesk(2);
															}}
														>
															<Tab
																label="Parking"
																style={
																	entityType == 'parking' ? { fontWeight: 'bold', minHeight: '31px !important' } : {}
																}
																className={
																	dispSpaceAndDesk == 2
																		? classes.headerSpacesAndDesksLinkActive
																		: classes.spaceAndDeskheaders
																}
																{...a11yProps(0)}
															/>
														</div>
													) : (
														''
													)}
													{/* <Button onClick={() => setShowMap(!showMap)}>Showmap</Button> */}
												</Tabs>
											</AppBar>
										</Grid>
										<Divider style={{ height: '0.5px', marginTop: '1%', backgroundColor: '#66CCFA' }} />
										{showMap ? (
											<Grid container style={{ height: '100%', paddingTop: '14px' }} className={classes.selectFloorBox}>
												<Grid item xs={12} style={{ marginTop: '21px' }} className={classes.overflowX}>
													<Grid
														item
														className={`${classes.gridPadding} ${classes.floorMargin}`}
														style={{
															marginTop: '33px',
															paddingLeft: '16px',
															paddingRight: '16px',
														}}
														container
														// xs={7}
														// sm={7}
														justify="flex-start"
													>
														<FormControl
															className={floorList.length > 0 ? classes.floorFormControl : classes.floorFormControlnew}
														>
															<InputLabel
																style={{
																	paddingLeft: '5px',
																	transform: 'translate(0, -21px) scale(0.80)',
																	color: '#002855',
																	height: '19px',
																}}
															>
																{' '}
																Select Floor
															</InputLabel>
															<Select
																MenuProps={{
																	className: classes.selectItemHover,
																	anchorOrigin: {
																		vertical: 'bottom',
																		horizontal: 'left',
																	},
																	transformOrigin: {
																		vertical: 'top',
																		horizontal: 'left',
																	},
																	getContentAnchorEl: null,
																}}
																value={selectedFloor}
																onChange={handleFloorChange}
															>
																{floorList.map((e: any) => {
																	return e.active === 'True' ? (
																		<MenuItem key={e.floorId} value={e.floorId}>
																			{e.floorName}
																		</MenuItem>
																	) : (
																		''
																	);
																})}
															</Select>
														</FormControl>
													</Grid>
													<div className={classes.tabPanel}>
														<TabPanel value={value} index={0}>
															<InputLabel
																style={{
																	paddingLeft: '5px',
																	transform: 'translate(-3px, -27.5px) scale(0.80)',
																	color: '#002855',
																	height: '19px',
																}}
															>
																{' '}
																Select Date and Time
															</InputLabel>
															<div className={classes.gridPadding}>
																<div style={{ display: 'flex', gap: '8px' }}>
																	<Grid item>
																		<MuiPickersUtilsProvider utils={DateFnsUtils}>
																			{/* <KeyboardDateTimePicker
																				ampm={false}
																				className={classes.pickerStyle}
																				placeholder="Start"
																				id="time-picker"
																				minDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format(
																					'YYYY-MM-DDTHH:mm',
																				)}
																				maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																					.add(60, 'days')
																					.format('YYYY-MM-DDTHH:mm')}
																				value={spaceForm.StartTime}
																				onChange={evt => {
																					_handleFormChange('StartTime', moment(evt, 'YYYY-MM-DDTHH:mm').format());
																					_handleFormChange(
																						'EndTime',
																						moment(evt, 'YYYY-MM-DDTHH:mm')
																							.add(15, 'minutes')

																							.format(),
																					);
																				}}
																				disablePast
																				format="dd/MM/yyyy HH:mm"
																				minutesStep={15}
																				keyboardIcon={<CalendarIcon />}
																			/> */}
																			{entityType === 'desk' ? (
																				<KeyboardDateTimePicker
																					ampm={false}
																					className={classes.pickerStyle}
																					placeholder="Start"
																					id="time-picker"
																					minDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format(
																						'YYYY-MM-DDTHH:mm',
																					)}
																					maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																						.add(60, 'days')
																						.format('YYYY-MM-DDTHH:mm')}
																					value={deskForm.StartTime}
																					onChange={evt => {
																						_handleFormChange(
																							'StartTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm').format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																						_handleFormChange(
																							'EndTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm')
																								.add(15, 'minutes')

																								.format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																					}}
																					disablePast
																					format="dd/MM/yyyy HH:mm"
																					minutesStep={15}
																					keyboardIcon={<CalendarIcon />}
																				/>
																			) : (
																				<KeyboardDateTimePicker
																					ampm={false}
																					className={classes.pickerStyle}
																					placeholder="Start"
																					id="time-picker"
																					minDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format(
																						'YYYY-MM-DDTHH:mm',
																					)}
																					maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																						.add(60, 'days')
																						.format('YYYY-MM-DDTHH:mm')}
																					value={spaceForm.StartTime}
																					onChange={evt => {
																						_handleFormChange(
																							'StartTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm').format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																						_handleFormChange(
																							'EndTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm')
																								.add(15, 'minutes')

																								.format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																					}}
																					disablePast
																					format="dd/MM/yyyy HH:mm"
																					minutesStep={15}
																					keyboardIcon={<CalendarIcon />}
																				/>
																			)}
																		</MuiPickersUtilsProvider>
																	</Grid>
																	<Grid item>
																		<MuiPickersUtilsProvider utils={DateFnsUtils}>
																			{/* <KeyboardDateTimePicker
																				className={classes.pickerStyle}
																				ampm={false}
																				placeholder="End Time"
																				minDate={spaceForm.StartTime}
																				maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																					.add(60, 'days')
																					.format('YYYY-MM-DDTHH:mm')}
																				value={spaceForm.EndTime}
																				onChange={evt => {
																					_handleFormChange('EndTime', moment(evt, 'YYYY-MM-DDTHH:mm').format());
																					updateStartDate(moment(evt, 'YYYY-MM-DDTHH:mm'));
																				}}
																				disablePast
																				format="dd/MM/yyyy HH:mm"
																				minutesStep={15}
																				keyboardIcon={<CalendarIcon />}
																			/> */}
																			{entityType === 'desk' ? (
																				<KeyboardDateTimePicker
																					className={classes.pickerStyle}
																					ampm={false}
																					placeholder="End Time"
																					minDate={deskForm.StartTime}
																					maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																						.add(60, 'days')
																						.format('YYYY-MM-DDTHH:mm')}
																					value={deskForm.EndTime}
																					onChange={evt => {
																						_handleFormChange(
																							'EndTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm').format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																						updateStartDate(
																							moment(evt, 'YYYY-MM-DDTHH:mm'),
																							entityType === 'desk' ? 'desk' : 'spaces',
																						);
																					}}
																					disablePast
																					format="dd/MM/yyyy HH:mm"
																					minutesStep={15}
																					keyboardIcon={<CalendarIcon />}
																				/>
																			) : (
																				<KeyboardDateTimePicker
																					className={classes.pickerStyle}
																					ampm={false}
																					placeholder="End Time"
																					minDate={spaceForm.StartTime}
																					maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																						.add(60, 'days')
																						.format('YYYY-MM-DDTHH:mm')}
																					value={spaceForm.EndTime}
																					onChange={evt => {
																						_handleFormChange(
																							'EndTime',
																							moment(evt, 'YYYY-MM-DDTHH:mm').format(),
																							entityType === 'desk' ? 'desk' : 'space',
																						);
																						updateStartDate(
																							moment(evt, 'YYYY-MM-DDTHH:mm'),
																							entityType === 'desk' ? 'desk' : 'spaces',
																						);
																					}}
																					disablePast
																					format="dd/MM/yyyy HH:mm"
																					minutesStep={15}
																					keyboardIcon={<CalendarIcon />}
																				/>
																			)}
																		</MuiPickersUtilsProvider>
																	</Grid>
																</div>

																<Grid item style={{ marginTop: '28px', float: 'right', marginRight: '0px' }}>
																	<FormGroup aria-label="position" row>
																		{
																			<ButtonGroup
																				size="small"
																				aria-label="small outlined button group"
																				style={{ marginLeft: '26px', marginRight: '11px' }}
																			></ButtonGroup>
																		}
																	</FormGroup>
																	<br />
																</Grid>
																{entityType === 'room' || entityType === 'parking' ? (
																	<div>
																		<Grid item style={{ float: 'right', marginRight: '0px', marginTop: '-5px' }}>
																			<FormControl
																				className={
																					floorList.length > 0
																						? `${classes.floorFormControl} ${
																								selectedType == -1 ? classes.placeholderItem : ''
																						  }`
																						: `${classes.floorFormControlnew} ${
																								selectedType == -1 ? classes.placeholderItem : ''
																						  }`
																				}
																				style={{ marginLeft: 0 }}
																			>
																				<InputLabel
																					style={{
																						paddingLeft: '0px',
																						transform: 'translate(0, -18px) scale(0.80)',
																						color: '#002855',
																						height: '19px',
																					}}
																				>
																					{' '}
																					Select Type
																				</InputLabel>
																				<Select
																					MenuProps={{
																						className: classes.selectItemHover,
																						anchorOrigin: {
																							vertical: 'bottom',
																							horizontal: 'left',
																						},

																						transformOrigin: {
																							vertical: 'top',
																							horizontal: 'left',
																						},
																						getContentAnchorEl: null,
																					}}
																					value={selectedType}
																					onChange={handelSelectedType}
																					// onFocus={e => setShowPlaceholderSelectType(false)}
																					// onClose={(e: React.ChangeEvent<{ value: number }>) =>
																					// 	setShowPlaceholderSelectType(e.target.value === 0)
																					// }
																				>
																					<MenuItem disabled value="-1" style={{ color: '#8B8DAE' }}>
																						Please select
																					</MenuItem>
																					{spaces.eventList &&
																						spaces.eventList.map((e: any) => {
																							return (
																								<MenuItem key={e.id} value={e.id}>
																									{e.description}
																								</MenuItem>
																							);
																						})}
																				</Select>
																			</FormControl>
																		</Grid>
																		<FormGroup
																			aria-label="position"
																			style={{
																				display: 'inline-flex',
																				flexWrap: 'wrap',
																				flexDirection: 'column',
																				marginTop: '0px',
																				width: '100%',
																				float: 'left',
																				lineHeight: '0.7',
																			}}
																		>
																			<label htmlFor="" style={{ marginLeft: '4px !important', display: 'none' }}>
																				Equipment{' '}
																			</label>
																			<br />
																			<div className={classes.filtelayout}>
																				<FormControlLabel
																					value={
																						clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																							? '2'
																							: equipmenttMap.Display
																					}
																					checked={chkval}
																					control={<Checkbox color="primary" />}
																					label="Display Screen"
																					labelPlacement="end"
																					name="DisplayScreen"
																					onChange={evt => {
																						setCheckval((evt.target as HTMLInputElement).checked);
																						_handleCheckBox(
																							'Roomfeatures',
																							(evt.target as HTMLInputElement).value,
																							(evt.target as HTMLInputElement).checked,
																						);
																					}}
																				/>
																				<div>
																					<FormControlLabel
																						value={
																							clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																								? '25'
																								: equipmenttMap.VideoConference
																						}
																						control={<Checkbox color="primary" />}
																						label="Video Conference"
																						checked={chkval1}
																						labelPlacement="end"
																						name="Video Conference"
																						onChange={evt => {
																							setCheckval1((evt.target as HTMLInputElement).checked);
																							_handleCheckBox(
																								'Roomfeatures',
																								(evt.target as HTMLInputElement).value,
																								(evt.target as HTMLInputElement).checked,
																							);
																						}}
																					/>
																				</div>
																				<div>
																					<FormControlLabel
																						value={
																							clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																								? '5'
																								: equipmenttMap.AudioConference
																						}
																						control={<Checkbox color="primary" />}
																						label="Audio Conference"
																						checked={chkval2}
																						labelPlacement="end"
																						name="Audio Conference"
																						onChange={evt => {
																							setCheckval2((evt.target as HTMLInputElement).checked);
																							_handleCheckBox(
																								'Roomfeatures',
																								(evt.target as HTMLInputElement).value,
																								(evt.target as HTMLInputElement).checked,
																							);
																						}}
																					/>
																				</div>
																				<div>
																					<FormControlLabel
																						value={
																							clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																								? '6'
																								: equipmenttMap.FlipChart
																						}
																						control={<Checkbox color="primary" />}
																						label="Flip Chart"
																						checked={chkval3}
																						labelPlacement="end"
																						name="FlipChart"
																						onChange={evt => {
																							setCheckval3((evt.target as HTMLInputElement).checked);
																							_handleCheckBox(
																								'Roomfeatures',
																								(evt.target as HTMLInputElement).value,
																								(evt.target as HTMLInputElement).checked,
																							);
																						}}
																					/>
																				</div>

																				<div>
																					<FormControlLabel
																						value={
																							clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																								? '1'
																								: equipmenttMap.Whiteboard
																						}
																						control={<Checkbox color="primary" />}
																						label="Whiteboard"
																						checked={chkval4}
																						labelPlacement="end"
																						name="Whiteboard"
																						onChange={evt => {
																							setCheckval4((evt.target as HTMLInputElement).checked);
																							_handleCheckBox(
																								'Roomfeatures',
																								(evt.target as HTMLInputElement).value,
																								(evt.target as HTMLInputElement).checked,
																							);
																						}}
																					/>
																				</div>

																				<div>
																					<FormControlLabel
																						value={
																							clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25'
																								? '9'
																								: equipmenttMap.Projector
																						}
																						control={<Checkbox color="primary" />}
																						label="Projector"
																						checked={chkval5}
																						labelPlacement="end"
																						name="Projector"
																						onChange={evt => {
																							setCheckval5((evt.target as HTMLInputElement).checked);
																							_handleCheckBox(
																								'Deskfeatures',
																								(evt.target as HTMLInputElement).value,
																								(evt.target as HTMLInputElement).checked,
																							);
																						}}
																					/>
																				</div>
																			</div>
																		</FormGroup>
																	</div>
																) : null}
																{entityType === 'room' ? (
																	<Grid item style={{ float: 'right', marginRight: '0px', marginTop: '44px' }}>
																		<FormControl
																			className={
																				floorList.length > 0
																					? `${classes.floorFormControl} ${
																							selectedFilter.findIndex(
																								(x: any) => x.description === 'Meeting Equipment',
																							) !== -1
																								? classes.placeholderItem
																								: ''
																					  }`
																					: `${classes.floorFormControlnew} ${
																							selectedFilter.findIndex(
																								(x: any) => x.description === 'Meeting Equipment',
																							) !== -1
																								? classes.placeholderItem
																								: ''
																					  }`
																			}
																			style={{ marginLeft: 0 }}
																		>
																			<InputLabel
																				id="demo-mutiple-checkbox-label"
																				style={{
																					paddingLeft: '0px',
																					transform: 'translate(0, -22px) scale(0.80)',
																					color: '#002855',
																					height: '19px',
																					width: '72px',
																				}}
																			>
																				Equipment{' '}
																			</InputLabel>
																			<Select
																				// style={{ maxHeight: '164px important', top: '657px' }}
																				labelId="demo-mutiple-checkbox-label"
																				id="demo-mutiple-checkbox"
																				multiple
																				value={selectedFilter}
																				onChange={handleChangeFilter}
																				input={<Input />}
																				renderValue={(selected: any[]) => selected.map(x => x.description).join(', ')}
																				MenuProps={{
																					className: classes.selectItemHover,
																					style: { height: '250px' },
																					anchorOrigin: {
																						vertical: 'bottom',
																						horizontal: 'left',
																					},
																					getContentAnchorEl: null,
																				}}
																			>
																				{spaces.featuresData &&
																					spaces.featuresData.map((item: any) => (
																						<MenuItem key={item?.id} value={item}>
																							<Checkbox
																								checked={
																									selectedFilter.findIndex((filter: any) => filter.id === item.id) >= 0
																								}
																							/>
																							<ListItemText primary={item.description} />
																						</MenuItem>
																					))}
																			</Select>
																		</FormControl>
																	</Grid>
																) : null}
															</div>
														</TabPanel>
													</div>

													<TabPanel value={value} index={1}>
														<div className={classes.gridPadding}>
															<Grid item>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDateTimePicker
																		className={classes.pickerStyle}
																		ampm={false}
																		label="Start"
																		minDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm')}
																		maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																			.add(60, 'days')
																			.format('YYYY-MM-DDTHH:mm')}
																		value={deskForm.StartTime}
																		onChange={evt => {
																			_handleFormChange('StartTime', moment(evt, 'YYYY-MM-DDTHH:mm').format(), 'desk');
																			_handleFormChange(
																				'EndTime',
																				moment(evt, 'YYYY-MM-DDTHH:mm')
																					.add(15, 'minutes')

																					.format(),
																				'desk',
																			);
																		}}
																		disablePast
																		format="dd/MM/yyyy HH:mm"
																		minutesStep={15}
																		keyboardIcon={
																			<WatchLater style={{ height: '0.75em', color: '#5B6F86' }}></WatchLater>
																		}
																	/>
																</MuiPickersUtilsProvider>
															</Grid>
															<Grid item style={{ marginTop: '16px' }}>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDateTimePicker
																		className={classes.pickerStyle}
																		ampm={false}
																		label="Finish"
																		minDate={deskForm.StartTime}
																		maxDate={moment(getRoundedDate(), 'YYYY-MM-DDTHH:mm')
																			.add(60, 'days')
																			.format('YYYY-MM-DDTHH:mm')}
																		value={deskForm.EndTime}
																		onChange={evt => {
																			_handleFormChange('EndTime', moment(evt, 'YYYY-MM-DDTHH:mm').format(), 'desk');
																			updateStartDate(moment(evt, 'YYYY-MM-DDTHH:mm'), 'desk');
																		}}
																		disablePast
																		format="dd/MM/yyyy HH:mm"
																		minutesStep={15}
																		keyboardIcon={
																			<WatchLater style={{ height: '0.75em', color: '#5B6F86' }}></WatchLater>
																		}
																	/>
																</MuiPickersUtilsProvider>
															</Grid>
														</div>
													</TabPanel>
												</Grid>
											</Grid>
										) : (
											// <BookingMenu />
											//second form code
											<div style={{ height: '100%' }}>
												<Grid container style={{ marginTop: '25px', paddingTop: '16.5px' }}>
													<Grid item xs={12} md={12}>
														<FormControl
															variant="outlined"
															style={{ minWidth: '90%', marginLeft: '10px', paddingRight: '10px' }}
														>
															<InputLabel
																style={{
																	paddingLeft: '1px',
																	transform: 'translate(0, -16.5px) scale(0.80)',
																	fontSize: '16px',
																	color: '#002855',
																}}
															>
																Available times
															</InputLabel>
															<Select
																style={{
																	height: '48px',
																	width: '350px',
																	borderRadius: '8px',
																	paddingRight: '10px',
																	color: '#002855',
																}}
																defaultValue={
																	selectMonthListOption[
																		selectMonthListOption.indexOf(`${newDataVariablemonth1} ${selectedYear}`)
																	]
																}
																MenuProps={{
																	className: classes.selectItemHover,
																	anchorOrigin: {
																		vertical: 'bottom',
																		horizontal: 'left',
																	},
																	transformOrigin: {
																		vertical: 'top',
																		horizontal: 'left',
																	},
																	getContentAnchorEl: null,
																}}
																onChange={(event: any, value: any) => monthChangeEventHandler(event, value)}
																IconComponent={Calendar}
																classes={{
																	iconOpen: classes.iconOpen,
																}}
															>
																{selectMonthListOption.map((res: any) => (
																	<MenuItem style={{ minWidth: '346px' }} value={res}>
																		{res}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>

													<Grid item xs={5} md={5}>
														<Box style={{ paddingLeft: '11px', color: '#002855', fontSize: '16px', paddingTop: '7px' }}>
															Day
														</Box>
													</Grid>
													<Grid item xs={5} md={5}>
														<Box style={{ paddingLeft: '10px', color: '#002855', fontSize: '16px', paddingTop: '7px' }}>
															Time
														</Box>
													</Grid>
												</Grid>

												<Grid container>
													<Grid item xs={5} md={5} className={classes.gridStyle} id={'dynamicHeight'}>
														{buildingId !== null && dateSlots.length > 0
															? dateSlots?.map((dateRange: any, id: any) => {
																	return (
																		<Button
																			key={id}
																			id={'days_' + id}
																			onClick={() => _handleDays(id)}
																			className={selectedDate.id == id ? classes.timebtnnew : `${classes.timebtn}`}
																			color="primary"
																			disabled={dateRange.dayVisible}
																			variant={selectedDate.id == id ? 'contained' : 'outlined'}
																			style={{ margin: '1px', minWidth: '0', boxShadow: 'none' }}
																		>
																			{moment(new Date(dateRange.startDate))
																				.format('DD/MM')
																				.toString()}
																		</Button>
																	);
															  })
															: Array(noofdayscount)
																	.fill(0)
																	.map((_, id) => (
																		<Button
																			key={id}
																			id={'days_' + id}
																			onClick={() => _handleDays(id)}
																			className={selectedDate.id == id ? classes.timebtnnew : classes.timebtn}
																			color="primary"
																			variant={selectedDate.id == id ? 'contained' : 'outlined'}
																		>
																			<Moment format="ddd - DD/MM">
																				{crnttttmnth == 1
																					? moment().add(id, 'days')
																					: moment(selectedMonth).add(id, 'days')}
																			</Moment>
																		</Button>
																	))}
													</Grid>

													<Grid item xs={5} md={5} className={classes.gridStyle} id={'dynamicHeightTimeslot'}>
														{timeSlots.map((time, id) => {
															return (
																<Button
																	key={'time_' + id}
																	id={'time_' + id}
																	onClick={() => _handleTimeRange('time_' + id)}
																	className={`${classes.timebtn} timeBtnDefault`}
																	disabled={checkSlotStatus('time_' + id)}
																	variant="outlined"
																>
																	{time}
																</Button>
															);
														})}
													</Grid>
												</Grid>

												{/* {currentAvailableTime ? '' : ''} */}
											</div>

											//second form code ends
										)}

										<Grid>
											{entityType == 'room' ? (
												<>
													<div
														style={{
															position: 'sticky',
															overflow: 'hidden',
															height: '56px',
															top: 'calc(100vh-50px)',
														}}
														className={classes.stickyresponsive}
													>
														<Divider style={{ height: '0.5px', backgroundColor: '#66CCFA', marginBottom: '2%' }} />
														<div style={{ marginRight: '8px', float: 'right' }}>
															<Button
																size="small"
																variant="outlined"
																className={classes.backbutton}
																onClick={() => setShowMap(!showMap)}
																style={{
																	textDecoration: 'none',
																	float: 'left',
																	display: showMap == false ? 'block' : 'none',
																	height: '36px',
																	width: '64px',
																}}
															>
																Back
															</Button>

															<div style={{ display: showMap == false ? 'none' : 'block', float: 'left' }}>
																<Button
																	size="small"
																	variant="outlined"
																	color="primary"
																	disabled={formSpacesTouched}
																	onClick={() => clearFilters('space')}
																	style={{ border: '1px solid #cfdbe5', height: '36px', width: '104px' }}
																>
																	Clear filters
																</Button>
																<Button
																	size="small"
																	variant="contained"
																	color="primary"
																	className={classes.applyFilterButton}
																	onClick={() => applyFilters()}
																>
																	Apply filters
																</Button>
															</div>

															<Button
																size="small"
																variant="contained"
																className={classes.booknowButton}
																disabled={
																	!selectedFloor || (showMap == false && !getSelectedTimeRange().start) ? true : false
																}
																onClick={ConfirmMsg}
															>
																Book now
															</Button>
														</div>
													</div>
												</>
											) : entityType == 'parking' ? (
												<>
													<div
														style={{
															position: 'sticky',
															overflow: 'hidden',
															height: '56px',
															top: 'calc(100vh-50px)',
														}}
														className={classes.stickyresponsive}
													>
														<Divider style={{ height: '0.5px', backgroundColor: '#66CCFA', marginBottom: '2%' }} />
														<div style={{ marginRight: '8px', float: 'right' }}>
															<Button
																size="small"
																variant="outlined"
																color="primary"
																onClick={() => setShowMap(!showMap)}
																style={{
																	textDecoration: 'none',
																	float: 'left',
																	display: showMap == false ? 'block' : 'none',
																	height: '36px',
																	width: '64px',
																}}
															>
																Back
															</Button>

															<div style={{ display: showMap == false ? 'none' : 'block', float: 'left' }}>
																<Button
																	size="small"
																	variant="outlined"
																	color="primary"
																	disabled={formSpacesTouched}
																	onClick={() => clearFilters('parking')}
																	style={{ border: '1px solid #cfdbe5', height: '36px', width: '104px' }}
																>
																	Clear filters
																</Button>
																<Button
																	size="small"
																	variant="contained"
																	color="primary"
																	//disable()d={showMap == false}
																	onClick={() => applyFiltersNew()}
																	className={classes.applyFilterButton}
																	style={{ marginLeft: '10px' }}
																>
																	Apply filters
																</Button>
															</div>

															<Button
																size="small"
																variant="contained"
																className={classes.booknowButton}
																disabled={
																	!selectedFloor || (showMap == false && !getSelectedTimeRange().start) ? true : false
																}
																color="primary"
																onClick={ConfirmMsg}
															>
																Book now
															</Button>
														</div>
													</div>
												</>
											) : (
												<div
													style={{
														position: 'sticky',
														overflow: 'hidden',
														height: '56px',
														top: 'calc(100vh-50px)',
													}}
													className={classes.stickyresponsive}
												>
													<Divider style={{ height: '0.5px', backgroundColor: '#66CCFA', marginBottom: '2%' }} />
													<div style={{ marginRight: '8px', float: 'right' }}>
														<Button
															size="small"
															variant="outlined"
															color="primary"
															onClick={() => setShowMap(!showMap)}
															style={{
																textDecoration: 'none',
																float: 'left',
																display: showMap == false ? 'block' : 'none',
																height: '36px',
																width: '64px',
															}}
														>
															Back
														</Button>

														<div style={{ display: showMap == false ? 'none' : 'block', float: 'left' }}>
															<Button
																size="small"
																variant="outlined"
																color="primary"
																disabled={formDesksTouched}
																onClick={() => clearFilters('desk')}
																style={{ border: '1px solid #cfdbe5', height: '36px', width: '104px' }}
															>
																Clear filters
															</Button>
															<Button
																size="small"
																variant="contained"
																color="primary"
																onClick={() => applyFiltersDesks()}
																style={{ marginLeft: '10px' }}
																className={classes.applyFilterButton}
															>
																Apply filters
															</Button>
														</div>
														<Button
															size="small"
															variant="contained"
															color="primary"
															onClick={ConfirmMsg}
															className={classes.booknowButton}
															disabled={
																!selectedFloor || (showMap == false && !getSelectedTimeRange().start) ? true : false
															}
														>
															Book now
														</Button>
													</div>
												</div>
											)}
										</Grid>
									</div>
								</Grid>
							) : (
								''
							)}
						</Grid>
					</div>
				</div>

				<Dialog open={esmeDialogOpen} onClose={handleClickClose}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Confirm spaces booking?
						</DialogTitle>
						<DialogContentText
							style={{
								paddingLeft: '24px',
								paddingRight: '24px',
								marginBottom: '1px',
							}}
						>
							Booking for {meetingRoomName} on {floorNameVal} floor from{' '}
							{`${moment(getDisplayStartDate()).format('DD/MM/YYYY HH:mm')} until ${moment(getDisplayEndDate()).format(
								'DD/MM/YYYY HH:mm',
							)}`}
							{/* on{' '} */}
							{/* {`${moment(uRoomStartTime).format('ddd')} ${moment(uRoomStartTime).format('DD/MM/YYYY')}`} */}
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickClose} color="primary">
								Cancel
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickOpen} color="primary">
								Confirm
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={opensubject} onClose={handleClose} aria-labelledby="form-dialog-title">
					<DialogContent className={classes.alignStyle} style={{ width: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting Subject
						</DialogTitle>

						<DialogContentText
							style={{
								paddingLeft: '24px',
								paddingRight: '24px',
								marginBottom: '1px',
							}}
						>
							Please enter a short title for your
							<br /> meeting subject
						</DialogContentText>
						<TextField
							style={{
								paddingLeft: '24px',
								paddingRight: '24px',
								marginBottom: '6px',
							}}
							margin="normal"
							className={classes.meetingsubject}
							id="name"
							label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Meeting Subject"
							type="text"
							fullWidth
							onChange={subject}
							variant="filled"
						/>

						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Cancel
							</Button>
							<Button
								style={{ paddingRight: '8px' }}
								onClick={
									entityType == 'room'
										? handleClickFinalOpen
										: entityType == 'desk'
										? handleClickFinalOpen2
										: handleClickFinalOpen3
								}
								color="primary"
							>
								Save
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={openevent} onClose={handleCloseevent} aria-labelledby="form-dialog-title">
					<DialogContent className={classes.alignStyle} style={{ width: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting type
						</DialogTitle>

						<DialogContentText
							style={{
								paddingLeft: '24px',
								paddingRight: '24px',
								marginBottom: '1px',
							}}
						>
							Please select from one of the below meeting types.
						</DialogContentText>
						<div>
							<Autocomplete
								options={evntdata}
								getOptionLabel={(option: any) => option.description}
								size="small"
								autoComplete
								className={classes.Autocomplete}
								classes={{
									option: classes.option,
								}}
								id="size-small-standard"
								noOptionsText="Loading..."
								defaultValue={evntdata[0]}
								onChange={(event: any, newValue: any) => {
									setEventVal(newValue.id);
								}}
								renderInput={params => <TextField {...params} label="Meeting type" variant="filled" margin="normal" />}
							/>
						</div>

						<DialogActions>
							<Button onClick={handleClickOpen} color="primary">
								Done
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={openFinal} onClose={handleClickfinal}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="spaceForm-dialog-title">
							{/* {meetingRoomName && meetingRoomName} booked */}
							{entityType == 'room'
								? `Room ${meetingRoomName}`
								: entityType == 'desk'
								? `Desk ${meetingRoomName}`
								: `Parking slot ${meetingRoomName}`}
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							Your {entityType == 'room' ? 'room' : entityType == 'desk' ? 'desk' : 'parking slot'} is booked. To view
							your schedule, select the Bookings tab
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickfinal} color="primary">
								Close
							</Button>
							{/* <Button style={{ paddingRight: '8px' }} onClick={AddAttendees} color="primary">
								Add attendees
							</Button> */}
							<Button style={{ paddingRight: '8px' }} color="primary" onClick={redirection2}>
								View booking
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={opennotavailable} onClose={handleClickClose}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="spaceForm-dialog-title">
							Booking Status
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							{entityType === 'room'
								? 'No room available for booking'
								: entityType === 'desk'
								? 'No desk available for booking'
								: 'No parking slot available for booking'}
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={invalidDialog} onClose={handleInvalidClose}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="spaceForm-dialog-title">
							selected time is invalid please select future time
						</DialogTitle>
						{/* <DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
						selected time is invalid please select future time
						</DialogContentText> */}
						<DialogActions>
							<Button onClick={handleInvalidClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={openFinal2} onClose={handleClickfinal2}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="spaceForm-dialog-title">
							{/* {meetingRoomName && meetingRoomName} booked */}
							{/* Booking Status <br /> */}
							{entityType == 'room' ? 'Room' : entityType == 'desk' ? 'Desk' : 'Park'}{' '}
							{meetingRoomName && meetingRoomName}
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							{entityType == 'room' ? 'Room is booked' : entityType == 'desk' ? 'Desk is booked' : 'Parking is booked'},
							Go to bookings tab to view your booking
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickfinal2} color="primary">
								Close
							</Button>
							{/* <Button style={{ paddingRight: '8px' }} onClick={AddAttendees} color="primary">
								Add attendees
							</Button> */}
							<Button style={{ paddingRight: '8px' }} color="primary" onClick={redirection2}>
								View booking
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={consecutiveTimeDialog} onClose={handleConsecutiveSlots}>
					<DialogContent className={classes.alignStyle} style={{ width: '330px', height: '235px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Some of the times you selected are already booked
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '10px' }}>
							Please select a meeting duration where all times are available or select another space
						</DialogContentText>
						<DialogActions>
							<Button style={{ paddingRight: '8px' }} onClick={handleConsecutiveSlots} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				{/* new dialogue popups  */}
				{/* 
<Dialog open={esmeDialogOpen} onClose={handleClickClose}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							{zoneSingleRecord ? zoneSingleRecord.ZoneName : ''} selected
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '1px' }}>
							Please confirm your booking of {zoneSingleRecord ? zoneSingleRecord.ZoneName : ''} for{' '}
							{getSelectedTimeRange().start}- {poputmee} on{' '}
							{`${moment(`${selectedDate.date}`).format('ddd')} ${moment(selectedDate.date).format('DD/MM/YYYY')}`}
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickClose} color="primary">
								Cancel
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickOpen} color="primary">
								Confirm
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>



				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
					<DialogContent className={classes.alignStyle} style={{ width: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting Subject
						</DialogTitle>

						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '1px' }}>
							Please enter a short title for your
							<br /> meeting subject
						</DialogContentText>
						<TextField
							style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}
							margin="normal"
							id="name"
							label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Meeting Subject"
							type="text"
							fullWidth
							onChange={subject}
							variant="filled"
						/>

						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Cancel
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickFinalOpen} color="primary">
								Save
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog> */}

				{/* */}
			</div>
		) : (
			<>
				<div className={classes.Dimensions}>
					<img src={noResults} className={classes.condecoImage} alt="noresults" />
					<Typography className={classes.PaymentCaptionnew}>
						<a
							href={spaces.condecoDetails && spaces.condecoDetails.CondecoLink}
							target="_blank"
							style={{ textDecoration: 'none' }}
						>
							{' '}
							<img src={CondecoLogo} style={{ marginBottom: '-15px', width: '36px', borderRadius: '6%' }} />
							&nbsp;&nbsp;
							<Button variant="contained" color="primary" style={{ fontSize: '13px', outline: 'none!important' }}>
								Open Condeco booking system
							</Button>
						</a>{' '}
					</Typography>
				</div>
			</>
		),
	);
});

export default SpacesHome;
