import * as React from 'react';
import { ListItem, List, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import {
	faBuilding,
	faEdit,
	faCity,
	faCalendarStar,
	faMapMarkerEdit,
	faPencilRuler,
} from '@fortawesome/pro-light-svg-icons';
import {
	faEdit as faEditSolid,
	faBuilding as faBuildingSolid,
	faCity as faCitySolid,
	faCalendarStar as faCalendarStarSolid,
	faMapMarkerEdit as faMapMarkerEditSolid,
	faPencilRuler as faPencilRulerSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { stores } from '../../store';
import { IHeaderNavProps } from '../../types';
import { observer } from 'mobx-react-lite';
import sidebarUseStyles from '../../assets/css/SideBarCss';
const useStyles = makeStyles(theme => ({
	listChildItemSelected: {
		backgroundColor: '#CFDBE5 !important',
	},
	PermissionsView: {
		display: 'none',
	},
}));

const HeaderNav = observer((props: IHeaderNavProps) => {
	const routes = React.useContext(stores.context.routes);
	const { routeName } = routes;
	const classesSideBar = sidebarUseStyles();
	const classesnew = useStyles();
	const [selectedItem, setSelectedItem] = React.useState('');
	const [selectedMenuItem, setSelectedMenuItem] = React.useState('');
	const [contentClicked, setContentClicked] = React.useState(false);
	const classes = props.classNames;
	const appStores = React.useContext(stores.context.appStores);
	const spacesList: string[] = ['spacesHome', 'siteInfo', 'myBookings', 'bookingsView'];
	const user = React.useContext(stores.context.user);
	const floordata = user.floorsingledata ? user.floorsingledata : '';
	const spaces = React.useContext(stores.context.spaces);
	const contentManagementList: string[] = [
		'spaces',
		'floorplanUploader',
		'floorplanEditor',
		'editSiteInfo',
		'floorplans',
	];
	const contentManagementListSelected = !!routeName && !!contentManagementList.includes(routeName);
	const spacesRoutesSelected = !!routeName && !!spacesList.includes(routeName);
	const [openCont, setOpenCont] = React.useState(false || contentManagementListSelected);
	const [open, setOpen] = React.useState(spacesRoutesSelected || contentManagementListSelected);
	const [editFloorPlansClicked, setEditFloorPlansClicked] = React.useState(false);
	React.useEffect(() => {
		if (['floorplanEditor', 'floorplanUploader'].includes(routeName!)) {
		} else {
			if (selectedItem === 'spaces' || routeName === selectedItem) navigateToMainPage('spacesHome');
			if (selectedItem === 'spaces') navigateToPage('spaces');
			else if (selectedItem === routeName || selectedMenuItem === routeName) {
				navigateToMainPage('spacesHome');
				setOpenCont(false);
			}
		}
		spaces.fetchCondecoDetails(user.currentSiteId);
	}, [user.currentSiteId, routeName, selectedItem, selectedMenuItem]);
	React.useEffect(() => {
		if ((!openCont && contentClicked) || editFloorPlansClicked) navigateToMainPage('spacesHome');
	}, [contentClicked]);

	const handleClick = () => {
		setOpen(true);
	};
	const navigateToPage = (route: string) => {
		routes.navigate(
			{ name: route },
			{
				clearParams: true,
			},
		);
	};

	const navigateToMainPage = (route: string) => {
		routes.navigate(
			{
				name: route,
				params: {
					clientId: floordata.length > 0 ? floordata.clientId : 'NA',
					floorId: floordata.length > 0 ? floordata.floorId : 'NA',
				},
			},
			{
				clearParams: true,
			},
		);
	};

	const [editSiteInfo, setEditSiteInfo] = React.useState(0);

	React.useEffect(() => {
		if (
			user.permissions &&
			user.permissions.Modules.find((m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8')
		) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create &&
					user.permissions.Modules[spacesIndex].Update &&
					user.permissions.Modules[spacesIndex].Delete
				) {
					setEditSiteInfo(1);
				}
			} else {
				setEditSiteInfo(0);
			}
		}
	}, [user.permissions]);

	return (
		<>
			<List
				disablePadding={false}
				className={
					user.modulePermissions && user.modulePermissions.SPACES ? classes.sideBarList : classesnew.PermissionsView
				}
			>
				<ListItem
					button
					disableTouchRipple={true}
					onClick={() => {
						handleClick();
						navigateToMainPage('spacesHome');
						appStores.drawerOpen = false;
					}}
					selected={routeName === 'spacesHome' || spacesRoutesSelected || contentManagementListSelected}
				>
					<ListItemIcon className={classes.listIcon}>
						<Icon
							icon={
								routeName === 'spacesHome' || spacesRoutesSelected || contentManagementListSelected
									? faBuildingSolid
									: faBuilding
							}
						/>
					</ListItemIcon>
					<ListItemText primary="Spaces" />
				</ListItem>
			</List>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List disablePadding={false} className={classesSideBar.contentMenuFeed}>
					{spaces.condecoDetails && spaces.condecoDetails.ISEMSAvailable ? (
						<ListItem
							button
							disableTouchRipple={true}
							onClick={() => {
								navigateToPage('myBookings');
								setSelectedMenuItem('myBookings');
								appStores.drawerOpen = false;
							}}
							selected={routeName === 'myBookings' || routeName === 'bookingsView'}
						>
							<ListItemIcon className={classes.listIcon}>
								<Icon
									icon={
										routeName === 'myBookings' || routeName === 'bookingsView' ? faCalendarStarSolid : faCalendarStar
									}
								/>
							</ListItemIcon>
							<ListItemText primary="My Bookings" />
						</ListItem>
					) : (
						''
					)}
					<ListItem
						disableTouchRipple={true}
						button
						onClick={() => {
							navigateToPage('siteInfo');
							setSelectedMenuItem('siteInfo');
							appStores.drawerOpen = false;
						}}
						selected={routeName === 'siteInfo'}
					>
						<ListItemIcon className={classes.listIcon}>
							<Icon icon={routeName === 'siteInfo' ? faCitySolid : faCity} />
						</ListItemIcon>
						<ListItemText primary="Site Information" />
					</ListItem>
					{editSiteInfo === 1 && appStores.showAppLoader === false ? (
						<>
							<ListItem
								button
								onClick={() => {
									setOpenCont(!openCont);
									setOpen(true);
									setContentClicked(true);
								}}
								selected={contentManagementListSelected || openCont}
								disableTouchRipple={true}
								className={openCont && selectedMenuItem === 'contentManagement' ? classesnew.listChildItemSelected : ''}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={contentManagementListSelected || openCont ? faEditSolid : faEdit} />
								</ListItemIcon>
								<ListItemText primary="Content Management" />
							</ListItem>
							<Collapse
								in={openCont}
								timeout="auto"
								unmountOnExit
								className={contentManagementListSelected ? classes.listCollapseSelected : classes.listCollapse}
							>
								<List component="div" disablePadding={false} className={classesSideBar.contentMenuFeed}>
									<ListItem
										button
										onClick={() => {
											navigateToPage('spaces');
											setSelectedItem('spaces');
											setEditFloorPlansClicked(true);
											appStores.drawerOpen = false;
										}}
										selected={routeName === 'spaces' || routeName === 'floorplanEditor'}
										disableTouchRipple={true}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon
												icon={
													routeName === 'spaces' || routeName === 'floorplanEditor' ? faPencilRulerSolid : faPencilRuler
												}
											/>
										</ListItemIcon>
										<ListItemText style={{ paddingLeft: '0px' }} primary="Edit Floor Plans" />
									</ListItem>
									<ListItem
										button
										onClick={() => {
											navigateToPage('editSiteInfo');
											setSelectedItem('editSiteInfo');
											appStores.drawerOpen = false;
										}}
										selected={routeName === 'editSiteInfo'}
										disableTouchRipple={true}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon icon={routeName === 'editSiteInfo' ? faMapMarkerEditSolid : faMapMarkerEdit} />
										</ListItemIcon>
										<ListItemText primary="Edit Site Information" style={{ paddingLeft: '0px' }} />
									</ListItem>
								</List>
							</Collapse>
						</>
					) : (
						''
					)}
				</List>
			</Collapse>
		</>
	);
});

export default HeaderNav;
