import * as React from 'react';
import { makeStyles } from '@material-ui/core';

interface IFrameProps {
	iframeSrc: string;
	height: string;
	width: string;
	token: string;
	clientId: string;
}
const useStyles = makeStyles(theme => ({
	frameWrapper: {
		height: '100%',
		borderRadius: '16px 16px 0px 0px',
		'& iframe': {
			border: 0,
			borderRadius: '16px 16px 0px 0px',
		},
	},
}));
export const IframeComponent2: React.FunctionComponent<IFrameProps> = ({
	iframeSrc,
	height,
	width,
	clientId,
	token,
}) => {
	const classes = useStyles();
	const onLoadHandler = () => {
		sendMessage();
	};
	const sendMessage = () => {
		const iframeEl: any = document.getElementById('myframe');
		// Make sure you are sending a string, and to stringify JSON
		iframeEl.contentWindow.postMessage({ ariaToken: token }, '*');
	};
	const src = iframeSrc + '&clientId=' + clientId;
	return (
		<div className={classes.frameWrapper}>
			<iframe id="myframe" title={'iframe'} src={src} style={{ height: '100%' }} width={width} onLoad={onLoadHandler} />
		</div>
	);
};
