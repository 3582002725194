import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core';
import RefuelMenuForm from './ContentManagement/RefuelMenuForm';
import RefuelMenuList from 'components/RefuelMenuList';
import RefuelLanding from 'components/RefuelLanding';
import PermanentAppBar from '../components/Header/PermanentAppBar';
const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
		margin: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(5, 1, 1, 1),
			overflow: 'auto',
		},
		width: '100%',
		overflow: 'hidden',
		paddingBottom: '38px',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
}));

const Refuel = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const appStores = React.useContext(stores.context.appStores);
	const { routeName } = React.useContext(stores.context.routes);
	appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{/* {routeName === 'refuel' ? <RefuelMenuList /> : <RefuelMenuForm />} */}
				{routeName === 'refuel' ? (
					<RefuelLanding />
				) : routeName === 'refuelList' ? (
					<RefuelMenuList />
				) : routeName === 'addMenus' ? (
					<RefuelMenuForm />
				) : routeName === 'editMenus' ? (
					<RefuelMenuForm />
				) : routeName === 'copyMenus' ? (
					<RefuelMenuForm />
				) : (
					''
				)}
			</main>
		</>
	);
});

export default Refuel;
