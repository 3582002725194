import * as React from 'react';
import { Observer } from 'mobx-react-lite';
//import { Grid, Link, Typography, makeStyles, Button, Avatar } from '@material-ui/core';
import { Grid, Typography, makeStyles, Avatar } from '@material-ui/core';
import applconLarge from '../assets/images/ariaLogo.svg';
import { stores } from '../store';

const useStyles = makeStyles(theme => ({
	appIconLarge: {
		width: '82px',
		height: '82px',
		display: 'inline-block',
	},
	welcomeName: {
		margin: theme.spacing(3, 0),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0),
		},
	},
	raiseRequestButton: {
		margin: theme.spacing(2, 0),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0),
		},
	},
}));

const WelcomeUser = () => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const applconLargeImg = applconLarge;
	//const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
	const routes = React.useContext(stores.context.routes);
	React.useEffect(() => {
		routes.navigate({ name: 'feed' });
	}, []);
	return (
		<Grid container direction="row" justify="center" alignItems="center" spacing={1}>
			<Grid item xs={12}>
				<Grid container direction="row" justify="center" alignItems="center" spacing={1}>
					<Grid item xs={12}>
						<Avatar variant="rounded" src={applconLargeImg} className={classes.appIconLarge}></Avatar>
					</Grid>
					<Grid item xs={12}>
						<Observer>
							{() => (
								<Typography variant="h5" className={classes.welcomeName}>
									Welcome {user.username}
								</Typography>
							)}
						</Observer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2">Click on a link above to view and manage content.</Typography>
					</Grid>
					{/* <Grid item xs={12}>
						<Typography variant="body2">Click below or 'Ask' to raise a service request.</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" className={classes.raiseRequestButton}>
							Raise service request
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Link href="#" onClick={preventDefault}>
							Manage my account
						</Link>
					</Grid> */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WelcomeUser;
