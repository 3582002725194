import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import SiteLocation from './Location';
import HeaderNav from './HeaderNav';
import AppIcons from './AppIcons';
import Sidebar from 'components/Sidebar';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth - 10,
				flexShrink: 0,
			},
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: '400px',
			},
			backgroundColor: theme.palette.common.white,
			boxShadow: '-8px 0px 8px #327CA129',
			borderRadius: '24px 0px 0px 0px',
			opacity: 1,
		},
		appBarHome: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100%)`,
			},
			backgroundColor: theme.palette.common.white,
			opacity: 1,
		},
		logo: {
			left: '23px',
			top: '12px',
			opacity: 1,
			position: 'fixed',
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		toolbar: { position: 'fixed' },
		sidebar: {
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			boxShadow: 'inset -8px -2px 8px #327CA129',
			opacity: 1,
			top: '64px',
			left: '0px',
			height: '100%',
			width: '260px',
			overflow: 'hidden',
			paddingTop: '10px',
		},

		drawerPaper: {
			marginTop: 64,
			width: drawerWidth,
			borderRight: 'none',
		},
		inputRoot: {
			color: 'inherit',
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		grow: {
			flexGrow: 1,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(0.5),
		},
	}),
);

export default function NewAppBar(props: any) {
	const { currentComponent, isUserLoggedIn } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<div className={classes.sidebar}>
			<div className={classes.toolbar} />
			<Sidebar />
		</div>
	);

	const container = window.document.body;

	return (
		<div className={classes.root}>
			<CssBaseline />
			{isUserLoggedIn ? (
				<>
					<AppBar position="fixed" className={classes.appBar}>
						<Toolbar>
							<SiteLocation />
							<div className={classes.grow} />

							<HeaderNav />
							<div className={classes.sectionMobile}>
								<IconButton aria-label="show more" aria-haspopup="true" color="inherit">
									<MoreIcon />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<nav className={classes.drawer} aria-label="mailbox folders">
						<Box className={classes.logo}>
							<AppIcons />
						</Box>
						<Hidden smUp implementation="css">
							<Drawer
								container={container}
								variant="temporary"
								anchor={theme.direction === 'rtl' ? 'right' : 'left'}
								open={mobileOpen}
								onClose={handleDrawerToggle}
								classes={{
									paper: classes.drawerPaper,
								}}
								ModalProps={{
									keepMounted: true,
								}}
							>
								{drawer}
							</Drawer>
						</Hidden>
						<Hidden xsDown implementation="css">
							<Drawer
								classes={{
									paper: classes.drawerPaper,
								}}
								variant="permanent"
								open
							>
								{drawer}
							</Drawer>
						</Hidden>
					</nav>
					<main className={classes.content}>{currentComponent}</main>
				</>
			) : (
				<>
					<AppBar position="fixed" className={classes.appBarHome}>
						<Toolbar>
							<AppIcons />
						</Toolbar>
					</AppBar>
				</>
			)}
		</div>
	);
}
