import { observable, computed } from 'mobx';
import { NavigationOptions } from 'router5';
import { ILinkData, routes, options } from '../routing/routes';
import { makeMobxRouter } from '../routing/mobxRouterMiddleware';
import { DoneFn } from 'router5/types/types/base';

function toJSForce(object: any) {
	return JSON.parse(JSON.stringify(object));
}

export interface IRoute {
	name: string | null;
	params: any;
}

class Routes {
	public router = makeMobxRouter(routes, options, this);
	public routes = routes;
	@observable public testval: any = false;
	@observable public viewmyjobval: any = false;
	@observable public accessRoutes: string[] = [];
	@observable
	public route: IRoute = {
		name: null,
		params: {},
	};

	@computed
	public get routeName() {
		return this.route.name;
	}

	@computed
	public get breadcrumbs() {
		if (this.routeName) {
			const route = this.routes[this.routeName];
			return route.breadcrumbs || [];
		}
		return [];
	}

	public navigate = (linkData: ILinkData, navigateOptions?: NavigationOptions, done?: DoneFn) => {
		const name = linkData.name || this.routeName;
		const currentParams = toJSForce(this.route.params);
		if (name) {
			const params = linkData.params ? toJSForce(linkData.params) : {};
			this.router.navigate(
				name,
				{
					...(navigateOptions && navigateOptions.clearParams ? {} : currentParams),
					...params,
				},
				navigateOptions || {},
				done,
			);
		}
	};

	public buildPath = (linkData: ILinkData, buildPathOptions: any = {}) => {
		const name = linkData.name || this.routeName;
		const { date, ...rest } = toJSForce(this.route.params);

		if (name) {
			return this.router.buildPath(name, {
				...(buildPathOptions.clearParams ? {} : rest),
				...(linkData.params || {}),
			});
		}
		return '#';
	};
}
export default Routes;
