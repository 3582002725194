import React from 'react';
import { imageExt, isValidFileType, isImageUrl, isVideoUrl, videoExt, isValidUrl } from '../../util';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	makeStyles,
	TextField,
	Link,
	FormHelperText,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Button,
} from '@material-ui/core';
import classnames from 'classnames';
import { ContainerID, IContent, ISurvey, mediaTypes } from 'types';
import { stores } from 'store';
import { FormContextValues } from 'react-hook-form';
// import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
	formControl: {
		display: 'block',
		width: '100%',
		marginTop: theme.spacing(1),
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	formSelect: {
		width: '100%',
	},
	hidden: {
		display: 'none',
	},

	shrinkLabel: {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.pxToRem(21),
	},
}));

interface IProps {
	methods: FormContextValues<IContent | ISurvey>;
	setValues: React.SetStateAction<any>;
	values: IContent | ISurvey;
}

const UploadComponent = ({ methods, setValues, values }: IProps) => {
	const classes = useStyles();
	const { register, errors, setValue, setError, clearError, triggerValidation } = methods;
	const { MediaType, MediaUrl, MediaPreviewUrl } = values;
	const blob = React.useContext(stores.context.blob);
	const [sourceFileType, setSourceFileType] = React.useState('');
	const [sourceFileName, setSourceFileName] = React.useState('');
	const [previewFileType, setPreviewFileType] = React.useState('');
	const [previewFileName, setPreviewFileName] = React.useState('');
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [ItemSrc, setItemsrc] = React.useState<any>(0);
	const [VideoSrc, setVideosrc] = React.useState<any>(0);
	const [ItemPreview, setItempreview] = React.useState<any>(0);
	const [InvalidUrl, setInvalidUrl] = React.useState<any>(false);
	const [ImageUrlError, setImageUrlError] = React.useState<any>(false);
	const [VideoUrlError, setVideoUrlError] = React.useState<any>(false);
	const [MediaUrlError, setMediaUrlError] = React.useState<any>(0);
	const [MediaPreviewUrlError, setMediaPreviewUrlError] = React.useState<any>(0);
	let count = 0;
	const mediaTypeChange = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
		setValues({ ...values, MediaType: event.target.value, MediaUrl: '', MediaPreviewUrl: '' });
		setValue(event.target.name, event.target.value);
		setValue('MediaUrl', '');
		setValue('MediaPreviewUrl', '');
		setSourceFileType('');
		setPreviewFileType('');
		setSourceFileName('');
		setPreviewFileName('');
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		clearError('MediaUrl');
		clearError('MediaPreviewUrl');

		triggerValidation('MediaType');

		if (MediaType === 0) {
			setItemsrc(0);
			setItempreview(0);
			setVideosrc(0);
		}
		if (MediaType === 1) {
			setVideosrc(0);
		}
		if (MediaType === 2) {
			setItemsrc(0);
			setItempreview(0);
		}
	};

	const handleChange = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
		setValue(event.target.name, event.target.value);
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const handleClose = () => {
		setDialogOpen(false);
	};
	const handleCloseUrl = () => {
		setInvalidUrl(false);
		count = 0;
		if ((MediaType === 1 || MediaType === 2) && MediaUrlError === 1) {
			setValues({ ...values, MediaUrl: '' });
			setMediaUrlError(0);
		}
		if (MediaType === 1 && MediaPreviewUrlError === 1) {
			setValues({ ...values, MediaPreviewUrl: '' });
			setMediaPreviewUrlError(0);
		}
	};
	const handleCloseImageUrl = () => {
		setImageUrlError(false);
		if ((MediaType === 1 || MediaType === 2) && MediaUrlError === 1) {
			setValues({ ...values, MediaUrl: '' });
			setMediaUrlError(0);
		}
		if (MediaType === 1 && MediaPreviewUrlError === 1) {
			setValues({ ...values, MediaPreviewUrl: '' });
			setMediaPreviewUrlError(0);
		}
	};
	const handleCloseVideoUrl = () => {
		setVideoUrlError(false);
		if ((MediaType === 1 || MediaType === 2) && MediaUrlError === 1) {
			setValues({ ...values, MediaUrl: '' });
			setMediaUrlError(0);
		}
		if (MediaType === 1 && MediaPreviewUrlError === 1) {
			setValues({ ...values, MediaPreviewUrl: '' });
			setMediaPreviewUrlError(0);
		}
	};
	const handleUpload = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'source') clearError('MediaUrl');
		else clearError('MediaPreviewUrl');

		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		const mediaExt = MediaType === 1 ? imageExt : videoExt;
		if (uploadedFile && mediaExt.includes(uploadedFile.type)) {
			const fileReader: FileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			fileReader.onload = async (event: any) => {
				if (uploadedFile.size >= 5000000) {
					setDialogOpen(true);
				}
				const isValidExtension = isValidFileType(uploadedFile.type, inverseObject(mediaTypes)[MediaType]);
				if (!isValidExtension) {
					setError(type === 'source' ? 'MediaUrl' : 'MediaPreviewUrl', 'invalid', 'Invalid file type.');
				} else {
					const fileResult = event.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
					const token = await blob.blobToken();
					let postData = {
						Token: token.uploadToken,
						Container: ContainerID.Feed,
						Data: fileResult,
						ContentType: uploadedFile.type,
						Extension: uploadedFile.type.split('/').pop(),
					};
					const response = await blob.blobImage(postData);

					if (type === 'source') {
						setValues({ ...values, MediaUrl: response.url });
						setValue('MediaUrl', response.url);
						setSourceFileName(uploadedFile.name);
						triggerValidation('MediaUrl');
					} else {
						setValues({ ...values, MediaPreviewUrl: response.url });
						setValue('MediaPreviewUrl', response.url);
						setPreviewFileName(uploadedFile.name);
						triggerValidation('MediaPreviewUrl');
					}
				}
			};
		} else {
			const invalidMsg =
				MediaType === 1 ? imageExt.join(', ').replace(/image\//g, '') : videoExt.join(', ').replace(/video\//g, '');
			if (type === 'source') setError('MediaUrl', 'invalid', `Please select ${invalidMsg} types`);
			else setError('MediaPreviewUrl', 'invalid', `Please select ${invalidMsg} types`);
		}
	};

	function inverseObject(obj: any) {
		var retobj = {};
		for (var key in obj) {
			retobj[obj[key]] = key;
		}
		return retobj;
	}

	React.useEffect(() => {
		register({ name: 'MediaType' }); // custom register react-select
		register({ name: 'MediaUrl' }); // custom register react-select
		register({ name: 'MediaPreviewUrl' }); // custom register react-select
	}, [register]);

	return (
		<React.Fragment>
			<FormControl className={classes.formControl} variant="filled" fullWidth>
				<InputLabel>Add Media</InputLabel>
				<Select
					name="MediaType"
					required
					value={MediaType}
					className={classes.formSelect}
					onChange={mediaTypeChange}
					inputRef={e => {
						register(e);
					}}
				>
					{Object.keys(mediaTypes).map(key => (
						<MenuItem key={key} value={mediaTypes[key]}>
							{key}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl
				variant="filled"
				fullWidth
				className={classnames(classes.formControl, MediaType === 0 ? classes.hidden : '')}
				error={sourceFileType === 'upload' && Boolean(errors.MediaUrl)}
			>
				<InputLabel
					style={{
						color:
							MediaType == 1 && ItemSrc == 1 && values.MediaUrl === ''
								? '#bd0810'
								: MediaType == 2 && VideoSrc == 1 && values.MediaUrl === ''
								? '#bd0810'
								: '',
					}}
				>
					Item Source
				</InputLabel>
				<Select
					value={MediaUrl ? 'attachment' : ''}
					label="Item Source"
					name="sourceFileType"
					className={classes.formSelect}
					error={
						MediaType == 1 && ItemSrc == 1 && values.MediaUrl === ''
							? true
							: MediaType == 2 && VideoSrc == 1 && values.MediaUrl === ''
							? true
							: false
					}
					displayEmpty
					inputRef={e => {
						register(e);
					}}
					onFocus={() => {
						MediaType === 1 ? setItemsrc(1) : MediaType === 2 ? setVideosrc(1) : setVideosrc(0);
					}}
					onChange={(e: React.ChangeEvent<{ value: string }>) => {
						setSourceFileType(e.target.value);

						setSourceFileName('');
						setValue('MediaUrl', '');
						setValues({ ...values, sourceFileType: e.target.value });
						const file = document.getElementById('sourceFile') as HTMLInputElement;
						if (file) {
							file.value = '';
						}

						if (e.target.value === 'upload') {
							file.click();
						}
					}}
				>
					{MediaUrl !== '' ? (
						<MenuItem value="attachment">
							<Link href={MediaUrl} color="primary" underline="none" target="_blank">
								Attachment
							</Link>
						</MenuItem>
					) : null}
					<MenuItem value="upload">Upload</MenuItem>
					<MenuItem value="url">Url</MenuItem>
				</Select>

				{sourceFileName ? <FormHelperText>{sourceFileName}</FormHelperText> : null}
				{errors.MediaUrl && sourceFileType === 'upload' ? (
					<FormHelperText error>{errors.MediaUrl.message}</FormHelperText>
				) : null}
			</FormControl>

			<input
				accept="video/*,image/*"
				type="file"
				name="sourceFile"
				id="sourceFile"
				className={classes.hidden}
				onChange={event => handleUpload(event, 'source')}
			/>

			<FormControl
				className={classnames(classes.formControl, sourceFileType !== 'url' ? classes.hidden : '')}
				error={Boolean(errors.MediaUrl)}
			>
				<TextField
					label="Source media URL"
					name="MediaUrl"
					variant="filled"
					margin="normal"
					value={MediaUrl}
					fullWidth
					error={Boolean(errors.MediaUrl || ((MediaType === 1 || MediaType === 2) && values.MediaUrl === ''))}
					helperText={errors.MediaUrl ? errors.MediaUrl.message : ''}
					onChange={handleChange}
					onBlur={e => {
						if (!isValidUrl(e.target.value)) {
							setInvalidUrl(true);

							count++;
							if (MediaType === 1 || MediaType === 2) setMediaUrlError(1);
						}

						if (count === 0) {
							if (MediaType === 1 && !isImageUrl(e.target.value)) {
								// setValue('MediaUrl', '');
								// setError('MediaUrl', 'invalid', 'Please enter valid image url');

								setImageUrlError(true);
								setMediaUrlError(1);
							}

							if (MediaType === 2 && !isVideoUrl(e.target.value)) {
								// setValue('MediaUrl', '');
								// setError('MediaUrl', 'invalid', 'Please enter valid video url');
								setVideoUrlError(true);
								setMediaUrlError(1);
							}
						}
					}}
					inputRef={e => {
						register(e);
					}}
				/>
			</FormControl>
			{ItemSrc == 1 ? (
				MediaType === 1 && values.MediaUrl === '' ? (
					<FormHelperText error style={{ marginLeft: '12px', color: '#bd0810' }}>
						Item source is required
					</FormHelperText>
				) : MediaType === 1 ? (
					<FormHelperText>{sourceFileName}</FormHelperText>
				) : null
			) : null}

			{VideoSrc == 1 ? (
				MediaType === 2 && values.MediaUrl === '' ? (
					<FormHelperText error style={{ marginLeft: '12px', marginTop: '7px', color: '#bd0810' }}>
						Item source is required
					</FormHelperText>
				) : MediaType === 2 ? (
					<FormHelperText>{sourceFileName}</FormHelperText>
				) : null
			) : null}
			<FormControl
				variant="filled"
				fullWidth
				className={classnames(classes.formControl, MediaType === 0 || MediaType !== 1 ? classes.hidden : '')}
				error={previewFileType === 'upload' && Boolean(errors.MediaPreviewUrl)}
			>
				<InputLabel style={{ color: ItemPreview == 1 && values.MediaPreviewUrl === '' ? '#bd0810' : '' }}>
					Preview Source
				</InputLabel>
				<Select
					name="previewFileType"
					label="Preview File"
					value={MediaPreviewUrl ? 'attachement' : ''}
					error={ItemPreview == 1 && values.MediaPreviewUrl === '' ? true : false}
					className={classes.formSelect}
					inputRef={e => {
						register(e);
					}}
					onFocus={() => {
						setItempreview(1);
					}}
					onChange={(e: React.ChangeEvent<{ value: string }>) => {
						setPreviewFileType(e.target.value);
						setPreviewFileName('');
						setValue('MediaPreviewUrl', '');
						setValues({ ...values, sourceFileType: e.target.value });
						const file = document.getElementById('previewFile') as HTMLInputElement;
						if (file) {
							file.value = '';
						}

						if (e.target.value === 'upload') {
							file.click();
						}

						if (e.target.value === 'sameAsAbove') {
							setValue('MediaPreviewUrl', values.MediaUrl);
							setValues({ ...values, MediaPreviewUrl: values.MediaUrl });

							clearError('MediaPreviewUrl');
						}
					}}
				>
					{MediaPreviewUrl !== '' ? (
						<MenuItem value="attachement">
							<Link href={MediaPreviewUrl} color="primary" underline="none" target="_blank">
								Attachment
							</Link>
						</MenuItem>
					) : null}
					{MediaType === 1 ? <MenuItem value="sameAsAbove">Same as above</MenuItem> : null}

					<MenuItem value="upload">Upload</MenuItem>
					<MenuItem value="url">Url</MenuItem>
				</Select>

				<>
					{/* <FormHelperText>{previewFileName ? '' : 'Preview has only image files'}</FormHelperText> */}
					{errors.MediaPreviewUrl && previewFileType === 'upload' ? (
						<FormHelperText error>{errors.MediaPreviewUrl.message}</FormHelperText>
					) : null}
				</>
			</FormControl>

			<input
				accept="image/*"
				type="file"
				name="previewFile"
				id="previewFile"
				className={classes.hidden}
				onChange={event => handleUpload(event, 'preview')}
			/>

			<FormControl
				className={classnames(classes.formControl, previewFileType !== 'url' ? classes.hidden : '')}
				error={Boolean(errors.MediaPreviewUrl)}
			>
				<TextField
					label="Preview Url"
					name="MediaPreviewUrl"
					variant="filled"
					margin="normal"
					value={MediaPreviewUrl}
					fullWidth
					error={Boolean(errors.MediaPreviewUrl || (MediaType === 1 && values.MediaPreviewUrl === ''))}
					helperText={errors.MediaPreviewUrl ? errors.MediaPreviewUrl.message : ''}
					onChange={handleChange}
					onBlur={e => {
						if (!isValidUrl(e.target.value)) {
							setInvalidUrl(true);
							count++;
							setMediaPreviewUrlError(1);
						}

						if (count === 0) {
							if (MediaType === 1 && !isImageUrl(e.target.value) && InvalidUrl != true) {
								setImageUrlError(true);
								setMediaPreviewUrlError(1);
							}
						}
					}}
					inputRef={e => {
						register(e);
					}}
				/>
			</FormControl>
			{ItemPreview == 1 ? (
				MediaType === 1 && values.MediaPreviewUrl === '' ? (
					<FormHelperText error style={{ marginLeft: '12px', color: '#bd0810' }}>
						Preview source is required
					</FormHelperText>
				) : (
					<FormHelperText>{previewFileName}</FormHelperText>
				)
			) : null}

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={dialogOpen}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Failed
					</DialogTitle>
					<DialogContentText>Cannot upload 'Media item' larger than 5MB.</DialogContentText>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							OK
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={InvalidUrl}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Failed
					</DialogTitle>
					<DialogContentText>Please specify a valid 'Media URL' that commences with HTTP or HTTPS</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseUrl} color="primary">
							OK
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={ImageUrlError}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Failed
					</DialogTitle>
					<DialogContentText>
						please specify a 'Media Item' with one of the following file types: BMP, GIF, JPEG, JPG, PNG
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseImageUrl} color="primary">
							OK
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={VideoUrlError}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Failed
					</DialogTitle>
					<DialogContentText>please specify a 'Media Item' with one of the following file types: MP4</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseVideoUrl} color="primary">
							OK
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

export default UploadComponent;
