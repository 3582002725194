import * as React from 'react';
import {
	makeStyles,
	Typography,
	Box,
	Card,
	CardContent,
	Divider,
	CardActions,
	Button,
	List,
	ListItem,
	Collapse,
} from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { IAskItems } from '../../types';
import { displayDateTime } from '../../util';
import { stores } from '../../store';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface IProps {
	ask: IAskItems;
}
const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 275,
	},
	actions: {
		justifyContent: 'flex-end',
	},
	nested: {
		marginTop: '-5%',
		marginLeft: '10%',
	},
	IconStyle: {
		background: theme.palette.warning.main,
		color: theme.palette.background.paper,
		borderRadius: '25px',
		justifyContent: 'space-between',
	},
	ArrowIcon: {
		fontSize: '2.5em',
		marginLeft: '-10px',
		color: '#72879E',
	},
	closedCard: (props: IProps) => ({
		minWidth: '350px',
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
	}),
	actionIconButton: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
}));

const AskEsme = (props: IProps) => {
	const classes = useStyles(props);
	const user = React.useContext(stores.context.user);
	const routes = React.useContext(stores.context.routes);
	const [jobOpen, setJobOpen] = React.useState(false);
	const [decorateOpen, setDecorateJobOpen] = React.useState<any>({});
	const jobHandleClick = () => {
		setJobOpen(!jobOpen);
	};
	const decorateHandleClick = (index: any) => {
		setDecorateJobOpen({ ...decorateOpen, [index]: !decorateOpen[index] });
	};
	return (
		<>
			{user.srFull &&
				user.srFull.map((n: any) => (
					<Card className={classes.closedCard}>
						<CardContent>
							<Box display="flex" justifyContent="center" p={1} bgcolor="background.paper">
								<Box p={1}>
									<Icon icon={faCommentsAlt} size="4.5rem" />
								</Box>
							</Box>
							<Box display="flex" justifyContent="center">
								<Typography variant="h6" component="p">
									{n.classstructure && n.classstructure[0].commodities[0].description}
								</Typography>
							</Box>
							<Box textAlign="center" m={1}>
								<Typography variant="body2" color="textSecondary">
									{`Ref: ${n.ticketId}`}
								</Typography>
								<Typography variant="body2" color="textSecondary">
									{n.locationDescription}
								</Typography>
								<Typography variant="body2" color="textSecondary">
									{/* {`Helpdesk phone ${n.pluspcustomer[0] && n.pluspcustomer[0].pelcusthdphone}`} */}
								</Typography>
							</Box>
							<Box mt={1}>
								<Typography variant="subtitle2">Description</Typography>
								<Typography variant="body2" color="textSecondary">
									{n.description}
								</Typography>
							</Box>
							<Box mt={1}>
								{n.status !== 'APPR' && n.status !== 'WAPPR' && (
									<Typography variant="subtitle2">Service Request Status:</Typography>
								)}
							</Box>
							<Box display="flex" flexDirection="row" mt={1}>
								<Typography variant="subtitle1">
									{n.status !== 'APPR' && n.status !== 'WAPPR' && n.statusDescription}
								</Typography>
								<Box display="flex" flexDirection="row" style={{ marginLeft: 'auto' }}>
									<Typography style={{ margin: 'auto' }} color="textSecondary" variant="body2">
										{n.status !== 'APPR' && n.status !== 'WAPPR' && displayDateTime(n.statusDate)}
									</Typography>
								</Box>
							</Box>
							<Box my={1}>
								<Divider />
							</Box>
							<Box mt={-2}>
								<List>
									<ListItem button onClick={jobHandleClick}>
										{jobOpen ? (
											<ArrowDropDownIcon className={classes.ArrowIcon} />
										) : (
											<ArrowRightIcon className={classes.ArrowIcon} />
										)}
										<Typography variant="body2">{'    '}</Typography>
										<Typography variant="subtitle1">Job History</Typography>
									</ListItem>

									<Collapse style={{ marginTop: '-20px' }} in={jobOpen} timeout="auto" unmountOnExit>
										{n.relatedRecord &&
											n.relatedRecord.map((wo: any) =>
												wo.workOrder.map((workItems: any, index: any) => (
													<>
														<List>
															<ListItem button onClick={() => decorateHandleClick(index)}>
																{decorateOpen[index] ? (
																	<ArrowDropDownIcon className={classes.ArrowIcon} />
																) : (
																	<ArrowRightIcon className={classes.ArrowIcon} />
																)}
																<Typography variant="body2">{'    '}</Typography>
																<Typography variant="subtitle1">
																	{workItems.wonum} - {workItems.description}
																</Typography>
															</ListItem>

															<Collapse
																className={classes.nested}
																in={decorateOpen[index]}
																timeout="auto"
																unmountOnExit
															>
																{workItems.assignmenthistory &&
																	workItems.assignmenthistory.map((history: any) => (
																		<>
																			<Box display="flex" flexDirection="row" mt={2}>
																				<Typography variant="body2">
																					{history.statusDescription ? history.statusDescription : ''}
																				</Typography>
																				<Typography
																					color="textSecondary"
																					style={{ marginLeft: 'auto' }}
																					variant="body2"
																				>
																					{/* {displayDateTime(n.relatedRecord[0].workOrder[0].assignment.finishDate)} */}
																					{history.statusdate ? displayDateTime(history.statusdate) : ''}
																					{''}
																				</Typography>
																			</Box>
																			<Box my={1}>
																				<Divider />
																			</Box>
																		</>
																	))}
															</Collapse>
														</List>
													</>
												)),
											)}

										{n.tkstatus &&
											n.tkstatus.map((statuses: any) => (
												<>
													<Box display="flex" flexDirection="row" mt={2}>
														<Typography variant="body2">
															{statuses.statusDescription ? statuses.statusDescription : ''}
														</Typography>
														<Typography color="textSecondary" style={{ marginLeft: 'auto' }} variant="body2">
															{statuses.changedate ? displayDateTime(statuses.changedate) : ''}
															{''}
														</Typography>
													</Box>
													<Box my={1}>
														<Divider />
													</Box>
												</>
											))}
									</Collapse>
								</List>
							</Box>
						</CardContent>
						<CardActions className={classes.actions}>
							<Button
								size="small"
								color="primary"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={() => {
									const currentFeed = user.userSRs.find((f: any) => f.Id === props.ask.Id);
									currentFeed!.showOpenCard = false;
								}}
							>
								Close
							</Button>
							<Button
								size="small"
								color="primary"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={() => {
									routes.navigate({
										name: 'chatbot',
										params: {
											srId: n.ticketId,
										},
									});
									const currentFeed = user.userSRs.find((f: any) => f.Id === props.ask.Id);
									currentFeed!.showOpenCard = false;
								}}
							>
								Raise query
							</Button>
						</CardActions>
					</Card>
				))}
		</>
	);
};

export default AskEsme;
