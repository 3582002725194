import { action, observable } from 'mobx';
import { postAPI, putAPI, deletePostAPI, getAPI } from 'services/apiService';

import { IContent, IEvacuation, ISurvey } from 'types';

class ContentManagement {
	@observable public contentManagement: IContent[];
	@observable public contentEvacuation: IEvacuation[];
	@observable public contentSurvey: ISurvey[];
	@observable public contentRecords: number;

	@action
	public async saveContentManagementValues(values: object, Id: string) {
		if (Id !== '') {
			return await putAPI('/feedContent/edit', values);
		} else {
			return await postAPI('/feedContent/save', values);
		}
	}
	@action
	public async saveMenu(values: object) {
		return await postAPI('/refuelContent/saveMenu', values);
	}
	@action
	public async fetchContentManagement(feedListOptions: any) {
		const postData = {
			Category: feedListOptions.Category,
			Sort: feedListOptions.Sort,
			Order: feedListOptions.Order,
			PageSize: feedListOptions.PageSize ? feedListOptions.PageSize + 1 : 1,
			PageCount: feedListOptions.PageCount,
		};
		const contentManagement = await postAPI('/feedContent/category', postData);
		this.contentManagement = contentManagement.Result;
		this.contentRecords = contentManagement.Total;

		return this.contentManagement;
	}

	@action
	public async fetchContentEvacuation(feedListOptions: any) {
		const postData = {
			Category: feedListOptions.Category,
			Sort: feedListOptions.Sort,
			Order: feedListOptions.Order,
			PageSize: feedListOptions.PageSize ? feedListOptions.PageSize + 1 : 1,
			PageCount: feedListOptions.PageCount,
		};
		const contentManagement = await postAPI('/feedContent/category', postData);
		this.contentEvacuation = contentManagement.Result;
		this.contentRecords = contentManagement.Total;

		return this.contentEvacuation;
	}

	@action
	public async deleteContentItem(Id: any) {
		this.contentManagement = await deletePostAPI('/feedContent/delete', Id);
		return this.contentManagement;
	}

	@action
	public async sendSurveyReport(Id: any) {
		this.contentManagement = await getAPI(`/feedContent/surveyreport/${Id}`, {});
		return this.contentManagement;
	}

	@action
	public async deleteEvacuation(Id: any) {
		this.contentEvacuation = await deletePostAPI('/feedContent/delete', Id);
		return this.contentEvacuation;
	}

	@action
	public async fetchSurveyDetail(Id: number) {
		this.contentSurvey = await getAPI('/feedContent/' + Id);
		return this.contentSurvey;
	}

	@action
	public async fetchSurveyEmailReport(Id: number) {
		return await getAPI('/feedContent/emailReport/' + Id);
	}
}
export default ContentManagement;
