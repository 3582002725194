import * as React from 'react';
import { Container } from '@material-ui/core';
import { Alert } from '@mitie/material-ui-extensions';
import PermanentAppBar from 'components/Header/PermanentAppBar';

const NotFound: React.FunctionComponent = () => {
	return (
		<Container style={{ margin: '100px 0 0', textAlign: 'center' }}>
			<PermanentAppBar isUserLoggedIn={false} />
			<Alert>Page not found</Alert>
		</Container>
	);
};

export default NotFound;
