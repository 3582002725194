import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	androidDownloadIcon: {
		width: '52%',
		height: 'auto',
		display: 'inline-block',
		position: 'relative',
		top: '-33px',
	},
	'@global': {
		':root': {
			backgroundColor: '#F4F8FC',
			width: '100%',
			height: '100%',
		},
	},
	appleDownloadIcon: {
		width: '52%',
		height: 'auto',
		display: 'inline-block',
		position: 'relative',
		top: '-33px',
	},
	appIconLarge: {
		width: 'auto',
		height: 'auto',
		display: 'inline-block',
		position: 'relative',
		top: '-33px',
	},
	userLoginSection: {
		justifyContent: 'center',
		textAlign: 'center',
		'& > *': {
			margin: theme.spacing(0.5, 0),
		},
		position: 'relative',
		top: '80px',
	},

	textSize: {
		fontSize: '14px',
		position: 'relative',
		top: '-2px',
	},
}));

export default useStyles;
