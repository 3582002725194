import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { stores } from '../store';
import accessDeniedIcon from '../assets/images/access-denied.png';
import { faPlus, faTrash, faEdit, faGripLines, faSort, faCopy } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
	DraggableProvided,
	DroppableProvided,
	DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
	Avatar,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Tooltip,
	makeStyles,
	Grid,
	Paper,
	Fab,
	IconButton,
	Switch,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { IMenuGetItem } from '../types';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	title: {
		padding: theme.spacing(2),
	},
	spinner: {
		position: 'absolute',
		top: '40%',
		left: '50%',
		zIndex: 1,
	},
	floorplanAddIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	actionIcons: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	actionIconButton: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	actionHomeIconButtonActive: {
		'& > * svg': {
			color: theme.palette.text.primary,
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	tableHeaders: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'& > *': {
			visibility: 'hidden',
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	noResults: {
		textAlign: 'center',
		marginTop: '25vh',
	},
	textMargin: {
		margin: '2vh 0 10vh',
	},
	accessDimensions: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}));

const RefuelMenuList = observer(() => {
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const refuelMenus = React.useContext(stores.context.refuelMenus);
	const user = React.useContext(stores.context.user);
	const notifications = React.useContext(stores.context.notifications);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const classes = useStyles();
	const [showRefuelMenu, setShowRefuelMenu] = React.useState(0);
	const [menus, setMenus] = useState<IMenuGetItem[]>([]);
	appStores.showAppLoader = user.permissions === null ? true : false;
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const refuelIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '35FA69F9-7A9E-41FC-AFB3-A8BEFA4E4637',
			);
			if (user.permissions.Modules[refuelIndex] && refuelIndex && user.permissions.Modules[refuelIndex].Read) {
				if (
					user.permissions.Modules[refuelIndex].Create ||
					user.permissions.Modules[refuelIndex].Update ||
					user.permissions.Modules[refuelIndex].Delete
				) {
					setShowRefuelMenu(1);
				}
			} else {
				setShowRefuelMenu(0);
			}
		} else {
			setShowRefuelMenu(0);
		}
	}, [user.permissions]);

	localStorage.removeItem('URL');
	localStorage.removeItem('CMValue');

	useEffect(() => {
		const fetchMenus = async () => {
			await fetchRefuelMenus();
		};
		fetchMenus();
	}, [user.currentSiteId, refuelMenus, appStores]);

	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	const handleDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}
		const quotes: any = reorder(menus, result.source.index, result.destination.index);
		setMenus(quotes);
		const menuPosition = `${result.destination.index + 1}`;
		const idIndex = quotes.map((e: any) => e.Id);
		const menuId = idIndex[result.destination.index];
		const response = await refuelMenus.reorderRefuelMenu(menuId, menuPosition);
		if (response.Success && !response.Error) {
			notifications.addNotification('Menu reordered', 'success');
			await fetchRefuelMenus();
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};

	const fetchRefuelMenus = async () => {
		appStores.showAppLoader = true;
		const result = await refuelMenus.fetchRefuelMenus(user.currentSiteId);
		const orderedList = result.sort((a, b) => a.Order - b.Order);
		setMenus(toJS(orderedList));
		appStores.showAppLoader = false;
	};
	const deleteMenu = async (menuId: string) => {
		const response = await refuelMenus.deleteRefuelMenu(menuId);
		if (response.Success && !response.Error) {
			notifications.addNotification('Menu deleted', 'delete');
			await fetchRefuelMenus();
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};
	const handleChange = async (id: number) => {
		const newMenuState: IMenuGetItem[] = [...menus];
		if (id !== 0) {
			const menu = newMenuState.find((menuGetItem: IMenuGetItem) => {
				return menuGetItem.Id === id;
			}) as IMenuGetItem;

			menu.IsActive = !menu.IsActive;
			let params = {
				siteId: user.currentSiteId,
				RefuelMenus: [
					{
						Id: menu.Id,
						RefuelMenuTitle: menu.RefuelTitle,
						RefuelSystemName: menu.RefuelSystemName,
						MediaType: menu.MediaType,
						MediaUrl: menu.MediaUrl,
						IsActive: menu.IsActive,
						StartTime: menu.StartTime,
						ExpiredTime: menu.ExpiredTime,
					},
				],
			};

			setMenus(newMenuState);
			const response = await contentManagement.saveMenu(params);

			if (response.Success === true) {
				await refuelMenus.fetchRefuelMenus(user.currentSiteId);
			}
		}
	};

	return (
		<div>
			{showRefuelMenu === 1 && appStores.showAppLoader === false ? (
				!appStores.showAppLoader && (
					<Paper className={classes.paper}>
						<Grid container>
							<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
								<Typography variant="h5">Menu List</Typography>
							</Grid>
							<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
								<Tooltip title="Add">
									<Fab
										onClick={() => {
											routes.navigate(
												{ name: 'addMenus' },
												{
													clearParams: true,
												},
											);
										}}
										color="primary"
										aria-label="add"
										className={classes.floorplanAddIcon}
									>
										<Icon icon={faPlus} />
									</Fab>
								</Tooltip>
							</Grid>
						</Grid>
						{menus.length === 0 ? (
							<Grid container>
								<Grid item xs={12} className={classes.noResults}>
									<Typography variant="h6">We couldn't find Menus </Typography>
									<Typography className={classes.textMargin}>Please upload menus from Add button on top.</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid container>
								<Grid item xs={12}>
									<Table style={{ overflow: 'hidden' }}>
										<TableHead>
											<TableRow>
												<TableCell>
													<div className={classes.tableHeaders}>
														Menu Name <Icon icon={faSort} />
													</div>
												</TableCell>
												<TableCell>
													<div className={classes.tableHeaders}>
														System Name <Icon icon={faSort} />
													</div>
												</TableCell>
												<TableCell>
													<div className={classes.tableHeaders}>
														Format <Icon icon={faSort} />
													</div>
												</TableCell>
												<TableCell>
													<div className={classes.tableHeaders}>
														Active <Icon icon={faSort} />
													</div>
												</TableCell>
												<TableCell align="center">Actions</TableCell>
											</TableRow>
										</TableHead>
										<DragDropContext onDragEnd={handleDragEnd}>
											<Droppable droppableId="droppable" direction="vertical">
												{(droppableProvided: DroppableProvided) => (
													<TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
														{menus.map((menu: IMenuGetItem, index: number) => (
															<Draggable key={menu.Id} draggableId={menu.Id} index={index}>
																{(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
																	return (
																		<TableRow
																			ref={draggableProvided.innerRef}
																			{...draggableProvided.draggableProps}
																			style={{
																				...draggableProvided.draggableProps.style,
																				background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
																				position: 'relative',
																			}}
																			key={menu.Id!}
																			hover={true}
																			className="dataRow"
																		>
																			<TableCell>{menu.RefuelTitle}</TableCell>
																			<TableCell>{menu.RefuelSystemName}</TableCell>
																			<TableCell>{menu.MediaType}</TableCell>
																			<TableCell>
																				<Switch
																					key={menu.Id}
																					checked={menu.IsActive}
																					onChange={() => handleChange(menu.Id)}
																					name={menu.RefuelTitle}
																					color="primary"
																				/>
																			</TableCell>
																			<TableCell padding="none">
																				<div className={classes.actionIcons}>
																					<Tooltip title="Drag to reorder">
																						<IconButton
																							aria-label="Edit"
																							color="inherit"
																							size="small"
																							className={classes.actionIconButton}
																						>
																							<div {...draggableProvided.dragHandleProps}>
																								<Icon icon={faGripLines} />
																							</div>
																						</IconButton>
																					</Tooltip>
																					<Tooltip title="Make copy">
																						<IconButton
																							aria-label="Copy"
																							color="inherit"
																							size="small"
																							className={classes.actionIconButton}
																							onClick={() => {
																								if (menu.Id) {
																									routes.navigate(
																										{
																											name: 'copyMenus',
																											params: {
																												Id: menu.Id!,
																												title: menu.RefuelTitle,
																												systemName: menu.RefuelSystemName,
																												type: menu.MediaType,
																												siteId: menu.SiteId,
																												isActive: menu.IsActive,
																												startTime: menu.StartTime,
																												expireTime: menu.ExpiredTime,
																												url: menu.MediaUrl,
																												Heading: 'Copy',
																											},
																										},
																										{
																											clearParams: true,
																										},
																									);
																								}
																							}}
																						>
																							<Icon icon={faCopy} />
																						</IconButton>
																					</Tooltip>
																					<Tooltip title="Edit">
																						<IconButton
																							aria-label="Edit"
																							color="inherit"
																							size="small"
																							className={classes.actionIconButton}
																							onClick={() => {
																								routes.navigate(
																									{
																										name: 'editMenus',
																										params: {
																											Id: menu.Id!,
																											title: menu.RefuelTitle,
																											systemName: menu.RefuelSystemName,
																											type: menu.MediaType,
																											siteId: menu.SiteId,
																											isActive: menu.IsActive,
																											startTime: menu.StartTime,
																											expireTime: menu.ExpiredTime,
																											url: menu.MediaUrl,
																										},
																									},
																									{
																										clearParams: true,
																									},
																								);
																							}}
																						>
																							<Icon icon={faEdit} />
																						</IconButton>
																					</Tooltip>
																					<Tooltip title="Delete">
																						<IconButton
																							aria-label="Delete"
																							color="inherit"
																							size="small"
																							onClick={() => {
																								deleteMenu(menu.Id!);
																							}}
																							className={classes.actionIconButton}
																						>
																							<Icon icon={faTrash} />
																						</IconButton>
																					</Tooltip>
																				</div>
																			</TableCell>
																		</TableRow>
																	);
																}}
															</Draggable>
														))}
														{droppableProvided.placeholder}
													</TableBody>
												)}
											</Droppable>
										</DragDropContext>
									</Table>
								</Grid>
							</Grid>
						)}
					</Paper>
				)
			) : showRefuelMenu === 0 && appStores.showAppLoader === false ? (
				<>
					<Avatar alt="access denied" src={accessDeniedIcon} className={classes.accessDimensions} />
					<Typography variant="subtitle2">You do not have permission to access this area</Typography>
					<Typography variant="caption">Please contact your manager to request this access</Typography>
				</>
			) : (
				''
			)}
		</div>
	);
});

export default RefuelMenuList;
