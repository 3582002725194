import * as React from 'react';
import { makeStyles, Typography, Box, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faListAlt } from '@fortawesome/pro-light-svg-icons';
import { stores } from '../../store';
import { IAskItems } from '../../types';
import { displayDateTime } from '../../util';

interface IProps {
	ask: IAskItems;
}
const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 275,
	},
	actions: {
		justifyContent: 'flex-end',
	},
	IconStyle: {
		background: theme.palette.warning.main,
		color: theme.palette.background.paper,
		borderRadius: '25px',
		justifyContent: 'space-between',
	},
	closedCard: () => ({
		minWidth: '350px',
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
		//borderLeft: `3px solid ${props.feed.StatusColour}`,
	}),
	actionIconButton: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
}));

const AskWorkSpace = (props: IProps) => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const siteName = sites.siteList.findIndex((nam: any) => nam.Id === props.ask.SiteName);
	const location = siteName !== -1 ? sites.siteList[siteName].Name : '';
	return (
		<Card className={classes.closedCard}>
			<CardContent>
				<Box display="flex" justifyContent="center" p={1} bgcolor="background.paper">
					<Box p={1}>
						<Icon icon={faListAlt} size="3rem" />
					</Box>
				</Box>
				<Box display="flex" justifyContent="center">
					<Typography variant="h6" component="p">
						{props.ask.RequestType}
					</Typography>
				</Box>
				<Box textAlign="center" m={1}>
					<Typography variant="caption" display="block" color="textSecondary">
						{displayDateTime(props.ask.CreatedDate)}
					</Typography>
				</Box>
				<Box mt={2}>
					<Typography variant="subtitle2">Location</Typography>
					<Typography variant="caption" color="textSecondary">
						{location}
					</Typography>
				</Box>
				<Box mt={2}>
					<Typography variant="subtitle2">Message</Typography>
					<Typography variant="caption" color="textSecondary">
						{props.ask.Details}
					</Typography>
				</Box>
				<Box my={1}>
					<Typography variant="subtitle2">Attachments</Typography>
				</Box>
				<Box display="flex" flexDirection="row">
					{props.ask.Attachments.map((n: any) => (
						<Box display="flex" flexDirection="column" style={{ margin: 'auto' }}>
							<img src={n} alt="" width="33%" />
						</Box>
					))}
				</Box>
			</CardContent>
			<CardActions className={classes.actions}>
				<Button
					size="small"
					color="primary"
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={() => {
						const currentFeed = user.sentItems.find((f: any) => f.Id === props.ask.Id);
						currentFeed!.showOpenCard = false;
					}}
				>
					Close
				</Button>
			</CardActions>
		</Card>
	);
};

export default AskWorkSpace;
