import * as React from 'react';
import {
	makeStyles,
	Box,
	Avatar,
	Link,
	DialogTitle,
	Dialog,
	Button,
	DialogContentText,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
//import applconImg from '../../assets/images/ariaLogo.svg';
//import appLogoImg from '../../assets/images/aria_logo.png';
import appLogoImg from '../../assets/images/arialogo2.svg';

import { stores } from '../../store';

const useStyles = makeStyles(theme => ({
	appIconContainer: {
		display: 'flex',
		// cursor: 'pointer',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1, 0),
		},
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	appIcon: {
		width: '40px',
		height: '40px',
	},
	appLogo: {
		width: '116px',
		height: '40px',
		cursor: 'pointer',
	},
	drawerLogo: {
		marginLeft: '-27px !important',
	},
}));

const AppIcons = (props: any) => {
	const routes = React.useContext(stores.context.routes);
	const classes = useStyles();
	const appStores = React.useContext(stores.context.appStores);
	//const appIcon = applconImg;
	const appLogo = appLogoImg;
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	//const [routName, setRoutName] = React.useState('');
	//const [rotName, setRoutName] = React.useState('');
	const dialogfn = (val: any) => {
		//alert(window.location.pathname);
		//setRoutName(rotName);
		//alert(routes.testval);
		if (routes.testval === true && window.location.pathname === '/ask/chatbot') {
			setEsmeDialogOpen(true);
		} else {
			routes.navigate(
				{ name: val },
				{
					clearParams: true,
				},
			);
		}
	};

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const handleCloseChat = () => {
		routes.testval = false;
		setEsmeDialogOpen(false);
		routes.navigate(
			{ name: 'feed' },
			{
				clearParams: true,
			},
		);
	};

	return (
		<>
			<Box className={classes.appIconContainer}>
				{/* <Link
				onClick={() => {
					routes.navigate(
						{ name: 'feed' },
						{
							clearParams: true,
						},
					);
				}}
			>
				<Avatar variant="rounded" src={appIcon} className={classes.appIcon}></Avatar>
			</Link> */}
				<Link
					// onClick={() => {
					// 	routes.navigate(
					// 		{ name: 'feed' },
					// 		{
					// 			clearParams: true,
					// 		},
					// 	);
					// }}
					onClick={() => {
						dialogfn('feed');
					}}
				>
					{/* { appStores.desktopDrawer? (
						<Avatar variant="rounded" src={appLogo} className={`${classes.appLogo} ${classes.drawerLogo}`} ></Avatar>
					) : (
						<Avatar variant="rounded" src={appLogo} className={`${classes.appLogo}`}></Avatar>
					)} */}
					{appStores.drawerOpen ? (
						<Avatar variant="rounded" src={appLogo} className={`${classes.appLogo} ${classes.drawerLogo}`}></Avatar>
					) : (
						<Avatar variant="rounded" src={appLogo} className={`${classes.appLogo} ${props.className}`}></Avatar>
					)}
				</Link>
			</Box>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChat} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClose} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default AppIcons;
