import * as React from 'react';
import { Container } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { stores } from '../store';

const UnRegister: React.FunctionComponent = () => {
	const routes = React.useContext(stores.context.routes);
	return (
		<Container style={{ margin: '100px 0 0', textAlign: 'center' }}>
			<PermanentAppBar isUserLoggedIn={false} />
			<p>You don't have access. Please register to start using the application.</p>
			<Button
				size="small"
				color="primary"
				variant="contained"
				onClick={() => {
					routes.navigate({ name: 'register' });
				}}
			>
				Register
			</Button>
		</Container>
	);
};

export default UnRegister;
