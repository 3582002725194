const error = {
	email: 'Email is required',
	validEmail: 'Please Enter a valid email',
	fullName: 'FullName is required',
	telephone: 'Telephone Number is required',
	validNumber: 'Must contain `+44` international code',
	validNumberLatitude: 'Must be valid latitude',
	validNumberLongitude: 'Must be valid longitude',
};
export default error;
