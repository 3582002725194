import { stringify } from 'querystring';

async function parseResponse(response: any) {
	let json: any;
	const headerType = response.headers.get('Content-Type');
	//console.log(headerType);
	const csvType = 'text/csv';

	try {
		if (headerType && headerType.split(';').includes(csvType)) {
			json = await response.blob();
		}
		// else if(headerType && headerType.split(';').includes(texttype))
		// {
		// 	//console.log('test',response.text());
		// 	//json = await response.text();
		//     json = {
		// 		"Zones": [
		// 		  {
		// 			"ZoneId": "b72bf304-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 2",
		// 			"RoomId": 11,
		// 			"ZoneDescription": "Meeting Room 2",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "False",
		// 			"NumberOfPeople": "10",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneImageSource": "ShardMeetingRoom2.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bf476-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 3",
		// 			"ZoneDescription": "Meeting Room 3",
		// 			"RoomId": 12,
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "False",
		// 			"NumberOfPeople": "6",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom3.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bf5b6-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 4",
		// 			"RoomId": 13,
		// 			"ZoneDescription": "Meeting Room 4",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "False",
		// 			"NumberOfPeople": "6",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom4.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bf6f6-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 5",
		// 			"RoomId": 14,
		// 			"ZoneDescription": "Meeting Room 5",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "False",
		// 			"NumberOfPeople": "3",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom5.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bf836-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 6",
		// 			"RoomId": 15,
		// 			"ZoneDescription": "Meeting Room 6",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "4",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom6.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bfc0a-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 7",
		// 			"RoomId": 16,
		// 			"ZoneDescription": "Meeting Room 7",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "False",
		// 			"NumberOfPeople": "4",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom7.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bfd86-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 8",
		// 			"RoomId": 17,
		// 			"ZoneDescription": "Meeting Room 8",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "6",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom8.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72bfed0-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 9",
		// 			"RoomId": 18,
		// 			"ZoneDescription": "Meeting Room 9",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "4",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom9.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72c0006-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 10",
		// 			"RoomId": 19,
		// 			"ZoneDescription": "Meeting Room 10",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "3",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom10.jpg"
		// 		  },

		// 		  {
		// 			"ZoneId": "b72c077c-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 15",
		// 			"RoomId": 34,
		// 			"ZoneDescription": "Meeting Room 15",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "3",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom15.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "b72c08a8-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 16",
		// 			"RoomId": 35,
		// 			"ZoneDescription": "Meeting Room 16",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "6",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom16.jpg"
		// 		  },

		// 		  {
		// 			"ZoneId": "b72c0cb8-23df-11e9-ab14-d663bd873d93",
		// 			"ZoneName": "Sha- Meeting 18",
		// 			"RoomId": 38,
		// 			"ZoneDescription": "Meeting Room 18",
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "3",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom18.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "d0a42f65-552b-3112-8e7f-21cd9ca3c4ad",
		// 			"ZoneName": "Sha- Meeting 1",
		// 			"ZoneDescription": "Meeting Room 1",
		// 			"RoomId": 10,
		// 			"ZoneType": "meetingroom",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "4",
		// 			"ResourcesAvailable": [
		// 			  "1",
		// 			  "2"
		// 			],
		// 			"Climate": "0",
		// 			"Keywords": [
		// 			  "Professional",
		// 			  "Casual",
		// 			  "Private",
		// 			  "Quiet",
		// 			  "Comfortable",
		// 			  "Standing",
		// 			  "Accessible"
		// 			],
		// 			"SuitableFor": [
		// 			  "WorkingOnMyOwn",
		// 			  "CollaborativeWorking",
		// 			  "CatchingUpWithSomeone",
		// 			  "MeetingWithGroup",
		// 			  "Presentation",
		// 			  "GroupAndConferenceCall",
		// 			  "StandUp",
		// 			  "ClientMeeting"
		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "ShardMeetingRoom1.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "d0a42f65-552b-3112-8e7f-21cd9ca3c4ad",
		// 			"ZoneName": "S-HD02",
		// 			"ZoneDescription": "Shard Hot Desks",
		// 			"RoomId": 149,
		// 			"ZoneType": "hotdesks",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "1",
		// 			"ResourcesAvailable": [
		// 			  "51",
		// 			  "52",
		// 			  "53"

		// 			],
		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "Desk1.jpg"
		// 		  },
		// 		  {
		// 			"ZoneId": "d0a42f65-552b-3112-8e7f-21cd9ca3c4ad",
		// 			"ZoneName": "S-HD01",
		// 			"ZoneDescription": "Shard Hot Desks",
		// 			"RoomId": "148",

		// 			"ZoneType": "hotdesks",
		// 			"ContainsHotDesks": "True",
		// 			"NumberOfPeople": "1",
		// 			"ResourcesAvailable": [
		// 			  "51",
		// 			  "52",
		// 			  "53"

		// 			],

		// 			"ZoneStatus": "",
		// 			"ZoneImageSource": "Desk1.jpg"
		// 		  }

		// 		]
		// 	  }
		// 	//console.log('helooooo');
		// }
		else {
			json = await response.json();
			//console.log('heyyy',json);
		}
	} catch (e) {
		//console.log(e);
		json = undefined;
	}

	const status = response.status;
	if (status < 200 || status > 299) {
		const error = json !== undefined && json.error !== undefined ? json.error : response.statusText;

		const errorObject = {
			status,
			error,
			response: json,
		};
		throw errorObject;
	} else {
		return json;
	}
}

async function fetchData(url: string, options: RequestInit): Promise<any> {
	options.credentials = 'include';
	return fetch(url, options).then(parseResponse);
}

const httpMethods = {
	get(url: string, params?: any) {
		return fetchData(params ? `${url}?${stringify(params)}` : url, {
			method: 'GET',
			headers: {},
		});
	},

	del(url: string, params?: any) {
		return fetchData(params ? `${url}?${stringify(params)}` : url, {
			method: 'DELETE',
			headers: {},
		});
	},

	delPost(url: string, postData: {}) {
		return fetchData(url, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(postData),
		});
	},

	post(url: string, postData: {}) {
		return fetchData(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(postData),
		});
	},

	put(url: string, postData: {}) {
		return fetchData(url, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(postData),
		});
	},

	request(method: string, url: string, postData: {}) {
		let options: RequestInit;
		if (method.toUpperCase() === 'GET' || method.toUpperCase() === 'DELETE') {
			options = {
				method,
			};
		} else {
			options = {
				method,
				body: JSON.stringify(postData),
			};
		}
		return fetchData(url, options);
	},
};

export default {
	...httpMethods,

	generateHttpMethods(domain: string) {
		return {
			get: (path: string, params?: any) => httpMethods.get(domain + path, params),
			post: (path: string, params?: any) => httpMethods.post(domain + path, params),
			delPost: (path: string, params?: any) => httpMethods.delPost(domain + path, params),
			del: (path: string, params?: any) => httpMethods.del(domain + path, params),
			put: (path: string, params?: any) => httpMethods.put(domain + path, params),
		};
	},
};
