import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Box, Container, Grid, Typography, Link } from '@material-ui/core';
import { stores } from '../../store';
import { IAskItems } from '../../types';
import { toJS } from 'mobx';
import ClosedCard from './AskItems';
import PermanentAppBar from '../../components/Header/PermanentAppBar';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 70px)',
		display: 'flex',
	},
	askContainer: {
		width: '100%',
	},
}));
const SentItems = observer(() => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const appStores = React.useContext(stores.context.appStores);
	const [totalSentItems, setTotalSentItems] = React.useState<any>([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		user.getSentItems();
	}, []);
	React.useEffect(() => {
		setTotalSentItems([...user.sentItems, ...user.userSRs]);
	}, [user.sentItems, user.userSRs]);

	const fetchRecentlyCreatedJobs = (pageNo: Number) => {
		setLoading(true);
		user.GetUserCreatedSRs(pageNo).then((res: any) => {
			if (res) {
				setLoading(false);
			}
		});
	};
	appStores.showAppLoader = user.sentItems === null ? true : false;
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container}>
					<Box className={classes.askContainer} m={1}>
						<Box>
							{totalSentItems.length > 0 &&
								totalSentItems.map((askItem: IAskItems) => <ClosedCard ask={toJS(askItem)} key={askItem.Id} />)}

							<Grid item container xs={6} sm={6} justify="flex-end" style={{ padding: '8px' }}>
								<Typography variant="body2" color="primary" style={{ margin: 'auto' }}>
									{user.userSRs.length >= 10 && user.SRsTotalPages !== user.SRspageNo && (
										<Link
											style={{ marginLeft: '50px', cursor: 'pointer', textDecoration: 'none', fontWeight: 'bold' }}
											component="button"
											variant="body2"
											onClick={() => fetchRecentlyCreatedJobs(Number(user.SRspageNo) + 1)}
										>
											{loading ? 'Loading...' : 'Load more jobs'}
										</Link>
									)}
								</Typography>
							</Grid>
						</Box>
					</Box>
				</Container>
			</main>
		</>
	);
});

export default SentItems;
