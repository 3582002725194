import * as React from 'react';
import { ListItem, List, ListItemIcon, ListItemText, makeStyles, Badge, Typography, Collapse } from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import {
	faLayerGroup,
	faEdit,
	faIdCardAlt,
	faPollPeople,
	faBadgePercent,
	faExclamationTriangle,
	faBellSchool,
	faBox,
	faBullhorn,
	faHistory,
} from '@fortawesome/pro-light-svg-icons';
import {
	faLayerGroup as faLayerGroupSolid,
	faEdit as faEditSolid,
	faIdCardAlt as faIdCardAltSolid,
	faBadgePercent as faBadgePercentSolid,
	faPollPeople as faPollPeopleSolid,
	faExclamationTriangle as faExclamationTriangleSolid,
	faBellSchool as faBellSchoolSolid,
	faBox as faBoxSolid,
	faBullhorn as faBullhornSolid,
	faHistory as faHistorySolid,
} from '@fortawesome/pro-solid-svg-icons';
import { stores } from '../../store';
import { IHeaderNavProps } from '../../types';
import { observer } from 'mobx-react-lite';
import sidebarUseStyles from '../../assets/css/SideBarCss';

const useStyles = makeStyles(theme => ({
	textStyle: {
		marginLeft: '-18px',
	},

	textStylefeed: {
		marginLeft: '8px',
	},
	feedBadge: {
		'& span': {
			backgroundColor: theme.palette.warning.main,
			fontSize: '0.7rem',
			right: 'auto',
		},
	},
	listChildItemSelected: {
		backgroundColor: '#CFDBE5',
	},
}));

const HeaderNav = observer((props: IHeaderNavProps) => {
	const routes = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);
	const { routeName } = routes;
	const appStores = React.useContext(stores.context.appStores);
	const feed = React.useContext(stores.context.feed);
	const classesUseStyles = useStyles();
	const classesSideBar = sidebarUseStyles();
	const classes = props.classNames;
	const [selectedItem, setSelectedItem] = React.useState('');
	const [selectedMenuItem, setSelectedMenuItem] = React.useState('');
	const [currentFeedItems, setCurrentFeedItems] = React.useState(0);
	const [showFloorPlans, setShowFloorPlans] = React.useState(0);
	const contentManagementList: string[] = [
		'announcements',
		'announcementsEditor',
		'delivery',
		'evacuation',
		'evacuationEditor',
		'incident',
		'promotion',
		'promotionEditor',
		'survey',
		'surveyEditor',
		'surveyReport',
		'visitor',
	];
	const contentManagementListSelected = !!routeName && !!contentManagementList.includes(routeName);
	const [open, setOpen] = React.useState(
		routeName === 'feed' || routeName === 'archive' || contentManagementListSelected,
	);
	const [openCont, setOpenCont] = React.useState(false || contentManagementListSelected);
	const [contentClicked, setContentClicked] = React.useState(false);
	React.useEffect(() => {
		if (selectedItem === routeName || selectedMenuItem === routeName || (contentClicked && !openCont)) {
			navigateToPage('feed');
		}
		const mainFeedCount =
			feed.mainFeedItems && feed.mainFeedItems.Pagination && feed.mainFeedItems.Pagination.TotalFeedCount;
		const statusFeedCount =
			feed.statusFeedItems && feed.statusFeedItems.Pagination && feed.statusFeedItems.Pagination.TotalFeedCount;
		setCurrentFeedItems(mainFeedCount + statusFeedCount);
	}, [feed.statusFeedItems, feed.mainFeedItems, selectedItem, routeName, selectedMenuItem]);
	const navigateToPage = (route: string) => {
		routes.navigate(
			{
				name: route,
			},
			{ clearParams: true },
		);
	};
	const handleClick = () => {
		setOpenCont(!openCont);
		setOpen(true);
		setContentClicked(true);
	};
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);

			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create &&
					user.permissions.Modules[feedIndex].Update &&
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowFloorPlans(1);
				}
			} else {
				setShowFloorPlans(0);
			}
		} else {
			setShowFloorPlans(0);
		}
	}, [user.permissions]);

	return (
		<>
			<List disablePadding={false} className={classes.sideBarList}>
				<ListItem
					button
					disableTouchRipple={true}
					onClick={() => {
						appStores.showAppLoader = true;
						routes.navigate(
							{ name: 'feed' },
							{
								clearParams: true,
							},
						);
						appStores.drawerOpen = false;
						setOpen(true);
					}}
					selected={routeName === 'feed' || routeName === 'archive' || contentManagementListSelected}
				>
					<ListItemIcon className={classes.listIcon}>
						<React.Fragment>
							{currentFeedItems === 0 || currentFeedItems === null ? (
								''
							) : (
								<Badge
									badgeContent={currentFeedItems || '0'}
									color="primary"
									className={classesUseStyles.feedBadge}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
								>
									<Typography />
								</Badge>
							)}
							<Icon
								icon={
									routeName === 'feed' || routeName === 'archive' || contentManagementListSelected
										? faLayerGroupSolid
										: faLayerGroup
								}
							/>
						</React.Fragment>
					</ListItemIcon>
					<ListItemText primary="Feed" />
				</ListItem>
			</List>

			<Collapse
				in={open}
				timeout="auto"
				unmountOnExit
				className={open ? classes.listCollapseSelected : classes.listCollapse}
			>
				<List disablePadding={false} className={classesSideBar.contentMenuFeed}>
					<ListItem
						disableTouchRipple={true}
						button
						onClick={() => {
							navigateToPage('archive');
							setSelectedMenuItem('archive');
							appStores.drawerOpen = false;
						}}
						selected={selectedMenuItem === 'archive' || routeName === 'archive'}
					>
						<ListItemIcon className={classes.listIcon}>
							<Icon icon={routeName === 'archive' ? faHistorySolid : faHistory} />
						</ListItemIcon>
						<ListItemText primary="Archive" />
					</ListItem>
					{showFloorPlans === 1 ? (
						<>
							<ListItem
								disableTouchRipple={true}
								button
								onClick={() => {
									handleClick();
									setSelectedMenuItem('contentManagement');
								}}
								selected={contentManagementListSelected || openCont}
								className={
									openCont && selectedMenuItem === 'contentManagement' ? classesUseStyles.listChildItemSelected : ''
								}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={contentManagementListSelected || openCont ? faEditSolid : faEdit} />
								</ListItemIcon>
								<ListItemText primary="Content Management" />
							</ListItem>
							<Collapse
								in={openCont}
								timeout="auto"
								unmountOnExit
								className={contentManagementListSelected ? classes.listCollapseSelected : classes.listCollapse}
							>
								<List disablePadding={false}>
									<ListItem
										disableTouchRipple={true}
										button
										selected={routeName === 'announcements' || routeName === 'announcementsEditor'}
										onClick={() => {
											navigateToPage('announcements');
											setSelectedItem('announcements');
											appStores.drawerOpen = false;
										}}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon
												icon={
													(contentManagementListSelected && routeName === 'announcements') ||
													routeName === 'announcementsEditor'
														? faBullhornSolid
														: faBullhorn
												}
											/>
										</ListItemIcon>
										<ListItemText primary="Announcements" />
									</ListItem>

									<ListItem
										disableTouchRipple={true}
										button
										selected={routeName === 'delivery'}
										onClick={() => {
											navigateToPage('delivery');
											setSelectedItem('delivery');
											appStores.drawerOpen = false;
										}}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon
												className={classes.listIcon}
												icon={contentManagementListSelected && routeName === 'delivery' ? faBoxSolid : faBox}
											/>
										</ListItemIcon>
										<ListItemText primary="Delivery" />
									</ListItem>

									<ListItem
										disableTouchRipple={true}
										button
										onClick={() => {
											navigateToPage('evacuation');
											setSelectedItem('evacuation');
											appStores.drawerOpen = false;
										}}
										selected={routeName === 'evacuation' || routeName === 'evacuationEditor'}
									>
										<Icon
											icon={
												(contentManagementListSelected && routeName === 'evacuation') ||
												routeName === 'evacuationEditor'
													? faBellSchoolSolid
													: faBellSchool
											}
											className={classes.listIcon}
										/>
										<ListItemText primary="Evacuation test" />
									</ListItem>

									<ListItem
										disableTouchRipple={true}
										onClick={() => {
											navigateToPage('incident');
											setSelectedItem('incident');
											appStores.drawerOpen = false;
										}}
										button
										selected={routeName === 'incident'}
									>
										<Icon
											icon={
												contentManagementListSelected && routeName === 'incident'
													? faExclamationTriangleSolid
													: faExclamationTriangle
											}
											className={classes.listIcon}
										/>
										<ListItemText primary="Incident" />
									</ListItem>

									<ListItem
										onClick={() => {
											navigateToPage('promotion');
											setSelectedItem('promotion');
											appStores.drawerOpen = false;
										}}
										disableTouchRipple={true}
										button
										selected={routeName === 'promotion' || routeName === 'promotionEditor'}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon
												icon={
													(contentManagementListSelected && routeName === 'promotion') ||
													routeName === 'promotionEditor'
														? faBadgePercentSolid
														: faBadgePercent
												}
											/>
										</ListItemIcon>
										<ListItemText primary="Promotion" />
									</ListItem>

									<ListItem
										onClick={() => {
											navigateToPage('survey');
											setSelectedItem('survey');
											appStores.drawerOpen = false;
										}}
										disableTouchRipple={true}
										button
										selected={routeName === 'survey' || routeName === 'surveyEditor'}
									>
										<Icon
											icon={
												(contentManagementListSelected && routeName === 'survey') || routeName === 'surveyEditor'
													? faPollPeopleSolid
													: faPollPeople
											}
											className={classes.listIcon}
										/>

										<ListItemText primary="Survey" />
									</ListItem>

									<ListItem
										onClick={() => {
											navigateToPage('visitor');
											setSelectedItem('visitor');
											appStores.drawerOpen = false;
										}}
										disableTouchRipple={true}
										button
										selected={routeName === 'visitor'}
									>
										<Icon
											className={classes.listIcon}
											icon={contentManagementListSelected && routeName === 'visitor' ? faIdCardAltSolid : faIdCardAlt}
										/>

										<ListItemText primary="Visitor" />
									</ListItem>
								</List>
							</Collapse>
						</>
					) : (
						''
					)}
				</List>
			</Collapse>
		</>
	);
});

export default HeaderNav;
