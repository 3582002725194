import * as React from 'react';
import {
	makeStyles,
	Card,
	Grid,
	Paper,
	TextField,
	Typography,
	CardContent,
	Box,
	CardHeader,
	Link,
	CircularProgress,
} from '@material-ui/core';
import { displayLocalTime } from '../../util';
import { stores } from '../../store';
import { observer } from 'mobx-react-lite';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		boxShadow: 'none !important',
	},
	title: {
		padding: theme.spacing(2.1),
		paddingLeft: '6.2px',
		marginTop: '3px',
		paddingBottom: '16px',
	},
	text: {
		padding: '12px',
	},
	closedCard: {
		marginBottom: theme.spacing(2.3),
		border: 'none',
		cursor: 'pointer',
		'& .MuiCardHeader-root': {
			padding: '15px',
		},
		'& .MuiCardContent-root': {
			padding: '6px 16px 16px 16px',
		},
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
			border: 'none',
		},
		width: '460px',
		height: '124px',
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '8px',
		marginLeft: '4px',
	},
	Autocomplete: {
		width: '512px',
		height: '48px',
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#F9FBFD',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#F9FBFD',
		},
		'&[data-focus="true"]': {
			backgroundColor: '#098BBD',
		},

		'& .MuiAutocomplete-option': {
			'&[data-focus="true"]': {
				backgroundColor: '#F9FBFD !important',
			},
		},
		'& .MuiInputBase-input': {
			background: '#F9FBFD',
		},
	},
	CardHeader: {
		backgroundColor: '#CFDBE5',
		height: '30px',
	},
	ViewMore: {
		padding: '10px 20px',
		margin: '20px 0 30px 0',
		cursor: 'pointer',
	},
	options: {
		color: '#002855',
		font: 'normal normal normal 16px/19px Roboto',
		'&:hover': {
			backgroundColor: '#F4F8FC !important',
			color: '#002855',
			font: 'normal normal normal 16px/19px Roboto',
		},
	},
	jobsView: {
		width: '512px',
		height: '745px',
		background: '#F4F8FC 0% 0% no-repeat padding-box',
		borderRadius: '16px',
	},
}));

const RecentlyOpenedJobs: React.FunctionComponent = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const routes: any = React.useContext(stores.context.routes);
	const [recentlyopenedSRs, setRecentlyopenedSRs] = React.useState<any>([]);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [totalPages, setTotalPages] = React.useState(1);
	const [totalSRs, setTotalSRs] = React.useState();
	const [previousSRs, setPreviousSRs] = React.useState<any>([]);
	const [classificationValue, setClassificationValue] = React.useState<any>();
	React.useEffect(() => {
		fetchRecentlyOpenedJobs(1);
	}, []);
	const fetchRecentlyOpenedJobs = (pageNumber: Number) => {
		setLoading(true);
		user.getUserServiceRequests(pageNumber).then((resp: any) => {
			if (classificationValue && classificationValue !== '') {
				if (resp && resp.member) {
					const classificationFilter = resp.member.filter((classification: any) => {
						return classification.classstructure
							? classification.classstructure[0].commodities[0].description === classificationValue
							: '';
					});
					setRecentlyopenedSRs([...recentlyopenedSRs, ...classificationFilter]);
					setPreviousSRs([...previousSRs, ...resp.member]);
					setPageNumber(resp.responseInfo && resp.responseInfo.pagenum);
					setTotalPages(resp.responseInfo && resp.responseInfo.totalPages);
					setTotalSRs(resp.responseInfo && resp.responseInfo.totalCount);
					setLoading(false);
				}
			} else {
				if (resp && resp.member) {
					setRecentlyopenedSRs([...recentlyopenedSRs, ...resp.member]);
					setPreviousSRs([...previousSRs, ...resp.member]);
					setPageNumber(resp.responseInfo && resp.responseInfo.pagenum);
					setTotalPages(resp.responseInfo && resp.responseInfo.totalPages);
					setTotalSRs(resp.responseInfo && resp.responseInfo.totalCount);
					setLoading(false);
					setClassificationValue('');
				}
			}
		});
	};
	React.useEffect(() => {
		window.addEventListener('message', event => {
			if (event.data === 'caseCreated') {
				user.GetUserCreatedSRs2(1);
				user.getUserServiceRequests(1).then((resp: any) => {
					if (resp && resp.member) {
						setRecentlyopenedSRs([...recentlyopenedSRs, ...resp.member]);
						setPreviousSRs([...recentlyopenedSRs, ...resp.member]);
						setPageNumber(resp.responseInfo && resp.responseInfo.pagenum);
						setTotalPages(resp.responseInfo && resp.responseInfo.totalPages);
						setTotalSRs(resp.responseInfo && resp.responseInfo.totalCount);
						setLoading(false);
					}
				});
				//user.getSentItems();
			}
		});
	}, []);

	const handleClassifications = (value: any) => {
		if (value !== null && value !== 'NA') {
			setClassificationValue(value);
			const classificationFilter = recentlyopenedSRs.filter((classification: any) => {
				return classification.classstructure
					? classification.classstructure[0].commodities[0].description === value
					: '';
			});
			setRecentlyopenedSRs(classificationFilter);
		} else {
			setRecentlyopenedSRs(previousSRs);
			setClassificationValue('');
		}
	};
	return (
		<Paper className={classes.paper}>
			<Grid container>
				<Grid item xs={12} sm={12} className={classes.title}>
					<Typography
						variant="h5"
						style={{ textAlign: 'left', font: 'normal normal normal 18px/12px Roboto', color: '#002855' }}
					>
						Recently Opened Jobs
					</Typography>
				</Grid>
			</Grid>

			<Grid className={classes.jobsView}>
				{/* fix to fit to screen */}
				<Grid item xs={12} style={{ margin: '29px 30px 0px 9px', height: '675px', width: '480px', overflow: 'auto' }}>
					{user.CafmId === 1 && user.serviceRequests === null ? (
						<CircularProgress size={'6rem'} style={{ color: '#66CCFA', marginLeft: '5%', marginTop: '50%' }} />
					) : (
						''
					)}
					{user.serviceRequests && user.serviceRequests.Success === false ? (
						<Typography variant="subtitle1">You don't have any jobs for this location</Typography>
					) : user.CafmId && user.CafmId === 2 ? (
						<Typography variant="subtitle1">{''}</Typography>
					) : user.serviceRequests && user.serviceRequests.length === 0 ? (
						<Typography variant="subtitle1">You don't have any jobs for this location</Typography>
					) : (
						user.serviceRequests &&
						recentlyopenedSRs.map((n: any) => (
							<Card variant="outlined" className={classes.closedCard} key={n.ticketId}>
								<CardHeader className={classes.CardHeader}></CardHeader>
								<CardContent
									style={{ margin: 'auto' }}
									onClick={() => {
										routes.navigate({
											name: 'chatbot',
											params: {
												srId: n.ticketId,
											},
										});
									}}
								>
									<Box display="flex" style={{ justifyContent: 'space-between' }}>
										<Box display="flex" flexDirection="column" style={{ alignItems: 'flex-start', textAlign: 'left' }}>
											<Typography style={{ font: 'normal normal normal 16px/19px Roboto', color: '#002855', maxWidth:'200px' }}>
												{n.classstructure && n.classstructure[0] && n.classstructure[0].description || n.classstructure && n.classstructure[0] && n.classstructure[0].commodities && n.classstructure[0].commodities[0] && n.classstructure[0].commodities[0].description}
											</Typography>
											<Typography style={{ font: 'normal normal normal 14px/17px Roboto', color: '#8B8DAE' }}>
												{n.description}
											</Typography>
											<Typography style={{ font: 'normal normal normal 14px/17px Roboto', color: '#8B8DAE' }}>
												{n.locationDescription}{' '}
											</Typography>
										</Box>
										<Box display="flex" flexDirection="column" style={{ alignItems: 'flex-end', textAlign: 'right' }}>
											<Typography style={{ font: 'normal normal normal 14px/17px Roboto', color: '#002855' }}>
												{displayLocalTime(n.reportDate)}{' '}
											</Typography>
											<Typography style={{ font: 'normal normal normal 14px/17px Roboto', color: '#8B8DAE' }}>
												{' '}
												<span style={{ color: '#002855' }}>Status:</span> {n.statusDescription}
											</Typography>
											<Typography style={{ font: 'normal normal normal 14px/17px Roboto', color: '#8B8DAE' }}>
												<span style={{ color: '#002855' }}>Ref:</span> {n.ticketId}{' '}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						))
					)}
				</Grid>
				<Grid container style={{ width: '460px', marginLeft: '10px' }}>
					<Grid item container xs={6} sm={6} className={classes.text} justify="flex-start">
						{recentlyopenedSRs.length > 0 ? (
							<Typography variant="caption" color="textSecondary">
								Showing {recentlyopenedSRs.length} of {totalSRs}
							</Typography>
						) : (
							''
						)}
					</Grid>
					<Grid item container xs={6} sm={6} className={classes.text} justify="flex-end">
						<Typography variant="body2" color="primary">
							{recentlyopenedSRs.length > 0 && totalPages !== pageNumber && (
								<Link
									style={{ cursor: 'pointer', textDecoration: 'none', fontWeight: 'bold' }}
									component="button"
									variant="body2"
									onClick={() => fetchRecentlyOpenedJobs(Number(pageNumber) + 1)}
								>
									{loading ? 'Loading...' : 'Load more jobs'}
								</Link>
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid>
				<Autocomplete
					options={
						recentlyopenedSRs && recentlyopenedSRs.length > 0
							? [
									...new Set(
										recentlyopenedSRs &&
											recentlyopenedSRs.map((classifications: any) =>
												classifications.classstructure && classifications.classstructure[0] && classifications.classstructure[0].commodities && classifications.classstructure[0].commodities[0]
													? classifications.description || classifications.classstructure[0].commodities[0].description
													: 'NA',
											),
									),
							  ]
							: ['Loading...']
					}
					size="small"
					id="size-small-standard"
					noOptionsText="Loading..."
					classes={{
						option: classes.options,
					}}
					className={classes.Autocomplete}
					autoComplete
					onChange={(event: any, value: any) => handleClassifications(value)}
					renderInput={params => (
						<TextField {...params} label="Filter by classification" variant="filled" margin="normal" />
					)}
				/>
			</Grid>
		</Paper>
	);
});

export default RecentlyOpenedJobs;
