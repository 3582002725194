import { action, observable } from 'mobx';
import { getAPI, postAPI } from 'services/apiService';
import { stores } from '.';
import { ISiteObject } from '../types';
import { IAskItems } from 'types';
import { isMobile } from 'react-device-detect';

const serverUrl = process.env.REACT_APP_API_URL || '';

class User {
	@observable
	public username = '';
	@observable
	public floorList: any = []!;
	@observable
	public userId = '';
	@observable
	public token = '';
	@observable
	public clientId = '';
	public displayName = '';
	@observable
	public isAdmin = false;
	@observable
	public isSuperAdmin = false;

	@observable
	public emailPreference = false;

	@observable
	public userPhoneNumber: any = '';

	@observable
	public gettingUser = false;
	@observable
	public isUserLoggedIn: boolean | null = null;
	@observable
	public userAzureId = '';
	@observable
	public currentSiteId = '';
	@observable
	public siteClientId = '';
	@observable
	public esmesite: any = [];
	@observable
	public userEmail = '';
	@observable
	public usernewname = '';
	@observable
	public reportAccess: boolean;
	@observable
	public fetchdetails: any = [];
	@observable
	public sentItems: any = [];
	@observable
	public srFull: any = [];
	@observable
	public userSRs: any = [];
	@observable
	public SRsCount: string = '0';
	@observable
	public SRsTotalPages: number = 0;
	@observable
	public SRspageNo: number = 0;
	@observable
	public sentItemsCategories: any = [];

	@observable
	public floorsingledata: any = [];

	@observable
	public flrdataname: any = [];

	@observable
	public permissions: any = null;

	@observable
	public WebYoYo_Url: any = null;

	@observable
	public WebBoxed_Url: any = null;

	@observable
	public WebWioPay_Url: any = null;

	@observable
	public allSites: any[] = [];

	@observable
	public serviceRequests: any = null;

	@observable
	public sentItemsbadge: number = 0;
	@observable
	public PersonId = '';
	@observable
	public PersonUid = '';
	@observable
	public isDeleted: boolean | null = null;
	@observable
	public helpdeskInfo: any = null;
	@observable
	public promotionlists: any = [];

	@observable
	public evacuationlists: any = [];
	@observable
	public CafmId: number = 0;
	@observable
	public getUserCall: any = null;
	@observable
	public modulePermissions: any = null;
	@observable
	public featurePermissions: any = null;
	constructor() {
		this.fetchAskCategories();
		this.getSentItems();
		this.GetUserCreatedSRs(1);
		this.getUserDetails();
	}
	@action
	public logout() {
		this.username = '';
		this.displayName = '';
		localStorage.clear();
		window.location.replace(serverUrl + '/auth/logout?ref=' + encodeURIComponent(window.location.origin));
	}

	@action
	public async fetchIsAdmin() {
		const response = await getAPI(`/user/is-admin`, {});
		this.isAdmin = response.isAdmin;
	}

	@action
	public fetchAskCategories() {
		this.sentItemsCategories = [{ RequestType: 'Esme' }, { RequestType: 'Suggestion' }, { RequestType: 'Issue' }];
	}
	@action
	public async getUser(getUserCall: string) {
		this.getUserCall = getUserCall;
		if (this.gettingUser) {
			return;
		}
		this.gettingUser = true;
		const user = await getAPI(`/auth/me`, {});

		if (getUserCall !== 'root' && getUserCall !== 'register' && (!user || !user.displayName)) {
			return this.login();
		}

		this.isUserLoggedIn = user && user.displayName ? true : false;
		this.userId = user && user.userId ? user.userId : '';
		this.clientId = user && user.clientId ? user.clientId : '';
		//this.token = user && user.displayName.accessToken ? user.displayName.accessToken : '';
		this.token = user && user.token ? user.token : '';
		this.username = user && user.username ? user.username : '';
		this.displayName = user && user.displayName ? user.displayName : '';

		this.gettingUser = false;
	}

	@action
	public async getUserDetails() {
		if (this.gettingUser) {
			return;
		}
		this.gettingUser = true;
		const user = await getAPI(`/auth/me`, {});

		if (this.getUserCall !== 'root' && this.getUserCall !== 'register' && (!user || !user.displayName)) {
			return this.login();
		}

		this.isUserLoggedIn = user && user.displayName ? true : false;
		this.userId = user && user.userId ? user.userId : '';
		this.clientId = user && user.clientId ? user.clientId : '';
		this.token = user && user.token ? user.token : '';
		this.username = user && user.username ? user.username : '';
		this.displayName = user && user.displayName ? user.displayName : '';

		if (this.isUserLoggedIn) {
			const userData = await getAPI(`/user/data`, {});
			this.isSuperAdmin = userData && userData.IsSuperAdmin ? userData.IsSuperAdmin : false;
			this.isAdmin = userData && userData.IsAdmin ? userData.IsAdmin : false;
			this.userAzureId = userData && userData.UserAzureObjectId ? userData.UserAzureObjectId : '';
			this.currentSiteId = userData && userData.CurrentSiteId ? userData.CurrentSiteId : '';
			this.WebYoYo_Url = userData && userData.WebYoYo_Url ? userData.WebYoYo_Url : '';
			this.WebBoxed_Url = userData && userData.WebBoxed_Url ? userData.WebBoxed_Url : '';
			this.WebWioPay_Url = userData && userData.WebWioPay_Url ? userData.WebWioPay_Url : '';
			this.userPhoneNumber = userData && userData.Telephoneno ? userData.Telephoneno : '';
			this.modulePermissions = userData.PermissionData && userData.PermissionData.Modules;
			this.featurePermissions = userData.PermissionData && userData.PermissionData.Features;
			this.userEmail = userData && userData.Email ? userData.Email : '';
			this.usernewname = userData && userData.Name ? userData.Name : '';
			this.reportAccess = userData && userData.ReportAccess ? userData.ReportAccess : '';
			this.CafmId = userData && userData.CAFMId ? userData && userData.CAFMId : 0;
			this.siteClientId = userData && userData.CurrentSiteClientId ? userData.CurrentSiteClientId : '';
			let sitedat: any = localStorage.getItem('sitedatalat');
			let prechk: ISiteObject[] = JSON.parse(sitedat);
			stores.spaces.getCurrentSiteId();
			let sitedatanew: any = localStorage.getItem('sitedatalat');
			let df: ISiteObject[] = JSON.parse(sitedatanew);
			const sites: any = sitedat ? prechk : await stores.site.fetchSiteEntities();
			this.esmesite = sites;

			this.emailPreference = userData && userData.EmailPreference ? userData.EmailPreference : false;
			if (!this.permissions) {
				const client = sitedatanew
					? df.find((s: any) => s.Id === this.currentSiteId)
					: sites?.find((s: any) => s.Id === this.currentSiteId);
				const response = await postAPI(`/user/permissions`, { siteId: client?.ClientId });
				this.permissions = response.Permissions;
			}
			const floorplan = await postAPI('/spaces/allClients/floorplans/fetch', {
				pCount: 0,
				pSize: 10,
				sort: 'asc',
				order: 'FloorplanName',
			});

			const fllordata = await floorplan.floorplans.findIndex((floordt: any) => {
				return floordt.active == 'True' && floordt.buildingId === this.currentSiteId;
			});

			const fllordatanew = await floorplan.floorplans;
			this.flrdataname = await fllordatanew;
			this.floorsingledata = await floorplan.floorplans[fllordata];

			const clients = await stores.site.fetchSiteAndClientEntities();
			this.allSites = clients.filter((item: any) => item.LocationStatus !== 'INACTIVE');

			let sss: ISiteObject[] = sites;
			localStorage.setItem('sitedatalat', JSON.stringify(sss));

			// const fetchdetails = await postAPI(`/user/fetchdetails`, {
			// 	Siteid: this.currentSiteId,
			// 	email: this.userEmail != '' ? this.userEmail : user.username,
			// });
			// this.fetchdetails = fetchdetails.EntityDetails;

			if (!sites && sites.length > 0) {
				let sitecnt = sites.length;
				if (df.length !== sitecnt) {
					localStorage.setItem('sitedatalat', '');
				}
				if (localStorage.getItem('sitedatalat') == '') {
					localStorage.setItem('sitedatalat', JSON.stringify(sss));
				}
			}
			this.isDeleted = userData && userData.IsDeleted;
			this.isAdmin = userData && userData.IsAdmin ? userData.IsAdmin : false;
			//this.isSuperAdmin = userData && userData.IsSuperAdmin ? userData.IsSuperAdmin : false;
			this.userAzureId = userData && userData.UserAzureObjectId ? userData.UserAzureObjectId : '';
			this.currentSiteId = userData && userData.CurrentSiteId ? userData.CurrentSiteId : '';
			this.PersonId = userData && userData.PersonId ? userData.PersonId : '';
			this.PersonUid = userData && userData.PersonUid ? userData.PersonUid : '';
			this.esmesite = sites;
			//this.permissions = response.Permissions;
		}

		this.gettingUser = false;
	}

	@action
	public async getHelpDeskInfo() {
		try {
			const fetchdetails = await postAPI(`/user/fetchdetails`, {
				Siteid: this.currentSiteId,
				email: this.userEmail != '' ? this.userEmail : this.username,
			});
			this.fetchdetails = fetchdetails.EntityDetails;
		} catch (e) {
			console.error(`Help desk Info API failed with error :${e}`);
		}
	}
	@action
	public async login() {
		if (isMobile) {
			return;
		}
		window.location.replace(`${serverUrl}/auth/login?ref=${encodeURIComponent(window.location.href)}`);
		return;
	}

	@action
	public async updateUserSite(siteId: string) {
		return await postAPI(`/user/site`, { siteId });
	}

	@action
	async showAskCurrentCard(Id: string) {
		const askSRIndex = this.userSRs.findIndex((n: any) => n.Id === Id);
		const askSuggestionIndex = this.sentItems.findIndex((n: any) => n.Id === Id);
		if (askSRIndex >= 0) {
			this.srFull = await getAPI(`/ask/srfull/${Id}`);
			this.userSRs.forEach((f: any) => (f.showOpenCard = f.Id === Id));
		} else if (askSuggestionIndex >= 0) {
			this.sentItems.forEach((f: any) => (f.showOpenCard = f.Id === Id));
		}
	}

	@action
	public async updateuserDetails(SaveInfo: any) {
		return await postAPI('/user/person', SaveInfo);
	}

	@action
	public async getUserServiceRequests(pageNo: Number) {
		const res = await getAPI(`/ask/srlite/${pageNo}`);
		if (Array.isArray(res && res.member)) {
			//weird validation due to weird success/failure response formats
			res &&
				res.member.sort((a: any, b: any) => {
					if (a.reportDate > b.reportDate) return -1;
					if (a.reportDate < b.reportDate) return 1;
					return 0;
				});
			this.serviceRequests = res.member;
		}
		return res;
	}

	@action
	public async closeAccount() {
		return await postAPI('/user/closeaccount', {});
	}

	@action
	public async getHelpDeskDetails() {
		this.helpdeskInfo = await getAPI(`/user/helpdesk`, {});
	}

	@action
	public async getSentItems() {
		let sentItems = await getAPI(`/ask/sentitems`, {});
		sentItems = sentItems.map((f: IAskItems) => ({ ...f, showOpenCard: false }));
		sentItems.sort((a: IAskItems, b: IAskItems) => {
			const d1 = a.RequestType === 'Esme' ? a.reportDate : a.CreatedDate;
			const d2 = b.RequestType === 'Esme' ? b.reportDate : b.CreatedDate;

			if (d1 > d2) return -1;
			if (d1 < d2) return 1;
			return 0;
		});
		this.sentItems = sentItems;
	}
	public async GetUserCreatedSRs(pageNo: Number) {
		const res = await getAPI(`/ask/usercreatedsr/${pageNo}`);
		const arr = this.userSRs;
		if (Array.isArray(res && res.member)) {
			this.userSRs = [...arr, ...(res && res.member.map((f: IAskItems) => ({ ...f, showOpenCard: false })))];
			this.SRsCount = res && res.responseInfo && res.responseInfo.totalCount;
			this.SRspageNo = res && res.responseInfo && res.responseInfo.pagenum;
			this.SRsTotalPages = res && res.responseInfo && res.responseInfo.totalPages;
		}
		return res;
	}
	public async GetUserCreatedSRs2(pageNo: Number) {
		const res = await getAPI(`/ask/usercreatedsr/${pageNo}`);
		if (Array.isArray(res && res.member)) {
			this.userSRs = res.member.map((f: IAskItems) => ({ ...f, showOpenCard: false }));
			this.SRsCount = res && res.responseInfo && res.responseInfo.totalCount;
			this.SRspageNo = res && res.responseInfo && res.responseInfo.pagenum;
			this.SRsTotalPages = res && res.responseInfo && res.responseInfo.totalPages;
		}
		return res;
	}
	@action
	public async userCheck(email: string) {
		return await getAPI(`/user/usercheck/${email}`);
	}
	@action
	public async userExisted(userMailId: string) {
		return await getAPI(`/user/userexisted/${userMailId}`);
	}
	@action
	public async sendInvitation(email: string) {
		let newData = {
			emailId: email,
		};

		return await postAPI(`/user/invitation`, newData);
	}
}

export default User;
