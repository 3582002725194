import React, { useState, useEffect } from 'react';
import { displayDateTime } from '../../util';
import { observer, useLocalStore } from 'mobx-react-lite';
import { stores } from '../../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import classnames from 'classnames';
import { faPlus, faEdit, faCopy, faSlidersH, faStars, faTrash, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import useStyles from '../../assets/css/ListTheme';
import Tooltip from '@material-ui/core/Tooltip';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Grid,
	TablePagination,
	TableFooter,
	Paper,
	Fab,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Button,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { IContent, Category, ISiteObject } from '../../types';
import ReadMore from '../../components/ReadMore';
import accessDeniedIcon from '../../assets/images/access-denied.png';

interface IFeedListOptions {
	Category?: number;
	PageCount?: number;
	PageSize?: number;
	Sort?: number;
	Order?: string;
	SearchText?: string;
	TotalCount?: number;
}

const Surveylist = observer(() => {
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const feed = React.useContext(stores.context.feed);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	const sites = React.useContext(stores.context.site);
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const rowsPerPageOptions = [2, 5, 10, 15];
	const [menus, setMenus] = useState<IContent[]>([]);

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [deleteId, setDeleteID] = React.useState(Number);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [showSurveyForm, setShowSurveyForm] = React.useState(0);
	appStores.showAppLoader = user.permissions === null ? true : false;

	let feedListOptions: IFeedListOptions = useLocalStore(() => ({
		Category: Category.Survey,
		PageCount: 10,
		PageSize: 0,
		Sort: 0,
		Order: 'ModifiedTime',
		SearchText: '',
		TotalCount: 0,
	}));

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		feedListOptions.PageSize = newPage;
		fetchContentManagement();
	};
	const handleClick = (header: string) => {
		if (feedListOptions.Sort === 1) {
			feedListOptions.Sort = 0;
		} else {
			feedListOptions.Sort = 1;
		}
		feedListOptions.Order = header;
		fetchContentManagement();
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		feedListOptions.PageCount = parseInt(event.target.value, 10);
		feedListOptions.PageSize = 0;
		fetchContentManagement();
	};

	const fetchContentManagement = async () => {
		appStores.showAppLoader = true;
		const results = await contentManagement.fetchContentManagement(feedListOptions);

		setMenus(toJS(results));
		appStores.showAppLoader = false;
	};

	localStorage.removeItem('URL');
	localStorage.removeItem('CMValue');

	useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowSurveyForm(1);
				}
			} else {
				setShowSurveyForm(0);
			}
		} else {
			setShowSurveyForm(0);
		}
	}, [user.permissions]);
	useEffect(() => {
		const fetchMenus = async () => {
			await fetchContentManagement();
		};
		fetchMenus();
	}, []);

	const getFeedCount = () => {
		feedListOptions.TotalCount = contentManagement.contentRecords;
		return feedListOptions.TotalCount || 0;
	};

	const handleClickOpen = (Id: number) => {
		setDialogOpen(true);
		setDeleteID(Id);
	};

	// const sendReport = async (menuId: any) => {
	// 	if (menuId !== '') {
	// 		const response = await contentManagement.sendSurveyReport([menuId]);
	// 		if (response) {
	// 			notifications.addNotification('Survey report sent to email sucessfully', 'success');
	// 		} else {
	// 			notifications.addNotification(response + 'Something went wrong, Please try again', 'error');
	// 		}
	// 	}
	// };

	const deleteItem = async (menuId: any) => {
		if (menuId !== '') {
			const response = await contentManagement.deleteContentItem([menuId]);
			if (response) {
				notifications.addNotification('Survey deleted', 'delete');
				await fetchContentManagement();
				setDialogOpen(false);
			} else {
				notifications.addNotification(response + 'Something went wrong, Please try again', 'error');
			}
		}
	};
	const handleClose = () => {
		setDialogOpen(false);
	};

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div>
					{showSurveyForm === 1 && appStores.showAppLoader === false ? (
						<Paper className={classes.paper}>
							<Grid container>
								<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
									<Typography variant="h5">Surveys</Typography>
								</Grid>
								<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
									<Fab
										color="primary"
										aria-label="add"
										className={classes.addIcon}
										onClick={() => {
											routes.navigate(
												{ name: 'surveyEditor' },
												{
													clearParams: true,
												},
											);
										}}
									>
										<Icon icon={faPlus} />
									</Fab>
								</Grid>
							</Grid>
							{menus.length === 0 ? (
								<Grid container>
									<Grid item xs={12} className={classes.noResults}>
										<Typography variant="h6">We couldn't find Survey List </Typography>
										<Typography className={classes.textMargin}>Please upload survey from Add button on top.</Typography>
									</Grid>
								</Grid>
							) : (
								<Grid container>
									<Grid item xs={12}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<div className={classes.tableHeaders}>
															Sites
															{/* <Icon icon={faSort}  />*/}
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Type{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('surveytype');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Title{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('Title');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Subtitle{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('Description');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Last Edited{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('ModifiedTime');
																}}
															/>
														</div>
													</TableCell>
													<TableCell>
														<div className={classes.tableHeaders}>
															Status{' '}
															<Icon
																icon={faSort}
																onClick={() => {
																	handleClick('Status');
																}}
															/>
														</div>
													</TableCell>
													<TableCell align="center">Actions</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{menus.map((list: IContent, index: number) => {
													let allSitesofSurvey: any = [];
													list.Parameters.SiteId.map((item: any) => {
														let siteIndex = df.findIndex((nam: any) => nam.Id == item);
														let SiteName = siteIndex != -1 ? df[siteIndex].Name : '';
														if (!allSitesofSurvey.includes(SiteName)) {
															allSitesofSurvey.push(SiteName);
														}
														return;
													});
													return (
														<TableRow key={list.Id} hover={true} className="dataRow">
															<TableCell>
																<ReadMore sites={allSitesofSurvey} />
															</TableCell>
															<TableCell>
																{feed.surveyTypes[list.Parameters.SurveyTypeId] === 'NPS Survey' ? (
																	<Icon icon={faSlidersH} />
																) : (
																	<Icon icon={faStars} />
																)}
															</TableCell>
															<TableCell>{list.Title}</TableCell>
															<TableCell>{list.Description}</TableCell>
															<TableCell>{displayDateTime(list.ModifiedTime)}</TableCell>
															<TableCell>{list.Status}</TableCell>
															<TableCell padding="none">
																<div className={classes.actionIcons}>
																	<Tooltip title="View Report">
																		<IconButton
																			aria-label="View Report"
																			color="inherit"
																			size="small"
																			className={classnames(classes.actionIconButton)}
																			onClick={() => {
																				if (list.Id) {
																					routes.navigate(
																						{
																							name: 'surveyReport',
																							params: {
																								Id: list.Id,
																							},
																						},
																						{
																							clearParams: true,
																						},
																					);
																				}
																			}}
																			disabled={list.Status === 'Published' ? false : true}
																		>
																			<Icon icon={faFilePdf} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Make copy">
																		<IconButton
																			aria-label="Copy"
																			color="inherit"
																			size="small"
																			className={classnames(classes.actionIconButton)}
																			onClick={() => {
																				if (list.Id) {
																					routes.navigate(
																						{
																							name: 'surveyEditor',
																							params: {
																								SiteId: list.Parameters.SiteId,
																								Title: list.Parameters.SurveyTitle,
																								Subtitle: list.Parameters.SurveyDescription,
																								Questions: list.Parameters.Questions,
																								StartDate: list.Parameters.StartDate,
																								Expires: list.Parameters.EndDate,
																								SurveyLock: list.Parameters.SurveyLock,
																								MediaType: list.Parameters.MediaType,
																								MediaUrl: list.Parameters.MediaUrl,
																								MediaPreviewUrl: list.Parameters.MediaPreviewUrl,
																								SurveyType:
																									feed.surveyTypes[list.Parameters.SurveyTypeId] === 'NPS Survey'
																										? 'NPS'
																										: 'STAR',
																								Category: Category.Survey,
																								Heading: 'Edit Copy',
																								lockSurvey: list.Parameters.SurveyLock,
																							},
																						},
																						{
																							clearParams: true,
																						},
																					);
																				}
																			}}
																		>
																			<Icon icon={faCopy} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Edit">
																		<IconButton
																			aria-label="Edit"
																			color="inherit"
																			size="small"
																			className={classes.actionIconButton}
																			onClick={() => {
																				if (list.Id) {
																					routes.navigate(
																						{
																							name: 'surveyEditor',
																							params: {
																								Id: list.Id,
																								SiteId: list.Parameters.SiteId,
																								Title: list.Parameters.SurveyTitle,
																								Subtitle: list.Parameters.SurveyDescription,
																								Questions: list.Parameters.Questions,
																								StartDate: list.Parameters.StartDate,
																								Expires: list.Parameters.EndDate,
																								SurveyLock: list.Parameters.SurveyLock,
																								EndDate: list.Parameters.EndDate,
																								SurveyType:
																									feed.surveyTypes[list.Parameters.SurveyTypeId] === 'NPS Survey'
																										? 'NPS'
																										: 'STAR',
																								MediaType: list.Parameters.MediaType,
																								MediaUrl: list.Parameters.MediaUrl,
																								MediaPreviewUrl: list.Parameters.MediaPreviewUrl,
																								Category: Category.Survey,
																								status: list.Status,
																								lockSurvey: list.Parameters.SurveyLock,
																							},
																						},
																						{
																							clearParams: true,
																						},
																					);
																				}
																			}}
																		>
																			<Icon icon={faEdit} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Delete">
																		<IconButton
																			aria-label="Delete"
																			color="inherit"
																			size="small"
																			onClick={() => {
																				handleClickOpen(list.Id);
																			}}
																			className={classes.actionIconButton}
																		>
																			<Icon icon={faTrash} />
																		</IconButton>
																	</Tooltip>
																</div>
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
											<TableFooter>
												<TableRow>
													<TablePagination
														rowsPerPageOptions={rowsPerPageOptions}
														colSpan={7}
														count={getFeedCount() > 0 ? getFeedCount() : 0}
														rowsPerPage={feedListOptions.PageCount ? feedListOptions.PageCount : 5}
														page={feedListOptions.PageSize ? feedListOptions.PageSize : 0}
														SelectProps={{
															inputProps: { 'aria-label': 'Rows per page' },
															native: true,
														}}
														onChangePage={handleChangePage}
														onChangeRowsPerPage={handleChangeRowsPerPage}
													/>
												</TableRow>
											</TableFooter>
										</Table>
									</Grid>
								</Grid>
							)}
						</Paper>
					) : showSurveyForm === 0 && appStores.showAppLoader === false ? (
						<>
							<img src={accessDeniedIcon} className={classes.accessDimensionsForm} alt="" />
							<div className={classes.textMargin}>
								<Typography variant="subtitle2">You do not have permission to access this area</Typography>
								<Typography variant="caption">Please contact your manager to request this access</Typography>
							</div>
						</>
					) : (
						''
					)}
				</div>
				<Dialog
					open={dialogOpen}
					onClose={() => handleClose()}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete <br /> this item?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={() => {
								deleteItem(deleteId);
							}}
						>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</main>
		</>
	);
});

export default Surveylist;
