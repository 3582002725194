import React from 'react';
import { forwardRef } from 'react';

import { FormControl, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface PropsForm {
	onStartTime: (time: Date) => void;
	onEndTime: (time: Date) => void;
	selectedStartTime: Date;
	selectedEndTime: Date;
	startTimeError: string;
	endTimeError: string;
}

const TimeController = forwardRef<HTMLInputElement, PropsForm>(
	({ onStartTime, onEndTime, selectedStartTime, startTimeError, selectedEndTime, endTimeError }, ref) => {
		const classes = useStyles();

		const handleStartTime = (time: Date) => {
			onStartTime(time);
		};
		const handleEndTime = (time: Date) => {
			onEndTime(time);
		};
		return (
			<>
				<FormControl className={classes.formControl}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardTimePicker
							name="startTime"
							label="Opens"
							margin="normal"
							format="HH:mm"
							ampm={false}
							fullWidth
							value={selectedStartTime}
							onChange={handleStartTime}
							error={Boolean(startTimeError)}
							helperText={startTimeError}
							inputRef={ref}
							inputProps={{ className: classes.dateStyle }}
							InputLabelProps={{ className: classes.InputLabel }}
						/>
					</MuiPickersUtilsProvider>
				</FormControl>
				<FormControl className={classes.formControl}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardTimePicker
							name="endTime"
							label="Closes"
							margin="normal"
							format="HH:mm"
							ampm={false}
							fullWidth
							value={selectedEndTime}
							onChange={handleEndTime}
							inputProps={{ className: classes.dateStyle }}
							InputLabelProps={{ className: classes.InputLabel }}
							error={Boolean(endTimeError)}
							helperText={endTimeError}
						/>
					</MuiPickersUtilsProvider>
				</FormControl>
			</>
		);
	},
);
export default TimeController;
const useStyles = makeStyles(theme => ({
	dateStyle: {
		background: 'none',
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(1.5),
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
	},
	formControl: {
		paddingTop: theme.spacing(1),
		width: '100%',
	},
}));
