import React from 'react';
import { autorun } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
	Grid,
	MenuItem,
	Typography,
	Container,
	FormControl,
	TextField,
	Box,
	InputLabel,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	DialogContentText,
	Select,
	FormHelperText,
	Backdrop,
	CircularProgress,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { string as yupString, object as yupObject } from 'yup';
import useStyles from '../../../assets/css/ListTheme';
import { IContent, Category, repeat, ContainerID, mediaTypes } from '../../../types';
import { stores } from '../../../store';
import accessDeniedIcon from '../../../assets/images/access-denied.png';
import { compareAsc } from 'date-fns';
import ClientsAndSites from 'components/ClientsAndSites';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { faPlusCircle as PlusSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle as PlusLight } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Icon } from '@mitie/material-ui-extensions';
import ReachTextEditor from './component/ReachTextEditor';
import DatePicker from './component/DatePicker';
import { imageExt, isValidFileType, videoExt } from '../../../util';
// import _ from 'lodash';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { IconButton, InputAdornment } from '@material-ui/core';

const yup = require('yup');
interface PropsForm {
	props: string;
	config: any;
}

const fieldSchema = yupObject().shape({
	title: yupString().required('Title is required'),
	subtitle: yupString().required('Subtitle is required'),
	description: yupString().required('Description is required'),
});

const initialState = {
	Id: 0,
	SiteId: '',
	siteId: '',
	SiteIds: '',
	Title: '',
	title: '',
	description: '',
	CollapsedDescription: '',
	repeat: 0,
	subtitle: '',
	PromotionName: '',
	ShortDescription: '',
	Description: '',
	RepeatFrequency: 0,
	RepeatUntil: new Date(),
	StartTime: new Date(),
	startTime: new Date(),
	ExpiredTime: new Date(),
	errors: '',
	MediaType: 0 || undefined,
	repeatUntil: new Date(),
	token: '',
	MediaUrl: '',
	MediaPreviewUrl: '',
	Status: '',
	Parameters: {},
	CardTitle: '',
	CardSubtitle: '',
	endTime: new Date(),
	CardDescription: '',
	IsDraft: false,
	ModifiedTime: new Date(),
	buttonMode: false,
	mediaUrl: '',
	mediaPreviewUrl: '',
};

const editValuesState = {
	Id: 0,
	SiteId: '',
	siteId: '',
	repeat: 0,
	SiteIds: '',
	Title: '',
	title: '',
	subtitle: '',
	PromotionName: '',
	ShortDescription: '',
	description: '',
	CollapsedDescription: '',
	Description: '',
	RepeatFrequency: 0,
	RepeatUntil: new Date(),
	StartTime: new Date(),
	startTime: new Date(),
	ExpiredTime: new Date(),
	errors: '',
	MediaType: 0 || undefined,
	mediaUrl: '',
	mediaPreviewUrl: '',
	repeatUntil: new Date(),
	token: '',
	MediaUrl: '',
	MediaPreviewUrl: '',
	Status: '',
	Parameters: {},
	CardTitle: '',
	CardSubtitle: '',
	endTime: new Date(),
	CardDescription: '',
	IsDraft: false,
	ModifiedTime: new Date(),
	buttonMode: false,
};

const ContentForm = React.forwardRef<HTMLInputElement, PropsForm>(({ props, config }, ref) => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const site = React.useContext(stores.context.site);
	const content = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	const { route } = React.useContext(stores.context.routes);
	const routes = React.useContext(stores.context.routes);
	const date = new Date(new Date().setDate(new Date().getDate() + 1));
	const newdate: any = date.setHours(0, 0);
	const [bookingLoader, setBookingLoader] = React.useState(false);
	const [values, setValues] = React.useState<IContent>(initialState);
	const { MediaType } = values;
	const methods = useForm<IContent>({
		defaultValues: initialState,
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const [cardTitle, setCardTitle] = React.useState('Add ' + props);
	const { setValue, setError, formState, triggerValidation, clearError, errors } = methods;
	const [selectedRepeatUntil, setSelectedRepeatUntil] = React.useState<Date>(
		route.params.RepeatUntil ? new Date(route.params.RepeatUntil) : new Date(),
	);
	const [selectedStartTime, setSelectedStartTime] = React.useState<Date>(
		route.params.StartTime ? new Date(route.params.StartTime) : new Date(),
	);
	const [selectedEndTime, setSelectedEndTime] = React.useState<Date>(
		route.params.ExpiredTime ? new Date(route.params.ExpiredTime) : newdate,
	);

	const [responseError, setResponseError] = React.useState('');
	const [startTimeError, setStartTimeError] = React.useState('');
	const [sourceFileName, setSourceFileName] = React.useState('');
	const [previewFileName, setPreviewFileName] = React.useState('');
	const [endTimeError, setEndTimeError] = React.useState('');
	const [hover, setHover] = React.useState(false);
	const [buttonHover, setButtonHover] = React.useState(false);
	const [endRepeatTimeError, setEndRepeatTimeError] = React.useState('');
	const [showAnnouncements, setShowAnnouncements] = React.useState(0);
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.permissions === null ? true : false;
	const [selectedSitesForPromotion, setSelectedSitesForPromotion] = React.useState<any[]>([]);
	const [userClients, setUserClients] = React.useState<any[]>([]);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const blob = React.useContext(stores.context.blob);
	const [sourceFileType, setSourceFileType] = React.useState<any>(null);

	React.useEffect(() => {
		site.fetchSiteAndClientEntities();
		setUserClients(user.allSites);
	}, [route]);
	React.useEffect(
		() =>
			autorun(() => {
				if (user.permissions && user.permissions.Modules) {
					const feedIndex = user.permissions.Modules.findIndex(
						(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
					);
					if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
						if (
							user.permissions.Modules[feedIndex].Create ||
							user.permissions.Modules[feedIndex].Update ||
							user.permissions.Modules[feedIndex].Delete
						) {
							appStores.showAppLoader = false;
							setShowAnnouncements(1);
						}
					} else {
						setShowAnnouncements(0);
					}
				} else {
					setShowAnnouncements(0);
				}
			}),
		[user.permissions],
	);

	React.useEffect(() => {
		if (route.params.SiteId) {
			setCardTitle(
				route.params.status && route.params.status === 'Published'
					? 'Edit Published ' + props
					: route.params.status && route.params.status === 'Draft'
					? 'Edit ' + props
					: 'Edit Copy ' + props,
			);
			editValuesState.title = route.params.Title;
			editValuesState.subtitle = route.params.Subtitle;
			editValuesState.description = route.params.Description;
			editValuesState.StartTime = route.params.StartTime;
			editValuesState.ExpiredTime = route.params.ExpiredTime;
			editValuesState.RepeatFrequency = route.params.RepeatFrequency;
			editValuesState.MediaType = route.params.MediaType;
			editValuesState.MediaPreviewUrl = route.params.MediaPreviewUrl;
			editValuesState.MediaUrl = route.params.MediaUrl;
			editValuesState.buttonMode = true;
			setValues(editValuesState);
			setSourceFileName(route.params.MediaFileName);
			setPreviewFileName(route.params.PreviewFileName);
		} else {
			editValuesState.buttonMode = false;
		}
		if (editValuesState.MediaType === 2) {
			setSourceFileType('Video');
		} else {
			setSourceFileType('Image');
		}
	}, []);

	const validateSchema = (date: Date, name: string, message: string, dateValid: Date) => {
		let schema = yup.date().test(name, message, (value: Date) => {
			return compareAsc(value, dateValid) <= 0;
		});
		return schema;
	};

	const handleUpload = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'source') clearError('MediaUrl');
		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		const mediaExt = MediaType === 0 ? imageExt : videoExt;
		if (uploadedFile && (mediaExt || imageExt.includes(uploadedFile.type) || videoExt.includes(uploadedFile.type))) {
			const fileReader: FileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			fileReader.onload = async (event: any) => {
				if (uploadedFile.size >= 5000000) {
					setDialogOpen(true);
				}
				const MediaFileType = imageExt.includes(uploadedFile.type)
					? 'Image'
					: videoExt.includes(uploadedFile.type)
					? 'Video'
					: 'Image';
				setSourceFileType(MediaFileType);
				const MediaType = MediaFileType === 'Image' ? 1 : MediaFileType === 'Video' ? 2 : 0;
				const isValidExtension = isValidFileType(uploadedFile.type, inverseObject(mediaTypes)[MediaType]);
				if (!isValidExtension) {
					setError(
						type === 'source' ? 'MediaUrl' : 'MediaPreviewUrl',
						'invalid',
						'Please select jpg, png, jpeg or mp4, 3gp, 3gpp, m4v, webm, mpeg types',
					);
				} else {
					const fileResult = event.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
					const token = await blob.blobToken();
					let postData = {
						Token: token.uploadToken,
						Container: ContainerID.Feed,
						Data: fileResult,
						ContentType: uploadedFile.type,
						Extension: uploadedFile.type.split('/').pop(),
					};
					const response = await blob.blobImage(postData);

					if (type === 'source') {
						if (MediaFileType === 'Video') {
							setValues({ ...values, MediaUrl: response.url, MediaPreviewUrl: response.url, MediaType: MediaType });
							setValue('MediaUrl', response.url);
							setSourceFileName(uploadedFile.name);
							setPreviewFileName(uploadedFile.name);
							setValue('MediaPreviewUrl', response.url);
						} else {
							setValues({ ...values, MediaUrl: response.url, MediaType: MediaType });
							setValue('MediaUrl', response.url);
							setSourceFileName(uploadedFile.name);
						}

						triggerValidation('MediaUrl');
					} else {
						setValues({ ...values, MediaPreviewUrl: response.url, MediaType: MediaType });
						setValue('MediaPreviewUrl', response.url);
						setPreviewFileName(uploadedFile.name);
						if (!response.url) triggerValidation('MediaPreviewUrl');
					}
					clearError();
				}
			};
		} else {
			const invalidMsg =
				MediaType === 0 ? imageExt.join(', ').replace(/image\//g, '') : videoExt.join(', ').replace(/video\//g, '');
			if (type === 'source') setError('MediaUrl', 'invalid', `Please select ${invalidMsg} types`);
			else setError('MediaPreviewUrl', 'invalid', `Please select ${invalidMsg} types`);
		}
	};

	function inverseObject(obj: any) {
		var retobj = {};
		for (var key in obj) {
			retobj[obj[key]] = key;
		}
		return retobj;
	}

	const handleStartTime = async (date: Date) => {
		setSelectedStartTime(date);
		setEndTimeError('');
		setEndRepeatTimeError('');
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		const schema = validateSchema(date, 'startTime', 'Start time should be less than end time', selectedEndTime);
		schema
			.validate(date)
			.then((response: string) => {
				setStartTimeError('');
				if (selectedRepeatUntil && Number(values.RepeatFrequency) !== 0) {
					validateSchema(date, 'repeatUntil', 'Start time should be less than Repeat time', selectedRepeatUntil)
						.validate(date)
						.then((response: string) => {
							setStartTimeError('');
						})
						.catch((err: any) => {
							setStartTimeError(err.message);
						});
				}
			})
			.catch((err: any) => {
				setStartTimeError(err.message);
			});
	};
	const handleEndTime = (date: Date) => {
		setSelectedEndTime(date);
		setStartTimeError('');
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		let schema = yup.date().test('startTime', 'End time should greater than start time', (value: Date) => {
			return compareAsc(value, selectedStartTime) >= 0;
		});
		schema
			.validate(date)
			.then((response: string) => {
				setEndTimeError('');
			})
			.catch((err: any) => {
				setEndTimeError(err.message);
			});
	};
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};

	const IsDraft = (event: any) => {
		clearError('MediaUrl');
		clearError('MediaPreviewUrl');
		clearError('MediaType');
		submitForm(event.currentTarget.value);
	};

	const handleRepeatValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};
	const handleRepeatUntil = (date: Date) => {
		setSelectedRepeatUntil(date);
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		if (date > selectedStartTime) {
			setStartTimeError('');
		}
		let schema = yup.date().test('startTime', "The 'Repeat Until' cannot be before the 'Start Date'", (value: Date) => {
			return compareAsc(value, selectedStartTime) >= 0;
		});
		schema
			.validate(date)
			.then(() => {
				setEndRepeatTimeError('');
			})
			.catch((err: any) => {
				setEndRepeatTimeError(err.message);
			});
	};

	const submitForm = async (event: React.SyntheticEvent) => {
		const isValidForm = await triggerValidation();
		localStorage.removeItem('CMValue');
		if (props === '') {
			if (!isValidForm || !formState.isValid) return;
		} else {
			if (!isValidForm) return;
		}

		startTimeError == '' ? setBookingLoader(true) : setBookingLoader(false);

		const { title, subtitle, description, MediaType, RepeatFrequency, MediaUrl, MediaPreviewUrl, token } = values;
		const form = { Category: 0, Parameters: {}, UploadToken: '', Id: 0 };
		form.Category = props === 'Announcement' ? Category.Announcement : Category.Promotion;
		form.Id = route.params.Id;
		let startDate = moment(selectedStartTime, 'YYYY-MM-DDTHH:mm:ss').format();
		let endDate = new Date(selectedEndTime).toISOString();

		const siteIdsList: any[] = [];
		selectedSitesForPromotion.forEach(item => {
			siteIdsList.push(item.SiteId);
		});

		let repeatDate = RepeatFrequency !== 0 ? selectedRepeatUntil.toISOString() : new Date(endDate).toISOString();
		if (props === 'Add Promotion' || props === 'Promotion') {
			form.UploadToken = token;
			form.Category = Category.Promotion;
			form.Parameters = {
				SiteIds: siteIdsList,
				SiteId: null,
				PromotionName: title,
				ShortDescription: subtitle,
				Description: description,
				StartTime: startDate,
				EndTime: endDate,
				RepeatFrequency: RepeatFrequency,
				RepeatUntil: repeatDate,
				MediaType: MediaType,
				MediaUrl: MediaUrl,
				ModifiedTime: startDate,
				MediaPreviewUrl: MediaPreviewUrl,
				IsDraft: Number(event),
				MediaFileName: sourceFileName,
				PreviewFileName: previewFileName,
			};
		} else {
			form.UploadToken = token;
			form.Category = Category.Announcement;
			form.Parameters = {
				SiteIds: siteIdsList,
				SiteId: null,
				CardTitle: title,
				CardSubtitle: subtitle,
				CardDescription: description,
				StartTime: startDate,
				EndTime: endDate,
				RepeatFrequency: RepeatFrequency,
				RepeatUntil: repeatDate,
				MediaType: MediaType,
				MediaUrl: MediaUrl,
				MediaPreviewUrl: MediaPreviewUrl,
				IsDraft: Number(event),
				MediaFileName: sourceFileName,
				PreviewFileName: previewFileName,
			};
		}

		content.saveContentManagementValues(form.Id ? form : [form], form.Id ? route.params.Id : '').then(response => {
			setResponseError(response.Errors);
			if (form.Id) {
				if (response.Success === true && response.Error === null) {
					setBookingLoader(false);
					notifications.addNotification(Number(event) === 1 ? `${props} saved` : `${props} published`, 'success');
					if (props === 'Announcement') {
						window.location.href = '/contentManagement/AnnouncementList';
					} else {
						window.location.href = '/contentManagement/PromotionList';
					}
				} else {
					setBookingLoader(false);
					notifications.addNotification('Something went wrong , Please try again', 'error');
				}
			} else {
				if (response.Success === true && response.Errors === null) {
					setBookingLoader(false);
					notifications.addNotification(Number(event) === 1 ? `${props} saved` : `${props} published`, 'success');
					if (props === 'Announcement') {
						window.location.href = '/contentManagement/AnnouncementList';
					} else {
						window.location.href = '/contentManagement/PromotionList';
					}
				} else {
					if (response.Errors.length > 0 && response.Errors !== null) {
						setBookingLoader(false);
						notifications.addNotification('Something went wrong , Please try again', 'error');
					}
				}
			}
		});
	};
	const toggleButtonState = () => {
		if (
			methods.errors.title?.message ||
			methods.errors.subtitle?.message ||
			methods.errors.description?.message ||
			values.title === '' ||
			values.subtitle === '' ||
			values.description === '' ||
			values.MediaUrl === '' ||
			(sourceFileType === 'Image' ? values.MediaPreviewUrl === '' : '') ||
			sourceFileName === '' ||
			previewFileName === ''
		) {
			return true;
		}
		return false;
	};
	const handleClose = () => {
		setDialogOpen(false);
	};

	return useObserver(() => (
		<>
			<Backdrop className={classes.backdrop} open={bookingLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container className={classes.container}>
				<main className={classes.content}>
					{showAnnouncements === 1 && appStores.showAppLoader === false ? (
						<Grid container alignItems="center">
							<Grid className={classes.gridItem} item md={5}>
								<div>
									<Typography className={classes.text} variant="body1">
										{cardTitle}
									</Typography>
								</div>
								<form style={{ width: '1050px', marginLeft: '-11px' }}>
									<Box style={{ marginLeft: '6px', marginTop: '13px' }}>
										<FormControl className={classes.formControlsites}>
											<ClientsAndSites
												clientsAndSitesData={userClients}
												setSelectedSites={setSelectedSitesForPromotion}
												status={route.params.status && route.params.status === 'Published' ? true : false}
											/>
										</FormControl>

										<FormControl className={classes.timeStart}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DateTimePicker
													name="startTime"
													//label="Starts at"
													label="Starts"
													margin="normal"
													format="dd/MM/yyyy HH:mm"
													ampm={false}
													fullWidth
													disablePast
													disabled={route.params.status && route.params.status === 'Published' ? true : false}
													value={selectedStartTime}
													onChange={handleStartTime}
													error={Boolean(startTimeError)}
													helperText={startTimeError}
													inputRef={ref}
													InputProps={{
														className: classes.dateStyle,
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<AccessTimeIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													InputLabelProps={{ className: classes.InputLabel }}
												/>
											</MuiPickersUtilsProvider>
										</FormControl>

										<FormControl className={classes.timeEnd}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DateTimePicker
													name="endTime"
													fullWidth
													label="Expires"
													margin="normal"
													disablePast
													format="dd/MM/yyyy HH:mm"
													ampm={false}
													value={selectedEndTime}
													onChange={handleEndTime}
													InputProps={{
														className: classes.dateStyle,
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<AccessTimeIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													InputLabelProps={{ className: classes.InputLabel }}
													error={Boolean(endTimeError)}
													helperText={endTimeError}
												/>
											</MuiPickersUtilsProvider>
										</FormControl>
									</Box>
									<Box>
										<FormControl>
											<Typography className={classes.textTitle} variant="body1">
												Title
											</Typography>
											<TextField
												autoComplete="off"
												style={{
													width: '1014px',
													height: '36px',
													borderRadius: '8px',
													background: '#FFFFFF',
													marginLeft: '7px',
												}}
												type="text"
												name="title"
												fullWidth
												id="outlined-basic"
												value={values.title}
												variant="outlined"
												margin="normal"
												placeholder="Write your message here..."
												onChange={handleInputChange}
												InputLabelProps={{
													shrink: true,
												}}
												helperText={errors.title ? errors.title.message : ''}
												error={errors.title ? true : false}
												FormHelperTextProps={{ className: classes.errorInputText }}
												className={classes.InputField}
											/>
										</FormControl>
									</Box>
									<Box>
										<FormControl>
											<Typography className={classes.textSubtitle} variant="body1">
												Subtitle
											</Typography>
											<TextField
												autoComplete="off"
												style={{
													width: '1014px',
													height: '36px',
													borderRadius: '8px',
													background: '#FFFFFF',
													marginLeft: '7px',
												}}
												type="text"
												name="subtitle"
												fullWidth
												id="outlined-basic"
												value={values.subtitle}
												variant="outlined"
												margin="normal"
												placeholder="Write your message here..."
												onChange={handleInputChange}
												InputLabelProps={{
													shrink: true,
												}}
												helperText={errors.subtitle ? errors.subtitle.message : ''}
												error={errors.subtitle ? true : false}
												FormHelperTextProps={{ className: classes.errorInputText }}
												className={classes.InputField}
											/>
										</FormControl>
									</Box>
									<Box>
										<Typography className={classes.textContent} variant="body1">
											Content
										</Typography>
										<ReachTextEditor
											values={values}
											defaultValue={values.description}
											field={config.fields[2]}
											setValues={setValues}
										/>
									</Box>
									<Box style={{ marginTop: '-25px', height: '70px', width: '110%' }}>
										<div style={{ display: 'flex' }}>
											<FormControl variant="filled" fullWidth className={classes.formControl}>
												<InputLabel
													className={values.RepeatFrequency === 0 || values.RepeatFrequency ? classes.noLabel : ''}
												>
													Repeats
												</InputLabel>
												<Select
													name="RepeatFrequency"
													variant="outlined"
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													className={classes.formSelect}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: 'bottom',
															horizontal: 'left',
														},
													}}
													onChange={handleRepeatValue}
													disableUnderline={true}
													value={values.RepeatFrequency}
												>
													{Object.keys(repeat).map(key => (
														<MenuItem key={key} value={repeat[key]}>
															{key}
														</MenuItem>
													))}
												</Select>
											</FormControl>

											<div className={classes.repeatUntil}>
												{Object.keys(repeat)[values.RepeatFrequency] !== '' &&
												Object.keys(repeat)[values.RepeatFrequency] !== 'Repeats' ? (
													<DatePicker
														properties={methods}
														onRepeatUntilChange={handleRepeatUntil}
														selectedRepeatUntil={selectedRepeatUntil}
														endRepeatTimeError={endRepeatTimeError}
													/>
												) : (
													''
												)}
											</div>
										</div>
										<div style={{ display: 'flex', marginTop: '-50px', marginLeft: '460px', position: 'static' }}>
											<div
												style={{ marginRight: '5px', marginLeft: '-70px', marginTop: '1px' }}
												onMouseEnter={() => {
													setHover(true);
												}}
												onMouseLeave={() => {
													setHover(false);
												}}
											>
												<input
													accept="video/*,image/*"
													name="sourceFile"
													id="sourceFile"
													type="file"
													className={classes.uploader}
													onChange={event => handleUpload(event, 'source')}
												/>

												<label htmlFor="sourceFile" className={classnames(classes.fileUploadInput)}>
													<Icon
														icon={hover ? PlusSolid : PlusLight}
														style={{
															height: '32px',
															width: '32px',
															color: '#66CCFA',
															marginTop: '5px',
															marginLeft: '-8px',
														}}
													/>
													Attach Media
												</label>
												{sourceFileName ? (
													<FormHelperText className={classes.formHelp}>{sourceFileName}</FormHelperText>
												) : null}
												{errors.MediaUrl ? (
													<FormHelperText className={classes.formHelp} error>
														{errors.MediaUrl.message}
													</FormHelperText>
												) : null}
											</div>

											<div
												style={{ marginRight: '15px', marginTop: '1px' }}
												onMouseEnter={() => {
													setButtonHover(true);
												}}
												onMouseLeave={() => {
													setButtonHover(false);
												}}
											>
												<input
													accept="image/*"
													type="file"
													name="previewFile"
													id="previewFile"
													className={classes.uploader}
													onChange={event => handleUpload(event, 'preview')}
													disabled={sourceFileType === 'Image' ? false : true}
												/>
												<label htmlFor="previewFile" className={classnames(classes.fileUploadInput)}>
													<Icon
														icon={buttonHover ? PlusSolid : PlusLight}
														style={{
															height: '32px',
															width: '32px',
															color: '#66CCFA',
															marginTop: '5px',
															marginLeft: '-8px',
														}}
													/>
													Attach Preview
												</label>
												{previewFileName ? (
													<FormHelperText className={classes.formHelp}>{previewFileName}</FormHelperText>
												) : null}
												{errors.MediaPreviewUrl ? (
													<FormHelperText className={classes.formHelp} error>
														{errors.MediaPreviewUrl.message}
													</FormHelperText>
												) : null}
											</div>

											<Button
												className={classes.cancelbtn}
												variant="outlined"
												size="medium"
												color="primary"
												onClick={() => {
													localStorage.removeItem('CMValue');
													props === 'Announcement'
														? routes.navigate(
																{ name: 'announcements' },
																{
																	clearParams: true,
																},
														  )
														: routes.navigate(
																{ name: 'promotion' },
																{
																	clearParams: true,
																},
														  );
												}}
											>
												Cancel
											</Button>

											<Button
												className={classes.Draftbtn}
												variant="contained"
												size="medium"
												color="primary"
												value="1"
												disabled={toggleButtonState()}
												onClick={IsDraft}
											>
												Save Draft
											</Button>
											<Button
												className={classes.Publishbtn}
												variant="contained"
												size="medium"
												color="primary"
												value="0"
												disabled={toggleButtonState()}
												onClick={IsDraft}
												name="0"
											>
												Publish
											</Button>
										</div>
									</Box>
								</form>
								{responseError && responseError !== null ? (
									<FormHelperText error={Boolean(responseError)}>{responseError}</FormHelperText>
								) : (
									''
								)}
							</Grid>
						</Grid>
					) : showAnnouncements === 0 && appStores.showAppLoader === false ? (
						<>
							<img src={accessDeniedIcon} className={classes.accessDimensionsForm} alt="" />
							<div className={classes.textMargin}>
								<Typography variant="subtitle2">You do not have permission to access this area</Typography>
								<Typography variant="caption">Please contact your manager to request this access</Typography>
							</div>
						</>
					) : (
						''
					)}
					<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={dialogOpen}>
						<DialogContent>
							<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
								Failed
							</DialogTitle>
							<DialogContentText>Cannot upload 'Media item' larger than 5MB.</DialogContentText>
							<DialogActions>
								<Button onClick={handleClose} color="primary">
									OK
								</Button>
							</DialogActions>
						</DialogContent>
					</Dialog>
				</main>
			</Container>
		</>
	));
});

export default ContentForm;
