import React from 'react';
import { InputLabel, makeStyles, FormHelperText, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Icon } from '@mitie/material-ui-extensions';
import { faImage, faFileUpload } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { FormContextValues } from 'react-hook-form';
import { ISiteInfoForm, IFileUpload, IAboutForm, IMenuItem } from 'types';
import { getAllowedFileTypes } from '../util';

interface IProps {
	methods: FormContextValues<ISiteInfoForm | IAboutForm | IMenuItem>;
	setFile: React.SetStateAction<any>;
	file: IFileUpload;
	fileInputName: string;
	fileInputLabel: string;
	fileInputErrorMessage?: string;
	fileInputFormatType?: string;
	fileInputAccept?: string;
	tooltip?: string;
}

const FileUpload = ({
	methods,
	setFile,
	file,
	fileInputName,
	fileInputLabel,
	fileInputErrorMessage,
	fileInputFormatType,
	fileInputAccept,
	tooltip,
}: IProps) => {
	const classes = useStyles();
	const { register, errors, setValue, setError, clearError, triggerValidation } = methods;

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		clearError(fileInputName);
		setValue(fileInputName, '');
		setFile({
			...file,
			name: '',
			error: true,
		});

		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		const extensionType = fileInputFormatType ? getAllowedFileTypes(fileInputFormatType) : getAllowedFileTypes('Image');
		if (uploadedFile && extensionType.includes(uploadedFile.type)) {
			const fileReader: FileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			fileReader.onload = async (event: any) => {
				const object = event.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
				setFile({
					...file,
					name: uploadedFile.name,
					format: uploadedFile.type,
					extension: uploadedFile.type.split('/').pop()!,
					object,
					error: false,
				});
			};
			setValue(fileInputName, uploadedFile.name);
			clearError(fileInputName);
			triggerValidation(fileInputName);
		} else {
			setFile({
				...file,
				name: uploadedFile && uploadedFile.name,
				error: true,
			});
			setError(fileInputName, 'invalid', fileInputErrorMessage ? fileInputErrorMessage : 'Invalid file');
		}
	};

	React.useEffect(() => {
		register({ name: fileInputName });
	}, [register, fileInputName]);

	return (
		<React.Fragment>
			<div
				className={classnames(
					classes.fileUploadInputContainer,
					Boolean(errors[fileInputName]) ? classes.fileUploadInputContainerError : '',
				)}
			>
				<InputLabel htmlFor={fileInputName} className={classes.fileLabel}>
					{file.name ? file.name : fileInputLabel}
				</InputLabel>
				<input
					accept={fileInputAccept ? fileInputAccept : 'image/*'}
					id={fileInputName}
					onChange={event => handleFileUpload(event)}
					type="file"
					className={classes.fileUpload}
				/>
				<label htmlFor={fileInputName}>
					<Icon icon={fileInputFormatType === 'PDF' ? faFileUpload : faImage} className={classes.fileUploadIcon} />
				</label>

				{tooltip && (
					<Tooltip title={<div className={classes.tooltip}>{tooltip}</div>} placement="bottom">
						<HelpIcon className={classes.helpIcon} />
					</Tooltip>
				)}
			</div>
			{Boolean(errors[fileInputName]) && (
				<FormHelperText error={Boolean(errors[fileInputName])} className={classes.fileUploadInputTextError}>
					{errors[fileInputName].message ? errors[fileInputName].message : 'Error in uploaded file'}
				</FormHelperText>
			)}
		</React.Fragment>
	);
};

export default FileUpload;

const useStyles = makeStyles(theme => ({
	fileUpload: {
		display: 'none',
	},
	fileLabel: {
		padding: theme.spacing(0, 0, 0, 2),
		fontSize: theme.typography.pxToRem(16),
		width: '100%',
	},
	fileUploadInputContainer: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
		margin: theme.spacing(2, 0, 1, 0),
		backgroundColor: `${theme.palette.primary.main}0A`,
		height: '48px',
	},
	fileUploadInputContainerError: {
		borderBottom: `${theme.palette.error.main} 2px solid`,
		color: theme.palette.error.main,
		'& > label': {
			color: theme.palette.error.main,
		},
	},
	fileUploadInputTextError: {
		margin: theme.spacing(1, 1.75, 0),
	},
	fileUploadIcon: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: theme.spacing(0, 2, 0, 0),
	},
	tooltip: {
		width: 120,
		padding: 1,
		textAlign: 'center',
	},
	helpIcon: {
		color: theme.palette.grey[50],
		fontSize: '1.3em',
		marginRight: '-24px',
	},
}));
