import * as React from 'react';
import { makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import { IframeComponent2 } from './IframeComponent2';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import RecentlyOpenedJobs from '../components/Ask/RecentlyOpenedJobs';

const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
		marginBottom: 0,
	},
	paper: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		boxShadow: 'none !important',
	},
	jobsDisplay: {
		'@media (min-width:0px) and (max-width:1830px)': {
			display: 'none',
		},
	},
	actionIconButton: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',

		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	title: {
		padding: theme.spacing(2.1),
		paddingLeft: '6.2px',
		marginTop: '3px',
		paddingBottom: '9px',
	},
	esmeCloseIcon: {
		width: '35px',
		height: '35px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	textMargin: {
		textAlign: 'center',
	},

	accessDimensions: {
		width: '60%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	closeIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
}));

const DynamicForm: React.FunctionComponent = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const esmeUrl = process.env.REACT_APP_ESME_URL;
	return (
		<>
			<div className={classes.frameContainer}>
				{user.esmesite.map((ss: any) =>
					ss.Id === user.currentSiteId && ss.EsmeClientId !== null ? (
						<Grid container spacing={1} key={ss.EsmeClientId} style={{ width: '100%', height: '100%' }}>
							{user.CafmId === 1 ? (
								<Grid
									item
									className={classes.jobsDisplay}
									style={{ height: '850px', width: '512px', marginLeft: '30px' }}
								>
									<RecentlyOpenedJobs />
								</Grid>
							) : (
								''
							)}
							<Grid item style={{ width: '992px', paddingLeft: '60px' }}>
								<Paper className={classes.paper}>
									<Grid container>
										<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
											<Typography
												variant="h5"
												style={{
													textAlign: 'left',
													font: 'normal normal normal 18px/12px Roboto',
													color: '#002855',
													marginLeft: '8px',
													lineHeight: 'normal',
													width: '147px',
													height: '22px',
												}}
											>
												Job Request Form
											</Typography>
										</Grid>
									</Grid>
									<Grid container style={{ height: '100%' }}>
										<Grid item xs={12}>
											<IframeComponent2
												iframeSrc={esmeUrl + 'dialogueForm?subChannel=dy02&dform=x'}
												height="100%"
												width="100%"
												token={user.token}
												clientId={ss.EsmeClientId}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					) : ss.Id === user.currentSiteId && ss.EsmeClientId === null ? (
						<>
							<div className={classes.textMargin}>
								<Typography variant="subtitle2">Chatbot is not available for this site.</Typography>
							</div>
						</>
					) : (
						''
					),
				)}
			</div>
		</>
	);
});

export default DynamicForm;
