import * as React from 'react';
import {
	makeStyles,
	Grid,
	Dialog,
	Card,
	CardContent,
	Typography,
	CardActionArea,
	CardMedia,
	Box,
	Slider,
	Button,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import Rating from '@material-ui/lab/Rating';
import { stores } from '../store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';
import PermanentAppBar from '../components/Header/PermanentAppBar';
import FeedWireFrame from '../components/Feed/FeedWireframe';
import StatusFeeds from '../components/Feed/StatusFeeds';
import { IFeedResultItems, IQuestion } from '../types';
import { faFrown, faSmile } from '@fortawesome/pro-light-svg-icons';
import { displayLocalTime, isExpired, displayDateTime } from '../util';
import likeselected from '../assets/icons/favouriteselected.svg';
import ShareIcon from '../assets/icons/Share';
import LikeIcon from '../assets/icons/Like';
const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
		marginBottom: '0px',
	},
	paper: {
		display: 'flex',
		height: '100%',
		overflow: 'auto',
		flexDirection: 'column',
	},
	actionIconButton: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	openCardDialog: {
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 'none !important',
			borderRadius: '8px',
			boxShadow: '0px 5px 8px #00000063',
			width: '35%',
			height: '70%',
		},
		boxShadow: '0px 5px 8px #00000063',
	},
	esmeCloseIcon: {
		width: '35px',
		height: '35px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	title: {
		padding: '14px',
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	textMargin: {
		textAlign: 'center',
	},

	accessDimensions: {
		width: '60%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	closeIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		height: '100%',
		'. &infinite-scroll-component__outerdiv': {
			height: '100% !impotant',
		},
	},
	gridWidth: {
		width: 'calc(100% + 12px) !important',
	},
	gridContainer: {
		height: '100%',
		paddingLeft: '15px',
		paddingTop: '2px',
	},
	smileyIcon: {
		fontSize: '2rem',
	},
	staticCard: {
		borderRadius: '8px',
		height: '280px',
		boxShadow: '0px 3px 6px #00000029 !important',
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	},
	Card: {
		borderRadius: '8px',
		width: '460px',
		height: '280px',
		boxShadow: '0px 3px 6px #00000029',
		marginLeft: '12px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2),
		},
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	},
	statusCardView: {
		height: '100% !important',
		paddingBottom: '0px !important',
		'@media (min-width: 1280px)': {
			maxWidth: '507px',
			flexBasis: '507px',
		},
		'@media (min-width: 250px) and (max-width: 1279px)': {
			maxWidth: '500px',
			flexBasis: '500px',
		},
	},
	staticCardView: {
		maxWidth: '1452px',
		marginLeft: '22px',
		marginBottom: '16px',
		marginTop: '2px',
		'@media (min-width: 1281px) and (max-width: 1786px)': {
			maxWidth: '956px',
			flexBasis: '956p',
		},
		'@media (min-width: 250px) and (max-width: 1280px)': {
			maxWidth: '460px',
			flexBasis: '460px',
		},
	},
	mainCardView: {
		height: '100% !important',
		paddingBottom: '0px !important',
		'@media (min-width: 1280px)': {
			maxWidth: '1002px',
			flexBasis: '1002px',
		},
		'@media (min-width: 1250px) and (max-width: 1786px)': {
			maxWidth: '505px',
			flexBasis: '505px',
		},
		'@media (min-width: 600px) and (max-width: 1279px)': {
			maxWidth: '496px',
			flexBasis: '496px',
		},
		'@media (min-width: 0px) and (max-width: 599px)': {
			maxWidth: '496px',
			flexBasis: '496px',
		},
	},
	cardView: {
		paddingLeft: '3px !important',
		marginBottom: '2px',
		'@media (min-width: 1250px) and (max-width: 1786px)': {
			maxWidth: '496px',
			flexBasis: '496px',
		},
		'@media (min-width: 600px) and (max-width: 1249px)': {
			maxWidth: '496px',
			flexBasis: '496px',
		},
		'@media (min-width: 0px) and (max-width: 599px)': {
			maxWidth: '496px',
			flexBasis: '496px',
		},
	},
	wireframeCard: {
		boxShadow: '0px 3px 6px #00000029',
		width: '460px',
		height: '280px',
		borderRadius: '8px',
	},
	cardMedia: {
		width: '460px',
		height: '280px',
		objectFit: 'fill',
		borderRadius: '8px 8px 0px 0px',
	},
	timeView: {
		font: 'normal normal normal 14px/17px Roboto',
		color: '#002855',
	},
	OpenCardtimeView: {
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
	},
	actionArea: {
		'&:hover $focusHighlight': {
			opacity: 0,
		},
	},
	titleView: {
		height: '1.5rem',
		color: '#002855',
		width: '20rem',
		font: 'normal normal normal 16px/19px Roboto',
		whiteSpace: 'nowrap',
		overflow: 'hidden !important',
		textOverflow: 'ellipsis',
		marginTop: '-4px',
	},
	openCardTitle: {
		color: '#002855',
		font: 'normal normal normal 20px/24px Roboto',
		width: '81%',
		overflowWrap: 'break-word',
	},
	openCardDescr: {
		color: '#002855',
		font: 'normal normal normal 14px/17px Roboto',
		lineHeight: '1.4',
		overflowWrap: 'break-word',
	},
	subTitleView: {
		width: '20rem',
		overflow: 'hidden',
		font: 'normal normal normal 14px/17px Roboto',
		color: '#8B8DAE',
	},
	focusHighlight: {},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
	},
	openCard: {},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 354px)',
		display: 'flex',
	},
	'@global': {
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: '#66CCFA',
		},
	},
	ActionButtons: {
		backgroundColor: '#66ccfa !important',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#56b1df !important',
			color: '#FFFFFF',
		},
	},
	ActionButtons2: {
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		'&:hover': {
			backgroundColor: '#66ccfa',
			color: '#FFFFFF',
			border: '1px solid #66CCFA',
		},
	},
	scrollerDiv: {
		'& .infinite-scroll-component__outerdiv': {
			height: '100% !impotant',
		},
	},
	disableStatusFeed: {
		display: 'none',
	},
	slider: {
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	actions: {
		justifyContent: 'flex-end',
	},
	actions2: {
		justifyContent: 'flex-end',
		'& .MuiButtonBase-root': {
			color: '#66CCFA !important',
			font: 'normal normal normal 14px/17px Roboto',
			'&:hover': {
				background: 'none',
			},
		},
	},
	feedUIStyle: {
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
			'&:hover': {
				color: '#14A0D6',
			},
		},
	},
	shareIconStyle: {
		zIndex: 999,
		position: 'absolute',
		textAlign: 'start',
		top: '16px',
		left: '16px',
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
		},
	},
	likeIconStyle: {
		position: 'absolute',
		zIndex: 999,
		textAlign: 'end',
		cursor: 'pointer',
		top: '16px',
		'& .fa-heart': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
			'&:hover': {
				color: '#14A0D6',
			},
		},
		right: '16px',
	},
	likedIconStyle: {
		position: 'absolute',
		zIndex: 999,
		cursor: 'pointer',
		textAlign: 'end',
		top: '16px',
		right: '19px',
		height: '23px',
		width: '21px',
	},
	ratingStyle: {
		'& .MuiRating-iconFilled': {
			color: '#2195f1',
		},
		'& .MuiRating-iconHover': {
			color: '#2195f1',
		},
	},
}));
interface IQuestionResponse {
	QuestionNumber: number;
	UserResponse: number;
}
const Feed: React.FunctionComponent = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const routes: any = React.useContext(stores.context.routes);
	const { route } = React.useContext(stores.context.routes);
	const feed = React.useContext(stores.context.feed);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [mainFeedData, setMainFeedData] = React.useState<IFeedResultItems[]>([]);
	const [hasMoreMainFeed, setHasMoreMainFeed] = React.useState(true);
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	const [feedDialogOpen, setFeedDialogOpen] = React.useState(false);
	const [rating, setRating] = React.useState<IQuestionResponse[] | null>(null);
	const [ratValid, setRatingValid] = React.useState<any>('');
	const [disabled, setDisabled] = React.useState<any>();
	const [questionType, setQuestionType] = React.useState<any>();
	const site = React.useContext(stores.context.site);
	const [expired, setExpired] = React.useState<any>();
	const [feedShareDialogOpen, setFeedShareDialogOpen] = React.useState(false);
	const [shareCardData, setShareCardData] = React.useState<any>({});
	const [opencardData, setOpenCardData] = React.useState<any>({});
	const feedHandleClickClose = () => {
		setFeedDialogOpen(false);
	};
	const feedShareHandleClickClose = () => {
		setFeedShareDialogOpen(false);
		routes.navigate({ name: 'feed' }, { clearParams: true });
	};
	const archiveFeed = () => {
		setTimeout(() => {
			fetchMainFeeds(1);
		}, 3000);
	};
	window.addEventListener(
		'message',
		event => {
			if (event.data === 'firstmessage') {
				routes.testval = true;
			} else {
				routes.testval = false;
			}
		},
		false,
	);
	React.useEffect(() => {
		if (route.params.id !== undefined) {
			feed.getsharedFeed(route.params.id).then((resp: any) => {
				setFeedShareDialogOpen(true);
				if (resp && (resp.statusCode === 200 || resp.statusCode === undefined)) {
					setShareCardData({
						Title: resp.Title,
						Description: resp.Description,
						StartTime: resp.StartTime,
						MediaUrl: resp.Parameters.MediaUrl,
						MediaType: resp.Parameters.MediaType,
						StatusCode: 200,
					});
				} else {
					setShareCardData({ Message: resp.body.Message, StatusCode: resp.statusCode });
				}
			});
		}
	}, []);
	React.useEffect(() => {
		const { responses = null } = opencardData.UserState || { responses: null };
		if (opencardData && opencardData.Category === 7) {
			if (responses) {
				setRating(responses);
			} else {
				setRating(
					opencardData &&
						opencardData.Parameters &&
						opencardData.Parameters.Questions.map((question: IQuestion) => ({
							QuestionNumber: question.QuestionNumber,
							UserResponse: 0,
						})),
				);
			}
		}
	}, [opencardData]);
	const fetchMoreMainFeeds = () => {
		if (mainFeedData.length >= feed.mainFeedItems && feed.mainFeedItems.Pagination.TotalFeedCount) {
			setHasMoreMainFeed(false);
			return;
		} else if (pageNumber === -1) {
			setHasMoreMainFeed(false);
			return;
		} else {
			feed.getMainFeeds(pageNumber).then((resp: any) => {
				if (resp && resp.Result) {
					setMainFeedData([...mainFeedData, ...resp.Result]);
					setPageNumber(resp && resp.Pagination.NextPageNumber);
				}
			});
		}
	};
	React.useEffect(() => {
		fetchMainFeeds(1);
		appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	}, []);
	const fetchFeedURL = (event: any, feedid: number) => {
		event.stopPropagation();
		feed.getSharedURL(feedid).then((resp: any) => {
			if (resp && resp.url) {
				window.location.href = `mailto:?subject=I would like to share this feed on Aria!&body=Here's the link - ${resp.url}`;
			}
		});
	};
	const fetchMainFeeds = (pageNumber: Number) => {
		feed.getMainFeeds(pageNumber).then((resp: any) => {
			if (resp && resp.Result) {
				setPageNumber(resp && resp.Pagination.NextPageNumber);
				setMainFeedData(resp.Result);
				if (resp && resp.Pagination.TotalFeedCount <= 6) {
					setHasMoreMainFeed(false);
				}
			}
		});
	};
	const handleOpenCard = (
		title: string,
		time: string,
		description: string,
		parameters: any,
		category: number,
		id: number,
		availableButtons: any,
		userstate: any,
		IsLiked: boolean,
	) => {
		console.log(ratValid);
		const surveryExpired = isExpired(parameters.EndDate);
		setExpired(surveryExpired);
		const questionType = feed.surveyTypes[parameters.SurveyTypeId];
		setQuestionType(questionType);
		const disabled = surveryExpired;
		setDisabled(disabled);
		setFeedDialogOpen(true);
		setOpenCardData({
			Title: title,
			Description: description,
			StartTime: time,
			Parameters: parameters,
			Category: category,
			Id: id,
			AvailableButtons: availableButtons,
			UserState: userstate,
			IsLiked: IsLiked,
		});
	};
	const handleLike = (event: any, feedId: number, liked: boolean) => {
		event.stopPropagation();
		const data = {
			feedId,
			liked,
		};
		feed.postFeedLike(data).then(res => {
			if (res) console.log('res', res);
			const newdata = mainFeedData.filter((el: IFeedResultItems) => {
				if (el.Id === feedId) {
					el.IsLiked = liked;
					return el;
				}
				return el;
			});
			setMainFeedData([...newdata]);
			if (opencardData.Id === feedId) {
				setOpenCardData({ ...opencardData, IsLiked: liked });
			}
		});
	};
	const feedWelcomImage = process.env.REACT_APP_FEED_WELCOME_IMAGE;
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div className={classes.container}>
					<div className={classes.frameContainer}>
						<Grid xl={12} className={classes.staticCardView}>
							<Card className={classes.staticCard}>
								<CardMedia
									component="img"
									height="140"
									image={feedWelcomImage}
									alt="Aria"
									style={{ height: '186px' }}
								/>
								<CardContent style={{ height: '80px', padding: '18px' }}>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }}>
												<Typography variant="subtitle2" className={classes.titleView}>
													Hello {user.usernewname && user.usernewname}
												</Typography>
											</Box>
										</Box>
										<Box
											style={{
												textAlign: 'start',
												marginTop: '6px',
												width: '25rem',
												height: '35px',
												overflow: 'hidden',
											}}
										>
											<Typography className={classes.subTitleView} variant="caption" color="textSecondary">
												{'Start exploring and discovering more with Aria today...'}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Grid>
						<Grid container spacing={2} className={classes.gridContainer}>
							<Grid item xs={12} lg={4} md={4} className={classes.statusCardView}>
								<StatusFeeds />
							</Grid>
							<Grid
								item
								xs={12}
								lg={8}
								md={8}
								className={classes.mainCardView}
								style={{ height: '100%', marginBottom: '2px', marginLeft: '-11px' }}
							>
								<div
									id="scrollableDivMain"
									className={classes.scrollerDiv}
									style={{ height: '100%', overflowY: 'scroll' }}
								>
									<InfiniteScroll
										dataLength={mainFeedData.length}
										next={fetchMoreMainFeeds}
										hasMore={hasMoreMainFeed}
										style={{ height: '100%', overflow: 'Hidden' }}
										loader={<FeedWireFrame feedType="mainfeed" />}
										hasChildren={feed.mainFeedItemsCount <= mainFeedData.length ? true : false}
										endMessage={<p></p>}
										scrollableTarget="scrollableDivMain"
									>
										<Grid
											container
											spacing={2}
											direction="row"
											justify="flex-start"
											alignItems="flex-start"
											className={classes.gridWidth}
										>
											{mainFeedData.map((elem: IFeedResultItems) => {
												return (
													<Grid
														item
														xs={12}
														lg={6}
														sm={12}
														md={12}
														className={classes.cardView}
														key={mainFeedData.indexOf(elem)}
													>
														{elem.Category === 1000 ? (
															<Card className={classes.wireframeCard}>
																<CardMedia
																	component="img"
																	height="140"
																	image={elem.Parameters.MediaUrl}
																	alt="green iguana"
																	className={classes.cardMedia}
																/>
															</Card>
														) : (
															<Card className={classes.Card}>
																<CardActionArea
																	classes={{
																		root: classes.actionArea,
																		focusHighlight: classes.focusHighlight,
																	}}
																	disableTouchRipple={true}
																	onClick={() => {
																		handleOpenCard(
																			elem.CollapsedTitle,
																			elem.StartTime,
																			elem.Description,
																			elem.Parameters,
																			elem.Category,
																			elem.Id,
																			elem.AvailableButtons,
																			elem.UserState,
																			elem.IsLiked,
																		);
																	}}
																>
																	{elem.Category == 1001 || elem.Category === 7 ? (
																		''
																	) : (
																		<>
																			<Box className={classes.feedUIStyle}>
																				<span onClick={event => fetchFeedURL(event, elem.Id)}>
																					<ShareIcon />
																				</span>
																			</Box>
																			<Box className={classes.feedUIStyle}>
																				<span
																					onClick={event => {
																						handleLike(event, elem.Id, !elem.IsLiked);
																					}}
																				>
																					{elem.IsLiked ? (
																						<img
																							src={likeselected}
																							className={classes.likedIconStyle}
																							alt=""
																							width="30px"
																						/>
																					) : (
																						<LikeIcon />
																					)}
																				</span>
																			</Box>
																		</>
																	)}
																	{elem.Parameters.MediaType === 2 ? (
																		<video
																			style={{
																				height: '186px',
																				width: '100%',
																				objectFit: 'cover',
																				borderRadius: '8px 8px 0px 0px',
																			}}
																		>
																			<source src={elem.Parameters.MediaUrl}></source>
																		</video>
																	) : (
																		<CardMedia
																			component="img"
																			height="140"
																			image={elem.Parameters.MediaPreviewUrl}
																			alt="Aria"
																			style={{ height: '186px', borderRadius: '8px 8px 0px 0px' }}
																		/>
																	)}
																	<CardContent style={{ height: '80px', padding: '18px' }}>
																		<Box display="flex" flexDirection="column" flexGrow={1}>
																			<Box style={{ height: '18px' }}>
																				<Box style={{ textAlign: 'start' }}>
																					<Typography variant="subtitle2" className={classes.titleView}>
																						{elem.CollapsedTitle}
																					</Typography>
																				</Box>
																				<Box style={{ textAlign: 'end', marginTop: '-27px' }}>
																					{elem.Category === 1001 ? (
																						<Typography className={classes.timeView}>{''}</Typography>
																					) : (
																						<Typography variant="subtitle1" className={classes.timeView}>
																							{displayLocalTime(elem.StartTime)}
																						</Typography>
																					)}
																				</Box>
																			</Box>
																			<Box
																				style={{
																					textAlign: 'start',
																					marginTop: '18px',
																					width: '25rem',
																					height: '35px',
																					overflow: 'hidden',
																				}}
																			>
																				<Typography
																					className={classes.subTitleView}
																					variant="caption"
																					color="textSecondary"
																				>
																					{elem.CollapsedDescription}
																				</Typography>
																			</Box>
																		</Box>
																	</CardContent>
																</CardActionArea>
															</Card>
														)}
													</Grid>
												);
											})}
										</Grid>
									</InfiniteScroll>
								</div>
							</Grid>
						</Grid>
					</div>
					<Dialog
						className={classes.openCardDialog}
						style={{ margin: 'auto', borderRadius: '8px' }}
						open={feedDialogOpen}
						onClose={feedHandleClickClose}
						scroll={'paper'}
					>
						{opencardData.Category === 7 ? (
							<>
								{opencardData.Parameters.MediaType === 1 ? (
									<img
										style={{ width: '100%', objectFit: 'cover', height: '50%' }}
										src={opencardData.Parameters.MediaUrl}
										alt="image"
									/>
								) : (
									<video autoPlay controls width="100%" style={{ height: '50%', objectFit: 'cover' }}>
										<source src={opencardData.Parameters.MediaUrl}></source>
									</video>
								)}
								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }} className={classes.openCardTitle}>
												<Typography variant="h6">{opencardData.Title}</Typography>
											</Box>
											<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
												<Typography variant="subtitle1" className={classes.OpenCardtimeView}>
													{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
												</Typography>
											</Box>
										</Box>
										<Box style={{ textAlign: 'start', marginTop: '8px' }}>
											<Typography className={classes.openCardDescr} variant="subtitle2">
												<div dangerouslySetInnerHTML={{ __html: opencardData.Description }} />
											</Typography>
										</Box>
										<Box display="flex" justifyContent="center" flexDirection="column" mt={2}>
											{questionType === 'Star Survey' ? (
												<Typography variant="subtitle2">On a scale of 0 to 5</Typography>
											) : (
												<Typography variant="subtitle2">On a scale of 0 to 10</Typography>
											)}
											{opencardData.Parameters.Questions &&
												opencardData.Parameters.Questions.map((question: IQuestion, qIdx: number) => (
													<Box key={Math.random()} mt={2}>
														<Typography variant="subtitle2" style={{ overflowWrap: 'break-word' }}>
															Q{qIdx + 1} of {opencardData.Parameters.Questions.length}: {question.QuestionDescription}
														</Typography>
														{questionType === 'Star Survey' ? (
															<Rating
																className={classes.ratingStyle}
																name={`simple-controlled${qIdx + 1}`}
																value={rating ? rating[qIdx].UserResponse : 0}
																readOnly={disabled}
																onChange={(event: any, newValue: number) => {
																	const currentRating = rating?.find(
																		q => Number(q.QuestionNumber) === Number(question.QuestionNumber),
																	);
																	newValue !== 0
																		? localStorage.setItem('CMValue', 'true')
																		: localStorage.setItem('CMValue', 'false');
																	localStorage.setItem('URL', window.location.pathname);

																	currentRating!.UserResponse = newValue;
																	setRatingValid(currentRating!.UserResponse);
																	setRating([...rating]);
																}}
															/>
														) : (
															<React.Fragment>
																<Box textAlign="center" mt={1}>
																	{rating && rating[qIdx] ? rating[qIdx].UserResponse : 0}
																</Box>
																<Box display="flex">
																	<Icon icon={faFrown} className={classes.smileyIcon} size={'2x'}></Icon>
																	<Slider
																		className={classes.slider}
																		disabled={disabled}
																		defaultValue={rating && rating[qIdx] ? rating[qIdx].UserResponse : 0}
																		onChange={(event: any, newValue: number) => {
																			newValue !== 0
																				? localStorage.setItem('CMValue', 'true')
																				: localStorage.setItem('CMValue', 'false');
																			localStorage.setItem('URL', window.location.pathname);
																			if (!rating || !rating[qIdx]) return;
																			const currentRating = rating.find(
																				q => Number(q.QuestionNumber) === Number(question.QuestionNumber),
																			);
																			currentRating!.UserResponse = newValue;
																			setRatingValid(currentRating!.UserResponse);
																			setRating([...rating]);
																		}}
																		track="inverted"
																		step={1}
																		min={0}
																		max={10}
																	/>
																	<Icon icon={faSmile} className={classes.smileyIcon} size={'2x'}></Icon>
																</Box>
															</React.Fragment>
														)}
													</Box>
												))}
											<Box display="block" my={2}></Box>
											<Typography variant="caption" display="block" color={expired ? 'error' : undefined}>
												Survey {expired ? 'closed on' : 'closes'} :{' '}
												{opencardData.Parameters.EndDate ? displayDateTime(opencardData.Parameters.EndDate) : null}
											</Typography>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions className={classes.actions}>
									{opencardData &&
										opencardData.AvailableButtons.map((button: string) => {
											const currentSite = site.siteList.find(s => s.Id === site.siteId);

											return (
												<Button
													size="medium"
													color="primary"
													className={button === 'Submit' ? classes.ActionButtons : classes.ActionButtons2}
													variant={button === 'Submit' ? 'contained' : 'outlined'}
													key={button}
													onClick={() => {
														if (button === 'Submit') {
															localStorage.removeItem('CMValue');
															if (ratValid == '') {
																archiveFeed();
															} else {
																feed.updateFeedStatus({
																	feedId: opencardData.Id,
																	ButtonName: button,
																	State: {
																		RemindMeInSeconds: null,
																		responses: rating,
																		SiteName: currentSite?.Name,
																		SiteId: opencardData.Parameters.SiteId[0],
																	},
																});
																archiveFeed();
															}
															feedHandleClickClose();
														} else {
															feedHandleClickClose();
															localStorage.removeItem('CMValue');
														}
													}}
												>
													{button}
												</Button>
											);
										})}
								</DialogActions>
							</>
						) : (
							<>
								{opencardData.Category === 1001 ? (
									''
								) : (
									<>
										<Box className={classes.feedUIStyle}>
											<span onClick={event => fetchFeedURL(event, opencardData.Id)}>
												<ShareIcon />
											</span>
										</Box>
										<Box className={classes.feedUIStyle}>
											<span
												onClick={event => {
													handleLike(event, opencardData.Id, !opencardData.IsLiked);
												}}
											>
												{opencardData.IsLiked ? (
													<img src={likeselected} className={classes.likedIconStyle} alt="" width="30px" />
												) : (
													<LikeIcon />
												)}
											</span>
										</Box>
									</>
								)}
								{opencardData.Parameters ? (
									opencardData.Parameters.MediaType === 1 ? (
										<img
											style={{ width: '100%', objectFit: 'cover', height: '50%' }}
											src={opencardData.Parameters.MediaUrl}
											alt="image"
										/>
									) : (
										<video autoPlay controls width="100%" style={{ height: '50%', objectFit: 'cover' }}>
											<source src={opencardData.Parameters.MediaUrl}></source>
										</video>
									)
								) : (
									''
								)}
								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }}>
												<Typography variant="h6" className={classes.openCardTitle}>
													{opencardData.Title}
												</Typography>
											</Box>
											<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
												{opencardData.Category === 1001 ? (
													<Typography className={classes.OpenCardtimeView}>{''}</Typography>
												) : (
													<Typography variant="subtitle1" className={classes.OpenCardtimeView}>
														{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
													</Typography>
												)}
											</Box>
										</Box>
										<Box style={{ textAlign: 'start', marginTop: '30px', width: '95%', marginLeft: '14px' }}>
											<Typography className={classes.openCardDescr} variant="subtitle2">
												<div dangerouslySetInnerHTML={{ __html: opencardData.Description }} />
											</Typography>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions className={classes.actions2}>
									{opencardData.Category === 1001 ? (
										<Button
											size="small"
											color="primary"
											onClick={() => {
												feedHandleClickClose();
											}}
										>
											Close
										</Button>
									) : (
										<Button
											size="small"
											color="primary"
											onClick={() => {
												feed.updateFeedStatus({
													feedId: opencardData.Id,
													ButtonName: 'Ok',
													State: null,
												});
												feedHandleClickClose();
												archiveFeed();
											}}
										>
											Archive Item
										</Button>
									)}
								</DialogActions>
							</>
						)}
					</Dialog>
					<Dialog
						className={shareCardData && shareCardData.StatusCode === 200 ? classes.openCardDialog : ''}
						style={{ margin: 'auto', borderRadius: '8px' }}
						open={feedShareDialogOpen}
						onClose={feedShareHandleClickClose}
						scroll={'paper'}
					>
						{shareCardData && shareCardData.StatusCode === 200 ? (
							<>
								{shareCardData.MediaType === 1 ? (
									<img
										style={{ width: '100%', objectFit: 'cover', height: '50%' }}
										src={shareCardData.MediaUrl}
										alt="image"
									/>
								) : (
									<video autoPlay controls width="100%" style={{ height: '50%', objectFit: 'cover' }}>
										<source src={shareCardData.MediaUrl}></source>
									</video>
								)}

								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }}>
												<Typography variant="h6" className={classes.openCardTitle}>
													{shareCardData.Title}
												</Typography>
											</Box>
											<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
												<Typography variant="subtitle1" className={classes.OpenCardtimeView}>
													{shareCardData.StartTime ? displayLocalTime(shareCardData.StartTime) : ''}
												</Typography>
											</Box>
										</Box>
										<Box style={{ textAlign: 'start', marginTop: '30px', width: '95%', marginLeft: '14px' }}>
											<Typography className={classes.openCardDescr} variant="subtitle2">
												<div dangerouslySetInnerHTML={{ __html: shareCardData.Description }} />
											</Typography>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions className={classes.actions2}>
									<Button
										size="small"
										color="primary"
										onClick={() => {
											feedShareHandleClickClose();
										}}
									>
										Close
									</Button>
								</DialogActions>
							</>
						) : (
							<>
								{' '}
								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }}>
												<Typography variant="h6" className={classes.openCardTitle}>
													{shareCardData.Message}
												</Typography>
											</Box>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions className={classes.actions2}>
									<Button
										size="small"
										color="primary"
										onClick={() => {
											feedShareHandleClickClose();
										}}
									>
										Close
									</Button>
								</DialogActions>
							</>
						)}
					</Dialog>
				</div>
			</main>
		</>
	);
});

export default Feed;
