import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import {
	makeStyles,
	Typography,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Box,
	Link,
	IconButton,
} from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PermanentAppBar from 'components/Header/PermanentAppBar';
const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	root: {
		width: '40%',
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
		margin: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
	heading: {
		fontSize: theme.typography.pxToRem(24),
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	imageBlock: {
		maxWidth: '100%',
		height: 'auto',
	},
	textMargin: {
		textAlign: 'center',
	},
	accessDimensions: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	inActiveSitebanner: {
		display: 'none',
	},
	siteBannerEnable: {
		backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'row',
	},
	actionIconButton: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
}));

const Spaces = observer(() => {
	const classes = useStyles();
	const appStores = React.useContext(stores.context.appStores);
	const user = React.useContext(stores.context.user);
	const site = React.useContext(stores.context.site);
	const [expanded, setExpanded] = React.useState<string | false>('panel1');
	const [jobExpanded, setJobExpanded] = React.useState<string | false>('panel2');
	const [siteBanner, setSiteBanner] = React.useState(false);
	const routes = React.useContext(stores.context.routes);
	appStores.showAppLoader = user.permissions === null ? true : false;
	React.useEffect(() => {
		if (user.currentSiteId) {
			site.fetchSiteInfo(user.currentSiteId);
		}
		user.getHelpDeskDetails();
	}, [site, user.currentSiteId]);
	React.useEffect(() => {
		const inactiveLocations = site.siteList.filter(item => item.LocationStatus === 'INACTIVE');
		if (inactiveLocations.find(item => item.Id === user.currentSiteId)) {
			setSiteBanner(true);
		}
	}, [user.currentSiteId, site.siteList]);
	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	const jobHandleChange = (panel: string) => (event: React.ChangeEvent<{}>, isJobExpanded: boolean) => {
		setJobExpanded(isJobExpanded ? panel : false);
	};

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{user && user.token ? (
					<div>
						<div className={classes.root}>
							<div className={siteBanner ? classes.siteBannerEnable : classes.inActiveSitebanner}>
								<span style={{ margin: 'auto', color: 'white', width: '85%' }}>
									This site is inactive, please select another site
								</span>
								<Link
									onClick={() => {
										routes.navigate({
											name: 'profile',
										});
									}}
								>
									<IconButton
										aria-label="Click to view"
										color="inherit"
										size="small"
										className={classes.actionIconButton}
									>
										<Icon icon={faAngleRight} size="2.5rem" color="white" />
									</IconButton>
								</Link>
							</div>
							<ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
								>
									<Typography className={classes.heading}>Site Details</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.flexColumn}>
									<Box>
										{site.siteInfo.SitePhoto ? (
											<img className={classes.imageBlock} src={site.siteInfo.SitePhoto} alt="SitePhoto" />
										) : null}
									</Box>
									<Typography className={classes.flexColumn}>
										<strong>Address</strong>
										<span>{site.siteInfo.Address1}</span>
										<span>{site.siteInfo.Address2}</span>
										<span>{site.siteInfo.Address3}</span>
										<span>{site.siteInfo.Address4}</span>
										<span>{site.siteInfo.Postcode}</span>
									</Typography>
									<Box my={1}>
										<hr />
									</Box>
									<Typography className={classes.flexColumn}>
										<strong>Opening Hours</strong>
										<span>{site.siteInfo.OpeningTimes}</span>
									</Typography>
									<Box my={1}>
										<hr />
									</Box>
									<Typography className={classes.flexColumn}>
										<strong>Contact Phone</strong>
										<span>{site.siteInfo.ContactPhone}</span>
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel expanded={jobExpanded === 'panel2'} onChange={jobHandleChange('panel2')}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
								>
									<Typography className={classes.heading}>Job Escalation Contact</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.flexColumn}>
									<Typography className={classes.flexColumn}>
										<strong>Helpdesk Phone</strong>
										<span style={{ color: 'gray' }}>
											{user.helpdeskInfo && user.helpdeskInfo.EntityDetails
												? user.helpdeskInfo.EntityDetails.HelpDeskPhoneNumber
												: ''}
										</span>
									</Typography>
									<Box my={1}>
										<hr />
									</Box>
									<Typography className={classes.flexColumn}>
										<strong>Helpdesk Email</strong>
										<span style={{ color: 'gray' }}>
											{user.helpdeskInfo && user.helpdeskInfo.EntityDetails
												? user.helpdeskInfo.EntityDetails.HelpdeskEmail
												: ''}
										</span>
									</Typography>
									<Box my={1}></Box>
									<Typography variant="body1" className={classes.flexColumn}>
										Please quote your job reference number
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</div>
					</div>
				) : (
					''
				)}
			</main>
		</>
	);
});

export default Spaces;
