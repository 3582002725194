import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Box, Typography, Card, CardContent } from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faCommentsAlt, faBullhorn, faQuoteLeft, faListAlt } from '@fortawesome/pro-light-svg-icons';
import { stores } from '../../store';
import { displayLocalTime } from '../../util';
import { IAskItems } from '../../types';
import { toJS } from 'mobx';
import Esme from './AskEsme';
import Suggestion from './AskFeedback';
import Issue from './AskWorkSpace';

const categoryIcons = [
	{
		title: 'Esme',
		icon: faCommentsAlt,
		color: 'cornflowerblue',
	},
	{
		title: 'Issue',
		icon: faListAlt,
		color: 'cornflowerblue',
	},
	{
		title: 'Suggestion',
		icon: faQuoteLeft,
		color: 'cornflowerblue',
	},
];
interface IProps {
	ask: IAskItems;
}
const useStyles = makeStyles(theme => ({
	closedCard: {
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(1),
		cursor: 'pointer',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(1),
		},
	},
	content: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	openCard: {
		minWidth: '350px',
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	askType: {
		position: 'absolute',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
		},
	},
	askBox: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	askIcon: {
		fontSize: '2rem',
		textAlign: 'center',
		padding: '5px',
		marginRight: '10px',
		backgroundSize: '16px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right bottom',
		color: 'cornflowerblue',
	},
	askItem: {
		flexBasis: '50%',
		maxWidth: '400px',
	},
}));

let askCategoryName = '';
const openAskCard = (categoryType: string, categories: any) => {
	const askCategory = toJS(categories).find((c: any) => c.RequestType === categoryType);
	if (!askCategory) {
		return;
	}
	askCategoryName = askCategory.RequestType;
};

const SentItems = observer((props: IProps) => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const catIcon = categoryIcons.find(c => c.title === props.ask.RequestType);
	const askIcon = catIcon ? catIcon.icon : faBullhorn;
	const openCardView = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const scrollToBottom = () => {
		openCardView.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};
	React.useEffect(() => {
		if (openCardView.current) {
			scrollToBottom();
		}
	}, [openCardView.current, props.ask.showOpenCard]);
	const OpenCardComponent = {
		Esme,
		Suggestion,
		Issue,
	}[askCategoryName];
	return (
		<Box className={classes.askBox}>
			<Box className={classes.askItem}>
				<Card
					variant="outlined"
					className={classes.closedCard}
					onClick={() => {
						openAskCard(props.ask.RequestType, user.sentItemsCategories);
						user.showAskCurrentCard(props.ask.Id);
					}}
				>
					<CardContent>
						<Box display="flex" style={{}}>
							<Box display="flex" flexDirection="column" justifyContent="center" mr={1}>
								<Icon icon={askIcon} className={classes.askIcon} size="2x" />
							</Box>
							<Box display="flex" flexDirection="column" flexGrow={1} style={{ margin: 'auto' }}>
								<Typography variant="subtitle2">
									{props.ask.RequestType === 'Esme' ? 'description' : 'Feedback'}
								</Typography>
								<Typography variant="caption">
									{props.ask.RequestType === 'Esme' ? props.ask.description : ''}
								</Typography>
								<Typography variant="caption">
									{props.ask.RequestType === 'Esme' ? props.ask.locationDescription : props.ask.Building}{' '}
								</Typography>
							</Box>
							<Box display="flex" flexDirection="column" style={{ textAlign: 'right', margin: 'auto' }}>
								<Typography variant="caption" color="primary">
									{props.ask.RequestType === 'Esme' ? (
										props.ask.statusDescription ? (
											props.ask.statusDescription
										) : (
											<br />
										)
									) : (
										''
									)}
								</Typography>
								<Typography variant="caption" color="textSecondary">
									{props.ask.RequestType === 'Esme'
										? displayLocalTime(props.ask.reportDate)
										: displayLocalTime(props.ask.CreatedDate)}
								</Typography>
								<Typography variant="caption" color="textSecondary">
									{' '}
									{props.ask.RequestType === 'Suggestion' ? 'Aria App Feedback' : `Ref: ${props.ask.Id}`}{' '}
								</Typography>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
			{props.ask.showOpenCard ? (
				<div ref={openCardView}>
					<Box className={classes.askItem} position="relative">
						<Box className={classes.askType}>
							<OpenCardComponent {...props} />
						</Box>
					</Box>
				</div>
			) : null}
		</Box>
	);
});

export default SentItems;
