import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

type sitesType = {
	sites: string[];
};

const ReadMore: React.FC<sitesType> = observer(({ sites }) => {
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	return (
		<React.Fragment>
			{isReadMore ? sites.slice(0, 3).join(', ') : sites.join(', ')}
			<span onClick={toggleReadMore} style={{ fontWeight: 'bold', color: '#098bbda1', cursor: 'pointer' }}>
				{sites.length > 3 && isReadMore ? `, +${sites.length - 3} more` : sites.length < 3 ? '' : ' show less'}
			</span>
		</React.Fragment>
	);
});

export default ReadMore;
