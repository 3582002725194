import * as React from 'react';
import {
	Card,
	Grid,
	Dialog,
	Menu,
	MenuItem,
	CardActions,
	Button,
	Typography,
	CardContent,
	CardActionArea,
	CardMedia,
	CardHeader,
	Box,
} from '@material-ui/core';
import { displayLocalTime } from '../../util';
import { stores } from '../../store';
import { observer } from 'mobx-react-lite';
import { IFeedResultItems, ISiteObject } from '../../types';
import FeedWireFrame from '../../components/Feed/FeedWireframe';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStyles from '../../assets/css/StatusFeedcss';
import VisitorImg from '../../assets/images/visitor.png';
import DeliveryImg from '../../assets/images/delivery.png';

const StatusFeeds: React.FunctionComponent = observer(() => {
	const feed = React.useContext(stores.context.feed);
	const classes = useStyles();
	const [pageNumber, setPageNumber] = React.useState(1);
	const [feedData, setFeedData] = React.useState<IFeedResultItems[]>([]);
	const [hasMore, setHasMore] = React.useState(true);
	const [feedDialogOpen, setFeedDialogOpen] = React.useState(false);
	const [opencardData, setOpenCardData] = React.useState<any>({});
	const [deliverySiteName, setDeliverySiteName] = React.useState<any>();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const site = React.useContext(stores.context.site);
	const routes: any = React.useContext(stores.context.routes);
	React.useEffect(() => {
		const sitedatanew: any = localStorage.getItem('sitedatalat');
		const df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : site.siteList;
		if (opencardData && opencardData.Parameters) {
			const deiliverySite = df.find(s => s.Id === opencardData.Parameters.SiteId[0]);
			setDeliverySiteName(deiliverySite);
		}
	}, [site.siteList, opencardData, opencardData.Parameters]);

	const fetchMoreData = () => {
		if (feedData.length >= feed.statusFeedItems && feed.statusFeedItems.Pagination.TotalFeedCount) {
			setHasMore(false);
			return;
		} else if (pageNumber === -1) {
			setHasMore(false);
			return;
		} else {
			feed.getStatusFeeds(pageNumber).then((resp: any) => {
				if (resp && resp.Result) {
					setFeedData([...feedData, ...resp.Result]);
					setPageNumber(resp && resp.Pagination.NextPageNumber);
				}
			});
		}
	};
	const feedHandleClickClose = () => {
		setFeedDialogOpen(false);
	};
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	React.useEffect(() => {
		fetchStatusFeeds(1);
	}, []);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleFeedDelayDelivery = (button: string, delaytime: number, id: any) => {
		feed.updateFeedStatus({
			feedId: id,
			ButtonName: button,
			State: { RemindMeInSeconds: delaytime },
		});
		setAnchorEl(null);
		feedHandleClickClose();
	};
	const handleFeedDelay = (button: string, delaytime: number, id: any) => {
		feed.updateFeedStatus({
			feedId: id,
			ButtonName: button,
			State: { RemindMeInSeconds: delaytime },
		});
		setAnchorEl(null);
		feedHandleClickClose();
	};
	const handleOpenCard = (
		title: string,
		time: string,
		description: string,
		parameters: any,
		category: number,
		id: number,
		buttons: any,
	) => {
		if (category === 2 || category === 3) {
			setFeedDialogOpen(true);
			setOpenCardData({
				Title: title,
				Description: description,
				StartTime: time,
				Parameters: parameters,
				Category: category,
				Id: id,
				AvailableButtons: buttons,
			});
		} else if (category === 8) {
			routes.navigate({
				name: 'chatbot',
				params: {
					srId: parameters.SRNumber,
				},
			});
		}
	};
	const fetchStatusFeeds = (pageNumber: Number) => {
		feed.getStatusFeeds(pageNumber).then((resp: any) => {
			if (resp && resp.Result) {
				setPageNumber(resp && resp.Pagination.NextPageNumber);
				setFeedData(resp.Result);
				if (resp && resp.Pagination.TotalFeedCount <= 6) {
					setHasMore(false);
				}
			}
		});
	};
	return (
		<>
			<div id="scrollableDivStatus" style={{ height: '100%', overflowY: 'scroll' }}>
				<InfiniteScroll
					dataLength={feedData.length}
					next={fetchMoreData}
					hasMore={hasMore}
					hasChildren={feed.statusFeedItemsCount <= feedData.length ? true : false}
					style={{ height: '100%', overflow: 'Hidden' }}
					loader={<FeedWireFrame feedType="statusfeed" />}
					endMessage={<p> </p>}
					scrollableTarget="scrollableDivStatus"
				>
					<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
						{feedData.map((elem: any) => (
							<Grid className={classes.feedAlignment} item xs={12} lg={12} md={12} key={feedData.indexOf(elem)}>
								{elem.Category === 1000 ? (
									<Card className={classes.wireframeCard}>
										<CardMedia
											component="img"
											height="140"
											image={elem.Parameters.MediaUrl}
											alt="green iguana"
											className={classes.statusFeed}
										/>
									</Card>
								) : (
									<Card className={classes.Card}>
										<CardActionArea
											classes={{
												root: classes.actionArea,
												focusHighlight: classes.focusHighlight,
											}}
											onClick={() => {
												handleOpenCard(
													elem.CollapsedTitle,
													elem.StartTime,
													elem.Description,
													elem.Parameters,
													elem.Category,
													elem.Id,
													elem.AvailableButtons,
												);
											}}
											disableTouchRipple={true}
										>
											<CardHeader className={classes.CardHeader}></CardHeader>
											<CardContent>
												<Box display="flex" flexDirection="column" flexGrow={1}>
													<Box>
														<Box style={{ textAlign: 'start' }}>
															<Typography variant="subtitle1" className={classes.titleView}>
																{elem.CollapsedTitle}
															</Typography>
														</Box>
														<Box style={{ textAlign: 'end', marginTop: '-16px' }}>
															<Typography className={classes.timeView} variant="subtitle1">
																{displayLocalTime(elem.StartTime)}
															</Typography>
														</Box>
													</Box>
													<Box style={{ textAlign: 'start', marginTop: '6px' }}>
														{elem.Category === 1 ? (
															<>
																<Typography className={classes.subTitleViewEvacuation} variant="caption">
																	<br />
																	{elem.Description}
																</Typography>
																<br />
																<Typography className={classes.subTitleView} variant="caption" color="textSecondary">
																	{elem.CollapsedDescription}
																</Typography>
															</>
														) : elem.Category === 3 ? (
															<>
																<Typography className={classes.subTitleView} variant="caption" color="textSecondary">
																	{'You have received a Delivery'}
																</Typography>
																<br />
																<br />
																<Typography
																	variant="subtitle1"
																	style={{ color: '#002855', font: 'normal normal normal 14px/17px Roboto' }}
																>
																	{'Please click this notification to confirm.'}
																</Typography>
															</>
														) : elem.Category === 2 ? (
															<>
																<Typography className={classes.subTitleView} variant="caption" color="textSecondary">
																	{'A visitor has arrived'}
																</Typography>
																<br />
																<br />
																<Typography
																	variant="subtitle1"
																	style={{ color: '#002855', font: 'normal normal normal 14px/17px Roboto' }}
																>
																	{'Please click this notification to confirm and then collect.'}
																</Typography>
															</>
														) : (
															<Typography className={classes.subTitleView} variant="caption" color="textSecondary">
																{elem.Description}
															</Typography>
														)}
													</Box>
												</Box>
											</CardContent>
										</CardActionArea>
									</Card>
								)}
							</Grid>
						))}
					</Grid>
				</InfiniteScroll>
			</div>
			<Dialog
				className={classes.openCardDialog}
				style={{ margin: 'auto', borderRadius: '8px' }}
				open={feedDialogOpen}
				onClose={feedHandleClickClose}
			>
				{opencardData.Category === 2 ? (
					<Card className={classes.openCard}>
						{opencardData.Parameters ? (
							<CardMedia
								component="img"
								height="140"
								image={VisitorImg}
								alt="Aria"
								style={{ height: '250px', objectFit: 'contain' }}
							/>
						) : (
							''
						)}
						<CardContent>
							<Box display="flex" flexDirection="column" flexGrow={1}>
								<Box>
									<Box style={{ textAlign: 'start' }}>
										<Typography variant="subtitle1" className={classes.openCardTitle}>
											{opencardData.Title}
										</Typography>
									</Box>
									<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
										<Typography variant="subtitle1" className={classes.openCardTime}>
											{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
										</Typography>
									</Box>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									flexDirection="column"
									style={{ textAlign: 'start', marginTop: '15px', marginLeft: '10px' }}
								>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'A visitor has arrived from:'}{' '}
										<b>{opencardData.Parameters && opencardData.Parameters.VisitorFromOrReasonForAttending}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'Visitor Name:'} <b>{opencardData.Parameters && opencardData.Parameters.VisitorName}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'You can meet them at:'}{' '}
										<b>{opencardData.Parameters && opencardData.Parameters.CollectionPlace}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'At:'}
										{opencardData.Parameters && deliverySiteName ? deliverySiteName.Name : ''}
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										Please select from the following options
									</Typography>
								</Box>
							</Box>
						</CardContent>
						<CardActions className={classes.actions}>
							{opencardData &&
								opencardData.AvailableButtons.map((button: string) =>
									button === 'Not ready' ? (
										<div>
											<Button
												size="medium"
												color="primary"
												key={button}
												className={classes.ActionButtons2}
												variant={button === 'Not ready' ? 'outlined' : 'contained'}
												onClick={handleClick}
											>
												{button}
											</Button>
											<Menu
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}
											>
												<MenuItem className={classes.menuButton}>I will be there... </MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelay(button, 300, opencardData.Id)}
												>
													In 5 minutes
												</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelay(button, 600, opencardData.Id)}
												>
													In 10 minutes
												</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelay(button, 900, opencardData.Id)}
												>
													In 15 minutes
												</MenuItem>
											</Menu>
										</div>
									) : (
										<Button
											size="medium"
											color="primary"
											key={button}
											className={classes.ActionButtons}
											variant={button === 'Not ready' ? 'outlined' : 'contained'}
											onClick={() => {
												feed.updateFeedStatus({
													feedId: opencardData.Id,
													ButtonName: button,
													State: null,
												});
												feedHandleClickClose();
											}}
										>
											{button}
										</Button>
									),
								)}
						</CardActions>
					</Card>
				) : opencardData.Category === 3 ? (
					<Card className={classes.openCard}>
						{opencardData.Parameters ? (
							<CardMedia
								component="img"
								height="140"
								image={DeliveryImg}
								alt="Aria"
								style={{ height: '250px', objectFit: 'contain' }}
							/>
						) : (
							''
						)}
						<CardContent>
							<Box display="flex" flexDirection="column" flexGrow={1}>
								<Box>
									<Box style={{ textAlign: 'start' }}>
										<Typography variant="subtitle1" className={classes.openCardTitle}>
											{opencardData.Title}
										</Typography>
									</Box>
									<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
										<Typography variant="subtitle1" className={classes.openCardTime}>
											{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
										</Typography>
									</Box>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									flexDirection="column"
									style={{ textAlign: 'start', marginTop: '15px', marginLeft: '10px' }}
								>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'A delivery has arrived from:'}{' '}
										<b>{opencardData.Parameters && opencardData.Parameters.SenderName}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'Parcel Number:'} <b>{opencardData.Parameters && opencardData.Parameters.ParcelNumber}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'You can collect the item from:'}{' '}
										<b>{opencardData.Parameters && opencardData.Parameters.CollectionPlace}</b>
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										{'At:'}
										{opencardData.Parameters && deliverySiteName ? deliverySiteName.Name : ''}
									</Typography>
									<Typography className={classes.openCardTypography} variant="body2" gutterBottom>
										Please select from the following options
									</Typography>
								</Box>
							</Box>
						</CardContent>
						<CardActions className={classes.actions}>
							{opencardData &&
								opencardData.AvailableButtons.map((button: string) =>
									button === 'Hold on to it' ? (
										<div>
											<Button
												size="medium"
												color="primary"
												key={button}
												aria-controls="simple-menu"
												aria-haspopup="false"
												variant={button === 'Hold on to it' ? 'outlined' : 'contained'}
												onClick={handleClick}
												className={classes.ActionButtons2}
											>
												{button}
											</Button>
											<Menu
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}
											>
												<MenuItem className={classes.menuButton}>Remind me in...</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelayDelivery(button, 3600, opencardData.Id)}
												>
													1 hour
												</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelayDelivery(button, 7200, opencardData.Id)}
												>
													2 hours
												</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelayDelivery(button, 14400, opencardData.Id)}
												>
													4 hours
												</MenuItem>
												<MenuItem
													className={classes.menuButton2}
													onClick={() => handleFeedDelayDelivery(button, 0, opencardData.Id)}
												>
													Don't remind
												</MenuItem>
											</Menu>
										</div>
									) : (
										<Button
											size="medium"
											color="primary"
											key={button}
											className={classes.ActionButtons}
											variant={button === 'Hold on to it' ? 'outlined' : 'contained'}
											onClick={() => {
												feed.updateFeedStatus({
													feedId: opencardData.Id,
													ButtonName: button,
													State: null,
												});
												feedHandleClickClose();
											}}
										>
											{button}
										</Button>
									),
								)}
						</CardActions>
					</Card>
				) : (
					''
				)}
			</Dialog>
		</>
	);
});

export default StatusFeeds;
