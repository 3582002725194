import * as React from 'react';
import {
	makeStyles,
	Grid,
	Typography,
	Paper,
	Dialog,
	DialogTitle,
	Button,
	DialogContentText,
	DialogActions,
	Fab,
	DialogContent,
	Container,
} from '@material-ui/core';
import { IframeComponent } from './IframeComponent';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import CloseChatIcon from '../assets/icons/CloseChatSolid.png';

const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 70px)',
		display: 'flex',
	},
	paper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		boxShadow: 'none !important',
		height: '100%',
		marginLeft: '30px',
		marginTop: '4px',
	},
	esmeCloseIcon: {
		width: '35px',
		height: '35px',
		display: 'none',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	title: {
		padding: theme.spacing(2),
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	textMargin: {
		textAlign: 'center',
	},
	closeIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	textView: {
		color: '#002855',
		font: 'normal normal normal 18px/12px Roboto',
		width: '112px',
		height: '22px',
		lineHeight: 'normal',
		marginLeft: '-10px',
		marginTop: '-3px',
	},
}));

const ViewMyJobs: React.FunctionComponent = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const routes: any = React.useContext(stores.context.routes);
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const handleCloseChat = () => {
		setEsmeDialogOpen(false);
		routes.viewmyjobval = false;
		resetChatbot();
	};
	window.addEventListener(
		'message',
		event => {
			if (event.data == 'firstmessage') {
				//setEsmeDialogOpen(true);
				//alert(routes.viewmyjobval);
				routes.viewmyjobval = true;
			} else {
				routes.viewmyjobval = false;
			}

			// ...
		},
		false,
	);

	const resetChatbot = () => {
		const iframeEl: any = document.getElementById('myframe');
		iframeEl.contentWindow.postMessage('reset', '*');
	};
	const esmeUrl = process.env.REACT_APP_ESME_URL;
	return (
		<>
			<Container className={classes.container}>
				<div className={classes.frameContainer}>
					{user.esmesite.map((ss: any) =>
						ss.Id === user.currentSiteId && ss.EsmeClientId !== null ? (
							<Paper className={classes.paper}>
								<Grid container>
									<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
										<Typography className={classes.textView} variant="h5">
											View My Jobs
										</Typography>
									</Grid>
									<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
										<Fab
											color="primary"
											aria-label="add"
											className={classes.esmeCloseIcon}
											onClick={() => {
												setEsmeDialogOpen(true);
											}}
										>
											<img src={CloseChatIcon} alt="" width="30px" />
										</Fab>
									</Grid>
								</Grid>
								<Grid container style={{ height: '100%' }}>
									<Grid item xs={12} style={{ height: '100%' }}>
										<IframeComponent
											iframeSrc={esmeUrl + '?subChannel=ar02&header=off&viewmyjobs=x'}
											//iframeSrc="https://edisonesme.azurewebsites.net/?subChannel=ar02&header=off&viewmyjobs=x"
											height="450px"
											width="100%"
											token={user.token}
											clientId={ss.EsmeClientId}
										/>
									</Grid>
								</Grid>
							</Paper>
						) : ss.Id === user.currentSiteId && ss.EsmeClientId === null ? (
							<>
								<div className={classes.textMargin}>
									<Typography variant="subtitle2">Chatbot is not available for this site.</Typography>
								</div>
							</>
						) : (
							''
						),
					)}
				</div>
				<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
					<DialogContent>
						<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
							Are you sure you want to leave the chat
						</DialogTitle>
						<DialogContentText>
							Please take a note of the Service Request reference if given, this chat will end and be deleted.
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleCloseChat} color="primary">
								Close chat
							</Button>
							<Button onClick={handleClickClose} color="primary">
								Return to chat
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</Container>
		</>
	);
});

export default ViewMyJobs;
