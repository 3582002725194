import { action } from 'mobx';
import { postAPI, getAPI } from 'services/apiService';

class Blob {
	@action
	public async blobToken() {
		let getUploadTokenApi = '/api/token';
		return await getAPI(getUploadTokenApi);
	}
	public async blobImage(values: Object) {
		let getUploadImageApi = '/uploadImage/save';
		return await postAPI(getUploadImageApi, values);
	}
}
export default Blob;
