import * as React from 'react';
import {
	Grid,
	Dialog,
	DialogContent,
	Slider,
	Card,
	CardContent,
	Typography,
	CardActionArea,
	CardMedia,
	Box,
} from '@material-ui/core';
import { stores } from '../store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';
import PermanentAppBar from '../components/Header/PermanentAppBar';
import FeedWireFrame from '../components/Feed/FeedWireframe';
import { IFeedResultItems, IQuestion } from '../types';
import { displayLocalTime, displayDateTime, isExpired } from '../util';
import useStyles from '../assets/css/FeedArchiveCss';
import Rating from '@material-ui/lab/Rating';
import { Icon } from '@mitie/material-ui-extensions';
import { faFrown, faSmile } from '@fortawesome/pro-light-svg-icons';
import likeselected from '../assets/icons/favouriteselected.svg';
import ShareIcon from '../assets/icons/Share';
import LikeIcon from '../assets/icons/Like';

interface IQuestionResponse {
	QuestionNumber: number;
	UserResponse: number;
}

const Archive: React.FunctionComponent = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const [feedDialogOpen, setFeedDialogOpen] = React.useState(false);
	const [opencardData, setOpenCardData] = React.useState<any>({});
	const feed = React.useContext(stores.context.feed);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [mainFeedData, setMainFeedData] = React.useState<IFeedResultItems[]>([]);
	const [hasMoreMain, setHasMoreMain] = React.useState(true);
	const appStores = React.useContext(stores.context.appStores);
	const [rating, setRating] = React.useState<IQuestionResponse[] | null>(null);
	const [ratValid, setRatingValid] = React.useState<any>('');
	const [questionType, setQuestionType] = React.useState<any>();
	const [expired, setExpired] = React.useState<any>();
	appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;

	const feedHandleClickClose = () => {
		setFeedDialogOpen(false);
	};
	const fetchMoreMainFeeds = () => {
		if (mainFeedData.length >= feed.archiveFeedItems.Pagination.TotalFeedCount) {
			setHasMoreMain(false);
			return;
		}
		feed.getArchiveFeeds(pageNumber).then((resp: any) => {
			if (resp && resp.Result) {
				setMainFeedData([...mainFeedData, ...resp.Result]);
				setPageNumber(resp && resp.Pagination.NextPageNumber);
			}
		});
	};
	React.useEffect(() => {
		fetchArchiveFeeds(1);
		appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	}, []);
	const fetchArchiveFeeds = (pageNumber: Number) => {
		feed.getArchiveFeeds(pageNumber).then((resp: any) => {
			if (resp && resp.Result) {
				setPageNumber(resp && resp.Pagination.NextPageNumber);
				setMainFeedData(resp.Result);
				if (resp && resp.Pagination.TotalFeedCount <= 12) {
					setHasMoreMain(false);
				}
			}
		});
	};
	const handleOpenCard = (
		title: string,
		time: string,
		description: string,
		parameters: any,
		category: number,
		userstate: any,
		IsLiked: boolean,
		Id: number,
	) => {
		console.log(ratValid);
		const surveryExpired = isExpired(parameters.EndDate);
		setExpired(surveryExpired);
		const questionType = feed.surveyTypes[parameters.SurveyTypeId];
		setQuestionType(questionType);
		setFeedDialogOpen(true);
		setOpenCardData({
			Title: title,
			Id: Id,
			Description: description,
			StartTime: time,
			Parameters: parameters,
			Category: category,
			UserState: userstate,
			IsLiked: IsLiked,
		});
		console.log(opencardData);
	};
	const fetchFeedURL = (event: any, feedid: number) => {
		event.stopPropagation();
		feed.getSharedURL(feedid).then((resp: any) => {
			if (resp && resp.url) {
				window.location.href = `mailto:?subject=I would like to share this feed on Aria!&body=Here's the link - ${resp.url}`;
			}
		});
	};
	React.useEffect(() => {
		const { responses = null } = opencardData.UserState || { responses: null };
		if (opencardData && opencardData.Category === 7) {
			if (responses) {
				setRating(responses);
			} else {
				setRating(
					opencardData &&
						opencardData.Parameters &&
						opencardData.Parameters.Questions.map((question: IQuestion) => ({
							QuestionNumber: question.QuestionNumber,
							UserResponse: 0,
						})),
				);
			}
		}
	}, [opencardData]);
	const handleLike = (event: any, feedId: number, liked: boolean) => {
		event.stopPropagation();
		const data = {
			feedId,
			liked,
		};
		feed.postFeedLike(data).then(res => {
			if (res) console.log('res', res);
			const newdata = mainFeedData.filter((el: IFeedResultItems) => {
				if (el.Id === feedId) {
					el.IsLiked = liked;
					return el;
				}
				return el;
			});
			setMainFeedData([...newdata]);
			if (opencardData.Id === feedId) {
				setOpenCardData({ ...opencardData, IsLiked: liked });
			}
		});
	};
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div className={classes.container}>
					<div className={classes.frameContainer}>
						<Grid
							style={{ height: 'calc(100vh - 80px)', paddingTop: '2px', paddingBottom: '0px' }}
							className={classes.archiveView}
						>
							<div id="scrollableDivMain" style={{ height: '100%', overflowY: 'scroll' }}>
								<InfiniteScroll
									dataLength={mainFeedData.length}
									next={fetchMoreMainFeeds}
									hasMore={hasMoreMain}
									style={{ height: '100%', overflow: 'Hidden' }}
									loader={<FeedWireFrame feedType="archive" />}
									hasChildren={feed.archiveFeedItemsCount <= mainFeedData.length ? true : false}
									endMessage={<p></p>}
									scrollableTarget="scrollableDivMain"
								>
									<Grid
										container
										spacing={2}
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
										className={classes.gridWidth}
									>
										{mainFeedData.map((elem: IFeedResultItems) => {
											return (
												<Grid
													item
													xs={12}
													lg={4}
													sm={12}
													md={6}
													className={classes.cardView}
													key={mainFeedData.indexOf(elem)}
												>
													{elem.Category === 1000 ? (
														<Card className={classes.wireframeCard}>
															<CardMedia
																component="img"
																height="140"
																image={elem.Parameters.MediaUrl}
																alt="green iguana"
																className={classes.cardMedia}
															/>
														</Card>
													) : (
														<Card className={classes.Card}>
															<CardActionArea
																classes={{
																	root: classes.actionArea,
																	focusHighlight: classes.focusHighlight,
																}}
																disableTouchRipple={true}
																onClick={() => {
																	handleOpenCard(
																		elem.CollapsedTitle,
																		elem.StartTime,
																		elem.Description,
																		elem.Parameters,
																		elem.Category,
																		elem.UserState,
																		elem.IsLiked,
																		elem.Id,
																	);
																}}
															>
																{elem.Category === 7 ? (
																	''
																) : (
																	<>
																		<Box className={classes.feedUIStyle}>
																			<span onClick={event => fetchFeedURL(event, elem.Id)}>
																				<ShareIcon />
																			</span>
																		</Box>
																		<Box className={classes.feedUIStyle}>
																			<span
																				onClick={event => {
																					handleLike(event, elem.Id, !elem.IsLiked);
																				}}
																			>
																				{elem.IsLiked ? (
																					<img
																						src={likeselected}
																						className={classes.likedIconStyle}
																						alt=""
																						width="30px"
																					/>
																				) : (
																					<LikeIcon />
																				)}
																			</span>
																		</Box>
																	</>
																)}
																{elem.Parameters.MediaType === 2 ? (
																	<video
																		style={{
																			height: '186px',
																			width: '100%',
																			objectFit: 'cover',
																			borderRadius: '8px 8px 0px 0px',
																		}}
																	>
																		<source src={elem.Parameters.MediaUrl}></source>
																	</video>
																) : (
																	<CardMedia
																		component="img"
																		height="140"
																		image={elem.Parameters.MediaPreviewUrl}
																		alt="Aria"
																		style={{ height: '186px', borderRadius: '8px 8px 0px 0px' }}
																	/>
																)}
																<CardContent style={{ height: '94px', padding: '18px' }}>
																	<Box display="flex" flexDirection="column" flexGrow={1}>
																		<Box>
																			<Box style={{ textAlign: 'start' }}>
																				<Typography variant="subtitle1" className={classes.titleView}>
																					{elem.CollapsedTitle}
																				</Typography>
																			</Box>
																			<Box style={{ textAlign: 'end', marginTop: '-27px' }}>
																				<Typography variant="subtitle1">{displayLocalTime(elem.StartTime)}</Typography>
																			</Box>
																		</Box>
																		<Box style={{ textAlign: 'start', marginTop: '5px' }}>
																			<Typography
																				style={{
																					overflowWrap: 'break-word',
																					font: 'normal normal normal 14px/17px Roboto',
																				}}
																				variant="caption"
																				color="textSecondary"
																			>
																				{elem.CollapsedDescription}
																			</Typography>
																		</Box>
																	</Box>
																</CardContent>
															</CardActionArea>
														</Card>
													)}
												</Grid>
											);
										})}
									</Grid>
								</InfiniteScroll>
							</div>
						</Grid>
					</div>
					<Dialog
						className={classes.openCardDialog}
						style={{ margin: 'auto', borderRadius: '8px' }}
						open={feedDialogOpen}
						onClose={feedHandleClickClose}
						scroll={'paper'}
					>
						{opencardData.Category === 7 ? (
							<>
								{opencardData.Parameters.MediaType === 1 ? (
									<img
										style={{ width: '100%', objectFit: 'cover', height: '50%' }}
										src={opencardData.Parameters.MediaUrl}
										alt="image"
									/>
								) : (
									<video autoPlay controls width="100%" style={{ height: '50%', objectFit: 'cover' }}>
										<source src={opencardData.Parameters.MediaUrl}></source>
									</video>
								)}

								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }} className={classes.openCardTitle}>
												<Typography variant="h6">{opencardData.Title}</Typography>
											</Box>
											<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
												<Typography variant="subtitle1" className={classes.OpenCardtimeView}>
													{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
												</Typography>
											</Box>
										</Box>
										<Box style={{ textAlign: 'start', marginTop: '8px' }}>
											<Typography className={classes.openCardDescr} variant="subtitle2">
												<div dangerouslySetInnerHTML={{ __html: opencardData.Description }} />
											</Typography>
										</Box>
										<Box display="flex" justifyContent="center" flexDirection="column" mt={2}>
											{questionType === 'Star Survey' ? (
												<Typography variant="subtitle2">On a scale of 0 to 5</Typography>
											) : (
												<Typography variant="subtitle2">On a scale of 0 to 10</Typography>
											)}
											{opencardData.Parameters.Questions &&
												opencardData.Parameters.Questions.map((question: IQuestion, qIdx: number) => (
													<Box key={Math.random()} mt={2}>
														<Typography variant="subtitle2" style={{ overflowWrap: 'break-word' }}>
															Q{qIdx + 1} of {opencardData.Parameters.Questions.length}: {question.QuestionDescription}
														</Typography>
														{questionType === 'Star Survey' ? (
															<Rating
																className={classes.ratingStyle}
																name={`simple-controlled${qIdx + 1}`}
																value={rating ? rating[qIdx].UserResponse : 0}
																readOnly={true}
																onChange={(event: any, newValue: number) => {
																	const currentRating = rating?.find(
																		q => Number(q.QuestionNumber) === Number(question.QuestionNumber),
																	);
																	newValue !== 0
																		? localStorage.setItem('CMValue', 'true')
																		: localStorage.setItem('CMValue', 'false');
																	localStorage.setItem('URL', window.location.pathname);

																	currentRating!.UserResponse = newValue;
																	setRatingValid(currentRating!.UserResponse);
																	setRating([...rating]);
																}}
															/>
														) : (
															<React.Fragment>
																<Box textAlign="center" mt={1}>
																	{rating && rating[qIdx] ? rating[qIdx].UserResponse : 0}
																</Box>
																<Box display="flex">
																	<Icon icon={faFrown} className={classes.smileyIcon} size={'2x'}></Icon>
																	<Slider
																		className={classes.slider}
																		disabled={true}
																		defaultValue={rating && rating[qIdx] ? rating[qIdx].UserResponse : 0}
																		onChange={(event: any, newValue: number) => {
																			newValue !== 0
																				? localStorage.setItem('CMValue', 'true')
																				: localStorage.setItem('CMValue', 'false');
																			localStorage.setItem('URL', window.location.pathname);
																			if (!rating || !rating[qIdx]) return;
																			const currentRating = rating.find(
																				q => Number(q.QuestionNumber) === Number(question.QuestionNumber),
																			);
																			currentRating!.UserResponse = newValue;
																			setRatingValid(currentRating!.UserResponse);
																			setRating([...rating]);
																		}}
																		track="inverted"
																		step={1}
																		min={0}
																		max={10}
																	/>
																	<Icon icon={faSmile} className={classes.smileyIcon} size={'2x'}></Icon>
																</Box>
															</React.Fragment>
														)}
													</Box>
												))}
											<Box display="block" my={2}></Box>
											<Typography variant="caption" display="block" color={expired ? 'error' : undefined}>
												Survey {expired ? 'closed on' : 'closes'} :{' '}
												{opencardData.Parameters.EndDate ? displayDateTime(opencardData.Parameters.EndDate) : null}
											</Typography>
										</Box>
									</Box>
								</DialogContent>
							</>
						) : (
							<>
								<Box className={classes.feedUIStyle}>
									<span onClick={event => fetchFeedURL(event, opencardData.Id)}>
										<ShareIcon />
									</span>
								</Box>
								<Box className={classes.feedUIStyle}>
									<span
										onClick={event => {
											handleLike(event, opencardData.Id, !opencardData.IsLiked);
										}}
									>
										{opencardData.IsLiked ? (
											<img src={likeselected} className={classes.likedIconStyle} alt="" width="30px" />
										) : (
											<LikeIcon />
										)}
									</span>
								</Box>
								{opencardData.Parameters ? (
									opencardData.Parameters.MediaType === 1 ? (
										<img
											style={{ width: '100%', objectFit: 'cover', height: '50%' }}
											src={opencardData.Parameters.MediaUrl}
											alt="image"
										/>
									) : (
										<video autoPlay controls width="100%" style={{ height: '50%', objectFit: 'cover' }}>
											<source src={opencardData.Parameters.MediaUrl}></source>
										</video>
									)
								) : (
									''
								)}
								<DialogContent>
									<Box display="flex" flexDirection="column" flexGrow={1}>
										<Box>
											<Box style={{ textAlign: 'start' }}>
												<Typography variant="h6" className={classes.openCardTitle}>
													{opencardData.Title}
												</Typography>
											</Box>
											<Box style={{ textAlign: 'end', marginTop: '-25px' }}>
												<Typography variant="subtitle1" className={classes.OpenCardtimeView}>
													{opencardData.StartTime ? displayLocalTime(opencardData.StartTime) : ''}
												</Typography>
											</Box>
										</Box>
										<Box style={{ textAlign: 'start', marginTop: '30px', width: '95%', marginLeft: '14px' }}>
											<Typography className={classes.openCardDescr} variant="subtitle2">
												<div dangerouslySetInnerHTML={{ __html: opencardData.Description }} />
											</Typography>
										</Box>
									</Box>
								</DialogContent>
							</>
						)}
					</Dialog>
				</div>
			</main>
		</>
	);
});

export default Archive;
