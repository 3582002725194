import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core';
import Feedback from './Feedback';
import Chatbot from './Chatbot';
import SentItems from '../components/Ask/SentItems';
import DynamicForm from './DynamicForm';
import JobRequestForm from './JobRequestForm';
import ViewMyJobs from './ViewMyJobs';
import AskLanding from './AskLanding';
import PermanentAppBar from '../components/Header/PermanentAppBar';
const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
		backgroundColor: '#f4f8fc',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
		width: '100%',
		overflowX: 'hidden',
		backgroundColor: '#ffffff',
	},
	'@global': {
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: '#66CCFA',
		},
		'*::-webkit-scrollbar': {
			width: '6px',
		},
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		display: 'flex',
		backgroundColor: 'white',
	},
}));

const Ask = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const appStores = React.useContext(stores.context.appStores);
	const { routeName } = React.useContext(stores.context.routes);
	appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	appStores.showAppLoader = user.currentSiteId ? false : true;
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div className={classes.container}>
					{routeName === 'ask' ? <AskLanding /> : ''}
					{routeName === 'jobRequestForm' ? <JobRequestForm /> : ''}
					{routeName === 'viewMyJobs' ? <ViewMyJobs /> : ''}

					{routeName === 'feedback' ? <Feedback /> : ''}
					{routeName === 'dynamicform' ? <DynamicForm /> : ''}
					{routeName === 'chatbot' ? <Chatbot /> : ''}
					{routeName === 'sentItems' ? <SentItems /> : ''}
				</div>
			</main>
		</>
	);
});

export default Ask;
