import Routes from './Routes';
import { createContext } from 'react';
import User from './User';
import Spaces from './Spaces';
import AppStores from './AppStores';
import Register from './Register';
import Feed from './Feed';
import FeedBack from './Feedback';
import Site from './Site';
import Notification from './Notifications';
import Settings from './Settings';
import ContentManagement from './ContentManagement';
import Blob from './Blob';
import RefuelMenus from './RefuelMenus';
import About from './About';

export { default as Routes } from './Routes';
export { default as User } from './User';
export { default as Spaces } from './Spaces';
export { default as AppStores } from './AppStores';
export { default as Register } from './Register';
export { default as Feed } from './Feed';
export { default as Site } from './Site';
export { default as FeedBack } from './Feedback';
export { default as Notification } from './Notifications';
export { default as ContentManagement } from './ContentManagement';
export { default as Settings } from './Settings';
export { default as Blob } from './Blob';
export { default as About } from './About';

export class Root {
	public routes = new Routes();
	public user = new User();
	public spaces = new Spaces();
	public register = new Register();
	public appStores = new AppStores();
	public site = new Site();
	public feed = new Feed();
	public feedback = new FeedBack();
	public notifications = new Notification();
	public settings = new Settings();
	public contentManagement = new ContentManagement();
	public blob = new Blob();
	public refuelMenus = new RefuelMenus();
	public about = new About();

	public context = {
		routes: createContext(this.routes),
		user: createContext(this.user),
		site: createContext(this.site),
		feed: createContext(this.feed),
		feedback: createContext(this.feedback),
		spaces: createContext(this.spaces),
		appStores: createContext(this.appStores),
		register: createContext(this.register),
		notifications: createContext(this.notifications),
		contentManagement: createContext(this.contentManagement),
		settings: createContext(this.settings),
		blob: createContext(this.blob),
		refuelMenus: createContext(this.refuelMenus),
		about: createContext(this.about),
	};

	constructor() {
		Object.keys(this).forEach(storeName => this[storeName].init && this[storeName].init());
	}
}

export const stores = new Root();

(window as any).stores = stores;
