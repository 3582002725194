import * as React from 'react';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import classnames from 'classnames';
import { stores } from '../../store';
import FloorplanEditorCanvas from './Canvas';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import { Icon, Alert } from '@mitie/material-ui-extensions';
import {
	MenuItem,
	makeStyles,
	Button,
	Backdrop,
	CircularProgress,
	Typography,
	Grid,
	InputLabel,
	FormControl,
	Select,
	Tabs,
	Tab,
	Box,
	IconButton,
	Tooltip,
	Divider,
} from '@material-ui/core';
import {
	faSearchPlus,
	faSearchMinus,
	faHandPaper,
	faDrawSquare,
	faDrawPolygon,
	faUndo,
	faEraser,
	faCopy,
	faDrawCircle,
	faCompass,
	faRuler,
	faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { IFloorplanDetail, IObject, IActiveCoordinates, IFloorCoordinates, IEntitie } from '../../types';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		textAlign: 'left',
		'& .MuiContainer-root': {
			margin: theme.spacing(0),
			padding: theme.spacing(0),
		},
	},
	dropBoxContainer: {
		background: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		width: '240px',
		padding: theme.spacing(2),
		display: 'none',
	},
	dropBoxContainerVisible: {
		display: 'block',
	},
	dropBoxContainerDisplay: {
		position: 'absolute',
		right: '16px',
		textTransform: 'capitalize',
	},
	dropBox: {
		height: '56px',
		width: '208px',
		margin: theme.spacing(1.5, 0),
		'& > label': {
			cursor: 'pointer',
		},
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	itemLengthLabel: {
		margin: theme.spacing(2, 0),
	},
	footerIcons: {
		borderRadius: theme.shape.borderRadius,
		height: '72px',
		width: '434px',
		flexWrap: 'nowrap',
		textAlign: 'center',
		overflow: 'auto',
		background: `${theme.palette.grey[50]} 0% 0% no-repeat padding-box`,
		boxShadow: `0px 3px 6px ${theme.palette.text.primary}52`,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0),
		},
	},
	iconBox: {
		cursor: 'pointer',
		color: theme.palette.text.secondary,
		'&:active, &:hover': {
			'& > * , & > * svg': {
				color: theme.palette.primary.main,
				fontWeight: 'bold',
			},
		},
	},
	iconBoxSelected: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		'& > * , & > * svg': {
			color: theme.palette.primary.main,
			fontWeight: 'bold',
		},
	},
	iconBoxDisabled: {
		color: theme.palette.grey[100],
		'& > * , & > * svg': {
			height: '30px',
			width: '30px',
			color: theme.palette.grey[100],
		},
	},
	iconsDivider: {
		margin: theme.spacing(1, 0.5),
		'& > *': {
			backgroundColor: theme.palette.text.secondary,
		},
	},
	footerIcon: {
		width: '48px',
		height: '48px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	footerIconLabel: {
		fontSize: '9px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '24px',
		width: 'auto',
	},
	footerBtn: {
		height: '36px',
		width: '140px',
		margin: theme.spacing(0, 1, 0, 0),
	},
	outlinedBtn: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '4px',
		padding: theme.spacing(0, 0.125),
	},
	footerTabs: {
		width: '195px',
		height: '36px',
		minHeight: '36px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '185px',
		},
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		'& .Mui-selected, & .Mui-selected:hover': {
			backgroundColor: theme.palette.primary.main,
			color: `${theme.palette.background.paper}`,
		},
		'& button:last-child': {
			borderRight: 'none',
		},
	},
	rootItem: {
		minWidth: '62px',
		minHeight: '36px',
		borderRight: `1px solid ${theme.palette.primary.main}`,
		[theme.breakpoints.down('xs')]: {
			width: '33.3%',
		},
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	indicator: {
		display: 'none',
	},
	canvasWrapper: {
		width: '100%',
		height: 'calc(100vh - 100px)',
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		//color: '#fff',
	},
	canvasWrapperDrawerOpen: {
		width: 'calc(100% - 185px)',
	},
	statusMsg: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			backgroundColor: theme.palette.success.main,
			padding: theme.spacing(1),
		},
	},
	closeIcon: {
		'& > *': {
			color: theme.palette.background.paper,
		},
	},
	footerIconJustify: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'space-between',
		},
	},
	displayFixed: {
		position: 'fixed',
		bottom: '8px',
		width: 'auto',
		display: 'flex',
		zIndex: 'inherit',
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},
}));

const Editor = () => {
	const spaces = React.useContext(stores.context.spaces);
	const appStores = React.useContext(stores.context.appStores);
	const { route } = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	React.useEffect(() => {
		if (spaces.flrInfoEditor.length > 1) {
			setEntityType('room');
		} else {
			setEntityType('parking');
		}
	}, [spaces.flrInfoEditor]);
	const [entityType, setEntityType] = React.useState('');
	const [open, setStatusMessageOpen] = React.useState('');
	const [showFloorEditor, setShowFloorEditor] = React.useState(0);
	const [bookingLoader, setBookingLoader] = React.useState(false);
	const [entityData, setEntityData] = React.useState<any>();
	const SVGIcon = {
		size: '30px',
	};

	let floorplanDetail: IFloorplanDetail = useLocalStore(() => ({
		URL: '',
		propertyTypeId: '',
		propertyId: '',
		propertyName: '',
		controlType: '',
		savedFloorplanCoordinates: [],
		showPropContainer: false,
		mappedEntityIds: [],
		entitiesList: [],
		entitiesListByType: [],
		isEntityTypeDisabled: false,
		ZoneData: [],
	}));

	const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
		let newvalueupdated: any = '';
		if (newValue == 'room') {
			newvalueupdated = 1;
		} else if (newValue == 'desk') {
			newvalueupdated = 0;
		} else {
			newvalueupdated = 2;
		}
		spaces.florentities(route.params.floorId, newvalueupdated).then(res => {
			setEntityData(res);
		});

		setBookingLoader(true);
		floorplanDetail.showPropContainer = false;
		enableEditorToDraw('');
		floorplanDetail.isEntityTypeDisabled = false;
		setEntityType(newValue);
	};

	const enableEditorToDraw = (controlType: string) => {
		setStatusMessageOpen('');
		floorplanDetail.propertyId = '';
		controlType !== '' && controlType !== 'DiscardChanges' && controlType !== 'zoomIn' && controlType !== 'zoomOut'
			? localStorage.setItem('CMValue', 'true')
			: localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		if (!floorplanDetail.showPropContainer) {
			floorplanDetail.controlType =
				(floorplanDetail.controlType === 'undo' && controlType === 'undo') ||
				floorplanDetail.controlType !== controlType
					? controlType
					: '';
			FloorplanEditorCanvas.enableEditorToDraw(controlType);
			if (floorplanDetail.controlType === 'DiscardChanges') {
				floorplanDetail.isEntityTypeDisabled = false;
			} else if (
				floorplanDetail.controlType !== 'zoomIn' &&
				floorplanDetail.controlType !== 'zoomOut' &&
				floorplanDetail.controlType !== 'isPanMode'
			) {
				floorplanDetail.isEntityTypeDisabled = true;
			}
		}
	};

	const setActiveCanvas = (activeCoordinates: IActiveCoordinates) => {
		FloorplanEditorCanvas.setActiveCanvas(activeCoordinates);
		floorplanDetail.showPropContainer = false;
		floorplanDetail.propertyId = '';
		floorplanDetail.propertyName = '';
		floorplanDetail.controlType = '';
		FloorplanEditorCanvas.floorplanDetail.controlType = '';
	};

	const cancelLastDrawnInCanvas = () => {
		localStorage.removeItem('URL');
		localStorage.removeItem('CMValue');
		if (floorplanDetail.controlType) {
			FloorplanEditorCanvas.cancelLastDrawnInCanvas();
			floorplanDetail.propertyId = '';
			floorplanDetail.propertyName = '';
			floorplanDetail.controlType = '';
			FloorplanEditorCanvas.floorplanDetail.controlType = '';
		}
		floorplanDetail.showPropContainer = false;
	};

	const saveFloorplanCoordinates = () => {
		localStorage.removeItem('CMValue');
		localStorage.removeItem('URL');
		const floorplanCoordinates: IFloorCoordinates[] = FloorplanEditorCanvas.saveFloorplanCoordinates();
		floorplanDetail.isEntityTypeDisabled = false;

		if (floorplanCoordinates.length) {
			appStores.showAppLoader = true;
			spaces.saveFloorplanCoordinates(route.params.floorId, floorplanCoordinates).then(() => {
				appStores.showAppLoader = false;
				setStatusMessageOpen('saved');
			});
		}
	};
	const showStatusForDeleteCoordinates = () => {
		setStatusMessageOpen('deleted');
	};

	const setMappedEntityIds = (mappedEntityIds: string[]) => {
		floorplanDetail.mappedEntityIds = mappedEntityIds;
		setBookingLoader(false);
	};

	React.useEffect(() => {
		if (
			user.permissions &&
			user.permissions.Modules.find((m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8')
		) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create &&
					user.permissions.Modules[spacesIndex].Update &&
					user.permissions.Modules[spacesIndex].Delete
				) {
					setShowFloorEditor(1);
					setBookingLoader(true);
				}
			} else {
				setShowFloorEditor(0);
			}
		}
		spaces.EditorFlrData(route.params.floorId);
	}, [user.permissions]);

	React.useEffect(() => {
		const fetchFloorEntities = () => {
			spaces
				.fetchEntitiesZone(
					{ clientId: route.params.clientId, EntityId: route.params.floorId, isDescendants: 'descendants' },
					false,
					user.currentSiteId,
				)
				.then((list: IEntitie[]) => {
					if (user.currentSiteId !== '') {
						floorplanDetail.entitiesList = list;
						floorplanDetail.entitiesListByType =
							floorplanDetail &&
							floorplanDetail.entitiesList.filter(node => {
								return node.Type === entityType;
							});
					}
				});
			let newEntityValue: any = '';
			if (entityType == 'room') {
				newEntityValue = 1;
			} else if (entityType == 'desk') {
				newEntityValue = 0;
			} else {
				newEntityValue = 2;
			}
			spaces.florentities(route.params.floorId, newEntityValue).then(res => {
				setEntityData(res);
				console.log(entityData);
			});
		};
		fetchFloorEntities();
		setTimeout(() => {
			// const fetchFloorEntities = () => {
			// 	spaces
			// 		.fetchEntities(
			// 			{ clientId: route.params.clientId, EntityId: route.params.floorId, isDescendants: 'descendants' },
			// 			false,
			// 		)
			// 		.then((list: IEntitie[]) => {
			// 			floorplanDetail.entitiesList = list;
			// 			floorplanDetail.entitiesListByType = floorplanDetail.entitiesList.filter(node => {
			// 				return node.Type === entityType;
			// 			});
			// 		});
			// };

			if (route.params.clientId != 'NA' && route.params.floorId != 'NA') {
				setBookingLoader(true);
				FloorplanEditorCanvas.fetchFloorplanImage(
					spaces,
					route,
					entityType,
					showStatusForDeleteCoordinates,
					setMappedEntityIds,
				).then(() => {
					let canvasObj: fabric.Canvas = FloorplanEditorCanvas.getCanvas();
					setBookingLoader(false);
					canvasObj &&
						canvasObj.on('mouse:down', () => {
							floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
							let canvasActiveObject: IObject = canvasObj.getActiveObject()!;

							if (!floorplanDetail.controlType && canvasActiveObject && canvasActiveObject.type !== 'activeSelection') {
								floorplanDetail.propertyId = canvasActiveObject.id || '';
								floorplanDetail.showPropContainer = true;
							}
						});
					canvasObj &&
						canvasObj.on('mouse:up', () => {
							if (floorplanDetail.controlType) {
								floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
								floorplanDetail.controlType = FloorplanEditorCanvas.floorplanDetail.controlType;
							}
						});
					canvasObj &&
						canvasObj.on('object:modified', () => {
							floorplanDetail.isEntityTypeDisabled = true;
						});
				});
			}
		}, 3000);
	}, [route, spaces, floorplanDetail, entityType, user.currentSiteId]);

	FloorplanEditorCanvas.initDrawerOpen(appStores.drawerOpen);

	return useObserver(() => (
		<main className={classes.root}>
			<Backdrop className={classes.backdrop} open={bookingLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{showFloorEditor === 1 && appStores.showAppLoader === false ? (
				user && user.token ? (
					<div>
						{/* <div className={classes.appBarSpacer} />
				{showFloorEditor === 1 && appStores.showAppLoader === false ? (
			<div>	 */}
						{open && (
							<Box className={classes.statusMsg}>
								<Alert>
									<Typography variant="caption">
										{open === 'deleted' ? 'Deleted successfully!' : 'Floorplan coordinates saved successfully!'}
									</Typography>
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setStatusMessageOpen('');
										}}
									>
										<Icon icon={faTimesCircle} className={classes.closeIcon} />
									</IconButton>
								</Alert>
							</Box>
						)}
						<Grid container>
							<Grid item xl={12} lg={12} xs={12} md={9} container>
								<div
									id="canvasWrapper"
									className={appStores.drawerOpen ? classes.canvasWrapperDrawerOpen : classes.canvasWrapper}
								>
									<canvas id="floorplanCanvas"></canvas>
								</div>
							</Grid>
							<Grid item xs={12} md={3} container justify="flex-end" className={classes.dropBoxContainerDisplay}>
								<div
									className={classnames(
										classes.dropBoxContainer,
										floorplanDetail.showPropContainer ? classes.dropBoxContainerVisible : '',
									)}
								>
									<Typography variant="h5" component="h5">
										{entityType}s
									</Typography>
									<FormControl className={classes.dropBox}>
										<InputLabel>{entityType} Name</InputLabel>
										<Select
											value={floorplanDetail.propertyId}
											onChange={e => {
												if (e && e.target && e.target.value) {
													floorplanDetail.propertyId = e.target.value as string;
													const filteredProperty = floorplanDetail.entitiesListByType.filter(
														node => node.Entity_Id === e.target.value,
													);
													floorplanDetail.propertyName =
														filteredProperty[0] && filteredProperty[0].Name ? filteredProperty[0].Name : '';
												}
											}}
										>
											{spaces.floorentities &&
												spaces.floorentities.Zones.map((entitie: any) => {
													return (
														<MenuItem
															key={entitie.ZoneId}
															value={entitie.ZoneId}
															disabled={floorplanDetail.mappedEntityIds.includes(entitie.ZoneId)}
														>
															{entitie.ZoneName}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
									<Grid container justify="flex-end">
										<Button
											color="primary"
											onClick={() => {
												cancelLastDrawnInCanvas();
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											onClick={() => {
												setActiveCanvas({
													propertyId: floorplanDetail.propertyId,
													propertyName: floorplanDetail.propertyName,
													entityType: entityType,
													isSaved: false,
													setObjectType: floorplanDetail.controlType ? 'lastObject' : 'activeObject',
												} as IActiveCoordinates);
											}}
											disabled={floorplanDetail.propertyId === ''}
										>
											Create
										</Button>
									</Grid>
								</div>
							</Grid>
							<Grid
								item
								xs={12}
								md={3}
								container
								justify="space-between"
								alignItems="flex-end"
								className={classes.footerIconJustify}
							>
								<Tooltip
									title={
										floorplanDetail.isEntityTypeDisabled
											? 'Mapping action incomplete, Save or Discard changes to continue'
											: ''
									}
									placement="top"
								>
									<Tabs
										value={entityType}
										classes={{
											indicator: classes.indicator,
										}}
										textColor="primary"
										onChange={handleChange}
										aria-label="Select Entity Type"
										className={classnames(classes.footerTabs, classes.displayFixed)}
									>
										{spaces.flrInfoEditor &&
											spaces.flrInfoEditor.map((frldtt: any) => (
												<Tab
													label={frldtt.ZoneType}
													value={frldtt.ZoneType == 'Room' ? 'room' : frldtt.ZoneType == 'Desk' ? 'desk' : 'parking'}
													className={classes.rootItem}
													disabled={floorplanDetail.isEntityTypeDisabled}
												/>
											))}
									</Tabs>
								</Tooltip>
							</Grid>

							<Grid item xs={12} md={appStores.drawerOpen ? 5 : 6} container alignItems="center" justify="space-around">
								<Tooltip title={floorplanDetail.showPropContainer ? 'Mapping action incomplete' : ''} placement="top">
									<div className={classnames(classes.footerIcons, classes.displayFixed)}>
										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'zoomIn' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('zoomIn')}
										>
											<Icon icon={faSearchPlus} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Zoom In
											</Typography>
										</Box>

										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'zoomOut' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('zoomOut')}
										>
											<Icon icon={faSearchMinus} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Zoom Out
											</Typography>
										</Box>

										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'isPanMode' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('isPanMode')}
										>
											<Icon icon={faHandPaper} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Pan
											</Typography>
										</Box>
										<div className={classes.iconsDivider}>
											<Divider orientation="vertical" component="div" />
										</div>
										<Box
											className={classnames(
												classes.iconBoxDisabled,
												floorplanDetail.controlType === 'compass' ? classes.iconBoxSelected : '',
											)}
											onClick={() => {}}
										>
											<Icon icon={faCompass} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Orientation
											</Typography>
										</Box>

										<Box
											className={classnames(
												classes.iconBoxDisabled,
												floorplanDetail.controlType === 'ruler' ? classes.iconBoxSelected : '',
											)}
											onClick={() => {}}
										>
											<Icon icon={faRuler} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Scale
											</Typography>
										</Box>
										<div className={classes.iconsDivider}>
											<Divider orientation="vertical" component="div" />
										</div>
										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'rect' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('rect')}
										>
											<Icon icon={faDrawSquare} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Rectangle
											</Typography>
										</Box>

										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'polygon' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('polygon')}
										>
											<Icon icon={faDrawPolygon} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Polygon
											</Typography>
										</Box>

										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'circle' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('circle')}
										>
											<Icon icon={faDrawCircle} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Circle
											</Typography>
										</Box>
										<div className={classes.iconsDivider}>
											<Divider orientation="vertical" component="div" />
										</div>
										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'copy' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('copy')}
										>
											<Icon icon={faCopy} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Copy
											</Typography>
										</Box>
										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'delete' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('delete')}
										>
											<Icon icon={faEraser} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Delete
											</Typography>
										</Box>

										<Box
											className={classnames(
												floorplanDetail.showPropContainer ? classes.iconBoxDisabled : classes.iconBox,
												floorplanDetail.controlType === 'undo' ? classes.iconBoxSelected : '',
											)}
											onClick={() => enableEditorToDraw('undo')}
										>
											<Icon icon={faUndo} className={classes.footerIcon} size={SVGIcon.size} />
											<Typography variant="caption" display="block" className={classes.footerIconLabel}>
												Undo
											</Typography>
										</Box>
									</div>
								</Tooltip>
							</Grid>

							<Grid item xs={12} md={appStores.drawerOpen ? 4 : 3} container justify="flex-end" alignItems="flex-end">
								<div className={classnames(classes.footerIconJustify, classes.displayFixed)}>
									<div className={classnames(classes.footerBtn, classes.outlinedBtn)}>
										<Button
											variant="outlined"
											color="primary"
											onClick={() => {
												localStorage.removeItem('URL');
												localStorage.removeItem('CMValue');
												enableEditorToDraw('DiscardChanges');
											}}
											disabled={floorplanDetail.showPropContainer}
										>
											Discard Changes
										</Button>
									</div>
									<Button
										variant="contained"
										color="primary"
										className={classes.footerBtn}
										onClick={() => saveFloorplanCoordinates()}
										disabled={floorplanDetail.showPropContainer}
									>
										Save Changes
									</Button>
								</div>
							</Grid>
						</Grid>
						{/* ) : showFloorEditor === 0 && appStores.showAppLoader === false ? (
			<>
				<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
				<div className={classes.textMargin}>
					<Typography variant="subtitle2">You do not have permission to access this area</Typography>
					<Typography variant="caption">Please contact your manager to request this access</Typography>
				</div>
			</>
		) : (
			''
		)} */}
					</div>
				) : (
					''
				)
			) : showFloorEditor === 0 && appStores.showAppLoader === false ? (
				user && user.token ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)
			) : (
				''
			)}
		</main>
	));
};

export default Editor;
