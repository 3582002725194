import { action } from 'mobx';
import { postAPI } from 'services/apiService';
import { IFeedbackForm, IJobRequestForm } from '../types';

class Feedback {
	@action
	public async submitFeedBackForm(feedbackOptions: IFeedbackForm) {
		let postData = {
			EntityId: feedbackOptions.site,
			Category: null,
			CreatedDate: feedbackOptions.createdDate.toISOString(),
			DateTimeSubmitted: feedbackOptions.currentDate.toISOString(),
			Subject: feedbackOptions.subject,
			Details: feedbackOptions.details,
			DeviceModel: feedbackOptions.deviceModel,
			AppVersion: feedbackOptions.osversion,
			UploadToken: feedbackOptions.token ? feedbackOptions.token : null,
			SiteId: null,
			RequestType: 'Suggestion',
			Building: feedbackOptions.Building,
		};
		return await postAPI('/feedback/form', postData);
	}

	@action
	public async submitJobRequestForm(feedbackOptions: IJobRequestForm) {
		let postData = {
			EntityId: feedbackOptions.site,
			Criticality: feedbackOptions.criticallity,
			Location: feedbackOptions.location ? feedbackOptions.location : null,
			Category: feedbackOptions.category,
			CreatedDate: feedbackOptions.createdDate.toISOString(),
			DateTimeSubmitted: feedbackOptions.currentDate.toISOString(),
			Description: feedbackOptions.issue,
			DeviceModel: null,
			AppVersion: null,
			UploadToken: feedbackOptions.token ? feedbackOptions.token : null,
			SiteId: null,
			RequestType: 'Issue',
			Building: feedbackOptions.building,
			Emailid: feedbackOptions.email,
		};
		return await postAPI('/feedback/jobrequestform', postData);
	}

	@action
	public async FetchFormData(feedbackOptions: IJobRequestForm) {
		//console.log('store',feedbackOptions);
		let postData = {
			Siteid: feedbackOptions.site,
			email: feedbackOptions.email,
		};
		return await postAPI('/user/fetchdetails', postData);
	}
}

export default Feedback;
