import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import fieldSchema from '../Validation/schemaValidation';
import { useForm } from 'react-hook-form';
import {
	makeStyles,
	Container,
	DialogActions,
	Dialog,
	Button,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Typography,
	Switch,
	Box,
	Tooltip,
	Link,
	Paper,
	FormHelperText,
	FormControl,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { autorun } from 'mobx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ISiteObject } from '../types';
const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '130% !important',
			paddingRight: '3rem !important',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-root': {
			width: '130% !important',
		},
	},
	appBarSpacer: {
		height: '64px',
	},
	paper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		width: '380px',
		'@media (min-width: 0px) and (max-width: 680px)': {
			width: '100% !important',
		},

		margin: theme.spacing(0),
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},

	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		display: 'flex',
	},
	profilecard: {
		height: 'calc(57vh)',
		width: '34%',
		'@media (min-width: 1100px) and (max-width: 1500px)': {
			width: '34% !important',
		},
		'@media (min-width: 850px) and (max-width: 1099px)': {
			width: '34% !important',
			height: 'auto',
		},
		'@media (min-width: 501px) and (max-width: 849px)': {
			width: '34% !important',
			height: 'auto',
		},
		'@media (min-width: 0px) and (max-width: 500px)': {
			width: '100% !important',
			height: 'auto',
		},
	},
	smallIcon: {
		fontSize: '1rem',
	},
	actionLink: {
		margin: theme.spacing(1),
		'&:hover': {
			textDecoration: 'none',
		},
	},
	inactiveLink: {
		pointerEvents: 'none',
		color: 'rgba(0, 0, 0, 0.26)',
		cursor: 'default',
	},
	helpIcon: {
		color: theme.palette.grey[50],
		fontSize: '1.5em',
		marginTop: 12,
		paddingLeft: 4,
	},
	tooltip: {
		width: 82,
		padding: 2,
		textAlign: 'center',
	},
	typoText: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing(2, 0),
	},
	formControlLabel: {
		'& .MuiFilledInput-underline:after': {
			borderColor: '#bd0810',
		},
		'& .MuiFilledInput-underline:before': {
			borderColor: '#bd0810',
		},
		'& .MuiIconButton-label': {
			color: '#bd0810',
		},
		'& .makeStyles-Autocomplete-11 .MuiInputLabel-root': {
			color: '#bd0810',
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
			color: '#bd0810',
		},
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	errorInputText: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#BD0810',
		},
		'&.MuiInputBase-input:focus': {
			color: '#BD0810',
		},
		'&.MuiInputLabel-filled': {
			color: '#BD0810',
		},
		'&.MuiFormLabel-root.Mui-focused': {
			color: '#BD0810',
		},
		'&.MuiFormHelperText-root': {
			color: '#BD0810',
		},
		'&.MuiFormLabel-root': {
			color: '#BD0810',
		},
		'&.MuiFormLabel-root.Mui-error': {
			color: '#BD0810',
		},
		'&.MuiFilledInput-underline.Mui-error': {
			color: '#BD0810',
		},
	},
}));
interface FormData {
	telephone: string;
}
const Spaces = observer(() => {
	const user = React.useContext(stores.context.user);
	const sites: any = React.useContext(stores.context.site);
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const [showLocation, setShowLocation] = React.useState(true);
	const [siteLocation, setSiteLocation] = React.useState<any>();
	const notifications = React.useContext(stores.context.notifications);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [siteVal, setSite] = React.useState('');
	const [siteKey, setSiteKey] = React.useState(0);
	const [phoneKey, setPhoneeKey] = React.useState(0);
	const siteNamenew: any = df.findIndex((nam: any) => nam.Id == siteVal);
	const [siteNewLocation, setSiteNewLocation] = React.useState<any>();
	const [siteError, setSiteError] = React.useState(false);
	const [telephone, setTelephone] = React.useState('');
	const [isFormValid, setIsFormValid] = React.useState<boolean>();

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const methods = useForm<FormData>({
		validationSchema: fieldSchema,

		mode: 'onChange',
	});

	const { register, errors } = methods;
	React.useEffect(() => {
		setTelephone(user && user.userPhoneNumber ? user.userPhoneNumber : '');
		setPhoneeKey(phoneKey + 1);
	}, [user, user.userPhoneNumber, user.emailPreference]);
	// Delete account functionality removed
	// const handleDeleteAccount = async () => {
	// 	setEsmeDialogOpen(false);
	// 	const response = await user.closeAccount();
	// 	if (response && response.Success) {
	// 		notifications.addNotification('Account Deleted Successfully', 'success');
	// 		user.logout();
	// 	} else {
	// 		notifications.addNotification('Something went wrong, Please try again', 'error');
	// 	}
	// };

	const handleInputChange = (event: any) => {
		setTelephone(event.target.value);
		setIsFormValid(false);
		if (!errors.telephone && telephone.length > 0 && telephone.length !== 13) {
			setIsFormValid(true);
		}
	};

	React.useEffect(
		() =>
			autorun(() => {
				const inactiveLocations = sites.siteList.filter((item: any) => item.LocationStatus === 'INACTIVE');
				if (inactiveLocations.find((item: any) => item.Id === user.currentSiteId)) {
					setSiteError(true);
				}
				if (user.currentSiteId) {
					setSiteLocation(df[siteName]);
					setSiteNewLocation(df[siteNamenew]);
					setSiteKey(siteKey + 1);
				}
			}),
		[user.currentSiteId, , sites.siteList],
	);

	const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowLocation(event.target.checked);
	};
	const updateUserSite = async () => {
		const telephoneNumber = user && user.userPhoneNumber ? user.userPhoneNumber : '';
		if (telephoneNumber === telephone) {
			const response = await user.updateUserSite(siteVal);
			if (!response) {
				notifications.addNotification('Your default location is updated', 'success');
				window.location.reload();
			} else {
				notifications.addNotification('Something went wrong, Please try again', 'error');
			}
		}
		if (telephoneNumber !== telephone && user.currentSiteId !== siteVal && siteVal !== '') {
			const SaveInfo = {
				emailPreference: user && user.emailPreference ? true : false,
				phoneNumber: telephone,
			};
			const siteResponse = await user.updateUserSite(siteVal);
			const response = await user.updateuserDetails(SaveInfo);
			if (!siteResponse && response && response.Success) {
				notifications.addNotification('Your details are updated', 'success');
				window.location.reload();
			} else {
				notifications.addNotification('Something went wrong, Please try again', 'error');
				setTelephone(user && user.userPhoneNumber ? user.userPhoneNumber : '');
			}
		} else {
			const SaveInfo = {
				emailPreference: user && user.emailPreference ? true : false,
				phoneNumber: telephone,
			};
			user.updateuserDetails(SaveInfo).then(response => {
				if (response && response.Success) {
					notifications.addNotification('Your telephone number is updated', 'success');
				} else {
					notifications.addNotification('Something went wrong, Please try again', 'error');
					setTelephone(user && user.userPhoneNumber ? user.userPhoneNumber : '');
				}
			});
		}
	};

	const classes = useStyles();

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{user && user.token ? (
					<Container className={classes.container}>
						<Paper className={classes.paper}>
							<Box m={2}>
								<Box mb={4}>
									<Typography variant="h5">Profile</Typography>
								</Box>
								<Typography className={classes.typoText}>{user.displayName}</Typography>
								<Typography
									style={{ wordWrap: 'break-word', justifyContent: 'space-between', margin: 'theme.spacing(2, 0)' }}
								>
									{user.username}
								</Typography>

								<Box display="flex" flexDirection="row">
									<div key={phoneKey} style={{ width: '92%', marginTop: '16px' }}>
										<TextField
											type="text"
											label="Telephone"
											name="telephone"
											variant="filled"
											id="filled-telephone-input-required"
											autoComplete="Telephone"
											fullWidth
											defaultValue={telephone}
											inputRef={e => {
												register(e);
											}}
											error={errors.telephone ? true : false}
											helperText={errors.telephone ? errors.telephone.message : ''}
											FormHelperTextProps={{ className: classes.errorInputText }}
											onChange={handleInputChange}
										/>
									</div>
									<Box my={1} flexBasis="8%">
										<Tooltip
											title={
												<div className={classes.tooltip}>
													Update your business number including +44 etc, dialing code
												</div>
											}
											placement="bottom"
										>
											<HelpIcon className={classes.helpIcon} />
										</Tooltip>
									</Box>
								</Box>

								<Box display="flex" flexDirection="row" style={{ display: 'none' }}>
									<Box my={1} flexBasis="40%">
										<Typography>My Location</Typography>
									</Box>
									<Box flexBasis="20%">
										<Switch
											checked={showLocation}
											onChange={handleChange('checkedB')}
											value="showLocation"
											color="primary"
											inputProps={{ 'aria-label': 'primary checkbox' }}
										/>
									</Box>
									<Box my={1} flexBasis="20%">
										<Tooltip
											title={<div className={classes.tooltip}>Turn on my location to see Aria feed content</div>}
											placement="bottom"
										>
											<HelpIcon className={classes.helpIcon} />
										</Tooltip>
									</Box>
									<Box flexBasis="20%"></Box>
								</Box>
								<Box my={1} display="flex" flexDirection="row">
									<FormControl
										style={{ width: '100%' }}
										className={siteError === true ? classes.formControlLabel : ''}
										error={siteError}
									>
										<Box my={1} flexBasis="60%" style={{ marginTop: '0px' }}>
											<div key={siteKey}>
												<Autocomplete
													options={(sitedatanew ? df : sites.siteList).filter(
														(item: any) => item.LocationStatus !== 'INACTIVE',
													)}
													getOptionLabel={option => option.Name}
													size="small"
													className={classes.Autocomplete}
													id="size-small-standard"
													noOptionsText="Loading..."
													defaultValue={sitedatanew ? df[siteName] : siteLocation}
													value={siteNewLocation}
													autoComplete
													onChange={(event: any, newValue: any) => {
														const inactiveLocations = sites.siteList.filter(
															(item: any) => item.LocationStatus === 'INACTIVE',
														);
														if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
															setSiteError(true);
														} else {
															setSiteError(false);
															setIsFormValid(true);
														}
														if (newValue.Id === null) {
															setSite(user.currentSiteId);
														} else {
															setSite(newValue.Id);
														}
													}}
													renderInput={params => (
														<TextField {...params} label="Default Site" variant="filled" margin="normal" />
													)}
												/>
											</div>
										</Box>
										{siteError === true ? (
											<FormHelperText style={{ width: 'max-content', marginTop: '0px', lineHeight: '0px' }}>
												This site is inactive, please select another site
											</FormHelperText>
										) : (
											''
										)}
									</FormControl>
									<Box my={1} flexBasis="20%">
										<Tooltip
											title={<div className={classes.tooltip}>Select your default work location</div>}
											placement="bottom"
										>
											<HelpIcon className={classes.helpIcon} />
										</Tooltip>
									</Box>
									<Box flexBasis="20%"></Box>
								</Box>
								<Box textAlign="right">
									{/* <Link
									component="button"
									onClick={() => {
										setEsmeDialogOpen(true);
									}}
									className={classes.actionLink}
								>
									Delete account
								</Link> */}
									<Link
										component="button"
										className={!isFormValid || siteError === true ? classes.inactiveLink : classes.actionLink}
										disabled={!isFormValid || siteError === true ? true : false}
										onClick={updateUserSite}
									>
										Save changes
									</Link>
								</Box>
							</Box>
						</Paper>
					</Container>
				) : (
					''
				)}
			</main>
			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title">Are you sure you want to delete your account</DialogTitle>
					<DialogContentText>You will loose access to the aria web and mobile apps and all data.</DialogContentText>
					<DialogActions>
						<Button onClick={handleClickClose} color="primary">
							Cancel
						</Button>
						{/* <Button onClick={handleDeleteAccount} color="primary">
							Ok
						</Button> */}
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default Spaces;
