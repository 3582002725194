import * as React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import CancelIcon from '@material-ui/icons/Cancel';
import {
	makeStyles,
	Grid,
	Paper,
	Typography,
	Link,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	TextField,
	Breadcrumbs,
	IconButton,
	Backdrop,
	CircularProgress,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import noResults from '../assets/images/NoRoomPhoto@2x.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { stores } from '../store';
import Box from '@material-ui/core/Box';

import { ListItem, List } from '@material-ui/core';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
	disableButton: {
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},
	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 98px)',
		margin: '0 !important',
	},
	attendeesText: {
		width: '250px',
		marginTop: '-1%',
		'& .MuiFilledInput-root': { backgroundColor: 'white' },
		'& .MuiFilledInput-input': { borderRadius: '5px' },
		'& .MuiFilledInput-input:focus': { background: '#E4ECF3' },
	},
	headerMenuLinkDefault: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		padding: '13px',
		paddingTop: theme.spacing(2),
	},
	headerMenuLinkActive: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		fontWeight: 'bold',
		padding: '13px',
		paddingTop: theme.spacing(2),
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},

	headerNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '100px !important',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	imageBlock: {
		width: '100%',
		height: '85%',
	},
	paper: {
		display: 'flex',
		overflow: 'auto',
		height: '100%',
		flexDirection: 'column',
		textAlign: 'left',
		'& .MuiInputBase-input': {},
	},

	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 0),
	},
	fileUploadInputError: {
		borderBottom: `${theme.palette.error.main} 1px solid`,
		color: theme.palette.error.main,
	},
	createCancelLink: {
		'& > *': {
			cursor: 'pointer',
			margin: theme.spacing(0, 0, 0, 2),
		},
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	accessDimensions: {
		width: '70%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},

	alignStyle: {
		padding: '0px 0px !important',
	},
	feedBadge: {
		'& span': {
			backgroundColor: '#357a38',
			padding: 'inherit',
			fontSize: '0.7rem',
			marginRight: '32px',
			transform: 'scale(1) translate(100%, -60%)',
		},
	},
	testnew: {
		'& .slick-prev, .slick-next': {
			height: '35px',
			width: '37px',
			background: 'grey',
		},
		'& .slick-slide, .slick-active': {
			width: '68px !important',
		},
		'& .slick-list': {
			marginLeft: '19px',
			marginRight: '21px',
		},
	},

	tagItem: {
		backgroundColor: '#098BBD14',
		fontSize: '14px',
		borderRadius: '30px',
		height: '30px',
		padding: '5px 8px 7px 1rem',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		margin: '13px 0.3rem 0.3rem 0',
	},

	lsttext: {
		marginTop: '-10px',
		'& .MuiListItemText-root': {
			marginTop: '-10px',
			marginBottom: '1px',
		},
	},

	actionIconButton: {
		marginTop: '-0.7%',
		width: 50,
		height: 45,
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
		'& .svg-inline--fa': {
			height: '1.5em !important',
		},
	},
}));

const AddAttendees = observer(() => {
	const classes = useStyles();

	const appStores = React.useContext(stores.context.appStores);
	const user = React.useContext(stores.context.user);
	appStores.showAppLoader = user.permissions === null ? true : false;

	const [showFloorInfo, setShowFloorInfo] = React.useState(0);
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const { route } = React.useContext(stores.context.routes);
	const spaces = React.useContext(stores.context.spaces);
	const [bookData, setBookData] = React.useState<any>('');

	const [existattend, setexist] = React.useState<any>('');
	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const [open, setOpen] = React.useState(false);
	const [zoneSingleRecord, setZoneSingleRecord] = React.useState<any>();

	const handleClickOpen = () => {
		setEsmeDialogOpen(false);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [openFinal, setDialogFinal] = React.useState(false);

	const handleClickFinalOpen = () => {
		setDialogFinal(true);
		setOpen(false);
	};
	const handleClickfinal = () => {
		setDialogFinal(false);
	};

	const [showAttendees, setshowAttendees] = React.useState<any>();
	const floordata = user.floorsingledata;
	const [bookingLoader, setBookingLoader] = React.useState(false);

	const [atndlist, setAtendlist] = React.useState<any>(0);

	const [sendbtnstate, setSendBtnState] = React.useState(false);

	const [showAttendeesnew, setshowAttendeesnew] = React.useState<any>([]);
	const [errmsgnew, setError] = React.useState(true);

	React.useEffect(() => {
		if (user.currentSiteId) {
			spaces.fetchCondecoDetails(user.currentSiteId);
		}

		const ExistingAttendees = () => {
			spaces.ExistingAttendees(route.params.BookingId).then((res: any) => {
				setexist(res.attendees);
			});
		};

		ExistingAttendees();
	}, [user.currentSiteId, user.permissions, atndlist]);

	const chngValue = (e: any) => {
		e.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setshowAttendees(e.target.value);

		const validEmail = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

		let userInput: any = e.target.value;

		let flag: any = userInput.match(validEmail) && userInput.split('@')[1].includes('.') ? false : true;
		if (flag == true) {
			setError(true);
		} else {
			setError(false);
		}
	};

	React.useEffect(() => {
		if (user.currentSiteId !== '' && spaces.condecoDetails) {
			if (spaces.condecoDetails && spaces.condecoDetails.ISEMSAvailable) {
				setBookingLoader(false);
			} else {
				setBookingLoader(true);
				window.location.href = `/spaces/spacesHome/NA/NA`;
			}
		}
	}, [spaces.condecoDetails, user.currentSiteId, user.floorsingledata]);

	const chngValue2 = () => {
		setshowAttendeesnew([...showAttendeesnew, showAttendees]);
		setshowAttendees('');
		setError(true);

		setSendBtnState(false);
	};

	const deleteItem = (val: any) => {
		setshowAttendeesnew(showAttendeesnew.filter((item: any) => item !== val));
	};
	const [invitation, setOpennew] = React.useState(false);

	const handleAvailableclose = () => {
		setOpennew(false);
	};

	const sendInvitation = () => {
		localStorage.removeItem('CMValue');
		setBookingLoader(true);
		spaces.sendInvitation(showAttendeesnew, route.params.BookingId).then((res: any) => {
			if (res) {
				setOpennew(true);
				setBookingLoader(false);

				setSendBtnState(true);
				setAtendlist(atndlist + 1);
				setshowAttendeesnew([]);
			}
		});
	};

	React.useEffect(() => {
		if (
			user.permissions &&
			user.permissions.Modules.find((m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8')
		) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create ||
					user.permissions.Modules[spacesIndex].Update ||
					user.permissions.Modules[spacesIndex].Delete
				) {
					setShowFloorInfo(1);
				}
			} else {
				setShowFloorInfo(0);
			}
		}

		const fetchZoneData = () => {
			const test = 'b72bae26-23df-11e9-ab14-d663bd873d93';
			spaces.fetchZoneDetails(test, route.params.floorId).then((res: any) => {
				const Zonelist = res.Zones;

				const ZoneData = Zonelist.findIndex((Zonerecord: any) => {
					return Zonerecord.ZoneId === route.params.zoneId;
				});

				setZoneSingleRecord(Zonelist[ZoneData]);
			});
		};

		fetchZoneData();
	}, [user.permissions]);
	React.useEffect(() => {
		const fetchBookList1 = () => {
			spaces.BookingList().then(res => {
				if (res !== null) {
					const BookDtfil = res.filter((item: any) => item.id == route.params.BookingId);
					setBookData(BookDtfil[0]);
				}
			});
		};
		fetchBookList1();
	}, []);

	const routes = React.useContext(stores.context.routes);
	const [dispmenu, setdisplayMen] = React.useState(0);

	const menuChng1 = () => {
		setdisplayMen(0);
	};
	return useObserver(() => (
		<>
			<main className={classes.container}>
				<Backdrop className={classes.backdrop} open={bookingLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>
				{(showFloorInfo === 1 || showFloorInfo === 0) && appStores.showAppLoader === false ? (
					<Grid container direction="row" spacing={1}>
						<Grid item xs={12} md={7}>
							<Paper className={classes.paper}>
								<Grid style={{ minHeight: '480px', padding: '10px', lineHeight: '10px' }}>
									<div key={atndlist}>
										<Grid container item xs={12} justify="flex-start">
											<Typography variant="h6" paragraph={true}>
												Add meeting attendees
											</Typography>
										</Grid>

										<TextField
											name="emaildata"
											label="Email"
											variant="filled"
											size="small"
											margin="dense"
											onChange={chngValue}
											fullWidth
											value={showAttendees || ''}
											className={classes.attendeesText}
										/>
										<IconButton
											aria-label="Add"
											color="inherit"
											size="small"
											onClick={chngValue2}
											className={classes.actionIconButton}
											disabled={errmsgnew}
										>
											{errmsgnew ? (
												<AddBoxIcon style={{ fontSize: 50, color: '#cfdbe5' }} />
											) : (
												<AddBoxIcon style={{ fontSize: 50 }} color="primary" />
											)}
										</IconButton>

										{existattend &&
											existattend.map((list: any) => (
												<>
													<div className={classes.tagItem} key={list}>
														{list.email}
														<CancelIcon
															color="disabled"
															fontSize="small"
															style={{
																float: 'right',
																cursor: 'pointer',
																marginRight: '0',
																marginLeft: '8px',
															}}
														/>
													</div>
												</>
											))}
										{showAttendeesnew.map((item: any) => (
											<div
												className={
													(sendbtnstate === false && errmsgnew === false) || errmsgnew === true ? classes.tagItem : ''
												}
												key={item}
												style={{
													display:
														(sendbtnstate === false && errmsgnew === false) || errmsgnew === true ? 'flex' : 'none',
												}}
											>
												{item}
												<CancelIcon
													color="action"
													fontSize="small"
													onClick={() => {
														deleteItem(item);
													}}
													style={{
														float: 'right',
														cursor: 'pointer',
														marginRight: '0',
														marginLeft: '8px',
													}}
												/>
											</div>
										))}
									</div>
								</Grid>

								<Grid item xs={12} container alignItems="center" style={{ padding: '10px' }}>
									<Box alignContent="flex-start" flexGrow={1} display="flex" flexWrap="wrap"></Box>

									<Box alignContent="flex-end" display="flex" flexWrap="wrap">
										<Button
											variant="outlined"
											color="primary"
											onClick={() => {
												localStorage.removeItem('CMValue');
												routes.navigate(
													{
														name: 'spacesHome',
														params: {
															clientId: floordata.clientId,
															floorId: floordata.floorId,
														},
													},
													{
														clearParams: true,
													},
												);
											}}
										>
											Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;
										<Button
											variant="contained"
											color="primary"
											onClick={sendInvitation}
											disabled={showAttendeesnew.length === 0 || sendbtnstate === true ? true : false}
											style={{ color: '#FFFFFF' }}
											className={classes.disableButton}
										>
											Send invites
										</Button>
									</Box>
								</Grid>
							</Paper>
						</Grid>

						<Grid item xs={12} md={5}>
							<Paper className={classes.paper} style={{ height: '63.3%' }}>
								<Grid style={{ overflowY: 'hidden', height: '100%' }}>
									<Grid container item xs={12} justify="flex-start" style={{ height: '100%' }}>
										<Breadcrumbs
											aria-label="breadcrumb"
											separator=""
											color="textSecondary"
											className={classes.headerNav}
										>
											<Link
												color="inherit"
												underline="none"
												style={{ cursor: 'pointer' }}
												onClick={menuChng1}
												className={dispmenu == 0 ? classes.headerMenuLinkActive : ''}
											>
												Photo
											</Link>
										</Breadcrumbs>
										<hr style={{ opacity: '.2', width: '100%', marginTop: '0px', marginBottom: '-2px' }} />
										{dispmenu == 0 && zoneSingleRecord && zoneSingleRecord.ZoneImageSource ? (
											<>
												<div style={{ height: '100%' }}>
													<img
														className={classes.imageBlock}
														src={require(`../assets/images/spaces/${zoneSingleRecord.ZoneImageSource}`)}
														alt="SitePhoto"
													/>
												</div>
											</>
										) : (
											<div style={{ height: '100%', margin: 'auto', padding: '55px' }}>
												<img src={noResults} className={classes.accessDimensions} alt="underconstruction" />

												<Typography variant="subtitle2" style={{ display: 'flex', justifyContent: 'center' }}>
													No room photo available
												</Typography>
												<Typography
													color="textSecondary"
													variant="caption"
													style={{ display: 'flex', justifyContent: 'center' }}
												>
													Uploaded images will appear here
												</Typography>
											</div>
										)}
									</Grid>
								</Grid>
							</Paper>

							<Paper style={{ height: '36%' }}>
								<Grid>
									<List style={{ marginTop: '4px' }}>
										<ListItem className={classes.lsttext}>
											<Grid item xs={12}>
												<Grid container>
													<Grid item xs={3}>
														<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Subject :</span>{' '}
													</Grid>
													<Grid item xs={9}>
														{route.params.subjectTitle}
													</Grid>
												</Grid>
											</Grid>
										</ListItem>
										{bookData ? (
											<>
												<ListItem className={classes.lsttext}>
													<Grid item xs={12}>
														<Grid container>
															<Grid item xs={3}>
																<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Site :</span>{' '}
															</Grid>
															<Grid item xs={9}>
																{bookData.room.building.description}
															</Grid>
														</Grid>
													</Grid>
												</ListItem>

												<ListItem className={classes.lsttext}>
													<Grid item xs={12}>
														<Grid container>
															<Grid item xs={3}>
																<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Room :</span>{' '}
															</Grid>
															<Grid item xs={9}>
																{bookData.room.code} , {bookData.room.floor.description}
															</Grid>
														</Grid>
													</Grid>
												</ListItem>
												<ListItem className={classes.lsttext}>
													<Grid item xs={12}>
														<Grid container>
															<Grid item xs={3}>
																<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Date :</span>{' '}
															</Grid>
															<Grid item xs={9}>
																{moment(bookData.eventStartTime).format('dddd DD/MM/YYYY')}
															</Grid>
														</Grid>
													</Grid>
												</ListItem>
												<ListItem className={classes.lsttext}>
													<Grid item xs={12}>
														<Grid container>
															<Grid item xs={3}>
																<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Time :</span>{' '}
															</Grid>
															<Grid item xs={9}>
																{`${moment(bookData.reserveStartTime).format('HH:mm')} - ${moment(
																	bookData.reserveEndTime,
																).format('HH:mm')} `}
															</Grid>
														</Grid>
													</Grid>
												</ListItem>
											</>
										) : (
											''
										)}
									</List>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : (
					''
				)}

				<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
					<DialogContent>
						<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
							Meeting Room 11 selected
						</DialogTitle>
						<DialogContentText>
							Please confirm your booking of Meeting Room 11 for 15:00 - 15:45 on Wed 24/03/21.
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickClose} color="primary">
								Cancel
							</Button>
							<Button onClick={handleClickOpen} color="primary">
								confirm
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Meeting Subject</DialogTitle>
					<DialogContent>
						<DialogContentText>Please enter a short title for your meeting subject.</DialogContentText>
						<TextField autoFocus margin="dense" id="name" label="Meeting Subject" type="text" fullWidth />
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={handleClickFinalOpen} color="primary">
							Save
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={openFinal} onClose={handleClickfinal}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px', maxHeight: '175px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting invitation
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							Invitation sent successfully
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickfinal} color="primary">
								Close
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickfinal} color="primary">
								Add attendees
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={invitation} onClose={handleAvailableclose}>
					<DialogContent className={classes.alignStyle} style={{ width: '328px', height: '175px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting invitation
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '30px' }}>
							Invitation sent successfully
						</DialogContentText>
						<DialogActions>
							<Button style={{ paddingRight: '8px' }} onClick={handleAvailableclose} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</main>
		</>
	));
});

export default AddAttendees;
