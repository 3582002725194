import React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
//import { toJS } from 'mobx';
import { useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Divider, Typography, Paper, TextField, makeStyles } from '@material-ui/core';
//import { SelectTreeDataNode, SelectTree } from '@mitie/material-ui-extensions';
import Button from '@material-ui/core/Button';
import { string as yupString, object as yupObject } from 'yup';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { IDelivery, Category, ISiteObject } from '../../types';
import { stores } from '../../store';

const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			// marginLeft: '13px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			// marginLeft: '12px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '60%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
		width: '100%',
	},
	dateStyle: {
		background: 'none',
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(4),
	},
	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 0),
	},
	fileUploadInputError: {
		borderBottom: `${theme.palette.error.main} 1px solid`,
		color: theme.palette.error.main,
	},
	keyboardDateTime: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		textAlign: 'left',
		width: '100%',
	},
	formControl: {
		paddingTop: theme.spacing(1),
		width: '100%',
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
		// width: '40%',
		// height: '40%',
		// overflow: 'inherit',
		// marginLeft: 'auto',
		// marginRight: 'auto',
	},
	textMargin: {
		textAlign: 'center',
	},
}));

const fieldSchema = yupObject().shape({
	recipientEmail: yupString()
		.required('Recipient name is required')
		.email('Please enter a valid email'),
	// siteselection: yupString().required('Sender Name is required'),
	senderName: yupString().required('Sender Name is required'),
	parcelNumber: yupString().required('Parcel Number is required'),
	collectionPlace: yupString().required('Collection is required'),
	respondEmail: yupString()
		.required('Respond mail is required')
		.email('Please enter a valid email'),
});

const Delivery: React.FunctionComponent = observer(() => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	const [siteVal, setSite] = React.useState('');

	const [showDeliveryForm, setShowDeliveryForm] = React.useState(0);
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.permissions === null ? true : false;
	//console.log('delivery',localStorage.getItem('sitedata'));
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [siteKey, setSiteKey] = React.useState(0);
	//console.log('sitelist',df);
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowDeliveryForm(1);
				}
			} else {
				setShowDeliveryForm(0);
			}
		} else {
			setShowDeliveryForm(0);
		}
	}, [user.permissions]);
	const [values, setValues] = React.useState({
		recipientEmail: '',
		senderName: '',
		parcelNumber: '',
		site: '',
		collectionPlace: '',
		respondEmail: '',
		Category: Category.ParcelDelivery,
		UploadToken: null,
	});
	const methods = useForm<IDelivery>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, reset, formState } = methods;
	// React.useEffect(() => {
	// 	if (sites.siteList.length) {
	// 		const list = toJS(sites.siteList).map(item => ({
	// 			key: item.Id,
	// 			title: item.Name,
	// 			selectable: true,
	// 		}));
	// 		setSiteData(list);
	// 		if (user.currentSiteId) {
	// 			setLocation(list.find(node => node.key === user.currentSiteId));
	// 		}
	// 	}
	// }, [user.currentSiteId, sites.siteList]);

	// React.useEffect(() => {
	// 	const filteredSiteData = siteData.filter((node: any) => {
	// 		return filterSite && node && node.title ? node.title.toLowerCase().includes(filterSite.toLowerCase()) : true;
	// 	});
	// 	setFilteredSiteData(filteredSiteData);
	// }, [filterSite, siteData]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};
	const submitIncidentForm = async () => {
		const { Category, UploadToken, recipientEmail, senderName, parcelNumber, collectionPlace, respondEmail } = values;
		const deliveryForm = { Category: Category, UploadToken: UploadToken, Parameters: {} };
		localStorage.removeItem('CMValue');
		deliveryForm.Category = Category;
		deliveryForm.UploadToken = UploadToken;
		deliveryForm.Parameters = {
			SiteId: [siteVal],
			UserEmail: recipientEmail,
			SenderName: senderName,
			ParcelNumber: parcelNumber,
			CollectionPlace: collectionPlace,
			RespondToEmail: respondEmail,
		};
		const response = await contentManagement.saveContentManagementValues([deliveryForm], '');
		if (response.Success === true) {
			notifications.addNotification('Delivery notification sent', 'success');
			reset();
			setSiteKey(siteKey + 1);
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};
	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{showDeliveryForm === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item md={4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									Delivery
								</Typography>
								<Divider variant="fullWidth" />
								<form>
									<Grid className={classes.gridStyle}>
										{/* <SelectTree
											label="Site"
											filter={filterSite}
											onFilterChange={setFilterSite}
											treeData={filteredSiteData}
											selectedNode={location}
											checkable={false}
											onSelect={node => {
												setLocation(node);
											}}
										/> */}
										<div key={siteKey}>
											<Autocomplete
												options={(sitedatanew ? df : sites.siteList).filter(
													(item: any) => item.LocationStatus !== 'INACTIVE',
												)}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												onChange={(event: any, newValue: any) => {
													newValue !== ''
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
													setSite(newValue.Id);
													const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
														item => item.LocationStatus === 'INACTIVE',
													);
													if (inactiveLocations.find(item => item.Id === newValue.Id)) {
														//setHasError(true);
													} else {
														//setHasError(false);
													}
												}}
												renderInput={params => (
													<TextField {...params} label="Select Site" variant="filled" margin="normal" />
												)}
											/>

											{/* <Autocomplete
												options={sitedatanew ? df : sites.siteList}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												onChange={(event: any, newValue: any) => {
													newValue !== ''
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
													setSite(newValue.Id);
												}}
												renderInput={params => (
													<TextField {...params} label="Select Site" variant="filled" margin="normal" />
												)}
											/> */}
										</div>
										<TextField
											name="recipientEmail"
											label="Recipient email"
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											fullWidth
											error={Boolean(errors.recipientEmail)}
											helperText={errors.recipientEmail ? errors.recipientEmail.message : ''}
											inputRef={e => {
												register(e);
											}}
										/>
										<TextField
											name="senderName"
											label="Sender name"
											fullWidth
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											error={Boolean(errors.senderName)}
											helperText={errors.senderName ? errors.senderName.message : ''}
											inputRef={e => {
												register(e);
											}}
										/>
										<TextField
											name="parcelNumber"
											label="Parcel number"
											fullWidth
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.parcelNumber ? errors.parcelNumber.message : ''}
											error={Boolean(errors.parcelNumber)}
										/>
										<TextField
											name="collectionPlace"
											label="Collection place"
											fullWidth
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.collectionPlace ? errors.collectionPlace.message : ''}
											error={Boolean(errors.collectionPlace)}
										/>
										<TextField
											name="respondEmail"
											label="Respond to email"
											fullWidth
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.respondEmail ? errors.respondEmail.message : ''}
											error={Boolean(errors.respondEmail)}
										/>
									</Grid>
									<Grid item xs={12} container justify="flex-end" alignItems="center">
										<Button
											color="primary"
											disabled={!formState.isValid || siteVal === ''}
											onClick={() => {
												localStorage.removeItem('CMValue');
												reset();
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											disabled={!formState.isValid || siteVal === ''}
											onClick={submitIncidentForm}
										>
											Send
										</Button>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
				) : showDeliveryForm === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
			</main>
		</>
	));
});

export default Delivery;
