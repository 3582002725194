import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import { makeStyles, Container } from '@material-ui/core';
import WelcomeUser from '../components/WelcomeUser';
import UserLogin from '../components/UserLogin';
import PermanentAppBar from 'components/Header/PermanentAppBar';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
	},
	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 70px)',
		display: 'flex',
	},
}));

const Home = observer(() => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.isUserLoggedIn === null ? true : false;
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content} style={{ overflow: 'hidden' }}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container}>
					{user.isUserLoggedIn ? <WelcomeUser /> : user.isUserLoggedIn === false ? <UserLogin /> : <></>}
				</Container>
			</main>
		</>
	);
});

export default Home;
