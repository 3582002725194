import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { stores } from '../store';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '64px',
		top: '64px',
		alignItems: 'center',
		margin: 0,
		backgroundColor: '#313C45',
		opacity: 1,
		fontFamily: 'Roboto',
	},

	btn1: {
		color: '#66ccfa',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		margin: 0,
		padding: 0,
		textTransform: 'none',
	},

	msg: {
		width: '400px',
		fontSize: '0.875rem',
	},
	btn: {
		display: 'flex',
		justifyContent: 'flex-start',
		backgroundColor: '#313C45',
		'@media (min-width:0px) and (max-width:800px)': {
			flexDirection: 'row',
		},
		flexDirection: 'column',
		alignItems: 'baseline',
		marginRight: '50px',

		'& .MuiButton-label': {
			color: '#66ccfa',
		},
		'& .MuiButton-text': { padding: '6px 16px !important' },
	},
	content: {
		display: 'flex',
		backgroundColor: '#313C45',
	},

	cookibaner: {
		margin: '5px',
		'@media (min-width:800px) and (max-width:2700px)': {
			display: 'inline-flex',
		},
		gap: '24%',
		marginLeft: '1%',
	},
	firstbtn: {
		marginTop: '-32px !important',
		'@media (min-width:0px) and (max-width:800px)': {
			marginTop: '-17px !important',
		},
	},
	cntnalign: {
		marginTop: '7px',
		width: '380px',
		'@media (min-width:0px) and (max-width:800px)': {
			width: '100%',
		},
		color: '#CFDBE5',
	},
}));

const CookiePolicy = () => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	let keyvalue: boolean = true;
	if (Cookies.get('cookieconsent')) {
		keyvalue = false;
	}
	return keyvalue ? (
		<div>
			<CookieConsent
				buttonText={
					<Button className={classes.firstbtn} startIcon={<DoneIcon />}>
						Yes, I agree
					</Button>
				}
				buttonStyle={{
					color: '#66ccfa',
					textTransform: 'none',
					margin: '0px !important',
					padding: '0px !important',
					backgroundColor: '#313C45',
					'& .MuiButton-text': { margin: '0px !important', padding: '0px !important' },
				}}
				onAccept={() => {
					Cookies.set('cookieconsent', 'true', { expires: 7 });
				}}
				enableDeclineButton
				declineButtonText={
					<Button style={{ marginTop: '-17px' }} startIcon={<SettingsOutlinedIcon />}>
						No, take me to settings
					</Button>
				}
				onDecline={() => {
					Cookies.set('cookieconsent', 'false', { expires: 7 });
					user.login();
					window.location.href = '/about';
				}}
				setDeclineCookie={true}
				declineButtonStyle={{
					color: '#66ccfa',
					textTransform: 'none',
					margin: '0px !important',
					padding: '0px !important',
					backgroundColor: '#313C45',
					'& .MuiButton-text': { margin: '0px !important', padding: '0px !important' },
				}}
				//contentStyle={{ margin: '5px', display: 'inline-flex', gap: '24%', marginLeft: '1%' }}

				buttonWrapperClasses={classes.btn}
				contentClasses={classes.cookibaner}
				flipButtons={true}
				style={{ backgroundColor: '#313C45', zIndex: 10000, display: 'flex', top: '64px', height: 'fit-content' }}
			>
				<div style={{ fontSize: '1.2rem', marginTop: '11px' }}>Let us know you agree to cookies</div>
				<div className={classes.cntnalign}>
					We use{' '}
					<Link
						href="https://www.mitie.com/legal/cookie-policy/"
						target="_blank"
						style={{ color: '#66ccfa', padding: 0 }}
					>
						cookies
					</Link>{' '}
					to give you the best online experience. Please let us know if you agree to all of these cookies
				</div>
			</CookieConsent>
		</div>
	) : (
		<div></div>
	);
};

export default CookiePolicy;
