export enum Status {
	None,
	Loading,
	Error,
	Done,
	Empty,
}

export enum ContainerID {
	None = 0,
	Feed = 1,
	Ask = 2,
	Site = 3,
	Refuel = 4,
	About = 5,
}
export const mediaTypes = {
	'Please Select': 0,
	Image: 1,
	Video: 2,
};
export const repeat = {
	Repeats: 0,
	EveryDay: 1,
	'Every week': 2,
	'Every 2 weeks': 3,
	'Every Month': 4,
	'Every Year': 5,
};
export const evacuationType = {
	'Fire Alarm Test': 0,
	'Chemical Alarm Test': 1,
	'Security Alarm Test': 2,
};

export enum Category {
	Incident = 0,
	EvacuationTest = 1,
	Visitor = 2,
	ParcelDelivery = 3,
	Promotion = 4,
	RoomBooking = 5,
	Announcement = 6,
	Survey = 7,
}

export interface IAskItems {
	Attachments: any;
	Building: string;
	showOpenCard: boolean;
	RequestType: string;
	Location: string;
	location: string;
	locationDescription?: string;
	reportDate: string;
	CreatedDate: string;
	Details: string;
	statuses: any;
	Id: string;
	redecorate: any;
	fixleak: string;
	SiteName: string;
	status?: string;
	statusDescription?: string;
	description?: string;
	commodityGroup?: string;
}

export const intialState = {
	values: {
		mediaType: 0,
		evacuationType: 0,
		repeat: 0,
	},
};

export const AboutMedia = {
	'0': 'Release Notes',
	'1': 'FAQs',
	'2': 'User Manual',
	'3': 'Accessibility Statement',
	'4': 'Privacy Policy',
	'5': 'Terms and Conditions',
};

export interface IUserLoginStatus {
	isUserLoggedIn?: boolean | null;
	currentComponent?: any;
}

export interface IAPIStatus {
	success: boolean;
	error: null | string;
	result_length: number;
	duration: number;
}
export interface IDay {
	startDate: Date;
	endDate: Date;
}
export interface IVisitor {
	visitor: string;
	visitorFrom: string;
	respondEmail: string;
	site: string;
	collection: string;
	hostEmail: string;
}
export interface IFeedbackForm {
	subject: string;
	details: string;
	osversion: string;
	deviceModel: string;
	feedbackId: string;
	fileObject: string;
	fileFormat: string;
	fileError: boolean;
	token: string;
	container: number;
	contentType: string;
	extension: string;
	createdDate: Date;
	currentDate: Date;
	Id: string;
	Name: string;
	site: string;
	key: string;
	title: string;
	selectable?: boolean;
	Building?: string;
}
export interface IContent {
	SiteId: string;
	SiteIds: string;
	Id: number;
	siteId: string;
	title: string;
	Title: string;
	subtitle: string;
	CollapsedDescription: string;
	description: string;
	Description: string;
	repeat: number;
	repeatUntil: Date;
	startTime: Date;
	RepeatUntil: Date;
	StartTime: Date;
	ExpiredTime: Date;
	endTime: Date;
	token: string;
	mediaUrl: string;
	errors: string;
	mediaPreviewUrl: string;
	itemUrl?: string;
	previewUrl?: string;
	defaultValues?: string;
	Parameters: any;
	IsDraft: Boolean;
	SurveyType?: string;
	RepeatFrequency: number;
	ModifiedTime: Date;
	Status: string;
	CardTitle: string;
	CardSubtitle: string;
	CardDescription: string;
	PromotionName: string;
	MediaUrl: string;
	MediaPreviewUrl: string;
	MediaType?: any;
	MediaFileName?: string;
	PreviewFileName?: string;
	Likes?: number;
	Shares?: number;
	Visibility?: string;
}
export interface IPromotion {
	siteId: string;
	description: string;
	repeat: number;
	repeatUntil: Date;
	startTime: Date;
	endTime: Date;
	mediaType: number;
	token: string;
	mediaUrl: string;
	errors: string;
	mediaPreviewUrl: string;
	itemUrl?: string;
	previewUrl?: string;
	defaultValues?: string;
	Parameters: any;
	Title: String;
	Id: number;
	SiteId: string;
	SiteIds: string;
	title: string;
	subtitle: string;
	Description: string;
	RepeatFrequency: number;
	RepeatUntil: Date;
	StartTime: Date;
	ExpiredTime: Date;
	MediaType: number;
	MediaUrl: string;
	MediaPreviewUrl: string;
	Status: String;
	ModifiedTime: Date;
	CardTitle: string;
	CardSubtitle: string;
	CardDescription: string;
	PromotionName: String;
	ShortDescription: String;
	IsDraft: Boolean;
	SurveyType?: String;
}
// export interface IPromotion {
// 	SiteId: string;
// 	SiteIds: string;
// 	Title: String;
// 	subtitle: String;
// 	Description: string;
// 	RepeatFrequency: number;
// 	RepeatUntil: Date;
// 	StartTime: Date;
// 	EndTime: Date;
// 	MediaType: number;
// 	MediaUrl: string;
// 	MediaPreviewUrl: string;
// 	token: string;
// 	MediaUrl: string;
// 	errors: string;
// 	MediaPreviewUrl: string;
// 	itemUrl?: string;
// 	previewUrl?: string;
// 	defaultValues?: string;
// 	Parameters: any;
// }
export interface IContentValues {
	fileSourceName: string;
	fileSourceVideoFormat: string;
	fileSourceImageFormat: string;
	fileSourceObject: string;
	fileSourceError: Boolean;
	fileSourceExtension: string;
	itemSource: string;
}
export interface IPreviewValues {
	filePreviewVideoFormat: string;
	filePreviewImageFormat: string;
	filePreviewObject: string;
	filePreviewError: Boolean;
	filePreviewExtension: string;
	previewSource: string;
}

export interface IEvacuation {
	category: number;
	uploadToken: null;
	evacuationType: number;
	sites: string;
	startTime: Date;
	StartTime: Date;
	repeat: number;
	repeatUntil: string;
	startDayTime: string;
	Id: number;
	Parameters: any;
	Status: string;
	Title: string;
	EvacuationType: number;
	RepeatFrequency: number;
	RepeatUntil: string;
	StartDayTime: string;
	SiteId: string;
}
export interface IDelivery {
	recipientEmail: string;
	senderName: string;
	parcelNumber: string;
	site: string;
	collectionPlace: string;
	respondEmail: string;
	Category: number;
	UploadToken: null;
}
export interface ISurvey {
	title: String;
	subtitle: String;
	siteId: string;
	surveyType: string;
	Title: String;
	Subtitle: String;
	SiteId: string;
	SurveyType: string;
	question1: string;
	question2: string;
	question3: string;
	question4: string;
	question5: string;
	question6: string;
	question7: string;
	question8: string;
	question9: string;
	question10: string;
	lockSurvey: boolean;
	startDate: Date;
	endTime: Date;
	mediaType: number;
	token: string;
	mediaUrl: string;
	errors: string;
	mediaPreviewUrl: string;
	EndDate?: Date;
	EndTime: Date;
	MediaType: number;
	MediaUrl: string;
	MediaPreviewUrl: string;
	UsersResponses?: number;
	SurveyUsers?: number;
	AverageRating?: string;
	SurveyTitle?: string;
}

export interface IQuestion {
	QuestionNumber: number;
	QuestionDescription: string;
	MandatoryAnswer: boolean;
}

export interface IFloorplanImageDetail {
	URL: string;
	fileFormat: null | string;
	active: boolean;
	default: boolean;
}

export interface IFloorplanImageAPIResponse {
	id: string;
	floorplanDetails: IFloorplanImageDetail;
	status: IAPIStatus;
}
export interface ISiteObject {
	Id: string;
	Name: string;
	EntityId: string;
	Latitude: number;
	Longitude: number;
	RefuelStatusId: number;
	SpacesStatusId: number;
	RefuelVendorTypeId: number;
	LocationStatus?: string;
	Company: string;
	ClientId: string;
	EsmeClientId?: string | null;
}

export interface IFeedPreference {
	Id: number;
	Name: string;
	PushEnabled: boolean;
	FeedVisible: boolean;
	IsMandatory: boolean;
}
export interface INotification {
	id: number;
	message: string;
	status: string;
}

export interface ISite {
	key: string;
	title: string;
	selectable: boolean;
}
export interface IIncident {
	IncidentName: string;
	Category: number;
	UploadToken: null;
}
export interface IFeedResultItems {
	Id: any;
	Title: string;
	Description: string;
	CollapsedTitle: string;
	CollapsedDescription: string;
	VisibleFromTime: string;
	StartTime: string;
	ExpiredTime: string;
	Category: number;
	IconUrl: string;
	ExternalData: null;
	IsPopup: boolean;
	IsDeleted: boolean;
	IsLiked: boolean;
	HasActioned: boolean;
	UserState: any;
	UserStateCreatedTime: null | string;
	UserStateModifiedTime: any;
	Parameters: any;
	AvailableButtons: string[];
	StatusIcon: string | null;
	StatusColour: string;
}
export interface IFeedItem {
	Id: any;
	Title: string;
	Description: string;
	CollapsedTitle: string;
	CollapsedDescription: string;
	VisibleFromTime: string;
	StartTime: string;
	ExpiredTime: string;
	Category: string;
	IconUrl: string;
	ExternalData: null;
	IsPopup: boolean;
	IsDeleted: boolean;
	HasActioned: boolean;
	UserState: any;
	UserStateCreatedTime: null | string;
	UserStateModifiedTime: any;
	Parameters: any;
	AvailableButtons: string[];
	StatusIcon: string | null;
	StatusColour: string;
	showOpenCard: boolean;
}

export interface IFeedCategory {
	id: string;
	title: string;
}

export interface IEntitie {
	Client_Id: string;
	Entity_Id: string;
	Name: string;
	Type: string;
	ChildrenCount: number;
	Uri: string;
}

export interface IEntitiesAPIResponse {
	Entities: IEntitie[];
	status: IAPIStatus;
}

export interface ICanvasFloorplanDetail {
	URL?: string;
	propertyTypeId?: string;
	propertyId?: string;
	propertyName?: string;
	controlType?: string;
	savedFloorplanCoordinates?: IFloorCoordinates[];
	showPropContainer: boolean;
}

export interface IObject extends fabric.Object {
	id?: string;
	entityType?: string;
	isSaved?: boolean;
	name?: string;
	radius?: number;
}

export interface ICanvas extends fabric.Canvas {
	isDragging?: boolean;
	lastPosX: number;
	lastPosY: number;
}

export interface ICircle extends fabric.Circle {
	id?: number | string;
}

export interface ILine extends fabric.Line {
	class?: string;
	id?: number | string;
}

export interface IFloorplanDetail extends ICanvasFloorplanDetail {
	mappedEntityIds: any;
	entitiesList: IEntitie[];
	entitiesListByType: IEntitie[];
	isEntityTypeDisabled: boolean;
	ZoneData: [];
}

export interface IActiveCoordinates {
	propertyId?: string;
	propertyName?: string;
	entityType: string;
	isSaved: boolean;
	setObjectType: string;
}

export interface IPoint {
	x: number;
	y: number;
}

export interface ICoordinatedPoint {
	left?: number;
	top?: number;
	radius?: number;
	width?: number;
	height?: number;
	editorCanvasWidth?: number;
	angle?: number;
	point?: IPoint[];
}

export interface ICoordinates {
	coordinateType: string;
	points: ICoordinatedPoint;
}

export interface IFloorCoordinates {
	name: string;
	id: string;
	type: string;
	coordinates: ICoordinates;
}

export interface IHeaderNavProps {
	classNames: {
		sideBarList: string;
		listIcon: string;
		listCollapse: string;
		listCollapseSelected: string;
		listCollapseIcon: string;
	};
}
export interface IMenuItem {
	Id: string | null;
	MediaUrl: string;
	RefuelMenuTitle: string;
	RefuelSystemName: string;
	MediaType: string;
	IsActive: boolean;
	StartTime: Date | null;
	ExpiredTime: Date | null;
}
// export interface IMenuGetItem {
// 	Id: string | null;
// 	IsActive: boolean;
// 	StartTime: Date | null;
// 	ExpiredTime: Date | null;
// }
export interface IMenuGetItem {
	Id: any | null;
	SiteId: string;
	MediaUrl: string;
	RefuelTitle: string;
	RefuelMenuTitle: string;
	RefuelSystemName: string;
	MediaType: string;
	Order: any;
	IsActive: boolean;
	StartTime: Date | null;
	ExpiredTime: Date | null;
}
export interface ISaveMenuRequestBody {
	siteId: string;
	RefuelMenus: IMenuItem[];
}

export interface ISiteInfoForm {
	address1: string;
	address2: string;
	address3: string;
	address4: string;
	postCode: string;
	contactPhone: string;
	startTime: Date;
	endTime: Date;
	sitePhotoUrl: string;
	siteSecondaryPhotoUrl: string;
	token: string;
	errors: string;
	isSiteInfoLoad: boolean;
	siteId: string;
	sitePhoto: string;
	secondaryPhoto: string;
	longitude: string;
	latitude: string;
}
export interface ISiteInfoPhoto {
	fileName: string;
	fileFormat: string;
	fileObject: string;
	fileExtension: string;
	fileError: Boolean;
}

export interface IFileUpload {
	name: string;
	format: string;
	object: string;
	extension: string;
	error: Boolean;
}

export interface ISiteInfoData {
	Id?: string;
	SiteName?: string;
	Address1?: string;
	Address2?: string;
	Address3?: string;
	Address4?: string;
	Postcode?: string;
	ContactPhone?: string;
	OpeningTimes?: string;
	SitePhoto?: string;
	SecondaryPhoto?: string;
	address1: string;
	address2: string;
	address3: string;
	address4: string;
	postcode: string;
	phone: string;
	primaryURL: string;
	secondaryURL: string;
	longitude: string;
	latitude: string;
	siteId: string;
	openTime: string;
	closeTime: string;
	UploadToken: string;
}

export interface ISiteInfo {
	SiteId: string;
	SiteData: ISiteInfoData[];
}

export interface IAboutForm {
	aboutFile: string;
	token: string;
}

export interface IAboutMedia {
	MediaUrl: string;
	Category: number;
	CategoryName?: string;
	URL: string;
	CategoryId: number;
}

export interface IJobRequestForm {
	subject: string;
	details: string;
	location?: string;
	issue: string;
	feedbackId: string;
	fileObject: string;
	fileFormat: string;
	fileError: boolean;
	token: string;
	container: number;
	contentType: string;
	category: string;
	extension: string;
	createdDate: Date;
	currentDate: Date;
	Id: string;
	Name: string;
	site: string;
	building: string;
	key: string;
	title: string;
	selectable?: boolean;
	criticallity: string;
	email: string;
}
export interface IAskItems {
	Attachments: any;
	Building: string;
	showOpenCard: boolean;
	RequestType: string;
	Location: string;
	location: string;
	locationDescription?: string;
	reportDate: string;
	CreatedDate: string;
	Details: string;
	statuses: any;
	Id: string;
	redecorate: any;
	fixleak: string;
	SiteName: string;
	status?: string;
	statusDescription?: string;
	description?: string;
	commodityGroup?: string;
	URL: string;
	CategoryId: number;
	CategoryName?: string;
	classstructure?: any;
}
