import * as React from 'react';
import {
	makeStyles,
	Fab,
	Grid,
	Paper,
	Dialog,
	DialogTitle,
	Button,
	DialogContentText,
	DialogActions,
	DialogContent,
	Typography,
} from '@material-ui/core';
import { IframeComponent } from './IframeComponent';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import RecentlyOpenedJobs from '../components/Ask/RecentlyOpenedJobs';
import { faCommentAltTimes } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import { faCommentAltTimes as faCommentAltTimesSolid } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
		marginBottom: 8,
	},
	paper: {
		display: 'flex',
		height: '100%',
		overflow: 'auto',
		flexDirection: 'column',
		boxShadow: 'none !important',
		//height: 'calc(100vh - 95px) !important',
	},
	actionIconButton: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	esmeCloseIcon: {
		width: '46px',
		height: '46px',
		display: 'none',
		backgroundColor: '#66CCFA !important',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#14A0D6 !important',
			'& > * svg': {
				color: '#FFFFFF',
				display: 'flex',
			},
		},
		'& > * svg': {
			color: '#FFFFFF',
			display: 'flex',
			fontSize: '24px !important',
		},
	},
	title: {
		alignItems: 'center',
		padding: theme.spacing(2.1),
		paddingLeft: '6.2px',
		marginTop: '3px',
		paddingBottom: '9px',
	},
	alignStyle: {
		'& .MuiTypography-h6': {
			font: 'normal normal normal 24px/12px Roboto',
			color: '#002855',
			height: '29px',
			marginTop: '10px',
		},
	},
	textMargin: {
		textAlign: 'center',
	},

	accessDimensions: {
		width: '60%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	jobsDisplay: {
		'@media (min-width:0px) and (max-width:1830px)': {
			display: 'none',
		},
	},
	closeIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	ActionButtons: {
		backgroundColor: '#66ccfa !important',
		color: '#FFFFFF',
		marginLeft: '2px !important',
		'&:hover': {
			backgroundColor: '#56b1df !important',
			color: '#FFFFFF',
		},
	},
	ActionButtons2: {
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		'&:hover': {
			backgroundColor: '#66ccfa',
			color: '#FFFFFF',
			border: '1px solid #66CCFA',
		},
	},
	dialogStyle: {
		'& .MuiDialog-paper': {
			margin: '0px',
		},
		'& .MuiDialogContent-root': {
			padding: '0px',
		},
		'& .MuiDialog-paperScrollPaper': {
			maxHeight: 'unset',
			borderRadius: '16px',
			boxShadow: '0px 3px 6px #00000029',
			height: '157px',
		},
		'& .MuiDialogTitle-root': {
			padding: '16px 29px 0px 30px !important',
		},
		'& .MuiDialogContentText-root': {
			marginLeft: '24px',
			font: 'normal normal normal 18px/24px Roboto',
			color: '#8B8DAE',
			marginBottom: '6px',
			marginTop: '-5px',
		},
	},
}));

const ChatBot: React.FunctionComponent = observer(() => {
	const { route } = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const routes: any = React.useContext(stores.context.routes);
	const [hover, sethover] = React.useState(false);
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const handleCloseChat = () => {
		setEsmeDialogOpen(false);
		routes.navigate({ name: 'chatbot' }, { clearParams: true });
		routes.testval = false;
		resetChatbot();
	};
	window.addEventListener(
		'message',
		event => {
			if (event.data === 'firstmessage') {
				routes.testval = true;
			} else {
				routes.testval = false;
			}
		},
		false,
	);

	const resetChatbot = () => {
		const iframeEl: any = document.getElementById('myframe');
		iframeEl.contentWindow.postMessage('reset', '*');
	};
	const esmeUrl = process.env.REACT_APP_ESME_URL;
	return (
		<>
			<div className={classes.frameContainer}>
				{user.esmesite.map((ss: any) =>
					ss.Id === user.currentSiteId && ss.EsmeClientId !== null ? (
						<Grid container spacing={1} key={ss.EsmeClientId} style={{ width: '100%', height: '100%' }}>
							{user.CafmId === 1 ? (
								<Grid
									item
									className={classes.jobsDisplay}
									style={{ height: '850px', width: '512px', marginLeft: '30px' }}
								>
									<RecentlyOpenedJobs />
								</Grid>
							) : (
								''
							)}
							<Grid item style={{ width: '992px', paddingLeft: '60px' }}>
								<Paper className={classes.paper}>
									<Grid container>
										<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
											<Typography
												variant="h5"
												style={{
													textAlign: 'left',
													font: 'normal normal normal 18px/12px Roboto',
													color: '#002855',
													marginLeft: '8px',
													height: '22px',
													lineHeight: 'normal',
												}}
											>
												Job Request Chat
											</Typography>
										</Grid>
										<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
											<Fab
												color="primary"
												aria-label="add"
												onMouseOver={() => sethover(true)}
												onMouseOut={() => sethover(false)}
												className={classes.esmeCloseIcon}
												onClick={() => {
													setEsmeDialogOpen(true);
												}}
											>
												{hover ? <Icon icon={faCommentAltTimesSolid} /> : <Icon icon={faCommentAltTimes} />}
											</Fab>
										</Grid>
									</Grid>
									<Grid container style={{ height: '100%' }}>
										<Grid item xs={12}>
											{route.params.srId === undefined ? (
												<IframeComponent
													iframeSrc={`${esmeUrl}?subChannel=ar02&header=off`}
													height="450px"
													width="100%"
													token={user.token}
													clientId={ss.EsmeClientId}
												/>
											) : (
												<IframeComponent
													iframeSrc={`${esmeUrl}?subChannel=ar02&header=off&servicerequest=${route.params.srId}`}
													height="450px"
													width="100%"
													token={user.token}
													clientId={ss.EsmeClientId}
												/>
											)}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					) : ss.Id === user.currentSiteId && ss.EsmeClientId === null ? (
						<>
							<div className={classes.textMargin}>
								<Typography variant="subtitle2">Chatbot is not available for this site.</Typography>
							</div>
						</>
					) : (
						''
					),
				)}
			</div>
			<Dialog
				style={{ margin: 'auto', maxWidth: '487px' }}
				className={classes.dialogStyle}
				open={esmeDialogOpen}
				onClose={handleClickClose}
			>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat?
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button
							size="medium"
							color="primary"
							className={classes.ActionButtons2}
							variant="outlined"
							onClick={handleClickClose}
						>
							Return to Chat
						</Button>
						<Button
							size="medium"
							color="primary"
							className={classes.ActionButtons}
							variant="contained"
							onClick={handleCloseChat}
						>
							Close Chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default ChatBot;
