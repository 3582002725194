import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
	makeStyles,
	Typography,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Link,
	TextField,
	Grid,
	Dialog,
	DialogActions,
	Button,
	Paper,
	IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { string as yupString, object as yupObject } from 'yup';
import { Icon } from '@mitie/material-ui-extensions';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { stores } from '../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { IAboutMedia } from '../types';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
	},
	heading: {
		fontSize: theme.typography.pxToRem(24),
		flexShrink: 0,
	},
	aboutMenuItem: {
		height: '60px',
	},
	dialogBoxStyle: {
		minWidth: 'calc(12% + 100px)',
		padding: theme.spacing(3),
	},
	dialogBoxText: {
		color: theme.palette.text.primary,
		fontSize: '20',
	},
	actionIconButton: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	root: {
		'&[disabled]': {
			color: 'grey',
			cursor: 'default',
			'&:hover': {
				textDecoration: 'none',
			},
		},
	},
}));

const fieldSchema = yupObject().shape({
	fileUrl: yupString().required('URL is Required'),
});

const About = observer(() => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const about = React.useContext(stores.context.about);
	const appStores = React.useContext(stores.context.appStores);
	const [expanded, setExpanded] = React.useState<string | false>('panel');
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [privacyLink, setprivacylink] = React.useState<any>();
	const [aboutMedia, setAboutMedia] = React.useState<IAboutMedia>({
		URL: '',
		CategoryId: 0,
		CategoryName: '',
		MediaUrl: '',
		Category: 0,
	});
	const notifications = React.useContext(stores.context.notifications);
	const [fileUrl, setfileUrl] = React.useState('');

	const methods = useForm({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, reset, formState, triggerValidation } = methods;

	React.useEffect(() => {
		if (user.currentSiteId) {
			const fetchprivacypolicylink = async () => {
				const privacydata = await about.fetchprivacylinkupdated();
				setprivacylink(privacydata && privacydata.PrivacyPolicyLink);
			};
			fetchprivacypolicylink();
		}
	}, [user.currentSiteId]);

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};
	const saveAboutFile = async () => {
		const isValidForm = await triggerValidation();
		if (!isValidForm || !formState.isValid) return;

		const mediaInfo: IAboutMedia = {
			CategoryId: aboutMedia.CategoryId,
			CategoryName: aboutMedia.CategoryName,
			URL: fileUrl,
			MediaUrl: '',
			Category: 0,
		};

		about.saveMediaInfo(mediaInfo).then(response => {
			if (response.Success) {
				resetAboutFile();
				about.fetchAboutMedia();
				about.fetchAboutList();
				notifications.addNotification('Successfully updated', 'success');
			} else {
				notifications.addNotification('Something went wrong , Please try again', 'error');
			}
		});
	};
	const resetAboutFile = () => {
		reset();
		setfileUrl('');
		setDialogOpen(false);
	};

	React.useEffect(() => {
		about.fetchAboutMedia();
		about.fetchAboutList();
	}, [about]);

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{user && user.token ? (
					<Grid container alignItems="center">
						<Grid item md={appStores.drawerOpen ? 5 : 4}>
							<Paper className={classes.paper}>
								<ExpansionPanel expanded={expanded === 'panel'} onChange={handleChange('panel')}>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id="panel-header">
										<Typography className={classes.heading}>About</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										{about.aboutList && (
											<Grid container>
												<Grid item xs={12} container>
													{about.aboutList.map((aboutMediaList: IAboutMedia, index: number) => (
														<Grid container alignItems="center" key={index} className={classes.aboutMenuItem}>
															{index == 3 ? (
																<>
																	<Grid item xs={8} container>
																		<Typography>Privacy Notice</Typography>
																	</Grid>
																	<Grid item xs={2} container justify="center">
																		<Link href={privacyLink ? privacyLink : ''} target="_blank" rel="noopener">
																			<IconButton
																				aria-label="Click to view"
																				color="inherit"
																				size="small"
																				className={classes.actionIconButton}
																			>
																				<Icon icon={faExternalLink} />
																			</IconButton>
																		</Link>
																	</Grid>
																</>
															) : index == 4 ? (
																<>
																	<Grid item xs={8} container>
																		<Typography>Cookie Policy</Typography>
																	</Grid>
																	<Grid item xs={2} container justify="center">
																		<Link
																			href={'https://www.mitie.com/legal/cookie-policy/'}
																			target="_blank"
																			rel="noopener"
																		>
																			<IconButton
																				aria-label="Click to view"
																				color="inherit"
																				size="small"
																				className={classes.actionIconButton}
																			>
																				<Icon icon={faExternalLink} />
																			</IconButton>
																		</Link>
																	</Grid>
																</>
															) : (
																<>
																	<Grid item xs={8} container>
																		<Typography>{aboutMediaList.CategoryName}</Typography>
																	</Grid>
																	<Grid item xs={2} container justify="center">
																		<Link href={aboutMediaList.URL} target="_blank" rel="noopener">
																			<IconButton
																				aria-label="Click to view"
																				color="inherit"
																				size="small"
																				className={classes.actionIconButton}
																			>
																				<Icon icon={faExternalLink} />
																			</IconButton>
																		</Link>
																	</Grid>
																</>
															)}
															{user.isSuperAdmin && (
																<Grid item xs={2} container justify="flex-end">
																	<Link
																		style={
																			index == 3 || index == 4
																				? { color: 'grey', cursor: 'default', textDecoration: 'none' }
																				: {}
																		}
																		component="button"
																		variant="body2"
																		disabled={index == 3 || index == 4 ? true : false}
																		onClick={() => {
																			setAboutMedia(aboutMediaList);
																			setDialogOpen(true);
																		}}
																	>
																		Edit
																	</Link>
																</Grid>
															)}
														</Grid>
													))}
												</Grid>

												{about.aboutList.map((aboutMediaItem: IAboutMedia, num: number) =>
													num === 4 ? (
														<Grid container alignItems="center" className={classes.aboutMenuItem}>
															<Grid item xs={8} container>
																<Typography>{aboutMediaItem.CategoryName}</Typography>
															</Grid>
															<Grid item xs={2} container justify="center">
																<Link href={aboutMediaItem.URL} target="_blank" rel="noopener">
																	<IconButton
																		aria-label="Click to view"
																		color="inherit"
																		size="small"
																		className={classes.actionIconButton}
																	>
																		<Icon icon={faExternalLink} />
																	</IconButton>
																</Link>
															</Grid>
															{user.isSuperAdmin && (
																<Grid item xs={2} container justify="flex-end">
																	<Link
																		component="button"
																		variant="body2"
																		onClick={() => {
																			setAboutMedia(aboutMediaItem);
																			setDialogOpen(true);
																		}}
																	>
																		Edit
																	</Link>
																</Grid>
															)}
														</Grid>
													) : (
														''
													),
												)}
											</Grid>
										)}
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</Paper>
						</Grid>
					</Grid>
				) : (
					''
				)}
				<Dialog
					open={dialogOpen}
					onClose={() => handleClose()}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<Typography variant="h5" component="h3" className={classes.dialogBoxText}>
						{aboutMedia.CategoryName}
					</Typography>
					<form>
						<TextField
							autoFocus
							margin="dense"
							name="fileUrl"
							label="Enter URL here"
							type="text"
							defaultValue={fileUrl}
							onChange={e => setfileUrl(e.target.value)}
							fullWidth
							error={Boolean(errors.fileUrl)}
							inputRef={e => {
								register(e);
							}}
						/>
					</form>
					<DialogActions>
						<Button
							color="primary"
							onClick={() => {
								resetAboutFile();
							}}
						>
							Cancel
						</Button>
						<Button color="primary" disabled={!formState.isValid} onClick={() => saveAboutFile()}>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</main>
		</>
	);
});

export default About;
