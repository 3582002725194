import { format, compareAsc, formatDistanceStrict, isSameDay } from 'date-fns';
import moment from 'moment-timezone';
export const imageExt = ['image/jpg', 'image/png', 'image/jpeg'];
export const videoExt = ['video/mp4', 'video/3gp', 'video/3gpp', 'video/m4v', 'video/webm', 'video/mpeg'];
export const pdfExt = ['application/pdf'];

export function displayLocalTime(utcDateTime: string): string {
	const fnsDate = new Date(utcDateTime);
	const diff = isSameDay(fnsDate, new Date());
	if (!diff) {
		const distance = formatDistanceStrict(fnsDate, new Date(), { addSuffix: false });
		return `${distance} ago`;
	}
	return format(fnsDate, 'HH:mm');
}

export function displayDateTime(dateTime: number | Date | string) {
	const fnsDate = new Date(dateTime);
	return format(fnsDate, 'dd/MM/yyyy HH:mm');
}

export function displayDate(date: number | Date | string) {
	const fnsDate = new Date(date);
	return format(fnsDate, 'dd/MM/yy');
}

export function isExpired(utcDateTime: string): boolean {
	return compareAsc(new Date(utcDateTime), new Date()) <= 0;
}

export function isValidFileType(fName: string, mediaType: string) {
	const extensionLists = { Image: imageExt, Video: videoExt };
	return extensionLists[mediaType].indexOf(fName.split('.').pop()) > -1;
}

export function isImageUrl(url: string) {
	return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export function isVideoUrl(url: string) {
	return url.match(/\.(webm|mp4|ogg|3gp|mpeg)$/) != null;
}
export function isValidUrl(url: string) {
	return url.match(
		/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
	);
}

export function getAllowedFileTypes(type: string) {
	const extensionLists = { Image: imageExt, Video: videoExt, PDF: pdfExt };
	return extensionLists[type] || [];
}

export function displayDayTime(dateTime: number | Date | string) {
	const time = moment
		.utc(new Date(dateTime))
		.add(1, 'hour')
		.format('HH:mm ');
	const fnsDate = new Date(dateTime);

	const weekday = new Array(7);
	weekday[0] = 'Sunday';
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';
	return time + weekday[fnsDate.getDay()];
}
