import * as React from 'react';
import { Container } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
// import { Grid, Typography, Avatar, Link } from '@material-ui/core';
// import applconLarge from '../assets/images/AriaLogoText.svg';
// import appleDownloadIcon from '../assets/images/AppleDownloadIcon.png';
// import androidDownloadIcon from '../assets/images/AndroidDownloadIcon.png';
import useStyles from '../assets/css/MobileViewCss';

const MobileView: React.FunctionComponent = () => {
	const classes = useStyles();
	// const applconLargeImg = applconLarge;
	// const appleDownloadIconImg = appleDownloadIcon;
	// const androidDownloadIconImg = androidDownloadIcon;

	return (
		<Container style={{ backgroundColor: '#F4F8FC', padding: '100px 0 0', textAlign: 'center' }}>
			<Grid container direction="row" justify="space-evenly" alignItems="center">
				<Grid item xs={12} sm={12} md={6}>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={5}
						className={classes.userLoginSection}
					>
						<Grid item xs={12}>
							<Typography variant="body2" color="primary" className={classes.textSize}>
								Thank you for visiting <b>Aria.mitie.com</b>
								<br /> <b>Aria</b> is a webapp designed for desktop browsers
							</Typography>
						</Grid>
						{/*
						<Grid item xs={12} style={{ marginBottom: '-60px' }}>
							<Avatar variant="rounded" src={applconLargeImg} className={classes.appIconLarge}></Avatar>
						</Grid>
						<Grid item xs={12} style={{ marginBottom: '35px' }}>
							<Typography variant="body2" color="primary" className={classes.textSize}>
								To use <b>Aria</b> on your mobile device,
								<br /> Please download the <b>Aria</b> app.
							</Typography>
						</Grid>
						<Grid direction="row" justify="space-evenly" alignItems="center" spacing={1}>
							<Grid item xs={12}>
								<Link
									href={'https://apps.apple.com/gb/app/aria-by-mitie/id1414948627'}
									target="_blank"
									underline="none"
								>
									<Avatar variant="rounded" src={appleDownloadIconImg} className={classes.appleDownloadIcon}></Avatar>
								</Link>
							</Grid>
							<Grid item xs={12}>
								<Link
									href={'https://play.google.com/store/apps/details?id=com.mitie.aria&hl=en_US'}
									target="_blank"
									underline="none"
								>
									<Avatar
										variant="rounded"
										src={androidDownloadIconImg}
										className={classes.androidDownloadIcon}
									></Avatar>
								</Link>
							</Grid>
						</Grid>
	*/}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default MobileView;
