import * as React from 'react';
import { stores } from '../store';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core';
import FloorplanList from '../components/FloorplanList';
import FloorplanUploader from '../components/FloorplanUploader';
import FloorplanEditor from '../components/FloorplanEditor/Editor';
import PermanentAppBar from '../components/Header/PermanentAppBar';
import RoomInfo from '../components/RoomInfo';
import DeskInfo from '../components/deskInfo';
import AddAttendees from '../components/AddAttendees';
import SpacesHome from '../components/SpacesHomePage';
import BookingsView from '../components/BookingsView';
const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
		margin: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(5, 1, 1, 1),
			overflow: 'auto',
		},
		width: '80%',
	},
	appBarSpacerList: {
		height: '64px',
		backgroundColor: '#f4f8fc',
	},
	contentList: {
		flexGrow: 1,
		textAlign: 'center',
		width: '100%',
		overflowX: 'hidden',
		backgroundColor: '#ffffff',
	},
	containerList: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		display: 'flex',
		backgroundColor: 'white',
	},
	containerListfloor: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		marginLeft: '25px',
		marginRight: '25px',
		backgroundColor: 'white',
	},
}));

const Spaces = observer(() => {
	const user = React.useContext(stores.context.user);
	const { routeName } = React.useContext(stores.context.routes);
	const classes = useStyles();
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			{routeName === 'floorplanUploader' ? (
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<FloorplanUploader />
				</main>
			) : routeName === 'floorplanEditor' ? (
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<FloorplanEditor />
				</main>
			) : routeName === 'roomInfo' ? (
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<RoomInfo />
				</main>
			) : routeName === 'deskInfo' ? (
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<DeskInfo />
				</main>
			) : routeName === 'addAttendees' ? (
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<AddAttendees />
				</main>
			) : routeName === 'spacesHome' ? (
				<main className={classes.contentList}>
					<div className={classes.appBarSpacerList} />
					<div className={classes.containerList}>
						<SpacesHome />
					</div>
				</main>
			) : routeName === 'bookingsView' ? (
				<main className={classes.contentList}>
					<div className={classes.appBarSpacerList} />
					<div className={classes.containerList}>
						<BookingsView />
					</div>
				</main>
			) : (
				<main className={classes.contentList}>
					<div className={classes.appBarSpacerList} />
					<div className={classes.containerListfloor}>
						<FloorplanList />
					</div>
				</main>
			)}
		</>
	);
});

export default Spaces;
