import { action } from 'mobx';
import { getAPI, postAPI } from 'services/apiService';
import { IFeedPreference } from '../types';

class Settings {
	@action
	public async getPreferenceData() {
		const { Feed } = await getAPI('/user/preferences');
		return Feed;
	}
	@action
	public async submitFeed(feed: IFeedPreference[]) {
		return await postAPI('/user/settings', feed);
	}
}
export default Settings;
