import React from 'react';
import { Grid, Card, CardMedia } from '@material-ui/core/';
import feedWireframe from '../../assets/images/feed-wireframe.png';
import statusFeedWireframe from '../../assets/images/statusfeed-wireframe.png';
import useStyles from '../../assets/css/FeedWireframecss';

const FeedWireFrame = (props: any) => {
	const classes = useStyles();
	return (
		<div>
			<>
				{props.feedType === 'mainfeed' ? (
					<Grid
						container
						spacing={2}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className={classes.gridWidth}
					>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia component="img" image={feedWireframe} alt="Aria" className={classes.mainFeed} />
							</Card>
						</Grid>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={6} className={classes.cardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
					</Grid>
				) : props.feedType === 'statusfeed' ? (
					<Grid
						container
						spacing={2}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className={classes.gridWidth}
					>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia component="img" image={statusFeedWireframe} alt="Aria" className={classes.statusFeed} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia component="img" image={statusFeedWireframe} alt="Aria" className={classes.statusFeed} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia
									component="img"
									height="140"
									image={statusFeedWireframe}
									alt="Aria"
									className={classes.statusFeed}
								/>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia component="img" image={statusFeedWireframe} alt="Aria" className={classes.statusFeed} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia component="img" image={statusFeedWireframe} alt="Aria" className={classes.statusFeed} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className={classes.statusCard}>
								<CardMedia component="img" image={statusFeedWireframe} alt="Aria" className={classes.statusFeed} />
							</Card>
						</Grid>
					</Grid>
				) : (
					<Grid
						container
						spacing={2}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className={classes.gridWidth}
					>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia component="img" image={feedWireframe} alt="Aria" className={classes.mainFeed} />
							</Card>
						</Grid>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
						<Grid item xs={4} className={classes.archiveCardView}>
							<Card className={classes.mainCard}>
								<CardMedia className={classes.mainFeed} component="img" image={feedWireframe} alt="Aria" />
							</Card>
						</Grid>
					</Grid>
				)}
			</>
		</div>
	);
};

export default FeedWireFrame;
