import React from 'react';
import { forwardRef } from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface PropsForm {
	onStartTime: (date: Date) => void;
	onEndTime: (date: Date) => void;
	selectedStartTime: Date;
	selectedEndTime: Date;
	startTimeError: string;
	endTimeError: string;
	status: boolean;
}

const DateController = forwardRef<HTMLInputElement, PropsForm>(
	({ onStartTime, onEndTime, selectedStartTime, startTimeError, selectedEndTime, endTimeError, status }, ref) => {
		const classes = useStyles();

		const handleStartTime = (date: Date) => {
			onStartTime(date);
		};
		const handleEndTime = (date: Date) => {
			onEndTime(date);
		};
		return (
			<>
				<FormControl variant="filled" className={classes.formControl}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							name="startTime"
							//label="Starts at"
							label="Starts"
							margin="normal"
							format="dd/MM/yyyy HH:mm"
							ampm={false}
							fullWidth
							disablePast
							className={classes.root}
							disabled={status}
							value={selectedStartTime}
							onChange={handleStartTime}
							error={Boolean(startTimeError)}
							helperText={startTimeError}
							inputRef={ref}
							inputProps={{ className: classes.dateStyle }}
							InputLabelProps={{ className: classes.InputLabel }}
						/>
					</MuiPickersUtilsProvider>
				</FormControl>
				<FormControl variant="filled" className={classes.formControl}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							name="endTime"
							// label="Ends at"
							// margin="normal"
							fullWidth
							// disablePast
							label="Expires"
							margin="normal"
							disablePast
							//disabled={status}
							className={classes.root}
							format="dd/MM/yyyy HH:mm"
							ampm={false}
							value={selectedEndTime}
							onChange={handleEndTime}
							inputProps={{ className: classes.dateStyle }}
							InputLabelProps={{ className: classes.InputLabel }}
							error={Boolean(endTimeError)}
							helperText={endTimeError}
						/>
					</MuiPickersUtilsProvider>
				</FormControl>
			</>
		);
	},
);
export default DateController;
const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'double',
		},
	},
	dateStyle: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
	},
	formControl: {
		display: 'block',
		width: '100%',
		marginTop: theme.spacing(1),
	},
}));
