import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import {
	makeStyles,
	Typography,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Box,
} from '@material-ui/core';
import { Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { stores } from '../store';
import { IFeedPreference } from '../types';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
	},
	root: {
		width: '40%',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
	heading: {
		fontSize: theme.typography.pxToRem(24),
		flexShrink: 0,
	},
	feedPreferences: {
		display: 'flex',
		flexDirection: 'column',
	},
	allFeeds: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	feedItem: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing(1),
		padding: theme.spacing(0, 4),
	},
	feedItemNew: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing(1),
		padding: theme.spacing(0, 0),
	},
}));

const Settings: React.FunctionComponent = () => {
	const classes = useStyles();
	const [feed, setFeed] = React.useState<IFeedPreference[]>([]);
	const [allNotifications, setAllNotifications] = React.useState<boolean>(false);
	const user = React.useContext(stores.context.user);
	const settings = React.useContext(stores.context.settings);
	const feedNew = React.useContext(stores.context.feed);
	const [emailPreference, setEmailPreference] = React.useState(
		user && user.emailPreference ? user.emailPreference : false,
	);
	const handleEmailPreference = (email: boolean) => {
		const SaveInfo = {
			emailPreference: email,
			phoneNumber: user && user.userPhoneNumber ? user.userPhoneNumber : '',
		};
		user.updateuserDetails(SaveInfo).then(response => {
			if (response && response.Success) {
				setEmailPreference(email);
				user.emailPreference = email;
			}
		});
	};
	const handleChange = (id: number) => {
		const newFeedState: IFeedPreference[] = [...feed];
		if (id !== 0) {
			const feedItem = newFeedState.find((feedPreference: IFeedPreference) => {
				return feedPreference.Id === id;
			}) as IFeedPreference;
			feedItem.FeedVisible = !feedItem.FeedVisible;
			feedItem.PushEnabled = !feedItem.PushEnabled;
			setAllNotifications(isAllNotificationsOn(feed));
		} else {
			newFeedState
				.filter((feedPreference: IFeedPreference) => {
					return (
						feedPreference.Name !== 'Incident' &&
						feedPreference.Name !== 'Closed Sites' &&
						feedPreference.Name !== 'Quote Approval' &&
						feedPreference.Name !== 'Uplift Approval'
					);
				})
				.forEach((feedPreference: IFeedPreference) => {
					feedPreference.FeedVisible = !allNotifications;
					feedPreference.PushEnabled = !allNotifications;
				});
			setAllNotifications(!allNotifications);
		}
		setFeed(newFeedState);
		settings.submitFeed(newFeedState).then(response => {
			feedNew.fetchPreferences();
		});
	};
	React.useEffect(() => {
		settings.getPreferenceData().then((feed: IFeedPreference[]) => {
			setAllNotifications(isAllNotificationsOn(feed));
			setFeed(
				feed
					.filter((feedPreference: IFeedPreference) => {
						return (
							feedPreference.Name !== 'Room Booking' &&
							feedPreference.Name !== 'Closed Sites' &&
							feedPreference.Name !== 'Quote Approval' &&
							feedPreference.Name !== 'Uplift Approval'
						);
					})
					.sort(sortByName),
			);
		});
		setEmailPreference(user.emailPreference);
	}, [settings, user.emailPreference]);

	React.useEffect(() => {
		let flag: boolean = feed
			.filter(
				(feedPreferenceItem: IFeedPreference) =>
					feedPreferenceItem.Name !== 'Incident' && feedPreferenceItem.Name !== 'Room Booking',
			)
			.every((feedPreference: IFeedPreference) => {
				return feedPreference.FeedVisible === true;
			});
		setAllNotifications(flag);
	}, [feed, user.currentSiteId]);

	const isAllNotificationsOn = (feed: IFeedPreference[]) => {
		return feed
			.filter(
				(feedPreferenceItem: IFeedPreference) =>
					feedPreferenceItem.Name !== 'Incident' && feedPreferenceItem.Name !== 'Room Booking',
			)
			.every((feedPreference: IFeedPreference) => {
				return feedPreference.FeedVisible === true;
			});
	};

	const sortByName = (a: IFeedPreference, b: IFeedPreference) => {
		return a.Name > b.Name ? 1 : -1;
	};
	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{user && user.token ? (
					<div className={classes.root}>
						<ExpansionPanel defaultExpanded={true}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className={classes.heading}>Notifications</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.feedPreferences}>
								<Box>
									<Box>
										<Box className={classes.feedItemNew}>
											<Box>
												<Typography>{'Email notifications'}</Typography>
											</Box>
											<Box>
												<Switch
													name="email"
													checked={emailPreference}
													onChange={() => handleEmailPreference(!emailPreference)}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel defaultExpanded={true}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className={classes.heading}>Feed Preferences</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.feedPreferences}>
								<Box>
									<Box className={classes.allFeeds}>
										<Box>
											<Typography>All notifications</Typography>
										</Box>
										<Box>
											<Switch checked={allNotifications} onChange={() => handleChange(0)} name="All Notifications" />
										</Box>
									</Box>
									<Box>
										{feed.map(item => (
											<Box className={classes.feedItem} key={item.Id}>
												<Box>
													<Typography>{item.Name}</Typography>
												</Box>
												<Box>
													<Switch
														key={item.Id}
														checked={item.FeedVisible}
														onChange={() => handleChange(item.Id)}
														name={item.Name}
														disabled={item.Name === 'Incident'}
													/>
												</Box>
											</Box>
										))}
									</Box>
								</Box>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>
				) : (
					''
				)}
			</main>
		</>
	));
};

export default Settings;
