import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	Card: {
		borderRadius: '8px',
		boxShadow: '0px 3px 6px #00000029',
		width: '460px',
		height: '131px',
		marginLeft: '7px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2),
		},
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	},
	openCardTypography: {
		marginBottom: '1em !important',
		font: 'normal normal normal 14px/17px Roboto',
		color: '#002855',
	},
	openCardTime: {
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
	},
	openCardTitle: {
		font: 'normal normal normal 20px/24px Roboto',
		color: '#002855',
	},
	wireframeCard: {
		boxShadow: '0px 3px 6px #00000029',
		width: '460px',
		height: '131px',
		borderRadius: '8px',
	},
	subTitleViewEvacuation: {
		font: 'normal normal normal 14px/17px Roboto',
		color: '#002855',
	},
	menuButton: {
		'&:hover': {
			backgroundColor: 'white',
			cursor: 'auto',
		},
	},
	menuButton2: {
		'&:hover': {
			backgroundColor: 'rgba(102, 204, 250, 0.2)',
			color: '#002855',
		},
		color: '#002855',
	},
	feedAlignment: {
		marginBottom: '2px',
	},
	openCard: {},
	openCardDialog: {
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 'none !important',
			borderRadius: '8px',
			boxShadow: '0px 5px 8px #00000063',
			width: '33%',
		},
		boxShadow: '0px 5px 8px #00000063',
	},
	ActionButtons: {
		backgroundColor: '#66ccfa !important',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#56b1df !important',
			color: '#FFFFFF',
		},
	},
	ActionButtons2: {
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		'&:hover': {
			backgroundColor: '#66ccfa',
			color: '#FFFFFF',
			border: '1px solid #66CCFA',
		},
	},
	actions: {
		justifyContent: 'flex-end',
	},
	CardHeader: {
		backgroundColor: '#CFDBE5',
		height: '37px',
		borderRadius: '8px 8px 0px 0px',
	},
	CardContent: {
		paddig: '8px',
	},
	textItem: {
		alignSelf: 'flex-start',
	},
	actionArea: {
		'&:hover $focusHighlight': {
			opacity: 0,
		},
	},
	titleView: {
		height: '17px',
		width: '386px',
		marginTop: '-10px',
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
	},
	subTitleView: {
		font: 'normal normal normal 14px/17px Roboto',
		color: '#8B8DAE',
	},
	timeView: {
		font: 'normal normal normal 14px/17px Roboto',
		color: '#002855',
	},
	focusHighlight: {},
	statusFeed: {
		height: '125px',
		objectFit: 'fill',
	},
}));

export default useStyles;
