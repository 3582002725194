import { observable } from 'mobx';

// This Store is used to manage the Application related global things
class AppStores {
	@observable
	public showAppLoader: boolean = false;

	@observable
	public drawerOpen: boolean = false;

	@observable
	public desktopDrawer: boolean = true;

	@observable
	public captchaval: boolean = false;

	@observable
	public captchaerr: boolean = false;
}

export default AppStores;
