import { action, observable } from 'mobx';
import { getAPI, postAPI } from 'services/apiService';
import { ISiteObject, ISiteInfoData } from '../types';

class Site {
	@observable
	public siteId = '';
	@observable
	public loading = false;
	@observable
	public siteInfo: any = {};
	@observable
	public siteList: ISiteObject[] = [];

	@observable
	public sitesByClientList: any = [];

	@action
	public async fetchSiteInfo(siteId: string) {
		const site = await getAPI('/site/' + siteId);
		this.siteId = siteId;
		this.siteInfo = site;
	}

	@action
	public async fetchSiteEntities() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.siteList = await getAPI('/site/list');
		this.loading = false;
		return this.siteList;
	}

	@action
	public async saveSiteInfo(siteInfo: ISiteInfoData) {
		return await postAPI('/site/saveSiteInfo', siteInfo);
	}

	@action
	public async fetchSiteAndClientEntities() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		const clients = await getAPI('/site/clientsData');
		this.sitesByClientList = clients.filter((item: any) => item.LocationStatus !== 'INACTIVE');
		this.loading = false;
		return this.sitesByClientList;
	}
}

export default Site;
