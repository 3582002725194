import { string as yupString, object as yupObject } from 'yup';
import error from './ErrorMessage';

const phoneRegExp = /^((\+44\s?\d{4}|\(?\d{5}\)?)\s?\d{6})|((\+44\s?|0)7\d{3}\s?\d{6})$/;
const fieldSchema = yupObject().shape({
	email: yupString()
		.required(error.email)
		.email(error.validEmail),
	name: yupString().required(error.fullName),
	telephone: yupString()
		.matches(phoneRegExp, error.validNumber)
		.length(13),
});
export default fieldSchema;
