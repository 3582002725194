import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
// import * as _ from 'lodash';
import {
	Grid,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	Divider,
	Typography,
	Paper,
	TextField,
	makeStyles,
	Switch,
	FormHelperText,
	Backdrop,
	CircularProgress,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { SelectTreeDataNode, SelectTree } from '@mitie/material-ui-extensions';
import DateController from './DateController';
import { string as yupString, object as yupObject, ObjectSchema } from 'yup';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { ISurvey, Category, IQuestion } from '../../types';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import { stores } from '../../store';
import UploadComponent from './UploadComponent';
import { compareAsc } from 'date-fns';

import ClientsAndSites from 'components/ClientsAndSites';

const yup = require('yup');
const fieldSchema = yupObject().shape({
	Title: yupString().required('Title is required'),
	Subtitle: yupString().required('SubTitle is required'),
	question1: yupString()
		.required('Question1 is required')
		.test('len', 'Maximum 140 characters allowed', val => val.length <= 140)
		.test('len', 'Question1 is required', val => val.replace(/\s/g, '').length),
	question2: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question3: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question4: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question5: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question6: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question7: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question8: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question9: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	question10: yupString().test('len', 'Maximum 140 characters allowed', val => val.length <= 140),
	mediaType: yup.number().default(0),
	mediaUrl: yup
		.string()
		.url()
		.when('mediaType', (mediaType: number, fieldSchema: ObjectSchema) => {
			return mediaType !== 0 ? fieldSchema.required('Media is required') : fieldSchema.notRequired();
		}),
	mediaPreviewUrl: yup
		.string()
		.url()
		.when('mediaType', (mediaType: number, fieldSchema: ObjectSchema) => {
			return mediaType !== 0 ? fieldSchema.required('Media preview is required') : fieldSchema.notRequired();
		}),
});

const initialState = {
	Id: 0,
	SiteId: '',
	SurveyType: '',
	Title: '',
	Subtitle: '',
	StartDate: new Date(),
	EndTime: new Date(),
	question1: '',
	question2: '',
	question3: '',
	question4: '',
	question5: '',
	question6: '',
	question7: '',
	question8: '',
	question9: '',
	question10: '',
	lockSurvey: false,
	errors: '',
	MediaType: 0,
	token: '',
	MediaUrl: '',
	MediaPreviewUrl: '',
	buttonMode: false,
	IsDraft: false,
	siteId: '',
	subtitle: '',
	title: '',
	startDate: new Date(),
	EndDate: new Date(),
	mediaType: 0,
	mediaUrl: '',
	mediaPreviewUrl: '',
	surveyType: '',
	endTime: new Date(),
};

const editValuesState = {
	SiteId: '',
	SurveyType: '',
	Title: '',
	Subtitle: '',
	StartDate: new Date(),
	EndTime: new Date(),
	question1: '',
	question2: '',
	question3: '',
	question4: '',
	question5: '',
	question6: '',
	question7: '',
	question8: '',
	question9: '',
	question10: '',
	lockSurvey: false,
	errors: '',
	MediaType: 0,
	token: '',
	MediaUrl: '',
	MediaPreviewUrl: '',
	buttonMode: true,
	IsDraft: false,
	siteId: '',
	subtitle: '',
	title: '',
	startDate: new Date(),
	EndDate: new Date(),
	mediaType: 0,
	mediaUrl: '',
	mediaPreviewUrl: '',
	surveyType: '',
	endTime: new Date(),
};

export interface clientsAndSitesInfo {
	clientsAndSitesData: {
		ClientId: string;
		SiteId: string | null;
		Type: string;
		Name: string;
		completed?: boolean;
		indeterminateStatus?: boolean;
	}[];
}

const Survey = observer(() => {
	const classes = useStyles();
	const sites = React.useContext(stores.context.site);
	const user = React.useContext(stores.context.user);
	const site = React.useContext(stores.context.site);
	const feed = React.useContext(stores.context.feed);
	const content = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	const routes = React.useContext(stores.context.routes);
	const surveyTypes = Object.keys(feed.surveyTypes).map(key => ({
		key: key,
		title: feed.surveyTypes[key],
		selectable: true,
	}));
	const [cardTitle, setCardTitle] = React.useState('Add Survey');
	const date = new Date(new Date().setDate(new Date().getDate() + 1));
	const newdate: any = date.setHours(0, 0);
	const { route } = React.useContext(stores.context.routes);
	const [selectedSites, setSelectedSites] = React.useState<any[]>([]);
	const [bookingLoader, setBookingLoader] = React.useState(false);

	const [userClients, setUserClients] = React.useState<any[]>([]);

	React.useEffect(() => {
		site.fetchSiteAndClientEntities();
		setUserClients(user.allSites);
	}, [route]);

	React.useEffect(() => {
		if (route.params.SiteId) {
			setCardTitle(route.params.Id ? 'Edit Survey' : 'Edit Copy Survey ');
			editValuesState.Title = route.params.Title;
			editValuesState.SiteId = route.params.SiteId;
			editValuesState.Subtitle = route.params.Subtitle;
			editValuesState.StartDate = route.params.StartDate;
			editValuesState.EndTime = route.params.Expires;
			editValuesState.MediaType = route.params.MediaType;
			editValuesState.MediaPreviewUrl = route.params.MediaPreviewUrl;
			editValuesState.MediaUrl = route.params.MediaUrl;
			editValuesState.SurveyType = route.params.SurveyType;
			editValuesState.lockSurvey = route.params.SurveyLock;
			editValuesState.buttonMode = true;
			editValuesState.lockSurvey = route.params.lockSurvey;
			setValues(editValuesState);
			setLockSurvey(route.params.lockSurvey);
		} else {
			editValuesState.buttonMode = false;
		}
	}, []);

	const [values, setValues] = React.useState<ISurvey>(initialState);
	const methods = useForm<ISurvey>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, formState, triggerValidation } = methods;
	const [surveyType, setSurveyType] = React.useState<SelectTreeDataNode>();
	const [lockSurvey, setLockSurvey] = React.useState(route.params.SurveyLock || false);
	const [selectedStartTime, setSelectedStartTime] = React.useState<Date>(
		route.params.StartDate ? new Date(route.params.StartDate) : new Date(),
	);
	const [selectedEndTime, setSelectedEndTime] = React.useState<Date>(
		route.params.Expires ? new Date(route.params.Expires) : newdate,
	);
	const [responseError, setResponseError] = React.useState('');
	const [startTimeError, setStartTimeError] = React.useState('');
	const [endTimeError, setEndTimeError] = React.useState('');
	const [showSurveyForm, setShowSurveyForm] = React.useState(0);
	const [popupErrorMessage, setPopupErrorMessage] = React.useState('');
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.permissions === null ? true : false;
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	appStores.showAppLoader = user.permissions === null ? true : false;
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowSurveyForm(1);
				}
			} else {
				setShowSurveyForm(0);
			}
		} else {
			setShowSurveyForm(0);
		}
	}, [user.permissions]);
	const validateSchema = (date: Date, name: string, message: string, dateValid: Date) => {
		let schema = yup.date().test(name, message, (value: Date) => {
			return compareAsc(value, dateValid) <= 0;
		});
		return schema;
	};

	const handleStartTime = async (date: Date) => {
		setSelectedStartTime(date);
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setEndTimeError('');
		const schema = validateSchema(
			date,
			'startTime',
			'Start date and time should be less than end date and time',
			selectedEndTime,
		);
		schema
			.validate(date)
			.then((response: string) => {
				setStartTimeError('');
			})
			.catch((err: any) => {
				setStartTimeError(err.message);
			});
	};

	React.useEffect(() => {
		if (route.params.SiteId) {
			setCardTitle(
				route.params.status && route.params.status === 'Published'
					? 'Edit Published Survey'
					: route.params.status && route.params.status === 'Draft'
					? 'Edit Survey'
					: 'Edit Copy Survey',
			);
			editValuesState.Title = route.params.Title;
			editValuesState.SiteId = route.params.SiteId;
			editValuesState.Subtitle = route.params.Subtitle;
			editValuesState.StartDate = route.params.StartDate;
			editValuesState.EndTime = route.params.Expires;
			const inactiveLocations = sites.siteList.filter((item: any) => item.LocationStatus === 'INACTIVE');
			if (inactiveLocations.find((item: any) => item.Id === route.params.SiteId)) {
			}
			editValuesState.Title = route.params.Title;
			editValuesState.EndTime = route.params.EndDate;
			editValuesState.MediaType = route.params.MediaType;
			editValuesState.lockSurvey = route.params.lockSurvey;
			route.params.Questions.map((n: any) => {
				if (n.QuestionNumber === 1) {
					editValuesState.question1 = n.QuestionDescription;
				} else if (n.QuestionNumber === 2) {
					editValuesState.question2 = n.QuestionDescription;
				} else if (n.QuestionNumber === 3) {
					editValuesState.question3 = n.QuestionDescription;
				} else if (n.QuestionNumber === 4) {
					editValuesState.question4 = n.QuestionDescription;
				} else if (n.QuestionNumber === 5) {
					editValuesState.question5 = n.QuestionDescription;
				} else if (n.QuestionNumber === 6) {
					editValuesState.question6 = n.QuestionDescription;
				} else if (n.QuestionNumber === 7) {
					editValuesState.question7 = n.QuestionDescription;
				} else if (n.QuestionNumber === 8) {
					editValuesState.question8 = n.QuestionDescription;
				} else if (n.QuestionNumber === 9) {
					editValuesState.question9 = n.QuestionDescription;
				} else {
					editValuesState.question10 = n.QuestionDescription;
				}
			});
			editValuesState.lockSurvey = route.params.SurveyLock;
			editValuesState.MediaPreviewUrl = route.params.MediaPreviewUrl;
			editValuesState.MediaUrl = route.params.MediaUrl;
			editValuesState.buttonMode = true;
			setValues(editValuesState);
			setLockSurvey(route.params.lockSurvey);
		} else {
			editValuesState.buttonMode = false;
		}
	}, []);
	const handleEndTime = (date: Date) => {
		setSelectedEndTime(date);
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setStartTimeError('');
		let schema = yup
			.date()
			.test('startTime', 'End date and time should be greater than start date and time', (value: Date) => {
				return compareAsc(value, selectedStartTime) >= 0;
			});
		schema
			.validate(date)
			.then((response: string) => {
				setEndTimeError('');
			})
			.catch((err: any) => {
				setEndTimeError(err.message);
			});
	};
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
		if (errors.question1?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 1');
			setOpen(true);
		} else if (errors.question2?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 2');
			setOpen(true);
		} else if (errors.question3?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 3');
			setOpen(true);
		} else if (errors.question4?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 4');
			setOpen(true);
		} else if (errors.question5?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 5');
			setOpen(true);
		} else if (errors.question6?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 6');
			setOpen(true);
		} else if (errors.question7?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 7');
			setOpen(true);
		} else if (errors.question8?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 8');
			setOpen(true);
		} else if (errors.question9?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 9');
			setOpen(true);
		} else if (errors.question10?.message === 'Maximum 140 characters allowed') {
			setPopupErrorMessage('Question 10');
			setOpen(true);
		} else {
			setOpen(false);
		}
	};

	const IsDraft = (event: any) => {
		submitForm(event.currentTarget.value);
	};
	const submitForm = async (event: React.SyntheticEvent) => {
		const isValidForm = await triggerValidation();
		localStorage.removeItem('CMValue');
		if (!isValidForm || !formState.isValid) return;
		startTimeError == '' ? setBookingLoader(true) : setBookingLoader(false);
		const SurveyForm = { Category: 0, Parameters: {}, Id: 0, UploadToken: '' };
		const {
			question1,
			question2,
			question3,
			question4,
			question5,
			question6,
			question7,
			question8,
			question9,
			question10,
		} = values;
		const questions: IQuestion[] = [
			question1,
			question2,
			question3,
			question4,
			question5,
			question6,
			question7,
			question8,
			question9,
			question10,
		]
			.filter(q => q.trim() !== '')
			.map((q, i) => ({
				QuestionNumber: i + 1,
				QuestionDescription: q,
				MandatoryAnswer: true,
			}));

		SurveyForm.Category = Category.Survey;
		//let startDate = new Date(selectedStartTime).toISOString();
		let startDate = moment(selectedStartTime, 'YYYY-MM-DDTHH:mm:ss').format();
		let endDate = new Date(selectedEndTime).toISOString();

		SurveyForm.Id = route.params.Id;

		const siteIdsList: any[] = [];
		selectedSites.forEach(item => {
			siteIdsList.push(item.SiteId);
		});

		SurveyForm.Parameters = {
			SiteId: siteIdsList,
			SurveyTypeId: surveyType?.key,
			SurveyTitle: values.Title,
			SurveyDescription: values.Subtitle,
			SurveyLock: lockSurvey,
			StartDate: startDate,
			EndDate: endDate,
			MediaType: values.MediaType,
			FileUrl: values.MediaUrl,
			MediaUrl: values.MediaUrl,
			MediaPreviewUrl: values.MediaType === 2 ? values.MediaUrl : values.MediaPreviewUrl,
			NoOfQuestions: questions.length,
			Questions: questions,
			CreatedBy: user.username,
			ModifiedBy: '',
			IsDraft: Number(event),
		};
		SurveyForm.UploadToken = values.token;
		content
			.saveContentManagementValues(SurveyForm.Id ? SurveyForm : [SurveyForm], route.params.Id ? route.params.Id : '')
			.then(response => {
				setResponseError(response.Errors);
				if (SurveyForm.Id) {
					if (response.Success === true && response.Error === null) {
						setBookingLoader(false);
						notifications.addNotification(
							Number(event) === 1 ? 'Survey is Saved, thank you' : 'Survey is published, thank you',
							'success',
						);
						window.location.href = '/contentManagement/surveylist';
					} else {
						setBookingLoader(false);
						notifications.addNotification('Something went wrong , Please try again', 'error');
					}
				} else {
					setBookingLoader(false);
					if (response.Success === true && response.Errors === null) {
						notifications.addNotification(
							Number(event) === 1 ? 'Survey is Saved, thank you' : 'Survey is published, thank you',
							'success',
						);
						window.location.href = '/contentManagement/surveylist';
					} else {
						if (response.Errors.length > 0 && response.Errors !== null) {
							setBookingLoader(false);
							notifications.addNotification('Something went wrong , Please try again', 'error');
						}
					}
				}
			});
	};

	React.useEffect(() => {
		const defaultType: any = Object.keys(feed.surveyTypes)[0];
		const defaultTypenew: any = Object.keys(feed.surveyTypes)[1];
		if (route.params.SurveyType === 'NPS') {
			setSurveyType({ key: defaultTypenew, title: feed.surveyTypes[defaultTypenew] });
		} else {
			setSurveyType({ key: defaultType, title: feed.surveyTypes[defaultType] });
		}
	}, [feed.surveyTypes]);
	const restriction = (e: any) => {
		if (e.which === 32 && !e.target.value.length) e.preventDefault();
	};

	const toggleButtonState = () => {
		if (
			errors.Title ||
			errors.Subtitle ||
			errors.MediaUrl ||
			errors.MediaPreviewUrl ||
			values.Title === '' ||
			values.question1 === '' ||
			values.Subtitle === '' ||
			!formState.isValid ||
			values.MediaType === 0 ||
			(values.MediaType === 1 && (values.MediaUrl === '' || values.MediaPreviewUrl === '')) ||
			(values.MediaType === 2 && values.MediaUrl === '')
		) {
			return true;
		}
		return false;
	};
	return (
		<>
			<Backdrop className={classes.backdrop} open={bookingLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{showSurveyForm === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item md={4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									{cardTitle}
								</Typography>
								<Divider />
								<Grid className={classes.gridStyle}>
									<Box style={{ marginTop: '8px', marginBottom: '8px' }}>
										<ClientsAndSites
											clientsAndSitesData={userClients}
											setSelectedSites={setSelectedSites}
											status={route.params.status && route.params.status === 'Published' ? true : false}
										/>
									</Box>
									<SelectTree
										label="Type"
										treeData={surveyTypes}
										selectedNode={surveyType}
										checkable={false}
										onSelect={node => {
											node ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
											localStorage.setItem('URL', window.location.pathname);
											setSurveyType(node);
										}}
									/>
									<TextField
										label="Title"
										name="Title"
										variant="filled"
										margin="normal"
										onChange={handleInputChange}
										defaultValue={values.Title}
										fullWidth
										error={Boolean(errors.Title)}
										helperText={errors.Title ? 'Title is required' : ''}
										inputRef={e => {
											register(e);
										}}
									/>
									<TextField
										label="Subtitle"
										type="text"
										name="Subtitle"
										variant="filled"
										fullWidth
										defaultValue={values.Subtitle}
										margin="normal"
										error={Boolean(errors.Subtitle)}
										helperText={errors.Subtitle ? 'SubTitle is required' : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 1"
										name="question1"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question1}
										error={Boolean(errors.question1)}
										helperText={errors.question1 ? errors.question1.message : ''}
										inputRef={e => {
											register(e);
										}}
										onKeyPress={e => {
											restriction(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 2"
										name="question2"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question2}
										error={Boolean(errors.question2)}
										helperText={errors.question2 ? errors.question2.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 3"
										name="question3"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question3}
										error={Boolean(errors.question3)}
										helperText={errors.question3 ? errors.question3.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 4"
										name="question4"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question4}
										error={Boolean(errors.question4)}
										helperText={errors.question4 ? errors.question4.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 5"
										name="question5"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question5}
										error={Boolean(errors.question5)}
										helperText={errors.question5 ? errors.question5.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 6"
										name="question6"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question6}
										error={Boolean(errors.question6)}
										helperText={errors.question6 ? errors.question6.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 7"
										name="question7"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question7}
										error={Boolean(errors.question7)}
										helperText={errors.question7 ? errors.question7.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>

									<TextField
										label="Question 8"
										name="question8"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question8}
										error={Boolean(errors.question8)}
										helperText={errors.question8 ? errors.question8.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>

									<TextField
										label="Question 9"
										name="question9"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question9}
										error={Boolean(errors.question9)}
										helperText={errors.question9 ? errors.question9.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>
									<TextField
										label="Question 10"
										name="question10"
										variant="filled"
										fullWidth
										margin="normal"
										defaultValue={values.question10}
										error={Boolean(errors.question10)}
										helperText={errors.question10 ? errors.question10.message : ''}
										inputRef={e => {
											register(e);
										}}
										onChange={handleInputChange}
									/>

									<Box display="flex" flexDirection="row" mx={1}>
										<Box my={1} flexGrow={1}>
											<Typography>Lock Survey after vote</Typography>
										</Box>
										<Box>
											<Switch
												checked={lockSurvey}
												onChange={e => {
													!lockSurvey
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
													setLockSurvey(!lockSurvey);
												}}
												value={values.lockSurvey}
												color="primary"
												inputProps={{ 'aria-label': 'primary checkbox' }}
											/>
										</Box>
									</Box>
									<DateController
										onStartTime={handleStartTime}
										onEndTime={handleEndTime}
										selectedStartTime={selectedStartTime}
										startTimeError={startTimeError}
										endTimeError={endTimeError}
										selectedEndTime={selectedEndTime}
										ref={register}
										status={route.params.status && route.params.status === 'Published' ? true : false}
									/>
									<UploadComponent methods={methods} values={values} setValues={setValues} />
									{responseError && responseError !== null ? (
										<FormHelperText error={Boolean(responseError)}>{responseError}</FormHelperText>
									) : (
										''
									)}
								</Grid>

								<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
									<Button
										color="primary"
										onClick={() => {
											localStorage.removeItem('CMValue');
											routes.navigate(
												{ name: 'survey' },
												{
													clearParams: true,
												},
											);
										}}
									>
										Cancel
									</Button>
									<Button color="primary" value="1" disabled={toggleButtonState()} onClick={IsDraft}>
										Save as draft
									</Button>
									<Button color="primary" value="0" disabled={toggleButtonState()} onClick={IsDraft} name="0">
										Publish
									</Button>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : showSurveyForm === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
				<Dialog open={open} onClose={handleClose}>
					<DialogContent className={classes.alignStyle} style={{ width: '330px', height: '155px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							{popupErrorMessage} is too long
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '10px' }}>
							Maximum 140 characters allowed
						</DialogContentText>
						<DialogActions>
							<Button style={{ paddingRight: '8px' }} onClick={handleClose} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</main>
		</>
	);
});

export default Survey;

const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	paper: {
		color: theme.palette.text.primary,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		textAlign: 'left',
	},
	dateStyle: {
		fontSize: theme.typography.pxToRem(14),
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	uploader: {
		display: 'none',
	},
	createCancelLink: {
		'& > *': {
			cursor: 'pointer',
			margin: theme.spacing(0, 0, 0, 2),
		},
		margin: theme.spacing(3, 4),
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	keyboardDateTime: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		textAlign: 'left',
		width: '100%',
	},
	formControl: {
		display: 'block',
		width: '100%',
		marginTop: theme.spacing(1),
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.pxToRem(22),
	},
	selectItem: {
		fontSize: theme.typography.pxToRem(16),
		paddingLeft: theme.spacing(2),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},
	alignStyle: {
		padding: '0px 0px !important',
	},

	formControlLabel: {
		width: '100%',
		'& .MuiFilledInput-underline:after': {
			borderColor: '#bd0810',
		},
		'& .MuiFilledInput-underline:before': {
			borderColor: '#bd0810',
		},
		'& .MuiIconButton-label': {
			color: '#bd0810',
		},
		'& .makeStyles-Autocomplete-11 .MuiInputLabel-root': {
			color: '#bd0810',
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
			color: '#bd0810',
		},
	},
	selectTreeWidth: {
		width: '100%',
	},
}));
