import { ICanvasFloorplanDetail } from '../../types';
const copyControl = {
	mouseDown(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		if (canvas.getActiveObject()) {
			canvas.getActiveObject().clone((cloned: fabric.Object) => {
				if (cloned && cloned.left) {
					cloned.set({
						left: cloned.left + 20,
					});
					canvas.add(cloned);
					canvas.setActiveObject(cloned);
					floorplanDetail.showPropContainer = true;
				}
			});
		}
	},

	mouseUp(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		canvas.selection = true;
	},
	mouseMove(canvas: fabric.Canvas, options: fabric.IEvent) {},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
};

export default {
	...copyControl,
};
