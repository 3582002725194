import { makeStyles } from '@material-ui/core';

const sidebarUseStyles = makeStyles(theme => ({
	contentMenuFeed: {
		paddingTop: '0px',
		paddingBottom: '0px',
		'& .MuiListItemText-primary': {
			fontSize: '14px !important',
			color: '#8594A5 !important',
			fontFamily: 'Roboto !important',
		},
		disableRipple: true,
		'& .MuiList-padding': {
			paddingTop: '0px !important',
			paddingBottom: '0px !important',
		},
		'& .MuiListItemText-root': {
			marginTop: '0px !important',
			marginBottom: '0px !important',
		},
		'& .MuiListItem-button': {
			marginBottom: '6px !important',
			width: '228px',
			height: '40px',
			borderRadius: '4px',
			margin: 'auto',
		},
		'& > * svg': {
			opacity: '1',
			color: '#8594A5 !important',
			marginLeft: '2px',
		},
		'& .MuiListItem-root': {
			disableTouchRipple: 'true',
		},

		'& .MuiListItem-button:hover': {
			'& > * svg': {
				color: '#5B6F86 !important',
			},
			width: '228px',
			height: '40px',
			margin: 'auto',
			backgroundColor: '#F4F8FC !important',
			border: 'none !important',
			'& .MuiTypography-body1': {
				color: '#5B6F86 !important',
				opacity: '1',
			},
		},
		'& .Mui-selected': {
			backgroundColor: '#CFDBE5 !important',
			marginBottom: '6px !important',
			'& .MuiTypography-body1': {
				color: '#5B6F86 !important',
			},
			'& .MuiListItemText-primary': {
				fontSize: '14px !important',
				color: '#5B6F86 !important',
				fontFamily: 'Roboto !important',
			},
			'& > * svg': {
				color: '#5B6F86 !important',
			},
		},
		'& .Mui-selected:hover': {
			backgroundColor: '#CFDBE5 !important',
			'& .MuiListItemText-primary': {
				fontSize: '14px !important',
				color: '#5B6F86 !important',
				fontFamily: 'Roboto!important',
			},
			'& > * svg': {
				color: '#5B6F86 !important',
			},
		},
	},
}));

export default sidebarUseStyles;
