import { IconButton, Snackbar, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';

import NotificationStore from '../store/Notifications';
import { INotification } from '../types';

interface INotificationProps {
	className?: string;
	notification: INotification;
	notifications: NotificationStore;
}

const useStyles = makeStyles(theme => ({
	success: {
		'& div': {
			backgroundColor: theme.palette.success.main,
		},
	},
	error: {
		'& div': {
			backgroundColor: theme.palette.error.main,
		},
	},
	warning: {
		'& div': {
			backgroundColor: theme.palette.warning.main,
		},
	},
	info: {
		'& div': {
			backgroundColor: theme.palette.info.main,
		},
	},
	delete: {
		'& div': {
			backgroundColor: theme.palette.success.contrastText,
		},
	},
}));

const Notification = (NotificationProps: INotificationProps) => {
	const [open, setOpen] = React.useState(true);
	const classes = useStyles();
	const handleClose = () => {
		setOpen(false);
	};

	const { message, status, id } = NotificationProps.notification;
	const { deleteNotification } = NotificationProps.notifications;

	return useObserver(() => (
		<div className={classes[status]}>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				message={<span>{message}</span>}
				className={status}
				action={[
					<IconButton key="close" aria-label="Close" color="inherit" className="close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>,
				]}
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				onExited={() => {
					deleteNotification(id);
				}}
			/>
		</div>
	));
};

export default Notification;
