import * as React from 'react';
import Spaces from './spaces';
import Feed from './feed';
import Ask from './ask';
import Refuel from './refuel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	sideBarList: {
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
		marginBottom: '6px !important',
		disableRipple: true,
		'& .Mui-selected': {
			backgroundColor: '#66CCFA',
			'& .MuiTypography-body1': {
				color: '#FFFFFF',
				font: 'normal normal normal 16px/12px Roboto',
			},
			'& > * svg': {
				color: '#FFFFFF',
				width: '18px',
				height: '18px',
				opacity: '1',
				letterSpacing: '0px',
				textAlign: 'left',
				font: 'normal normal 300 18px/12px Font Awesome 5 Pro',
			},
		},
		'& .Mui-selected:hover': {
			backgroundColor: '#66CCFA !important',
			width: '228px',
			border: '2px #66CCFA !important',
			padding: '-4px !important',
			opacity: 1,
			'& .MuiTypography-body1': {
				font: 'normal normal normal 16px/12px Roboto',
				color: '#FFFFFF!important',
				opacity: 1,
			},
			'& > * svg': {
				color: '#FFFFFF!important',
				opacity: 1,
			},
		},
		'& .MuiTypography-body1': {
			fontWeight: theme.typography.fontWeightMedium,
		},

		'& > * svg': {
			color: '#14A0D6',
			width: '18px',
			height: '18px',
			letterSpacing: '0px',
			textAlign: 'left',
			font: 'normal normal 300 18px/12px Font Awesome 5 Pro',
			opacity: 1,
		},
		'& .MuiListItemText-primary': {
			width: '35px',
			height: '19px',
			color: '#14A0D6',
			font: 'normal normal normal 16px/12px Roboto',
			letterSpacing: '0px',
			textAlign: 'left',
			opacity: 1,
		},
		'& .MuiListItemText-primary:hover': {
			color: '#66CCFA',
			width: '35px',
			height: '19px',
			font: 'normal normal normal 16px/12px Roboto',
			textAlign: 'left',
			letterSpacing: '0px',
			opacity: 1,
		},
		'& .MuiListItem-button': {
			width: '228px',
			height: '40px',
			borderRadius: '4px',
			margin: 'auto',
			font: 'normal normal normal 16px/12px Roboto',
			opacity: 1,
		},
		'& .MuiListItemText-root': {
			marginTop: '0px !important',
			marginBottom: '0px !important',
			color: '#FFFFFF',
		},
		'& .MuiListItem-button:hover': {
			'& > * svg': {
				color: '#66CCFA',
				opacity: 1,
				height: '18px',
				letterSpacing: '0px',
				textAlign: 'left',
				font: 'normal normal 300 18px/12px Font Awesome 5 Pro',
			},
			borderRadius: '4px',
			backgroundColor: '#FFFFFF',
			border: '2px solid #66CCFA',
			opacity: 1,
			'& .MuiListItemText-primary': {
				color: '#66CCFA',
				width: '35px',
				height: '19px',
				font: 'normal normal normal 16px/12px Roboto',
				letterSpacing: '0px',
				textAlign: 'left',
				opacity: 1,
			},
		},
	},
	listIcon: {
		minWidth: 'auto',
		width: '32px',
	},
	listCollapse: {
		'& .MuiListItemText-root': {
			paddingLeft: theme.spacing(2),
		},
	},
	listCollapseSelected: {
		'& .MuiListItemText-root': {
			paddingLeft: theme.spacing(0),
		},
	},
	listCollapseIcon: {
		display: 'flex',
	},
	menuList: {},
}));

const Sidebar = () => {
	const classes = useStyles();
	const menuItems: any[] = [
		{ name: 'feed', component: <Feed classNames={classes} /> },
		{ name: 'ask', component: <Ask classNames={classes} /> },
		{ name: 'refuel', component: <Refuel classNames={classes} /> },
		{ name: 'spaces', component: <Spaces classNames={classes} /> },
	];

	return (
		<div>
			{menuItems.map((item: any) => (
				<div className={classes.menuList}>{item.component}</div>
			))}
		</div>
	);
};

export default Sidebar;
