import { action, observable } from 'mobx';
import { getAPI, postAPI, deleteAPI, putAPI } from 'services/apiService';
import { IFloorplanImageAPIResponse } from '../types';
import { stores } from '.';

interface IFloorplanListOptions {
	pCount?: number;
	pSize?: number;
	sort?: string;
	order?: string;
	searchText?: string;
}

interface IFloorplanDetail {
	fileObject: string;
	fileContentType: string;
	fileName: string;
	fileFormat: string;
	active: boolean;
	default: boolean;
	floorplanId: string;
}

interface IEntitiesRequestParams {
	clientId: string;
	EntityId?: string;
	isDescendants?: string;
}
class Spaces {
	@observable
	public allFloorplans: any = {};

	@observable
	public BookDataList: any = [];

	@observable
	public eventList: any = [];

	@observable
	public condecoDetails: any = null;

	@observable
	public currentSiteId: any = '';

	@observable
	public Availabledays: any = '';

	@observable
	public officeHours: any = '';

	@observable
	public featuresData: any = '';

	@observable
	public floorentities: any = '';

	@observable
	public flrInfoEditor: any = [];

	@action
	public async fetchFloorplans(floorplanListOptions: IFloorplanListOptions) {
		const postData = {
			sort: floorplanListOptions.sort,
			order: floorplanListOptions.order,
			pCount: floorplanListOptions.pCount ? floorplanListOptions.pCount + 1 : 1,
			pSize: floorplanListOptions.pSize,
		};

		this.allFloorplans = await postAPI('/spaces/allClients/floorplans/fetch', postData);
	}

	@action
	public async fetchAllClients() {
		return await postAPI('/spaces/allClients/floorplans/fetch', {}).then((clientList: any) => {
			let allClients: any = [];
			let map = new Map();
			clientList['floorplans'].forEach((node: any) => {
				if (!map.has(node.clientId)) {
					map.set(node.clientId, true);
					allClients.push({
						ClientId: node.clientId,
						EntityId: false,
						name: node.clientName,
						title: node.clientName,
						key: node.clientId,
						isFlag: true,
					});
				}
			});
			return allClients;
		});
	}

	@action
	public async fetchAllClientsSpaces() {
		return await postAPI('/spaces/allClients/floorplans/fetch', {}).then((clientList: any) => {
			return clientList;
		});
	}

	@action
	public async fetchEntities(entitiesParams: IEntitiesRequestParams, isFormatRequired: boolean) {
		let getClientEntities = '/api/client/' + entitiesParams.clientId;
		getClientEntities += entitiesParams.EntityId ? '/entity/' + entitiesParams.EntityId : '';
		getClientEntities += entitiesParams.isDescendants ? '/' + entitiesParams.isDescendants : '';
		let entitiesList = await getAPI(getClientEntities);
		return isFormatRequired
			? this.formatEntities(entitiesList)
			: entitiesList && entitiesList.Entities
			? entitiesList.Entities
			: [];
	}

	private formatEntities(entityResponse: any) {
		if (entityResponse && entityResponse['Entities']) {
			let floorplanList = entityResponse['Entities'].map((node: any) => {
				return {
					name: node.Name,
					EntityId: node.Entity_Id,
					ClientId: node.Client_Id,
					title: node.Name,
					key: node.Entity_Id,
					selectable: node.Type === 'floor' ? true : false,
					isLeaf: node.Type === 'floor' ? true : false,
				};
			});
			return floorplanList;
		} else {
			return entityResponse;
		}
	}

	@action
	public async uploadFloorplan(floorplanDetail: IFloorplanDetail) {
		let postData = {
			entityId: floorplanDetail.floorplanId,
			floorplanDetails: {
				fileObject: floorplanDetail.fileObject,
				fileContentType: floorplanDetail.fileContentType,
				fileName: floorplanDetail.fileName,
				fileFormat: floorplanDetail.fileFormat,
				active: floorplanDetail.active,
				default: floorplanDetail.default,
			},
		};
		return await postAPI('/spaces/floorplan/save', postData);
	}

	@action
	public async fetchFloorplanImage(floorplanId: string) {
		const floorplanImageDetail: IFloorplanImageAPIResponse = await getAPI('/api/floorplanimage/' + floorplanId);

		return floorplanImageDetail;
	}

	@action
	public async fetchFloorplanCoordinates(floorId: string, type: string) {
		return await getAPI('/api/coordinates/' + floorId + '/type/' + type);
		//console.log('heyyyy',ss);
	}

	@action
	public async fetchZoneDetails(buildingId: string, floorId: string) {
		//console.log(buildingId,floorId);
		//console.log('buildingid',buildingId);
		return await getAPI('/api/entity/' + buildingId + '/level/' + floorId + '/data/zone');
	}

	@action
	public async getCurrentSiteId() {
		this.currentSiteId = stores.user.currentSiteId;
	}

	@action
	public async fetchDeskAvailability(postData: {}) {
		return await postAPI('/spaces/DeskAvailability', postData);
	}

	@action
	public async checkInBooking(bookId: any) {
		let newData = {
			bookingId: bookId,
		};

		return await postAPI(`/spaces/CheckIn`, newData);
	}

	@action
	public async fetchRoomAvailability(postData: {}) {
		return await postAPI('/spaces/RoomAvailability', postData);
	}

	@action
	public async RoomAvailable(roomId: any, startDateTime: any, endDateTime: any) {
		return await getAPI(
			'/api/room/' +
				roomId +
				'/status' +
				'?' +
				'startDateTime' +
				'=' +
				startDateTime +
				'&' +
				'endDateTime' +
				'=' +
				endDateTime,
		);
	}

	@action
	public async DeskAvailable(roomId: any, startDateTime: any, endDateTime: any) {
		return await getAPI(
			'/api/booking/Desk/' +
				roomId +
				'/status' +
				'?' +
				'startDateTime' +
				'=' +
				startDateTime +
				'&' +
				'endDateTime' +
				'=' +
				endDateTime,
		);
	}

	@action
	public async DeskBooking(RoomId: any, recordType: any, StartTime: any, EndTime: any, subject: any, SetUpTypeId: any) {
		let postData = {
			RoomId: RoomId,
			recordType: recordType,
			StartTime: StartTime,
			EndTime: EndTime,
			subjectTitle: subject,
			defaultsetupId: SetUpTypeId,
		};
		//console.log(postData);
		return await postAPI('/spaces/Deskreservation', postData);
	}

	@action
	public async BookingList() {
		const BkData = await getAPI('/api/booking/mybookings');
		return (this.BookDataList = BkData && BkData.map((items: any) => ({ ...items, showOpenCard: false })));
	}

	// @action
	// public async meetingeventlist() {
	// 	const tt: any = await getAPI('/api/room/eventtypes');
	// 	return (this.eventList = tt);
	// 	//console.log('hello',tt);
	// 	//return (this.BookDataList = BkData && BkData.map((items: any) => ({ ...items, showOpenCard: false })));
	// }
	@action
	public async meetingeventlist() {
		const tt: any = await getAPI('/spaces/room/eventtypes');
		return (this.eventList = tt);

		//return (this.BookDataList = BkData && BkData.map((items: any) => ({ ...items, showOpenCard: false })));
	}

	@action
	public async fetchFeatures() {
		const features: any = await getAPI('/spaces/room/features');
		return (this.featuresData = features);

		//return (this.BookDataList = BkData && BkData.map((items: any) => ({ ...items, showOpenCard: false })));
	}

	@action
	public async EditorFlrData(flrid: string) {
		const flrInfo: any = await getAPI('/spaces/room/EditorFlrData/' + flrid);
		return (this.flrInfoEditor = flrInfo);

		//return (this.BookDataList = BkData && BkData.map((items: any)=> ({ ...items, showOpenCard: false })));
	}

	@action
	public async florentities(floorId: string, entitytype: number) {
		const flrentities: any = await getAPI('/spaces/floorentitiesupdated/' + floorId + '/' + entitytype);
		return (this.floorentities = flrentities);
	}

	@action
	public async RoomBooking(
		RoomId: any,
		recordType: any,
		StartTime: any,
		EndTime: any,
		subject: any,
		EventTypeId: any,
		SetUpTypeId: any,
	) {
		let postData = {
			RoomId: RoomId,
			recordType: recordType,
			StartTime: StartTime,
			EndTime: EndTime,
			subjectTitle: subject,
			EventTypeId: EventTypeId,
			defaultsetupId: SetUpTypeId,
		};
		//console.log(postData);
		return await postAPI('/spaces/reservation', postData);
	}

	@action
	public async RoomBookingUpdated(
		RoomId: any,
		recordType: any,
		StartTime: any,
		EndTime: any,
		subject: any,
		EventTypeId: any,
		SetUpTypeId: any,
		zoneType: any,
	) {
		let postData = {
			RoomId: RoomId,
			recordType: recordType,
			StartTime: StartTime,
			EndTime: EndTime,
			subjectTitle: subject,
			EventTypeId: EventTypeId,
			defaultsetupId: SetUpTypeId,
		};
		//console.log(postData);
		return await postAPI(`/spaces/room/reservationupdated/${zoneType}`, postData);
	}

	@action
	public async sendInvitation(Attendeeslist: any, BookingId: any) {
		let postData = {
			EmailIds: Attendeeslist,
			BookingId,
		};
		//console.log('hii',postData);
		return await postAPI('/spaces/addattendees', postData);
	}

	@action
	public async quickspacefilters(ptData: any) {
		return await postAPI(`/spaces/Room/filter`, ptData);
	}

	@action
	public async quickdeskfilters(ptData: any) {
		return await postAPI(`/spaces/Desk/filter`, ptData);
	}

	@action
	public async deleteBooking(bookId: string) {
		return await deleteAPI(`/spaces/cancelbooking/${bookId}`, {});
	}

	@action
	public async saveFloorplanCoordinates(floorId: string, floorplanCoordinatesDetail: any) {
		return await postAPI('/spaces/coordinates/' + floorId, floorplanCoordinatesDetail);
	}

	@action
	public async deleteFloorplanCoordinates(floorId: string) {
		return await getAPI('/api/floorplancoordinates/' + floorId);
	}

	@action
	public async deleteFloorWithFloorID(floorId: string) {
		return await deleteAPI(`/spaces/deleteFloorWithFloorId/${floorId}`, {});
	}

	@action
	public async changeFloorStaus(floorID: string, status: boolean) {
		return await postAPI(`/spaces/changeFloorStaus/${floorID}/status/${status}`, {});
	}

	@action
	public async ExistingAttendees(BoookingId: string) {
		return await getAPI('/api/attendees/' + BoookingId);
	}

	@action
	public async getBookedDateTime(roomId: number, startTimeSlot: string, endTimeSlot: string) {
		return await getAPI(`/spaces/bookeddata/${roomId}/${startTimeSlot}/${endTimeSlot}`);
	}

	@action
	public async getDeskBookedDateTime(roomId: number, startTimeSlot: string, endTimeSlot: string) {
		return await getAPI(`/spaces/bookeddeskdata/${roomId}/${startTimeSlot}/${endTimeSlot}`);
	}

	@action
	public async deleteRoomOrDeskCoordinates(floorplanId: string, entityId: string) {
		return await getAPI('/api/roomordeskcoordinates/' + floorplanId + '/entityId/' + entityId);
	}

	@action
	public async fetchEntitiesZone(
		entitiesParams: IEntitiesRequestParams,
		isFormatRequired: boolean,
		SiteID: string,
	): Promise<any> {
		const postData = {
			ClientId: entitiesParams.clientId,
			SiteId: SiteID,
			levelId: entitiesParams.EntityId,
		};
		return this.formatEntities(await postAPI('/spaces/booking/Desk/Room/Available', postData));
	}

	@action
	public async fetchCondecoDetails(siteId: string) {
		this.condecoDetails = await getAPI(`/spaces/${siteId}`);
		return this.condecoDetails;
	}

	@action
	public async roomdatesavailability(entityId: string) {
		const dtt: any = await getAPI('/spaces/roomavailabledates/' + entityId);

		return (this.Availabledays = dtt);
	}

	@action
	public async dynamichours(siteId: string) {
		this.officeHours = await getAPI(`/spaces/officehours/${siteId}`);
		return this.officeHours;
	}

	@action
	public async reorderFloorsPlan(floorId: string, position: string) {
		const floorplans = await putAPI(`/spaces/floorplan/${floorId}/position/${position}`, {});
		return floorplans;
	}

	@action
	public async defaultfloorplandata(pagenumber: string) {
		const floorplans = await getAPI(`/spaces/defaultfloordata/${pagenumber}`);
		return floorplans;
	}

	@action
	public async defaultfloorspagewisedata(pagenumber: string, pagecount: string) {
		const floorplans = await getAPI(`/spaces/defaultfloorspagewisedata/${pagenumber}/${pagecount}`);
		return floorplans;
	}

	@action
	public async changeFloorImage(floorID: string, data: any) {
		return await postAPI(`/spaces/updateFloorPlanImage/${floorID}`, { ...data });
	}

	@action
	public async getCordinate(payload: any, zoneType: number) {
		return await postAPI(`/spaces/coordinate/${zoneType}`, payload);
	}
}

export default Spaces;
