import * as React from 'react';
import { stores } from '../../store';
import { observer } from 'mobx-react-lite';
import {
	Box,
	makeStyles,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { autorun } from 'mobx';
import { ISiteObject } from '../../types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
		},
		'& .MuiFormControl-marginNormal': {
			marginTop: '12px !important',
			marginBottom: '4px !important',
			left: '12px !important',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '460px !important',
			height: '40px',
		},
		'@media (min-width: 0px) and (max-width: 600px)': {
			'& .MuiAutocomplete-inputRoot': {
				width: '460px !important',
			},
		},
		'@media (min-width: 950px) and (max-width: 1250px)': {
			'& .MuiAutocomplete-inputRoot': {
				width: '460px !important',
			},
		},

		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-popupIndicatorOpen': {
			transform: 'none !important',
		},
		'& .MuiAutocomplete-root': {
			width: '110% !important',
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
			paddingRight: '38px !important',
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			paddingRight: '38px !important',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
	},
	option: {
		'&[data-focus="true"]': {
			backgroundColor: '#f4f8fc',
		},
		'&[aria-selected="true"]': {
			backgroundColor: '#f4f8fc',
		},
	},
	selectStyle: {
		width: '460px',
	},
	searchIcon: {
		verticalAlign: 'middle',
		marginTop: '5px',
	},
}));

const SiteLocation = observer(() => {
	const user = React.useContext(stores.context.user);
	const site = React.useContext(stores.context.site);

	const notifications = React.useContext(stores.context.notifications);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : site.siteList;
	const [hasError, setHasError] = React.useState(false);
	const [siteLocation, setSiteLocation] = React.useState<any>();
	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [siteVal, setSite] = React.useState('');
	const [siteKey, setSiteKey] = React.useState(0);
	const siteNamenew: any = df.findIndex((nam: any) => nam.Id == siteVal);
	const [changeLocation, setChangeLocation] = React.useState<any>();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [siteNewLocation, setSiteNewLocation] = React.useState<any>();
	React.useEffect(
		() =>
			autorun(() => {
				if (user.currentSiteId) {
					setSiteLocation(df[siteName]);
					setSiteNewLocation(df[siteNamenew]);
					setSiteKey(siteKey + 1);
				}
			}),
		[user.currentSiteId, siteVal, site.siteList],
	);
	const handleClose = () => {
		setDialogOpen(false);
		if (localStorage.getItem('URL') !== window.location.pathname) {
			localStorage.removeItem('CMValue');
			localStorage.removeItem('URL');
		}
		setSite(user.currentSiteId);
	};
	const handleClickOpen = (node: any) => {
		setDialogOpen(true);
		setChangeLocation(node);
	};
	const updateUserSite = async (siteVal: any) => {
		const response = await user.updateUserSite(siteVal);
		if (!response) {
			notifications.addNotification('Your default location is updated', 'success');
			if (localStorage.getItem('URL') === '/contentManagement/announcements') {
				window.location.href = '/contentManagement/AnnouncementList';
			} else if (window.location.pathname === '/contentManagement/announcements') {
				window.location.href = '/contentManagement/AnnouncementList';
			} else if (localStorage.getItem('URL') === '/contentManagement/promotion') {
				window.location.href = '/contentManagement/PromotionList';
			} else if (window.location.pathname === '/contentManagement/promotion') {
				window.location.href = '/contentManagement/PromotionList';
			} else if (localStorage.getItem('URL') === '/contentManagement/evacuation') {
				window.location.href = '/contentManagement/evacuationList';
			} else if (window.location.pathname === '/contentManagement/evacuation') {
				window.location.href = '/contentManagement/evacuationList';
			} else if (localStorage.getItem('URL') === '/contentManagement/survey') {
				window.location.href = '/contentManagement/surveyList';
			} else if (window.location.pathname === '/contentManagement/survey') {
				window.location.href = '/contentManagement/surveyList';
			} else if (localStorage.getItem('URL') === '/refuel/edit-menus/') {
				window.location.href = '/refuelList';
			} else if (window.location.pathname === '/refuel/edit-menus/') {
				window.location.href = '/refuelList';
			} else if (localStorage.getItem('URL') === '/refuel/addMenus') {
				window.location.href = '/refuelList';
			} else if (window.location.pathname === '/refuel/addMenus') {
				window.location.href = '/refuelList';
			} else if (localStorage.getItem('URL') === '/spaces/floorplanUploader') {
				window.location.href = '/spaces/floorplans';
			} else if (window.location.pathname === '/spaces/floorplanUploader') {
				window.location.href = '/spaces/floorplans';
			} else if (window.location.href.indexOf('floorplanEditor') != -1) {
				window.location.href = '/spaces/floorplans';
			} else if (window.location.href.indexOf('floorplanEditor') != -1) {
				window.location.href = '/spaces/floorplans';
			} else if (localStorage.getItem('URL') === '/spaces/addAttendees') {
				localStorage.removeItem('URL');
				localStorage.removeItem('CMValue');
				window.location.href = '/spaces/spacesHome/NA/NA';
			} else if (window.location.href.indexOf('/spaces/addAttendees') != -1) {
				window.location.href = '/spaces/spacesHome/NA/NA';
			} else {
				localStorage.removeItem('URL');
				localStorage.removeItem('CMValue');
				window.location.reload();
			}
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};

	const classes = useStyles();

	return (
		<>
			<main>
				<Box display="flex" flexDirection="row">
					<Box flexBasis="48%" className={classes.selectStyle}>
						<div key={siteKey}>
							<Autocomplete
								disableClearable
								popupIcon={<SearchIcon />}
								options={(sitedatanew ? df : site.siteList).filter((item: any) => { 
									if (item.LocationStatus === 'INVALID' || item.LocationStatus === 'INACTIVE'){
										return false;
									} else{
										return true;
									}
								})}
								getOptionLabel={option => option.Name}
								size="small"
								className={classes.Autocomplete}
								classes={{
									option: classes.option,
								}}
								id="combo-box-demo"
								noOptionsText="Loading..."
								defaultValue={sitedatanew ? df[siteName] : siteLocation}
								value={siteNewLocation}
								onChange={(event: any, newValue: any) => {
									const inactiveLocations = (sitedatanew ? df : site.siteList).filter(
										(item: any) => (item.LocationStatus === 'INACTIVE' || item.LocationStatus === 'INVALID'),
									);
									if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
										setHasError(true);
									} else {
										setHasError(false);
									}
									if (window.location.href.indexOf('floorplanEditor') != -1) {
										localStorage.removeItem('floorId');
										localStorage.removeItem('clientId');
									}
									if (
										localStorage.getItem('CMValue') === 'true' &&
										localStorage.getItem('URL') === window.location.pathname
									) {
										handleClickOpen(newValue.Id);
									} else if (!newValue) {
										setSite(user.currentSiteId);
									} else {
										setSite(newValue.Id);
										updateUserSite(newValue.Id);
									}
								}}
								renderInput={(params: any) => (
									<TextField {...params} label="Current Location" variant="outlined" fullWidth margin="normal" />
								)}
							/>
							{hasError === true ? (
								<FormHelperText style={{ margin: 'auto' }}>
									This site is inactive, please select another site
								</FormHelperText>
							) : (
								''
							)}
						</div>
					</Box>

					<Box flexBasis="20%"></Box>
				</Box>

				<Dialog open={dialogOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
					<DialogTitle id="alert-dialog-title">{' Change location ?'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							You have unsaved changes that will be <br /> lost if you continue. Are you sure you <br />
							want to change location ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={() => {
								updateUserSite(changeLocation);
							}}
						>
							Continue
						</Button>
					</DialogActions>
				</Dialog>
			</main>
		</>
	);
});

export default SiteLocation;
