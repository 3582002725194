import React, { useState } from 'react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import { IMenuGetItem } from 'types';
import '../assets/slidercss/carousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import { Swiper as SwiperCore } from 'swiper/types';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';

const Carousel = ({ menus, classes }: any) => {
	const swiperRef = React.useRef<SwiperCore>();
	const [ispreEnable, setPreButtonEnable] = useState(false);
	const [isNextEnable, setNextButtonEnable] = useState(true);

	const effectTarget = (effectParams: any, $slideEl: any) => {
		if (effectParams.transformEl) {
			return $slideEl.find(effectParams.transformEl).css({
				'backface-visibility': 'hidden',
				'-webkit-backface-visibility': 'hidden',
			});
		}

		return $slideEl;
	};

	return (
		<div className={'carousel-container'}>
			{ispreEnable ? (
				<button className={'button-prev'} onClick={() => swiperRef.current?.slidePrev()}>
					<Icon style={{ color: '#66CCFA', cursor: 'pointer', fontSize: '46' }} icon={faArrowCircleLeft} />
				</button>
			) : (
				<button className={'button-prev disabled'} disabled>
					<Icon className="disableIcon" style={{ color: '#E3E4E5', fontSize: '46' }} icon={faArrowCircleLeft} />
				</button>
			)}

			<Swiper
				effect={'coverflow'}
				grabCursor={false}
				centeredSlides={true}
				slidesPerView={5}
				initialSlide={0}
				spaceBetween={350}
				slideToClickedSlide={true}
				coverflowEffect={{
					rotate: 20,
					stretch: 100,
					depth: 200,
					modifier: -1,
					scale: 1,
					slideShadows: false,
				}}
				onBeforeInit={swiper => {
					swiperRef.current = swiper;
					setPreButtonEnable(false);
				}}
				onAfterInit={swiper => {
					if (swiper.slides.length == 1) {
						setNextButtonEnable(false);
					}
				}}
				onSlideChange={swiper => {
					if (swiper.activeIndex == 0) {
						setPreButtonEnable(false);
						setNextButtonEnable(true);
					}
					// most right postion
					else if (swiper.activeIndex == swiper.slides.length - 1) {
						setPreButtonEnable(true);
						setNextButtonEnable(false);
					}
					// middle positions
					else {
						setPreButtonEnable(true);
						setNextButtonEnable(true);
					}
				}}
				pagination={{ clickable: true }}
				modules={[EffectCoverflow, Pagination, Navigation]}
				className="mySwiper"
				onSetTranslate={(swiper, tran) => {
					const { width: swiperWidth, height: swiperHeight } = swiper;
					const slides = swiper.slides;
					const slidesSizesGrid = swiper['slidesSizesGrid'];
					const params: any = swiper.params.coverflowEffect;
					const isHorizontal = swiper.isHorizontal();
					const transform = swiper.translate;
					const center = isHorizontal ? -transform + swiperWidth / 2 : -transform + swiperHeight / 2;
					const rotate = isHorizontal ? params?.rotate : -`${params?.rotate}`;
					const translate = params?.depth; // Each slide offset from center

					for (let i = 0, length = slides.length; i < length; i += 1) {
						const $slideEl = slides.eq(i);
						const slideSize = slidesSizesGrid[i];
						const slideOffset = $slideEl[0]['swiperSlideOffset'];
						const centerOffset = (center - slideOffset - slideSize / 2) / slideSize;
						const offsetMultiplier =
							typeof params.modifier === 'function' ? params.modifier(centerOffset) : centerOffset * params.modifier;

						let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
						if (offsetMultiplier < 0) {
							rotateY = isHorizontal ? -45 : 0;
						}

						if (offsetMultiplier > 0) {
							rotateY = isHorizontal ? 45 : 0;
						}

						let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier; // var rotateZ = 0

						let translateZ = -(translate ? translate : 0) * Math.abs(offsetMultiplier);
						let stretch: any = params?.stretch; // Allow percentage to make a relative stretch for responsive sliders

						if (typeof stretch === 'string' && stretch.indexOf('%') !== -1) {
							stretch = (parseFloat(params?.stretch) / 100) * slideSize;
						}

						let translateY = isHorizontal ? 0 : stretch * offsetMultiplier;
						let translateX = isHorizontal ? stretch * offsetMultiplier : 0;
						let getOptions: any = swiper.params;

						if (offsetMultiplier > 0) {
							translateX = translateX - getOptions?.spaceBetween - params?.stretch - 100;
							translateZ = translateZ + getOptions?.spaceBetween;
						}

						let scale = 1 - (1 - params.scale) * Math.abs(offsetMultiplier); // Fix for ultra small values

						if (offsetMultiplier == 0) {
							scale = 1;
						} else {
							scale = 1.5;
						}

						const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(${scale})`;
						const $targetEl = effectTarget(params, $slideEl);
						$targetEl.transform(slideTransform);
						$slideEl[0]['style'].zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
					}
				}}
			>
				{menus.map((menu: IMenuGetItem) =>
					menu.MediaType == 'Image' ? (
						<SwiperSlide>
							<img
								src={menu.MediaUrl}
								style={{
									objectFit: 'cover',
									cursor: 'pointer',
									width: '480px !important',
									height: '680px !important',
								}}
								alt="dd"
							/>
						</SwiperSlide>
					) : (
						<SwiperSlide>
							<iframe
								src={`${menu.MediaUrl}#scrollbar=0`}
								id={`${menu.Id}`}
								style={{
									height: '680px',
									borderRadius: '20px',
									minWidth: '480px',
									maxWidth: '600px',
									cursor: 'pointer',
									border: 0,
								}}
							/>
						</SwiperSlide>
					),
				)}
			</Swiper>

			{isNextEnable ? (
				<button className={'button-next'} onClick={() => swiperRef.current?.slideNext()}>
					<Icon style={{ color: '#66CCFA', cursor: 'pointer', fontSize: '46' }} icon={faArrowCircleRight} />
				</button>
			) : (
				<button className={'button-next'}>
					<Icon className="disableIcon" style={{ color: '#E3E4E5', fontSize: '46' }} icon={faArrowCircleRight} />
				</button>
			)}
		</div>
	);
};
export default Carousel;
