import canvasProperties from './CanvasProperties';
import rectangleControl from './Rectangle';
import polygonControl from './Polygon';
import circleControl from './Circle';
import copyControl from './Copy';
import panControl from './Pan';
import zoomControl from './Zoom';
import { Spaces } from 'store';
import { IRoute } from 'store/Routes';
import {
	ICanvasFloorplanDetail,
	IObject,
	IActiveCoordinates,
	IFloorCoordinates,
	IPoint,
	ICoordinatedPoint,
} from '../../types';

const controls = {
	rect: rectangleControl,
	polygon: polygonControl,
	circle: circleControl,
	copy: copyControl,
	isPanMode: panControl,
	zoomIn: zoomControl,
	zoomOut: zoomControl,
};

const fabric = canvasProperties.fabric;
let aspectRatio = 0;
let canvas: fabric.Canvas;
let drawerOpen: boolean | string = 'initialCall';
let drawerOpenCanvasWidth = 0;
let canvasWidth = 0;
let canvasHeight = 0;
let scaleFactor = 0;
let editorCanvasWidth = 0;
let displayScaleFactor = 1;

let spaces: Spaces;
let route: IRoute;
let entityType: string;

let showStatusForDeleteCoordinates: () => void;
let setMappedEntityIds: (value: string[]) => void;

let floorplanDetail: ICanvasFloorplanDetail = {
	URL: '',
	propertyTypeId: '',
	propertyId: '',
	propertyName: '',
	controlType: '',
	savedFloorplanCoordinates: [],
	showPropContainer: false,
};

let entityIdToRoomId = {};
let AvailabilityApiRes = {};
let filterApires: any;
let filteredZoneData: any;

let ZoneData: any;

const canvasEditor = {
	floorplanDetail: floorplanDetail,

	initDrawerOpen(appBardrawerOpen: boolean) {
		if (drawerOpen !== 'initialCall' && appBardrawerOpen !== drawerOpen) {
			drawerOpen = appBardrawerOpen;

			this.floorplanDetail.savedFloorplanCoordinates = [];
			this.floorplanDetail.savedFloorplanCoordinates = this.saveFloorplanCoordinates();
			this.resetCanvas('deleteObjects');

			drawerOpenCanvasWidth = drawerOpen ? canvasWidth - 185 : canvasWidth + 185;
			canvasWidth = drawerOpenCanvasWidth;
			canvas.setWidth(drawerOpenCanvasWidth);

			let backgroundImage = canvas.backgroundImage as IObject;
			let scaleFactor = backgroundImage.width ? drawerOpenCanvasWidth / backgroundImage.width : 1;

			backgroundImage.set({
				scaleX: scaleFactor,
				scaleY: scaleFactor,
			});

			canvas.renderAll();

			const canvasFloorplanCanvas = document.getElementById('floorplanCanvas')!;
			editorCanvasWidth = canvasFloorplanCanvas.clientWidth;

			this.showFloorplanCoordinates();
		}
	},

	fetchFloorplanImage(
		spacesRef: Spaces,
		routeRef: IRoute,
		entityTypeRef: string,
		showStatusForDeleteCoordinatesRef: () => void,
		setMappedEntityIdsRef: (value: string[]) => void,
		bookingsView: boolean = false,
	) {
		spaces = spacesRef;
		route = routeRef;
		entityType = entityTypeRef;
		showStatusForDeleteCoordinates = showStatusForDeleteCoordinatesRef;
		setMappedEntityIds = setMappedEntityIdsRef;
		return spaces.fetchFloorplanImage(route.params.floorId).then(floorplan => {
			this.floorplanDetail.URL = (floorplan && floorplan.floorplanDetails && floorplan.floorplanDetails.URL) || '';
			if (this.floorplanDetail.URL) {
				return this.initCanvas(this.floorplanDetail.URL, bookingsView);
			}
			return;
		});
	},

	getCanvas() {
		return canvas;
	},

	initCanvas(floorplanImage: string, bookingsView?: boolean) {
		this.resetCanvas('resetCanvas');

		canvas = new fabric.Canvas('floorplanCanvas');

		const canvasWrapper = document.getElementById('canvasWrapper')!;

		fabric.Image.fromURL(floorplanImage, img => {
			if (!img.height || !img.width || !canvasWrapper) {
				return;
			}
			//console.log('canvaswidth',canvasWrapper.clientWidth);
			//console.log('canvasheihht',canvasWrapper.clientWidth * aspectRatio);
			//console.log('imgwidth',img.width);
			//console.log('imghwe',img.height);
			aspectRatio = img.height / img.width;
			canvasWidth = canvasWrapper.clientWidth;
			canvasHeight = canvasWrapper.clientWidth * aspectRatio;
			//console.log('canvasnewheight',canvasHeight);
			scaleFactor = canvasWidth / img.width;

			img.set({
				width: img.width,
				height: img.height,
				originX: 'left',
				originY: 'top',
				scaleX: scaleFactor,
				scaleY: scaleFactor,
			});

			canvas.setWidth(canvasWidth);
			canvas.setHeight(canvasHeight);

			canvas.setDimensions(
				{
					width: '100%',
					height: 'auto',
				},
				{
					cssOnly: true,
				},
			);

			canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
				scaleX: scaleFactor,
				scaleY: canvasHeight / img.height,
			});
			canvas.selection = true;
			canvas.isDrawingMode = false;
			canvas.renderAll();

			const canvasFloorplanCanvas = document.getElementById('floorplanCanvas')!;
			editorCanvasWidth = canvasFloorplanCanvas?.clientWidth;
			if (!bookingsView) {
				this.fetchAndShowFloorplanCoordinates();
			}
		});

		canvas.on('mouse:down', options => {
			if (
				!this.floorplanDetail.showPropContainer &&
				this.floorplanDetail.controlType &&
				this.floorplanDetail.controlType !== 'undo'
			) {
				if (this.floorplanDetail.controlType === 'delete') {
					this.deleteCoordinatesInCanvas();
				} else {
					controls[this.floorplanDetail.controlType].mouseDown(canvas, options, this.floorplanDetail);
				}
			}
		});

		fabric.Canvas.prototype.setCursor = function() {
			// Add your own logic here to override
			canvas.defaultCursor = 'pointer';
		};

		canvas.on('mouse:up', options => {
			if (
				!this.floorplanDetail.showPropContainer &&
				this.floorplanDetail.controlType &&
				this.floorplanDetail.controlType !== 'undo' &&
				this.floorplanDetail.controlType !== 'delete'
			) {
				controls[this.floorplanDetail.controlType].mouseUp(canvas, options, this.floorplanDetail);
			}
		});

		canvas.on('mouse:move', options => {
			if (
				!this.floorplanDetail.showPropContainer &&
				this.floorplanDetail.controlType &&
				this.floorplanDetail.controlType !== 'undo' &&
				this.floorplanDetail.controlType !== 'delete'
			) {
				controls[this.floorplanDetail.controlType].mouseMove(canvas, options);
				canvas.hoverCursor = 'pointer';
			}
		});

		canvas.on('mouse:wheel', options => {
			const evt = options.e as any;
			const delta = evt.deltaY * -1;
			if (delta >= 0) {
				this.floorplanDetail.controlType = 'zoomIn';
			} else if (delta < 0) {
				this.floorplanDetail.controlType = 'zoomOut';
			}

			if (
				!this.floorplanDetail.showPropContainer &&
				this.floorplanDetail.controlType &&
				this.floorplanDetail.controlType !== 'undo' &&
				this.floorplanDetail.controlType !== 'delete'
			) {
				controls[this.floorplanDetail.controlType].mouseWheel(canvas, options, this.floorplanDetail);
			}
		});

		canvas.on('object:modified', options => {
			let obj = options.target;
			if (obj) {
				if (obj.type === 'polygon') {
					const polygon = obj as fabric.Polygon;
					const matrix = obj.calcTransformMatrix();
					return (
						polygon.points &&
						polygon.points.map(point => {
							let pointTemp = { x: point.x, y: point.y } as fabric.Point;
							pointTemp.x -= polygon.pathOffset.x;
							pointTemp.y -= polygon.pathOffset.y;
							return fabric.util.transformPoint(pointTemp, matrix);
						})
					);
				} else if (obj.type === 'circle') {
					const circle = obj as fabric.Circle;
					circle.set({
						width: obj.width! * obj.scaleX!,
						height: obj.height! * obj.scaleY!,
						radius: circle.radius! * obj.scaleX!,
					});
				} else if (obj.type === 'rect') {
					obj.set({ width: obj.width! * obj.scaleX!, height: obj.height! * obj.scaleY! });
				}

				obj.scaleX = 1;
				obj.scaleY = 1;
				obj.setCoords();
			}
			return;
		});

		return canvas;
	},

	fetchAndShowFloorplanCoordinates() {
		spaces.fetchFloorplanCoordinates(route.params.floorId, entityType).then(list => {
			const test = spaces.currentSiteId;
			if (list.floorplanCoordinates !== null) {
				spaces.fetchZoneDetails(test, route.params.floorId).then((res: any) => {
					ZoneData = res;
					//console.log('zoneinfo',ZoneData);
					let roomIdList: { RoomId: any; EntityId: any }[] = [];
					entityIdToRoomId = {};

					list.floorplanCoordinates.forEach((floorplanCoordinate: any) => {
						const foundZone = res.Zones.find((z: any) => z.ZoneId === floorplanCoordinate.id);
						if (foundZone) {
							roomIdList.push({ RoomId: foundZone.RoomId, EntityId: foundZone.ZoneId });
							entityIdToRoomId[floorplanCoordinate.id] = foundZone.RoomId;
						} else {
							entityIdToRoomId[floorplanCoordinate.id] = undefined;
						}
					});
					if (entityType == 'desk') {
						spaces.fetchDeskAvailability({ RoomList: roomIdList }).then(async (res: any) => {
							AvailabilityApiRes = await res;

							const avaidesks =
								(await ZoneData.Zones) &&
								ZoneData.Zones.filter((item: any) =>
									Object.keys(AvailabilityApiRes)
										.map(Number)
										.indexOf(item.RoomId) !== -1
										? item
										: '',
								).map((zoneInfo: any) => zoneInfo.ZoneId);

							const avaideskslatst = await list.floorplanCoordinates
								.slice(0)
								.filter((item: any) => (avaidesks.includes(item.id) ? item : ''));

							this.floorplanDetail.savedFloorplanCoordinates = (await avaideskslatst) || [];
							//alert('first');
							this.showFloorplanCoordinates();
						});
					} else if (entityType == 'room') {
						spaces.fetchRoomAvailability({ RoomList: roomIdList }).then((res: any) => {
							AvailabilityApiRes = res;
							const avairooms = list.floorplanCoordinates
								.slice(0)
								.filter((item: any) => (Object.keys(AvailabilityApiRes).includes(item.id) ? item : ''));
							//this.floorplanDetail.savedFloorplanCoordinates = list.floorplanCoordinates || [];
							this.floorplanDetail.savedFloorplanCoordinates = avairooms || [];
							this.showFloorplanCoordinates();
						});
					} else if (entityType == 'parking') {
						const avairooms = list.floorplanCoordinates;
						this.floorplanDetail.savedFloorplanCoordinates = avairooms || [];
						this.showFloorplanCoordinates();
					}
				});
			}
		});
	},

	setActiveCanvas(activeCoordinates: IActiveCoordinates) {
		if (canvas.getObjects().length) {
			let canvasObject: IObject;
			if (activeCoordinates.setObjectType === 'lastObject') {
				const canvasItem: number = canvas.getObjects().length - 1;
				canvasObject = (canvas.item(canvasItem) as any) as fabric.Object;
			} else {
				canvasObject = canvas.getActiveObject() || null;
			}

			if (canvasObject) {
				canvasObject.name = activeCoordinates.propertyName;
				canvasObject.id = activeCoordinates.propertyId;
				canvasObject.entityType = activeCoordinates.entityType;
				canvasObject.isSaved = activeCoordinates.isSaved;
			}
		}

		this.floorplanDetail.showPropContainer = false;
		this.findAndSetMappedEntityIds();
	},

	findAndSetMappedEntityIds() {
		let canvasMappedEntityIds: string[] = [];
		for (const canvasObject of canvas.getObjects() as IObject[]) {
			if (canvasObject.id) {
				canvasMappedEntityIds.push(canvasObject.id);
			}
		}
		setMappedEntityIds(canvasMappedEntityIds);
	},

	getPXtoMM(value?: number, DPI: number = 96) {
		if (value) {
			return (value * 25.4) / DPI;
		}
		return 0;
	},

	getMMtoPX(value: number, DPI: number = 96) {
		if (value) {
			return (value * DPI) / 25.4;
		}
		return 0;
	},

	getRealValue(value: number, displayScaleFactorValue: number) {
		if (value) {
			return this.getMMtoPX(value) * displayScaleFactorValue;
		}
		return 0;
	},

	checkAvailableOrNot(entityId: any, filtered: Boolean) {
		if (!filterApires) {
			if (entityType == 'desk') {
				return AvailabilityApiRes[entityIdToRoomId[entityId]] === 0 ? false : true;
			} else if (entityType == 'room') {
				return AvailabilityApiRes[entityId] === 0 ? false : true;
			} else if (entityType == 'parking') {
				return AvailabilityApiRes[entityId] === 0 ? false : true;
			} else return true;
		} else {
			if (entityType == 'desk') {
				return filtered ? false : true;
			} else if (entityType == 'room') {
				return filtered ? false : true;
			} else if (entityType == 'parking') {
				return filtered ? false : true;
			} else return true;
		}
	},
	getFillColor(entityId: any, filtered: Boolean, isHighLight: any) {
		if (!filterApires) {
			if (isHighLight) return '#FFC500';
			if (entityType == 'desk') {
				return AvailabilityApiRes[entityIdToRoomId[entityId]] === '0'
					? canvasProperties.vacant
					: canvasProperties.occupied;
			} else if (entityType == 'room') {
				return AvailabilityApiRes[entityId] === 0 ? canvasProperties.vacant : canvasProperties.occupied;
			} else if (entityType == 'parking') {
				return AvailabilityApiRes[entityId] === 0 ? canvasProperties.vacant : canvasProperties.occupied;
			} else return canvasProperties.occupied;
		} else {
			if (entityType == 'desk') {
				return filtered ? canvasProperties.vacant : canvasProperties.occupied;
			} else if (entityType == 'room') {
				return filtered ? canvasProperties.vacant : canvasProperties.occupied;
			} else if (entityType == 'parking') {
				return filtered ? canvasProperties.vacant : canvasProperties.occupied;
			} else return canvasProperties.occupied;
		}
	},

	showFloorplanCoordinates(filteredRes?: any, filteredZoneDt?: any, isHighLight?: any, viewVaccoordinate: any = false) {
		this.resetCanvas('deleteObjects');
		//alert('third');consol

		let floorplanCoordinates =
			this.floorplanDetail.savedFloorplanCoordinates && this.floorplanDetail.savedFloorplanCoordinates;
		//console.log('floorplancorodrn',floorplanCoordinates);
		if (floorplanCoordinates && floorplanCoordinates.length) {
			filterApires = filteredRes;
			filteredZoneData = filteredZoneDt;
			if (viewVaccoordinate) {
				const filterresult: any[] = floorplanCoordinates.filter(row => {
					if (this.checkAvailableOrNot(row.id, this.findUnavailbleReason(row.id)) === false) {
						return true;
					} else {
						return false;
					}
				});

				floorplanCoordinates = filterresult!;
			}

			floorplanCoordinates.forEach(floorplanCoordinate => {
				// filterApires = filteredRes;
				// filteredZoneData = filteredZoneDt;
				if (floorplanCoordinate.coordinates.coordinateType === 'rect') {
					this.showRectangle(
						floorplanCoordinate,
						this.getFillColor(floorplanCoordinate.id, this.findUnavailbleReason(floorplanCoordinate.id), isHighLight),
					);
				} else if (floorplanCoordinate.coordinates.coordinateType === 'polygon') {
					this.showPolygon(
						floorplanCoordinate,
						this.getFillColor(floorplanCoordinate.id, this.findUnavailbleReason(floorplanCoordinate.id), isHighLight),
					);
				} else if (floorplanCoordinate.coordinates.coordinateType === 'circle') {
					this.showCircle(
						floorplanCoordinate,
						this.getFillColor(floorplanCoordinate.id, this.findUnavailbleReason(floorplanCoordinate.id), isHighLight),
					);
				}
			});
			//alert('second');
			this.findAndSetMappedEntityIds();
		}
	},

	findUnavailbleReason(floorplanCoordinateid: any) {
		let roomIndex = filteredZoneData && filteredZoneData.findIndex((x: any) => x.ZoneId == floorplanCoordinateid);
		let roomId = filteredZoneData && filteredZoneData[roomIndex].RoomId;
		let unAvailableReasonIndex = filterApires && filterApires.findIndex((x: any) => x.id == roomId);
		return filterApires && filterApires[unAvailableReasonIndex].unavailableReason == 0;
	},

	showRectangle(floorplanCoordinate: IFloorCoordinates, fillColor: any) {
		const coordinatesPoint = floorplanCoordinate.coordinates.points;
		displayScaleFactor = this.getDisplayScaleFactor(floorplanCoordinate.coordinates.points);
		canvas.add(
			new fabric.Rect({
				left: this.getRealValue(coordinatesPoint.left!, displayScaleFactor),
				top: this.getRealValue(coordinatesPoint.top!, displayScaleFactor),
				width: this.getRealValue(coordinatesPoint.width!, displayScaleFactor),
				height: this.getRealValue(coordinatesPoint.height!, displayScaleFactor),
				originX: 'left',
				originY: 'top',
				angle: coordinatesPoint.angle || 0,
				fill: fillColor,
				opacity: canvasProperties.opacity,
				objectCaching: false,
			}),
		);

		this.setActiveCanvas({
			propertyId: floorplanCoordinate.id,
			propertyName: floorplanCoordinate.name,
			entityType: floorplanCoordinate.type,
			isSaved: true,
			setObjectType: 'lastObject',
		});
	},

	showPolygon(floorplanCoordinate: IFloorCoordinates, fillColor: any) {
		const coordinatesPoints = floorplanCoordinate.coordinates.points.point || [];
		displayScaleFactor = this.getDisplayScaleFactor(floorplanCoordinate.coordinates.points);
		const points: IPoint[] = [];
		coordinatesPoints.forEach(coordinatesPoint => {
			points.push({
				x: this.getRealValue(coordinatesPoint.x, displayScaleFactor),
				y: this.getRealValue(coordinatesPoint.y, displayScaleFactor),
			});
		});
		const polygon = new fabric.Polygon(points, {
			fill: fillColor,
			opacity: canvasProperties.opacity,
			objectCaching: false,
		});
		canvas.add(polygon);

		this.setActiveCanvas({
			propertyId: floorplanCoordinate.id,
			propertyName: floorplanCoordinate.name,
			entityType: floorplanCoordinate.type,
			isSaved: true,
			setObjectType: 'lastObject',
		});
	},

	showCircle(floorplanCoordinate: IFloorCoordinates, fillColor: any) {
		const coordinatesPoint = floorplanCoordinate.coordinates.points;
		displayScaleFactor = this.getDisplayScaleFactor(floorplanCoordinate.coordinates.points);
		canvas.add(
			new fabric.Circle({
				left: this.getRealValue(coordinatesPoint.left!, displayScaleFactor),
				top: this.getRealValue(coordinatesPoint.top!, displayScaleFactor),
				radius: this.getRealValue(coordinatesPoint.radius!, displayScaleFactor),
				width: this.getRealValue(coordinatesPoint.width!, displayScaleFactor),
				height: this.getRealValue(coordinatesPoint.height!, displayScaleFactor),
				fill: fillColor,
				opacity: canvasProperties.opacity,
				originX: 'center',
				originY: 'center',
				objectCaching: false,
			}),
		);

		this.setActiveCanvas({
			propertyId: floorplanCoordinate.id,
			propertyName: floorplanCoordinate.name,
			entityType: floorplanCoordinate.type,
			isSaved: true,
			setObjectType: 'lastObject',
		});
	},

	getDisplayScaleFactor(coordinates: ICoordinatedPoint) {
		return coordinates.editorCanvasWidth ? editorCanvasWidth / coordinates.editorCanvasWidth : 1;
	},

	enableEditorToDraw(controlType: string) {
		controls.polygon.resetPolygonCoordinates();

		this.floorplanDetail.controlType =
			(this.floorplanDetail.controlType === 'undo' && controlType === 'undo') ||
			this.floorplanDetail.controlType !== controlType
				? controlType
				: '';
		this.floorplanDetail.showPropContainer = false;

		if (this.floorplanDetail.controlType === 'DiscardChanges') {
			this.floorplanDetail.controlType = '';
			this.resetCanvas('deleteObjects');

			this.fetchAndShowFloorplanCoordinates();
		} else if (this.floorplanDetail.controlType === 'undo') {
			this.cancelLastDrawnInCanvas();
		}
	},

	resetCanvas(resetType: string) {
		if (canvas && canvas.getObjects()) {
			if (resetType === 'deleteObjects') {
				if (canvas.getObjects()) {
					canvas.remove(...canvas.getObjects());
				}
			} else {
				canvas.clear();
				canvas.dispose();
			}
		}
	},

	cancelLastDrawnInCanvas() {
		const lastObject: IObject | undefined = canvas.getObjects() && canvas.getObjects().pop();
		if (lastObject && !lastObject.isSaved) {
			canvas.remove(lastObject);
			this.findAndSetMappedEntityIds();
		}
		this.floorplanDetail.showPropContainer = false;
	},

	deleteCoordinatesInCanvas() {
		const activeObject: IObject = canvas.getActiveObject();
		if (activeObject) {
			if (activeObject.id && activeObject.isSaved) {
				spaces.deleteRoomOrDeskCoordinates(route.params.floorId, activeObject.id).then(() => {
					canvas.remove(activeObject);
					showStatusForDeleteCoordinates();
					this.findAndSetMappedEntityIds();
				});
			} else {
				canvas.remove(activeObject);
				showStatusForDeleteCoordinates();
				this.findAndSetMappedEntityIds();
			}
		}
	},

	saveFloorplanCoordinates() {
		canvas.discardActiveObject();
		canvas.requestRenderAll();

		const floorplanCoordinates: IFloorCoordinates[] = [];
		const objects: IObject[] = canvas.getObjects();
		for (const canvasObject of objects) {
			if (canvasObject.id && canvasObject.name && canvasObject.entityType) {
				if (canvasObject.type === 'polygon') {
					const polygon = canvasObject as fabric.Polygon;
					const matrix = polygon.calcTransformMatrix();
					const polygonPoints =
						polygon.points &&
						polygon.points.map(point => {
							let pointTemp = { x: point.x, y: point.y } as fabric.Point;
							pointTemp.x -= polygon.pathOffset.x;
							pointTemp.y -= polygon.pathOffset.y;
							const returnValue = fabric.util.transformPoint(pointTemp, matrix);
							return {
								x: this.getPXtoMM(returnValue.x),
								y: this.getPXtoMM(returnValue.y),
							};
						});

					floorplanCoordinates.push({
						name: canvasObject.name,
						id: canvasObject.id,
						type: canvasObject.entityType,
						coordinates: {
							coordinateType: canvasObject.type,
							points: {
								editorCanvasWidth: editorCanvasWidth,
								point: polygonPoints!,
							},
						},
					});
				} else if (canvasObject.type === 'circle') {
					floorplanCoordinates.push({
						name: canvasObject.name,
						id: canvasObject.id,
						type: canvasObject.entityType,
						coordinates: {
							coordinateType: canvasObject.type,
							points: {
								left: this.getPXtoMM(canvasObject.left),
								top: this.getPXtoMM(canvasObject.top),
								radius: this.getPXtoMM(canvasObject.radius),
								width: this.getPXtoMM(canvasObject.width),
								height: this.getPXtoMM(canvasObject.height),
								editorCanvasWidth: editorCanvasWidth,
							},
						},
					});
				} else if (canvasObject.type === 'rect') {
					floorplanCoordinates.push({
						name: canvasObject.name,
						id: canvasObject.id,
						type: canvasObject.entityType,
						coordinates: {
							coordinateType: canvasObject.type,
							points: {
								left: this.getPXtoMM(canvasObject.left),
								top: this.getPXtoMM(canvasObject.top),
								width: this.getPXtoMM(canvasObject.width),
								height: this.getPXtoMM(canvasObject.height),
								angle: canvasObject.angle,
								editorCanvasWidth: editorCanvasWidth,
							},
						},
					});
				}
			}
		}
		return floorplanCoordinates;
	},
};

export default {
	...canvasEditor,
};
