import React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
//import { toJS } from 'mobx';

import { useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import { SelectTree, SelectTreeDataNode } from '@mitie/material-ui-extensions';

import { Grid, Divider, Typography, Paper, TextField, makeStyles, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { string as yupString, object as yupObject } from 'yup';

import { stores } from '../../store';
import { IIncident, Category, ISiteObject } from '../../types';

const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			// marginLeft: '13px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			// marginLeft: '12px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
		width: '100%',
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
		// width: '40%',
		// height: '40%',
		// overflow: 'inherit',
		// marginLeft: 'auto',
		// marginRight: 'auto',
	},
	textMargin: {
		textAlign: 'center',
	},
	formControlLabel: {
		'& .fa-caret-down': {
			color: '#bd0810',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#bd0810',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			color: '#bd0810',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#bd0810',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#bd0810',
		},
	},
	// Autocomplete: {
	// 	'& .MuiInputLabel-root': {
	// 		zIndex: 999,
	// 		// marginLeft: '13px',
	// 		background: '#f9fbfd',
	// 	},
	// 	'& .MuiAutocomplete-inputRoot': {
	// 		background: '#f9fbfd',
	// 	},
	// 	'& .MuiInputBase-input': {
	// 		// marginLeft: '12px',
	// 		background: '#f9fbfd',
	// 	},
	// 	'& .MuiAutocomplete-clearIndicator': {
	// 		display: 'none',
	// 	},
	// },
}));

const fieldSchema = yupObject().shape({
	IncidentName: yupString().required('Incident is required'),
});
const Incident: React.FunctionComponent = observer(() => {
	const classes = useStyles();
	const [siteVal, setSite] = React.useState('');
	const appStores = React.useContext(stores.context.appStores);
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	//const [filterSite, setFilterSite] = React.useState('');
	//const [location, setLocation] = React.useState<SelectTreeDataNode>();
	//const [siteData, setSiteData] = React.useState<ISite[]>([]);
	//const [filteredSiteData, setFilteredSiteData] = React.useState<ISite[]>([]);
	const [values, setValues] = React.useState({ IncidentName: '', Category: Category.Incident, UploadToken: null });
	const [showIncidentForm, setShowIncidentForm] = React.useState(0);
	const [siteKey, setSiteKey] = React.useState(0);
	//appStores.showAppLoader = user.permissions === null ? true : false;

	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;

	const [hasError, setHasError] = React.useState(false);
	//const [siteKey, setSiteKey] = React.useState(0);
	//const [siteVal, setSite] = React.useState('');
	appStores.showAppLoader = user.permissions === null ? true : false;

	React.useEffect(() => {
		if (user.permissions) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowIncidentForm(1);
				}
			} else {
				setShowIncidentForm(0);
			}
		} else {
			setShowIncidentForm(0);
		}
	}, [user.permissions]);

	const methods = useForm<IIncident>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, reset, formState } = methods;
	// React.useEffect(() => {
	// 	if (sites.siteList.length) {
	// 		const list = toJS(sites.siteList).map(item => ({
	// 			key: item.Id,
	// 			title: item.Name,
	// 			selectable: true,
	// 		}));
	// 		setSiteData(list);
	// 		if (user.currentSiteId) {
	// 			setLocation(list.find(node => node.key === user.currentSiteId));
	// 		}
	// 	}
	// }, [user.currentSiteId, sites.siteList]);

	// React.useEffect(() => {
	// 	const filteredSiteData = siteData.filter((node: any) => {
	// 		return filterSite && node && node.title ? node.title.toLowerCase().includes(filterSite.toLowerCase()) : true;
	// 	});
	// 	setFilteredSiteData(filteredSiteData);
	// }, [filterSite, siteData]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};
	const submitIncidentForm = async () => {
		const { Category, IncidentName, UploadToken } = values;
		const incidentForm = { Category: Category, UploadToken: UploadToken, Parameters: {} };
		localStorage.removeItem('CMValue');
		incidentForm.Category = Category;
		incidentForm.UploadToken = UploadToken;
		incidentForm.Parameters = {
			SiteId: [siteVal],
			IncidentName: IncidentName,
		};
		const response = await contentManagement.saveContentManagementValues([incidentForm], '');
		if (response.Success === true) {
			notifications.addNotification('Incident notification sent', 'success');
			reset();
			setSiteKey(siteKey + 1);
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};
	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{showIncidentForm === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item xs={12} sm={8} md={4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									Incident
								</Typography>
								<Divider />
								<form>
									<Grid className={classes.gridStyle}>
										<TextField
											name="IncidentName"
											label="Incident name"
											variant="filled"
											fullWidth
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.IncidentName ? errors.IncidentName.message : ''}
											error={Boolean(errors.IncidentName)}
										/>
										{/* <SelectTree
											label="Site"
											filter={filterSite}
											onFilterChange={setFilterSite}
											treeData={filteredSiteData}
											selectedNode={location}
											checkable={false}
											onSelect={node => {
												setLocation(node);
											}}
										/> */}
										<div key={siteKey}>
											<Autocomplete
												options={(sitedatanew ? df : sites.siteList).filter(
													(item: any) => item.LocationStatus !== 'INACTIVE',
												)}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												onChange={(event: any, newValue: any) => {
													newValue !== ''
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
													setSite(newValue.Id);
													const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
														item => item.LocationStatus === 'INACTIVE',
													);
													if (inactiveLocations.find(item => item.Id === newValue.Id)) {
														setHasError(true);
													} else {
														setHasError(false);
													}
												}}
												renderInput={params => (
													<TextField {...params} label="Select Site" variant="filled" margin="normal" />
												)}
											/>
										</div>
										{hasError === true ? (
											<FormHelperText style={{ margin: 'auto' }}>
												This site is inactive, please select another site
											</FormHelperText>
										) : (
											''
										)}
									</Grid>
									<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
										<Button
											color="primary"
											disabled={!formState.isValid || siteVal == ''}
											onClick={() => {
												localStorage.removeItem('CMValue');
												reset();
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											disabled={!formState.isValid || siteVal === ''}
											onClick={submitIncidentForm}
										>
											Send
										</Button>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
				) : showIncidentForm === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
			</main>
		</>
	));
});
export default Incident;
