import React from 'react';
import { forwardRef } from 'react';

import { FormControl, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { IconButton, InputAdornment } from '@material-ui/core';

interface PropsForm {
	properties: any;
	onRepeatUntilChange: (date: Date) => void;
	selectedRepeatUntil: Date;
	endRepeatTimeError: string;
}

const DatePicker = forwardRef<HTMLInputElement, PropsForm>(
	({ onRepeatUntilChange, selectedRepeatUntil, properties, endRepeatTimeError }, ref) => {
		const classes = useStyles();
		const handleDateChange = (date: Date) => {
			onRepeatUntilChange(date);
		};
		return (
			<>
				<FormControl className={classes.formControl}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							name="repeatUntil"
							label="Repeat until"
							margin="normal"
							value={selectedRepeatUntil}
							format="dd/MM/yyyy"
							fullWidth
							onChange={handleDateChange}
							InputProps={{
								className: classes.dateStyle,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<AccessTimeIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
							InputLabelProps={{ className: classes.InputLabel }}
							error={Boolean(endRepeatTimeError)}
							helperText={endRepeatTimeError}
							autoOk
							ampm={false}
						/>
					</MuiPickersUtilsProvider>
				</FormControl>
			</>
		);
	},
);
export default DatePicker;

const useStyles = makeStyles(theme => ({
	dateStyle: {
		fontSize: theme.typography.pxToRem(14),
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.pxToRem(22),
	},
	formControl: {
		paddingTop: theme.spacing(1),
		width: '100%',
		'& .MuiIconButton-root': {
			color: '#66CCFA',
			padding: '0px',
			marginTop: '-15px',
			marginRight: '5px',
		},
		'& .MuiInputLabel-animated': {
			color: '#66CCFA',
			fontSize: '17px',
		},
	},
}));
