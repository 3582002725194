import { getAPI, deleteAPI, putAPI } from 'services/apiService';
import { observable, action } from 'mobx';
import { IMenuGetItem } from 'types';
class RefuelMenus {
	@observable public refuelMenus: IMenuGetItem[];

	@action
	public async fetchRefuelMenus(siteId: string) {
		this.refuelMenus = await getAPI(`/refuel/${siteId}`);
		return this.refuelMenus;
	}
	@action
	public async fetchRefuelActiveMenus(siteId: string) {
		this.refuelMenus = await getAPI(`/refuel/refuelmenu/${siteId}`);
		return this.refuelMenus;
	}
	@action
	public async deleteRefuelMenu(Id: string) {
		return await deleteAPI(`/refuel/refuelmenu/${Id}`, {});
	}
	@action
	public async reorderRefuelMenu(Id: string, Position: string) {
		return await putAPI(`/refuel/refuelmenu/${Id}/newposition/${Position}`, {});
	}
}
export default RefuelMenus;
