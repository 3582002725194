import * as fabricJs from 'fabric';

const canvasProperties = {
	//fillColor: '#BD0810',
	fillColor: '#2E8B57',
	lineColor: '#bd0810',
	vacant: '#1ba257',
	occupied: '#D71920',
	recentlyVacant: '#FD9725',
	opacity: 0.3,
	fabric: fabricJs.fabric,
};

export default {
	...canvasProperties,
};
