import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { imageExt, pdfExt } from '../../util';
import {
	Grid,
	Typography,
	Paper,
	TextField,
	makeStyles,
	Container,
	Box,
	FormControl,
	FormHelperText,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { string as yupString, object as yupObject } from 'yup';
import { stores } from '../../store';
import { IMenuItem, IMenuGetItem, ContainerID, ISaveMenuRequestBody, ISiteObject } from '../../types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { compareAsc } from 'date-fns';
const yup = require('yup');

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 85px)',
	},
	appBarSpacer: {
		height: '64px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	formControlLabel: {
		'& .MuiFilledInput-underline:after': {
			borderColor: '#bd0810',
		},
		'& .MuiFilledInput-underline:before': {
			borderColor: '#bd0810',
		},
		'& .MuiIconButton-label': {
			color: '#bd0810',
		},
		'& .makeStyles-Autocomplete-11 .MuiInputLabel-root': {
			color: '#bd0810',
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
			color: '#bd0810',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		flexBasis: '50%',
		maxWidth: '400px',
		color: theme.palette.text.primary,
		'& p': {
			textAlign: 'left',
		},
	},
	fileInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 0),
	},
	fileError: {
		borderBottom: `${theme.palette.error.main} 1px solid`,
		color: theme.palette.error.main,
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	textMargin: {
		margin: '2vh 0 10vh',
	},
	accessDimensions: {
		width: '45%',
		height: '70%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	alignButton: {
		padding: theme.spacing(0, 1, 1, 0),
	},
	hidden: {
		display: 'none',
		color: 'red',
	},
	formControl: {
		display: 'block',
		width: '100%',
		marginTop: theme.spacing(1),
	},
	dateStyle: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
	},
	checkbox: {
		marginTop: theme.spacing(2),
	},
	refuelBox: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
}));

const validationSchema = yupObject().shape({
	RefuelMenuTitle: yupString().required('Menu Name is required'),
});

const RefuelMenuForm: React.FunctionComponent = observer(() => {
	const initialFileState = {
		name: '',
		format: '',
		extension: '',
		object: '',
		error: false,
	};
	const initialValuesState: IMenuItem = {
		Id: null,
		MediaUrl: '',
		RefuelMenuTitle: '',
		RefuelSystemName: '',
		MediaType: '',
		IsActive: false,
		StartTime: null,
		ExpiredTime: null,
	};
	const editValuesState: IMenuGetItem = {
		Id: null,
		SiteId: '',
		MediaUrl: '',
		RefuelTitle: '',
		RefuelSystemName: '',
		MediaType: '',
		RefuelMenuTitle: '',
		Order: '',
		IsActive: false,
		StartTime: null,
		ExpiredTime: null,
	};
	const classes = useStyles();
	const user = useContext(stores.context.user);
	const contentManagement = useContext(stores.context.contentManagement);
	const notifications = useContext(stores.context.notifications);
	const blob = useContext(stores.context.blob);
	const { route, routeName } = React.useContext(stores.context.routes);
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const sites = useContext(stores.context.site);
	const [siteLocation, setSiteLocation] = React.useState<any>();
	const [location, setLocation] = useState(user.currentSiteId);
	const [file, setFile] = useState(initialFileState);
	const [values, setValues] = useState<IMenuItem | IMenuGetItem>(initialValuesState);
	appStores.showAppLoader = user.permissions === null ? true : false;
	const [cardTitle, setCardTitle] = useState('Add Menu');
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [siteNewLocation, setSiteNewLocation] = React.useState<any>();
	const siteNamenew: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [showRefuelMenu, setShowRefuelMenu] = React.useState(0);
	React.useEffect(() => {
		if (routeName === 'editMenus') {
			setCardTitle('Edit Menu');
			editValuesState.SiteId = route.params.siteId || location;
			setLocation(editValuesState.SiteId);
			editValuesState.Id = route.params.Id;
			editValuesState.RefuelTitle = route.params.title;
			editValuesState.RefuelMenuTitle = route.params.title;
			editValuesState.RefuelSystemName = route.params.systemName;
			editValuesState.MediaType = route.params.type;
			editValuesState.IsActive = route.params.isActive;
			editValuesState.StartTime = route.params.startTime;
			editValuesState.ExpiredTime = route.params.expireTime;
			editValuesState.MediaUrl = route.params.url;
			setValues(editValuesState);
			setFile({
				...file,
				format: editValuesState.MediaType,
			});
		}
	}, [route, routeName]);

	const [hasError, setHasError] = React.useState(false);
	const [active, setActive] = React.useState(false);
	const date = new Date(new Date().setDate(new Date().getDate() + 1));
	const newdate: any = date.setHours(0, 0);
	const [selectedStartTime, setSelectedStartTime] = React.useState<Date>(
		route.params.startTime ? new Date(route.params.startTime) : new Date(),
	);
	const [selectedEndTime, setSelectedEndTime] = React.useState<Date>(
		route.params.expireTime ? new Date(route.params.expireTime) : new Date(newdate),
	);
	const [startTimeError, setStartTimeError] = React.useState('');
	const [endTimeError, setEndTimeError] = React.useState('');

	const handleChange = (event: any) => {
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		values.IsActive = !values.IsActive;
		setActive(values.IsActive);
	};

	const validateSchema = (date: Date, name: string, message: string, dateValid: Date) => {
		let schema = yup.date().test(name, message, (value: Date) => {
			return compareAsc(value, dateValid) <= 0;
		});
		return schema;
	};
	const handleStartTime = async (date: Date) => {
		setSelectedStartTime(date);
		setEndTimeError('');
		// setting the key in local storage
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		const schema = validateSchema(date, 'startTime', 'Start time should be less than end time', selectedEndTime);
		schema
			.validate(date)
			.then((response: string) => {
				setStartTimeError('');
			})
			.catch((err: any) => {
				setStartTimeError(err.message);
			});
	};
	const handleEndTime = async (date: Date) => {
		setSelectedEndTime(date);
		setStartTimeError('');

		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		let schema = yup.date().test('startTime', 'End time should be greater than start time', (value: Date) => {
			return compareAsc(value, selectedStartTime) >= 0;
		});
		schema
			.validate(date)
			.then((response: string) => {
				setEndTimeError('');
			})
			.catch((err: any) => {
				setEndTimeError(err.message);
			});
	};

	React.useEffect(() => {
		if (routeName === 'editMenus' || routeName === 'copyMenus') {
			route.params.Heading === 'Copy' ? setCardTitle('Copy Menu') : setCardTitle('Edit Menu');
			editValuesState.SiteId = route.params.siteId || location;
			setActive(route.params.isActive);
			setLocation(editValuesState.SiteId);
			setSiteLocation(df[siteName]);
			setSiteNewLocation(df[siteNamenew]);
			const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
				(item: any) => item.LocationStatus === 'INACTIVE',
			);

			if (inactiveLocations.find((item: any) => item.Id === editValuesState.SiteId)) {
				setHasError(true);
			}
			editValuesState.Id = route.params.Id;
			editValuesState.RefuelTitle = route.params.title;
			editValuesState.RefuelMenuTitle = route.params.title;
			editValuesState.RefuelSystemName = route.params.systemName;
			editValuesState.MediaType = route.params.type;
			editValuesState.IsActive = route.params.isActive;
			editValuesState.StartTime = route.params.startTime;
			editValuesState.ExpiredTime = route.params.expireTime;
			editValuesState.MediaUrl = route.params.url;
			setValues(editValuesState);
			setFile({
				...file,
				format: editValuesState.MediaType,
			});
		}
	}, [route, routeName]);

	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const refuelIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '35FA69F9-7A9E-41FC-AFB3-A8BEFA4E4637',
			);
			if (user.permissions.Modules[refuelIndex] && user.permissions.Modules[refuelIndex].Read) {
				if (
					user.permissions.Modules[refuelIndex].Create ||
					user.permissions.Modules[refuelIndex].Update ||
					user.permissions.Modules[refuelIndex].Delete
				) {
					setShowRefuelMenu(1);
				}
			} else {
				setShowRefuelMenu(0);
			}
		} else {
			setShowRefuelMenu(0);
		}
	}, [user.permissions]);

	const methods = useForm<IMenuItem | IMenuGetItem>({
		validationSchema,
		mode: 'onChange',
	});
	const { errors, reset, register, formState } = methods;
	const [siteKey, setSiteKey] = React.useState(0);
	const [siteVal, setSite] = React.useState(user.currentSiteId);
	const [filePreview, setFilePreview] = useState(route.params.url ? route.params.url : null);
	const [fileType, setFileType] = useState(route.params.type ? route.params.type : null);
	const [errmsgnew, setError] = React.useState('');

	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};
	const uploadFile = async (data: any) => {
		const token = await blob.blobToken();
		const params = {
			Token: token.uploadToken,
			Container: ContainerID.Refuel,
			...data,
		};
		const response = await blob.blobImage(params);
		return response.url;
	};
	const handleFileChange = (event: React.ChangeEvent<any>) => {
		const uploadedFile = event.target.files[0];
		const mediaExt = imageExt;
		uploadedFile !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		const pdfExtnew = pdfExt;
		if (uploadedFile && (mediaExt.includes(uploadedFile.type) || pdfExtnew.includes(uploadedFile.type))) {
			setFilePreview(uploadedFile && URL.createObjectURL(uploadedFile));
			if (uploadedFile) {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(uploadedFile);
				fileReader.onload = (e: any) => {
					const object = e.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
					const extension =
						uploadedFile.type === 'image/png' ? 'png' : uploadedFile.type === 'application/pdf' ? 'pdf' : 'jpg';
					setFile({
						...file,
						name: uploadedFile.name,
						format: uploadedFile.type,
						extension,
						object,
						error: false,
					});
					const MediaType = getMediaType(uploadedFile.type);
					setFileType(MediaType);
					setValues({ ...values, MediaType });
				};
				setError('');
			} else {
				setFile({ ...initialFileState, error: true });
				setValues(initialValuesState);
				setError('Please select jpg,png,jpeg,pdf types');
			}
		}
	};
	const getMediaType = (type: string) => {
		let format = type.split('/')[0] ? type.split('/')[0] : '';
		if (format === 'image') {
			format = format.replace(/^\w/, c => c.toUpperCase());
		} else if (type === 'application/pdf') {
			format = 'PDF';
		}
		return format;
	};

	const submitForm = async () => {
		localStorage.removeItem('CMValue');
		const MediaUrl = !file.object
			? filePreview
			: await uploadFile({
					Data: file.object,
					ContentType: file.format,
					Extension: file.extension,
			  });
		const RefuelSystemName = file.name.split('.')[0] + '_' + Date.now();
		const StartTime = values.IsActive === true ? selectedStartTime : null;
		const ExpiredTime = values.IsActive === true ? selectedEndTime : null;
		let params: ISaveMenuRequestBody = {
			siteId: siteVal,
			RefuelMenus: [{ ...values, RefuelSystemName, MediaUrl, StartTime, ExpiredTime }],
		};
		if (routeName === 'editMenus' || routeName === 'copyMenus') {
			params = {
				siteId: siteVal,
				RefuelMenus: [
					{
						Id: routeName === 'copyMenus' ? null : values.Id,
						RefuelMenuTitle: values.RefuelMenuTitle,
						RefuelSystemName: values.RefuelSystemName,
						MediaType: values.MediaType,
						MediaUrl,
						IsActive: values.IsActive,
						StartTime: values.IsActive === true ? selectedStartTime : null,
						ExpiredTime: values.IsActive === true ? selectedEndTime : null,
					},
				],
			};
		}

		const response = await contentManagement.saveMenu(params);

		if (response.Success === true) {
			notifications.addNotification('Menu uploaded successfully', 'success');
			resetForm();
			setSiteKey(siteKey + 1);
			routes.navigate(
				{ name: 'refuelList' },
				{
					clearParams: true,
				},
			);
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};
	const resetForm = () => {
		reset();
		setLocation(user.currentSiteId);
		setFile(initialFileState);
	};
	return (
		<>
			<Container className={classes.container}>
				{showRefuelMenu === 1 && appStores.showAppLoader === false ? (
					user && user.token ? (
						<Grid container direction="row" spacing={1}>
							<Grid item md={appStores.drawerOpen ? 5 : 12} className={classes.refuelBox}>
								<Paper className={classes.paper}>
									<Typography variant="h5" className={classes.text}>
										{cardTitle}
									</Typography>
									<form>
										<Grid className={classes.gridStyle}>
											<FormControl
												style={{ width: '100%' }}
												error={hasError}
												className={hasError === true ? classes.formControlLabel : ''}
											>
												<Grid>
													{route.params.siteId ? (
														<Autocomplete
															options={(sitedatanew ? df : sites.siteList).filter(
																(item: any) => item.LocationStatus !== 'INACTIVE',
															)}
															getOptionLabel={option => option.Name}
															size="small"
															className={classes.Autocomplete}
															id="size-small-standard"
															noOptionsText="Loading..."
															defaultValue={siteLocation}
															value={siteNewLocation}
															autoComplete
															onChange={(event: any, newValue: any) => {
																setSite(newValue.Id);
																const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
																	(item: any) => item.LocationStatus === 'INACTIVE',
																);
																if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
																	setHasError(true);
																} else {
																	setHasError(false);
																}
															}}
															renderInput={params => (
																<TextField {...params} label="Select Site" variant="filled" margin="normal" />
															)}
														/>
													) : (
														<>
															<div key={siteKey}>
																<Autocomplete
																	options={(sitedatanew ? df : sites.siteList).filter(
																		(item: any) => item.LocationStatus !== 'INACTIVE',
																	)}
																	getOptionLabel={option => option.Name}
																	size="small"
																	className={classes.Autocomplete}
																	id="size-small-standard"
																	noOptionsText="Loading..."
																	autoComplete
																	onChange={(event: any, newValue: any) => {
																		setSite(newValue.Id);
																		const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
																			(item: any) => item.LocationStatus === 'INACTIVE',
																		);
																		if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
																			setHasError(true);
																		} else {
																			setHasError(false);
																		}
																	}}
																	renderInput={params => (
																		<TextField {...params} label="Select Site" variant="filled" margin="normal" />
																	)}
																/>
															</div>
														</>
													)}
													{hasError === true ? (
														<FormHelperText style={{ margin: 'auto' }}>
															This site is inactive, please select another site
														</FormHelperText>
													) : (
														''
													)}
												</Grid>
											</FormControl>
											<Grid>
												{routeName !== 'editMenus' && routeName !== 'copyMenus' ? (
													<TextField
														name="RefuelMenuTitle"
														label="Menu name"
														variant="filled"
														fullWidth
														margin="normal"
														onChange={handleInputChange}
														defaultValue={values.RefuelMenuTitle}
														helperText={errors.RefuelMenuTitle ? errors.RefuelMenuTitle.message : ''}
														error={Boolean(errors.RefuelMenuTitle)}
														inputRef={e => {
															register(e);
														}}
													/>
												) : (
													<TextField
														name="RefuelMenuTitle"
														label="Menu name"
														variant="filled"
														fullWidth
														margin="normal"
														onChange={handleInputChange}
														inputRef={e => {
															register(e);
														}}
														value={values.RefuelMenuTitle}
														helperText={errors.RefuelMenuTitle ? errors.RefuelMenuTitle.message : ''}
														error={Boolean(errors.RefuelMenuTitle)}
													/>
												)}
											</Grid>
											<Grid style={{ textAlign: 'left' }}>
												<FormControlLabel
													control={
														<Checkbox
															checked={values.IsActive}
															onChange={handleChange}
															color="primary"
															value={values.IsActive}
															name="IsActive"
															inputProps={{ 'aria-label': 'secondary checkbox' }}
														/>
													}
													label="Activate"
													className={classes.checkbox}
												/>
											</Grid>
											<Grid className={classnames(active === false ? classes.hidden : '')}>
												<FormControl variant="filled" className={classes.formControl}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DateTimePicker
															name="startTime"
															label="Starts"
															margin="normal"
															format="dd/MM/yyyy HH:mm"
															ampm={false}
															fullWidth
															disablePast
															value={selectedStartTime}
															onChange={handleStartTime}
															error={Boolean(startTimeError)}
															helperText={startTimeError}
															//inputRef={ref}
															inputProps={{ className: classes.dateStyle }}
															InputLabelProps={{ className: classes.InputLabel }}
														/>
													</MuiPickersUtilsProvider>
												</FormControl>
												<FormControl variant="filled" className={classes.formControl}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DateTimePicker
															name="endTime"
															label="Expires"
															margin="normal"
															disablePast
															fullWidth
															format="dd/MM/yyyy HH:mm"
															ampm={false}
															value={selectedEndTime}
															onChange={handleEndTime}
															inputProps={{ className: classes.dateStyle }}
															InputLabelProps={{ className: classes.InputLabel }}
															error={Boolean(endTimeError)}
															helperText={endTimeError}
														/>
													</MuiPickersUtilsProvider>
												</FormControl>
											</Grid>
											<Grid>
												<TextField
													name="RefuelSystemName"
													variant="filled"
													fullWidth
													margin="normal"
													inputRef={e => {
														register(e);
													}}
													disabled
													style={{ display: 'none' }}
												/>
											</Grid>
											<Grid>
												<input
													accept="image/jpeg,image/jpg,image/png,application/pdf"
													id="fileInput"
													onChange={handleFileChange}
													type="file"
													style={{ display: 'none' }}
												/>
												<label
													htmlFor="fileInput"
													className={classnames(classes.fileInput, file.error ? classes.fileError : '')}
												>
													{routeName !== 'editMenus' && routeName !== 'copyMenus' ? (
														<Typography
															style={{ marginLeft: '13px' }}
															color={file.error ? 'error' : 'textSecondary'}
															paragraph={true}
														>
															{file.name ? file.name : 'Add file'}
														</Typography>
													) : (
														<Typography
															style={{ marginLeft: '13px' }}
															color={file.error ? 'error' : 'textSecondary'}
															paragraph={true}
														>
															{file.name ? file.name : values.MediaUrl.split('/').pop()}
														</Typography>
													)}
													<Icon icon={faFileUpload} style={{ marginLeft: '-37px' }} />
												</label>
												{errmsgnew !== '' ? (
													<FormHelperText style={{ marginTop: '5px', color: '#BD0810' }}>{errmsgnew}</FormHelperText>
												) : (
													''
												)}
											</Grid>
											<Grid>
												<Typography variant="body2" paragraph={true} style={{ fontWeight: 500 }}>
													Multi-page menus can be uploaded as .pdf file.
												</Typography>
											</Grid>
											<Grid>
												<Typography variant="body2" paragraph={true} style={{ fontWeight: 500 }}>
													Single-page menus can be uploaded as .jpg or .png files.
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
											<Button
												color="primary"
												onClick={() => {
													routes.navigate(
														{ name: 'refuelList' },
														{
															clearParams: true,
														},
													);
												}}
											>
												Cancel
											</Button>
											{routeName === 'addMenus' ? (
												hasError === true ? (
													<Button color="primary" disabled={true} onClick={submitForm}>
														Create
													</Button>
												) : (
													<Button
														color="primary"
														disabled={!formState.isValid || !file.object || siteVal === ''}
														onClick={submitForm}
													>
														Create
													</Button>
												)
											) : errors.RefuelMenuTitle ? (
												hasError === true ? (
													<Button color="primary" disabled={true} onClick={submitForm}>
														{routeName === 'copyMenus' ? 'Create' : 'Edit'}
													</Button>
												) : (
													<Button color="primary" disabled={!formState.isValid || !file.object} onClick={submitForm}>
														{routeName === 'copyMenus' ? 'Create' : 'Edit'}
													</Button>
												)
											) : hasError === true ? (
												<Button color="primary" disabled={true} onClick={submitForm}>
													{routeName === 'copyMenus' ? 'Create' : 'Edit'}
												</Button>
											) : (
												<Button color="primary" disabled={!values.MediaUrl} onClick={submitForm}>
													{routeName === 'copyMenus' ? 'Create' : 'Edit'}
												</Button>
											)}
										</Grid>
									</form>
								</Paper>
								{filePreview && filePreview !== '' ? (
									<Box className={classes.paper} style={{ backgroundColor: 'none !important', marginLeft: '15%' }}>
										{fileType === 'Image' ? (
											<img
												src={filePreview}
												style={{
													width: '100%',
												}}
												alt="file preview"
											/>
										) : (
											<iframe
												src={`${filePreview}#scrollbar=0&toolbar=0`}
												style={{
													width: '100%',
													height: '100%',
												}}
											/>
										)}
									</Box>
								) : (
									''
								)}
							</Grid>
						</Grid>
					) : (
						''
					)
				) : (
					''
				)}
			</Container>
		</>
	);
});
export default RefuelMenuForm;
