import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Typography, Checkbox } from '@material-ui/core';
import { stores } from '../store';

export interface clientsAndSitesInfo {
	clientsAndSitesData: {
		ClientId: string;
		SiteId: string | null;
		Type: string;
		Name: string;
		completed?: boolean;
		indeterminateStatus?: boolean;
	}[];
	setSelectedSites: React.Dispatch<React.SetStateAction<clientsAndSitesInfo['clientsAndSitesData']>>;
	status: boolean;
}

type OptionsInfo = {
	ClientId: string;
	SiteId: string | null;
	Type: string;
	Name: string;
	completed?: boolean;
	indeterminateStatus?: boolean;
};

const useStyles = makeStyles(theme => ({
	listbox: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
	dividerFullWidth: {
		margin: `0px 0 0 0px`,
	},
	Autocomplete: {
		margin: 0,
		marginLeft: 0,
		backgroundColor: '#f9fbfd',
		'& .MuiInputLabel-root': {
			zIndex: 999,
		},
		'& .MuiAutocomplete-inputRoot': {
			backgroundColor: '#f9fbfd',
			paddingRight: '0px !important',
			'&:hover': {
				color: '#fff',
				backgroundColor: '#66CCFA !important',
				'& .MuiAutocomplete-tag': {
					color: '#fff !important',
				},
			},
		},
		'& .MuiInputBase-input': {
			background: 'transparent',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiChip-root': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiChip-deleteIcon': {
			display: 'none !important',
		},
		'& .MuiChip-label': {
			paddingLeft: '1px !important',
			width: '148px !important',
		},
		'& .MuiFilledInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'double',
		},
	},
	option: {
		'& .MuiAutocomplete-option : hover': {
			marginRight: '0px',
			width: '100% !important',
		},
		paddingLeft: '0px !important',
		paddingRight: '0px !important',

		'& .MuiTypography-noWrap': {
			marginLeft: '-9px !important',
			whiteSpace: 'nowrap !important',
			minWidth: '198px !important',
		},
		left: '0px !important',
		'&[data-focus="true"]': {
			backgroundColor: '#f4f8fc',
		},
		'&[aria-selected="true"]': {
			backgroundColor: '#f4f8fc',
		},
	},
}));

const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: (style.top as number) + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child: React.ReactNode) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}
		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};
	const gridRef = useResetCache(itemCount);
	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index: any) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

export let resultArray: clientsAndSitesInfo['clientsAndSitesData'] = [];

let editSites: any[] = [];

export function editSitesList(data: clientsAndSitesInfo['clientsAndSitesData']) {
	const listOfSites = data.reduce((acc, item) => {
		if (item.Type !== 'client') {
			if (acc[item.ClientId]) {
				acc[item.ClientId] += 1;
			} else {
				acc[item.ClientId] = 1;
			}
		}
		return acc;
	}, {});

	const arra1: any[] = data.map(item => {
		let item2 = editSites.find(i2 => item.SiteId === i2);
		return item2
			? { ...item, completed: true, indeterminateStatus: false }
			: { ...item, completed: false, indeterminateStatus: false };
	});

	const listofClientslistData = arra1.reduce((acc, item) => {
		if (item.completed && item.Type === 'site') {
			if (acc[item.ClientId]) {
				acc[item.ClientId] += 1;
			} else {
				acc[item.ClientId] = 1;
			}
		}
		return acc;
	}, {});

	const editSitesData = arra1.map(item => {
		if (item.Type === 'client') {
			if (listofClientslistData[item.ClientId] !== listOfSites[item.ClientId]) {
				return { ...item, completed: false, indeterminateStatus: false };
			} else {
				return { ...item, completed: true };
			}
		}
		return item;
	});
	return editSitesData;
}

const ClientsAndSites: React.FC<clientsAndSitesInfo> = observer(({ clientsAndSitesData, setSelectedSites, status }) => {
	const classes = useStyles();
	const { route } = React.useContext(stores.context.routes);
	const [paramSite, setParamSite] = React.useState<any[]>([]);
	const site = React.useContext(stores.context.site);
	editSites =
		route.params.SiteId && route.params.SiteId.length > 0
			? [].concat.apply([], Array.of(route.params.SiteId))
			: paramSite;
	clientsAndSitesData =
		clientsAndSitesData && clientsAndSitesData.length !== 0 ? clientsAndSitesData : site.sitesByClientList;

	useEffect(() => {
		clientsAndSitesData = clientsAndSitesData.map((item: any) => {
			return item.Type === 'client'
				? { ...item, completed: false, indeterminateStatus: false }
				: { ...item, completed: false };
		});
	}, []);
	const [sitesAndClientsList, setSitesAndClientsList] = useState<clientsAndSitesInfo['clientsAndSitesData']>(
		clientsAndSitesData,
	);
	const [selected, setSelected] = useState<any[]>([]);
	const [siteKey, setSiteKey] = React.useState(0);

	//editSites = route.params.SiteId;

	React.useEffect(() => {
		editSites = [].concat.apply([], Array.of(route.params.SiteId));
		setParamSite([].concat.apply([], Array.of(route.params.SiteId)));
		if (route.params.SiteId && [].concat.apply([], Array.of(route.params.SiteId)).length > 0) {
			setSitesAndClientsList(editSitesList(JSON.parse(JSON.stringify(site.sitesByClientList))));
		}
	}, [route.params.SiteId, site.sitesByClientList]);

	React.useEffect(() => {
		let siteNames: string[] = [];
		const clonedArray = JSON.parse(JSON.stringify(sitesAndClientsList));
		clonedArray.forEach((item: any) => {
			if (item.completed && item.Type === 'site') {
				siteNames.push(item);
			}
		});
		if (siteNames.length > 0) {
			setSelected(siteNames);
			setSiteKey(siteKey + 1);
		}
	}, [sitesAndClientsList]);

	const handleClientCheckBoxChange = (options: OptionsInfo) => {
		const { ClientId, Type, SiteId } = options;
		if (Type === 'client') {
			let clientUpdatedArray = [];
			const result = sitesAndClientsList.filter(item => item.ClientId === ClientId && item.Type === 'client');
			const { completed: selected, indeterminateStatus: blocked } = result[0];
			if (selected) {
				clientUpdatedArray = sitesAndClientsList.map(item => {
					return item.ClientId === ClientId && item.Type === 'client'
						? {
								...item,
								completed: !item.completed,
								indeterminateStatus: item.indeterminateStatus ? !item.indeterminateStatus : item.indeterminateStatus,
						  }
						: item.Type === 'site' && item.ClientId === ClientId
						? { ...item, completed: false }
						: { ...item };
				});
				setSitesAndClientsList(clientUpdatedArray);
			} else if (!selected) {
				clientUpdatedArray = sitesAndClientsList.map(item => {
					return item.ClientId === ClientId && item.Type === 'client'
						? {
								...item,
								completed: !item.completed,
								indeterminateStatus: item.indeterminateStatus ? !item.indeterminateStatus : item.indeterminateStatus,
						  }
						: item.Type === 'site' && item.ClientId === ClientId
						? { ...item, completed: true }
						: { ...item };
				});
				setSitesAndClientsList(clientUpdatedArray);
			} else if (selected && blocked) {
				clientUpdatedArray = sitesAndClientsList.map(item => {
					return item.ClientId === ClientId && item.Type === 'client'
						? {
								...item,
								completed: !item.completed,
								indeterminateStatus: item.indeterminateStatus ? !item.indeterminateStatus : item.indeterminateStatus,
						  }
						: item.Type === 'site' && item.ClientId === ClientId
						? { ...item, completed: true }
						: { ...item };
				});
				setSitesAndClientsList(clientUpdatedArray);
			} else {
				clientUpdatedArray = sitesAndClientsList.map(item => {
					return item.ClientId === ClientId
						? {
								...item,
								completed: !item.completed,
								indeterminateStatus: item.indeterminateStatus ? !item.indeterminateStatus : item.indeterminateStatus,
						  }
						: { ...item };
				});
				setSitesAndClientsList(clientUpdatedArray);
			}
		}

		if (Type === 'site') {
			let siteUpdateData = sitesAndClientsList.map(item => {
				if (item.SiteId === SiteId && item.Type === 'site') {
					return { ...item, completed: !item.completed };
				}
				return { ...item };
			});

			let indivi = siteUpdateData.filter(item => {
				if (item.ClientId === ClientId) {
					return item;
				}
				return;
			});

			let sitesUpdatedArrayData = siteUpdateData.map(item => {
				if (item.Type === 'client' && item.ClientId === ClientId) {
					let flag = indivi.slice(1, indivi.length).every(site => site.completed);
					return !flag
						? {
								...item,
								indeterminateStatus: item.indeterminateStatus ? item.indeterminateStatus : !item.indeterminateStatus,
								completed: !flag,
						  }
						: {
								...item,
								indeterminateStatus: item.indeterminateStatus ? !item.indeterminateStatus : item.indeterminateStatus,
								completed: flag,
						  };
				}
				return { ...item };
			});
			setSitesAndClientsList(sitesUpdatedArrayData);
		}
	};

	// Selected Checkboxes data
	useEffect(() => {
		resultArray = sitesAndClientsList.slice(0).filter(item => {
			if (item.completed && item.Type !== 'client') {
				return item;
			}
			return;
		});
		setSelectedSites(resultArray);
		setSelected(resultArray);
	}, [sitesAndClientsList]);

	return (
		<div key={siteKey}>
			<Autocomplete
				value={selected}
				multiple
				limitTags={1}
				className={classes.Autocomplete}
				classes={{
					option: classes.option,
				}}
				id="checkboxes-tags-demo"
				disableCloseOnSelect
				disabled={status}
				getOptionLabel={option => option.Name}
				style={{ backgroundColor: '#f9fbfd', marginRight: '0px' }}
				ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
				options={sitesAndClientsList}
				renderOption={option => {
					return (
						<React.Fragment>
							<Checkbox
								style={{ marginRight: 8 }}
								checked={option.completed}
								indeterminate={option.indeterminateStatus}
								onChange={() => handleClientCheckBoxChange(option)}
								color="primary"
							/>
							{option.Type === 'client' ? (
								<Typography noWrap style={{ fontWeight: 'bold' }}>
									{option.Type === 'client' ? `All ${option.Name} Sites` : option.Name}
								</Typography>
							) : (
								<Typography noWrap>{option.Name}</Typography>
							)}
						</React.Fragment>
					);
				}}
				renderInput={params => <TextField {...params} variant="filled" label="Select Site" color="primary" />}
			/>
		</div>
	);
});

export default ClientsAndSites;
