import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
	RichTextEditorComponent,
	Toolbar,
	Inject,
	Image,
	Link,
	HtmlEditor,
	Count,
	QuickToolbar,
	Table,
} from '@syncfusion/ej2-react-richtexteditor';
import { ToolbarSettingsModel, FileManager } from '@syncfusion/ej2-react-richtexteditor';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-icons/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';

interface RichTextEditoreIprops {
	defaultValue: any;
	field: any;
	setValues: any;
	values: any;
}

const useStyles = makeStyles(theme => ({
	RichTextContainer: {
		width: '1014px',
		height: '500px ',
		marginBottom: '10px',
		marginLeft: '7px',
		marginTop: '17px',
		'& .e-icons': {
			color: '#66CCFA !important',
			fontSize: '12px !important',
			'&:hover': {
				color: '#14A0D6 !important',
				background: 'none',
			},
		},
		'& .e-active': {
			'& .e-icons': {
				color: '#14a0d6 !important',
			},
		},
		'& .e-content': {
			border: '1px solid #DBE4EB',
			height: '438px !important',
			borderRadius: '0px 0px 8px 8px',
			'&:hover': {
				border: '1px solid #8b8dae',
			},
		},
		'& .e-rte-tb-expand.e-focused': {
			'& .e-content': {
				border: '1px solid #14a0d6',
			},
		},
		'& .e-tbar-btn': {
			'&:hover': {
				background: 'none !important',
			},
		},
		'& .e-richtexteditor': {
			borderRadius: '4px',
			border: 0,
			'& .e-rte-toolbar': {
				borderTop: '1px solid #CFDBE5',
				borderRight: '1px solid #CFDBE5',
				borderLeft: '1px solid #CFDBE5',
				borderRadius: '8px 8px 0px 0px !important',
				background: '#fff !important',
			},
			'& .e-rte-content': {
				border: '0 !important',
			},
			'& .e-toolbar-wrapper': {
				height: '32px !important',
			},
		},
		'& .e-toolbar': {
			'& .e-toolbar-items': {
				background: '#fff !important',
				minHeight: '32px',
				borderRadius: '8px 0px 0px 0px',
				'& .e-toolbar-item': {
					minHeight: '32px',
					padding: '0px',
				},
			},
			'& .e-tbar-btn': {
				background: '#fff !important',
			},
		},
	},
}));

const ReachTextEditor = ({ defaultValue, field, setValues, values }: RichTextEditoreIprops) => {
	let rteObj: RichTextEditorComponent;
	const classes = useStyles();
	const items: string[] = ['Bold', 'Italic', 'Underline', 'OrderedList', 'UnorderedList', 'CreateLink'];
	const toolbarSettings: ToolbarSettingsModel = {
		items: items,
	};

	const refFunction = (testeditor: any) => {
		rteObj = testeditor;
	};
	const getEditorContent = () => {
		setValues({ ...values, description: rteObj.value });
	};

	return (
		<div className={classes.RichTextContainer}>
			<div className="control-section" id="rteTools">
				<div className="rte-control-section">
					<RichTextEditorComponent
						id="toolsRTE"
						ref={refFunction}
						toolbarSettings={toolbarSettings}
						change={getEditorContent}
						value={defaultValue}
						placeholder="Write your message here..."
					>
						<div></div>
						<Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, FileManager]} />
					</RichTextEditorComponent>
				</div>
			</div>
		</div>
	);
};

export default ReachTextEditor;
