import * as React from 'react';
import { useObserver, useLocalStore, observer } from 'mobx-react-lite';
import { stores } from '../store';
import FloorplanEditorCanvas from '../components/FloorplanEditor/Canvas';
import { Icon, Alert } from '@mitie/material-ui-extensions';
import ZoomInImg from '../assets/images/Zoom_In@2x.png';
import ZoomOutImg from '../assets/images/Zoom_Out@2x.png';
import CondecoLogo from '../assets/images/Condeco_Logo_Large.png';
import noResults from '../assets/images/Office_Web@2x.png';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { makeStyles, Typography, Grid, Paper, Box, IconButton } from '@material-ui/core';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { IFloorplanDetail, IObject, IEntitie, ICanvas } from '../types';

const useStyles = makeStyles(theme => ({
	disabledButton: {
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},
	root: {
		backgroundColor: theme.palette.background.default,
		textAlign: 'left',
		overFlow: 'hidden',
		'& .MuiContainer-root': {
			margin: theme.spacing(0),
			padding: theme.spacing(0),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},

	headerNavBox: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	selectItemHover: {
		'& .MuiListItem-button:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
			backgroundColor: '#098bbd3d !important',
		},
	},
	gridPadding: {
		'& .MuiFormControl-root': {
			width: '100%',
			backgroundColor: '#f9fbfd',
			height: '48px',
		},

		'& .MuiFormControl-root:hover': {
			width: '100%',
			backgroundColor: '#e9f5f8 !important',
			borderRadius: '2px !important',
		},
	},
	imageIcon: {
		height: '100%',
	},
	iconRoot: {
		textAlign: 'center',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 223,
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		marginBottom: '8px',
		borderRadius: '4px',
		'&:hover': { background: `#e9f5f8 !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '6px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '15px',
		},
		background: '#f9fbfd',
	},

	filtelayout: {
		paddingLeft: '10px',
		'& .PrivateSwitchBase-root-487': {
			padding: '4px',
		},

		'@media (min-width: 1200px) and (max-width: 1250px) and (min-height:500px) and (max-height:550px)': {
			height: '131px !important',
			overflow: 'auto',
		},
		'@media (min-width: 1200px) and (max-width: 1250px)': {
			height: '58px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1280px) and (max-width: 1340px) and (min-height:600px) and (max-height:660px)': {
			height: '154px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1280px) and (max-width: 1340px) and (min-height:790px) and (max-height:1000px)': {
			height: '264px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1350px) and (max-width: 1380px) and (min-height:500px) and (max-height:649px)': {
			height: '105px !important',
			overflow: 'auto',
			marginLeft: '-9px !important',
		},

		'@media (min-width: 1350px) and (max-width: 1380px) and (min-height:650px)': {
			height: '190px !important',
			overflow: 'auto',
			marginLeft: '-9px !important',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:610px) and (max-height:660px)': {
			height: '184px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:670px) and (max-height:690px)': {
			height: '212px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1400px) and (max-width: 1499px) and (min-height:691px) and (max-height:789px)': {
			height: '232px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1401px) and (max-width: 1499px) and (min-height:790px) and (max-height:1200px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1350px) and (min-height:600px) and (max-height:650px) and (max-width: 1399px)': {
			height: '140px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 600px) and (max-height: 699px) and (max-width:1599px)': {
			height: '162px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 450px) and (max-height: 600px) and (max-width:1599px)': {
			height: '200px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1500px) and (min-height: 700px) and (max-width:1599px)': {
			height: '254px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1600px) and (min-height: 700px) and (max-width:1699px)': {
			height: '255px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1700px) and (min-height: 700px) and (max-height:799px)': {
			height: '235px !important',
			overflow: 'auto',
			marginLeft: '-5px !important',
		},

		'@media (min-width: 1700px) and (min-height: 400px) and (max-height: 699px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1700px) and (min-height: 800px) and (max-height:850px)': {
			height: '270px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1601px) and (max-width: 1699px)': {
			height: '200px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1601px) and (max-width: 1699px) and (min-height: 1000px) and (max-height:1200px)': {
			height: '288px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1801px) and (max-width: 1850px)': {
			height: '266px !important',
			overflow: 'auto',
		},

		'@media (min-width: 1851px) and (max-width: 2000px)': {
			height: '290px !important',
			overflow: 'auto',
		},
		'@media (min-width: 2001px) and (max-width: 2200px)': {
			height: '300px !important',
			overflow: 'auto',
		},
		'@media (min-width: 2700px) and (max-width: 3000px)': {
			height: '400px !important',
			overflow: 'auto',
		},
	},

	stickyresponsive: {
		'@media (min-width: 1800px) and (max-width: 2050px)': {
			height: '61px !important',
		},
		'@media (min-width: 2070px) and (max-width: 4000px)': {
			height: '64px !important',
		},
		'@media (min-width: 1420px) and (max-width: 1799px)  ': {
			height: '57px !important',
		},
		'@media (min-width: 1350px) and (max-width: 1419px)  ': {
			height: '54px !important',
		},
		'@media (min-width: 1200px) and (max-width: 1349px)  ': {
			height: '52px !important',
		},
	},

	stickyresponsiveright: {
		'@media (min-width: 1350px) and (max-width: 1419px) and (min-height:750px) and (max-height:1100px)': {
			height: '64px !important',
		},

		'@media (min-width: 1200px) and (max-width: 1349px) and (min-height:750px) and (max-height:1100px) ': {
			height: '62px !important',
		},
	},
	formControlnew: {
		margin: theme.spacing(1),
		minWidth: 223,
		[theme.breakpoints.down('xs')]: {
			minWidth: 88,
		},
		marginBottom: '8px',
		borderRadius: '4px',
		'&:hover': { background: `#e9f5f8 !important` },
		'& label + .MuiInput-formControl': {
			marginTop: '6px',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '15px',
		},
		'& .MuiInputLabel-formControl': {
			transform: 'translate(0, 16px) scale(1)',
		},
	},

	formControlLenEmpty: {
		'& .MuiInputLabel-formControl': {
			transform: 'translate(0, 16px) scale(1)',
		},
	},
	floorRoomNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {
			float: 'right',
		},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '142px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	headerNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '129px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	alignStyle: {
		padding: '0px 0px !important',
	},
	headerMenuLinkActive: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		fontWeight: 500,
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
	},

	headerSpacesAndDesksLinkActive: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '70%',
		fontWeight: 500,
		fontSize: '1rem',
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	spaceAndDeskheaders: {
		color: '#72879E !important',
		fontSize: '1rem',
		fontWeight: 400,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},

	btngrpattende: {
		'&:hover': {
			backgroundColor: '#e9f5f8 !important',
		},
	},

	btngrpattendeplus: {
		'&:hover': {
			backgroundColor: '#e9f5f8 !important',
		},
	},

	pickerStyle: {
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'transparent !important',
		},

		'& .MuiInputLabel-root': {
			marginLeft: '12px !important',
			marginTop: '2px !important',
		},
		'& .MuiInputBase-input': {
			marginLeft: '11px !important',
		},
	},

	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,

			background: '#f9fbfd',
			marginLeft: '23px',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '86%',
			marginLeft: '23px',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-inputRoot:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiInputBase-input:hover': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},

		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
	},
	option: {
		'&[data-focus="true"]': {
			backgroundColor: '#f4f8fc',
		},

		'&[aria-selected="true"]': {
			backgroundColor: '#f4f8fc',
		},
	},

	dropBoxContainer: {
		background: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		width: '240px',
		padding: theme.spacing(2),
		display: 'none',
	},
	dropBoxContainerVisible: {
		display: 'block',
	},
	dropBoxContainerDisplay: {
		position: 'absolute',
		right: '16px',
		textTransform: 'capitalize',
	},
	dropBox: {
		height: '56px',
		width: '208px',
		margin: theme.spacing(1.5, 0),
		'& > label': {
			cursor: 'pointer',
		},
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	itemLengthLabel: {
		margin: theme.spacing(2, 0),
	},
	footerIcons: {
		borderRadius: theme.shape.borderRadius,
		height: '72px',
		width: '434px',
		flexWrap: 'nowrap',
		textAlign: 'center',
		overflow: 'auto',
		background: `${theme.palette.grey[50]} 0% 0% no-repeat padding-box`,
		boxShadow: `0px 3px 6px ${theme.palette.text.primary}52`,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0),
		},
	},
	iconBox: {
		cursor: 'pointer',
		color: theme.palette.text.secondary,
		'&:active, &:hover': {
			'& > * , & > * svg': {
				color: theme.palette.primary.main,
				fontWeight: 'bold',
			},
		},
	},
	TextField: {},
	iconBoxSelected: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		'& > * , & > * svg': {
			color: theme.palette.primary.main,
			fontWeight: 'bold',
		},
	},
	iconBoxDisabled: {
		color: theme.palette.grey[100],
		'& > * , & > * svg': {
			height: '30px',
			width: '30px',
			color: theme.palette.grey[100],
		},
	},
	iconsDivider: {
		margin: theme.spacing(1, 0.5),
		'& > *': {
			backgroundColor: theme.palette.text.secondary,
		},
	},
	footerIcon: {
		width: '48px',
		height: '48px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	footerIconLabel: {
		fontSize: '9px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '24px',
		width: 'auto',
	},
	footerBtn: {
		height: '36px',
		width: '140px',
		margin: theme.spacing(0, 1, 0, 0),
	},

	framecontainer: {
		width: '100%',
		marginBottom: 5,
	},

	outlinedBtn: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '4px',
		padding: theme.spacing(0, 0.125),
	},
	footerTabs: {
		width: '195px',
		height: '36px',
		minHeight: '36px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '185px',
		},
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		'& .Mui-selected, & .Mui-selected:hover': {
			backgroundColor: theme.palette.primary.main,
			color: `${theme.palette.background.paper}`,
		},
		'& button:last-child': {
			borderRight: 'none',
		},
	},
	rootItem: {
		minWidth: '62px',
		minHeight: '36px',
		borderRight: `1px solid ${theme.palette.primary.main}`,
		[theme.breakpoints.down('xs')]: {
			width: '33.3%',
		},
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	indicator: {
		display: 'none',
	},
	zoomSize: {
		padding: '0px',
		margin: '0px',
	},

	canvasWidth: {
		'@media (min-width: 1700px) and (max-width:1800px)': {
			width: '117% !important',
		},
	},
	canvasscreen: {
		width: '100%',
		height: 'calc(100vh - 175px)',
		display: 'flex',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	canvasWrapperGridItem: {
		padding: '0.3rem 0.3rem 0 0.3rem',
		height: '100%',
	},

	canvasWrapperDrawerOpen: {
		width: 'calc(100% - 185px)',
		height: '432px',
	},
	statusMsg: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			backgroundColor: theme.palette.success.main,
			padding: theme.spacing(1),
		},
	},
	closeIcon: {
		'& > *': {
			color: theme.palette.background.paper,
		},
	},
	footerIconJustify: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'space-between',
		},
	},
	displayFixed: {
		position: 'fixed',
		bottom: '8px',
		width: 'auto',
		display: 'flex',
		zIndex: 'inherit',
	},
	paper: {
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		flexDirection: 'column',
		boxShadow: 'none !important',
		'& .MuiPaper-elevation1': { boxShadow: 'none !important' },
		'& .MuiInputBase-input': {
			background: 'none',
		},
		'& .makeStyles-paper-51': {
			padding: '0px',
		},
		'& .MuiPaper-elevation4': {
			boxShadow: 'none',
		},
	},

	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 150px)',
		margin: '0 !important',
	},
	Dimensions: {
		display: 'inline-block',
		objectFit: 'contain',
		textAlign: 'center',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
		'@media (min-width:1280px)': {
			marginTop: '10px',
		},
		'@media (min-width:1454.55px)': {
			marginTop: '18px',
		},
		'@media (min-width:1600px)': {
			marginTop: '28px',
		},
		'@media (min-width:1777.73px)': {
			marginTop: '43px',
		},

		'@media (min-width:2000px)': {
			marginTop: '65px',
		},
		'@media  (min-width:2133.33px)': {
			marginTop: '80px',
		},
		'@media (min-width:2400px)': {
			marginTop: '110px',
		},
		'@media (min-width:3200px)': {
			marginTop: '190px',
		},
		'@media (min-width:4800px)': {
			marginTop: '345px',
		},
		'@media (min-width:6400px)': {
			marginTop: '500px',
		},
	},
	filterDimensions: {
		display: 'inline-block',
		objectFit: 'contain',
		textAlign: 'center',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
		'@media (min-width:1280px)': {
			position: 'relative',
			top: '20px',
		},
		'@media (min-width:1454.55px)': {
			position: 'relative',
			top: '30px',
		},
		'@media (min-width:1600px)': {
			position: 'relative',
			top: '80px',
		},

		'@media (min-width:1777.73px)': {
			position: 'relative',
			top: '80px',
		},

		'@media (min-width:2000px)': {
			position: 'relative',
			top: '130px',
		},
		'@media  (min-width:2133.33px)': {
			position: 'relative',
			top: '150px',
		},
		'@media (min-width:2400px)': {
			position: 'relative',
			top: '200px',
		},
		'@media (min-width:3200px)': {
			position: 'relative',
			top: '350px',
		},
		'@media (min-width:4800px)': {
			position: 'relative',
			top: '650px',
		},
		'@media (min-width:6400px)': {
			position: 'relative',
			top: '950px',
		},
	},

	textDimension3: {
		'@media (min-width:1280px)': {
			marginLeft: '26px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '20px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '3px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '2px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '0px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '0px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-30px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-100px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-180px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-300px',
		},
	},
	textDimension4: {
		'@media (min-width:1280px)': {
			marginLeft: 'px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: 'px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '2px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '2px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '0px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '0px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-30px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-90px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-150px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-290px',
		},
	},
	textDimension1: {
		'@media (min-width:1280px)': {
			marginLeft: '35px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '20px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '16px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '12px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '1px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '-8px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '-22px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-80px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-150px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-260px',
		},
	},
	textDimension2: {
		'@media (min-width:1280px)': {
			marginLeft: '50px',
		},
		'@media (min-width:1454.55px)': {
			marginLeft: '40px',
		},
		'@media (min-width:1600px)': {
			marginLeft: '35px',
		},
		'@media (min-width:1777.73px)': {
			marginLeft: '35px',
		},

		'@media (min-width:2000px)': {
			marginLeft: '23px',
		},
		'@media  (min-width:2133.33px)': {
			marginLeft: '17px',
		},
		'@media (min-width:2400px)': {
			marginLeft: '4px',
		},
		'@media (min-width:3200px)': {
			marginLeft: '-70px',
		},
		'@media (min-width:4800px)': {
			marginLeft: '-120px',
		},
		'@media (min-width:6400px)': {
			marginLeft: '-270px',
		},
	},

	spcfixed: {
		right: 0,
		position: 'fixed',
		left: '270px',
		'@media (min-width: 0px) and (max-width: 699px)': {
			position: 'relative !important',
			left: '0px !important',
		},
	},
	accessDimensions: {
		width: '45% !important',
		height: '3% !important',
		margin: 'auto',
		display: 'block',
		'@media (min-width: 1260px) and (min-height: 590px) and (max-width:1359px) and (max-height:690px)': {
			width: '49% !important',
		},
		'@media (min-width: 1400px) and (min-height: 650px) and (max-width:1499px) and (max-height:750px)': {
			width: '48% !important',
		},
		'@media (min-width: 1600px) and (min-height: 740px) and (max-width:1699px) and (max-height:840px)': {
			width: '44% !important',
		},
		'@media (min-width: 1700px) and (min-height: 800px) and (max-width:1799px) and (max-height:900px)': {
			width: '48% !important',
		},
		'@media (min-width: 1900px) and (min-height: 900px) and (max-width:1999px) and (max-height:1000px)': {
			width: '53% !important',
		},
		'@media (min-width: 2540px) and (min-height: 1200px) and (max-width:2640px) and (max-height:1300px)': {
			width: '52% !important',
		},
	},

	accessDimensions2: {
		width: '95%',
		height: '3%',
		margin: 'auto',
		display: 'block',
	},
	zoomButtons: {
		width: '95%',
		height: '3%',
		margin: 'auto',
		display: 'block',
	},

	tabs: {
		'& .MuiTab-root': {
			minWidth: '0px !important',
			minHeight: '52.6px!important',
		},
		' & .MuiTab-textColorInherit': {
			opacity: '1 !important',
		},
	},
	PaymentCaptionnew: {
		textOverFlow: 'ellipsis',
		position: 'relative',
		top: '90px',
	},
	condecoImage: {
		width: '45%',
		margin: 'auto',
		marginTop: '70px',
	},
	backbutton: {
		background: '#66CCFA',
		margin: '5px',
		color: '#fff',
		boxShadow: 'none',
		'&:hover': {
			background: '#56b1df',
			boxShadow: 'none',
			margin: '5px',
			color: '#fff',
		},
	},
}));

const BookingsView = observer(() => {
	const appStores = React.useContext(stores.context.appStores);
	const { route, navigate } = React.useContext(stores.context.routes);
	const classes = useStyles();
	const [entityType, setEntityType] = React.useState('room');
	const spaces = React.useContext(stores.context.spaces);
	const [zones, setZones] = React.useState([]);
	const [floorImgLoaded, setfloorImgLoaded] = React.useState(false);
	const [open, setStatusMessageOpen] = React.useState('');
	const user = React.useContext(stores.context.user);
	const [Bkngs, setBookings] = React.useState(false);
	React.useEffect(() => {
		const fetchDetails = async () => {
			await fetchCondecoDetails();
		};

		fetchDetails();
	}, [user.currentSiteId, spaces, appStores]);
	const fetchCondecoDetails = async () => {
		appStores.showAppLoader = true;
		const result = await spaces.fetchCondecoDetails(user.currentSiteId);
		setBookings(result && result.ISCondecoAvailable);
		appStores.showAppLoader = false;
	};
	const showStatusForDeleteCoordinates = () => {
		setStatusMessageOpen('deleted');
		setEntityType('room');
	};
	const setMappedEntityIds = (mappedEntityIds: string[]) => {
		floorplanDetail.mappedEntityIds = mappedEntityIds;
	};

	const floordata = user.floorsingledata ? user.floorsingledata : '';

	const TabPanel = (props: any) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	};

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.any.isRequired,
		value: PropTypes.any.isRequired,
	};

	const [appliedFilter, setAppliedFilter]: any = React.useState(null);
	const formRef: any = React.useRef();
	formRef.current = appliedFilter;
	const [counter, setCounter] = React.useState(1);
	const handleChangenew = (event: any, counter: any) => {
		handleZoom(counter + 1);
	};
	let floorplanDetail: IFloorplanDetail = useLocalStore(() => ({
		URL: '',
		propertyTypeId: '',
		propertyId: '',
		propertyName: '',
		controlType: '',
		savedFloorplanCoordinates: [],
		ZoneData: [],
		showPropContainer: false,
		mappedEntityIds: [],
		entitiesList: [],
		entitiesListByType: [],
		isEntityTypeDisabled: false,
	}));

	React.useEffect(() => {
		setAppliedFilter(null);
	}, [entityType]);
	React.useEffect(() => {
		const fetchFloorEntities = () => {
			spaces
				.fetchEntities(
					{
						clientId: route.params.clientId,
						EntityId: route.params.floorId,
						isDescendants: 'descendants',
					},
					false,
				)
				.then((list: IEntitie[]) => {
					floorplanDetail.entitiesList = list;
					floorplanDetail.entitiesListByType = floorplanDetail.entitiesList.filter(node => {
						return node.Type === entityType;
					});
				});
		};

		fetchFloorEntities();

		if (route.params.clientId != 'NA' && route.params.floorId != 'NA') {
			FloorplanEditorCanvas.fetchFloorplanImage(
				spaces,
				route,
				entityType,
				showStatusForDeleteCoordinates,
				setMappedEntityIds,
				true,
			).then(() => {
				setfloorImgLoaded(true);
				FloorplanEditorCanvas.resetCanvas('deleteObjects');
				let canvasObj: fabric.Canvas | ICanvas = FloorplanEditorCanvas.getCanvas();
				canvasObj &&
					canvasObj.on('mouse:down', (options: any) => {
						floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
						let canvasActiveObject: IObject = canvasObj.getActiveObject()!;

						if (!floorplanDetail.controlType && canvasActiveObject && canvasActiveObject.type !== 'activeSelection') {
							floorplanDetail.propertyId = canvasActiveObject.id || '';
							floorplanDetail.showPropContainer = true;
						} else {
							const evt = options.e as any;
							canvasObj['isDragging'] = true;
							canvasObj['selection'] = false;
							canvasObj['lastPosX'] = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
							canvasObj['lastPosY'] = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
						}
					});
				canvasObj &&
					canvasObj.on('mouse:up', () => {
						if (floorplanDetail.controlType) {
							floorplanDetail.showPropContainer = FloorplanEditorCanvas.floorplanDetail.showPropContainer;
							floorplanDetail.controlType = FloorplanEditorCanvas.floorplanDetail.controlType;
						} else {
							canvasObj['isDragging'] = false;
							canvasObj['selection'] = true;
						}
					});
				canvasObj &&
					canvasObj.on('object:modified', () => {
						floorplanDetail.isEntityTypeDisabled = true;
					});
				canvasObj &&
					canvasObj.on('mouse:move', (options: any) => {
						if (canvasObj['isDragging']) {
							const evt = options.e as any;
							const clientX = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
							const clientY = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
							if (canvasObj['viewportTransform']) {
								canvasObj['viewportTransform'][4] += clientX - canvasObj['lastPosX'];
								canvasObj['viewportTransform'][5] += clientY - canvasObj['lastPosY'];
							}
							canvasObj.requestRenderAll();
							canvasObj.getObjects().forEach((object: fabric.Object) => {
								object.setCoords();
							});
							canvasObj['lastPosX'] = clientX;
							canvasObj['lastPosY'] = clientY;
						}
					});
			});
		}
	}, [route, spaces, floorplanDetail, entityType, floordata]);
	const handleSingleRoom = () => {
		let sessionData: any = sessionStorage.getItem('localCoordinates') || null;
		sessionData = JSON.parse(sessionData);
		const abd: any[] = [
			{
				coordinates: {
					coordinateType: sessionData?.coordinateType,
					points: sessionData?.points,
				},
			},
		];
		FloorplanEditorCanvas.floorplanDetail.savedFloorplanCoordinates = abd;
		FloorplanEditorCanvas.showFloorplanCoordinates(false, false, true);
	};

	React.useEffect(() => {
		const fetchZoneData = async () => {
			const test = spaces.currentSiteId ? spaces.currentSiteId : '';
			spaces.fetchZoneDetails(test, route.params.floorId).then(async (res: any) => {
				await setZones(res.Zones);
			});
		};
		fetchZoneData();
	}, [spaces.currentSiteId, route.params.floorId]);
	FloorplanEditorCanvas.initDrawerOpen(appStores.drawerOpen);

	React.useEffect(() => {
		spaces.fetchAllClientsSpaces().then((list: any) => {
			let filteredFloorPlans = list.floorplans.filter((e: any) => e.buildingId === floordata.buildingId);
			if (filteredFloorPlans.length > 0) {
			}
		});
	}, [floordata.clientId]);

	const handleZoom = (counter: number) => {
		let canvasObj: fabric.Canvas = FloorplanEditorCanvas.getCanvas();
		canvasObj.zoomToPoint({ x: 20, y: 20 } as fabric.Point, counter);
		setCounter(counter);
	};
	React.useEffect(() => {
		const sessionData: any = sessionStorage.getItem('localCoordinates') || null;
		if (sessionData != null && zones.length) {
			setTimeout(() => {
				handleSingleRoom();
			}, 5000);
		}
	}, [sessionStorage.getItem('localCoordinates'), zones.length]);
	React.useEffect(() => {
		return () => {
			sessionStorage.removeItem('localCoordinates');
		};
	}, []);

	const mybookingpage = () => {
		navigate(
			{
				name: 'myBookings',
			},
			{
				clearParams: true,
			},
		);
	};

	return useObserver(() =>
		!Bkngs ? (
			<div className={classes.root}>
				{open && (
					<Box className={classes.statusMsg}>
						<Alert>
							<Typography variant="caption">
								{open === 'deleted' ? 'Deleted successfully!' : 'Floorplan coordinates saved successfully!'}
							</Typography>
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setStatusMessageOpen('');
								}}
							>
								<Icon icon={faTimesCircle} className={classes.closeIcon} />
							</IconButton>
						</Alert>
					</Box>
				)}

				<div
					style={{
						height: 'calc(100vh - 70px)',
						margin: 0,
						display: 'flex',
						padding: 0,
					}}
					className={appStores.desktopDrawer ? classes.spcfixed : ''}
				>
					<div className={classes.framecontainer}>
						<Grid container spacing={1} style={{ width: '100%', height: '100%' }}>
							<Grid item xs={12} md={12} style={{ marginBottom: '12px' }}>
								<Paper className={classes.paper}>
									<Grid container>
										<Grid item container xs={5} sm={5} justify="flex-end"></Grid>
									</Grid>

									<Grid container style={{ height: '100%' }}>
										<Grid item xs={12} style={{ marginTop: '5px' }}>
											<Button
												variant="contained"
												size="medium"
												className={classes.backbutton}
												onClick={mybookingpage}
												color="primary"
											>
												{' '}
												Back
											</Button>
											<div
												id="canvasWrapper"
												className={appStores.drawerOpen ? classes.canvasWrapperDrawerOpen : classes.canvasscreen}
											>
												<div style={{ position: 'relative', width: '100%' }}>
													<canvas id="floorplanCanvas"></canvas>
												</div>
											</div>
										</Grid>
									</Grid>
									<Grid>
										<div
											style={{
												position: 'sticky',
												overflow: 'hidden',
												height: '56px',
												top: 'calc(100vh-50px)',
											}}
											className={classes.stickyresponsive}
										>
											<hr style={{ opacity: '.5', borderColor: '#66CCFA' }} />
											<div
												style={{
													position: 'absolute',
													width: '300px',
													height: '200px',
													zIndex: 15,
													left: '50%',
													margin: '0px 0 0 -100px',
												}}
											>
												<FormGroup aria-label="position" row>
													{floorImgLoaded ? (
														<FormControlLabel
															value="end"
															style={{ marginTop: '8px' }}
															control={
																<ButtonGroup size="small" aria-label="small outlined button group">
																	<span
																		onClick={() => handleZoom(Math.max(counter - 1, 1))}
																		style={{ marginRight: '2px' }}
																	>
																		<img style={{ width: '24px', marginTop: '-2px' }} src={ZoomOutImg} alt="Zoom out" />
																	</span>
																	<div>
																		<Slider
																			value={counter}
																			min={1}
																			max={5}
																			onChange={handleChangenew}
																			style={{ bottom: '5px', width: '110px' }}
																			aria-labelledby="continuous-slider"
																		/>
																	</div>
																	<span
																		onClick={() => handleZoom(Math.min(counter + 1, 5))}
																		style={{ marginLeft: '12px' }}
																	>
																		<img style={{ width: '24px', marginTop: '-3px' }} src={ZoomInImg} alt="Zoom In" />
																	</span>
																</ButtonGroup>
															}
															label=""
															labelPlacement="start"
														/>
													) : (
														''
													)}
												</FormGroup>
											</div>
										</div>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		) : (
			<>
				<div className={classes.Dimensions}>
					<img src={noResults} className={classes.condecoImage} alt="noresults" />
					<Typography className={classes.PaymentCaptionnew}>
						<a
							href={spaces.condecoDetails && spaces.condecoDetails.CondecoLink}
							target="_blank"
							style={{ textDecoration: 'none' }}
						>
							{' '}
							<img src={CondecoLogo} style={{ marginBottom: '-15px', width: '36px', borderRadius: '6%' }} />
							&nbsp;&nbsp;
							<Button variant="contained" color="primary" style={{ fontSize: '13px', outline: 'none!important' }}>
								Open Condeco booking system
							</Button>
						</a>{' '}
					</Typography>
				</div>
			</>
		),
	);
});

export default BookingsView;
