import * as React from 'react';
import useStyles from '../../assets/css/IconsCss';

const ShareIcon: React.FunctionComponent<any> = () => {
	const classes = useStyles();
	return (
		<svg
			id="Share"
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="23"
			viewBox="0 0 21 23"
			className={classes.shareCss}
		>
			<defs>
				<clipPath id="clip-path">
					<rect id="Rectangle_877" data-name="Rectangle 877" width="21" height="23" fill="none" />
				</clipPath>
			</defs>
			<g id="Group_2123" data-name="Group 2123" opacity="0.5">
				<g id="Group_2122" data-name="Group 2122" clip-path="url(#clip-path)" className={classes.hoverStyle}>
					<path
						id="Path_9410"
						data-name="Path 9410"
						d="M13.91,6.01A3,3,0,1,1,16.5,7.5,2.969,2.969,0,0,1,13.91,6.01Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
					/>
					<path
						id="Path_9411"
						data-name="Path 9411"
						d="M7.09,9.99A3,3,0,1,1,4.5,8.5,2.969,2.969,0,0,1,7.09,9.99Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
					/>
					<path
						id="Path_9412"
						data-name="Path 9412"
						d="M19.5,18.5a3,3,0,1,1-3-3A3,3,0,0,1,19.5,18.5Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
					/>
					<line
						id="Line_4"
						data-name="Line 4"
						x2="6.83"
						y2="3.98"
						transform="translate(7.089 13.008)"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
					/>
					<line
						id="Line_5"
						data-name="Line 5"
						x1="6.82"
						y2="3.98"
						transform="translate(7.089 6.008)"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="3"
					/>
				</g>
			</g>
			<g id="Group_2125" data-name="Group 2125">
				<g id="Group_2124" data-name="Group 2124" clip-path="url(#clip-path)" className={classes.hoverStyle2}>
					<path
						id="Path_9413"
						data-name="Path 9413"
						d="M13.91,6.01A3,3,0,1,1,16.5,7.5,2.969,2.969,0,0,1,13.91,6.01Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<path
						id="Path_9414"
						data-name="Path 9414"
						d="M7.09,9.99A3,3,0,1,1,4.5,8.5,2.969,2.969,0,0,1,7.09,9.99Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<path
						id="Path_9415"
						data-name="Path 9415"
						d="M19.5,18.5a3,3,0,1,1-3-3A3,3,0,0,1,19.5,18.5Z"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<line
						id="Line_6"
						data-name="Line 6"
						x2="6.83"
						y2="3.98"
						transform="translate(7.089 13.008)"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<line
						id="Line_7"
						data-name="Line 7"
						x1="6.82"
						y2="3.98"
						transform="translate(7.089 6.008)"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	);
};

export default ShareIcon;
