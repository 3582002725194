import React, { useState, useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import {
	Typography,
	makeStyles,
	Grid,
	Button,
	Box,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Container,
} from '@material-ui/core';
import { string as yupString, object as yupObject } from 'yup';
import classnames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ISiteObject } from '../types';
import { useForm } from 'react-hook-form';
import { stores } from '../store';
import { IFeedbackForm, ContainerID } from '../types';
import { faPaperPlane as TelegramLight } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane as TelegramSolid } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import { faPlusCircle as PlusSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle as PlusLight } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		width: '280px',
		height: '36px !important',
		marginLeft: '0px',

		'&:hover': {
			'& .MuiInputBase-input': {
				backgroundColor: '#66CCFA !important',
			},
			'& > .MuiInputLabel-formControl': {
				color: '#FFFFFF',
			},
			'& .MuiSvgIcon-root': {
				color: '#FFFFFF !important',
			},
		},
		'& > .MuiInputLabel-formControl': {
			lineHeight: 0,
			color: '#66CCFA',
			marginTop: '-2px',
		},
		'& .MuiSvgIcon-root': {
			color: '#66CCFA !important',
		},
		'& .MuiInputLabel-shrink': {
			color: '#098BBD',
		},
	},

	noLabel: {
		margin: theme.spacing(1),
		width: '280px',
		height: '36px !important',
		marginLeft: '0px',
		'& > .MuiInputLabel-formControl': {
			display: 'none',
			border: '#098BBD !important',
		},
		'& > .MuiSelect-select': {
			paddingTop: '7px',
			paddingBottom: '24px',
			border: '2px solid #098BBD !important',
			padding: '2px',
		},
		'& .MuiInputBase-input': {
			color: '#FFFFFF',
			backgroundColor: '#098BBD !important',
			border: '2px solid #098BBD !important',
			borderRadius: '4px',
		},
		'& .MuiSvgIcon-root': {
			color: '#098BBD !important',
		},
	},
	formSelect: {
		width: '100%',
		'& .MuiSelect-select': {
			border: '2px solid #66CCFA',
			background: '#FFFFFF',
			height: '0px',
			borderRadius: '4px',
			padding: '16px',
			paddingTop: '8px',
			paddingBottom: '24px',
		},
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		overflow: 'scroll',
		maxWidth: '100%',
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	gridItem: {
		'@media (min-width: 960px)': {
			flexGrow: 0,
			maxWidth: '100%',
			flexBasis: '100%',
		},
	},
	formHelp: {
		width: '162px',
		overflow: 'hidden',
		marginLeft: '10px',
	},
	paper: {
		color: theme.palette.text.primary,
	},
	alignButton: {
		width: '880px',
		height: '110px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	appBarSpacer: {
		height: '64px',
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	text: {
		textAlign: 'left',
		fontSize: '18px',
		letterSpacing: '0px',
	},
	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		margin: theme.spacing(1),
		marginLeft: '6px',
		width: '162px',
		padding: theme.spacing(1),
		height: '46px',
		color: '#1fa4d8',
		fontSize: '16px',
		'&:hover': {
			border: '2px solid #66ccfa',
			borderRadius: '8px',
		},
	},
	buttonWraper: {
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(1),
		background: 'none',
		border: '2px solid #66ccfa',
		fontSize: '16px',
		color: '#14A0D6',
		'&:hover': {
			background: '#66ccfa',
			fontColor: 'white !important',
			color: 'white !important',
		},
		'&[disabled]': {
			border: '2px solid rgba(0, 0, 0, 0.26)',
			opacity: 0.2,
		},
	},
	telegram: {
		background: '#66ccfa',
		height: '46px',
		width: '46px',
		marginRight: '-2px',
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
	},
	telegramActive: {
		background: '#14a0d6',
		height: '46px',
		width: '46px',
		marginRight: '-2px',
		borderRadius: '4px',
	},
	InputField: {
		'&.MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline': {
			background: 'none',
		},
		'& .MuiInputBase-input MuiInput-input': {
			background: 'none',
		},
		'& .MuiInputBase-input': {
			background: '#FFFFFF !important',
			width: '95% !important',
		},
		'& .MuiOutlinedInput-input': {
			padding: '8.5px 12px',
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '0px',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '8px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#8B8DAE',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#14a0d6',
		},
	},
	uploader: {
		display: 'none',
	},
	errorInputText: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#BD0810',
			paddingBottom: '0px',
		},
		'& .MuiFormHelperText-contained': {
			margin: '2px !important',
		},
	},
	grid: {
		paddingRight: '60px',
	},
	formControlLabel: {
		'& .MuiIconButton-label': {
			color: '#bd0810',
		},
		'& .makeStyles-Autocomplete-11 .MuiInputLabel-root': {
			color: '#bd0810',
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
			color: '#bd0810',
		},

		'& .MuiInputBase-input:hover': {
			backgroundColor: 'transparent !important',
		},
	},
	Autocomplete: {
		width: '280px',
		height: '36px',
		marginLeft: '8px',
		'& .MuiInputLabel-root': {
			zIndex: 999,
			marginTop: '-8px',
			marginLeft: '0px',
			color: '#66CCFA',
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
			padding: '4px 4px',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '2px solid #098BBD',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: '2px solid #098BBD',
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child': {
			padding: '1px',
			fontSize: '14px',
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
			paddingRight: '0px',
		},
		'& .MuiFilledInput-root': {
			background: 'none',
			paddingRight: '0px !important',
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
			paddingTop: '3px',
			PaddingLeft: '8px',
		},
		'& > .MuiInputBase-input MuiFilledInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused MuiInputBase-inputAdornedEnd MuiFilledInput-inputAdornedEnd MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense': {
			marginRight: '-65px',
			marginLeft: '-4px',
		},
		'& .MuiInputLabel-shrink': {
			color: '#098BBD',
		},
	},
	typoFeed: {
		width: '114px',
		height: '22px',
		color: '#002855',
		letterSpacing: '0px',
		textAlign: 'left',
		font: 'normal normal normal 18px/12px Roboto',
		marginTop: '15px',
		paddingTop: '5px',
		marginLeft: '-3px',
		opacity: 1,
	},
	textSubject: {
		width: '53px',
		height: '19px',
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
		paddingTop: '1px',
		marginTop: '8px',
		marginLeft: '7px',
		marginBottom: '8px',
		opacity: 1,
	},
	textDetails: {
		width: '49px',
		color: '#002855',
		height: '19px',
		marginTop: '12px',
		marginLeft: '7px',
		marginBottom: '8px',
		opacity: 1,
	},

	location: {
		border: '2px solid #66CCFA',
		overflow: 'hidden',
		borderRadius: 4,
		marginTop: '8px',
		'& > .MuiFilledInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiFilledInput-underline:after': {
			borderBottom: 'none',
		},
		'& label + .MuiInput-formControl': {
			marginTop: '0px',
		},
		'& .MuiInputBase-input': {
			background: '#FFFFFF !important',
			marginLeft: '-4px',
		},
		'& .MuiSvgIcon-root': {
			color: '#66CCFA !important',
		},
		'&:hover': {
			backgroundColor: '#66CCFA !important',
			'& .MuiInputBase-input': {
				backgroundColor: '#66CCFA !important',
			},
			'& .MuiInputLabel-root': {
				color: '#FFFFFF',
				backgroundColor: '#66CCFA !important',
			},
			'& .MuiSvgIcon-root': {
				color: '#FFFFFF !important',
			},
			'& .MuiInputLabel-shrink': {
				color: '#098BBD',
			},
		},
		'&:active': {
			'& .MuiInputLabel-root': {
				color: '#098BBD',
				backgroundColor: '#ffffff !important',
			},
		},
	},
	locationSelected: {
		color: '#FFFFFF',
		background: '#098BBD',
		padding: '2px',
		borderRadius: '4px',
		'& .MuiInputLabel-root': {
			display: 'none',
		},
		'& .MuiInputBase-input': {
			color: '#FFFFFF',
			background: '#098BBD',
			caretColor: 'transparent',
		},
		'& .MuiFilledInput-underline:after': {
			borderBottom: 'none',
		},
		'& > .MuiFilledInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiAutocomplete-endAdornment': {
			color: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			color: '#098BBD !important',
		},
	},
}));

const fieldSchema = yupObject().shape({
	subject: yupString().required('Subject is required'),
	details: yupString().required('Details is required'),
	osversion: yupString().required('Operating system Version is required'),
});

const Feedback: React.FunctionComponent = observer(() => {
	const classes = useStyles();
	const user = useContext(stores.context.user);
	const site = useContext(stores.context.site);
	const feedback = useContext(stores.context.feedback);
	const notifications = useContext(stores.context.notifications);
	const blob = useContext(stores.context.blob);
	const [hover, setHover] = React.useState(false);
	const [isImageUpload, setIsImageUpload] = useState(false);
	const [buttonHover, setButtonHover] = React.useState(false);
	const [uploadImageName, setUploadImageName] = useState('');
	//const [location, setLocation] = useState<SelectTreeDataNode>();
	const [siteVal, setSite] = React.useState('');
	const [siteKey, setSiteKey] = React.useState(0);
	const [hasError, setHasError] = React.useState(false);
	const [deviceError, setDeviceError] = React.useState(false);
	const [deviceModel, setdeviceType] = React.useState('');
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const methods = useForm<IFeedbackForm>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const date = new Date(new Date().setDate(new Date().getDate() + 1));
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : site.siteList;
	const { errors, register, reset, formState } = methods;
	let feedbackOptions: IFeedbackForm = useLocalStore(() => ({
		subject: '',
		details: '',
		osversion: '',
		deviceModel: '',
		fileObject: '',
		Building: '',
		feedbackId: '',
		fileFormat: '',
		fileError: false,
		token: '',
		container: 0,
		createdDate: new Date(),
		currentDate: new Date(),
		contentType: '',
		extension: '',
		Id: '',
		Name: '',
		site: '',
		key: '',
		title: '',
		selectable: true,
	}));
	const [values, setValues] = useState({
		details: '',
		subject: '',
		site: '',
		osversion: '',
		deviceModel: '',
	});
	const clearState = () => {
		reset();
		setSiteKey(siteKey + 1);
		setUploadImageName('');
		setdeviceType('');
		setSite('');
	};
	const handleClose = () => {
		setDialogOpen(false);
	};
	const handleFeedbackUpload = (event: React.ChangeEvent<any>) => {
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		feedbackOptions.fileObject = '';
		feedbackOptions.fileError = false;

		setUploadImageName('');
		setIsImageUpload(false);

		if (uploadedFile && (uploadedFile.type === 'image/png' || uploadedFile.type === 'image/jpeg')) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			feedbackOptions.fileFormat = uploadedFile.type === 'image/png' ? 'png' : 'jpg';
			feedbackOptions.extension = feedbackOptions.fileFormat;
			fileReader.onload = async (e: any) => {
				feedbackOptions.fileObject = e.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
				feedbackOptions.container = ContainerID.Ask;
				setUploadImageName(uploadedFile.name);
				if (uploadedFile.size >= 5000000) {
					setDialogOpen(true);
					feedbackOptions.fileError = true;
				}
				feedbackOptions.contentType = uploadedFile.type;
				const token = await blob.blobToken();
				feedbackOptions.token = token.uploadToken;
				let postData = {
					Token: token.uploadToken,
					Container: feedbackOptions.container,
					Data: feedbackOptions.fileObject,
					ContentType: feedbackOptions.contentType,
					Extension: feedbackOptions.extension,
				};
				const response = await blob.blobImage(postData);
				if (response && uploadedFile.size <= 5000000) {
					feedbackOptions.fileError = false;
				} else {
					feedbackOptions.fileError = true;
				}
				setIsImageUpload(true);
			};
		} else {
			feedbackOptions.fileError = true;
		}
	};
	const handleDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setdeviceType(event.target.value);
		setDeviceError(false);
	};
	const submitFeedBackForm = async (event: React.SyntheticEvent) => {
		if (deviceModel === '') {
			setDeviceError(true);
		}
		if (deviceModel !== '') {
			const siteDataList = sitedatanew ? df : site.siteList;
			const siteName = siteDataList.findIndex((nam: any) => nam.Id === (siteVal === '' ? user.currentSiteId : siteVal));
			const locationName = siteName !== -1 ? siteDataList[siteName].Name : '';
			const { subject, details, osversion } = values;
			feedbackOptions.subject = subject;
			feedbackOptions.details = details;
			feedbackOptions.osversion = osversion;
			feedbackOptions.deviceModel = deviceModel;
			feedbackOptions.site = siteVal;
			feedbackOptions.Building = locationName as string;
			feedback.submitFeedBackForm(feedbackOptions).then(response => {
				if (!response) {
					notifications.addNotification('Feedback sent, thank you', 'success');
					setSiteKey(siteKey + 1);
					setUploadImageName('');
					clearState();
				} else {
					notifications.addNotification('Something went wrong, Please try again', 'error');
				}
			});
		}
	};

	React.useEffect(() => {
		isImageUpload == false ? setIsImageUpload(false) : setIsImageUpload(true);
		//setIsImageUpload(false);
	}, []);

	const handleInputChange = (event: React.ChangeEvent<any>) => {
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
		if (
			!errors.subject &&
			!errors.details &&
			!errors.osversion &&
			values.subject.length > 0 &&
			values.details.length > 0 &&
			values.osversion.length > 0 &&
			uploadImageName.length > 0
		) {
			setIsImageUpload(true);
		}
	};

	return (
		<Container className={classes.container}>
			<main className={classes.content}>
				{user && user.token ? (
					<Grid style={{ marginLeft: '39px' }} container alignItems="center">
						<Grid className={classes.gridItem} item md={5}>
							<div>
								<Typography className={classes.typoFeed} variant="body1">
									Aria Feedback
								</Typography>
							</div>
							<form style={{ width: '880px' }}>
								<Box style={{ paddingTop: '5px', paddingLeft: '7px' }}>
									<FormControl
										variant="filled"
										fullWidth
										className={deviceModel ? classes.noLabel : classes.formControl}
										error={deviceError}
									>
										<InputLabel>Device</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											name="deviceModel"
											className={classes.formSelect}
											onChange={handleDevice}
											MenuProps={{
												getContentAnchorEl: null,
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left',
												},
											}}
											value={deviceModel}
											disableUnderline={true}
										>
											<MenuItem value={'Android Phone'}>Android Phone</MenuItem>
											<MenuItem value={'iPhone'}>iPhone</MenuItem>
											<MenuItem value={'Android Tablet'}>Android Tablet</MenuItem>
											<MenuItem value={'iPad'}>iPad</MenuItem>
											<MenuItem value={'Windows PC'}>Windows PC</MenuItem>
											<MenuItem value={'Mac'}>Mac</MenuItem>
										</Select>

										{deviceError === true ? (
											<FormHelperText style={{ marginTop: 'auto' }}>Device model is required</FormHelperText>
										) : (
											''
										)}
									</FormControl>

									<FormControl error={hasError} className={hasError === true ? classes.formControlLabel : ''}>
										<div key={siteKey}>
											<Autocomplete
												options={(sitedatanew ? df : site.siteList).filter(
													(item: any) => item.LocationStatus !== 'INACTIVE',
												)}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												onChange={(event: any, newValue: any) => {
													setSite(newValue.Id);

													const inactiveLocations = (sitedatanew ? df : site.siteList).filter(
														item => item.LocationStatus === 'INACTIVE',
													);

													if (inactiveLocations.find(item => item.Id === newValue.Id)) {
														setHasError(true);
													} else {
														setHasError(false);
													}
												}}
												renderInput={params => (
													<TextField
														{...params}
														className={siteVal ? classes.locationSelected : classes.location}
														label="Select Site"
														variant="filled"
														margin="normal"
													/>
												)}
											/>
										</div>
										{hasError === true ? (
											<FormHelperText style={{ marginTop: 'auto' }}>
												This site is inactive, please select another site
											</FormHelperText>
										) : (
											''
										)}
									</FormControl>

									<FormControl>
										<TextField
											autoComplete="off"
											style={{
												width: '280px',
												marginLeft: '16px',
											}}
											//label="Operating System Version"
											type="text"
											name="osversion"
											id="outlined-basic"
											variant="outlined"
											fullWidth
											margin="normal"
											placeholder="Operating System Version..."
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											InputLabelProps={{
												shrink: true,
											}}
											helperText={errors.osversion ? errors.osversion.message : ''}
											error={errors.osversion ? true : false}
											FormHelperTextProps={{ className: classes.errorInputText }}
											className={classes.InputField}
										/>
									</FormControl>
								</Box>
								<Box>
									<Typography className={classes.textSubject} variant="body1">
										Subject
									</Typography>
									<TextField
										autoComplete="off"
										style={{
											width: '872px',
											height: '36px',
											borderRadius: '8px',
											background: '#FFFFFF',
											marginLeft: '7px',
										}}
										//label="Subject"
										type="text"
										name="subject"
										fullWidth
										id="outlined-basic"
										variant="outlined"
										margin="normal"
										placeholder="Write your message here..."
										onChange={handleInputChange}
										inputRef={e => {
											register(e);
										}}
										InputLabelProps={{
											shrink: true,
										}}
										helperText={errors.subject ? errors.subject.message : ''}
										error={errors.subject ? true : false}
										FormHelperTextProps={{ className: classes.errorInputText }}
										className={classes.InputField}
									/>
								</Box>
								<Box>
									<Typography className={classes.textDetails} variant="body1">
										Details
									</Typography>
									<TextField
										style={{
											width: '872px',
											height: '554px',
											marginLeft: '8px',
										}}
										//label="Details"
										type="text"
										name="details"
										fullWidth
										margin="normal"
										placeholder="Write your message here..."
										id="outlined-basic"
										variant="outlined"
										onChange={handleInputChange}
										inputRef={e => {
											register(e);
										}}
										InputLabelProps={{
											shrink: true,
										}}
										helperText={errors.details ? errors.details.message : ''}
										error={errors.details ? true : false}
										FormHelperTextProps={{ className: classes.errorInputText }}
										multiline
										rows={26.85}
										rowsMax={29}
										className={classes.InputField}
									/>
								</Box>
								<Box>
									<Grid item xs={12} container justify="flex-end" className={classes.alignButton}>
										<div
											style={{ height: '0px', paddingTop: '9px' }}
											onMouseEnter={() => {
												setHover(true);
											}}
											onMouseLeave={() => {
												setHover(false);
											}}
										>
											<input
												accept="image/*"
												id="FeedbackUploader"
												onChange={handleFeedbackUpload}
												type="file"
												className={classes.uploader}
												disabled={feedbackOptions.feedbackId ? true : false}
											/>
											<label htmlFor="FeedbackUploader" className={classnames(classes.fileUploadInput)}>
												<Icon
													icon={hover ? PlusSolid : PlusLight}
													style={{
														height: '32px',
														width: '32px',
														color: '#66CCFA',
														marginTop: '5px',
														marginLeft: '-8px',
													}}
												/>
												Attach Photos
											</label>
											{!feedbackOptions.fileError ? (
												<FormHelperText className={classes.formHelp}>
													{uploadImageName ? uploadImageName : ''}
												</FormHelperText>
											) : feedbackOptions.fileError ? (
												<FormHelperText className={classes.formHelp} error>
													{'Please select jpg, png, jpeg types'}
												</FormHelperText>
											) : null}
										</div>

										<div
											style={{ marginRight: '-8px', marginTop: '9px', height: '0px' }}
											onMouseEnter={() => {
												setButtonHover(true);
											}}
											onMouseLeave={() => {
												setButtonHover(false);
											}}
										>
											<Button
												className={classes.buttonWraper}
												style={{ width: '162px', height: '46px', paddingRight: '0px' }}
												color="primary"
												onClick={submitFeedBackForm}
												disabled={
													hasError === true || feedbackOptions.fileError === true || !formState.isValid ? true : false
												}
											>
												Submit Form
												<Icon
													icon={buttonHover ? TelegramSolid : TelegramLight}
													className={buttonHover ? classes.telegramActive : classes.telegram}
													style={{
														height: '32px',
														width: '32px',
														marginTop: '6px',
														color: 'white',
														marginRight: '-1px',
													}}
												/>
											</Button>
										</div>
									</Grid>
								</Box>
							</form>
						</Grid>
					</Grid>
				) : (
					''
				)}
				<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={dialogOpen}>
					<DialogContent>
						<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
							Failed
						</DialogTitle>
						<DialogContentText>Cannot upload 'Media item' larger than 5MB.</DialogContentText>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</main>
		</Container>
	);
});

export default Feedback;
