import { action, observable } from 'mobx';
import { postAPI } from 'services/apiService';

interface IRegisterValue {
	email?: string;
	name?: string;
	telephone?: string;
}
class Register {
	@observable
	public email = '';
	@observable
	public name = '';
	@observable
	public telephone = '';
	@observable
	public regsiterUser = false;
	@action
	public async saveUser(userRegistration: IRegisterValue) {
		let postData = {
			userRegistration: {
				Email: userRegistration.email,
				Name: userRegistration.name,
				TelephoneNumber: userRegistration.telephone,
				Groups: [],
			},
		};
		return await postAPI('/register/save', postData);
	}
}
export default Register;
