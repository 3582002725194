import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	statusFeed: {
		height: '124px',
		objectFit: 'fill',
	},
	mainFeed: {
		height: '255px',
		objectFit: 'fill',
	},
	cardView: {
		paddingLeft: '11px !important',
		'@media (min-width: 1250px) and (max-width: 1735px)': {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		'@media (min-width: 600px) and (max-width: 1249px)': {
			display: 'none',
		},
		'@media (min-width: 0px) and (max-width: 599px)': {
			display: 'none',
		},
	},
	archiveCardView: {
		paddingLeft: '31px !important',
		marginLeft: '-5px',
		'@media (min-width: 1280px) and (max-width: 1794px)': {
			maxWidth: '500px',
			flexBasis: '500px',
		},
		'@media (min-width: 250px) and (max-width: 1279px)': {
			maxWidth: '100%',
			flexBasis: '100%',
		},
	},
	mainCard: {
		marginTop: '6px',
		width: '460px',
		height: '280px',
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '8px',
	},
	statusCard: {
		marginTop: '6px',
		width: '460px',
		height: '124px',
		borderRadius: '8px',
		boxShadow: '0px 3px 6px #00000029',
	},
	gridWidth: {},
}));

export default useStyles;
