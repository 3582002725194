import React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import {
	Grid,
	Divider,
	Typography,
	Paper,
	makeStyles,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from '@material-ui/pickers';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { stores } from '../../store';
import { IEvacuation, Category, evacuationType, repeat, ISiteObject } from '../../types';
import { compareAsc } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';

const yup = require('yup');

const Evacuation = observer(() => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const notifications = React.useContext(stores.context.notifications);
	const { route } = React.useContext(stores.context.routes);

	const [responseError, setResponseError] = React.useState('');

	const fieldSchema = yup.object().shape({
		startTime: yup.string().required('Time is required'),
	});
	const methods = useForm<IEvacuation>({
		mode: 'onChange',
		validationSchema: fieldSchema,
	});
	const { register, errors, formState } = methods;
	const [values, setValues] = React.useState({
		category: Category.EvacuationTest,
		uploadToken: null,
		EvacuationType: route.params.EvacuationType ? route.params.EvacuationType : 0,
		SiteId: route.params.SiteId ? route.params.SiteId : '',
		sites: '',
		StartTime: route.params.StartTime ? route.params.StartTime : new Date(),
		RepeatFrequency: route.params.RepeatFrequency ? route.params.RepeatFrequency : 0,
		RepeatUntil: route.params.RepeatUntil ? route.params.RepeatUntil : new Date(),
		StartDayTime: '',
		formType:
			route.params.status && route.params.status === 'Published'
				? 'Edit Published '
				: route.params.Id
				? 'Edit'
				: route.params.Heading
				? 'Edit Copy'
				: 'Add',
	});
	const [selectedStartTime, setSelectedStartTime] = React.useState<Date>(
		route.params.StartTime ? new Date(route.params.StartTime) : new Date(),
	);
	const [selectedRepeatUntil, setSelectedRepeatUntil] = React.useState<Date>(
		route.params.RepeatUntil ? new Date(route.params.RepeatUntil) : new Date(),
	);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [showEvacuation, setShowEvacuation] = React.useState(0);
	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [siteVal, setSite] = React.useState(route.params.SiteId ? user.currentSiteId : '');

	const [startTimeError, setStartTimeError] = React.useState('');
	const [endRepeatTimeError, setEndRepeatTimeError] = React.useState('');
	const startTimeRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const routes = React.useContext(stores.context.routes);
	const [isSubmit, setIsSubmit] = React.useState(false);
	const appStores = React.useContext(stores.context.appStores);
	const [hasError, setHasError] = React.useState(false);
	appStores.showAppLoader = user.permissions === null ? true : false;

	React.useEffect(() => {
		if (user.permissions) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowEvacuation(1);
				}
			} else {
				setShowEvacuation(0);
			}
		} else {
			setShowEvacuation(0);
		}
	}, [user.permissions]);

	React.useEffect(() => {
		const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
			(item: any) => item.LocationStatus === 'INACTIVE',
		);
		if (inactiveLocations.find((item: any) => item.Id === route.params.SiteId)) {
			setHasError(true);
		} else {
			setHasError(false);
		}
	}, [df, route.params.SiteId]);

	const handleStartTime = (date: Date) => {
		setSelectedStartTime(date);
		setEndRepeatTimeError('');
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		if (Number(values.RepeatFrequency) !== 0 && route.params.RepeatFrequency === '') {
			setSelectedRepeatUntil(date);
		}

		if (values.RepeatUntil.length > 0 && Number(values.RepeatFrequency) !== 0) {
			let schema = yup
				.date()
				.test('repeatUntil', " The 'Start Time' cannot be after the 'Repeat Until'", (value: Date) => {
					return compareAsc(value, selectedRepeatUntil) <= 0;
				});
			schema
				.validate(date)
				.then((response: string) => {
					setStartTimeError('');

					setIsSubmit(true);
				})
				.catch((err: any) => {
					setIsSubmit(true);
					setStartTimeError(err.message);
				});
		}
	};

	const handleRepeatUntil = (date: Date) => {
		setSelectedRepeatUntil(date);
		// setting the key in local storage
		date ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);

		let schema = yup.date().test('startTime', "The 'Repeat Until' cannot be before the 'Start Date'", (value: Date) => {
			return compareAsc(value, selectedStartTime) >= 0;
		});
		schema
			.validate(date)
			.then(() => {
				setEndRepeatTimeError('');
				setIsSubmit(true);
			})
			.catch((err: any) => {
				setIsSubmit(true);
				setEndRepeatTimeError(err.message);
			});
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setValues({ ...values, [name]: value });
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		if (selectedRepeatUntil && evacuationType) {
			setIsSubmit(true);
		}
	};
	const IsDraft = (event: any) => {
		submitEvacuationForm(event.currentTarget.value);
	};
	const submitEvacuationForm = async (event: any) => {
		const { category, uploadToken } = values;

		const datenew = moment(selectedStartTime, 'YYYY-MM-DDTHH:mm:ss').format();

		values.StartDayTime = datenew;

		values.RepeatUntil =
			values.RepeatFrequency !== 0
				? new Date(selectedRepeatUntil).toISOString()
				: new Date(selectedStartTime).toISOString();
		const evacuationForm = { Category: category, UploadToken: uploadToken, Id: 0, Parameters: {} };
		evacuationForm.Category = category;
		evacuationForm.UploadToken = uploadToken;
		evacuationForm.Id = route.params.Id;
		evacuationForm.Parameters = {
			SiteId: [siteVal],
			StartTime: values.StartDayTime,
			RepeatFrequency: values.RepeatFrequency,
			RepeatUntil: values.RepeatUntil,
			EvacuationType: values.EvacuationType,
			IsDraft: Number(event),
		};
		const response = await contentManagement.saveContentManagementValues(
			evacuationForm.Id ? evacuationForm : [evacuationForm],
			route.params.Id ? route.params.Id : '',
		);
		setResponseError(response.Errors);

		if (evacuationForm.Id) {
			if (response.Success === true && response.Error === null) {
				notifications.addNotification(
					`Evacuation test notification ${Number(event) === 1 ? 'saved' : 'published'}`,
					'success',
				);
				window.location.href = '/contentManagement/EvacuationList';
			} else {
				notifications.addNotification('Something went wrong , Please try again', 'error');
			}
		} else {
			if (response.Success === true && response.Errors === null) {
				notifications.addNotification(
					`Evacuation test notification ${Number(event) === 1 ? 'saved' : 'published'}`,
					'success',
				);
				window.location.href = '/contentManagement/EvacuationList';
			} else {
				if (response.Errors.length > 0 && response.Errors !== null) {
					notifications.addNotification('Something went wrong , Please try again', 'error');
				}
			}
		}
	};

	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{showEvacuation === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item xs={12} sm={8} md={4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									{values.formType} Evacuation test
								</Typography>
								<Divider />

								<form>
									<Grid className={classes.gridStyle}>
										<InputLabel className={classes.keyboardDateTime}>Evacuation Test</InputLabel>

										<Select
											name="EvacuationType"
											value={values.EvacuationType}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
											fullWidth
										>
											{Object.keys(evacuationType).map((value, index) => (
												<MenuItem key={index} value={index}>
													<div className={classes.selectItem}>{value}</div>
												</MenuItem>
											))}
										</Select>

										{route.params.SiteId ? (
											<>
												<Autocomplete
													options={(sitedatanew ? df : sites.siteList).filter(
														(item: any) => item.LocationStatus !== 'INACTIVE',
													)}
													getOptionLabel={option => option.Name}
													size="small"
													className={classes.Autocomplete}
													id="size-small-standard"
													noOptionsText="Loading..."
													defaultValue={sitedatanew ? df[siteName] : sites.siteList[siteName]}
													autoComplete
													onChange={(event: any, newValue: any) => {
														newValue !== ''
															? localStorage.setItem('CMValue', 'true')
															: localStorage.setItem('CMValue', 'false');
														localStorage.setItem('URL', window.location.pathname);
														setSite(newValue.Id);
														const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
															item => item.LocationStatus === 'INACTIVE',
														);
														if (inactiveLocations.find(item => item.Id === newValue.Id)) {
															setHasError(true);
														} else {
															setHasError(false);
														}
													}}
													renderInput={params => (
														<TextField {...params} label="Select Site" variant="filled" margin="normal" />
													)}
												/>
												{hasError === true ? (
													<FormHelperText style={{ margin: 'auto' }}>
														This site is inactive, please select another site
													</FormHelperText>
												) : (
													''
												)}
											</>
										) : (
											<>
												<Autocomplete
													options={(sitedatanew ? df : sites.siteList).filter(
														(item: any) => item.LocationStatus !== 'INACTIVE',
													)}
													getOptionLabel={option => option.Name}
													size="small"
													className={classes.Autocomplete}
													id="size-small-standard"
													noOptionsText="Loading..."
													autoComplete
													onChange={(event: any, newValue: any) => {
														newValue !== ''
															? localStorage.setItem('CMValue', 'true')
															: localStorage.setItem('CMValue', 'false');
														localStorage.setItem('URL', window.location.pathname);
														setSite(newValue.Id);
														const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
															item => item.LocationStatus === 'INACTIVE',
														);
														if (inactiveLocations.find(item => item.Id === newValue.Id)) {
															setHasError(true);
														} else {
															setHasError(false);
														}
													}}
													renderInput={params => (
														<TextField {...params} label="Select Site" variant="filled" margin="normal" />
													)}
												/>
												{hasError === true ? (
													<FormHelperText style={{ margin: 'auto' }}>
														This site is inactive, please select another site
													</FormHelperText>
												) : (
													''
												)}
											</>
										)}
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<>
												<DateTimePicker
													name="startTime"
													label="Starts at"
													margin="normal"
													format="dd/MM/yyyy HH:mm"
													ampm={false}
													fullWidth
													className={classes.root}
													disablePast
													value={selectedStartTime}
													error={Boolean(startTimeError)}
													helperText={startTimeError}
													onChange={handleStartTime}
													inputProps={{ className: classes.dateStyle }}
													InputLabelProps={{ className: classes.InputLabel }}
													inputRef={e => {
														register(e);
														startTimeRef.current = e;
													}}
												/>
												{Boolean(errors.StartTime) ? (
													<FormHelperText error={Boolean(errors.StartTime)}>
														{errors.StartTime ? errors.StartTime.message : ''}
													</FormHelperText>
												) : (
													''
												)}
											</>
										</MuiPickersUtilsProvider>

										<InputLabel className={classes.keyboardDateTime}>Repeat</InputLabel>
										<div className={classes.root}>
											<Select
												name="RepeatFrequency"
												label="repeat"
												value={values.RepeatFrequency}
												inputRef={e => {
													register(e);
												}}
												onChange={handleInputChange}
												fullWidth
											>
												{Object.keys(repeat).map((value, index) => (
													<MenuItem key={index} value={index}>
														<div className={classes.selectItem}>{value}</div>
													</MenuItem>
												))}
											</Select>
										</div>
										{Object.keys(repeat)[values.RepeatFrequency] !== '' &&
										Object.keys(repeat)[values.RepeatFrequency] !== 'Never' ? (
											<>
												{' '}
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														name="repeatUntil"
														label="Repeat until"
														margin="normal"
														format="dd/MM/yyyy"
														fullWidth
														disablePast
														className={classes.root}
														value={selectedRepeatUntil}
														error={Boolean(endRepeatTimeError)}
														helperText={endRepeatTimeError}
														onChange={handleRepeatUntil}
														inputProps={{ className: classes.dateStyle }}
														InputLabelProps={{ className: classes.InputLabel }}
														inputRef={e => {
															register(e);
														}}
													/>
												</MuiPickersUtilsProvider>
												{responseError && responseError !== null ? (
													<FormHelperText error={Boolean(responseError)}>{responseError}</FormHelperText>
												) : (
													''
												)}
											</>
										) : (
											''
										)}
									</Grid>

									<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
										<Button
											color="primary"
											disabled={!formState.isValid && !isSubmit && siteVal == ''}
											onClick={() => {
												localStorage.removeItem('CMValue');
												routes.navigate(
													{ name: 'evacuation' },
													{
														clearParams: true,
													},
												);
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											value="1"
											disabled={(!formState.isValid && !isSubmit) || siteVal === ''}
											onClick={IsDraft}
										>
											Save as Draft
										</Button>
										<Button
											color="primary"
											value="0"
											disabled={(!formState.isValid && !isSubmit) || siteVal === ''}
											onClick={IsDraft}
										>
											Publish
										</Button>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
				) : showEvacuation === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
			</main>
		</>
	));
});

export default Evacuation;

const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,

			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-endAdornment': {
			right: '0px !important',
		},

		'& .MuiFilledInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'double',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
		paddingTop: 10,
	},
	paper: {
		color: theme.palette.text.primary,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		textAlign: 'left',
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	keyboardDateTime: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		textAlign: 'left',
		width: '100%',
	},
	formControl: {
		paddingTop: theme.spacing(0.5),
		width: '100%',
	},

	dateStyle: {
		fontSize: theme.typography.pxToRem(14),
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.pxToRem(22),
	},
	selectItem: {
		fontSize: theme.typography.pxToRem(16),
		paddingLeft: theme.spacing(2),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},

	root: {
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'double',
		},
	},
}));
