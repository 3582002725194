import * as React from 'react';
import {
	makeStyles,
	Box,
	Menu,
	MenuItem,
	Typography,
	Fab,
	Dialog,
	DialogTitle,
	Button,
	DialogContentText,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
import { stores } from '../../store';
import { observer } from 'mobx-react-lite';
import { faCog, faInfoCircle, faUser, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import { faUserCog as faUserCogSolid } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles(theme => ({
	accountIcon: {
		width: '40px',
		height: '40px',
		boxShadow: 'none',
		'& .fa-user-cog': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	listicon: {
		margin: theme.spacing(0, 1, 0, 0),
	},
}));

const UserAccountIcon = observer(() => {
	const user = React.useContext(stores.context.user);
	const routes = React.useContext(stores.context.routes);
	const classes = useStyles();
	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const [esmeDialogOpennew, setEsmeDialogOpennew] = React.useState(false);
	const [rotName, setRoutName] = React.useState('');

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const dialogfn = (val: any) => {
		setRoutName(val);
		if (routes.testval === true && window.location.pathname === '/ask/chatbot') {
			setEsmeDialogOpen(true);
		} else if (routes.viewmyjobval === true && window.location.pathname === '/ask/view-my-jobs') {
			setEsmeDialogOpen(true);
		} else {
			routes.navigate(
				{ name: val },
				{
					clearParams: true,
				},
			);
		}
	};

	const dialogfnnew = () => {
		if (routes.testval === true && window.location.pathname === '/ask/chatbot') {
			setEsmeDialogOpennew(true);
		} else if (routes.viewmyjobval === true && window.location.pathname === '/ask/view-my-jobs') {
			setEsmeDialogOpennew(true);
		} else {
			user.logout();
			handleClose();
		}
	};

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const handleCloseChat = () => {
		routes.testval = false;
		routes.viewmyjobval = false;
		setEsmeDialogOpen(false);
		routes.navigate(
			{ name: rotName },
			{
				clearParams: true,
			},
		);
	};

	const handleClickClosenew = () => {
		setEsmeDialogOpennew(false);
	};

	const handleCloseChatnew = () => {
		routes.testval = false;
		routes.viewmyjobval = false;
		setEsmeDialogOpennew(false);
		user.logout();
	};

	return (
		<>
			<Box>
				<Fab onClick={handleClick} color="primary" aria-label="add" className={classes.accountIcon}>
					<Icon icon={faUserCogSolid} />
				</Fab>
				{user.isUserLoggedIn && (
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						elevation={3}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						className="menu-class"
					>
						<MenuItem
							onClick={() => {
								dialogfn('profile');
							}}
						>
							<Icon icon={faUser} className={classes.listicon} />
							<Typography variant="inherit">Profile</Typography>
						</MenuItem>

						<MenuItem
							onClick={() => {
								dialogfn('settings');
							}}
						>
							<Icon icon={faCog} className={classes.listicon} />
							<Typography variant="inherit">Settings</Typography>
						</MenuItem>

						<MenuItem
							onClick={() => {
								dialogfn('about');
							}}
						>
							<Icon icon={faInfoCircle} className={classes.listicon} />
							<Typography variant="inherit">About</Typography>
						</MenuItem>

						<MenuItem
							onClick={() => {
								dialogfnnew();
							}}
						>
							<Icon icon={faSignOut} className={classes.listicon} />
							<Typography variant="inherit">Logout</Typography>
						</MenuItem>
					</Menu>
				)}
			</Box>
			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChat} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClose} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpennew} onClose={handleClickClosenew}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classes.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChatnew} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClosenew} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default UserAccountIcon;
