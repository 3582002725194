import { ICanvasFloorplanDetail, ICanvas } from '../../types';

const circleControl = {
	mouseDown(canvas: ICanvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		const evt = options.e as any;
		canvas.isDragging = true;
		canvas.selection = false;
		canvas.lastPosX = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
		canvas.lastPosY = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
	},

	mouseUp(canvas: ICanvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		canvas.isDragging = false;
		canvas.selection = true;
	},

	mouseMove(canvas: ICanvas, options: fabric.IEvent) {
		if (canvas.isDragging) {
			const evt = options.e as any;
			const clientX = evt.touches ? evt.touches.item(0).clientX : evt.clientX;
			const clientY = evt.touches ? evt.touches.item(0).clientY : evt.clientY;
			if (canvas.viewportTransform) {
				canvas.viewportTransform[4] += clientX - canvas.lastPosX;
				canvas.viewportTransform[5] += clientY - canvas.lastPosY;
			}
			canvas.requestRenderAll();
			canvas.getObjects().forEach((object: fabric.Object) => {
				object.setCoords();
			});
			canvas.lastPosX = clientX;
			canvas.lastPosY = clientY;
		}
	},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
};

export default {
	...circleControl,
};
