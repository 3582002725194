import * as React from 'react';
import { useLocalStore, useObserver, observer } from 'mobx-react-lite';
import { makeStyles, Grid, Paper, Container, Typography, Link, FormHelperText } from '@material-ui/core';
import { SelectTreeDataNode as DataNode, SelectTree, Icon } from '@mitie/material-ui-extensions';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { stores } from '../store';
import classnames from 'classnames';
import accessDeniedIcon from '../assets/images/access-denied.png';

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 85px)',
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		textAlign: 'left',
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 0),
	},
	fileUploadInputError: {
		borderBottom: `${theme.palette.error.main} 1px solid`,
		color: theme.palette.error.main,
	},
	createCancelLink: {
		'& > *': {
			cursor: 'pointer',
			margin: theme.spacing(0, 0, 0, 2),
		},
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	accessDimensions: {
		width: '70%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},
}));

function findTreeNode(key: React.ReactText, treeData: DataNode[]): DataNode | undefined {
	for (const node of treeData) {
		if (node.key === key) {
			return node;
		}
		if (node.children) {
			const childNode = findTreeNode(key, node.children);
			if (childNode) {
				return childNode;
			}
		}
	}
	return;
}

interface IFloorplanDetail {
	fileObject: string;
	fileContentType: string;
	fileName: string;
	fileFormat: string;
	active: boolean;
	default: boolean;
	floorplanId: string;
	fileError: boolean;
}

const FloorplanUploader = observer(() => {
	const classes = useStyles();
	const routes = React.useContext(stores.context.routes);
	const spaces = React.useContext(stores.context.spaces);
	const appStores = React.useContext(stores.context.appStores);
	const user = React.useContext(stores.context.user);
	appStores.showAppLoader = user.permissions === null ? true : false;
	const [isFloorplanUpload, setIsFloorplanUpload] = React.useState(false);
	const [uploadImageName, setUploadImageName] = React.useState('');
	const [showFloorInfo, setShowFloorInfo] = React.useState(0);

	let floorplanDetail: IFloorplanDetail = useLocalStore(() => ({
		fileObject: '',
		fileContentType: '',
		fileName: '',
		fileFormat: '',
		active: true,
		default: false,
		floorplanId: '',
		fileError: false,
	}));

	const [treeData, setTreeData] = React.useState([]);
	const [filter, setFilter] = React.useState('');
	const [floor, setFloor] = React.useState<any>();
	const [errmsgnew, setError] = React.useState('');

	const handleFloorplanUpload = (event: React.ChangeEvent<any>) => {
		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		uploadedFile !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');

		floorplanDetail.fileObject = '';
		floorplanDetail.fileError = false;
		setUploadImageName('');
		setIsFloorplanUpload(false);
		if (uploadedFile && (uploadedFile.type === 'image/png' || uploadedFile.type === 'image/jpeg')) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			floorplanDetail.fileContentType = uploadedFile.type;
			floorplanDetail.fileFormat = uploadedFile.type === 'image/png' ? 'png' : 'jpg';
			fileReader.onload = (e: any) => {
				floorplanDetail.fileObject = e.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
				setUploadImageName(uploadedFile.name);
				setIsFloorplanUpload(true);
			};
			setError('');
		} else {
			floorplanDetail.fileError = true;
			setError('Please select jpg,png,jpeg types');
		}
	};

	const uploadFloorplan = (event: React.SyntheticEvent) => {
		localStorage.removeItem('CMValue');
		if (floorplanDetail.fileObject) {
			appStores.showAppLoader = true;
			spaces.uploadFloorplan(floorplanDetail).then(response => {
				appStores.showAppLoader = false;
				if (response && response.status && response.status.success) {
					routes.navigate(
						{ name: 'spaces' },
						{
							clearParams: true,
						},
					);
				}
			});
		}
		event.preventDefault();
	};

	React.useEffect(() => {
		if (
			user.permissions &&
			user.permissions.Modules.find((m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8')
		) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create &&
					user.permissions.Modules[spacesIndex].Update &&
					user.permissions.Modules[spacesIndex].Delete
				) {
					setShowFloorInfo(1);
				}
			} else {
				setShowFloorInfo(0);
			}
		}
	}, [user.permissions]);

	React.useEffect(() => {
		spaces.fetchAllClients().then((list: any) => {
			setTreeData(list);
		});
	}, [spaces, setTreeData]);

	const fetchEntities = (treeNode: any) => {
		const node = findTreeNode(treeNode.key, treeData);
		if (node) {
			spaces.fetchEntities({ clientId: treeNode.ClientId, EntityId: treeNode.EntityId }, true).then(list => {
				node.children = list;
				setTreeData([...treeData]);
			});
		}
	};

	const filteredTreeData = treeData.filter((node: any) => {
		return filter && node && node.title ? node.title.toLowerCase().includes(filter.toLowerCase()) : true;
	});

	return useObserver(() => (
		<Container className={classes.container}>
			{showFloorInfo === 1 && appStores.showAppLoader === false ? (
				<Grid container direction="row" spacing={1}>
					<Grid item xs={12} sm={4}>
						<Paper className={classes.paper}>
							<Grid>
								<Grid container item xs={12} justify="flex-start">
									<Typography variant="h5" paragraph={true}>
										Add Floor Plan
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<SelectTree
										label="Floor"
										loadData={async treeNode => {
											fetchEntities(treeNode);
										}}
										treeData={filteredTreeData}
										filter={filter}
										onFilterChange={setFilter}
										checkable={false}
										selectedNode={floor}
										onSelect={(node: any) => {
											setFloor(node);
											node ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
											localStorage.setItem('URL', window.location.pathname);
											if (node && node.isLeaf) {
												floorplanDetail.fileName = node.name + '_' + node.EntityId;
												floorplanDetail.floorplanId = node.EntityId;
											} else {
												floorplanDetail.floorplanId = '';
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<input
										accept="image/*"
										id="floorplanUploader"
										onChange={handleFloorplanUpload}
										type="file"
										style={{ display: 'none' }}
										disabled={floorplanDetail.floorplanId ? false : true}
									/>
									<label
										htmlFor="floorplanUploader"
										className={classnames(
											classes.fileUploadInput,
											floorplanDetail.fileError ? classes.fileUploadInputError : '',
										)}
									>
										<Typography
											variant="h6"
											color={floorplanDetail.fileError ? 'error' : 'textSecondary'}
											paragraph={true}
										>
											{uploadImageName ? uploadImageName : 'Add image'}
										</Typography>
										<Icon icon={faFileUpload} />
									</label>
									{errmsgnew !== '' ? (
										<FormHelperText style={{ marginTop: '5px', color: '#BD0810' }}>{errmsgnew}</FormHelperText>
									) : (
										''
									)}
								</Grid>
								<br />
								<Grid item xs={12}>
									<Typography variant="body2" paragraph={true}>
										Floor Plans can be uploaded as .jpg or .png files.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2" paragraph={true}>
										Once floor plans are uploaded the file will be checked to ensure the format is suitable.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2" paragraph={true}>
										Once verified it will appear in the uploaded plans list, where it can be made the default floor plan
										or edited to add room and desk information.
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justify="flex-end"
									alignItems="center"
									className={classes.createCancelLink}
								>
									<Link
										color="primary"
										underline="none"
										onClick={() => {
											localStorage.removeItem('CMValue');
											routes.navigate(
												{ name: 'spaces' },
												{
													clearParams: true,
												},
											);
										}}
									>
										Cancel
									</Link>
									<Link
										color="primary"
										underline="none"
										onClick={uploadFloorplan}
										className={!isFloorplanUpload ? classes.disableLink : ''}
									>
										Create
									</Link>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			) : showFloorInfo === 0 && appStores.showAppLoader === false ? (
				<>
					<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
					<div className={classes.textMargin}>
						<Typography variant="subtitle2">You do not have permission to access this area</Typography>
						<Typography variant="caption">Please contact your manager to request this access</Typography>
					</div>
				</>
			) : (
				''
			)}
		</Container>
	));
});

export default FloorplanUploader;
