import * as React from 'react';
import useStyles from '../../assets/css/IconsCss';

const LikeIcon: React.FunctionComponent<any> = () => {
	const classes = useStyles();
	return (
		<svg
			id="Favourite"
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="23"
			viewBox="0 0 29.551 26.776"
			className={classes.likeIconStyle}
		>
			<defs>
				<radialGradient
					id="radial-gradient"
					cx="0.354"
					cy="0.332"
					r="0.84"
					gradientTransform="matrix(1, -0.001, 0.001, 1.18, 0, -0.059)"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stop-color="#90dcff" />
					<stop offset="1" stop-color="#14a0d6" />
				</radialGradient>
			</defs>
			<g id="Heart" transform="translate(2.935 2.56)">
				<g id="Group_4340" transform="translate(1.999 2)" opacity="0.5">
					<g id="Vector" className={classes.hoverStyle}>
						<path
							id="Path_9408"
							data-name="Path 9408"
							d="M3.815,10.672a5.01,5.01,0,0,1,0-6.769,4.315,4.315,0,0,1,6.366,0l1.767,1.879L13.715,3.9A4.343,4.343,0,0,1,16.9,2.5a4.24,4.24,0,0,1,1.724.366A4.48,4.48,0,0,1,20.082,3.9a5,5,0,0,1,0,6.769l-8.133,8.646Z"
							transform="translate(-2.499 -2.5)"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1"
							stroke-dasharray="0 0 0 0"
						/>
					</g>
				</g>
				<g id="Group_4340-2" transform="translate(0 0)" opacity="0.5">
					<g id="Vector-2" transform="translate(0 0)" className={classes.hoverStyle}>
						<path
							id="Path_9409"
							data-name="Path 9409"
							d="M2.4,12.138a7.061,7.061,0,0,1,0-9.64,6.291,6.291,0,0,1,9.194,0l.353.37L12.3,2.5a6.311,6.311,0,0,1,4.6-2,6.213,6.213,0,0,1,2.491.521A6.457,6.457,0,0,1,21.5,2.5a7.06,7.06,0,0,1,0,9.639L11.948,22.147Z"
							transform="translate(-0.5 -0.5)"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1"
							stroke-dasharray="0 0 0 0"
						/>
					</g>
				</g>
				<g id="Group_4340-3" transform="translate(0.997 1)" className={classes.hoverStyle2}>
					<path
						id="Vector-3"
						d="M19.008,1.943a5.293,5.293,0,0,0-6,1.258L11.948,4.32,10.888,3.2a5.3,5.3,0,0,0-7.78,0,6.03,6.03,0,0,0,0,8.207l1.06,1.118,7.78,8.207,7.78-8.207,1.06-1.118a6.029,6.029,0,0,0,0-8.2l0,0h0a5.492,5.492,0,0,0-1.78-1.258Z"
						transform="translate(-1.497 -1.5)"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						stroke-dasharray="0 0 0 0"
					/>
				</g>
			</g>
			<g id="Heart-2" data-name="Heart" opacity="0">
				<g id="Group_4340-3-2" data-name="Group_4340-3">
					<path
						id="Vector-3-2"
						data-name="Vector-3"
						d="M26.253,2.117A7.565,7.565,0,0,0,17.77,3.869l-1.5,1.557-1.5-1.557a7.573,7.573,0,0,0-11,0,8.3,8.3,0,0,0,0,11.425l1.5,1.557,11,11.425,11-11.425,1.5-1.557a8.3,8.3,0,0,0,0-11.423l0,0h0a7.764,7.764,0,0,0-2.516-1.752Z"
						transform="translate(-1.497 -1.5)"
						className={classes.hoverStyle2}
					/>
				</g>
			</g>
		</svg>
	);
};

export default LikeIcon;
