import { observable, action } from 'mobx';
import { INotification } from '../types';

class NotificationStore {
	@observable public notifications: INotification[] = [];

	@action
	public addNotification = (message: string, status: string) => {
		this.notifications.push({
			id: Date.now(),
			message,
			status,
		});
	};

	@action
	public deleteNotification = (id: number) => {
		this.notifications.splice(
			this.notifications.findIndex(arrNotification => arrNotification.id === id),
			1,
		);
	};
}

export default NotificationStore;
