import { action, observable } from 'mobx';
// import _ from 'lodash';
import { IAboutMedia, AboutMedia } from '../types';
import { getAPI, putAPI } from 'services/apiService';

class About {
	@observable
	public aboutMedia: IAboutMedia[] = [];
	@observable
	public privacylink: any = null;

	@observable
	public privacylinkupdated: any;

	@observable
	public aboutList: IAboutMedia[] = [];

	@action
	public async fetchAboutMedia() {
		let aboutMedia = await getAPI('/about/media/list');
		// this.aboutMedia = _.sortBy(
		// 	aboutMedia.map((f: IAboutMedia) => ({ ...f, CategoryName: AboutMedia[f.Category] || '' })),
		// 	'Category',
		// );

		this.aboutMedia = aboutMedia.map((f: IAboutMedia) => ({ ...f, CategoryName: AboutMedia[f.Category] || '' }));

		this.aboutMedia.sort((a, b) => a.Category - b.Category);
	}

	//  Params: ClientId
	//  Description: below function for getting privacy links dyanmically based on clientid
	@action
	public async fetchprivacylink(clientId: string) {
		this.privacylink = await getAPI(`/about/${clientId}`);
		return this.privacylink;
	}

	@action
	public async fetchAboutList() {
		this.aboutList = await getAPI(`/about/media/aboutList`);
		return this.aboutList;
	}

	@action
	public async fetchprivacylinkupdated() {
		this.privacylinkupdated = await getAPI(`/about/fetchprivacylinkupdated`);
		return this.privacylinkupdated;
	}
	@action
	public async saveMediaInfo(mediaInfo: IAboutMedia) {
		return await putAPI('/about/media/update', mediaInfo);
	}
}

export default About;
