import * as React from 'react';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@material-ui/core';

interface IIconProps extends Omit<Props, 'size'> {
	margin?: string;
	size?: string;
	iconClassName?: string;
	onClick: () => {};
}

const Icon = (props: IIconProps) => {
	const theme = useTheme();

	const { className, margin, size = '1rem', iconClassName, onClick, ...iconProps } = props;
	return (
		<Box m={margin} className={className} onClick={onClick}>
			<FontAwesomeIcon
				color={theme.palette.text.secondary}
				style={{ fontSize: size }}
				className={iconClassName}
				{...iconProps}
			/>
		</Box>
	);
};

export default Icon;
