import * as React from 'react';
import App from './App';
import { getTheme } from './theme';
import { ThemeProvider } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import MobileView from './pages/MobileView';
const AppContainer = () => {
	const theme = getTheme();
	const renderHomePage = () => {
		if (isMobile) {
			return <MobileView />;
		}
		return <App />;
	};
	return <ThemeProvider theme={theme}>{renderHomePage()}</ThemeProvider>;
};

export default AppContainer;
