import React from 'react';
//import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from 'mobx-react-lite';
import { stores } from '../store';
import { FormHelperText } from '@material-ui/core';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const Captcha = observer(() => {
	const appStores = React.useContext(stores.context.appStores);
	React.useEffect(() => {
		loadCaptchaEnginge(6);
	}, []);
	const doSubmit = (val: any) => {
		let user_captcha = val;
		if (validateCaptcha(user_captcha) == true) {
			appStores.captchaval = true;
			appStores.captchaerr = false;
			//		loadCaptchaEnginge(6);
		} else {
			appStores.captchaval = false;
			appStores.captchaerr = true;
		}
	};
	return (
		<div>
			<div className="container">
				<div className="form-group">
					<div className="col mt-3">
						<LoadCanvasTemplate />
					</div>
					<div className="col mt-3">
						<div>
							<input
								style={{ height: '30px' }}
								placeholder="Enter Captcha"
								id="user_captcha_input"
								name="user_captcha_input"
								type="text"
								onBlur={e => {
									doSubmit(e.target.value);
									if (appStores.captchaval == false) {
										e.target.value = '';
									}
								}}
							></input>
							{appStores.captchaerr === true ? (
								<FormHelperText style={{ marginTop: '5px', marginLeft: '12px', color: '#BD0810', textAlign: 'center' }}>
									Captcha not matched. Please try again.
								</FormHelperText>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
export default Captcha;
