import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { stores } from '../../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import useStyles from '../../assets/css/ListTheme';
import classnames from 'classnames';
import { Bar } from 'react-chartjs-2';
//import accessDeniedIcon from '../../assets/images/access-denied.png';
import { Typography, Paper, Grid, Box, Dialog, DialogActions } from '@material-ui/core';
import { toJS } from 'mobx';
import { ISurvey } from '../../types';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const SurveyReport = observer(() => {
	const { route } = React.useContext(stores.context.routes);
	const routes = React.useContext(stores.context.routes);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const user = React.useContext(stores.context.user);
	const [menus, setMenus] = useState<ISurvey[]>([]);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const classes = useStyles();

	const handleEmailReport = () => {
		contentManagement.fetchSurveyEmailReport(route.params.Id).then(response => {
			if (response.Success) {
				setDialogOpen(true);
			}
		});
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	const fetchSurveyDetail = async () => {
		const results = await contentManagement.fetchSurveyDetail(route.params.Id);
		setMenus(toJS(results));
	};

	useEffect(() => {
		const fetchMenus = async () => {
			await fetchSurveyDetail();
		};
		fetchMenus();
	}, []);

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div>
					<Paper className={classes.paper}>
						<Grid container>
							<Grid item container xs={12} sm={12} className={classes.titleReport} justify="flex-start">
								<Typography variant="subtitle1">
									<b>{menus['SurveyTitle']} </b>
								</Typography>
							</Grid>
							<Grid item container xs={12} className={classes.noPadding} justify="flex-start">
								<Typography variant="inherit" gutterBottom={false}>
									Type: {menus['SurveyType']}{' '}
								</Typography>
							</Grid>
							<Grid item container xs={6} sm={6} className={classes.noPadding} justify="flex-start">
								<Typography variant="inherit"> Starts: {menus['StartDate']} </Typography>
							</Grid>
							<Grid item container xs={6} sm={6} className={classes.noPadding} justify="flex-start">
								<Typography variant="inherit">Ends: {menus['EndDate']}</Typography>
							</Grid>
							<Grid item container xs={12} className={classes.titleReport} justify="flex-start">
								<Typography variant="h6">Survey Details at {moment().format('DD/MM/YYYY')}</Typography>
							</Grid>
							<Grid item container xs={12} className={classes.noPadding} justify="flex-start">
								<Typography variant="inherit">
									<b>Surveys Responses </b> Sent to: {menus['SurveyUsers'] ? menus['SurveyUsers'] : 0 + ' '} Respondents
									:{' '}
									{menus['UsersResponses'] ? menus['UsersResponses'] + ' (' + menus['AverageRating'] + ' %)' : '0 (%)'}{' '}
								</Typography>
							</Grid>
						</Grid>
						<Grid container>
							{' '}
							<Grid item container xs={12} className={classes.titleReport} justify="flex-start"></Grid>
						</Grid>
						<Grid container>
							{menus['Questions']
								? menus['Questions'].map((n: any) => (
										<>
											<Grid item xs={12} sm={6} className={classes.textReport}>
												<div>
													<Box
														style={{ wordWrap: 'break-word' }}
														justifyContent="space-between"
														margin="theme.spacing(2, 0)"
													>
														{'Q' + n.QuestionNumber + '. ' + n.QuestionDescription}
													</Box>
													<Bar
														type="bar"
														data={{
															labels:
																menus['SurveyType'] === 'Star'
																	? ['1-Star', '2-Star', '3-Star', '4-Star', '5-Star']
																	: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
															datasets: [
																{
																	label: menus['SurveyType'] === 'Star' ? 'Stars' : 'NPS',
																	backgroundColor:
																		menus['SurveyType'] === 'Star' ? 'rgba(3, 86, 255, 0.7)' : 'rgba(255, 0, 0, 1.0)',
																	borderColor: 'rgba(0,0,0,1)',
																	borderWidth: 1,
																	data:
																		menus['SurveyType'] === 'Star'
																			? [
																					n.Rating.One >= 1 ? n.Rating.One : null,
																					n.Rating.Two >= 1 ? n.Rating.Two : null,
																					n.Rating.Three >= 1 ? n.Rating.Three : null,
																					n.Rating.Four >= 1 ? n.Rating.Four : null,
																					n.Rating.Five >= 1 ? n.Rating.Five : null,
																			  ]
																			: [
																					n.Rating.Zero >= 1 ? n.Rating.Zero : null,

																					n.Rating.Two >= 1 ? n.Rating.Two : null,
																					n.Rating.Three >= 1 ? n.Rating.Three : null,
																					n.Rating.Four >= 1 ? n.Rating.Four : null,
																					n.Rating.Five >= 1 ? n.Rating.Five : null,
																					n.Rating.Six >= 1 ? n.Rating.Six : null,
																					n.Rating.Seven >= 1 ? n.Rating.Seven : null,
																					n.Rating.Eight >= 1 ? n.Rating.Eight : null,
																					n.Rating.Nine >= 1 ? n.Rating.Nine : null,
																					n.Rating.Ten >= 1 ? n.Rating.Ten : null,
																			  ],
																	barThickness: 30,
																	maxBarThickness: 65,
																	minBarLength: 30,
																	barShowStroke: false,
																},
															],
														}}
														options={{
															title: {
																display: true,
																fontSize: 14,
															},
															legend: {
																display: false,
																//position: 'right',
															},
															scales: {
																yAxes: [
																	{
																		ticks: {
																			beginAtZero: true,
																		},
																		scaleLabel: {
																			display: true,
																			labelString: '%',
																		},
																	},
																],
															},
														}}
													/>
												</div>
												{localStorage.setItem('Rating', n.Rating)}
												{menus['SurveyType'] === 'Star' ? (
													<Box component="fieldset" mb={3} borderColor="transparent">
														{n.AverageRating <= 5 ? (
															<Rating name="read-only" precision={0.5} value={n.AverageRating} readOnly />
														) : (
															''
														)}
														<Typography component="legend" variant="h6">
															Average {n.AverageRating} Stars
														</Typography>
													</Box>
												) : (
													''
												)}
											</Grid>
										</>
								  ))
								: ''}
						</Grid>
						<Grid container>
							{' '}
							<Grid item container xs={12} className={classes.titleReport} justify="flex-start"></Grid>
						</Grid>
						<Grid item xs={12} container justify="flex-end" alignItems="flex-end">
							<div className={classnames(classes.footerIconJustify, classes.displayFixed)}>
								<div className={classnames(classes.footerBtn, classes.outlinedBtn)}>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											routes.navigate({ name: 'survey' }, { clearParams: true });
										}}
									>
										Back
									</Button>
								</div>
								<Button
									variant="contained"
									color="primary"
									className={classes.footerBtn}
									onClick={() => {
										handleEmailReport();
									}}
								>
									Email Report
								</Button>
							</div>
						</Grid>
					</Paper>
				</div>
				<Dialog
					open={dialogOpen}
					onClose={() => handleClose()}
					aria-labelledby="form-dialog-title"
					PaperProps={{ className: classes.dialogBoxStyle }}
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">Email Sent.</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</main>
		</>
	);
});

export default SurveyReport;
