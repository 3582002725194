import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver, observer } from 'mobx-react-lite';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Divider, Typography, Paper, TextField, Button, FormHelperText } from '@material-ui/core';

import accessDeniedIcon from '../../assets/images/access-denied.png';
import { useForm } from 'react-hook-form';

import { string as yupString, object as yupObject } from 'yup';

import PermanentAppBar from 'components/Header/PermanentAppBar';
import { stores } from '../../store';
import { IVisitor, Category, ISiteObject } from '../../types';
const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 0, 3),
		width: '100%',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,

			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
		width: '100%',
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},

	root: {
		'& .MuiFormLabel-root': {
			fontSize: '15.4px',
			'@media (max-width:1280px)': {
				fontSize: '12px',
			},
		},
	},

	selectTreeWidth: {
		width: '100%',
	},
}));

const fieldSchema = yupObject().shape({
	visitor: yupString().required('Visitor name is required'),

	visitorFrom: yupString().required('Reason is required'),
	respondEmail: yupString()
		.required('Email is required')
		.email('Please enter a valid email'),
	collection: yupString().required('Collection is required'),
	hostEmail: yupString()
		.required('HostEmail is required')
		.email('Please enter a valid email'),
});
const Visitor = observer(() => {
	const classes = useStyles();
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const notifications = React.useContext(stores.context.notifications);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const [siteVal, setSite] = React.useState('');
	const [hasError, setHasError] = React.useState(false);

	const [values, setValues] = React.useState({
		visitor: '',
		visitorFrom: '',
		respondEmail: '',
		site: '',
		collection: '',
		hostEmail: '',
	});
	const [showVisitorForm, setShowVisitorForm] = React.useState(0);
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = user.permissions === null ? true : false;
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [siteKey, setSiteKey] = React.useState(0);
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowVisitorForm(1);
				}
			} else {
				setShowVisitorForm(0);
			}
		} else {
			setShowVisitorForm(0);
		}
	}, [user.permissions]);
	const methods = useForm<IVisitor>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, formState, reset } = methods;

	const handleInputChange = (event: React.ChangeEvent<any>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
	};

	const submitVisitorForm = async (event: React.SyntheticEvent) => {
		const visitor: IVisitor = values;
		localStorage.removeItem('CMValue');
		visitor.site = siteVal;
		const visitorForm = { Category: Category.Visitor, UploadToken: null, Parameters: {} };
		visitorForm.Parameters = {
			SiteId: [siteVal],
			VisitorName: visitor.visitor,
			UserEmail: visitor.hostEmail,
			RespondToEmail: visitor.respondEmail,
			VisitorFromOrReasonForAttending: visitor.visitorFrom,
			CollectionPlace: visitor.collection,
		};
		const response = await contentManagement.saveContentManagementValues([visitorForm], '');
		if (response.Success === true) {
			notifications.addNotification('Visitor notification sent', 'success');
			reset();
			setSiteKey(siteKey + 1);
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};

	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{showVisitorForm === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item xs={12} sm={8} md={4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									Visitor
								</Typography>
								<Divider />
								<Grid className={classes.gridStyle}>
									<form>
										<TextField
											type="text"
											name="visitor"
											label="Visitor name"
											fullWidth
											variant="filled"
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.visitor ? errors.visitor.message : ''}
											error={errors.visitor ? true : false}
										/>
										<TextField
											className={classes.root}
											name="visitorFrom"
											label="Visitor from or reason for attending"
											fullWidth
											margin="normal"
											variant="filled"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.visitorFrom ? errors.visitorFrom.message : ''}
											error={errors.visitorFrom ? true : false}
										/>

										<div key={siteKey}>
											<Autocomplete
												options={(sitedatanew ? df : sites.siteList).filter(
													(item: any) => item.LocationStatus !== 'INACTIVE',
												)}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												onChange={(event: any, newValue: any) => {
													const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
														(item: any) => item.LocationStatus === 'INACTIVE',
													);
													if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
														setHasError(true);
													} else {
														setHasError(false);
													}
													setSite(newValue.Id);
													newValue !== ''
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
												}}
												renderInput={params => (
													<TextField {...params} label="Select Site" variant="filled" margin="normal" />
												)}
											/>
											{hasError === true ? (
												<FormHelperText style={{ margin: 'auto' }}>
													This site is inactive, please select another site
												</FormHelperText>
											) : (
												''
											)}
										</div>
										<TextField
											name="collection"
											label="Collection place"
											fullWidth
											margin="normal"
											variant="filled"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.collection ? 'Collection place is required' : ''}
											error={errors.collection ? true : false}
										/>
										<TextField
											name="respondEmail"
											label="Respond to email"
											fullWidth
											margin="normal"
											variant="filled"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.respondEmail ? errors.respondEmail.message : ''}
											error={errors.respondEmail ? true : false}
										/>
										<TextField
											name="hostEmail"
											label="Host email"
											fullWidth
											margin="normal"
											variant="filled"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.hostEmail ? 'Email is required' : ''}
											error={errors.hostEmail ? true : false}
										/>
									</form>
								</Grid>
								<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
									<Button
										color="primary"
										disabled={!formState.isValid || siteVal === ''}
										onClick={() => {
											localStorage.removeItem('CMValue');
											reset();
										}}
									>
										Cancel
									</Button>
									<Button color="primary" disabled={!formState.isValid || siteVal === ''} onClick={submitVisitorForm}>
										Send
									</Button>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : showVisitorForm === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
			</main>
		</>
	));
});

export default Visitor;
