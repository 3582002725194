export default {
	fields: [
		{
			label: 'Title',
			name: 'title',
			displayname: 'Title',
			type: 'text',
		},
		{
			label: 'SubTitle',
			name: 'subtitle',
			displayname: 'SubTitle',
			type: 'text',
		},
		{
			label: 'Description',
			name: 'description',
			displayname: 'Description',
			type: 'text',
		},
		{
			label: 'Date',
			name: 'Date',
			displayname: 'Date',
			type: 'datePicker',
		},
		{
			label: 'Repeat',
			name: 'repeat',
			displayname: 'Repeat',
			type: 'select',
		},
	],
};
