import * as React from 'react';
import { useObserver, useLocalStore, observer } from 'mobx-react-lite';
import { stores } from '../store';
import { faTrashAlt, faImage, faGripLines, faPenSquare } from '@fortawesome/pro-light-svg-icons';
import {
	faPlus as faPlussolid,
	faPenSquare as faPenSquaresolid,
	faImage as faImagesolid,
	faTrashAlt as faTrashAltsolid,
} from '@fortawesome/pro-solid-svg-icons';
import accessDeniedIcon from '../assets/images/access-denied.png';
import { Icon } from '@mitie/material-ui-extensions';
import { getAllowedFileTypes } from '../util';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	makeStyles,
	Avatar,
	Grid,
	TablePagination,
	TableFooter,
	Paper,
	Fab,
	IconButton,
	Dialog,
	Button,
	DialogContentText,
	DialogTitle,
	DialogActions,
	DialogContent,
	Card,
	Tooltip,
	Switch,
} from '@material-ui/core';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
	DraggableProvided,
	DroppableProvided,
	DraggableStateSnapshot,
} from 'react-beautiful-dnd';

interface IFloorplanListOptions {
	pCount?: number;
	pSize?: number;
	sort?: string;
	order?: string;
	searchText?: string;
	totalCount?: number;
}

const useStyles = makeStyles(theme => ({
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		overflow: 'scroll',
		maxWidth: '100%',
		marginLeft: '45px',
		marginTop: '40px',
	},
	paper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	title: {
		padding: theme.spacing(2),
	},
	listPaper: {
		overflow: 'hidden',
		flexDirection: 'column',
		background: '#f4f8fc',
		borderRadius: '16px',
		marginTop: '-4px',
		boxShadow: 'none',
		'& .MuiPaper-elevation1': {
			boxShadow: '0px 3px 5px #00000029',
		},
	},
	listCard: {
		margin: '8px',
		borderRadius: '16px',
	},
	headCard: {
		width: '100%',
		boxShadow: 'none',
	},
	listtableCell: {
		borderBottom: 'none',
		paddingTop: '4px',
		paddingBottom: '4px',
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	spinner: {
		position: 'absolute',
		top: '40%',
		left: '50%',
		zIndex: 1,
	},
	floorplanAddIcon: {
		width: '46px',
		height: '46px',
		backgroundColor: '#66ccfa',
		boxShadow: 'none',
		opacity: 1,
		'&:hover': {
			backgroundColor: '#14a0d6 !important',
			boxShadow: '0px 3px 6px #00000029',
		},
		'& > * svg': {
			width: '21px !important',
			height: '24px',
			color: 'white',
			display: 'flex',
		},
	},
	actionIcons: {
		display: 'flex',
	},
	actionIconButton1: {
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	actionIconButtonimg: {
		'&.MuiIconButton-sizeSmall': {
			padding: '0px',
		},
		'&:hover': {
			backgroundColor: 'rgb(9, 139, 189, 0.08)',
		},
	},
	actionIconButtonupload: {
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		width: '37px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'& > * svg': {
			height: '24px',
			width: '21px !important',
			color: '#8B8DAE',
		},
		'&>*:nth-child(1)': {
			display: 'none',
		},
		'&:hover': {
			'& .svg-inline--fa': {
				color: '#14a0d6',
			},
			'&>*:nth-child(2)': {
				display: 'none',
			},
			'&>*:nth-child(1)': {
				display: 'block',
			},
		},
	},
	actionIconButton: {
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		width: '37px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgb(9, 139, 189, 0.08)',
		},
		'& > * svg': {
			height: '24px',
			width: '21px !important',
			color: '#8B8DAE',
		},
		'& .MuiIconButton-label': {
			'&>*:nth-child(1)': {
				display: 'none',
			},
			'&:hover': {
				'& .svg-inline--fa': {
					color: '#14a0d6',
				},
				'&>*:nth-child(2)': {
					display: 'none',
				},
				'&>*:nth-child(1)': {
					display: 'block',
				},
			},
		},
	},
	tooltip: {
		background: '#8B8DAE',
		fontSize: '12px',
		marginTop: '0px',
		padding: '5px',
	},
	actionHomeIconButtonActive: {
		'& > * svg': {
			color: theme.palette.text.primary,
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	footerCard: {
		boxShadow: 'none',
		marginTop: '8px',
		marginLeft: '1180px',
	},
	noResults: {
		textAlign: 'center',
		marginTop: '25vh',
	},
	textMargin: {
		textAlign: 'center',
	},
	accessDimensions: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	alignStyle: {
		padding: '0px 0px !important',
	},
	cellContentSites: {
		color: '#002855',
		paddingTop: '4px',
		paddingBottom: '6px',
		font: 'normal normal normal 18px/12px Roboto',
	},
	cellContentType: {
		maxWidth: '81px',
		width: '81px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		marginLeft: '215px',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
	},
	cellContentView: {
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
	},
	cellContentFormat: {
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
	},
	cellContentActive: {
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	tableHeaders: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'& > *': {
			visibility: 'hidden',
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableLocation: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableType: {
		display: 'flex',
		alignItems: 'center',
		color: '#002855',
		marginLeft: '602px',
		width: '34px',
		height: '19px',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableView: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	uploader: {
		display: 'none',
	},
	listtableFormat: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableActive: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableActions: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
}));
const FloorplanList = observer(() => {
	const spaces = React.useContext(stores.context.spaces);
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const user = React.useContext(stores.context.user);
	const notifications = React.useContext(stores.context.notifications);
	const classes = useStyles();
	const rowsPerPageOptions = [2, 5, 10, 15];
	const [showFloorPlans, setShowFloorPlans] = React.useState(0);
	const [resultPaginationOption, setResultPaginationOption] = React.useState<any>({});
	const [confirmpopup, setconfirmpopup] = React.useState(false);
	const [flrid, setfloorid] = React.useState<any>();
	const [floorPlans, setFloorPlans] = React.useState([]);
	const [flrfrmt, setflrfrmt] = React.useState<any>();
	appStores.showAppLoader = user.permissions === null ? true : false;
	const confpopup = (flrid: any, flrformat: any) => {
		setconfirmpopup(true);
		setfloorid(flrid);
		setflrfrmt(flrformat);
	};
	const handleClose = () => {
		setconfirmpopup(false);
	};
	const Delete = () => {
		if (flrfrmt) {
			deleteFloorCoordinates(flrid);
		}
	};
	React.useEffect(() => {
		if (user && spaces.allFloorplans.floorplans) {
			const floordata = spaces.allFloorplans.floorplans.filter((todos: any) => todos.buildingId === user.currentSiteId);
			setFloorPlans(floordata);
		}
		if (user.permissions && user.permissions.Modules) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create &&
					user.permissions.Modules[spacesIndex].Update &&
					user.permissions.Modules[spacesIndex].Delete
				) {
					setShowFloorPlans(1);
				}
			} else {
				setShowFloorPlans(0);
			}
		} else {
			setShowFloorPlans(0);
		}
	}, [user.permissions]);

	let floorplanListOptions: IFloorplanListOptions = useLocalStore(() => ({
		pCount: 0,
		pSize: 10,
		sort: 'asc',
		order: 'FloorplanName',
		searchText: '',
		totalCount: 0,
	}));

	const fetchFloorplans = (pagenumber?: number) => {
		const page_number = pagenumber?.toString() || '1';
		appStores.showAppLoader = true;
		spaces.defaultfloorplandata(page_number).then(response => {
			if (response) {
				setFloorPlans(response.Result);
				setResultPaginationOption(response.Pagination);
			}
		});
	};
	const clearFloorplans = () => {
		spaces.allFloorplans = {};
		floorplanListOptions.totalCount = 0;
	};

	localStorage.removeItem('URL');
	localStorage.removeItem('CMValue');

	React.useEffect(() => {
		fetchFloorplans();
	}, [spaces]);
	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		floorplanListOptions.pCount = newPage;
		fetchFloorplans();
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		floorplanListOptions.pSize = parseInt(event.target.value, 10);
		floorplanListOptions.pCount = 0;
		fetchFloorplans();
	};
	const getFloorplanCount = () => {
		return resultPaginationOption?.TotalCount || 0;
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, floorId: string) => {
		console.log('image');
		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		const extensionType = getAllowedFileTypes('Image');
		if (uploadedFile && extensionType.includes(uploadedFile.type)) {
			const fileReader: FileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile!);
			fileReader.onload = async (event: any) => {
				const object = event.target.result.replace('data:' + uploadedFile?.type + ';base64,', '');
				const floorImageData = {
					fileObject: object,
					fileContentType: uploadedFile.type,
				};
				spaces
					.changeFloorImage(floorId, floorImageData)
					.then(resOkay => {
						console.log(resOkay);
						notifications.addNotification('Image has been change successfully ', 'success');
						fetchFloorplans();
					})
					.catch(e => {
						notifications.addNotification('Something went wrong, Please try again', 'error');
					});
			};
		}
	};

	const deleteFloorCoordinates = (floorId: string) => {
		const newfloorplans = floorPlans.filter((ele: any) => ele.FloorId !== flrid);
		setFloorPlans(newfloorplans);
		clearFloorplans();
		spaces.deleteFloorWithFloorID(floorId).then(() => {
			floorplanListOptions.pCount = 0;
			fetchFloorplans();
			setconfirmpopup(false);
		});
	};

	const changeFloorPlanStaus = (item: any) => {
		const statusItem: boolean = item.Active === false ? true : false;
		spaces.changeFloorStaus(item.FloorId, statusItem).then(() => {
			fetchFloorplans();
		});
	};

	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const handleDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}
		const quotes: any = reorder(floorPlans, result.source.index, result.destination.index);
		setFloorPlans(quotes);
		const menuPosition = `${result.destination.index + 1}`;
		const idIndex = quotes.map((e: any) => e.FloorId);
		const menuId = idIndex[result.destination.index];
		const response = await spaces.reorderFloorsPlan(menuId, menuPosition);
		if (response.Success && !response.Error) {
			notifications.addNotification('Menu reordered', 'success');
			await fetchFloorplans();
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
		}
	};
	return useObserver(() => (
		<div>
			{showFloorPlans === 1 && appStores.showAppLoader === false ? (
				!appStores.showAppLoader && (
					<div className={classes.paper}>
						<Grid container>
							<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
								<Typography variant="h5">Floor Plans</Typography>
							</Grid>
							<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
								<Fab
									color="primary"
									aria-label="add"
									className={classes.floorplanAddIcon}
									onClick={() => {
										routes.navigate(
											{ name: 'floorplanUploader' },
											{
												clearParams: true,
											},
										);
										clearFloorplans();
									}}
								>
									<Icon icon={faPlussolid} />
								</Fab>
							</Grid>
						</Grid>
						{(getFloorplanCount() === 0 && floorplanListOptions && floorplanListOptions.searchText !== '') ||
						(spaces.allFloorplans.floorplans &&
							!spaces.allFloorplans.floorplans.filter((todos: any) => todos.buildingId === user.currentSiteId)
								.length) ? (
							<Grid container>
								<Grid item xs={12} className={classes.noResults}>
									<Typography variant="h6">We couldn't find Floorplan List </Typography>
									<Typography className={classes.textMargin}>
										Please make sure that it was spelled correctly.
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid container>
								<Grid item xs={12}>
									<Table>
										<TableHead>
											<TableRow>
												<td>
													<Card className={classes.headCard}>
														<table>
															<tr>
																<TableCell style={{ width: '8%' }} className={classes.listtableCell}>
																	<div className={classes.listtableLocation}>Location</div>
																</TableCell>
																<TableCell style={{ width: '3%' }} className={classes.listtableCell}>
																	<div className={classes.listtableView}>View</div>
																</TableCell>
																<TableCell style={{ width: '2%' }} className={classes.listtableCell}>
																	<div className={classes.listtableFormat}>Format</div>
																</TableCell>
																<TableCell style={{ width: '1%' }} className={classes.listtableCell}>
																	<div className={classes.listtableActive}>Active</div>
																</TableCell>
																<TableCell style={{ width: '5%' }} className={classes.listtableCell} align="center">
																	<div className={classes.listtableActions}>Actions</div>
																</TableCell>
															</tr>
														</table>
													</Card>
												</td>
											</TableRow>
										</TableHead>
										<DragDropContext onDragEnd={handleDragEnd}>
											<Droppable droppableId="droppable" direction="vertical">
												{(droppableProvided: DroppableProvided) => (
													<Paper className={classes.listPaper}>
														<TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
															{floorPlans &&
																floorPlans.map((floorplan: any, index: number) => (
																	<Draggable key={floorplan.FloorId} draggableId={floorplan.FloorId} index={index}>
																		{(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
																			return (
																				<TableRow
																					ref={draggableProvided.innerRef}
																					{...draggableProvided.draggableProps}
																					style={{
																						...draggableProvided.draggableProps.style,
																						background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
																						position: 'relative',
																					}}
																					key={floorplan.FloorId!}
																					hover={true}
																					className="dataRow"
																				>
																					<td style={{ width: '36%' }}>
																						<Card className={classes.listCard}>
																							<table>
																								<tr>
																									<TableCell
																										style={{ width: '5.6%' }}
																										className={classes.listtableCell}
																									>
																										<div className={classes.cellContentSites}>{floorplan.Location}</div>
																									</TableCell>
																									<TableCell style={{ width: '2%' }} className={classes.listtableCell}>
																										<div className={classes.cellContentView}>{floorplan.FloorName}</div>
																									</TableCell>
																									<TableCell
																										style={{ width: '1.4%' }}
																										className={classes.listtableCell}
																									>
																										<div className={classes.cellContentFormat}>
																											{floorplan.FileFormat}
																										</div>
																									</TableCell>
																									<TableCell style={{ width: '2%' }} className={classes.listtableCell}>
																										<div className={classes.cellContentActive}>
																											<Switch
																												checked={floorplan.Active === true ? true : false}
																												onChange={e => changeFloorPlanStaus(floorplan)}
																												color="primary"
																												inputProps={{ 'aria-label': 'primary checkbox' }}
																											/>
																										</div>
																									</TableCell>
																									<TableCell
																										style={{ width: '1%' }}
																										className={classes.listtableCell}
																										padding="none"
																									>
																										<div className={classes.actionIcons}>
																											<Tooltip
																												title="Drag to reorder"
																												placement="bottom"
																												classes={{
																													tooltip: classes.tooltip,
																												}}
																											>
																												<IconButton
																													aria-label="Edit"
																													color="inherit"
																													size="small"
																													className={classes.actionIconButton1}
																												>
																													<div {...draggableProvided.dragHandleProps}>
																														<Icon icon={faGripLines} />
																													</div>
																												</IconButton>
																											</Tooltip>
																											<Tooltip
																												title="Edit elements"
																												placement="bottom"
																												classes={{
																													tooltip: classes.tooltip,
																												}}
																											>
																												<IconButton
																													aria-label="Edit"
																													color="inherit"
																													disabled={floorplan.Active === false ? true : false}
																													size="small"
																													className={classes.actionIconButton}
																													onClick={() => {
																														if (floorplan.FileFormat) {
																															routes.navigate(
																																{
																																	name: 'floorplanEditor',
																																	params: {
																																		clientId: floorplan.ClientId,
																																		floorId: floorplan.FloorId,
																																	},
																																},
																																{
																																	clearParams: true,
																																},
																															);
																														}
																													}}
																												>
																													<Icon icon={faPenSquaresolid} />
																													<Icon icon={faPenSquare} />
																												</IconButton>
																											</Tooltip>
																											<Tooltip
																												title="Upload new image"
																												placement="bottom"
																												classes={{
																													tooltip: classes.tooltip,
																												}}
																											>
																												<IconButton
																													aria-label="Upload Image"
																													color="inherit"
																													size="small"
																													className={classes.actionIconButtonimg}
																												>
																													<input
																														accept={'image/*'}
																														id={'editfloimageID'}
																														onChange={event =>
																															handleFileUpload(event, floorplan.FloorId)
																														}
																														type="file"
																														className={classes.uploader}
																													/>
																													<label
																														htmlFor="editfloimageID"
																														className={classes.actionIconButtonupload}
																													>
																														<Icon icon={faImagesolid} />
																														<Icon icon={faImage} />
																													</label>
																												</IconButton>
																											</Tooltip>
																											<Tooltip
																												title="Delete floor"
																												placement="bottom"
																												classes={{
																													tooltip: classes.tooltip,
																												}}
																											>
																												<IconButton
																													aria-label="Delete"
																													color="inherit"
																													size="small"
																													onClick={() => {
																														confpopup(floorplan.FloorId, floorplan.FileFormat);
																													}}
																													className={classes.actionIconButton}
																												>
																													<Icon icon={faTrashAltsolid} />
																													<Icon icon={faTrashAlt} />
																												</IconButton>
																											</Tooltip>
																										</div>
																									</TableCell>
																								</tr>
																							</table>
																						</Card>
																					</td>
																				</TableRow>
																			);
																		}}
																	</Draggable>
																))}
															{droppableProvided.placeholder}
														</TableBody>
													</Paper>
												)}
											</Droppable>
										</DragDropContext>
										<TableFooter>
											<TableRow>
												<TablePagination
													className={classes.listtableCell}
													rowsPerPageOptions={rowsPerPageOptions}
													colSpan={7}
													count={getFloorplanCount() > 0 ? getFloorplanCount() : 0}
													rowsPerPage={floorplanListOptions.pSize ? floorplanListOptions.pSize : 5}
													page={floorplanListOptions.pCount ? floorplanListOptions.pCount : 0}
													SelectProps={{
														inputProps: { 'aria-label': 'Rows per page' },
														native: true,
													}}
													onChangePage={handleChangePage}
													onChangeRowsPerPage={handleChangeRowsPerPage}
												/>
											</TableRow>
										</TableFooter>
									</Table>
								</Grid>
							</Grid>
						)}
					</div>
				)
			) : showFloorPlans === 0 && appStores.showAppLoader === false ? (
				<>
					<Avatar alt="access denied" src={accessDeniedIcon} className={classes.accessDimensions} />
					<Typography variant="subtitle2">You do not have permission to access this area</Typography>
					<Typography variant="caption">Please contact your manager to request this access</Typography>
				</>
			) : (
				''
			)}

			<Dialog open={confirmpopup} onClose={handleClose}>
				<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
					<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
						Are you sure you want to delete this floor plan?
					</DialogTitle>
					<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '10px' }}>
						Deleting this will remove the floor plan and all rooms and desks.To update the floor plan while retaining
						the rooms and desks, please add a new floor plan using the 'Add' button and select this floor from the
						dropdown menu.
					</DialogContentText>

					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button style={{ paddingRight: '8px' }} onClick={Delete} color="primary">
							Delete
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</div>
	));
});

export default FloorplanList;
