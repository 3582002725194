import * as React from 'react';
import {
	Grid,
	// Box,
	Typography,
	makeStyles,
	Button,
	Avatar,
	Hidden,
	Link,
	TextField,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
import applconLarge from '../assets/images/AriaLogoText.svg';
import appIconSplash from '../assets/images/Splash.png';
// import appleDownloadIcon from '../assets/images/AppleDownloadIcon.png';
// import androidDownloadIcon from '../assets/images/AndroidDownloadIcon.png';
import { observer } from 'mobx-react-lite';
import { stores } from '../store';

import RegisterForm from './RegisterForm';

const useStyles = makeStyles(theme => ({
	androidDownloadIcon: {
		width: '110.05px',
		height: '36px',
		display: 'inline-block',
		position: 'relative',
		left: '715px',
		top: '-60px',
		overflowY: 'hidden',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			left: '675px',
		},
	},
	appleDownloadIcon: {
		width: '110.05px',
		height: '36px',
		margin: theme.spacing(0, 0, 0, 2),
		display: 'inline-block',
		position: 'relative',
		left: '705px',
		top: '-60px',
		overflowY: 'hidden',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			left: '665px',
		},
	},
	appIconLarge: {
		width: 'auto',
		height: 'auto',
		display: 'inline-block',
		position: 'relative',
		top: '-33px',
	},
	appIconSplash: {
		width: '384px',
		height: '384px',
		display: 'inline-block',
		position: 'relative',
		top: '-93px',
		left: '60px',
		overflowY: 'hidden',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			top: '-94px',
			left: '25px',
		},
	},
	userLoginSection: {
		justifyContent: 'center',
		textAlign: 'center',
		'& > *': {
			margin: theme.spacing(0.5, 0),
		},
		position: 'relative',
		top: '80px',
	},
	privacyNotice: {
		position: 'fixed',
		right: '23px',
		bottom: '9px',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			right: '7px',
			bottom: '-1px',
		},
	},
	loginButton: {
		position: 'relative',
		top: '29px',
		borderRadius: '4px',
		opacity: 1,
		backgroundColor: '#098BBD',
		color: '#FFFFFF',

		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			top: '28px',
		},
	},
	registerButton: {
		position: 'relative',
		top: '14px',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			top: '11px',
		},
	},
	downloadText: {
		fontSize: '14px',
		position: 'relative',
		left: '710px',
		top: '-50px',
		overflowY: 'hidden',
		'@media (min-width: 1340px) and (min-height:650px) and (max-height:700px) and (max-width: 1390px)': {
			position: 'relative',
			left: '670px',
		},
	},
	textSize: {
		fontSize: '14px',
		position: 'relative',
		top: '23px',
		color: '#172A3F',
	},
	textField: {
		width: '300px',

		border: '2px solid 098bbd',
		position: 'relative',
		top: '42px',
		borderRadius: '4px',
		opacity: 1,
		'& .MuiInputLabel-formControl': {
			position: 'absolute',
			top: '-3px',
			left: '10px',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			marginLeft: '-10px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			height: '53px',
		},
		'& .MuiOutlinedInput-input ': {
			backgroundColor: '#F4F8FC',
		},
		'& .MuiOutlinedInput-input:-webkit-autofill': {
			WebkitBoxShadow: '0 0 0 100px #F4F8FC inset ',
		},
	},

	textSize1: {
		fontSize: '14px',
		position: 'relative',
		top: '-2px',
	},
	alignStyle: {
		padding: '0px 0px !important',
	},
}));

const UserLogin = observer(() => {
	const user = React.useContext(stores.context.user);
	const appstores = React.useContext(stores.context.appStores);
	appstores.captchaerr = false;
	const classes = useStyles();
	const [btnStatus, setBtnStatus] = React.useState(true);
	const [email, setEmail] = React.useState('');
	const [openPopup, setOpenPopup] = React.useState(false);
	const applconLargeImg = applconLarge;
	const appIconSplashImg = appIconSplash;
	// const appleDownloadIconImg = appleDownloadIcon;
	// const androidDownloadIconImg = androidDownloadIcon;

	//const routes = React.useContext(stores.context.routes);
	const { routeName } = React.useContext(stores.context.routes);

	const validEmail = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

	const handleChange = (event: React.ChangeEvent<any>) => {
		let userInput: any = event.target.value;

		let flag: any = userInput.match(validEmail) && userInput.split('@')[1].includes('.') ? false : true;
		setBtnStatus(flag);
		setEmail(userInput);
	};
	const check = () => {
		user.userCheck(email).then((resp: any) => {
			if (resp && resp.isActive === true) user.login();
			else setOpenPopup(true);
		});
	};
	const clickHandler = () => {
		user.userExisted(email).then((res: any) => {
			if (res && res.UserExisted === true) check();
			//else routes.navigate({ name: 'register' });
			else setOpenPopup(true);
		});
	};
	// const sendInvite = () => {
	// 	user.sendInvitation(email).then((res: any) => setOpenPopup(false));
	// };

	return (
		<Grid container direction="row" justify="space-evenly" alignItems="center" spacing={1}>
			<Grid item xs={12} sm={12} md={6}>
				{routeName === 'register' ? (
					<RegisterForm />
				) : (
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={1}
						className={classes.userLoginSection}
					>
						<Grid item xs={12}>
							<Avatar variant="rounded" src={applconLargeImg} className={classes.appIconLarge}></Avatar>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" color="primary" className={classes.textSize1} style={{ fontWeight: 'bold' }}>
								Connecting you with your workplace
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.textSize}>
								You must be registered to use Aria.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.textSize}>
								Please enter your organisation email address below
							</Typography>
							<Typography variant="body2" className={classes.textSize} style={{ textAlign: 'center' }}>
								to start connecting with your workplace
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="outlined-multiline-flexible"
								label="Organisation email address"
								onChange={handleChange}
								type="text"
								name="email"
								autoComplete="email"
								variant="outlined"
								className={classes.textField}
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								disabled={btnStatus || email === ''}
								onClick={clickHandler}
								className={classes.loginButton}
							>
								Continue
							</Button>
						</Grid>
						{/*
						<Grid item xs={12}>
							<Typography variant="body2" className={classes.downloadText}>
								Download the Aria app for your mobile device
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box>
								<Avatar variant="rounded" src={androidDownloadIconImg} className={classes.androidDownloadIcon}></Avatar>
								<Avatar variant="rounded" src={appleDownloadIconImg} className={classes.appleDownloadIcon}></Avatar>
							</Box>
						</Grid>
				*/}
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} sm={12} md={6} className={classes.userLoginSection}>
				<Hidden smDown>
					<Avatar variant="rounded" src={appIconSplashImg} className={classes.appIconSplash}></Avatar>
				</Hidden>
			</Grid>
			<Grid>
				<Link
					href={'https://www.mitie.com/legal/privacy/'}
					target="_blank"
					rel="noopener"
					color="primary"
					style={{ textDecoration: 'none' }}
				>
					<Button color="primary" className={classes.privacyNotice}>
						Privacy notice
					</Button>
				</Link>
			</Grid>

			{/*	<Dialog open={openPopup}>
				<DialogContent
					className={classes.alignStyle}
					style={{ maxWidth: '283px', maxHeight: '245px', overflow: 'hidden' }}
				>
					<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
						Verification required
					</DialogTitle>
					<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
						Please Resend link and then check your mail for a link to verify your account or request another link. You
						will then be able to login to Aria.
					</DialogContentText>
					<DialogActions>
						<Button onClick={() => setOpenPopup(false)} color="primary">
							Cancel
						</Button>
						<Button style={{ paddingRight: '8px' }} onClick={sendInvite} color="primary">
							Resend link
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>*/}
			<Dialog open={openPopup}>
				<DialogContent
					className={classes.alignStyle}
					style={{ maxWidth: '283px', maxHeight: '245px', overflow: 'hidden' }}
				>
					<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
						Aria login
					</DialogTitle>
					<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
						User not registered with Aria. Please contact your administrator.{' '}
					</DialogContentText>
					<DialogActions>
						<Button onClick={() => setOpenPopup(false)} color="primary">
							Close
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</Grid>
	);
});

export default UserLogin;
