import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import { stores } from '../store';
import { ISiteObject } from '../types';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';

const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
		marginBottom: '0px',
	},
	avatarStyle: {
		backgroundColor: '#66CCFA',
		color: '#FFFFFF',
		left: '12px',
		top: '21px',
		width: '36px',
		height: '36px',
	},
	container: {
		margin: 'auto',
		padding: theme.spacing(0),
		display: 'flex',
		flexDirection: 'row',
		maxWidth: '100% !important',
		background: '#FFFFFF 0% 0% no-repeat padding-box !important',
		'@media (min-width: 1280px)': {
			'& .MuiContainer-maxWidthLg': {
				width: '100% !important',
				background: '#FFFFFF 0% 0% no-repeat padding-box !important',
			},
		},
		'@media (min-width: 1920px)': {
			'& .MuiContainer-maxWidthLg': {
				width: '100% !important',
				background: '#FFFFFF 0% 0% no-repeat padding-box !important',
			},
		},
	},
	askBackground: {},
	chatView1: {
		marginLeft: '10px',
		marginTop: '21px',
		width: '302px',
		backgroundColor: '#FFFFFF',
		height: '42px',
		borderRadius: '0px 16px 16px 16px',
	},
	textView1: {
		marginTop: '8px',
		marginLeft: '10px',
		width: '283px',
		font: 'normal normal normal 16px/22px Roboto',
		color: '#002855',
		height: '22px',
	},
	chatView2: {
		marginLeft: '10px',
		marginTop: '8px',
		backgroundColor: '#FFFFFF',
		borderRadius: '0px 16px 16px 16px',
		'@media (min-width: 1920px)': {
			height: '42px',
			width: '1021px',
		},
		'@media (min-width: 250px) and (max-width: 1000px)': {
			width: '85%',
		},
		'@media (min-width: 1001px) and (max-width: 1919px)': {
			width: '70%',
		},
	},
	textView2: {
		marginTop: '8px',
		marginLeft: '10px',
		font: 'normal normal normal 16px/22px Roboto',
		color: '#002855',

		'@media (min-width: 1920px)': {
			width: '997px',
			height: '30px',
		},
		'@media (min-width: 250px) and (max-width: 1000px)': {
			width: '85%',
		},
		'@media (min-width: 1001px) and (max-width: 1919px)': {
			width: '95%',
			paddingBottom: '6px',
		},
	},
	chatButton: {
		height: '42px',
		width: '151px',
		border: '2px solid #66CCFA',
		padding: '0px',
		backgroundColor: '#FFFFFF',
		borderRadius: '0px 16px 16px 16px',
		color: '#002855',
		font: 'normal normal normal 16px/12px Roboto',
		'&:hover': {
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 3px 6px #00000029',
		},
		'& .MuiButton-label': {
			width: '134px',
			height: '23px',
		},
	},
	chatView3: {
		marginLeft: '10px',
		marginTop: '8px',
	},
	feedbackButton: {
		height: '42px',
		width: '119px',
		padding: '0px',
		font: 'normal normal normal 16px/12px Roboto',
		border: '2px solid #66CCFA',
		color: '#002855',
		backgroundColor: '#FFFFFF',
		borderRadius: '0px 16px 16px 16px',
		'& .MuiButton-label': {
			width: '102px',
			height: '23px',
		},
		'&:hover': {
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 3px 6px #00000029',
		},
	},
	iconStyle: {
		display: 'none',
		'& .MuiSvgIcon-root': {
			width: '38.41px !important',
			height: '38.41px !important',
			marginLeft: '48px',
			marginTop: '11px',
		},
		'& .MuiSvgIcon-colorDisabled': {
			color: '#CFDBE5 !important',
		},
		'@media (min-width: 1920px)': {
			width: '1593px',
		},
		'@media (min-width: 250px) and (max-width: 1000px)': {
			width: '85%',
		},
		'@media (min-width: 1001px) and (max-width: 1919px)': {
			width: '92%',
		},
	},
	sentitemsButton: {
		height: '42px',
		width: '95px',
		padding: '0px',
		border: '2px solid #66CCFA',
		backgroundColor: '#FFFFFF',
		font: 'normal normal normal 16px/12px Roboto',
		borderRadius: '0px 16px 16px 16px',
		color: '#002855',
		'& .MuiButton-label': {
			width: '78px',
			height: '23px',
		},
		'&:hover': {
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 3px 6px #00000029',
		},
	},
	Paper: {
		backgroundColor: '#F4F8FC',
		height: '742px',
		borderRadius: '16px',
		marginTop: '30px',
		marginLeft: '30px',
		boxShadow: 'none',
		'@media (min-width: 1920px)': {
			width: '1568px',
		},
		'@media (min-width: 250px) and (max-width: 1000px)': {
			width: '88%',
		},
		'@media (min-width: 1001px) and (max-width: 1919px)': {
			width: '93%',
		},
	},
	textField: {
		height: '46px',
		marginLeft: '24px',
		marginTop: '7px',
		background: '#DBE4EB 0% 0% no-repeat padding-box',
		border: '1px solid #DBE4EB',
		borderRadius: '8px',
		'& .MuiTypography-subtitle1': {
			height: '19px',
			width: '188px',
			font: 'normal normal normal 15.9px/12px Roboto',
			marginLeft: '18px',
			lineHeight: 'unset',
			color: '#8B8DAE',
			opacity: 0.55,
			marginTop: '11px',
		},
		'@media (min-width: 1920px)': {
			width: '1295px',
		},
		'@media (min-width: 1001px) and (max-width: 1919px)': {
			width: '98%',
		},
		'@media (min-width: 250px) and (max-width: 1000px)': {
			width: '94%',
		},
	},
	textField1: {
		'& .MuiTypography-subtitle1': {
			height: '19px',
			width: '102px',
			font: 'normal normal normal 16px/12px Roboto',
			marginLeft: '5px',
			lineHeight: 'unset',
			marginTop: '21px',
			color: '#CFDBE5',
		},
	},
	iconStyle2: {
		width: '46px',
		marginTop: '7px',
		marginLeft: '10px',
		height: '46px',
		background: '#CFDBE5 0% 0% no-repeat padding-box',
		borderRadius: '4px',
	},
	textView: {
		'& .MuiTypography-subtitle1': {
			font: 'normal normal normal 18px/22px Roboto',
			color: '#002855',
			width: '30px',
			marginLeft: '36px',
			marginBottom: '-14px',
			marginTop: '15px',
		},
	},
	displayButtons: {
		display: 'none',
	},
}));

const AskLanding = observer(() => {
	const user = React.useContext(stores.context.user);
	const sites: any = React.useContext(stores.context.site);
	const [esmeConfig, setEsmeConfig] = React.useState(false);
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	appStores.showAppLoader = sites.siteList ? false : true;
	React.useEffect(() => {
		let sitedatanew: any = localStorage.getItem('sitedatalat');
		let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
		const currentSiteIndex = df.findIndex((m: any) => m.Id == user.currentSiteId);
		if (user.currentSiteId != '' && df[currentSiteIndex]) {
			if (df[currentSiteIndex].EsmeClientId == null || df[currentSiteIndex].EsmeClientId == '') {
				setEsmeConfig(false);
			} else {
				setEsmeConfig(true);
			}
		}
	}, [esmeConfig, sites.siteList, user.currentSiteId]);
	const classes = useStyles();
	return (
		<div className={classes.frameContainer}>
			<Box className={classes.textView}>
				<Typography variant="subtitle1">Ask</Typography>
			</Box>

			<Grid container className={classes.Paper} xs={12} sm={12}>
				<Box
					display="flex"
					flexDirection="column"
					style={{ alignItems: 'flex-start', textAlign: 'left', width: '100%' }}
				>
					<Box display="flex" flexDirection="row">
						<Box className={classes.chatView1}>
							<Typography className={classes.textView1} variant="subtitle1">
								Hello! I am Esme, your virtual Assistant.
							</Typography>
						</Box>
					</Box>
					<Box className={classes.chatView2}>
						<Typography className={classes.textView2} variant="subtitle1">
							I'm here to help you with any jobs you may need to request or updates on existing jobs, please select one
							of the options below to get started.
						</Typography>
					</Box>
					<Box
						className={
							esmeConfig && user.featurePermissions && user.featurePermissions.JOB_REQUEST_CHAT
								? classes.chatView3
								: classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'chatbot' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.chatButton}
							disableElevation
						>
							Job Request Chat
						</Button>
					</Box>
					<Box
						className={
							user.CafmId === -1 && user.featurePermissions && user.featurePermissions.JOB_REQUEST_FORM
								? classes.chatView3
								: classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'jobRequestForm' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.chatButton}
							disableElevation
						>
							Job Request Form
						</Button>
					</Box>
					<Box
						className={
							user.featurePermissions && user.featurePermissions.JOB_REQUEST_FORM
								? classes.chatView3
								: classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'dynamicform' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.chatButton}
							disableElevation
						>
							Job Request Form
						</Button>
					</Box>
					<Box
						className={
							user.featurePermissions && user.featurePermissions.ARIA_FEEDBACK
								? classes.chatView3
								: classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'feedback' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.feedbackButton}
							disableElevation
						>
							Aria Feedback
						</Button>
					</Box>
					<Box
						className={
							esmeConfig && user.featurePermissions && user.featurePermissions.VIEW_MY_JOBS
								? classes.chatView3
								: classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'viewMyJobs' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.feedbackButton}
							disableElevation
						>
							View My Jobs
						</Button>
					</Box>
					<Box
						className={
							user.featurePermissions && user.featurePermissions.SENT_ITEMS ? classes.chatView3 : classes.displayButtons
						}
					>
						<Button
							onClick={() => {
								routes.navigate(
									{ name: 'sentItems' },
									{
										clearParams: true,
									},
								);
							}}
							variant="contained"
							className={classes.sentitemsButton}
							disableElevation
						>
							Sent Items
						</Button>
					</Box>
					<Box className={classes.chatView3}>
						<Button
							href={'https://help.aria.mitie.com/686e313c2/p/75e69f-ask/b/329cb7'}
							target={'_blank'}
							variant="contained"
							className={classes.sentitemsButton}
							disableElevation
						>
							Help
						</Button>
					</Box>
				</Box>
			</Grid>
			<Box display="flex" flexDirection="row" className={classes.iconStyle}>
				<ControlPointOutlinedIcon color="disabled"></ControlPointOutlinedIcon>
				<Box className={classes.textField1}>
					<Typography variant="subtitle1">Attach Photos</Typography>
				</Box>
				<Box className={classes.textField}>
					<Typography variant="subtitle1">Write your message here...</Typography>
				</Box>
				<Box className={classes.iconStyle2}>
					<Icon icon={faPaperPlane} size="32px" color={'#FFFFFF'} margin="7px" />
				</Box>
			</Box>
		</div>
	);
});

export default AskLanding;
