import * as React from 'react';
import { ListItem, List, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import { faEdit, faMugHot, faUtensils } from '@fortawesome/pro-light-svg-icons';
import {
	faEdit as faEditSolid,
	faMugHot as faMugHotSolid,
	faUtensils as faUtensilsSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { stores } from '../../store';
import { IHeaderNavProps } from '../../types';
import { observer } from 'mobx-react-lite';
import sidebarUseStyles from '../../assets/css/SideBarCss';
const useStyles = makeStyles(theme => ({
	listChildItemSelected: {
		backgroundColor: '#CFDBE5 !important',
	},
	PermissionsView: {
		display: 'none',
	},
}));
const HeaderNav = observer((props: IHeaderNavProps) => {
	const routes = React.useContext(stores.context.routes);
	const classes = props.classNames;
	const [selectedItem, setSelectedItem] = React.useState('');
	const classesnew = useStyles();
	const classesSideBar = sidebarUseStyles();
	const { routeName } = routes;
	const appStores = React.useContext(stores.context.appStores);
	const [showRefuelList, setShowRefuelList] = React.useState(0);
	const [openCont, setOpenCont] = React.useState(false);
	const [contentClicked, setContentClicked] = React.useState(false);
	const user = React.useContext(stores.context.user);
	const contentManagementList: string[] = ['editMenus', 'refuelList', 'copyMenus', 'addMenus'];
	const contentManagementListSelected = !!routeName && !!contentManagementList.includes(routeName);
	const [open, setOpen] = React.useState(routeName === 'refuel' || contentManagementListSelected);
	const handleClick = () => {
		setOpen(true);
		setOpenCont(true);
	};
	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const refuelIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '35FA69F9-7A9E-41FC-AFB3-A8BEFA4E4637',
			);

			if (user.permissions.Modules[refuelIndex] && user.permissions.Modules[refuelIndex].Read) {
				if (
					user.permissions.Modules[refuelIndex].Create &&
					user.permissions.Modules[refuelIndex].Update &&
					user.permissions.Modules[refuelIndex].Delete
				) {
					setShowRefuelList(1);
				}
			} else {
				setShowRefuelList(0);
			}
		} else {
			setShowRefuelList(0);
		}
	}, [user.permissions]);

	const navigateToPage = (route: string) => {
		routes.navigate(
			{ name: route },
			{
				clearParams: true,
			},
		);
	};
	const handleClickContn = () => {
		setOpenCont(!openCont);
		setOpen(true);
		setContentClicked(true);
	};
	React.useEffect(() => {
		if (contentClicked && !openCont) navigateToPage('refuel');
	});
	return (
		<>
			<List
				disablePadding={true}
				className={
					user.modulePermissions && user.modulePermissions.REFUEL ? classes.sideBarList : classesnew.PermissionsView
				}
			>
				<ListItem
					disableTouchRipple={true}
					button
					onClick={() => {
						handleClick();
						navigateToPage('refuel');
						appStores.drawerOpen = false;
					}}
					selected={routeName === 'refuel' || contentManagementListSelected}
				>
					<ListItemIcon className={classes.listIcon}>
						<Icon icon={routeName === 'refuel' || contentManagementListSelected ? faMugHotSolid : faMugHot} />
					</ListItemIcon>
					<ListItemText primary="Refuel" />
				</ListItem>
			</List>
			{showRefuelList === 1 ? (
				<>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List disablePadding={true} className={classesSideBar.contentMenuFeed}>
							<ListItem
								selected={contentManagementListSelected || openCont}
								disableTouchRipple={true}
								button
								onClick={() => {
									handleClickContn();
									setSelectedItem('contentManagement');
									appStores.drawerOpen = false;
								}}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'refuelList' || openCont ? faEditSolid : faEdit} />
								</ListItemIcon>
								<ListItemText primary="Content Management" />
							</ListItem>
							<Collapse
								in={openCont}
								timeout="auto"
								unmountOnExit
								className={contentManagementListSelected ? classes.listCollapseSelected : classes.listCollapse}
							>
								<List component="div" disablePadding className={classesSideBar.contentMenuFeed}>
									<ListItem
										button
										disableTouchRipple={true}
										selected={routeName === 'refuelList' || routeName === 'addMenus'}
									>
										<ListItemIcon className={classes.listIcon}>
											<Icon
												icon={routeName === 'refuelList' || routeName === 'addMenus' ? faUtensilsSolid : faUtensils}
											/>
										</ListItemIcon>
										<ListItemText
											primary="Edit Menus "
											onClick={() => {
												navigateToPage('refuelList');
												setSelectedItem('refuelList');
												if (contentManagementListSelected) setOpenCont(false);
												appStores.drawerOpen = false;
												if (routeName === selectedItem) navigateToPage('refuel');
											}}
											style={{ paddingLeft: '0px' }}
										/>
									</ListItem>
								</List>
							</Collapse>
						</List>
					</Collapse>
				</>
			) : (
				''
			)}
		</>
	);
});

export default HeaderNav;
