import * as React from 'react';
import {
	ListItem,
	List,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Badge,
	Typography,
	Dialog,
	DialogTitle,
	Button,
	DialogContentText,
	DialogActions,
	DialogContent,
	Collapse,
} from '@material-ui/core';
import { Icon } from '@mitie/material-ui-extensions';
import {
	faCommentsAlt,
	faQuoteLeft,
	faCommentAltLines,
	faBallot,
	faWrench,
	faInboxOut,
} from '@fortawesome/pro-light-svg-icons';
import {
	faCommentsAlt as faCommentsAltSolid,
	faQuoteLeft as faQuoteLeftSolid,
	faBallot as faBallotSolid,
	faCommentAltLines as faCommentAltLinesSolid,
	faWrench as faWrenchSolid,
	faInboxOut as faInboxOutSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { stores } from '../../store';
import { IHeaderNavProps, ISiteObject } from '../../types';
import { observer } from 'mobx-react-lite';
import sidebarUseStyles from '../../assets/css/SideBarCss';

const useStyles = makeStyles(theme => ({
	askBadge: {
		'& span': {
			backgroundColor: theme.palette.warning.main,
			width: '15px',
			height: '15px',
			minWidth: '15px',
			padding: '0 3px',
			fontSize: '0.7rem',
			right: 'auto',
		},
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	listChildItemSelected: {
		backgroundColor: '#bdbdbd',
	},
	PermissionsView: {
		display: 'none',
	},
}));

const HeaderNav = observer((props: IHeaderNavProps) => {
	const routes = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);
	const { routeName } = routes;
	const classesnew = useStyles();
	const classesSideBar = sidebarUseStyles();
	const sites: any = React.useContext(stores.context.site);
	const [selectedItem, setSelectedItem] = React.useState('');
	const appStores = React.useContext(stores.context.appStores);
	const [esmeConfig, setEsmeConfig] = React.useState(false);
	const [currentAskItems, setCurrentAskItems] = React.useState('0');
	const [open, setOpen] = React.useState(
		routeName === 'ask' ||
			routeName === 'chatbot' ||
			routeName === 'jobRequestForm' ||
			routeName === 'dynamicform' ||
			routeName === 'viewMyJobs' ||
			routeName === 'sentItems' ||
			routeName === 'feedback',
	);

	React.useEffect(() => {
		if (selectedItem === 'sentItems') navigateToPage('ask');
		setCurrentAskItems(user.SRsCount);
	}, [user.SRsCount, selectedItem]);
	const navigateToPage = (route: string) => {
		routes.navigate(
			{ name: route },
			{
				clearParams: true,
			},
		);
	};

	React.useEffect(() => {
		let sitedatanew: any = localStorage.getItem('sitedatalat');
		let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
		const currentSiteIndex = df.findIndex((m: any) => m.Id == user.currentSiteId);
		if (user.currentSiteId != '' && df[currentSiteIndex]) {
			if (df[currentSiteIndex].EsmeClientId == null || df[currentSiteIndex].EsmeClientId == '') {
				setEsmeConfig(false);
			} else {
				setEsmeConfig(true);
			}
		}
	}, [esmeConfig, sites.siteList, user.currentSiteId]);
	const [rotName, setRoutName] = React.useState('');
	const classes = props.classNames;

	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);

	const dialogfn = (val: any) => {
		setRoutName(val);
		if (routes.testval === true && window.location.pathname === '/ask/chatbot') {
			setEsmeDialogOpen(true);
		} else if (routes.viewmyjobval === true && window.location.pathname === '/ask/view-my-jobs') {
			setEsmeDialogOpen(true);
		} else if (val === selectedItem) {
			navigateToPage('ask');
		} else {
			routes.navigate(
				{ name: val },
				{
					clearParams: true,
				},
			);
		}
	};

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
	};

	const handleCloseChat = () => {
		routes.testval = false;
		routes.viewmyjobval = false;
		setEsmeDialogOpen(false);

		routes.navigate(
			{ name: rotName },
			{
				clearParams: true,
			},
		);
	};

	return (
		<>
			<div>
				<List
					disablePadding={false}
					className={
						user.modulePermissions && user.modulePermissions.ASK ? classes.sideBarList : classesnew.PermissionsView
					}
				>
					<ListItem
						button
						onClick={() => {
							dialogfn('ask');
							appStores.drawerOpen = false;
							setOpen(true);
						}}
						disableTouchRipple={true}
						selected={
							routeName === 'ask' ||
							routeName === 'chatbot' ||
							routeName === 'jobRequestForm' ||
							routeName === 'dynamicform' ||
							routeName === 'viewMyJobs' ||
							routeName === 'sentItems' ||
							routeName === 'feedback'
						}
					>
						<ListItemIcon className={classes.listIcon}>
							<Icon
								icon={
									routeName === 'ask' ||
									routeName === 'chatbot' ||
									routeName === 'jobRequestForm' ||
									routeName === 'dynamicform' ||
									routeName === 'viewMyJobs' ||
									routeName === 'sentItems' ||
									routeName === 'feedback'
										? faCommentsAltSolid
										: faCommentsAlt
								}
							/>
						</ListItemIcon>
						<ListItemText primary="Ask" />
					</ListItem>
				</List>
				<Collapse
					in={open}
					timeout="auto"
					unmountOnExit
					className={open ? classes.listCollapseSelected : classes.listCollapse}
				>
					<List disablePadding={false} className={classesSideBar.contentMenuFeed}>
						{esmeConfig && user.featurePermissions && user.featurePermissions.JOB_REQUEST_CHAT ? (
							<ListItem
								disableTouchRipple={true}
								button
								onClick={() => {
									if (selectedItem === 'chatbot') {
										setSelectedItem('');
									} else {
										setSelectedItem('chatbot');
									}
									dialogfn('chatbot');
									appStores.drawerOpen = false;
								}}
								selected={routeName === 'chatbot'}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'chatbot' ? faCommentAltLinesSolid : faCommentAltLines} />
								</ListItemIcon>
								<ListItemText primary="Job Request Chat" />
							</ListItem>
						) : (
							''
						)}

						{user.CafmId === -1 && user.featurePermissions && user.featurePermissions.JOB_REQUEST_FORM ? (
							<ListItem
								disableTouchRipple={true}
								button
								onClick={() => {
									if (selectedItem === 'jobRequestForm') {
										setSelectedItem('');
									} else {
										setSelectedItem('jobRequestForm');
									}
									dialogfn('jobRequestForm');
									appStores.drawerOpen = false;
								}}
								selected={routeName === 'jobRequestForm'}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'jobRequestForm' ? faBallotSolid : faBallot} />
								</ListItemIcon>
								<ListItemText primary="Job Request Form" />
							</ListItem>
						) : (
							''
						)}
						{user.featurePermissions && user.featurePermissions.JOB_REQUEST_FORM ? (
							<ListItem
								disableTouchRipple={true}
								button
								selected={routeName === 'dynamicform'}
								onClick={() => {
									dialogfn('dynamicform');
									if (selectedItem === 'dynamicform') {
										setSelectedItem('');
									} else {
										setSelectedItem('dynamicform');
									}

									appStores.drawerOpen = false;
								}}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'dynamicform' ? faBallotSolid : faBallot} />
								</ListItemIcon>
								<ListItemText primary="Job Request Form" />
							</ListItem>
						) : (
							''
						)}
						{user.featurePermissions && user.featurePermissions.ARIA_FEEDBACK ? (
							<ListItem
								disableTouchRipple={true}
								button
								selected={routeName === 'feedback'}
								onClick={() => {
									if (selectedItem === 'feedback') {
										setSelectedItem('');
									} else {
										setSelectedItem('feedback');
									}

									dialogfn('feedback');
									appStores.drawerOpen = false;
								}}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'feedback' ? faQuoteLeftSolid : faQuoteLeft} />
								</ListItemIcon>
								<ListItemText primary="Aria Feedback" />
							</ListItem>
						) : (
							''
						)}
						{esmeConfig && user.featurePermissions && user.featurePermissions.VIEW_MY_JOBS ? (
							<ListItem
								disableTouchRipple={true}
								button
								onClick={() => {
									dialogfn('viewMyJobs');
									if (selectedItem === 'viewMyJobs') {
										setSelectedItem('');
									} else {
										setSelectedItem('viewMyJobs');
									}
									appStores.drawerOpen = false;
								}}
								selected={routeName === 'viewMyJobs'}
							>
								<ListItemIcon className={classes.listIcon}>
									<Icon icon={routeName === 'viewMyJobs' ? faWrenchSolid : faWrench} />
								</ListItemIcon>
								<ListItemText primary="View My Jobs" />
							</ListItem>
						) : (
							''
						)}
						{user.featurePermissions && user.featurePermissions.SENT_ITEMS ? (
							<ListItem
								onClick={() => {
									dialogfn('sentItems');
									setSelectedItem('sentItems');
									appStores.drawerOpen = false;
								}}
								disableTouchRipple={true}
								button
								selected={routeName === 'sentItems'}
							>
								<ListItemIcon className={classes.listIcon}>
									<React.Fragment>
										{currentAskItems !== '0' ? (
											<Badge
												badgeContent={currentAskItems || '0'}
												color="primary"
												className={classesnew.askBadge}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
											>
												<Typography />
											</Badge>
										) : (
											''
										)}
										<Icon icon={routeName === 'sentItems' ? faInboxOutSolid : faInboxOut} />
									</React.Fragment>
								</ListItemIcon>
								<ListItemText primary="Sent Items" />
							</ListItem>
						) : (
							''
						)}
					</List>
				</Collapse>
			</div>

			<Dialog style={{ margin: 'auto', maxWidth: '375px' }} open={esmeDialogOpen} onClose={handleClickClose}>
				<DialogContent>
					<DialogTitle id="form-dialog-title" className={classesnew.alignStyle}>
						Are you sure you want to leave the chat
					</DialogTitle>
					<DialogContentText>
						Please take a note of the Service Request reference if given, this chat will end and be deleted.
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleCloseChat} color="primary">
							Close chat
						</Button>
						<Button onClick={handleClickClose} color="primary">
							Return to chat
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default HeaderNav;
