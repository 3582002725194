import * as React from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { Icon } from '@mitie/material-ui-extensions';
import {
	makeStyles,
	Box,
	Typography,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	Grid,
	DialogContentText,
	DialogActions,
	DialogContent,
	Backdrop,
	CircularProgress,
	Paper,
} from '@material-ui/core';

import { stores } from '../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import Button from '@material-ui/core/Button';
import noResults from '../assets/images/noResultsNew.svg';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
	booklist: any;
}

const useStyles = makeStyles(theme => ({
	disabledButton: {
		height: '36px',
		background: '#66CCFA',
		color: '#ffffff',
		boxShadow: 'none',
		'&:hover': {
			background: '#098BBD',
			boxShadow: 'none',
		},
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},
	disabledButtonIgnoreChanges: {
		'&.MuiButton-root.Mui-disabled': {
			color: '#CFDBE5 !important',
			border: '1px solid #CFDBE5 !important',
		},
	},
	disabledButtonSaveChanges: {
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},

	closedCard: (props: IProps) => ({
		marginLeft: '2.5%',
		marginBottom: theme.spacing(2),
		cursor: 'pointer',
		borderRadius: '8px',
		width: '460px',
		boxShadow: '0px 3px 6px #00000029',
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	}),
	CheckInCard: (props: IProps) => ({
		marginLeft: '2.5%',
		marginBottom: theme.spacing(2),
		borderTop: '35px solid #8B8DAE',
		cursor: 'pointer',
		borderRadius: '8px',
		width: '460px',
		boxShadow: '0px 3px 6px #00000029',
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	}),

	openCard: (props: IProps) => ({
		minWidth: '350px',
		marginLeft: '2.5%',
		marginBottom: theme.spacing(2),
	}),
	closedCardDetails: {
		color: '#72879E',
	},
	feedType: {
		position: 'absolute',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
		},
	},
	feedBox: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	bgclrr: {
		backgroundColor: '#EBF6FA',
		borderLeft: '4px solid #098BBD',
	},
	feedItem: {
		flexBasis: '50%',
	},
	main: {
		flexGrow: 1,
	},
	accessDimensionsphoto: {
		width: '50%',
		height: '40%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	accessDimensions: {
		width: '80%',
		height: '65%',

		display: 'block',
		margin: 'auto',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	Dimensions: {
		display: 'block',
		objectFit: 'contain',
		textAlign: 'center',
		position: 'absolute',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
	},
	appBarSpacer: {
		height: '64px',
		backgroundColor: '#f4f8fc',
	},
	content: {
		flexGrow: 1,
		width: '100%',
		overflowX: 'hidden',
		backgroundColor: '#ffffff',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		display: 'flex',
		backgroundColor: 'white',
		overflow: 'hidden',
	},
	paper: {
		backgroundColor: '#f4f8fc',
		width: '105%',
		height: '100%',
		borderRadius: '8px',
		boxShadow: 'none !important',
	},
	viewBookingIcon: {
		width: '50%',

		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	attendeesText: {
		width: '250px',
		marginTop: '-1%',
		marginLeft: '16px',
		'& .MuiFilledInput-root': { backgroundColor: 'white' },
		'& .MuiFilledInput-input': { borderRadius: '6px' },
		'& .MuiFilledInput-input:focus': { background: '#E4ECF3' },
	},

	badge: {
		'& span': {
			backgroundColor: theme.palette.warning.main,
			transform: 'scale(1) translate(0%, -50%)',
			padding: 'inherit',
			fontSize: '0.7rem',
		},
	},
	badgeExpired: {
		'& span': {
			backgroundColor: theme.palette.grey[100],
			padding: 'inherit',
			fontSize: '0.7rem',
		},
	},
	alignStyle: {
		padding: '0px 0px !important',
	},
	lsttext: {
		'& .MuiListItemText-root': {
			marginTop: '-10px',
			marginBottom: '1px',
		},
		'&.MuiListItem-gutters': {
			marginTop: '-10px',
		},
	},
	imageBlock: {
		width: '100%',
		height: '209px',
		borderRadius: '0px 0px 3px 3px',
	},
	headerMenuLinkDefault: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',

		width: '100%',
		padding: '9px',
	},

	actionIconButton: {
		marginTop: '-0.7%',
		width: 50,
		height: 45,
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '2.5rem;',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
		'& .svg-inline--fa': {
			height: '2.5em !important',
		},
		'&.MuiSvgIcon-root': {
			fontSize: '2.5rem !important',
		},
	},
	headerMenuLinkActive: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		fontWeight: 'bold',

		width: '100%',
		padding: '9px',

		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
		'&:hover': {
			height: '100%',

			width: '100%',
		},
	},

	headerNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			width: '100px !important',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	tagItem: {
		backgroundColor: '#098BBD14',

		fontSize: '14px',
		borderRadius: '30px',
		height: '30px',
		padding: '4px 4px 3px 1rem',
		display: 'flex',
		alignItems: 'center',

		width: 'max-content',
		margin: '13px 0.3rem 0.3rem 13px',
	},
	rowIcon: {
		width: '25px',
		height: '25px',
		textAlign: 'center',
		padding: '5px',
		marginRight: '10px',
	},
	Card: {
		borderRadius: '8px',
		width: '460px',
		height: '280px',
		boxShadow: '0px 3px 6px #00000029',
		marginLeft: '12px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2),
		},
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	},
	imgContainer: {
		width: '100%',
		paddingTop: '0px',
		[theme.breakpoints.down('xs')]: {
			paddingTop: '33px',
		},
	},
	imgTag: {
		width: '100%',
	},
	feedContainer: {
		marginTop: '10px',
		height: 'calc(100vh - 64px)',
		overflow: 'scroll',
	},
	feedHeader: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: theme.spacing(2),
		width: '50%',
		maxWidth: '400px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'@media (min-width: 0px) and (max-width: 500px)': {
			marginTop: '40px',
		},
	},
	noFeedImage: {
		margin: theme.spacing(2),
	},
	textMargin: {
		textAlign: 'center',
	},
	subtitle2: {
		fontSize: '16px',
		color: '#002855',
	},
	pclass: {
		margin: '1px 0px',
		color: '#8b8dae',
	},
	cardDesignInner: {
		paddingBottom: '4px !important',
		paddingTop: '4px !important',
	},
	headerboxShadoColor: {
		background: '#cfdbe5',
		height: '30px',
		borderRadius: '5px 5px 0 0',
		position: 'relative',
	},
	statusCheckIcon: {
		position: 'absolute',
		right: '9px',
		top: '8px',
	},
	viewButton: {
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		height: '36px',
		'&:hover': {
			background: '#66CCFA',
			border: '1px solid #66CCFA',
			color: '#ffffff',
		},
	},
	cancelButton: {
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		height: '36px',
		marginLeft: '5px',
		'&:hover': {
			background: '#66CCFA',
			border: '1px solid #66CCFA',
			color: '#ffffff',
		},
	},
	actionButton: {
		textAlign: 'right',
		'& .Mui-disabled': {
			border: '1px solid #cfdbe5',
		},
	},
}));

const BookingCard = observer(() => {
	const classes = useStyles();

	const user = React.useContext(stores.context.user);

	const spaces = React.useContext(stores.context.spaces);
	const [bookData, setBookData] = React.useState<any>('');

	const [bookDataNew, setBookDataNew] = React.useState<any>('');
	const routes = React.useContext(stores.context.routes);
	const [cancelType, setcancelType] = React.useState<any>(0);
	const [selectedItem, setselectedItem] = React.useState<any>({});

	React.useEffect(() => {
		if (user.currentSiteId) {
			setBookingLoader(true);
			spaces.fetchCondecoDetails(user.currentSiteId);
			setBookingLoader(false);
		}
	}, [user.currentSiteId, user.permissions]);
	React.useEffect(() => {
		if (user.currentSiteId !== '' && spaces.condecoDetails) {
			if (spaces.condecoDetails && spaces.condecoDetails.ISEMSAvailable) {
				setBookingLoader(true);
				routes.navigate({
					name: 'myBookings',
				});
				setBookingLoader(false);
			} else {
				setBookingLoader(true);
				window.location.href = `spaces/spacesHome/NA/NA`;
			}
		}
	}, [spaces.condecoDetails, user.currentSiteId, user.floorsingledata]);

	React.useEffect(() => {
		fetchBookKingList();
	}, [cancelType, user.currentSiteId]);

	const fetchBookKingList = () => {
		setBookingLoader(true);
		spaces.BookingList().then(res => {
			setBookingLoader(false);
			if (res !== null) {
				setselectedItem(res[0]);
				setBookData(res);
			} else {
				setBookData('');
			}
		});
	};

	const [dltBooking, setOpennew] = React.useState(false);
	const [popupRelease, setpopupRelease] = React.useState(false);
	const [checkinPopup, setcheckinPopup] = React.useState(false);
	const [dltId, setdltId] = React.useState<any>();
	const [bookingLoader, setBookingLoader] = React.useState(false);

	const handleAvailableclose = () => {
		setOpennew(false);
	};

	const checkInpopup = () => {
		setcheckinPopup(true);
	};
	const okcheckIn = () => {
		setcheckinPopup(false);
	};
	const checkInFlow = (id: any) => {
		setBookingLoader(true);
		spaces.checkInBooking(id).then((res: any) => {
			if (res) {
				setBookingLoader(false);
				checkInpopup();
				fetchBookKingList();
			}
		});
	};

	const handleReleaseBooking = () => {
		setOpennew(false);
		setBookingLoader(true);
		spaces.deleteBooking(dltId).then((res: any) => {
			setBookingLoader(false);
			setpopupRelease(true);
		});
	};

	const releasePopup = () => {
		localStorage.removeItem('CMValue');
		fetchBookKingList();
		setcancelType(cancelType + 1);
		// setBookid('');
		setpopupRelease(false);
	};
	const DeleteBooking = () => {
		setOpennew(true);
	};

	const handleCheckIn = (e: any, feedItem: any) => {
		e.stopPropagation();
		checkInFlow(feedItem.id);
	};
	const handleDeleteBooking = (e: any, feedItem: any) => {
		e.stopPropagation();
		setdltId(feedItem.id);
		setBookDataNew(feedItem);
		DeleteBooking();
	};
	const handleMap = (e: any, feedItem: any) => {
		sessionStorage.setItem('localCoordinates', feedItem.room.coordinateJson);
		routes.navigate(
			{
				name: 'bookingsView',
				params: {
					clientId: user?.siteClientId,
					floorId: feedItem.room.floorId,
				},
			},
			{
				clearParams: true,
			},
		);
	};
	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<Backdrop className={classes.backdrop} open={bookingLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div className={classes.container}>
					<Grid container>
						<Grid item container xs={8} sm={8} direction="column" className={classes.imgContainer} justify="flex-start">
							<Grid style={{ maxWidth: '100%' }}>
								{selectedItem && <img src={selectedItem?.room?.blobUrl} className={classes.imgTag} />}
							</Grid>
						</Grid>
						<Grid item container xs={4} sm={4} className={classes.feedContainer}>
							<Grid>
								<Paper className={classes.paper}>
									{bookData.length > 0 && bookData !== '' ? (
										bookData &&
										bookData.map((feedItem: any) => (
											<Card
												className={classes.closedCard}
												variant="outlined"
												onClick={(e: any) => {
													setselectedItem(feedItem);
												}}
											>
												<Box display="flex" className={classes.headerboxShadoColor}>
													{feedItem.checkedIn === true ? (
														<Icon className={classes.statusCheckIcon} color="#002855" icon={faCheckCircle} />
													) : (
														''
													)}
												</Box>
												<CardContent className={classes.cardDesignInner}>
													<Box display="flex">
														<Box display="flex" flexDirection="column" flexGrow={1}>
															<>
																<Box style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
																	<Box style={{ width: '35%' }}>
																		<Typography variant="subtitle2" className={classes.subtitle2}>
																			{feedItem.eventName}
																		</Typography>
																		<Typography variant="caption" color="textSecondary">
																			<p className={classes.pclass}>{feedItem.room?.building?.description}</p>
																			<p className={classes.pclass}>Floor {feedItem.room?.floor?.description}</p>
																			<p className={classes.pclass}>{feedItem.room?.code}</p>
																		</Typography>
																	</Box>
																	<Box>
																		<Typography
																			variant="caption"
																			style={{ fontSize: '14px', color: '#002855', letterSpacing: '1px' }}
																		>
																			Starts: &nbsp;&nbsp;
																			{feedItem.eventStartTime
																				? `${moment(feedItem.eventStartTime).format('DD/MM/YYYY')}  -  ${moment(
																						feedItem.reserveStartTime,
																				  ).format('HH:mm')}`
																				: ''}
																		</Typography>
																		<Typography
																			variant="caption"
																			style={{
																				display: 'block',
																				fontSize: '14px',
																				color: '#002855',
																				letterSpacing: '1px',
																			}}
																		>
																			Ends: &nbsp;&nbsp;&nbsp;&nbsp;
																			{feedItem.eventStartTime
																				? `${moment(feedItem.eventStartTime).format('DD/MM/YYYY')} - ${moment(
																						feedItem.reserveEndTime,
																				  ).format('HH:mm')} `
																				: ''}
																		</Typography>

																		<Box style={{ margin: '5px 0' }} className={classes.actionButton}>
																			<Button
																				className={classes.viewButton}
																				onClick={(e: any) => handleMap(e, feedItem)}
																			>
																				view
																			</Button>

																			<Button
																				variant="outlined"
																				color="primary"
																				onClick={(e: any) => handleDeleteBooking(e, feedItem)}
																				disabled={feedItem.canCancel ? false : true}
																				style={{ marginRight: '8px' }}
																				className={classes.cancelButton}
																			>
																				Cancel
																			</Button>

																			<Button
																				variant="contained"
																				disabled={feedItem.canCheckIn ? false : true}
																				onClick={(e: any) => handleCheckIn(e, feedItem)}
																				className={classes.disabledButton}
																			>
																				{feedItem && feedItem.checkedIn === true ? 'Checked in' : 'Check in'}
																			</Button>
																		</Box>
																	</Box>
																</Box>
															</>
														</Box>
													</Box>
												</CardContent>
											</Card>
										))
									) : bookData.length === 0 && user.currentSiteId !== '' ? (
										<div className={classes.Dimensions}>
											<img src={noResults} className={classes.accessDimensions} alt="underconstruction" />
											<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
												You're all clear!
											</Typography>
											<Typography color="textSecondary" variant="caption">
												Your space and desk booking will appear here
											</Typography>
										</div>
									) : (
										''
									)}
								</Paper>
							</Grid>
						</Grid>
						<Dialog open={dltBooking} onClose={handleAvailableclose}>
							{' '}
							<DialogContent className={classes.alignStyle} style={{ maxWidth: '400px', maxHeight: '235px' }}>
								{' '}
								<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
									{'Cancel spaces booking?'}
								</DialogTitle>{' '}
								<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
									{`Booking for ${bookDataNew?.room?.code} in  '${bookDataNew?.room?.building?.description}' Floor ${bookDataNew?.room?.floor?.description}.`}
									<div>
										From{' '}
										{bookDataNew
											? `${moment(bookDataNew?.eventStartTime).format('DD/MM/YYYY')}  -  ${moment(
													bookDataNew?.reserveStartTime,
											  ).format('HH:mm')}`
											: ''}
									</div>
									<div>
										until{' '}
										{bookDataNew?.eventStartTime
											? `${moment(bookDataNew.eventStartTime).format('DD/MM/YYYY')} - ${moment(
													bookDataNew.reserveEndTime,
											  ).format('HH:mm')} `
											: ''}
									</div>
								</DialogContentText>{' '}
								<DialogActions>
									{' '}
									<Button onClick={handleAvailableclose} color="primary" style={{ fontSize: '1rem' }}>
										Go back{' '}
									</Button>{' '}
									<Button
										style={{ paddingRight: '8px', fontSize: '1rem' }}
										onClick={() => handleReleaseBooking()}
										color="primary"
									>
										Cancel booking{' '}
									</Button>{' '}
								</DialogActions>{' '}
							</DialogContent>{' '}
						</Dialog>{' '}
						<Dialog open={popupRelease} onClose={releasePopup}>
							{' '}
							<DialogContent
								className={classes.alignStyle}
								style={{ width: '328px', maxWidth: '328px', maxHeight: '175px' }}
							>
								{' '}
								<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
									{'My Bookings '}
								</DialogTitle>{' '}
								<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
									{'Cancel Successfully'}
								</DialogContentText>{' '}
								<DialogActions>
									{' '}
									<Button style={{ paddingRight: '8px' }} onClick={() => releasePopup()} color="primary">
										Ok{' '}
									</Button>{' '}
								</DialogActions>{' '}
							</DialogContent>{' '}
						</Dialog>
						<Dialog open={checkinPopup} onClose={okcheckIn}>
							<DialogContent
								className={classes.alignStyle}
								style={{ width: '328px', maxWidth: '328px', maxHeight: '175px' }}
							>
								<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
									Check in Confirmed
								</DialogTitle>
								<DialogContentText
									style={{
										paddingLeft: '24px',
										paddingRight: '24px',
										marginBottom: '6px',
										textAlign: 'left',
									}}
								>
									Thank you!
								</DialogContentText>
								<DialogActions>
									<Button style={{ paddingRight: '8px' }} onClick={okcheckIn} color="primary">
										Ok
									</Button>
								</DialogActions>
							</DialogContent>
						</Dialog>
					</Grid>
				</div>
			</main>
		</>
	);
});

export default BookingCard;
