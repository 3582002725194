import canvasProperties from './CanvasProperties';
import { ICanvasFloorplanDetail, IObject, ICircle, ILine } from '../../types';

const fabric = canvasProperties.fabric;

const min = 99;
const max = 999999;
let pointArray: ICircle[];
let lineArray: ILine[];
let activeLine: ILine | null;
let activeShape: fabric.Polygon | null;

const polygonControl = {
	mouseDown(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		const optionsTarget: IObject = options.target!;
		if (optionsTarget && pointArray[0] && optionsTarget.id === pointArray[0].id) {
			this.generatePolygon(canvas);
			floorplanDetail.showPropContainer = true;
			canvas.selection = true;
		} else {
			this.addPoint(canvas, options);
		}
	},

	mouseUp(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},

	mouseMove(canvas: fabric.Canvas, options: fabric.IEvent) {
		if (activeLine && activeShape && activeLine.class === 'line') {
			const pointer = canvas.getPointer(options.e);
			activeLine.set({ x2: pointer.x, y2: pointer.y });

			const points = activeShape.get('points')!;
			points[pointArray.length] = {
				x: pointer.x,
				y: pointer.y,
			} as fabric.Point;
			activeShape.set({
				points: points,
			});
			canvas.renderAll();
		}
	},

	addPoint(canvas: fabric.Canvas, options: fabric.IEvent) {
		const random = Math.floor(Math.random() * (max - min + 1)) + min;
		const id = new Date().getTime() + random;
		const pointer = canvas.getPointer(options.e);
		const circle: ICircle = new fabric.Circle({
			radius: 3 / canvas.getZoom(),
			fill: canvasProperties.fillColor,
			stroke: canvasProperties.lineColor,
			opacity: canvasProperties.opacity,
			strokeWidth: 0.5 / canvas.getZoom(),
			left: pointer.x,
			top: pointer.y,
			selectable: false,
			hasBorders: false,
			hasControls: false,
			originX: 'center',
			originY: 'center',
			objectCaching: false,
		});

		if (pointArray.length === 0) {
			circle.set({
				fill: canvasProperties.fillColor,
				id: id,
			});
		}

		const points = [pointer.x, pointer.y, pointer.x, pointer.y];
		const line: ILine = new fabric.Line(points, {
			strokeWidth: 1 / canvas.getZoom(),
			fill: canvasProperties.fillColor,
			stroke: canvasProperties.lineColor,
			opacity: canvasProperties.opacity,
			originX: 'center',
			originY: 'center',
			selectable: false,
			hasBorders: false,
			hasControls: false,
			evented: false,
			objectCaching: false,
		});
		line.set({
			class: 'line',
		});

		if (activeShape) {
			const pos = canvas.getPointer(options.e);
			const points = activeShape.get('points');
			if (points) {
				points.push({
					x: pos.x,
					y: pos.y,
				} as fabric.Point);
				const polygon = new fabric.Polygon(points, {
					stroke: canvasProperties.lineColor,
					strokeWidth: 1 / canvas.getZoom(),
					fill: canvasProperties.fillColor,
					opacity: canvasProperties.opacity,
					selectable: false,
					hasBorders: false,
					hasControls: false,
					evented: false,
					objectCaching: false,
				});
				canvas.remove(activeShape);
				canvas.add(polygon);
				activeShape = polygon;
				canvas.renderAll();
			}
		} else {
			const polyPoint = [{ x: pointer.x, y: pointer.y }];
			const polygon = new fabric.Polygon(polyPoint, {
				stroke: canvasProperties.lineColor,
				strokeWidth: 1 / canvas.getZoom(),
				fill: canvasProperties.fillColor,
				opacity: canvasProperties.opacity,
				selectable: false,
				hasBorders: false,
				hasControls: false,
				evented: false,
				objectCaching: false,
			});
			activeShape = polygon;
			canvas.add(polygon);
		}
		activeLine = line;
		pointArray.push(circle);
		lineArray.push(line);
		canvas.add(circle);
		canvas.add(line);
		canvas.selection = false;
	},

	generatePolygon(canvas: fabric.Canvas) {
		let points: fabric.Point[] = [];
		pointArray.forEach(point => {
			points.push({
				x: point.left,
				y: point.top,
			} as fabric.Point);
			canvas.remove(point);
		});
		lineArray.forEach(line => {
			canvas.remove(line);
		});
		if (activeShape) canvas.remove(activeShape);
		if (activeLine) canvas.remove(activeLine);

		const polygon = new fabric.Polygon(points, {
			fill: canvasProperties.fillColor,
			opacity: canvasProperties.opacity,
		});
		canvas.add(polygon);

		this.resetPolygonCoordinates();
	},

	resetPolygonCoordinates() {
		activeLine = null;
		activeShape = null;
		pointArray = [];
		lineArray = [];
	},
};

export default {
	...polygonControl,
};
