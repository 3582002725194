import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	title: {
		padding: theme.spacing(2),
	},
	titleReport: {
		padding: theme.spacing(3),
	},
	spinner: {
		position: 'absolute',
		top: '40%',
		left: '50%',
		zIndex: 1,
	},
	addIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	actionIcons: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	actionIconButton: {
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		width: 40,
		height: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	actionHomeIconButtonActive: {
		'& > * svg': {
			color: theme.palette.text.primary,
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	tableHeaders: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	noResults: {
		textAlign: 'center',
		marginTop: '25vh',
	},
	textMargin: {
		textAlign: 'center',
		margin: '2vh 0 10vh',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	accessDimensions: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		margin: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(5, 1, 1, 1),
			overflow: 'auto',
		},
		width: '90%',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		overflow: 'scroll',
		maxWidth: '100%',
	},
	contentForm: {
		flexGrow: 1,
		backgroundColor: '#ffffff',
		textAlign: 'left',
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	gridItem: {
		'@media (min-width: 960px)': {
			flexGrow: 0,
			maxWidth: '80%',
			flexBasis: '100%',
		},
	},
	main: {
		flexGrow: 1,
	},
	paperForm: {
		color: theme.palette.text.primary,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	dateStyle: {
		fontSize: theme.typography.pxToRem(14),
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	uploader: {
		display: 'none',
	},
	createCancelLink: {
		'& > *': {
			cursor: 'pointer',
			margin: theme.spacing(0, 0, 0, 2),
		},
		margin: theme.spacing(3, 4),
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	alignButton: {
		height: '100px',
	},
	cancelbtn: {
		width: '76px',
		height: '36px',
		marginRight: '5px',
		marginTop: '15px',
		color: '#66CCFA',
		border: '1px solid #66CCFA',
		'& .MuiButton-label': {
			marginTop: '1px',
		},
		'&:hover': {
			backgroundColor: '#66ccfa',
			color: '#FFFFFF',
			border: '1px solid #66CCFA',
		},
		'& .MuiButton-outlined': {
			border: '2px solid red',
		},
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	Draftbtn: {
		width: '96px',
		height: '36px',
		marginRight: '5px',
		marginTop: '15px',
		backgroundColor: '#66ccfa',
		color: '#FFFFFF',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#56b1df !important',
			color: '#FFFFFF',
			boxShadow: 'none',
		},
		'&[disabled]': {
			color: '#FFFFFF',
		},
		'& .MuiButton-label': {
			width: 'auto',
			marginLeft: '-1px',
			marginTop: '1px',
		},
	},
	Publishbtn: {
		width: '78px',
		height: '36px',
		marginRight: '5px',
		marginTop: '15px',
		backgroundColor: '#66ccfa',
		color: '#FFFFFF',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#56b1df !important',
			color: '#FFFFFF',
			boxShadow: 'none',
		},
		'&[disabled]': {
			color: '#FFFFFF',
		},
		'& .MuiButton-label': {
			marginTop: '1px',
		},
	},
	keyboardDateTime: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		textAlign: 'left',
		width: '100%',
	},
	formControl: {
		width: '180px',
		marginRight: '5px',
		marginLeft: '7px',
		'&:hover': {
			'& .MuiInputBase-input': {
				backgroundColor: '#66CCFA !important',
			},
			'& > .MuiInputLabel-formControl': {
				color: '#FFFFFF',
			},
			'& .MuiSvgIcon-root': {
				color: '#FFFFFF !important',
			},
		},
		'& > .MuiInputLabel-formControl': {
			color: '#66CCFA',
		},
		'& .MuiSvgIcon-root': {
			color: '#66CCFA !important',
		},
		'& .MuiInputLabel-shrink': {
			color: '#098BBD',
		},
		'& .MuiInputBase-input': {
			background: 'none',
		},
		'& .MuiOutlinedInput-input': {
			padding: '8.5px 14px',
		},
		'& .MuiInputLabel-filled': {
			marginTop: '-9px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#66CCFA',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#66CCFA',
		},
	},
	noLabel: {
		display: 'none',
	},
	formControlsites: {
		width: '280px',
		height: '36px !important',
		marginRight: '15px',
		zIndex: 999,
		'& .MuiTextField-root': {
			border: '1px solid #66CCFA',
			overflow: 'hidden',
			borderRadius: 4,
			'& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
				padding: '0 !important',
			},
			'& > .MuiFilledInput-underline:before': {
				borderBottom: 'none',
				padding: 0,
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: 'none',
			},
			'& .MuiFilledInput-underline:after': {
				borderBottom: 'none',
			},
			'& label + .MuiInput-formControl': {
				marginTop: '-5px',
			},
			'& .MuiInputBase-input': {
				background: '#FFFFFF !important',
				marginLeft: '-4px',
			},
			'& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
				padding: '7.5px 4px',
			},
			'& .MuiSvgIcon-root': {
				color: '#66CCFA !important',
			},
			'& .MuiInputLabel-formControl': {
				top: '-9px',
				color: '#66CCFA',
			},
			'& .MuiFilledInput-underline.Mui-disabled:before': {
				borderBottomStyle: 'none',
			},
			'& .MuiAutocomplete-inputRoot': {
				backgroundColor: 'white',
			},
			'& .MuiAutocomplete-tag': {
				margin: '0px',
			},

			'&:hover': {
				backgroundColor: '#66CCFA !important',
				'& .MuiInputBase-input': {
					backgroundColor: '#66CCFA !important',
				},
				'& .MuiInputLabel-root': {
					color: '#FFFFFF',
					backgroundColor: '#66CCFA !important',
				},
				'& .MuiSvgIcon-root': {
					color: '#FFFFFF !important',
				},
				'& .MuiInputLabel-shrink': {
					color: '#098BBD',
				},
				'& .MuiAutocomplete-inputRoot': {
					backgroundColor: '#66CCFA !important',
				},
			},
			'&:active': {
				'& .MuiInputLabel-root': {
					color: '#098BBD',

					backgroundColor: '#ffffff !important',
				},
			},
		},
		'& .MuiInputLabel-shrink ': {
			color: '#FFFFFF',
			background: '#098BBD',
			borderRadius: '4px',
			display: 'none',
			'& .MuiTextField-root': {
				background: 'blue',
			},
			'& .MuiInputLabel-root': {
				display: 'none',
			},
			'& .MuiInputBase-input': {
				color: '#FFFFFF',
				background: '#098BBD',
				caretColor: 'transparent',
			},
			'& .MuiFilledInput-underline:after': {
				borderBottom: 'none',
			},
			'& > .MuiFilledInput-underline:before': {
				borderBottom: 'none',
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: 'none',
			},
			'& .MuiAutocomplete-endAdornment': {
				color: 'transparent',
			},
			'& .MuiSvgIcon-root': {
				color: '#098BBD !important',
			},
		},
	},
	timeStart: {
		width: '280px',
		border: '1px solid #66CCFA',
		borderRadius: '4px',
		marginRight: '15px',
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			background: 'none',
			padding: '0px 0 1px',
		},
		'& .MuiFormControl-marginNormal': {
			marginTop: '0px',
			marginBottom: '0px',
		},
		'& label + .MuiInput-formControl': {
			marginTop: '0px',
		},
		'& .MuiIconButton-root': {
			color: '#66CCFA',
			padding: '0px',
			marginTop: '-15px',
			marginRight: '5px',
		},
		'& .MuiInputLabel-animated': {
			color: '#66CCFA',
			fontSize: '17px',
		},
	},
	formHelp: {
		width: '162px',
		overflow: 'hidden',
		marginLeft: '10px',
	},
	timeEnd: {
		width: '280px',
		border: '1px solid #66CCFA',
		borderRadius: '4px',
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			background: 'none',
			padding: '0px 0 1px',
		},
		'& .MuiFormControl-marginNormal': {
			marginTop: '0px',
			marginBottom: '0px',
		},
		'& label + .MuiInput-formControl': {
			marginTop: '0px',
		},
		'& .MuiIconButton-root': {
			color: '#66CCFA',
			padding: '0px',
			marginTop: '-15px',
			marginRight: '5px',
		},
		'& .MuiInputLabel-animated': {
			color: '#66CCFA',
			fontSize: '17px',
		},
	},
	repeatUntil: {
		width: '140px',
		marginTop: '-8px',
		'& .MuiFormControl-marginNormal': {
			marginTop: '0px',
			marginBottom: '0px',
			border: '2px solid #66ccfa',
			borderRadius: '4px',
			height: '36px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			background: 'none',
			padding: '0px',
		},
		'& label + .MuiInput-formControl': {
			marginTop: '0px',
		},
	},
	text: {
		width: '50%',
		height: '22px',
		color: '#002855',
		letterSpacing: '0px',
		textAlign: 'left',
		font: 'normal normal normal 18px/12px Roboto',
		marginTop: '9px',
		paddingTop: '5px',
		marginLeft: '-16px',
		opacity: 1,
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		margin: theme.spacing(1),
		marginLeft: '6px',
		width: '162px',
		padding: theme.spacing(1),
		height: '46px',
		color: '#1fa4d8',
		fontSize: '16px',
		'&:hover': {
			border: '2px solid #66ccfa',
			borderRadius: '8px',
		},
	},
	buttonWraper: {
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(1),
		background: 'none',
		border: '2px solid #66ccfa',
		fontSize: '16px',
		color: '#14A0D6',
		'&:hover': {
			background: '#66ccfa',
			fontColor: 'white !important',
			color: 'white !important',
		},
		'&[disabled]': {
			border: '2px solid rgba(0, 0, 0, 0.26)',
			opacity: 0.2,
		},
	},
	telegram: {
		background: '#66ccfa',
		height: '46px',
		width: '46px',
		marginRight: '-2px',
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
	},
	telegramActive: {
		background: '#14a0d6',
		height: '46px',
		width: '46px',
		marginRight: '-2px',
		borderRadius: '4px',
	},
	textTitle: {
		width: '53px',
		height: '19px',
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
		paddingTop: '1px',
		marginTop: '20px',
		marginLeft: '7px',
		marginBottom: '8px',
		opacity: 1,
	},
	textSubtitle: {
		width: '49px',
		color: '#002855',
		height: '19px',
		marginTop: '12px',
		marginLeft: '7px',
		marginBottom: '8px',
		opacity: 1,
	},
	textContent: {
		width: '49px',
		color: '#002855',
		height: '19px',
		marginTop: '12px',
		marginLeft: '7px',
		marginBottom: '8px',
		opacity: 1,
	},
	InputField: {
		'&.MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline': {
			background: 'none',
		},
		'& .MuiInputBase-input MuiInput-input': {
			background: 'none',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#DBE4EB',
			'&:hover': {
				borderColor: '#8b8dae !important',
			},
		},
		'& .MuiInputBase-input': {
			background: '#FFFFFF !important',
			width: '95% !important',
		},
		'& .MuiOutlinedInput-input': {
			padding: '8.5px 12px',
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '0px',
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '8px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#8B8DAE',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#14a0d6',
		},
	},
	errorInputText: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#BD0810',
			paddingBottom: '0px',
		},
		'& .MuiFormHelperText-contained': {
			margin: '2px !important',
		},
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.pxToRem(22),
	},
	accessDimensionsForm: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	dialogBoxStyle: {
		minWidth: 'calc(12% + 100px)',
		padding: theme.spacing(3),
	},
	dialogBoxText: {
		color: theme.palette.text.primary,
		fontSize: '20',
	},
	selectItem: {
		fontSize: theme.typography.pxToRem(16),
		paddingLeft: theme.spacing(2),
	},
	formSelect: {
		width: '100%',
	},
	noPadding: {
		padding: theme.spacing(0, 1, 1, 3),
	},
	textReport: {
		padding: theme.spacing(2, 3),
		textAlign: 'left',
		fontSize: 14,
	},
	marginRight: {
		marginRight: theme.spacing(2),
	},
	footerIconLabel: {
		fontSize: '9px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '24px',
		width: 'auto',
	},
	footerBtn: {
		height: '36px',
		margin: theme.spacing(0, 1, 0, 0),
	},
	outlinedBtn: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '4px',
		padding: theme.spacing(0, 0.125),
	},
	displayFixed: {
		position: 'fixed',
		bottom: '24px',
		width: 'auto',
		display: 'flex',
		zIndex: 'inherit',
	},
	footerIconJustify: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'space-between',
		},
	},
	formControlLabel: {
		'& .MuiFilledInput-underline:after': {
			borderColor: '#bd0810',
		},
		'& .MuiFilledInput-underline:before': {
			borderColor: '#bd0810',
		},
		'& .MuiIconButton-label': {
			color: '#bd0810',
		},
		'& .makeStyles-Autocomplete-11 .MuiInputLabel-root': {
			color: '#bd0810',
		},
		'& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
			color: '#bd0810',
		},
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	contentMain: {
		flexGrow: 1,
		width: '100%',
		overflowX: 'hidden',
		backgroundColor: '#ffffff',
	},
	appBarSpacerMain: {
		height: '64px',
		backgroundColor: '#f4f8fc',
	},
	containerMain: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		backgroundColor: 'white',
		marginLeft: '25px',
		marginRight: '25px',
	},
	listContent: {
		marginTop: '36px',
	},
	cloudIcon: {
		width: '46px',
		height: '46px',
		marginRight: '12px',
		backgroundColor: '#66ccfa',
		boxShadow: 'none',
		opacity: 1,
		'&:hover': {
			backgroundColor: '#14a0d6 !important',
			boxShadow: '0px 3px 6px #00000029',
		},
		'& > * svg': {
			width: '30px !important',
			height: '24px',
			color: 'white',
			display: 'flex',
		},
	},
	listPaper: {
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		background: '#f4f8fc',
		borderRadius: '16px',
		marginTop: '-4px',
		boxShadow: 'none',
		'& .MuiPaper-elevation1': {
			boxShadow: '0px 3px 5px #00000029',
		},
	},
	headCard: {
		// width: '100%',
		boxShadow: 'none',
		margin: '8px',
		borderRadius: '16px',
	},
	listCard: {
		margin: '8px',
		borderRadius: '16px',
	},
	cellContentSites: {
		width: '96px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color: '#002855',
		paddingTop: '4px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentTitle: {
		width: '200px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
	},
	cellContentStartTime: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentEndTime: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentLive: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		paddingTop: '8px',
		paddingBottom: '8px',
		color: '#002855',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	statuscellContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentLikes: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentShares: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#002855',
		paddingTop: '8px',
		paddingBottom: '8px',
		font: 'normal normal normal 18px/12px Roboto',
		borderBottom: 'none',
	},
	cellContentActions: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	plusIcon: {
		width: '46px',
		height: '46px',
		backgroundColor: '#66ccfa',
		boxShadow: 'none',
		opacity: 1,
		'&:hover': {
			backgroundColor: '#14a0d6 !important',
			boxShadow: '0px 3px 6px #00000029',
		},
		'& > * svg': {
			width: '21px !important',
			height: '24px',
			color: 'white',
			display: 'flex',
		},
	},
	listtableCell: {
		borderBottom: 'none',
	},
	listtableSites: {
		color: '#002855',
		width: '96px',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableTitle: {
		color: '#002855',
		width: '200px',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableStartDate: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableEndDate: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableLive: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableStatus: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableLikes: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableShares: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	listtableActions: {
		color: '#002855',
		'& > *': {
			margin: theme.spacing(0, 0, 0, 2),
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 0, 1),
			},
		},
	},
	actionIconButtonList: {
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		width: '37px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgb(9, 139, 189, 0.08)',
		},
		'& > * svg': {
			height: '24px',
			width: '21px !important',
			color: '#8B8DAE',
		},
		'& .MuiIconButton-label': {
			'&>*:nth-child(1)': {
				display: 'none',
			},
			'&:hover': {
				'& .svg-inline--fa': {
					color: '#14a0d6',
				},
				'&>*:nth-child(2)': {
					display: 'none',
				},
				'&>*:nth-child(1)': {
					display: 'block',
				},
			},
		},
	},
	actionIconsnew: {
		display: 'flex',
		// marginLeft: '30px',
	},
	footerCard: {
		boxShadow: 'none',
		marginTop: '8px',
		marginLeft: '1180px',
	},
	tooltip: {
		background: '#8B8DAE',
		fontSize: '12px',
		marginTop: '0px',
		padding: '5px',
	},
}));

export default useStyles;
