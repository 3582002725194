import * as React from 'react';
import Notification from './Notification';
import { stores } from '../store';
import { useObserver } from 'mobx-react-lite';

const NotificationCenter = () => {
	const notificationStore = React.useContext(stores.context.notifications);

	return useObserver(() => (
		<>
			{notificationStore.notifications.map(notification => (
				<Notification key={notification.id} notifications={notificationStore} notification={notification} />
			))}
		</>
	));
};

export default NotificationCenter;
