import React from 'react';
import { stores } from '../../store';
import { useObserver } from 'mobx-react-lite';
import ContentForm from './FeedForm/ContentForm';
import config from './FeedForm/config';
import { makeStyles } from '@material-ui/core';
import PermanentAppBar from '../../components/Header/PermanentAppBar';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
	},
	appBarSpacer: {
		height: '64px',
		backgroundColor: '#f4f8fc',
	},
	content: {
		flexGrow: 1,
		width: '100%',
		overflowX: 'hidden',
		backgroundColor: '#ffffff',
	},
	'@global': {
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: '#66CCFA',
		},
		'*::-webkit-scrollbar': {
			width: '6px',
		},
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 64px)',
		display: 'flex',
		backgroundColor: 'white',
		paddingLeft: '50px',
	},
}));

const Announcements = () => {
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	return useObserver(() => (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<div className={classes.container}>
					<ContentForm props="Announcement" config={config} />
				</div>
			</main>
		</>
	));
};

export default Announcements;
