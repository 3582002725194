import * as React from 'react';
import { constants, Options } from 'router5';
import { Params, State } from 'router5/types/types/base';

import RouteStore from '../store/Routes';
import NotFound from '../pages/NotFound';
import UnRegister from '../pages/UnRegister';
import Home from '../pages/Home';
import Spaces from '../pages/Spaces';
import Feed from '../pages/Feed';
import Ask from '../pages/Ask';
import Refuel from '../pages/Refuel';
import Profile from '../pages/Profile';
import SiteInfo from '../pages/SiteInfo';
import BookingsCard from '../components/BookingsCard';
import About from '../pages/About';
import Settings from '../pages/Settings';
import AnnouncementList from '../pages/ContentManagement/AnnouncementList';
import Announcements from '../pages/ContentManagement/Announcements';
import Delivery from '../pages/ContentManagement/Delivery';
import Survey from '../pages/ContentManagement/Survey';
import Surveylist from '../pages/ContentManagement/Surveylist';
import SurveyReport from '../pages/ContentManagement/SurveyReport';
import Visitor from '../pages/ContentManagement/Visitor';
import Promotion from '../pages/ContentManagement/Promotion';
import PromotionList from '../pages/ContentManagement/PromotionList';
import Evacuation from '../pages/ContentManagement/Evacuation';
import EvacuationList from '../pages/ContentManagement/EvacuationList';
import Incident from '../pages/ContentManagement/Incident';
import EditSiteInfo from '../pages/ContentManagement/EditSiteInfo';
import SentItems from '../components/Ask/SentItems';
import Archive from '../pages/FeedArchive';

export interface ILinkData {
	name?: string;
	params?: object;
}

export interface IRoute {
	component?: (next?: Params) => any;
	activate?: (store: RouteStore, current?: Params, prev?: State) => void;
	deactivate?: (store: RouteStore, current?: Params, next?: State) => void;
	path: string;
	defaultParams?: Params;
	forwardTo?: string;
	name?: string;
	breadcrumbs?: Array<{ title: string; link: ILinkData }>;
	prettyName?: string;
}

export interface IRoutes {
	[name: string]: IRoute;
}

const homeBreadcrumb = { title: 'Home', link: { name: 'Home' } };

export const routes: IRoutes = {
	root: {
		path: '/',
		//forwardTo: 'feed',
		component: () => <Home />,
		breadcrumbs: [homeBreadcrumb],
		prettyName: 'Home',
	},
	spaces: {
		path: '/spaces/floorplans',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},
	floorplanUploader: {
		path: '/spaces/floorplanUploader',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},
	roomInfo: {
		path: '/spaces/roomInfo/:clientId/:floorId/:zoneId',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},

	deskInfo: {
		path: '/spaces/deskInfo/:clientId/:floorId/:zoneId',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},

	spacesHome: {
		path: '/spaces/spacesHome/:clientId/:floorId',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},

	addAttendees: {
		path: '/spaces/addAttendees/:BookingId/:floorId/:zoneId/:subjectTitle/:meetingRoom',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},
	bookingsView: {
		path: '/spaces/bookingsview/:clientId/:floorId',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},
	floorplanEditor: {
		path: '/spaces/floorplanEditor/:clientId/:floorId',
		component: () => <Spaces />,
		prettyName: 'Spaces',
	},
	announcements: {
		path: '/contentManagement/announcementList',
		component: () => <AnnouncementList />,
		prettyName: 'Announcements',
	},
	announcementsEditor: {
		path: '/contentManagement/announcements',
		component: () => <Announcements />,
		prettyName: 'Announcements',
	},
	evacuation: {
		path: '/contentManagement/evacuationList',
		component: () => <EvacuationList />,
		prettyName: 'Evacuation',
	},
	evacuationEditor: {
		path: '/contentManagement/evacuation',
		component: () => <Evacuation />,
		prettyName: 'Evacuation',
	},
	promotion: {
		path: '/contentManagement/promotionList',
		component: () => <PromotionList />,
		prettyName: 'Promotion',
	},
	promotionEditor: {
		path: '/contentManagement/promotion',
		component: () => <Promotion />,
		prettyName: 'Promotion',
	},
	delivery: {
		path: '/contentManagement/delivery',
		component: () => <Delivery />,
		prettyName: 'Delivery',
	},
	incident: {
		path: '/contentManagement/incident',
		component: () => <Incident />,
		prettyName: 'Incident',
	},
	survey: {
		path: '/contentManagement/surveyList',
		component: () => <Surveylist />,
		prettyName: 'Survey',
	},
	surveyEditor: {
		path: '/contentManagement/survey',
		component: () => <Survey />,
		prettyName: 'Survey',
	},
	surveyReport: {
		path: '/contentManagement/surveyReport',
		component: () => <SurveyReport />,
		prettyName: 'Survey',
	},

	surveylist: {
		path: '/contentManagement/surveylist',
		component: () => <Surveylist />,
		prettyName: 'Survey',
	},

	visitor: {
		path: '/contentManagement/visitor',
		component: () => <Visitor />,
		prettyName: 'Visitor',
	},
	siteInfo: {
		path: '/site-info',
		component: () => <SiteInfo />,
		prettyName: 'Spaces',
	},

	myBookings: {
		path: '/myBookings',
		component: () => <BookingsCard />,
		prettyName: 'Spaces',
	},

	editSiteInfo: {
		path: '/edit-site-info',
		component: () => <EditSiteInfo />,
		prettyName: 'Spaces',
	},
	editFloorPlans: {
		path: '/edit-floor-plans',
		component: () => <Profile />,
		prettyName: 'Spaces',
	},
	feed: {
		path: '/feed',
		component: () => <Feed />,
		prettyName: 'Feed',
	},
	ask: {
		path: '/ask',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	feedback: {
		path: '/ask/feedback',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	chatbot: {
		path: '/ask/chatbot',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	jobRequestForm: {
		path: '/ask/job-request-form',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	jobRequestChat: {
		path: '/ask/job-request-chat',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	viewMyJobs: {
		path: '/ask/view-my-jobs',
		component: () => <Ask />,
	},
	dynamicform: {
		path: '/ask/dynamicform',
		component: () => <Ask />,
		prettyName: 'Ask',
	},
	sentItems: {
		path: '/ask/sentItems',
		component: () => <SentItems />,
		prettyName: 'Ask',
	},
	refuel: {
		path: '/refuel',
		component: () => <Refuel />,
		prettyName: 'Refuel',
	},
	copyMenus: {
		path: '/refuel/copy-menus/',
		component: () => <Refuel />,
		prettyName: 'Refuel',
	},
	editMenus: {
		path: '/refuel/edit-menus/',
		component: () => <Refuel />,
		prettyName: 'Refuel',
	},
	addMenus: {
		path: '/refuel/addMenus',
		component: () => <Refuel />,
		prettyName: 'Refuel',
	},
	refuelList: {
		path: '/refuelList',
		component: () => <Refuel />,
		prettyName: 'Refuel',
	},
	register: {
		path: '/register',
		component: () => <Home />,
		prettyName: 'RegisterForm',
	},
	profile: {
		path: '/profile',
		component: () => <Profile />,
		prettyName: 'Profile',
	},
	archive: {
		path: '/archive',
		component: () => <Archive />,
		prettyName: 'Archive',
	},
	about: {
		path: '/about',
		component: () => <About />,
		prettyName: 'About',
	},
	settings: {
		path: '/settings',
		component: () => <Settings />,
		prettyName: 'Settings',
	},
	[constants.UNKNOWN_ROUTE]: {
		component: () => <NotFound />,
		path: '/404',
	},
	deletedUser: {
		path: '/unregister',
		component: () => <UnRegister />,
		prettyName: 'UnRegister',
	},
};

export const options: Partial<Options> = {
	queryParamsMode: 'loose',
	allowNotFound: true,
};
