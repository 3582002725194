import canvasProperties from './CanvasProperties';
import { ICanvasFloorplanDetail } from '../../types';

const fabric = canvasProperties.fabric;

let circle: fabric.Circle;
let isDown: boolean;
let origX: number;

const circleControl = {
	mouseDown(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		isDown = true;
		const pointer = canvas.getPointer(options.e);
		origX = pointer.x;
		circle = new fabric.Circle({
			left: pointer.x,
			top: pointer.y,
			radius: 1,
			fill: canvasProperties.fillColor,
			opacity: canvasProperties.opacity,
			objectCaching: false,
			originX: 'center',
			originY: 'center',
		});
		canvas.add(circle);
		canvas.selection = false;
	},

	mouseUp(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		canvas.selection = true;
		isDown = false;
		floorplanDetail.showPropContainer = true;
	},

	mouseMove(canvas: fabric.Canvas, options: fabric.IEvent) {
		if (!isDown) return;
		const pointer = canvas.getPointer(options.e);
		circle.set({ radius: Math.abs(origX - pointer.x) });
		canvas.remove(circle);
		canvas.add(circle);
		canvas.renderAll();
	},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
};

export default {
	...circleControl,
};
