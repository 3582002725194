import canvasProperties from './CanvasProperties';
import { ICanvasFloorplanDetail } from '../../types';

const fabric = canvasProperties.fabric;

let isDown: boolean;
let origX: number;
let origY: number;
let rect: fabric.Rect;

const rectangleControl = {
	mouseDown(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		isDown = true;
		const pointer = canvas.getPointer(options.e);
		origX = pointer.x;
		origY = pointer.y;
		rect = new fabric.Rect({
			left: origX,
			top: origY,
			originX: 'left',
			originY: 'top',
			width: pointer.x - origX,
			height: pointer.y - origY,
			angle: 0,
			fill: canvasProperties.fillColor,
			opacity: canvasProperties.opacity,
			objectCaching: false,
		});
		canvas.add(rect);
		canvas.selection = false;
	},

	mouseUp(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {
		canvas.selection = true;
		isDown = false;
		floorplanDetail.showPropContainer = true;
	},

	mouseMove(canvas: fabric.Canvas, options: fabric.IEvent) {
		if (!isDown) return;
		const pointer = canvas.getPointer(options.e);

		if (origX > pointer.x) {
			rect.set({ left: Math.abs(pointer.x) });
		}
		if (origY > pointer.y) {
			rect.set({ top: Math.abs(pointer.y) });
		}

		rect.set({ width: Math.abs(origX - pointer.x) });
		rect.set({ height: Math.abs(origY - pointer.y) });
		canvas.remove(rect);
		canvas.add(rect);
		canvas.renderAll();
	},
	mouseWheel(canvas: fabric.Canvas, options: fabric.IEvent, floorplanDetail: ICanvasFloorplanDetail) {},
};

export default {
	...rectangleControl,
};
