import httpService from './httpService';

const { get, post, del, put, delPost } = httpService.generateHttpMethods(process.env.REACT_APP_API_URL as string);

export async function canAccessRoute(routes: string[]) {
	return get(`/permissions`, { type: 'changeThisRouteTypeName', ids: routes });
}

export async function getAPI(path: string, params?: any) {
	return get(path, params);
}

export async function postAPI(path: string, postData: {}) {
	return post(path, postData);
}

export async function deleteAPI(path: string, postData: {}) {
	return del(path, postData);
}
export async function deletePostAPI(path: string, postData: {}) {
	return delPost(path, postData);
}
export async function putAPI(path: string, postData: {}) {
	return put(path, postData);
}
