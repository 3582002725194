import React, { useEffect } from 'react';
import '../assets/slidercss/slide.css';
import { observer } from 'mobx-react-lite';
import { stores } from '../store';
import { Typography, makeStyles, Avatar } from '@material-ui/core';
import accessDeniedIcon from '../assets/images/noAccessNew.svg';
import noResults from '../assets/images/noResultsNew.svg';
import underConstruction from '../assets/images/underConstructionNew.svg';
import BoxedIcon from '../assets/images/Boxed_Icon_Shadow@2x.png';
import WioPayIcon from '../assets/images/WioPay_Icon_Shadow@2x.png';
import YoYoIcon from '../assets/images/YoYo_Icon_Shadow@2x.png';
import Button from '@material-ui/core/Button';
import Carousel from './Carousel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { toJS } from 'mobx';
import { IMenuGetItem, ISiteObject } from '../types';
const useStyles = makeStyles(theme => ({
	carWidth: {
		width: '350px',
		left: '260px',
	},
	Dimensions: {
		display: 'block',
		objectFit: 'contain',
		textAlign: 'center',
		position: 'absolute',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
	},
	textMargin: {
		margin: '2vh 0 10vh',
	},
	accessDimensionslatest: {
		width: '40%',
		height: '40%',
		overflow: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	accessDimensions: {
		width: '80%',
		height: '65%',
		display: 'block',
		margin: 'auto',
	},
	MediaCaption: {
		position: 'absolute',
		bottom: '0px',
		padding: '11px',
		fontSize: '0.9rem',
		background: '#172A3F',
		color: 'white',
		opacity: '0.9',
		width: '394px',
		height: '8%',
		borderRadius: '0px 0px 3px 3px',
	},

	test: {
		'& .MuiDialog-paperWidthSm': {
			maxWidth: '100%',
			overflow: 'auto',
		},
	},

	testlat: {
		marginLeft: '199px !important',
		position: 'fixed',
		right: 'auto',
	},
	testnew: {
		border: '2px solid red',
	},
	PaymentCaption: {
		textOverFlow: 'ellipsis',

		position: 'relative',
		bottom: '18px',
		padding: '4px',
		fontSize: '13px',
		top: '20px',
		height: '36px',
	},

	PaymentCaptionnew: {
		textOverFlow: 'ellipsis',

		position: 'relative',
		bottom: '18px',
		padding: '4px',
		fontSize: '13px',
		top: '40px',
		height: '36px',
	},
}));

const RefuelMenuList = observer(() => {
	const appStores = React.useContext(stores.context.appStores);
	const refuelMenus = React.useContext(stores.context.refuelMenus);
	const user = React.useContext(stores.context.user);
	const sites = React.useContext(stores.context.site);
	const classes = useStyles();
	const [showRefuelMenu, setShowRefuelMenu] = React.useState(0);
	const [RefuelStatus, setRefuelStatus] = React.useState(0);
	const [RefuelVendorType, setRefuelVendorType] = React.useState(0);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	appStores.showAppLoader = user.permissions === null ? true : false;

	React.useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const refuelIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '35FA69F9-7A9E-41FC-AFB3-A8BEFA4E4637',
			);

			const siteName: any = df.find((nam: any) => nam.Id === user.currentSiteId);
			setRefuelStatus(siteName.RefuelStatusId);
			setRefuelVendorType(siteName.RefuelVendorTypeId);
			if (user.permissions.Modules[refuelIndex] && refuelIndex && user.permissions.Modules[refuelIndex].Read) {
				if (
					user.permissions.Modules[refuelIndex].Create ||
					user.permissions.Modules[refuelIndex].Update ||
					user.permissions.Modules[refuelIndex].Delete
				) {
					setShowRefuelMenu(1);
				}
			} else {
				setShowRefuelMenu(0);
			}
		} else {
			setShowRefuelMenu(0);
		}
	}, [user.permissions, user.currentSiteId]);

	const [menus, setMenus] = React.useState<IMenuGetItem[]>([]);
	useEffect(() => {
		const fetchMenus = async () => {
			await fetchRefuelMenus();
		};
		fetchMenus();
	}, [user.currentSiteId, refuelMenus]);
	const fetchRefuelMenus = async () => {
		appStores.showAppLoader = true;
		const result = await refuelMenus.fetchRefuelActiveMenus(user.currentSiteId);
		setMenus(toJS(result));
		appStores.showAppLoader = false;
	};
	const [imagnew, addImage] = React.useState();
	const [Disptitle, setTitle] = React.useState();
	const [mtype, setType] = React.useState();
	const [open, setOpen] = React.useState(false);
	const handleClickOpenfirst: any = (imag: any, tit: any, medtype: any) => {
		setOpen(true);
		addImage(imag);
		setTitle(tit);
		setType(medtype);
	};
	console.log(handleClickOpenfirst);
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{showRefuelMenu === 1 && RefuelStatus === 1 && menus.length !== 0 && appStores.showAppLoader === false ? (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
					<div>
						<Carousel menus={menus} classes={classes} />
					</div>
				</div>
			) : showRefuelMenu === 1 && RefuelStatus === 3 && appStores.showAppLoader === false ? (
				<>
					<div className={classes.Dimensions}>
						<img src={underConstruction} className={classes.accessDimensions} alt="underconstruction" />
						<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
							Refuel is not available for this location
						</Typography>
						<Typography variant="caption">Please contact your system administrator</Typography>
					</div>
				</>
			) : showRefuelMenu === 0 && appStores.showAppLoader === false ? (
				<>
					<Avatar alt="access denied" src={accessDeniedIcon} className={classes.accessDimensionslatest} />
					<Typography variant="subtitle2">You do not have permission to access this area</Typography>
					<Typography variant="caption">Please contact your manager to request this access</Typography>
				</>
			) : showRefuelMenu === 1 &&
			  RefuelStatus === 1 &&
			  menus.length === 0 &&
			  RefuelVendorType === 3 &&
			  appStores.showAppLoader === false ? (
				<>
					<div className={classes.Dimensions}>
						<img src={noResults} className={classes.accessDimensions} alt="noresults" />
						<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
							No menus uploaded for this location
						</Typography>
						<Typography variant="caption">Please check again soon</Typography>
						<Typography className={classes.PaymentCaptionnew}>
							<a href={user.WebBoxed_Url} target="_blank" style={{ textDecoration: 'none' }}>
								{' '}
								<img src={BoxedIcon} style={{ marginBottom: '-19px', width: '42px' }} />
								&nbsp;&nbsp;
								<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
									Open payment wallet
								</Button>
							</a>{' '}
						</Typography>
					</div>
				</>
			) : showRefuelMenu === 1 &&
			  RefuelStatus === 1 &&
			  menus.length === 0 &&
			  RefuelVendorType === 2 &&
			  appStores.showAppLoader === false ? (
				<>
					<div className={classes.Dimensions}>
						<img src={noResults} className={classes.accessDimensions} alt="noresults" />
						<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
							No menus uploaded for this location
						</Typography>
						<Typography variant="caption">Please check again soon</Typography>
						<Typography className={classes.PaymentCaptionnew}>
							<a href={user.WebYoYo_Url} target="_blank" style={{ textDecoration: 'none' }}>
								{' '}
								<img src={YoYoIcon} style={{ width: '43px', marginBottom: '-19px' }} />
								&nbsp;&nbsp;
								<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
									Open payment wallet
								</Button>
							</a>{' '}
						</Typography>
					</div>
				</>
			) : showRefuelMenu === 1 &&
			  RefuelStatus === 1 &&
			  menus.length === 0 &&
			  RefuelVendorType === 1 &&
			  appStores.showAppLoader === false ? (
				<>
					<div className={classes.Dimensions}>
						<img src={noResults} className={classes.accessDimensions} alt="noresults" />
						<Typography variant="subtitle2" style={{ marginTop: '20px' }}>
							No menus uploaded for this location
						</Typography>
						<Typography variant="caption">Please check again soon</Typography>
						<Typography className={classes.PaymentCaptionnew}>
							<a href={user.WebWioPay_Url} target="_blank" style={{ textDecoration: 'none' }}>
								{' '}
								<img src={WioPayIcon} style={{ marginBottom: '-19px', width: '42px' }} />
								<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
									Open payment wallet
								</Button>
							</a>{' '}
						</Typography>
					</div>
				</>
			) : (
				''
			)}

			{showRefuelMenu === 1 &&
			RefuelStatus === 1 &&
			RefuelVendorType === 2 &&
			menus.length !== 0 &&
			appStores.showAppLoader === false ? (
				<Typography className={classes.PaymentCaption}>
					<a href={user.WebYoYo_Url} target="_blank" style={{ textDecoration: 'none' }}>
						{' '}
						<img src={YoYoIcon} style={{ marginLeft: '-20px', width: '43px', marginBottom: '-19px' }} />
						&nbsp;
						<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
							Open payment wallet
						</Button>
					</a>{' '}
				</Typography>
			) : showRefuelMenu === 1 &&
			  RefuelStatus === 1 &&
			  RefuelVendorType === 1 &&
			  menus.length !== 0 &&
			  appStores.showAppLoader === false ? (
				<Typography className={classes.PaymentCaption}>
					<a href={user.WebWioPay_Url} target="_blank" style={{ textDecoration: 'none' }}>
						{' '}
						<img src={WioPayIcon} style={{ marginBottom: '-19px', width: '42px' }} />
						<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
							Open payment wallet
						</Button>
					</a>{' '}
				</Typography>
			) : showRefuelMenu === 1 &&
			  RefuelStatus === 1 &&
			  RefuelVendorType === 3 &&
			  menus.length !== 0 &&
			  appStores.showAppLoader === false ? (
				<Typography className={classes.PaymentCaption}>
					<a href={user.WebBoxed_Url} target="_blank" style={{ textDecoration: 'none' }}>
						{' '}
						<img src={BoxedIcon} style={{ marginBottom: '-19px', width: '42px' }} />
						&nbsp;&nbsp;
						<Button variant="contained" color="primary" style={{ outline: 'none!important' }}>
							Open payment wallet
						</Button>
					</a>{' '}
				</Typography>
			) : (
				''
			)}

			{/* popup code  started */}
			{/* { isOpen ? (
          <dialog
           style={{ boxShadow: '0 8px 6px -6px black',
            overflow:'scroll',
            position: 'absolute',
            left: '20%',
            width:'60%',
            height:'80%',
            top: '15%'}}
            open
            onClick={closeModal}
          >
             <p></p>
            
             
            <img
              src={imagnew}  
              alt=""
              width="100%"
              
            />
             <button onClick={closeModal} style={{float:'right'}}>Ok</button>
          </dialog>
        ):''} */}
			{/* popup code closedddd  */}

			{open ? (
				<div>
					<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={classes.test}>
						<DialogTitle id="customized-dialog-title">
							<h2
								style={{
									textAlign: 'left',
									font: 'normal normal normal  Roboto',
									letterSpacing: '0px',
									color: '#172A3F',
									opacity: '1',
									fontWeight: 400,
									fontSize: '1.0rem',
									lineHeight: '0px',
								}}
							>
								{Disptitle}
							</h2>
						</DialogTitle>
						<DialogContent dividers>
							{mtype === 'Image' ? (
								<img src={imagnew} alt="" width="100%" style={{ marginTop: '-16px' }} />
							) : (
								<iframe
									src={imagnew}
									//id={mid}
									scrolling="no"
									style={{ width: '600px', height: '400px', marginTop: '-16px', border: 0 }}
								></iframe>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Ok
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			) : (
				''
			)}
		</div>
	);
});

export default RefuelMenuList;
