import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { Grid, Button, Divider, Typography, Paper, TextField, makeStyles, FormHelperText } from '@material-ui/core';
import TimeController from './TimeController';
import { string as yupString, object as yupObject } from 'yup';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { ISiteInfoForm, ISiteInfoData, ContainerID, IFileUpload, ISiteObject } from '../../types';
import { stores } from '../../store';
import { compareAsc } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FileUpload from '../../components/FileUpload';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import error from '../../Validation/ErrorMessage';
const yup = require('yup');

const phoneRegExp = /^((\+44\s?\d{4}|\(?\d{5}\)?)\s?\d{6})|((\+44\s?|0)7\d{3}\s?\d{6})$/;
const latitudeRegex = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
const longitudeRegex = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

const fieldSchema = yupObject().shape({
	address1: yupString().required('Address line 1 is required'),
	address2: yupString().required('Address line 2 is required'),
	postCode: yupString().required('Postcode is required'),
	longitude: yupString()
		.required('longitude is required')
		.matches(longitudeRegex, error.validNumberLongitude),
	latitude: yupString()
		.required('latitude is required')
		.matches(latitudeRegex, error.validNumberLatitude),
	contactPhone: yupString()
		.required('Contact phone is required')
		.matches(phoneRegExp, error.validNumber)
		.length(13),
	sitePhoto: yupString().required('Site photo is required'),
	secondaryPhoto: yupString().notRequired(),
});

const EditSiteInfo = observer(() => {
	const classes = useStyles();
	const sites = React.useContext(stores.context.site);
	const user = React.useContext(stores.context.user);
	const blob = React.useContext(stores.context.blob);
	const notifications = React.useContext(stores.context.notifications);
	const appStores = React.useContext(stores.context.appStores);
	let values: ISiteInfoForm = useLocalStore(() => ({
		address1: '',
		address2: '',
		address3: '',
		address4: '',
		postCode: '',
		contactPhone: '',
		startTime: new Date(),
		endTime: new Date(),
		errors: '',
		sitePhotoUrl: '',
		siteSecondaryPhotoUrl: '',
		token: '',
		isSiteInfoLoad: true,
		siteId: user.currentSiteId,
		sitePhoto: '',
		secondaryPhoto: '',
		longitude: '',
		latitude: '',
	}));

	const initialFileState = {
		name: '',
		format: '',
		extension: '',
		object: '',
		error: false,
	};
	const [sitePhoto, setSitePhoto] = React.useState<IFileUpload>(initialFileState);
	const [secondaryPhoto, setSecondaryPhoto] = React.useState<IFileUpload>(initialFileState);

	const methods = useForm<ISiteInfoForm>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, reset, formState, triggerValidation, setValue } = methods;
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [siteVal, setSite] = React.useState(user.currentSiteId);
	const [hasError, setHasError] = React.useState(false);
	const [selectedStartTime, setSelectedStartTime] = React.useState<Date>(new Date());
	const [selectedEndTime, setSelectedEndTime] = React.useState<Date>(new Date());
	const [startTimeError, setStartTimeError] = React.useState('');
	const [endTimeError, setEndTimeError] = React.useState('');
	const [editSiteInfo, setEditSiteInfo] = React.useState(0);
	appStores.showAppLoader = user.permissions === null ? true : false;
	const getAndSetSiteInfo = (siteId: string) => {
		if (values.isSiteInfoLoad && siteId) {
			values.isSiteInfoLoad = false;
			values.siteId = siteId;

			sites.fetchSiteInfo(siteId).then(() => {
				values.address1 =
					sites.siteInfo.address1 && sites.siteInfo.address1 !== 'undefined' ? sites.siteInfo.address1 : '';
				values.address2 = sites.siteInfo.address2 ? sites.siteInfo.address2 : '';
				values.address3 = sites.siteInfo.address3 ? sites.siteInfo.address3 : '';
				values.address4 = sites.siteInfo.address4 ? sites.siteInfo.address4 : '';
				values.postCode = sites.siteInfo.postCode ? sites.siteInfo.postCode : '';
				values.contactPhone = sites.siteInfo.phone ? sites.siteInfo.phone : '';
				values.longitude = sites.siteInfo.longitude ? sites.siteInfo.longitude : '';
				values.latitude = sites.siteInfo.latitude ? sites.siteInfo.latitude : '';
				values.sitePhotoUrl = sites.siteInfo.primaryURL ? sites.siteInfo.primaryURL : '';
				values.siteSecondaryPhotoUrl = sites.siteInfo.secondaryURL ? sites.siteInfo.secondaryURL : '';

				let openingStartTime = sites.siteInfo.openTime ? sites.siteInfo.openTime.split(':') : '00:00';
				let openingEndTime = sites.siteInfo.closeTime ? sites.siteInfo.closeTime.split(':') : '00:00';
				let currentStartDate: Date = new Date();
				currentStartDate.setHours(Number(openingStartTime[0]));
				currentStartDate.setMinutes(Number(openingStartTime[1]));
				let currentEndDate: Date = new Date();
				currentEndDate.setHours(Number(openingEndTime[0]));
				currentEndDate.setMinutes(Number(openingEndTime[1]));
				setSelectedStartTime(currentStartDate);
				setSelectedEndTime(currentEndDate);
				values.startTime = currentStartDate;
				values.endTime = currentEndDate;

				if (sites.siteInfo.primaryURL) {
					// setValue('sitePhoto', sites.siteInfo.primaryURL);
					setTimeout(() => {
						setValue('sitePhoto', sites.siteInfo.primaryURL);
						triggerValidation();
					}, 3000);
				}
				triggerValidation();
			});
		}
	};
	React.useEffect(() => {
		if (
			user.permissions &&
			user.permissions.Modules.find((m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8')
		) {
			const spacesIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '6846202B-42E2-4FD0-B81D-EE71FC6D2DE8',
			);
			if (user.permissions.Modules[spacesIndex] && user.permissions.Modules[spacesIndex].Read) {
				if (
					user.permissions.Modules[spacesIndex].Create &&
					user.permissions.Modules[spacesIndex].Update &&
					user.permissions.Modules[spacesIndex].Delete
				) {
					setEditSiteInfo(1);
				}
			} else {
				setEditSiteInfo(0);
			}
		}
	}, [user.permissions]);
	React.useEffect(() => {
		const inactiveLocations = sites.siteList.filter(item => item.LocationStatus === 'INACTIVE');
		if (inactiveLocations.find(item => item.Id === user.currentSiteId)) {
			setHasError(true);
		} else {
			setHasError(false);
		}
	}, [sites.siteList, user.currentSiteId]);
	const handleStartTime = (time: Date) => {
		setSelectedStartTime(time);
		time ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setEndTimeError('');
		values.startTime = time;
		let schema = yup.date().test('startTime', 'Opens time should be less than Closes time', (value: Date) => {
			return compareAsc(value, selectedEndTime) <= 0;
		});
		schema
			.validate(time)
			.then(() => {
				setStartTimeError('');
			})
			.catch((err: any) => {
				setStartTimeError(err.message);
			});
	};
	const handleEndTime = (time: Date) => {
		setSelectedEndTime(time);
		setStartTimeError('');
		time ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		values.endTime = time;
		let schema = yup.date().test('endTime', 'Close time should be greater than Opens time', (value: Date) => {
			return compareAsc(value, selectedStartTime) >= 0;
		});
		schema
			.validate(time)
			.then(() => {
				setEndTimeError('');
			})
			.catch((err: any) => {
				setEndTimeError(err.message);
			});
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		values[name] = value;

		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
	};

	const getToken = async () => {
		if (!values.token) {
			const blobToken = await blob.blobToken();
			values.token = blobToken.uploadToken;
		}
		return values.token;
	};
	const uploadFile = async (fileData: IFileUpload) => {
		const params = {
			Token: values.token,
			Container: ContainerID.Site,
			Data: fileData.object,
			ContentType: fileData.format,
			Extension: fileData.extension,
		};
		const response = await blob.blobImage(params);

		if (response.url) {
			return response.url;
		} else {
			notifications.addNotification('Something went wrong, Please try again', 'error');
			return '';
		}
	};
	const saveSiteInfo = async (event: React.SyntheticEvent) => {
		const isValidForm = await triggerValidation();
		localStorage.removeItem('CMValue');
		if (!isValidForm || !formState.isValid || sitePhoto.error || secondaryPhoto.error) return;

		if (!values.token) {
			values.token = await getToken();
		}

		if (sitePhoto.name && !sitePhoto.error) {
			values.sitePhotoUrl = await uploadFile(sitePhoto);
		}

		if (secondaryPhoto.name && !secondaryPhoto.error) {
			values.siteSecondaryPhotoUrl = await uploadFile(secondaryPhoto);
		}

		const siteInfoDetail: ISiteInfoData = {
			Id: sites.siteInfo.Id,
			address1: values.address1,
			address2: values.address2,
			address3: values.address3,
			address4: values.address4,
			postcode: values.postCode,
			phone: values.contactPhone,

			primaryURL: values.sitePhotoUrl,
			secondaryURL: values.siteSecondaryPhotoUrl,
			longitude: values.longitude,
			latitude: values.latitude,
			siteId: siteVal || sites.siteId || values.siteId,
			openTime: getOpeningTimes(values.startTime.getHours()) + ':' + getOpeningTimes(values.startTime.getMinutes()),
			closeTime: getOpeningTimes(values.endTime.getHours()) + ':' + getOpeningTimes(values.endTime.getMinutes()),
			UploadToken: values.token,
		};

		sites.saveSiteInfo(siteInfoDetail).then(response => {
			if (response.Success) {
				notifications.addNotification('Site Information saved, thank you', 'success');
			} else {
				notifications.addNotification('Something went wrong , Please try again', 'error');
			}
		});
	};
	const getOpeningTimes = (value: number) => {
		return (value < 10 ? '0' : '') + value;
	};
	const resetSiteInfoDetails = () => {
		setSitePhoto(initialFileState);
		setSecondaryPhoto(initialFileState);

		values.isSiteInfoLoad = true;
		reset();
	};
	useEffect(() => {
		setSite(user.currentSiteId);
	}, [user.currentSiteId]);

	getAndSetSiteInfo(user.currentSiteId);

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				{editSiteInfo === 1 && appStores.showAppLoader === false ? (
					<Grid container alignItems="center">
						<Grid item md={appStores.drawerOpen ? 5 : 4}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									Edit Site Information
								</Typography>
								<Divider />
								<Grid className={classes.gridStyle}>
									<form>
										<Autocomplete
											options={(sitedatanew ? df : sites.siteList).filter(
												(item: any) => item.LocationStatus !== 'INACTIVE',
											)}
											getOptionLabel={option => option.Name}
											size="small"
											className={classes.Autocomplete}
											id="size-small-standard"
											noOptionsText="Loading..."
											defaultValue={sitedatanew != '' ? df[siteName] : sites.siteList[siteName]}
											autoComplete
											onChange={(event: any, newValue: any) => {
												const inactiveLocations = (sitedatanew ? df : sites.siteList).filter(
													(item: any) => item.LocationStatus === 'INACTIVE',
												);
												if (inactiveLocations.find((item: any) => item.Id === newValue.Id)) {
													setHasError(true);
												} else {
													setHasError(false);
												}
												newValue.Id !== ''
													? localStorage.setItem('CMValue', 'true')
													: localStorage.setItem('CMValue', 'false');
												localStorage.setItem('URL', window.location.pathname);
												setSite(newValue.Id);
											}}
											renderInput={params => (
												<TextField {...params} label="Select Site" variant="filled" margin="normal" />
											)}
										/>
										{hasError === true ? (
											<FormHelperText style={{ margin: 'auto' }}>
												This site is inactive, please select another site
											</FormHelperText>
										) : (
											''
										)}
										<TextField
											label="Address line 1"
											name="address1"
											variant="filled"
											margin="normal"
											value={values.address1}
											onChange={handleInputChange}
											fullWidth
											error={Boolean(errors.address1)}
											helperText={errors.address1 ? errors.address1.message : ''}
											inputRef={e => {
												register(e);
											}}
										/>
										<TextField
											label="Address line 2"
											name="address2"
											variant="filled"
											value={values.address2}
											margin="normal"
											fullWidth
											error={Boolean(errors.address2)}
											helperText={errors.address2 ? errors.address2.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>
										<TextField
											label="Address line 3"
											name="address3"
											variant="filled"
											margin="normal"
											fullWidth
											value={values.address3}
											error={Boolean(errors.address3)}
											helperText={errors.address3 ? errors.address3.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>
										<TextField
											label="Address line 4"
											name="address4"
											variant="filled"
											margin="normal"
											fullWidth
											value={values.address4}
											error={Boolean(errors.address4)}
											helperText={errors.address4 ? errors.address4.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>
										<TextField
											label="Postcode"
											name="postCode"
											variant="filled"
											margin="normal"
											value={values.postCode}
											fullWidth
											error={Boolean(errors.postCode)}
											helperText={errors.postCode ? errors.postCode.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>

										<TextField
											label="Latitude"
											name="latitude"
											variant="filled"
											margin="normal"
											value={values.latitude}
											fullWidth
											error={Boolean(errors.latitude)}
											helperText={errors.latitude ? errors.latitude.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>
										<TextField
											label="Longitude"
											name="longitude"
											variant="filled"
											margin="normal"
											value={values.longitude}
											fullWidth
											error={Boolean(errors.longitude)}
											helperText={errors.longitude ? errors.longitude.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>

										<TimeController
											onStartTime={handleStartTime || '00:00'}
											onEndTime={handleEndTime || '00:00'}
											selectedStartTime={selectedStartTime}
											startTimeError={startTimeError}
											endTimeError={endTimeError}
											selectedEndTime={selectedEndTime}
											ref={register}
										/>
										<TextField
											label="Contact phone"
											name="contactPhone"
											variant="filled"
											margin="normal"
											value={values.contactPhone}
											fullWidth
											error={Boolean(errors.contactPhone)}
											helperText={errors.contactPhone ? errors.contactPhone.message : ''}
											inputRef={e => {
												register(e);
											}}
											onChange={handleInputChange}
										/>
									</form>
									<Grid item xs={12}>
										<FileUpload
											methods={methods}
											file={sitePhoto}
											setFile={setSitePhoto}
											fileInputName="sitePhoto"
											fileInputLabel="Add Site photo"
											fileInputErrorMessage="Please select appropriate format"
											tooltip="Landscape images are best as images are cropped to a 2:1 ratio"
										/>
									</Grid>
									<Grid item xs={12}>
										<FileUpload
											methods={methods}
											file={secondaryPhoto}
											setFile={setSecondaryPhoto}
											fileInputName="secondaryPhoto"
											fileInputLabel="Add Secondary photo"
											fileInputErrorMessage="Please select appropriate format"
											tooltip="Landscape images are best as images are cropped to a 2:1 ratio"
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} container justify="flex-end" alignItems="center" className={classes.alignButton}>
									<Button
										color="primary"
										onClick={() => {
											setSite(user.currentSiteId);
											resetSiteInfoDetails();
											getAndSetSiteInfo(user.currentSiteId);
											localStorage.removeItem('CMValue');
										}}
									>
										Cancel
									</Button>

									<Button
										color="primary"
										disabled={!formState.isValid || !!sitePhoto.error || !!secondaryPhoto.error}
										onClick={saveSiteInfo}
									>
										Save
									</Button>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : editSiteInfo === 0 && appStores.showAppLoader === false ? (
					<>
						<img src={accessDeniedIcon} className={classes.accessDimensions} alt="" />
						<div className={classes.textMargin}>
							<Typography variant="subtitle2">You do not have permission to access this area</Typography>
							<Typography variant="caption">Please contact your manager to request this access</Typography>
						</div>
					</>
				) : (
					''
				)}
			</main>
		</>
	);
});
export default EditSiteInfo;

const useStyles = makeStyles(theme => ({
	appBarSpacer: {
		height: '64px',
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			// marginLeft: '13px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	main: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '80%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	InputLabel: {
		paddingLeft: theme.spacing(2),
	},
	accessDimensions: {
		width: '60%',
		height: '60%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},
}));
