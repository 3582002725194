import React, { useState, useContext } from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react-lite';
import { Icon } from '@mitie/material-ui-extensions';
import {
	Typography,
	makeStyles,
	Grid,
	Paper,
	Button,
	TextField,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Container,
} from '@material-ui/core';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { string as yupString, object as yupObject } from 'yup';
import classnames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { autorun } from 'mobx';
import { ISiteObject } from '../types';

import { useForm } from 'react-hook-form';
import { stores } from '../store';
import { IJobRequestForm, ContainerID } from '../types';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	formSelect: {
		width: '100%',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 70px)',
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		textAlign: 'left',
		margin: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(8, 1, 1, 1),
			overflow: 'auto',
		},
	},
	paper: {
		color: theme.palette.text.primary,
	},
	alignButton: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	appBarSpacer: {
		height: '64px',
	},
	gridStyle: {
		padding: theme.spacing(0, 10, 4, 3),
	},
	text: {
		padding: theme.spacing(2, 2),
		textAlign: 'left',
	},
	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 1),
	},
	InputField: {
		background: '#f9fbfd',
		margin: theme.spacing(1),
	},
	uploader: {
		display: 'none',
	},
	errorInputText: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#BD0810',
			paddingTop: 10,
			paddingBottom: 10,
		},
	},
	grid: {
		paddingRight: '60px',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			// marginLeft: '13px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
		},
		'& .MuiInputBase-input': {
			// marginLeft: '12px',
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	multilineText: {
		'& .MuiFilledInput-multiline': {
			backgroundColor: 'rgb(255 255 255)',
		},
	},
}));

const fieldSchema = yupObject().shape({
	issue: yupString().required('Describe the issue'),
});

const JobRequestBack: React.FunctionComponent = observer(() => {
	const classes = useStyles();
	const user = useContext(stores.context.user);

	const sites: any = React.useContext(stores.context.site);
	//const feedback = useContext(stores.context.feedback);
	//const notifications = useContext(stores.context.notifications);
	const blob = useContext(stores.context.blob);
	const feedback = useContext(stores.context.feedback);
	const notifications = useContext(stores.context.notifications);
	const [isImageUpload, setIsImageUpload] = useState(false);
	const [uploadImageName, setUploadImageName] = useState('');
	//const [location, setLocation] = useState<SelectTreeDataNode>();
	const [categoryError, setCategoryError] = React.useState(false);
	const [priorityError, setPriorityError] = React.useState(false);
	const [category, setCategory] = React.useState('');
	const [priority, setPriority] = React.useState('');
	const [priorityDefault, setPriorityDefault] = React.useState('');

	const [siteLocation, setSiteLocation] = React.useState<any>();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const methods = useForm<IJobRequestForm>({
		validationSchema: fieldSchema,
		mode: 'onChange',
	});
	const { errors, register, reset, formState } = methods;
	let feedbackOptions: IJobRequestForm = useLocalStore(() => ({
		issue: '',
		email: '',
		subject: '',
		details: '',
		location: '',
		category: '',
		fileObject: '',
		feedbackId: '',
		fileFormat: '',
		fileError: false,
		token: '',
		container: 0,
		createdDate: new Date(),
		currentDate: new Date(),
		contentType: '',
		extension: '',
		Id: '',
		Name: '',
		site: '',
		building: '',
		key: '',
		title: '',
		selectable: true,
		criticallity: '',
	}));
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;

	//let dff:ISiteObject[] = df.filter(df=>(df.ClientId=='43a5f195-481e-41cd-9de4-549822000a04' || df.ClientId=='fab944c8-c796-41d6-9e8d-1c65caf7d04c'));
	const siteName: any = df.findIndex((nam: any) => nam.Id == user.currentSiteId);
	const [siteVal, setSite] = React.useState('');
	const [siteKey, setSiteKey] = React.useState(0);
	const siteNamenew: any = df.findIndex((nam: any) => nam.Id == siteVal);
	const [siteNewLocation, setSiteNewLocation] = React.useState<any>();
	const [helpDeskPhno, setHelpDeskPhno] = React.useState('');
	const [helpDeskEmail, setHelpDeskEmail] = React.useState('');
	const [errmsgnew, setError] = React.useState('');
	const [values, setValues] = useState({
		location: '',
		issue: '',
		category: '',
	});
	const clearState = () => {
		reset();
		setCategory('');
		setPriority('');
		setUploadImageName('');
		localStorage.removeItem('CMValue');
	};
	const handleClose = () => {
		setDialogOpen(false);
	};
	const handleFeedbackUpload = (event: React.ChangeEvent<any>) => {
		const uploadedFile =
			event && event.target && event.target.files && event.target.files[0] ? event.target.files[0] : false;
		uploadedFile !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		feedbackOptions.fileObject = '';
		feedbackOptions.fileError = false;
		setUploadImageName('');

		if (uploadedFile == false) {
			feedbackOptions.token = '';
		}

		isImageUpload == false ? setIsImageUpload(false) : setIsImageUpload(true);

		if (uploadedFile && (uploadedFile.type === 'image/png' || uploadedFile.type === 'image/jpeg')) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(uploadedFile);
			feedbackOptions.fileFormat = uploadedFile.type === 'image/png' ? 'png' : 'jpg';
			feedbackOptions.extension = feedbackOptions.fileFormat;
			fileReader.onload = async (e: any) => {
				feedbackOptions.fileObject = e.target.result.replace('data:' + uploadedFile.type + ';base64,', '');
				feedbackOptions.container = ContainerID.Ask;
				setUploadImageName(uploadedFile.name);
				feedbackOptions.contentType = uploadedFile.type;
				const token = await blob.blobToken();
				feedbackOptions.token = token.uploadToken;
				let postData = {
					Token: token.uploadToken,
					Container: feedbackOptions.container,
					Data: feedbackOptions.fileObject,
					ContentType: feedbackOptions.contentType,
					Extension: feedbackOptions.extension,
				};
				const response = await blob.blobImage(postData);

				if (response) {
					feedbackOptions.fileError = false;
				} else {
					feedbackOptions.fileError = true;
				}
				setIsImageUpload(true);
			};
		} else {
			feedbackOptions.fileError = true;
			setError('Please select jpg,png,jpeg types');
		}
	};

	const PriorErrorStat = () => {
		if (priority === '') {
			setPriorityError(true);
		}
	};

	const CateErrorStat = () => {
		if (category === '') {
			setCategoryError(true);
		}
	};
	const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCategory(event.target.value);
		setCategoryError(false);
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
	};
	const handlePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === 'Important') {
			setPriorityDefault('Important');
		}
		if (event.target.value === 'Routine') {
			setPriorityDefault('Routine');
		}
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		// if (event.target.value === 'Urgent' ) {

		// 	setDialogOpen(true);
		// 	setPriorityDefault('Routine');

		// }
		if (event.target.value === 'Urgent' && priorityDefault == 'Important') {
			setDialogOpen(true);
			setPriority('Important');
		} else if (event.target.value === 'Urgent' && priorityDefault == 'Routine') {
			setDialogOpen(true);
			setPriority('Routine');
		} else if (event.target.value === 'Urgent' && priorityDefault == '') {
			setDialogOpen(true);
			setPriority('');
		} else {
			setPriority(event.target.value);
		}
		setPriorityError(false);
	};

	const Popup = (siteId: any) => {
		feedbackOptions.site = siteId;
		feedbackOptions.email = user.userEmail != '' ? user.userEmail : user.username;
		feedback.FetchFormData(feedbackOptions).then(response => {
			setHelpDeskPhno(response.EntityDetails.HelpDeskPhoneNumber);
			setHelpDeskEmail(response.EntityDetails.HelpdeskEmail);
		});
	};
	const submitFeedBackForm = async (event: React.SyntheticEvent) => {
		localStorage.removeItem('CMValue');
		if (category === '') {
			setCategoryError(true);
		}
		if (priority === '') {
			setPriorityError(true);
		}
		if (category !== '' && priority !== '') {
			const { location, issue } = values;
			const siteName = df.findIndex((nam: any) => nam.Id === (siteVal == '' ? user.currentSiteId : siteVal));
			const locationName = siteName !== -1 ? df[siteName].Name : '';
			feedbackOptions.location = location;
			feedbackOptions.category = category;
			feedbackOptions.issue = issue;
			feedbackOptions.criticallity = priority;
			feedbackOptions.site = siteVal == '' ? user.currentSiteId : siteVal;
			feedbackOptions.building = locationName;
			feedbackOptions.email = helpDeskEmail != '' ? helpDeskEmail : user.fetchdetails.HelpdeskEmail;
			//console.log(JSON.stringify(feedbackOptions));
			feedback.submitJobRequestForm(feedbackOptions).then(response => {
				//alert('hii');
				//alert(uploadImageName);
				if (!response) {
					notifications.addNotification('Request sent, thank you', 'success');
					setSiteKey(siteKey + 1);
					clearState();
					setUploadImageName('');
				} else {
					notifications.addNotification('Something went wrong, Please try again', 'error');
				}
			});
		}
	};

	React.useEffect(
		() =>
			autorun(() => {
				if (user.currentSiteId) {
					setSiteLocation(df[siteName]);
					setSiteNewLocation(df[siteNamenew]);
					setSiteKey(siteKey + 1);
					user.getHelpDeskInfo();
				}
			}),
		[user.currentSiteId],
	);
	// React.useEffect(()=>{
	// 	user.getHelpDeskInfo();
	// });

	const handleInputChange = (event: React.ChangeEvent<any>) => {
		const { name, value } = event.target;
		event.target.value !== '' ? localStorage.setItem('CMValue', 'true') : localStorage.setItem('CMValue', 'false');
		localStorage.setItem('URL', window.location.pathname);
		setValues({ ...values, [name]: value });
		if (!errors.issue && values.issue.length > 0 && uploadImageName.length > 0) {
			setIsImageUpload(true);
		}
	};
	return useObserver(() => (
		<>
			<Container className={classes.container}>
				<main className={classes.content}>
					<Grid container alignItems="center">
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Typography variant="h5" className={classes.text}>
									Job Request Form
								</Typography>
								<Divider />
								<form>
									<Grid className={classes.gridStyle}>
										<div key={siteKey}>
											<Autocomplete
												options={sitedatanew ? df : sites.siteList}
												getOptionLabel={option => option.Name}
												size="small"
												className={classes.Autocomplete}
												id="size-small-standard"
												noOptionsText="Loading..."
												defaultValue={sitedatanew ? df[siteName] : siteLocation}
												value={siteNewLocation}
												autoComplete
												onChange={(event: any, newValue: any) => {
													newValue !== ''
														? localStorage.setItem('CMValue', 'true')
														: localStorage.setItem('CMValue', 'false');
													localStorage.setItem('URL', window.location.pathname);
													if (newValue.Id == null) {
														setSite(user.currentSiteId);
													} else {
														setSite(newValue.Id);
														Popup(newValue.Id);
													}
												}}
												renderInput={params => <TextField {...params} label="Site" variant="filled" margin="normal" />}
											/>
										</div>
										<FormControl variant="filled" fullWidth className={classes.formControl} error={categoryError}>
											<InputLabel>Category</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												name="category"
												className={classes.formSelect}
												onChange={handleCategory}
												value={category}
												onOpen={CateErrorStat}
											>
												<MenuItem value={1}>Cleaning</MenuItem>
												<MenuItem value={2}>Landscaping</MenuItem>
												<MenuItem value={3}>Maintenance</MenuItem>
												<MenuItem value={4}>Other</MenuItem>
												<MenuItem value={5}>Parking</MenuItem>
												<MenuItem value={6}>Pest Control</MenuItem>
												<MenuItem value={7}>Security</MenuItem>
												<MenuItem value={8}>Waste</MenuItem>
											</Select>

											{categoryError === true ? (
												<FormHelperText style={{ marginTop: '5px', marginLeft: '12px' }}>
													Nothing selected
												</FormHelperText>
											) : (
												''
											)}
										</FormControl>
										<FormControl variant="filled" fullWidth className={classes.formControl} error={priorityError}>
											<InputLabel>Priority</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												name="priority"
												className={classes.formSelect}
												onChange={handlePriority}
												value={priority}
												onOpen={PriorErrorStat}
											>
												<MenuItem value={'Routine'}>Routine</MenuItem>
												<MenuItem value={'Important'}>Important</MenuItem>
												<MenuItem value={'Urgent'}>Urgent</MenuItem>
											</Select>

											{priorityError === true ? (
												<FormHelperText style={{ marginTop: '5px', marginLeft: '12px' }}>
													Nothing selected
												</FormHelperText>
											) : (
												''
											)}
										</FormControl>
										<TextField
											label="Location"
											variant="filled"
											name="location"
											fullWidth
											margin="normal"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
										/>
										<TextField
											label="Describe the issue"
											name="issue"
											fullWidth
											variant="filled"
											onChange={handleInputChange}
											inputRef={e => {
												register(e);
											}}
											helperText={errors.issue ? errors.issue.message : ''}
											error={errors.issue ? true : false}
											FormHelperTextProps={{ className: classes.errorInputText }}
											multiline
											rows="4"
											className={classes.multilineText}
										/>
										<input
											accept="image/*"
											id="FeedbackUploader"
											onChange={handleFeedbackUpload}
											type="file"
											className={classes.uploader}
											disabled={feedbackOptions.feedbackId ? true : false}
										/>
										<label htmlFor="FeedbackUploader" className={classnames(classes.fileUploadInput)}>
											<Typography
												variant="subtitle1"
												//color={feedbackOptions.fileError ? 'error' : 'textSecondary'}
												color={'textSecondary'}
												paragraph={true}
											>
												{uploadImageName ? uploadImageName : <span>Add image</span>}
											</Typography>
											<Icon icon={faImage} />
										</label>
										{errmsgnew !== '' ? (
											<FormHelperText style={{ marginTop: '5px', marginLeft: '12px', color: '#BD0810' }}>
												{errmsgnew}
											</FormHelperText>
										) : (
											''
										)}
									</Grid>
									<Grid item xs={12} container justify="flex-end" className={classes.alignButton}>
										<Button
											color="primary"
											disabled={
												!formState.isValid || priority === '' || category === '' || priorityDefault === 'Urgent'
											}
											onClick={clearState}
										>
											Clear
										</Button>
										<Button
											color="primary"
											//disabled={ !isImageUpload || !formState.isValid || priority === ''}
											disabled={
												!formState.isValid || priority === '' || category === '' || priorityDefault === 'Urgent'
											}
											onClick={submitFeedBackForm}
										>
											Submit
										</Button>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						open={dialogOpen}
						onClose={() => handleClose()}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{'Job priority'}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{helpDeskPhno != '' || user.fetchdetails.HelpDeskPhoneNumber != null ? (
									<>
										This request is urgent, please call our Helpdesk on{' '}
										{helpDeskPhno != '' ? helpDeskPhno : user.fetchdetails.HelpDeskPhoneNumber} who will be able to
										respond immediately.
									</>
								) : (
									<>This request is urgent, please call our Helpdesk who will be able to respond immediately.</>
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								color="primary"
								onClick={() => {
									setDialogOpen(false);
								}}
							>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
				</main>
			</Container>
		</>
	));
});

export default JobRequestBack;
