import * as React from 'react';
import { useObserver, observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icon from './Icon';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	Backdrop,
	CircularProgress,
	ListItemSecondaryAction,
	makeStyles,
	Grid,
	Paper,
	Typography,
	Link,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	TextField,
	Breadcrumbs,
} from '@material-ui/core';
import Slider from 'react-slick';
import noResults from '../assets/images/NoRoomPhoto@2x.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { stores } from '../store';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Moment from 'react-moment';

const features =
	process.env.REACT_APP_ENV == 'PROD'
		? {
				'1': 'Display',
				'3': 'Audio Conference',
				'2': 'Video Conference',
				'18': 'Flip Chart',
				'16': 'Whiteboard',
				'19': 'Projector',
		  }
		: {
				'1': 'Display',
				'3': 'Audio Conference',
				'2': 'Video Conference',
				'11': 'FlipChart',
				'13': 'Whiteboard',
				'12': 'Projector',
		  };

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(0),
		height: 'calc(100vh - 98px)',
		margin: '0 !important',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	Autocomplete: {
		'& .MuiInputLabel-root': {
			zIndex: 999,
			background: '#f9fbfd',
			marginLeft: '23px',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '86%',
			marginLeft: '23px',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-inputRoot:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiInputBase-input:hover': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
	},
	MonthSelector: {
		marginLeft: '15px',
	},
	Autocompletenew: {
		'& .MuiInputLabel-root': {
			zIndex: 999,

			background: '#f9fbfd',
			marginLeft: '21px',
		},
		'& .MuiAutocomplete-inputRoot': {
			background: '#f9fbfd',
			width: '86% !important',
			marginLeft: '23px',
		},
		'& .MuiInputBase-input': {
			background: '#f9fbfd',
			width: '70px !important',
		},
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-inputRoot:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiAutocomplete-input': {
			minWidth: '120% !important',
		},
		'& .MuiInputBase-input:hover': {
			backgroundColor: 'transparent !important',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: '#098BBD0A !important',
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			backgroundColor: '#098BBD0A !important',
		},
	},

	listtext: {
		'& .MuiListItemText-root': {
			marginTop: '0px !important',
			marginBottom: '1px !important',
		},
	},
	option: {
		'&[data-focus="true"]': {
			backgroundColor: '#f4f8fc',
		},

		'&[aria-selected="true"]': {
			backgroundColor: '#f4f8fc',
		},
	},

	sldstyle: {
		width: '100%',
	},

	headerMenuLinkActive: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '70%',
		padding: '9px',
		fontWeight: 'bold',
		paddingTop: theme.spacing(0.5),
		borderBottom: `${theme.palette.primary.main} 4px solid !important`,
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},

	headerNav: {
		width: '100%',
		marginBottom: '4px',
		'& .MuiBreadcrumbs-ol': {},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			margin: '-2px',
			width: '115px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li a': {
			cursor: 'pointer',
			width: '100%',
			textAlign: 'center',
			padding: '16px',
			margin: '-2px',
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
			cursor: 'pointer',
			textAlign: 'center',
			margin: '-2px',
		},
	},

	imageBlock: {
		width: '100%',
		maxHeight: '212px',
		display: 'block',
	},
	timebtn: {
		margin: '1px !important',
		minWidth: '0 !important',
		boxShadow: 'none !important',
		padding: '10px 4px !important',
		border: '1px solid rgba(9, 139, 189, 0.5)',
		marging: '1px !important',
		width: 'auto !important',
		color: '#098BBD',
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},

	timebtnnew: {
		margin: '1px !important',
		minWidth: '0 !important',
		boxShadow: 'none !important',
		padding: '10px 4px !important',
		border: '1px solid rgba(9, 139, 189, 0.5)',
		marging: '1px !important',
		width: 'auto !important',
		color: '#FFFFFF',
		'&.MuiButton-root.Mui-disabled': {
			color: '#FFFFFF !important',
			border: '1px solid #CFDBE5 !important',
			backgroundColor: '#CFDBE5 !important',
		},
	},

	paper: {
		display: 'flex',

		overflow: 'hidden',
		height: '267px',
		flexDirection: 'column',
		textAlign: 'left',
		'& .MuiInputBase-input': {
			background: 'none',
		},
	},
	leftPadding: {
		paddingLeft: '10px',
	},

	fileUploadIcon: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		'& > *': {
			position: 'absolute',
			top: '12px',
			left: '14px',
			color: theme.palette.background.paper,
		},
	},
	fileUploadInput: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `${theme.palette.grey[100]} 1px solid`,
		margin: theme.spacing(3, 0),
	},
	fileUploadInputError: {
		borderBottom: `${theme.palette.error.main} 1px solid`,
		color: theme.palette.error.main,
	},
	createCancelLink: {
		'& > *': {
			cursor: 'pointer',
			margin: theme.spacing(0, 0, 0, 2),
		},
	},
	disableLink: {
		color: theme.palette.grey[100],
		cursor: 'default',
	},
	accessDimensions: {
		width: '50%',
		height: '40%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	textMargin: {
		textAlign: 'center',
	},

	alignStyle: {
		padding: '0px 0px !important',
	},
	TextField: {},
	feedBadge: {
		'& span': {
			backgroundColor: '#357a38',
			padding: 'inherit',
			fontSize: '0.7rem',
			marginRight: '32px',
			transform: 'scale(1) translate(100%, -60%)',
		},
	},
	dateSlider: {
		width: '724px',
		/* Extra small devices (phones, 600px and down) */
		'@media only screen and (max-width: 600px)': {
			width: '222px',
		},
		/* Small devices (portrait tablets and large phones, 600px and up) */
		'@media only screen and (min-width: 600px)': {
			width: '272px',
		},
		/* Medium devices (landscape tablets, 768px and up) */
		'@media only screen and (min-width: 768px)': {
			width: '372px',
		},

		/* Large devices (laptops/desktops, 992px and up) */
		'@media only screen and (min-width: 992px)': {
			width: '456px',
		},

		/* Extra large devices (large laptops and desktops, 1200px and up) */
		'@media only screen and (min-width: 1200px)': {
			width: '573px',
		},
		'@media only screen and (min-width: 1300px)': {
			width: '674px',
		},
		'@media only screen and (min-width: 1350px)': {
			width: '724px',
		},
		'@media only screen and (min-width: 1500px)': {
			width: '824px',
		},
		'@media only screen and (min-width: 1650px)': {
			width: '922px',
		},
		'@media only screen and (min-width: 1800px)': {
			width: '1024px',
		},
	},
	timeSlider: {
		width: '724px',
		/* Extra small devices (phones, 600px and down) */
		'@media only screen and (max-width: 600px)': {
			width: '222px',
		},
		/* Small devices (portrait tablets and large phones, 600px and up) */
		'@media only screen and (min-width: 600px)': {
			width: '272px',
		},
		/* Medium devices (landscape tablets, 768px and up) */
		'@media only screen and (min-width: 768px)': {
			width: '372px',
		},

		/* Large devices (laptops/desktops, 992px and up) */
		'@media only screen and (min-width: 992px)': {
			width: '456px',
		},

		/* Extra large devices (large laptops and desktops, 1200px and up) */
		'@media only screen and (min-width: 1200px)': {
			width: '573px',
		},
		'@media only screen and (min-width: 1300px)': {
			width: '674px',
		},
		'@media only screen and (min-width: 1350px)': {
			width: '724px',
		},
		'@media only screen and (min-width: 1500px)': {
			width: '824px',
		},
		'@media only screen and (min-width: 1650px)': {
			width: '922px',
		},
		'@media only screen and (min-width: 1800px)': {
			width: '1024px',
		},
	},
	testnew: {
		'& .slick-prev, .slick-next': {
			height: '35px',
			width: '37px',
			background: 'grey',
		},
		'& .slick-slide, .slick-active': {
			width: 'auto',
			maxWidth: '50px',
		},
		'.slick-prev:before, .slick-next:before': {
			color: 'black !important',
		},
		'& .slick-list': {
			margin: '0 12px',
		},
		'& .slick-track': {
			marginLeft: '0 !important',
		},
	},
	disableButton: {
		'&.MuiButton-contained.Mui-disabled': {
			color: '#FFFFFF',
			border: '1px solid #CFDBE5',
			backgroundColor: '#CFDBE5',
		},
	},
	arrowLeft: {
		width: '40px',
		left: '-20px',
		lineHeight: '0',
		position: 'absolute',
		top: '50%',
		padding: '0',
		transform: 'translateY(-50%)',
		cursor: 'pointer',
		border: 'none',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
			width: 40,
			borderRadius: '50%',
			height: 40,
			left: '-37px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	arrowRight: {
		width: '40',
		right: '-26px',
		lineHeight: '0',
		position: 'absolute',
		top: '50%',
		padding: '0',
		transform: 'translateY(-50%)',
		cursor: 'pointer',
		border: 'none',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
			width: 40,
			borderRadius: '50%',
			right: '-42px',
			height: 40,
			lineHeight: '0',
			position: 'absolute',

			transform: 'translateY(-50%)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	Dimensions: {
		display: 'block',
		objectFit: 'contain',
		textAlign: 'center',
		margin: 'auto',
		top: '100px',
		left: '0',
		right: '0',
		bottom: '0',
	},
	listValue: {
		color: '#172A3F',
		letterSpacing: '0.01px',
		opacity: '1',
		font: 'normal normal normal 15px/19px Roboto',
		left: '182px',
	},

	listValuenew: {
		color: '#172A3F',
		letterSpacing: '0.01px',
		opacity: '1',
		font: 'normal normal normal 15px/19px Roboto',
		left: '182px',
		marginLeft: '18px !important',
	},
}));
const RoomInfo = observer(() => {
	const classes = useStyles();
	const ArrowLeft = (props: any) => <Icon {...props} className={classes.arrowLeft} icon={faChevronLeft} size="3rem" />;
	const ArrowRight = (props: any) => (
		<Icon {...props} className={classes.arrowRight} icon={faChevronRight} size="3rem" />
	);
	const settingsForTime = {
		arrows: true,
		infinite: false,
		draggable: true,
		swipeToSlide: true,
		slidesToShow: 14,
		slidesToScroll: 1,
		dots: false,
		speed: 100,
		initialSlide: 0,
		prevArrow: <ArrowLeft />,
		nextArrow: <ArrowRight />,
		responsive: [
			{
				breakpoint: 599,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 7,
				},
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 9,
				},
			},
			{
				breakpoint: 1299,
				settings: {
					slidesToShow: 11,
				},
			},
			{
				breakpoint: 1349,
				settings: {
					slidesToShow: 13,
				},
			},
			{
				breakpoint: 1499,
				settings: {
					slidesToShow: 14,
				},
			},
			{
				breakpoint: 1649,
				settings: {
					slidesToShow: 16,
				},
			},
			{
				breakpoint: 1799,
				settings: {
					slidesToShow: 18,
				},
			},
			{
				breakpoint: 2199,
				settings: {
					slidesToShow: 20,
				},
			},
		],
	};
	const settingsForDate = {
		arrows: true,
		infinite: false,
		draggable: true,
		swipeToSlide: true,
		slidesToShow: 14,
		slidesToScroll: 1,
		dots: false,
		speed: 100,
		initialSlide: 0,
		prevArrow: <ArrowLeft />,
		nextArrow: <ArrowRight />,
		responsive: [
			{
				breakpoint: 599,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 7,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 9,
				},
			},
			{
				breakpoint: 1299,
				settings: {
					slidesToShow: 11,
				},
			},
			{
				breakpoint: 1349,
				settings: {
					slidesToShow: 13,
				},
			},
			{
				breakpoint: 1499,
				settings: {
					slidesToShow: 14,
				},
			},
			{
				breakpoint: 1649,
				settings: {
					slidesToShow: 16,
				},
			},
			{
				breakpoint: 1799,
				settings: {
					slidesToShow: 18,
				},
			},
			{
				breakpoint: 2199,
				settings: {
					slidesToShow: 20,
				},
			},
		],
	};

	const { route } = React.useContext(stores.context.routes);
	const spaces = React.useContext(stores.context.spaces);

	const user = React.useContext(stores.context.user);
	const [BookingId, setBookingId] = React.useState<any>('');
	const [clientidfeatures, setClientId] = React.useState<any>();
	const [zoneSingleRecord, setZoneSingleRecord] = React.useState<any>();

	const [esmeDialogOpen, setEsmeDialogOpen] = React.useState(false);
	const [consecutiveTimeDialog, setConsecutiveTimeDialog] = React.useState(false);
	const [bookingavailable, setbookingavailable] = React.useState(false);
	const [bookingLoader, setBookingLoader] = React.useState(true);
	const [bookRoombuttonText, setBookRoombuttonText] = React.useState('');

	const [officeHoursdnmc, setofficeHoursdnmc] = React.useState<any>([]);

	const [buildingentities, setbuidlingentities] = React.useState<any>([]);

	const [buildingId, setBuildingId] = React.useState<any>(null);
	const [officeHoursData, setOfficeHoursData] = React.useState<any>([]);

	const [selectedDate, setSelectedDate] = React.useState({ id: 0, date: moment().format('YYYY-MM-DD') });
	const [newDataVariablemonth1, setnewDataVariablemonth] = React.useState<any>(
		moment(moment(selectedDate.date).format('YYYY/MM/DD')).format('MMMM'),
	);
	const [keyval, setkeyval] = React.useState<any>(0);

	const [noofdayscount, setnoofdays] = React.useState<any>(31);
	const [dateSlots, setDateSlots] = React.useState<any>([]);

	let updatedDate = moment().format('YYYY-MM-DD');
	React.useEffect(() => {
		updatedDate = selectedDate.date;
		const buildingData = buildingentities.filter((buildingInfo: any) =>
			buildingInfo.Entity_Id === route.params.zoneId ? buildingInfo : null,
		);
		let buildingNumber: any = '';

		if (buildingData.length > 0) {
			buildingNumber = buildingData[0].BuildingId;
			setBuildingId(buildingNumber);

			let hoursData = officeHoursdnmc.filter((officeHoursData: any) =>
				officeHoursData.buildingId === buildingNumber ? officeHoursData : null,
			);

			const apiCount: number = spaces.Availabledays.AllowBookingsBeforeXDays
				? spaces.Availabledays.AllowBookingsBeforeXDays
				: 62;

			let daysDiff = moment(hoursData[hoursData.length - 1].startDate, 'YYYY-MM-DD').diff(moment(), 'days');

			let endDay = hoursData[hoursData.length - 1].startDate;

			let remainingDays = apiCount - daysDiff;

			for (let i = 1; i <= remainingDays; i++) {
				let obj = {
					startDate: `${moment(endDay)
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					endDate: `${moment(endDay)
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					dayVisible: false,
					webOnly: false,
					startTime: '00:00:00',
					endTime: '24:00:00',
					buildingId: `${buildingNumber}`,
				};
				hoursData.push(obj);
			}

			for (let i = 0; i < daysDiff; i++) {
				let obj = {
					startDate: `${moment()
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					endDate: `${moment()
						.add(i, 'days')
						.format('YYYY-MM-DD')}T00:00:00`,
					dayVisible: false,
					webOnly: false,
					startTime: '00:00:00',
					endTime: '24:00:00',
					buildingId: `${buildingNumber}`,
				};

				const foundItem = hoursData.find((item: any) => {
					return moment(item.startDate, 'YYYY-MM-DD').isSame(moment(obj.startDate, 'YYYY-MM-DD'));
				});
				if (foundItem === undefined) {
					hoursData.splice(i, 0, obj);
				}
			}

			setOfficeHoursData(hoursData);

			const monthlyDays =
				hoursData &&
				hoursData.slice(0).filter((dateValues: any) => {
					let month = new Date(dateValues.startDate).toLocaleString('default', { month: 'long' });
					let year = new Date(dateValues.startDate).getFullYear();
					let newupdatedmonth = moment(selectedDate.date).format('MMMM');
					let newupdatedyear = moment(selectedDate.date).format('YYYY');
					return month === newupdatedmonth && year === Number(newupdatedyear) ? dateValues : null;
				});

			let jsonObject = monthlyDays.map((e: any) => JSON.stringify(e));
			let uniqueSet = new Set(jsonObject);
			let uniqueArray = Array.from(uniqueSet).map((e: any) => JSON.parse(e));
			setDateSlots(uniqueArray);
		}
	}, [noofdayscount, selectedDate]);

	const handleConsecutiveSlots = () => {
		setConsecutiveTimeDialog(false);
	};
	const handleAvailableclose = () => {
		setbookingavailable(false);
	};

	const daysCount: number = spaces.Availabledays.AllowBookingsBeforeXDays
		? spaces.Availabledays.AllowBookingsBeforeXDays
		: 62;
	let monthsList: any = [];

	let dateStart = moment(moment().format('L'));
	let dateEnd = moment(
		moment()
			.add(daysCount, 'days')
			.format('L'),
	);

	while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
		monthsList.push(dateStart.format('MMMM YYYY'));
		dateStart.add(1, 'month');
	}

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setEventOpen(false);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [openevent, setEventOpen] = React.useState(false);

	const handleClickEventOpen = () => {
		setEsmeDialogOpen(false);
		setEventOpen(true);
	};

	const handleCloseevent = () => {
		setEventOpen(false);
	};

	const [evntdata, setEventData] = React.useState<any>([]);
	const [evntdess, setEvenDescription] = React.useState<any>();

	const [FloorDescription, setFloorDescription] = React.useState<any>();

	const startTimeSlot = '00:00';
	const endTimeSlot = '23:45';
	const interval = 15;

	const [selectedMonth, setselectedmonthval] = React.useState<any>(moment().format('MM'));
	const [selectedYear, setselectedYear] = React.useState<any>(moment().format('YYYY'));
	const [crnttttmnth, setcurntmnth] = React.useState<any>(0);

	const slideRefForDateSlider = React.useRef<Slider>(null);
	const slideRefForConstantDateSlider = React.useRef<Slider>(null);
	const slideRefForTimeSlider = React.useRef<Slider>(null);

	React.useEffect(() => {
		const dynmhours = async () => {
			const search = new URLSearchParams(window.location.search);

			const hrs = await spaces.dynamichours(user.currentSiteId);
			if (hrs !== '' && !search.get('startTime') && !search.get('endTime')) {
				getSlots(0);
			}
			setbuidlingentities(hrs.buildings_entities);

			setofficeHoursdnmc(hrs.officeHours);
		};

		if (user.currentSiteId) {
			const flrdataindividual = user.flrdataname.filter((item: any) => item.floorId == route.params.floorId);

			if (flrdataindividual.length > 0) {
				setFloorDescription(flrdataindividual[0].floorName);
			}
		}
		dynmhours();
	}, [user.currentSiteId, user.permissions, user.flrdataname]);
	React.useEffect(() => {
		if (user.currentSiteId !== '' && spaces.condecoDetails) {
			if (spaces.condecoDetails && spaces.condecoDetails.ISEMSAvailable) {
				setBookingLoader(false);
				setClientId(spaces.condecoDetails && spaces.condecoDetails.Clientid);
			} else {
				setBookingLoader(true);
				window.location.href = `/spaces/spacesHome/NA/NA`;
			}

			let currentmnthonly: any = moment().format('MM');

			const searchnew = new URLSearchParams(window.location.search);
			let diffnew: number = moment(searchnew.get('startTime'), 'YYYY-MM-DD').diff(
				moment().format('YYYY-MM-DD'),
				'days',
			);
			let yeamtnday = moment()
				.add(diffnew, 'days')
				.format('MM');
			if (searchnew.get('startTime') && currentmnthonly !== yeamtnday) {
				let diff: number = moment(searchnew.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days');
				let yearandmonth = moment()
					.add(diff, 'days')
					.format('YYYY-MM');
				let Noofdayscount2: any = moment(yearandmonth, 'YYYY-MM').daysInMonth();
				setselectedmonthval(yeamtnday);
				setnoofdays(Noofdayscount2);
			} else if (currentmnthonly == yeamtnday) {
				setcurntmnth(1);
				let a = moment().endOf('month');
				let b = moment();
				setnoofdays(a.diff(b, 'days') + 1);
				setselectedmonthval(moment().format('MM'));
			} else {
				let a = moment().endOf('month');
				let b = moment();
				setnoofdays(a.diff(b, 'days') + 1);
				setselectedmonthval(moment().format('MM'));
			}
		}
	}, [spaces.condecoDetails, user.currentSiteId, user.floorsingledata]);

	const [openFinal, setDialogFinal] = React.useState(false);

	const handleClickFinalOpen = () => {
		setOpen(false);

		Roombookingfinal();
	};
	const handleClickfinal = () => {
		setDialogFinal(false);
		setSelectedTimeRange(null);
		clearHighlight();
	};

	const monthChangeEventHandler = (event: object, value: any) => {
		let values = value.split(' ');
		let selectedMonth: string = values[0];
		let selectedYear: number = Number(values[1]);
		setselectedYear(selectedYear);
		let currentmnth: any = moment().format('MM');
		_handleDays(0);
		getSlots(0);
		let monthnumber = moment()
			.month(selectedMonth)
			.format('MM');
		let dateArray: any = moment()
			.add(daysCount, 'days')
			.format('YYYY-MMMM-DD')
			.split('-');
		let Noofdayscount: any = 0;
		let LastDayOfMonth = moment()
			.add(daysCount, 'days')
			.format('YYYY-MM-DD');

		if (dateArray[0] === selectedYear.toString() && dateArray[1] === selectedMonth) {
			let eventdate = moment(LastDayOfMonth);
			let todaysdate = moment(LastDayOfMonth).format('YYYY-MM-01');
			Noofdayscount = eventdate.diff(todaysdate, 'days') + 1;
		} else {
			Noofdayscount = moment(selectedYear + '-' + monthnumber, 'YYYY-MM').daysInMonth();
		}

		setselectedmonthval(monthnumber);

		if (currentmnth == monthnumber) {
			setcurntmnth(1);
			let a = moment().endOf('month');
			let b = moment();
			setnoofdays(a.diff(b, 'days') + 1);
		} else {
			setcurntmnth(0);

			setnoofdays(Noofdayscount);
		}
		let dateoldformat =
			currentmnth == monthnumber
				? { id: 0, date: moment().format('YYYY-MM-DD') }
				: { id: 0, date: selectedYear + '-' + monthnumber + '-' + '01' };
		updatedDate = dateoldformat.date;
		getSlots(0);
		setSelectedDate(dateoldformat);
	};

	const routes = React.useContext(stores.context.routes);

	const [selectedTimeRange, setSelectedTimeRange]: any = React.useState(null);
	const [timeSlots, setTimeSlots] = React.useState([]);
	const [bookedRange, setBookedRange]: any = React.useState([]);
	const [poputmee, setPopuentimte] = React.useState<any>('');
	const [initialTimerange, setInitialTimeRange]: any = React.useState(null);
	const [tearDownHours, setTearDownHours]: any = React.useState(null);

	React.useEffect(() => {
		const search = new URLSearchParams(window.location.search);
		let startTime = search.get('startTime');
		let scrollToDateSlot: number = 0;
		let scrollToTimeSlot: number = 0;

		dateSlots &&
			dateSlots.forEach((dateSlot: any, index: number) => {
				if (getParsedDate(dateSlot.startDate) == selectedDate.date) {
					scrollToDateSlot = index;
					return;
				}
			});

		timeSlots &&
			startTime &&
			timeSlots.forEach((slot: any, index: number) => {
				if (slot == getParsedTime(startTime)) {
					scrollToTimeSlot = index;
					return;
				}
			});
		slideRefForDateSlider?.current?.slickGoTo(scrollToDateSlot);
		slideRefForConstantDateSlider?.current?.slickGoTo(selectedDate.id);
		slideRefForTimeSlider?.current?.slickGoTo(scrollToTimeSlot);
	}, [dateSlots, timeSlots, selectedDate]);

	const getParsedDate = (date: any) => {
		let d = new Date(date);
		return [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
	};
	const getParsedTime = (time: any) => {
		let t = new Date(time);
		return ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2);
	};

	React.useEffect(() => {
		setcurntmnth(crnttttmnth);
		getSlots(0);
	}, [crnttttmnth]);

	React.useEffect(() => {
		const fetchZoneData = () => {
			const test = user.currentSiteId ? user.currentSiteId : '';

			spaces
				.fetchZoneDetails(test, route.params.floorId)
				.then((res: any) => {
					const Zonelist = res.Zones;
					const ZoneData = Zonelist.findIndex((Zonerecord: any) => {
						return Zonerecord.ZoneId === route.params.zoneId;
					});
					if (Zonelist[ZoneData]) {
						const startDateTime = moment()
							.utc()
							.format();
						const endDateTime = moment()
							.add('15', 'minutes')
							.utc()
							.format();
						spaces.RoomAvailable(Zonelist[ZoneData].RoomId, startDateTime, endDateTime).then((res: any) => {
							setTearDownHours(res[0].tearDownHours);
						});
					}
					setZoneSingleRecord(Zonelist[ZoneData]);
					spaces.roomdatesavailability(route.params.zoneId);

					spaces.meetingeventlist().then(res => {
						setEventData(res);

						setEvenDescription(res[0].id);
					});
				})
				.catch(err => {
					setBookingLoader(false);
				});
		};

		fetchZoneData();
	}, [user.currentSiteId]);
	React.useEffect(() => {
		const search = new URLSearchParams(window.location.search);
		if (search.get('startTime') && search.get('endTime') && buildingentities != '') {
			let currentmnthnew: any = moment().format('MM');
			let searchyear: any = moment(search.get('startTime')).format('YYYY');
			let searchmnth: any = moment(search.get('startTime')).format('MM');
			let searchday: any = moment(search.get('startTime')).format('DD');
			setnewDataVariablemonth(
				moment(moment(searchyear + '-' + searchmnth + '-' + searchday).format('YYYY/MM/DD')).format('MMMM'),
			);
			setkeyval(keyval + 1);

			let diff: number =
				currentmnthnew == searchmnth
					? moment(search.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days')
					: moment(search.get('startTime'), 'YYYY-MM-DD').diff(
							moment().format(searchyear + '-' + searchmnth + '-' + '01'),
							'days',
					  );

			if (diff < 0) {
				diff = 0;
			}
			const startTime: any = moment(search.get('startTime')).format('HH:mm');
			const endTime: any = moment(search.get('endTime'))
				.subtract(15, 'minutes')
				.format('HH:mm');

			const newDate =
				currentmnthnew == searchmnth
					? {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format('YYYY-MM-DD'),
					  }
					: {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format(searchyear + '-' + searchmnth + '-' + searchday),
					  };

			updatedDate = newDate.date;
			setSelectedDate(newDate);

			if (getSlots(0 + diff)) {
				let startIndex = getSlots(0 + diff).findIndex((item: any) => item == startTime);
				let endIndex = getSlots(0 + diff).findIndex((item: any) => item == endTime);
				if (startIndex >= 0 && endIndex >= 0) {
					setInitialTimeRange({ start: 'time_' + startIndex, end: 'time_' + endIndex });
				}
			}
		} else if (search.get('startTime') && search.get('endTime')) {
			let currentmnthnew: any = moment().format('MM');
			let searchyear: any = moment(search.get('startTime')).format('YYYY');
			let searchmnth: any = moment(search.get('startTime')).format('MM');
			let searchday: any = moment(search.get('startTime')).format('DD');
			setnewDataVariablemonth(
				moment(moment(searchyear + '-' + searchmnth + '-' + searchday).format('YYYY/MM/DD')).format('MMMM'),
			);
			setkeyval(keyval + 1);

			let diff: number =
				currentmnthnew == searchmnth
					? moment(search.get('startTime'), 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days')
					: moment(search.get('startTime'), 'YYYY-MM-DD').diff(
							moment().format(searchyear + '-' + searchmnth + '-' + '01'),
							'days',
					  );

			if (diff < 0) {
				diff = 0;
			}
			const startTime: any = moment(search.get('startTime')).format('HH:mm');
			const endTime: any = moment(search.get('endTime'))
				.subtract(15, 'minutes')
				.format('HH:mm');

			const newDate =
				currentmnthnew == searchmnth
					? {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format('YYYY-MM-DD'),
					  }
					: {
							id: 0 + diff,
							date: moment()
								.add(diff, 'days')
								.format(searchyear + '-' + searchmnth + '-' + searchday),
					  };

			updatedDate = newDate.date;
			setSelectedDate(newDate);

			if (getSlots(0 + diff)) {
				let startIndex = getSlots(0 + diff).findIndex((item: any) => item == startTime);
				let endIndex = getSlots(0 + diff).findIndex((item: any) => item == endTime);
				if (startIndex >= 0 && endIndex >= 0) {
					setInitialTimeRange({ start: 'time_' + startIndex, end: 'time_' + endIndex });
				}
			}
		} else {
			getSlots(0);
			setSelectedDate({
				id: 0,
				date: moment().format('YYYY-MM-DD'),
			});
		}
	}, [zoneSingleRecord, buildingentities]);
	React.useEffect(() => {
		const starnewDateTime = `${selectedDate.date}T00:00:00Z`;
		const endnewDateTime = `${selectedDate.date}T23:45:00Z`;
		if (zoneSingleRecord) {
			getBookingData(zoneSingleRecord && zoneSingleRecord.RoomId, starnewDateTime, endnewDateTime);
		}
	}, [selectedDate, zoneSingleRecord, BookingId]);

	const _handleDays = (id: number) => {
		settingsForTime['initialSlide'] = 0;
		slideRefForTimeSlider?.current?.slickGoTo(0);

		const selectDateValue =
			crnttttmnth == 1
				? {
						id,
						date: moment()
							.add(id, 'days')
							.format('YYYY-MM-DD'),
				  }
				: {
						id,
						date: moment(selectedYear + '-' + selectedMonth + '-' + '01')
							.add(id, 'days')
							.format('YYYY-MM-DD'),
				  };
		updatedDate = selectDateValue.date;
		setSelectedDate(selectDateValue);

		setSelectedTimeRange(null);

		clearHighlight();
		getSlots(id);
	};
	React.useEffect(() => {
		getAvailableStatus();
		if (initialTimerange) {
			setBookingLoader(true);
			setTimeout(() => {
				_highlightTimeRange(initialTimerange.start, initialTimerange.end);
			}, 2000);
		}
	}, [bookedRange]);
	const getBookingData = (roomId: any, starDateTime: string, endDateTime: string) => {
		setBookingLoader(true);
		spaces.getBookedDateTime(roomId, starDateTime, endDateTime).then((res: any) => {
			let bookedData = res.map((item: any) => ({
				start: moment(item.eventStartTime).format('HH:mm'),
				end: moment(item.eventEndTime).format('HH:mm'),
			}));
			setBookedRange(bookedData);
			if (!initialTimerange) {
				setBookingLoader(false);
			}
		});
	};

	const getSlots = (id: number) => {
		let slots: Array<any> | any = [];
		let startTime = moment(startTimeSlot, 'HH:mm');

		if (id == 0 && crnttttmnth == 1) {
			if (
				moment()
					.tz('Europe/London')
					.format('HH:mm') < moment(startTimeSlot, 'HH:mm').format('HH:mm')
			) {
				startTime = moment(startTimeSlot, 'HH:mm');
			} else {
				startTime = moment(getRoundedDate());
			}
		}
		var endTime = moment(endTimeSlot, 'HH:mm');
		if (startTime.isAfter(endTime)) {
			setTimeSlots(slots);
			return;
		}

		const buildingData = buildingentities.filter((buildingInfo: any) =>
			buildingInfo.Entity_Id === route.params.zoneId ? buildingInfo : null,
		);
		let buildingNumber: any = buildingData.length > 0 ? buildingData[0].BuildingId : null;

		const choosenDate =
			officeHoursData &&
			officeHoursData
				.slice(0)
				.filter((item: any) =>
					moment(item.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') === updatedDate ? item : null,
				);
		if (choosenDate.length > 0 && choosenDate[0].dayVisible) {
			setTimeSlots([]);
			setBookRoombuttonText('');
			setSelectedTimeRange(null);
		} else {
			while (startTime <= endTime) {
				if (buildingNumber !== null && choosenDate.length > 0) {
					if (
						moment(choosenDate[0].startTime, 'HH:mm') <= startTime &&
						moment(choosenDate[0].endTime, 'HH:mm').subtract('15', 'minutes') >= startTime
					) {
						slots.push(moment(startTime).format('HH:mm'));
						startTime.add(interval, 'minutes');
					} else {
						startTime.add(interval, 'minutes');
					}
				} else {
					slots.push(moment(startTime).format('HH:mm'));
					startTime.add(interval, 'minutes');
				}
			}
			if (tearDownHours && id == 0 && crnttttmnth == 1) {
				slots.shift();
			}
			setTimeSlots(slots);
		}
		return slots;
	};

	const getRoundedDate = (minutes: any = 15, d = moment().tz('Europe/London')) => {
		var time = 1000 * 60 * minutes;
		var date = new Date(d.format('YYYY-MM-DDTHH:mm'));
		var rounded = new Date(date.getTime() + time - (date.getTime() % time));
		return rounded;
	};
	const _highlightTimeRange = (start: string, end: string) => {
		let temp: any = { start, end };
		clearHighlight();
		let cursor1 = temp.start;
		let cursor2 = temp.end;
		if (Number(cursor1.split('_')[1]) < Number(cursor2.split('_')[1])) {
			let obj = { start: cursor1, end: cursor2 };
			setSelectedTimeRange(obj);
			highlightButtons(cursor1.split('_')[1], cursor2.split('_')[1], '#098BBD');
		} else {
			let obj = { start: cursor2, end: cursor1 };
			setSelectedTimeRange(obj);
			highlightButtons(cursor2.split('_')[1], cursor1.split('_')[1], '#098BBD');
		}
		setInitialTimeRange(null);
		setTimeout(() => setBookingLoader(false), 1000);
	};
	const _handleTimeRange = (id: string) => {
		if (!selectedTimeRange) {
			if (document.getElementById(id)) {
				document.getElementById(id)!.style.backgroundColor = '#098BBD';
				document.getElementById(id)!.style.color = '#fff';
				setSelectedTimeRange(() => ({ start: id }));
			}
		} else if (selectedTimeRange['start'] == id) {
			clearHighlight();
			setSelectedTimeRange(null);
			return;
		} else {
			let temp: any = { ...selectedTimeRange };
			clearHighlight();
			if (temp.start && temp.end) {
				let current = Number(id.split('_')[1]);
				if (Number(temp.start.split('_')[1]) > current) {
					temp.start = id;
				} else if (Number(temp.start.split('_')[1]) < current) {
					temp.end = id;
				}
			}
			let cursor1 = temp.start ? temp.start : selectedTimeRange.start;
			let cursor2 = temp.end ? temp.end : id;
			if (selectedTimeRange['end'] == id) {
				cursor2 = 'time_' + (Number(selectedTimeRange.end.split('_')[1]) - 1);
			}
			if (Number(cursor1.split('_')[1]) < Number(cursor2.split('_')[1])) {
				let obj = { start: cursor1, end: cursor2 };
				setSelectedTimeRange(obj);
				highlightButtons(cursor1.split('_')[1], cursor2.split('_')[1], '#098BBD');
			} else {
				let obj = { start: cursor2, end: cursor1 };
				setSelectedTimeRange(obj);
				highlightButtons(cursor2.split('_')[1], cursor1.split('_')[1], '#098BBD');
			}
		}
	};

	const clearHighlight = () => {
		const butns: any = document.querySelectorAll('.timeBtnDefault');
		for (let butn of butns) {
			(butn as HTMLElement).style.backgroundColor = 'white';
			(butn as HTMLElement).style.color = '#098BBD';
		}
	};
	const highlightButtons = (start: any, end: any, color: any) => {
		const butns: any = document.querySelectorAll('.timeBtnDefault');
		let startFound = false;
		let endFound = false;
		for (let butn of butns) {
			if (butn.id.split('_')[1] == start) {
				startFound = true;
			}
			if (startFound && !endFound) {
				if ((butn as HTMLButtonElement).disabled) {
					setConsecutiveTimeDialog(true);

					setSelectedTimeRange(null);
					clearHighlight();
					break;
				}
				(butn as HTMLElement).style.backgroundColor = color;
				(butn as HTMLElement).style.color = 'white';
			}
			if (butn.id.split('_')[1] == end) {
				endFound = true;
				break;
			}
		}
	};

	const getAvailableStatus = () => {
		let available;
		let id = 0;
		for (let time of timeSlots) {
			let booked = checkSlotStatus('time_' + id);
			if (booked) {
				available = time;
				break;
			}
			id++;
		}
		if (timeSlots && timeSlots[0] == available) {
			setCurrentAvailableTime(null);
		} else if (available) {
			setCurrentAvailableTime(available);
		} else {
			setCurrentAvailableTime(timeSlots[timeSlots.length - 1]);
		}
	};
	const checkSlotStatus = (id: string, offbreak = false) => {
		let booked = false;
		const index = Number(id.split('_')[1]);
		let slotTime = moment(timeSlots[index], 'HH:mm');
		bookedRange.forEach((booking: any) => {
			let currBookingStart = moment(booking.start, 'HH:mm');
			let currBookingEnd = moment(booking.end, 'HH:mm');
			if (!tearDownHours) {
				if (slotTime.isBetween(currBookingStart, currBookingEnd, undefined, '[]')) {
					if (offbreak) {
						booked = true;
					} else if (slotTime.isBetween(currBookingStart, currBookingEnd.subtract(15, 'minutes'), undefined, '[]')) {
						booked = true;
					}
				}
			} else if (
				slotTime.isBetween(
					currBookingStart.subtract(60 * Number(tearDownHours), 'minutes'),
					currBookingEnd,
					undefined,
					'[]',
				)
			) {
				booked = true;
			}
		});
		return booked;
	};

	const getSelectedTimeRange = () => {
		return {
			start:
				selectedTimeRange && selectedTimeRange.start && timeSlots[selectedTimeRange.start.split('_')[1]]
					? timeSlots[selectedTimeRange.start.split('_')[1]]
					: null,
			end:
				selectedTimeRange && selectedTimeRange.end && timeSlots[selectedTimeRange.end.split('_')[1]]
					? timeSlots[selectedTimeRange.end.split('_')[1]]
					: null,
		};
	};

	React.useEffect(() => {
		if (getSelectedTimeRange().end !== null || getSelectedTimeRange().start !== null) {
			const popupetime =
				getSelectedTimeRange().end == null
					? moment(getSelectedTimeRange().start, 'HH:mm')
							.add('15', 'minutes')
							.format('HH:mm')
					: moment(getSelectedTimeRange().end, 'HH:mm')
							.add('15', 'minutes')
							.format('HH:mm');
			setPopuentimte(popupetime);
		} else {
			setBookRoombuttonText('');
		}
	}, [getSelectedTimeRange(), poputmee]);
	React.useEffect(() => {
		if (poputmee === '' || getSelectedTimeRange().start === null) {
			setBookRoombuttonText('');
		} else {
			setBookRoombuttonText(
				`for ${moment(selectedDate.date).format('DD/MM/YYYY')} ${
					getSelectedTimeRange() && getSelectedTimeRange().start ? getSelectedTimeRange().start : ''
				} - ${poputmee}`,
			);
		}
	}, [bookRoombuttonText, poputmee, getSelectedTimeRange().start]);

	const cancelSlot = () => {
		setBookRoombuttonText('');
		const floordata = user.floorsingledata;
		routes.navigate(
			{
				name: 'spacesHome',
				params: {
					clientId: floordata.clientId,
					floorId: floordata.floorId,
				},
			},
			{
				clearParams: true,
			},
		);
	};

	const [subjecttit, setSubject] = React.useState<any>('Room Booking');
	const subject = (event: any) => {
		setSubject(event.target.value);
	};

	const [meetingevent, setEventVal] = React.useState<any>();

	const AddAttendees = () => {
		const subject = subjecttit ? subjecttit : 'Room Booking';
		window.location.href = `/spaces/addAttendees/${BookingId}/${route.params.floorId}/${route.params.zoneId}/${subject}/${zoneSingleRecord.ZoneDescription}`;
	};

	const [recordType, setRecordType] = React.useState<any>();

	const [setUpTypeId, setSetUpId] = React.useState<any>();

	const [currentAvailableTime, setCurrentAvailableTime]: any = React.useState(null);
	const floordata = user.floorsingledata;

	const bookRoom = () => {
		setBookingLoader(true);
		const startDateTime = moment(selectedDate.date + 'T' + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm')
			.utc()
			.add('01', 'hours')
			.format();
		const endDateTime =
			getSelectedTimeRange().end == null
				? moment(selectedDate.date + 'T' + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm')
						.add('15', 'minutes')
						.utc()
						.add('01', 'hours')

						.format()
				: moment(selectedDate.date + 'T' + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm')
						.add('15', 'minutes')
						.utc()
						.add('01', 'hours')

						.format();
		const popupetime =
			getSelectedTimeRange().end == null
				? moment(getSelectedTimeRange().start, 'HH:mm')
						.add('15', 'minutes')
						.format('HH:mm')
				: moment(getSelectedTimeRange().end, 'HH:mm')
						.add('15', 'minutes')
						.format('HH:mm');
		setPopuentimte(popupetime);
		spaces
			.RoomAvailable(zoneSingleRecord.RoomId, startDateTime, endDateTime)
			.then((res: any) => {
				const avairomm: any = res;
				setRecordType(avairomm[0].recordType);
				setSetUpId(avairomm[0].defaultSetupTypeId);
				if (avairomm[0].unavailableReason == 0) {
					setEsmeDialogOpen(true);
				} else {
					setbookingavailable(true);
				}
			})
			.finally(() => setBookingLoader(false));
	};

	const handleClickClose = () => {
		setEsmeDialogOpen(false);
		setSelectedTimeRange(null);
		clearHighlight();
	};

	const Roombookingfinal = () => {
		const startDateTime = moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss').format();

		const endDateTime =
			getSelectedTimeRange().end == null
				? moment(selectedDate.date + getSelectedTimeRange().start, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format()
				: moment(selectedDate.date + getSelectedTimeRange().end, 'YYYY-MM-DDTHH:mm:ss')
						.add('15', 'minutes')
						.format();
		setBookingLoader(true);
		const subject = subjecttit ? subjecttit : 'Room Booking';
		const EventTypeId = meetingevent ? meetingevent : evntdess;
		spaces
			.RoomBooking(zoneSingleRecord.RoomId, recordType, startDateTime, endDateTime, subject, EventTypeId, setUpTypeId)
			.then((res: any) => {
				setBookingId(res.BookingId);
				setDialogFinal(true);
			})
			.finally(() => setBookingLoader(false));
	};
	const [dispmenu, setdisplayMen] = React.useState(0);

	const menuChng1 = () => {
		setdisplayMen(0);
	};

	return useObserver(() => (
		<>
			<main className={classes.container}>
				<Backdrop className={classes.backdrop} open={bookingLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Grid container direction="row" spacing={1}>
					<Grid item xs={12} md={7}>
						<Paper className={classes.paper}>
							<Grid style={{ minHeight: '265px', padding: '16px' }}>
								<Grid container item xs={12} justify="flex-start">
									<Typography variant="h5" paragraph={true}>
										{zoneSingleRecord && zoneSingleRecord.ZoneName}, {FloorDescription},{floordata.buildingName}
									</Typography>
								</Grid>

								<ListItem style={{ marginBottom: '-22px' }}>
									<Grid item xs={12}>
										<ListItemText>
											<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Room capacity:</span>{' '}
										</ListItemText>
										<ListItemSecondaryAction className={classes.listValue}>
											{zoneSingleRecord && zoneSingleRecord.NumberOfPeople}
										</ListItemSecondaryAction>
									</Grid>
								</ListItem>

								<ListItem style={{ marginBottom: '-22px' }}>
									<Grid item xs={12}>
										<ListItemText>
											<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Room layout: </span>
										</ListItemText>
										<ListItemSecondaryAction className={classes.listValue}>
											{zoneSingleRecord && zoneSingleRecord.ZoneType}
										</ListItemSecondaryAction>
									</Grid>
								</ListItem>

								<ListItem style={{ marginBottom: '-22px' }}>
									<Grid item xs={12}>
										<ListItemText style={{ marginTop: '0px !important', marginBottom: '1px !important' }}>
											<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Provided equipment:</span>{' '}
											<List
												dense={true}
												style={{ display: 'inline-grid', left: '10px', top: '65%', marginTop: '-10px !important' }}
											>
												{zoneSingleRecord &&
												zoneSingleRecord.ResourcesAvailable &&
												zoneSingleRecord.ResourcesAvailable.length > 0 &&
												zoneSingleRecord.ResourcesAvailable.filter((item: any) => (Boolean(features[item]) ? item : ''))
													.length > 0 ? (
													zoneSingleRecord.ResourcesAvailable.filter((item: any) =>
														Boolean(features[item]) ? item : '',
													).map((item: any, id: number) => {
														return Boolean(item in features) ? (
															clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '2' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	Display Screen
																</span>
															) : clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '25' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	Video Conference
																</span>
															) : clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '5' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	Audio Conference
																</span>
															) : clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '6' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	FlipChart
																</span>
															) : clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '1' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	Whiteboard
																</span>
															) : clientidfeatures == '762ad627-5501-4792-a0a4-2914fa784f25' && item == '9' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	Projector
																</span>
															) : clientidfeatures !== '762ad627-5501-4792-a0a4-2914fa784f25' ? (
																<span className={classes.listValuenew} style={{ marginLeft: '18px !important' }}>
																	{features[item]}
																</span>
															) : (
																<span
																	className={classes.listValue}
																	style={{
																		marginLeft: '18px',
																	}}
																>
																	None specified
																</span>
															)
														) : (
															''
														);
													})
												) : (
													<span
														className={classes.listValue}
														style={{
															marginLeft: '18px',
														}}
													>
														None specified
													</span>
												)}
											</List>
										</ListItemText>
									</Grid>
								</ListItem>

								<ListItem style={{ marginBottom: '-23px' }}>
									<Grid item xs={12}>
										<ListItemText>
											<span style={{ fontWeight: 'bolder', fontSize: '0.90rem' }}>Available equipment:</span>{' '}
										</ListItemText>
										<ListItemSecondaryAction style={{ left: '182px' }}>
											<span
												style={{
													color: '#172A3F',
													letterSpacing: '0.01px',
													opacity: '1',
													font: 'normal normal normal 15px/19px Roboto',
												}}
											>
												None specified
											</span>
										</ListItemSecondaryAction>
									</Grid>
								</ListItem>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={5}>
						<Paper className={classes.paper}>
							<Grid style={{ overflowY: 'hidden', height: '350px' }}>
								<Grid container item xs={12} justify="flex-start">
									<Breadcrumbs aria-label="breadcrumb" separator="" color="textSecondary" className={classes.headerNav}>
										<Link
											color="inherit"
											underline="none"
											style={{ cursor: 'pointer' }}
											onClick={menuChng1}
											className={dispmenu == 0 ? classes.headerMenuLinkActive : ''}
										>
											Photo
										</Link>
									</Breadcrumbs>
									<hr style={{ opacity: '.2', width: '100%', marginTop: '0px', marginBottom: '-2px' }} />
									{dispmenu == 0 && zoneSingleRecord && zoneSingleRecord.ZoneImageSource ? (
										<>
											<div>
												<img
													className={classes.imageBlock}
													src={require(`../assets/images/spaces/${zoneSingleRecord.ZoneImageSource}`)}
													alt="SitePhoto"
												/>
											</div>
										</>
									) : (
										<div style={{ height: '100%', margin: 'auto', padding: '55px' }}>
											<img src={noResults} className={classes.accessDimensions} alt="underconstruction" />

											<Typography variant="subtitle2" style={{ display: 'flex', justifyContent: 'center' }}>
												No room photo available
											</Typography>
											<Typography
												color="textSecondary"
												variant="caption"
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												Uploaded images will appear here
											</Typography>
										</div>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					{zoneSingleRecord && (
						<Grid item xs={12} md={12} lg={12}>
							<Paper className={classes.paper} style={{ padding: '15px' }}>
								<Grid container xs={12} md={12} lg={12} justify="flex-start">
									<Typography variant="h5" paragraph={true}>
										Bookable times &nbsp;
									</Typography>
								</Grid>
								<Grid container style={{ padding: '0px 30px', alignItems: 'center' }}>
									<Grid item md={9} lg={9}>
										{buildingId !== null && dateSlots.length > 0 ? (
											<Slider
												ref={slideRefForDateSlider}
												{...settingsForDate}
												className={classes.testnew + ' ' + classes.dateSlider}
											>
												{dateSlots?.map((dateRange: any, id: any) => {
													return (
														<Button
															key={id}
															id={'days_' + id}
															onClick={() => _handleDays(id)}
															className={selectedDate.id == id ? classes.timebtnnew : classes.timebtn}
															color="primary"
															disabled={dateRange.dayVisible}
															variant={selectedDate.id == id ? 'contained' : 'outlined'}
															style={{ margin: '1px', minWidth: '0', boxShadow: 'none', padding: '10px 3px' }}
														>
															{moment(new Date(dateRange.startDate))
																.format('DD/MM')
																.toString()}
														</Button>
													);
												})}
											</Slider>
										) : (
											<Slider
												ref={slideRefForConstantDateSlider}
												{...settingsForDate}
												className={classes.testnew + ' ' + classes.dateSlider}
											>
												{Array(noofdayscount)
													.fill(0)
													.map((_, id) => (
														<Button
															key={id}
															id={'days_' + id}
															onClick={() => _handleDays(id)}
															className={selectedDate.id == id ? classes.timebtnnew : classes.timebtn}
															color="primary"
															variant={selectedDate.id == id ? 'contained' : 'outlined'}
														>
															<Moment format="DD/MM">
																{crnttttmnth == 1 ? moment().add(id, 'days') : moment(selectedMonth).add(id, 'days')}
															</Moment>
														</Button>
													))}
											</Slider>
										)}
									</Grid>
									<Grid item xs={12} md={3} lg={3}>
										<div key={keyval} className={classes.MonthSelector}>
											<Autocomplete
												options={monthsList}
												getOptionLabel={(option: any) => option}
												size="small"
												className={classes.Autocompletenew}
												id="size-small-standard"
												noOptionsText="Loading..."
												autoComplete
												ListboxProps={{ style: { overflow: 'hidden' } }}
												defaultValue={newDataVariablemonth1 + ' ' + selectedYear}
												onChange={(event: object, value: any) => monthChangeEventHandler(event, value)}
												renderInput={params => <TextField {...params} label="Month" variant="filled" margin="normal" />}
											/>
										</div>
									</Grid>
								</Grid>
								<Grid container style={{ padding: '0px 30px', alignItems: 'center' }}>
									<Grid item xs={12} md={12} lg={12}>
										<Slider
											ref={slideRefForTimeSlider}
											{...settingsForTime}
											className={classes.testnew + ' ' + classes.timeSlider}
										>
											{timeSlots.map((time, id) => {
												return (
													<Button
														key={'time_' + id}
														id={'time_' + id}
														onClick={() => _handleTimeRange('time_' + id)}
														className={`${classes.timebtn} timeBtnDefault `}
														disabled={checkSlotStatus('time_' + id)}
														variant="outlined"
													>
														{time}
													</Button>
												);
											})}
										</Slider>
									</Grid>
								</Grid>

								<Grid container xs={12} lg={12} md={12} alignItems="center" style={{ marginTop: '15px' }}>
									<Grid item md={7}>
										<Box
											alignContent="flex-start"
											flexDirection="row"
											style={{ paddingLeft: '2%', paddingTop: '1%' }}
											flexGrow={1}
											display="flex"
											flexWrap="wrap"
										>
											<FiberManualRecordIcon style={{ color: currentAvailableTime ? '#1BA257' : '#D71920' }} />
											<Typography
												variant="subtitle2"
												paragraph={true}
												style={{ marginLeft: '25px', paddingTop: '2px' }}
											>
												{currentAvailableTime
													? 'Currently available until ' + currentAvailableTime
													: 'Currently not available'}
											</Typography>
										</Box>
									</Grid>
									<Grid item md={5}>
										<Box alignContent="flex-end" display="flex" flexWrap="wrap" justifyContent="right">
											<Button variant="outlined" color="primary" onClick={cancelSlot}>
												Cancel
											</Button>
											&nbsp;&nbsp;&nbsp;
											<Button
												variant="contained"
												className={classes.disableButton}
												disabled={selectedTimeRange === null}
												color="primary"
												onClick={bookRoom}
											>
												Book room {bookRoombuttonText}
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>

				<Dialog open={consecutiveTimeDialog} onClose={handleConsecutiveSlots}>
					<DialogContent className={classes.alignStyle} style={{ width: '330px', height: '235px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Some of the times you selected are already booked
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '10px' }}>
							Please select a meeting duration where all times are available or select another space
						</DialogContentText>
						<DialogActions>
							<Button style={{ paddingRight: '8px' }} onClick={handleConsecutiveSlots} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
				<Dialog open={esmeDialogOpen} onClose={handleClickClose}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							{zoneSingleRecord ? zoneSingleRecord.ZoneName : ''} selected
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '1px' }}>
							Please confirm your booking of {zoneSingleRecord ? zoneSingleRecord.ZoneName : ''} for{' '}
							{getSelectedTimeRange().start}- {poputmee} on{' '}
							{`${moment(`${selectedDate.date}`).format('ddd')} ${moment(selectedDate.date).format('DD/MM/YYYY')}`}
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickClose} color="primary">
								Cancel
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickEventOpen} color="primary">
								Confirm
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={bookingavailable} onClose={handleAvailableclose}>
					<DialogContent className={classes.alignStyle} style={{ width: '328px', height: '175px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Room not available
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							The time slot you have selected for this room is no longer available
						</DialogContentText>
						<DialogActions>
							<Button style={{ paddingRight: '8px' }} onClick={handleAvailableclose} color="primary">
								OK
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
					<DialogContent className={classes.alignStyle} style={{ width: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting Subject
						</DialogTitle>

						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '1px' }}>
							Please enter a short title for your
							<br /> meeting subject
						</DialogContentText>
						<TextField
							style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}
							margin="normal"
							id="name"
							label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Meeting Subject"
							type="text"
							fullWidth
							onChange={subject}
							variant="filled"
						/>

						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Cancel
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={handleClickFinalOpen} color="primary">
								Save
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={openevent} onClose={handleCloseevent} aria-labelledby="form-dialog-title">
					<DialogContent className={classes.alignStyle} style={{ width: '328px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting type
						</DialogTitle>

						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '1px' }}>
							Please select from one of the below meeting types.
						</DialogContentText>

						<div>
							<Autocomplete
								options={evntdata}
								getOptionLabel={(option: any) => option.description}
								size="small"
								className={classes.Autocomplete}
								classes={{
									option: classes.option,
								}}
								id="size-small-standard"
								noOptionsText="Loading..."
								defaultValue={evntdata[0]}
								autoComplete
								onChange={(event: any, newValue: any) => {
									setEventVal(newValue.id);
								}}
								renderInput={params => <TextField {...params} label="Meeting type" variant="filled" margin="normal" />}
							/>
						</div>

						<DialogActions>
							<Button onClick={handleClickOpen} color="primary">
								Done
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>

				<Dialog open={openFinal} onClose={handleClickfinal}>
					<DialogContent className={classes.alignStyle} style={{ maxWidth: '328px', maxHeight: '175px' }}>
						<DialogTitle style={{ paddingTop: '21px' }} id="form-dialog-title">
							Meeting room booked
						</DialogTitle>
						<DialogContentText style={{ paddingLeft: '24px', paddingRight: '24px', marginBottom: '6px' }}>
							Would you like to invite any meeting attendees ?
						</DialogContentText>
						<DialogActions>
							<Button onClick={handleClickfinal} color="primary">
								Close
							</Button>
							<Button style={{ paddingRight: '8px' }} onClick={AddAttendees} color="primary">
								Add attendees
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</main>
		</>
	));
});

export default RoomInfo;
