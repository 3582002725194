import React, { useState, useEffect } from 'react';
import { displayDate } from '../../util';
import { observer, useLocalStore } from 'mobx-react-lite';
import { stores } from '../../store';
import PermanentAppBar from 'components/Header/PermanentAppBar';
import { faCopy, faPenSquare, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import {
	faCloudDownload as faCloudDownloadsolid,
	faPlus as faPlussolid,
	faCopy as faCopysolid,
	faPenSquare as faPenSquaresolid,
	faTrashAlt as faTrashAltsolid,
} from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@mitie/material-ui-extensions';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from '../../assets/css/ListTheme';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Grid,
	Dialog,
	DialogActions,
	TablePagination,
	TableFooter,
	Paper,
	Fab,
	IconButton,
	Card,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { IContent, Category, ISiteObject } from '../../types';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import accessDeniedIcon from '../../assets/images/access-denied.png';
import { getAPI } from 'services/apiService';
import { CSVLink } from 'react-csv';

interface IFeedListOptions {
	Category?: number;
	PageCount?: number;
	PageSize?: number;
	Sort?: number;
	Order?: string;
	SearchText?: string;
	TotalCount?: number;
}

const PromotionList = observer(() => {
	const routes = React.useContext(stores.context.routes);
	const appStores = React.useContext(stores.context.appStores);
	const notifications = React.useContext(stores.context.notifications);
	const contentManagement = React.useContext(stores.context.contentManagement);
	const sites = React.useContext(stores.context.site);
	const user = React.useContext(stores.context.user);
	const classes = useStyles();
	const rowsPerPageOptions = [2, 5, 10, 15];
	const [menus, setMenus] = useState<IContent[]>([]);
	let sitedatanew: any = localStorage.getItem('sitedatalat');
	let df: ISiteObject[] = sitedatanew ? JSON.parse(sitedatanew) : sites.siteList;
	const [csvdata, setCsvData] = useState([]);
	const headers = [
		{ label: 'SiteName', key: 'SiteID' },
		{ label: 'Title', key: 'Title' },
		{ label: 'StartTime', key: 'StartTime' },
		{ label: 'ExpiredTime', key: 'ExpiredTime' },
		{ label: 'Live', key: 'Live' },
		{ label: 'VisibleFromTime', key: 'VisibleFromTime' },
		{ label: 'Likes', key: 'Likes' },
		{ label: 'Shares', key: 'Shares' },
		{ label: 'Visibility', key: 'Visibility' },
	];
	const [showPromotions, setShowPromotions] = React.useState(0);
	appStores.showAppLoader = user.permissions === null ? true : false;
	let feedListOptions: IFeedListOptions = useLocalStore(() => ({
		Category: Category.Promotion,
		PageCount: 10,
		PageSize: 0,
		Sort: 0,
		Order: 'ModifiedTime',
		SearchText: '',
		TotalCount: 0,
	}));

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		feedListOptions.PageSize = newPage;
		fetchContentManagement();
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		feedListOptions.PageCount = parseInt(event.target.value, 10);
		feedListOptions.PageSize = 0;
		fetchContentManagement();
	};
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [deleteId, setDeleteID] = React.useState(Number);

	const handleClickOpen = (Id: number) => {
		setDialogOpen(true);
		setDeleteID(Id);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	const fetchContentManagement = async () => {
		appStores.showAppLoader = true;
		const result = await contentManagement.fetchContentManagement(feedListOptions);
		setMenus(toJS(result));
		appStores.showAppLoader = false;
	};

	const deleteItem = async (menuId: any) => {
		if (menuId !== '') {
			const response = await contentManagement.deleteContentItem([menuId]);
			if (response) {
				notifications.addNotification('Promotion deleted', 'delete');
				await fetchContentManagement();
				setDialogOpen(false);
				getData();
			} else {
				notifications.addNotification(response + 'Something went wrong, Please try again', 'error');
			}
		}
	};

	localStorage.removeItem('URL');
	localStorage.removeItem('routeName');
	localStorage.removeItem('CMValue');

	useEffect(() => {
		if (user.permissions && user.permissions.Modules) {
			const feedIndex = user.permissions.Modules.findIndex(
				(m: any) => m.ModuleId === '9DB69870-10EC-4BDC-8EF9-6C1D6BB39291',
			);
			if (user.permissions.Modules[feedIndex] && user.permissions.Modules[feedIndex].Read) {
				if (
					user.permissions.Modules[feedIndex].Create ||
					user.permissions.Modules[feedIndex].Update ||
					user.permissions.Modules[feedIndex].Delete
				) {
					setShowPromotions(1);
				}
			} else {
				setShowPromotions(0);
			}
		} else {
			setShowPromotions(0);
		}
	}, [user.permissions]);
	useEffect(() => {
		const fetchMenus = async () => {
			await fetchContentManagement();
		};
		fetchMenus();
		getData();
	}, []);

	const getData = async () => {
		const data = await getAPI('/feedContent/exportCSV/4');
		let editedMeta: any = [];
		data?.forEach((item: any) => {
			editedMeta.push({ ...item, SiteID: df?.find(sId => sId.Id === item.SiteID)?.Name });
		});
		setCsvData(editedMeta);
	};

	const getFeedCount = () => {
		feedListOptions.TotalCount = contentManagement.contentRecords;
		return feedListOptions.TotalCount || 0;
	};

	return (
		<>
			<PermanentAppBar isUserLoggedIn={user.isUserLoggedIn} />
			<main className={classes.contentMain}>
				<div className={classes.appBarSpacerMain} />
				<div className={classes.containerMain}>
					<main>
						{showPromotions === 1 && appStores.showAppLoader === false ? (
							<div>
								<Grid container>
									<Grid item container xs={8} sm={8} className={classes.title} justify="flex-start">
										<Typography style={{ color: '#002855' }} variant="h5">
											Promotions
										</Typography>
									</Grid>
									<Grid item container xs={4} sm={4} className={classes.title} justify="flex-end">
										<Fab color="primary" aria-label="add" className={classes.cloudIcon}>
											<CSVLink data={csvdata} headers={headers} filename={'PromotionsList'}>
												<Icon icon={faCloudDownloadsolid} />
											</CSVLink>
										</Fab>
										<Fab
											color="primary"
											aria-label="add"
											className={classes.plusIcon}
											onClick={() => {
												routes.navigate(
													{ name: 'promotionEditor' },
													{
														clearParams: true,
													},
												);
											}}
										>
											<Icon icon={faPlussolid} />
										</Fab>
									</Grid>
								</Grid>
								{menus.length === 0 ? (
									<Grid container>
										<Grid item xs={12} className={classes.noResults}>
											<Typography variant="h6">We couldn't find Promotion List </Typography>
											<Typography className={classes.textMargin}>
												Please upload promotion from Add button on top.
											</Typography>
										</Grid>
									</Grid>
								) : (
									<Grid style={{ marginTop: '24px' }} container>
										<Grid item xs={12}>
											<Table>
												<TableHead>
													<TableRow>
														<td>
															<Card className={classes.headCard}>
																<table style={{ width: '100%' }}>
																	<tr>
																		<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																			<div className={classes.listtableSites}>Sites</div>
																		</TableCell>
																		<TableCell style={{ width: '25%' }} className={classes.listtableCell}>
																			<div className={classes.listtableTitle}>Title </div>
																		</TableCell>
																		<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																			<div className={classes.listtableStartDate}>Start Date </div>
																		</TableCell>
																		<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																			<div className={classes.listtableEndDate}>End Date </div>
																		</TableCell>
																		<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																			<div className={classes.listtableLive}>Live </div>
																		</TableCell>
																		<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																			<div className={classes.listtableStatus}>Status </div>
																		</TableCell>
																		<TableCell style={{ width: '5%' }} className={classes.listtableCell}>
																			<div className={classes.listtableLikes}>Likes </div>
																		</TableCell>
																		<TableCell style={{ width: '5%' }} className={classes.listtableCell}>
																			<div className={classes.listtableShares}>Shares </div>
																		</TableCell>
																		<TableCell style={{ width: '15%' }} className={classes.listtableCell}>
																			<div className={classes.listtableActions}>Actions</div>
																		</TableCell>
																	</tr>
																</table>
															</Card>
														</td>
													</TableRow>
												</TableHead>

												<TableBody>
													<tr>
														<td>
															<Paper className={classes.listPaper}>
																<table style={{ width: '100%' }}>
																	{menus.map((list: IContent, index: number) => {
																		let siteIndex = df.findIndex(
																			(nam: any) => nam.Id == user.currentSiteId && user.currentSiteId,
																		);
																		const SiteName = siteIndex != -1 ? df[siteIndex].Name : '';

																		return (
																			<TableRow key={list.Id} hover={false}>
																				<td>
																					<Card className={classes.listCard}>
																						<table style={{ width: '100%' }}>
																							<tr>
																								<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentSites}>{SiteName}</div>
																								</TableCell>
																								<TableCell style={{ width: '25%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentTitle}>{list.Title}</div>
																								</TableCell>
																								<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentStartTime}>
																										{displayDate(list.StartTime)}
																									</div>
																								</TableCell>
																								<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentEndTime}>
																										{displayDate(list.ExpiredTime)}
																									</div>
																								</TableCell>
																								<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentLive}>{list.Status}</div>
																								</TableCell>
																								<TableCell style={{ width: '10%' }} className={classes.listtableCell}>
																									<div className={classes.statuscellContent}>{list.Visibility}</div>
																								</TableCell>
																								<TableCell style={{ width: '5%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentLikes}>{list.Likes}</div>
																								</TableCell>
																								<TableCell style={{ width: '5%' }} className={classes.listtableCell}>
																									<div className={classes.cellContentShares}>{list.Shares}</div>
																								</TableCell>
																								<TableCell
																									style={{ borderBottom: 'none', width: '15%' }}
																									padding="none"
																								>
																									<div className={classes.actionIconsnew}>
																										<Tooltip
																											title="Copy Post"
																											placement="bottom"
																											classes={{
																												tooltip: classes.tooltip,
																											}}
																										>
																											<IconButton
																												aria-label="Copy Post"
																												color="inherit"
																												size="small"
																												className={classes.actionIconButtonList}
																												onClick={() => {
																													if (list.Id) {
																														routes.navigate(
																															{
																																name: 'promotionEditor',
																																params: {
																																	SiteId: list.Parameters.SiteIds,
																																	Title: list.Parameters.PromotionName,
																																	Subtitle: list.Parameters.ShortDescription,
																																	Description: list.Parameters.Description,
																																	StartTime: list.Parameters.StartTime,
																																	ExpiredTime: list.Parameters.EndTime,
																																	MediaType: list.Parameters.MediaType,
																																	MediaUrl: list.Parameters.MediaUrl,
																																	MediaPreviewUrl: list.Parameters.MediaPreviewUrl,
																																	RepeatUntil: list.Parameters.RepeatUntil,
																																	RepeatFrequency: list.Parameters.RepeatFrequency,
																																	Category: Category.Promotion,
																																},
																															},
																															{
																																clearParams: true,
																															},
																														);
																													}
																												}}
																											>
																												<Icon
																													icon={list.SiteIds === 'True' ? faCopysolid : faCopysolid}
																												/>
																												<Icon icon={list.SiteIds === 'True' ? faCopy : faCopy} />
																											</IconButton>
																										</Tooltip>
																										<Tooltip
																											title="Edit Post"
																											placement="bottom"
																											classes={{
																												tooltip: classes.tooltip,
																											}}
																										>
																											<IconButton
																												aria-label="Edit Post"
																												color="inherit"
																												size="small"
																												className={classes.actionIconButtonList}
																												onClick={() => {
																													if (list.Id) {
																														routes.navigate(
																															{
																																name: 'promotionEditor',
																																params: {
																																	Id: list.Id,
																																	SiteId: list.Parameters.SiteIds,
																																	Title: list.Parameters.PromotionName,
																																	Subtitle: list.Parameters.ShortDescription,
																																	Description: list.Parameters.Description,
																																	StartTime: list.Parameters.StartTime,
																																	ExpiredTime: list.Parameters.EndTime,
																																	MediaType: list.Parameters.MediaType,
																																	MediaUrl: list.Parameters.MediaUrl,
																																	RepeatUntil: list.Parameters.RepeatUntil,
																																	RepeatFrequency: list.Parameters.RepeatFrequency,
																																	MediaFileName: list.Parameters.MediaFileName,
																																	MediaPreviewUrl: list.Parameters.MediaPreviewUrl,
																																	PreviewFileName: list.Parameters.PreviewFileName,
																																	Category: Category.Promotion,
																																	status: list.Status,
																																},
																															},
																															{
																																clearParams: true,
																															},
																														);
																													}
																												}}
																											>
																												<Icon icon={faPenSquaresolid} />
																												<Icon icon={faPenSquare} />
																											</IconButton>
																										</Tooltip>
																										<Tooltip
																											title="Delete Post"
																											placement="bottom"
																											classes={{
																												tooltip: classes.tooltip,
																											}}
																										>
																											<IconButton
																												aria-label="Delete Post"
																												color="inherit"
																												size="small"
																												onClick={() => {
																													handleClickOpen(list.Id);
																												}}
																												className={classes.actionIconButtonList}
																											>
																												<Icon icon={faTrashAltsolid} />
																												<Icon icon={faTrashAlt} />
																											</IconButton>
																										</Tooltip>
																									</div>
																								</TableCell>
																							</tr>
																						</table>
																					</Card>
																				</td>
																			</TableRow>
																		);
																	})}
																</table>
															</Paper>
														</td>
													</tr>
												</TableBody>

												<TableFooter>
													<TableRow>
														<TablePagination
															className={classes.listtableCell}
															rowsPerPageOptions={rowsPerPageOptions}
															colSpan={7}
															count={getFeedCount() > 0 ? getFeedCount() : 0}
															rowsPerPage={feedListOptions.PageCount ? feedListOptions.PageCount : 5}
															page={feedListOptions.PageSize ? feedListOptions.PageSize : 0}
															SelectProps={{
																inputProps: { 'aria-label': 'Rows per page' },
																native: true,
															}}
															onChangePage={handleChangePage}
															onChangeRowsPerPage={handleChangeRowsPerPage}
														/>
													</TableRow>
												</TableFooter>
											</Table>
										</Grid>
									</Grid>
								)}
							</div>
						) : showPromotions === 0 && appStores.showAppLoader === false ? (
							<>
								<img src={accessDeniedIcon} className={classes.accessDimensionsForm} alt="" />
								<div className={classes.textMargin}>
									<Typography variant="subtitle2">You do not have permission to access this area</Typography>
									<Typography variant="caption">Please contact your manager to request this access</Typography>
								</div>
							</>
						) : (
							''
						)}
						<Dialog
							open={dialogOpen}
							onClose={() => handleClose()}
							aria-labelledby="form-dialog-title"
							PaperProps={{ className: classes.dialogBoxStyle }}
						>
							<DialogTitle id="alert-dialog-title-">{'Confirm Delete'}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Are you sure you want to delete <br /> this item?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button color="primary" onClick={handleClose}>
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={() => {
										deleteItem(deleteId);
									}}
								>
									OK
								</Button>
							</DialogActions>
						</Dialog>
					</main>
				</div>
			</main>
		</>
	);
});

export default PromotionList;
