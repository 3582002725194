import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	divider: {
		'&.MuiDivider-vertical': {
			backgroundColor: '#66ccfa',
			height: '19px',
			opacity: 1,
		},
	},
	headerNavBox: {
		display: 'flex',
		alignItems: 'center',
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	headerNav: {
		width: '100%',
		'& .MuiBreadcrumbs-ol': {
			justifyContent: 'space-evenly',
		},
		'& .MuiBreadcrumbs-separator': {
			display: 'none',
		},
		'& li.MuiBreadcrumbs-li': {
			height: 64,
			width: 90,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 36,
				width: 80,
			},
		},
		'& li.MuiBreadcrumbs-li:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	headerMenuLinkActive: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		paddingTop: theme.spacing(0.5),
		borderBottom: `${theme.palette.primary.main} 4px solid`,
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	userAccountIcon: {
		marginLeft: theme.spacing(4.5),
	},
	feedBadge: {
		'& span': {
			backgroundColor: theme.palette.warning.main,
			padding: 'inherit',
			fontSize: '0.7rem',
			transform: 'scale(1) translate(100%, -50%)',
		},
	},
	dNone: {
		display: 'none',
	},
	dBlock: {},
	helpLink: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '5px',
		color: '#14A0D6 !important',
		'&:hover': {
			color: '#66CCFA !important',
		},
	},
	headerBreadcrm: {
		color: '#66CCFA',
		textTransform: 'capitalize',
		opacity: '1',
	},
	helpIcon: {
		width: '18px !important',
		height: '18px !important',
	},
	helpText: {},
}));

export default useStyles;
