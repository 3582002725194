import * as React from 'react';
import { stores } from 'store';
import { observer } from 'mobx-react-lite';
import { Container, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotificationCenter from './components/NotificationCenter';
import CookiePolicy from './components/CookiePolicy';

stores.routes.router.start();

const useStyles = makeStyles({
	root: {
		display: 'flex',
		padding: 0,
		margin: 0,
	},
});

const App = observer(() => {
	const classes = useStyles();
	const { route, routes, routeName } = React.useContext(stores.context.routes);
	const user = React.useContext(stores.context.user);

	if (route === null || !routeName) {
		return null;
	}

	React.useEffect(() => {
		user.getUser(routeName);
		user.getUserDetails();
	}, [user, routeName]);

	const component = routes[routeName].component;
	const askRoutes = [
		'ask',
		'chatbot',
		'jobRequestForm',
		'dynamicform',
		'viewMyJobs',
		'feedback',
		'announcementsEditor',
		'promotionEditor',
		'announcements',
		'promotion',
		'spaces',
		'bookingsView',
		'spacesHome',
		'myBookings',
	];
	return (
		<Container maxWidth={askRoutes.includes(routeName) ? false : 'xl'} className={classes.root}>
			<CookiePolicy />
			<CssBaseline />
			{component && component(route.params)}
			<NotificationCenter />
		</Container>
	);
});

export default App;
