import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	frameContainer: {
		margin: 16,
		width: '100%',
		marginBottom: '0px',
	},
	paper: {
		display: 'flex',
		height: '100%',
		overflow: 'auto',
		flexDirection: 'column',
	},
	actionIconButton: {
		width: 30,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}0A`,
		},
	},
	actions: {
		justifyContent: 'flex-end',
	},
	slider: {
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	smileyIcon: {
		fontSize: '2rem',
	},
	title: {
		padding: '14px',
	},
	alignStyle: {
		padding: '16px 0px !important',
	},
	textMargin: {
		textAlign: 'center',
	},

	accessDimensions: {
		width: '60%',
		height: '70%',
		margin: 'auto',
		display: 'block',
		objectFit: 'contain',
	},
	closeIcon: {
		width: '40px',
		height: '40px',
		'& > * svg': {
			color: theme.palette.background.paper,
			display: 'flex',
		},
	},
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		height: '100%',
		'. &infinite-scroll-component__outerdiv': {
			height: '100% !important',
		},
	},
	gridWidth: {
		width: 'calc(103% + 7px) !important',
		marginLeft: '12px',
	},
	Card: {
		borderRadius: '8px',
		width: '460px',
		height: '280px',
		marginBottom: '2px',
		boxShadow: '0px 3px 6px #00000029',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2),
		},
		'&:hover': {
			boxShadow: '0px 4px 6px #00000063',
		},
	},
	archiveView: {
		'@media (min-width: 1280px) and (max-width: 1794px)': {
			maxWidth: '1005px',
			flexBasis: '1005px',
		},
		'@media (min-width: 1795px)': {
			maxWidth: '1500px',
			flexBasis: '1500px',
		},
		'@media (min-width: 250px) and (max-width: 1279px)': {
			maxWidth: '508px',
			flexBasis: '508px',
		},
	},
	openCard: {
		width: '740px',
		height: '480px',
	},
	wireframeCard: {
		boxShadow: '0px 3px 6px #00000029',
		width: '460px',
		height: '280px',
		borderRadius: '8px',
	},
	openCardDialog: {
		'& .MuiDialog-paperWidthSm': {
			maxWidth: 'none !important',
			height: '70%',
			width: '35%',
			borderRadius: '8px',
			boxShadow: '0px 5px 8px #00000063',
		},
		boxShadow: '0px 5px 8px #00000063',
	},
	cardMedia: {
		width: '460px',
		height: '280px',
		objectFit: 'fill',
	},
	actionArea: {
		'&:hover $focusHighlight': {
			opacity: 0,
		},
	},
	focusHighlight: {},
	appBarSpacer: {
		height: '64px',
	},
	content: {
		flexGrow: 1,
		textAlign: 'center',
	},
	titleView: {
		height: '1.5rem',
		width: '20rem',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		marginTop: '-9px',
	},
	cardView: {
		paddingLeft: '20px !important',
		marginLeft: '-10px',
		marginRight: '-9px',
		'@media (min-width: 1280px) and (max-width: 1794px)': {
			maxWidth: '510px',
			flexBasis: '510px',
		},
		'@media (min-width: 250px) and (max-width: 1279px)': {
			maxWidth: '100%',
			flexBasis: '100%',
		},
	},
	openCardTitle: {
		color: '#002855',
		font: 'normal normal normal 20px/24px Roboto',
		width: '81%',
		overflowWrap: 'break-word',
	},
	OpenCardtimeView: {
		font: 'normal normal normal 16px/19px Roboto',
		color: '#002855',
	},
	openCardDescr: {
		color: '#002855',
		font: 'normal normal normal 14px/17px Roboto',
		lineHeight: '1.4',
		overflowWrap: 'break-word',
	},
	container: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
		height: 'calc(100vh - 70px)',
		display: 'flex',
	},
	'@global': {
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: '#66CCFA',
		},
	},
	feedUIStyle: {
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
			'&:hover': {
				color: '#14A0D6',
			},
		},
	},
	shareIconStyle: {
		position: 'absolute',
		textAlign: 'start',
		zIndex: 999,
		top: '16px',
		left: '16px',
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
		},
	},
	likeIconStyle: {
		position: 'absolute',
		zIndex: 999,
		textAlign: 'end',
		cursor: 'pointer',
		top: '16px',
		'& .fa-heart': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
			'&:hover': {
				color: '#14A0D6',
			},
		},
		right: '16px',
	},
	likedIconStyle: {
		position: 'absolute',
		zIndex: 999,
		cursor: 'pointer',
		textAlign: 'end',
		top: '16px',
		right: '19px',
		height: '23px',
		width: '21px',
	},
	ratingStyle: {
		'& .MuiRating-iconFilled': {
			color: '#2195f1',
		},
		'& .MuiRating-iconHover': {
			color: '#2195f1',
		},
	},
}));

export default useStyles;
