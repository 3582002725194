import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	shareCss: {
		zIndex: 999,
		position: 'absolute',
		textAlign: 'start',
		cursor: 'pointer',
		top: '16px',
		left: '16px',
		height: '23px',
		width: '21px',
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '21px',
		},
	},
	likeIconStyle: {
		position: 'absolute',
		zIndex: 999,
		textAlign: 'end',
		cursor: 'pointer',
		height: '23px ',
		width: '27px !important',
		top: '16px',
		'& > * svg': {
			color: '#FFFFFF',
			height: '23px',
			width: '25px important',
		},
		right: '16px',
	},
	hoverStyle: {
		fill: 'none',
		stroke: '#002855',
	},
	hoverStyle2: {
		fill: 'none',
		stroke: '#fff',
		'&:hover': {
			stroke: '#14A0D6 !important',
		},
	},
}));

export default useStyles;
